import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Route, Routes} from "react-router-dom";
import Alerts from "../Alerts";
import UploadingDocumentPopup from "../components/UploadingDocumentPopup";
import * as FeatureFlags from "../FeatureFlags";
import AgentScreen from "../screens/main/agent/AgentScreen";
import ApplicationTab from "../screens/main/applications/ApplicationTab";
import ApplyScreen from "../screens/main/applications/ApplyScreen";
import UniversityApplicationFormScreen from "../screens/main/applications/UniversityApplicationFormScreen";
import UniversityApplicationSpecificFormScreen from "../screens/main/applications/UniversityApplicationSpecificFormScreen";
import CourseFavouritesTab from "../screens/main/CourseFavouritesTab";
import CoursesTab from "../screens/main/CoursesTab";
import FundingTab from "../screens/main/FundingTab";
import MoreTab from "../screens/main/MoreTab";
import ProfileTab from "../screens/main/ProfileTab";
import SettingsTab from "../screens/more/SettingsTab";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {mainSectionTheme} from "../theme/Theme";
import * as FirebaseUtil from "../util/FirebaseUtil";
import {MainSectionActionBar} from "../views/ActionBar";

const MainSectionRouter = () => {
    return (
        <MuiThemeProvider theme={mainSectionTheme}>
            <GFThemeProvider value={sections.Main}>
                <Alerts/>
                <UploadingDocumentPopup/>
                <div className="mainSection" style={{display: "flex", flexDirection: "column", height: "100vh"}}>
                    <MainSectionActionBar/>
                    {FeatureFlags.AGENT_ENABLED && !FirebaseUtil.isAgent() && <AgentScreen/>}
                    <div id="scrollableContent" style={{flex: 1, overflowY: "auto"}}>
                        <Routes>
                            <Route key="profile" path="/profile/options" element={<ProfileTab optionsOpen/>}/>
                            <Route key="profile" path="/profile" element={<ProfileTab optionsOpen={false}/>}/>

                            <Route key="courses" path="/courses/:viewingCourseId/:tab" element={<CoursesTab />}/>
                            <Route key="courses" path="/courses/:viewingCourseId" element={<CoursesTab />}/>
                            <Route key="courses" path="/courses" element={<CoursesTab />}/>

                            <Route key="uniSpecificForm" path="/applications/apply/:applicationID/form/:subsection"
                                   element={<UniversityApplicationSpecificFormScreen />}/>
                            <Route key="apply" path="/applications/apply/:applicationID" element={<ApplyScreen />}/>
                            <Route key="uniForm" path="/applications/form/:subsection" element={<UniversityApplicationFormScreen />}/>
                            <Route key="applications" path="/applications/:tab" element={<ApplicationTab />}/>
                            <Route key="applicationsRoot" path="/applications" element={<ApplicationTab />}/>

                            <Route key="funding" path="/funding/:uid/:version" element={<FundingTab />}/>
                            <Route key="funding" path="/funding" element={<FundingTab />}/>

                            <Route key="favourites" path="/favourites/:viewingCourseId/:tab" element={<CourseFavouritesTab />}/>
                            <Route key="favourites" path="/favourites/:viewingCourseId" element={<CourseFavouritesTab />}/>
                            <Route key="favourites" path="/favourites" element={<CourseFavouritesTab />}/>

                            {!FirebaseUtil.isAgent() && <Route key="settings" path="/more/settings/:tab" element={<SettingsTab />}/>}
                            {!FirebaseUtil.isAgent() && <Route key="settings" path="/more/settings" element={<SettingsTab />}/>}

                            <Route key="more" path="/more" element={<MoreTab/>}/>
                        </Routes>
                    </div>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default MainSectionRouter;