import {createTheme, useTheme} from "@mui/material/styles";
import * as Tab from "../Tab";
import {Section} from "../Tab";

// Customising the definition of a PaletteColor to add in a 'type'
declare module "@mui/material/styles" {
    interface PaletteColor {
        main: string;
        type: string;
    }

    // allow configuration using `createTheme`
    interface PaletteColorOptions {
        main: string;
        type?: string;
    }
}

const makeTheme = (section: Section) => createTheme({
    palette: {
        primary: {
            main: section.colour,
            type: section.paletteType
        },
        secondary: {
            main: section.secondaryColour
        }
    }
});

export const mainSectionTheme = makeTheme(Tab.sections.Main);
export const accSectionTheme = makeTheme(Tab.sections.Main);
export const jobsSectionTheme = makeTheme(Tab.sections.Main);
export const universitiesSectionTheme = makeTheme(Tab.sections.Main);
export const informationAndGuidanceSectionTheme = makeTheme(Tab.sections.Main);
export const englishLanguageTestsSectionTheme = makeTheme(Tab.sections.Main);

export const homeTheme = makeTheme(Tab.sections.Home);

export const usePrimaryColour = () => useTheme().palette.primary.main;

export const useSecondaryColour = () => useTheme().palette.secondary.main;

export const useTextColour = () => {
    const theme = useTheme();
    return useIsLightTheme() ? theme.palette.secondary.main : theme.palette.primary.main;
};

/**
 * Returns a hex code for the colour to use for controls (like text buttons, checkboxes, etc) when displayed on a white background
 * This currently happens to be equal to the text color, but conceptually they are different things hence separate functions
 */
export const useControlColour = useTextColour;

/**
 * Returns the name of the colour to use for controls (like text buttons, checkboxes, etc) when displayed on a white background
 */
export const useControlColourName = (): "primary" | "secondary" => {
    return useIsLightTheme() ? "secondary" : "primary";
};

export const useColourOnControl = () => useIsLightTheme() ? "white" : "black";

export const useIsLightTheme = () => useTheme().palette.primary.type === "light";

const loginTheme = (section: Section) => createTheme ({
    components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              // this is styles for the new variants
              "&.subvariant-hovered": {
                "& fieldset": {
                  border: "1px solid #CDCCE4"
                },
                "& .MuiInputBase-input:hover + fieldset": {
                  border: "1px solid #CDCCE4"
                },
                "& .MuiInputBase-input:focus + fieldset": {
                  border: "1px solid #CDCCE4"
                }
              }
            }
          }
        }
      }
});

export const loginSectionTheme = loginTheme(Tab.sections.Main);
