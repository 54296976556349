/**
 * This interface defines the shape of the global 'window' object
 */
import firebase from "firebase/compat";

export interface GFWindow {
    isAgent?: boolean;
    agentToken?: string;
    agentSignedInAs?: string;
    firebaseApp?: firebase.app.App;
    AppleID: any;
}

export const gfWindow = window as unknown as GFWindow;
