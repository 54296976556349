// TO-DO IF WE need Icon
// import {Icon, Tab, Tabs} from "@mui/material";
import {Tab, Tabs} from "@mui/material";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import * as FeatureFlags from "../FeatureFlags";
import * as Str from "../strings/Str";
import * as SectionTab from "../Tab";
import {Section} from "../Tab";
import Dimens from "../theme/Dimens";
import TitleBar from "./TitleBar";
import {createBrowserHistory} from "history";
import {courseSearchFilters} from "../redux/selectors";
import {store} from "../redux/store";

interface ActionBarLinkItem {
    path: string;
    name: string;
    icon: string;
    show?: (() => boolean) | null;
}

interface ActionBarProps {
    selectedTab: number;
    locations: ActionBarLinkItem[];
    didSelect: (index: number) => void;
    section: Section;
}

const ActionBar = ({selectedTab, locations, didSelect, section}: ActionBarProps) => {
    const handleChange = (_, value) => {
        didSelect(value);
    };

    const tabsArray = locations.map(location => (
        <Tab
            key={location.name}
            value={location}
            label={<TabLabel location={location}/>}
            id={section.root.replaceAll("/", "").replaceAll("_", "-").toLowerCase() + (section.root !== "" ? "-" : "") + "menu-link-" + location.name.replaceAll("/", "-").replaceAll(" ", "-").toLowerCase()}
            sx={{
                flex: 1,
                color: section.tabBarUnselectedColour,
                "&.Mui-selected": {
                    backgroundColor:  section.tabBarSelectedColour,
                },
            }}
        />
    ));

    return (
        <div className="noSelect">
            <TitleBar currentSection={section}/>
            <div style={{backgroundColor: "#706CB2", height: "8px"}}>&nbsp;</div>
            <Tabs variant="fullWidth" value={locations[selectedTab]} onChange={handleChange} sx={{background: section.tabBarColour}}
                  TabIndicatorProps={{style: {backgroundColor: section.tabBarSelectedColour, height: Dimens.TabIndicatorHeight}}}>
                {tabsArray}
            </Tabs>
        </div>
    );
};

const TabLabel = ({location}: { location: ActionBarLinkItem }, isSelcted ) => {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            {/* TO-DO IF WE need Icon */}
            {/* <Icon>{location.icon}</Icon> */}
            {location.name}
        </div>
    );
};

interface RouterBasedActionBarProps {
    section: Section;
    locations: ActionBarLinkItem[];
}

const RouterBasedActionBar = ({locations, section}: RouterBasedActionBarProps) => {
    const root = section.root;
    const def = section.default;
    const history = createBrowserHistory();
    const nav = useNavigate();

    const [allHistory, setAllHistory] = useState<string[]>([]);

    useEffect(() => {
        history.listen((event) => {
            setAllHistory(prev => [...prev, event.location.pathname]);
        });
    }, []);

    const location = useLocation();

    const filteredLocations = locations.filter((value) => {
        if (value.show) {
            return value.show();
        } else {
            return true;
        }
    });
    const indexedLocations = filteredLocations.map((tab, index) => ({tab, index}));
    const selectedTab = indexedLocations.filter(e => {
        const tabPath = root + e.tab.path;
        if (location.pathname.startsWith(tabPath)) return true;
        //check if its the default tab and we're at root
        if (root === location.pathname || (root === "" && location.pathname === "/")) {
            return (tabPath === root + def);
        }
        return false;
    })[0]?.index;

    const didSelect = location => {
        const selectedPath = root + location.path;
        const previous = allHistory.filter(e => e.startsWith(selectedPath));
        if (previous.length > 0) {
            const newUrl = previous[previous.length - 1];
            if (history.location.pathname === newUrl) {
                nav(selectedPath);
            } else {
                nav(newUrl);
            }
        } else {
            nav(selectedPath);
        }
    };

    return <ActionBar locations={filteredLocations} selectedTab={selectedTab} didSelect={didSelect} section={section}/>;
};

const mainSectionLocations: ActionBarLinkItem[] = [
    {path: "/profile", name: Str.profile(), icon: "account_circle"},
    {path: "/courses", name: Str.courses(), icon: "school"},
    {path: "/applications", name: Str.applications(), icon: "assignments", show: () => {
        const filters = courseSearchFilters(store.getState());
        if (!filters) {
            return false;
        }
        return filters.levelOfStudy !== 1 && FeatureFlags.APPLICATIONS_ENABLED;
    }},
    {path: "/funding", name: Str.funding(), icon: "paid"},
    {path: "/favourites", name: Str.favourites(), icon: "favorite"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

const accSectionLocations: ActionBarLinkItem[] = [
    {path: "/search", name: Str.search(), icon: "search"},
    {path: "/applications", name: Str.applications(), icon: "assignments"},
    {path: "/favourites", name: Str.favourites(), icon: "favorite"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

const jobsSectionLocations: ActionBarLinkItem[] = [
    {path: "/search", name: Str.search(), icon: "search"},
    {path: "/favourites", name: Str.favourites(), icon: "favorite"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

const universitySectionLocations: ActionBarLinkItem[] = [
    {path: "/search", name: Str.search(), icon: "search"},
    {path: "/favourites", name: Str.favourites(), icon: "favorite"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

const englishLanguageTestsSectionLocations: ActionBarLinkItem[] = [
    {path: "/information", name: Str.information(), icon: "information"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

const informationAndGuidanceSectionLocations: ActionBarLinkItem[] = [
    {path: "/information", name: Str.information(), icon: "information"},
    {path: "/more", name: Str.more(), icon: "more_horiz"}
];

export const MainSectionActionBar = () => <RouterBasedActionBar locations={mainSectionLocations} section={SectionTab.sections.Main}/>;
export const AccSectionActionBar = () => <RouterBasedActionBar locations={accSectionLocations} section={SectionTab.sections.Accommodation}/>;
export const JobsSectionActionBar = () => <RouterBasedActionBar locations={jobsSectionLocations} section={SectionTab.sections.Jobs}/>;
export const UniversitySectionActionBar = () => <RouterBasedActionBar locations={universitySectionLocations} section={SectionTab.sections.Universities}/>;
export const InformationAndGuidanceSectionActionBar = () => <RouterBasedActionBar locations={informationAndGuidanceSectionLocations} section={SectionTab.sections.InformationAndGuidance}/>;
export const EnglishLanguageTestsSectionActionBar = () => <RouterBasedActionBar locations={englishLanguageTestsSectionLocations} section={SectionTab.sections.EnglishLanguageTests}/>;
