import {Route} from "react-router";
import {Link, Routes} from "react-router-dom";
import ButtonsTestPage from "./ButtonsTestPage";
import SegmentedControlTestPage from "./SegmentedControlTestPage";
import Dimens from "../theme/Dimens";

/**
 * The test screen is a place where we can build UI components to showcase them
 * It can only be accessed in sandbox
 */
const TestScreen = () => (
    <Routes>
        <Route path="/test/buttons" children={<ButtonsTestPage/>}/>
        <Route path="/test/segmentedControl" children={<SegmentedControlTestPage/>}/>
        <Route path="/test" children={<Menu/>}/>
    </Routes>
);

const Menu = () => (
    <div style={{margin: Dimens.DoubleMargin}}>
        <h1>This page is a playground for prototyping UI components</h1>
        <Link to="/test/buttons">Buttons</Link>
        <br/><br/>
        <Link to="/test/segmentedControl">Segmented Controls</Link>
    </div>
);

export default TestScreen;
