import {FormHeading, TickEntry} from "./FormItem";
import * as FormUtil from "./FormUtil";
import {modifyRecordEntry} from "./FormUtil";

describe("Show if satisfaction", () => {
    test("Show if questions show", () => {
        const itemA = new TickEntry("a", undefined, false, undefined, false, undefined, "question", "");
        const itemB = new FormHeading("b", {id: "a", answers: ["yes"]}, "heading");

        const allItems = [itemA, itemB];

        expect(FormUtil.isShowIfSatisfied(itemB, allItems, {a: "yes"})).toEqual(true);
        expect(FormUtil.isShowIfSatisfied(itemB, allItems, {a: "no"})).toEqual(false);
    });
    test("Chained show if questions show", () => {
        const itemA = new TickEntry("a", undefined, false, undefined, false, undefined, "question", "");
        const itemB = new TickEntry("b", {id: "a", answers: ["yes"]}, false, undefined, false, undefined, "question", "");
        const itemC = new TickEntry("c", {id: "b", answers: ["yes"]}, false, undefined, false, undefined, "question", "");

        const allItems = [itemA, itemB, itemC];

        expect(FormUtil.isShowIfSatisfied(itemC, allItems, {a: "yes", b: "yes"})).toEqual(true);
        expect(FormUtil.isShowIfSatisfied(itemC, allItems, {a: "no", b: "yes"})).toEqual(false);
        expect(FormUtil.isShowIfSatisfied(itemC, allItems, {a: "no", b: "no"})).toEqual(false);
    });
});

it("Inserts record answers", () => {
    const oldRecords = [[]];
    const newRecords = modifyRecordEntry(oldRecords, 0, "hello", "world");
    expect(newRecords).toEqual([[{question: "hello", answer: "world"}]]);
});

it("Modifies record answers", () => {
    const oldRecords = [[{question: "hello", answer: "world"}]];
    const newRecords = modifyRecordEntry(oldRecords, 0, "hello", "earth");
    expect(newRecords).toEqual([[{question: "hello", answer: "earth"}]]);
});