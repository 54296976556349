import {useNavigate} from "react-router-dom";

export const tabNumberToUrl = (tab: string): string | null => {
    const tabNumber = parseInt(tab);
    if (tabNumber === 0) return "/profile";
    if (tabNumber === 1) return "/courses";
    if (tabNumber === 2) return "/favourites";
    if (tabNumber === 3) return "/funding";
    if (tabNumber === 4) return "/universities/search";
    if (tabNumber === 5) return "/applications";
    if (tabNumber === 10) return "/accommodation/search";
    if (tabNumber === 11) return "/accommodation/search";
    if (tabNumber === 12) return "/accommodation/bookings";
    if (tabNumber === 13) return "/accommodation/favourites";
    return null;
};

export const ForwardTo = ({url}) => {
    const nav = useNavigate();
    nav(url);
    // This line is required so that TSLint knows we are returning JSX and we can call this function as a component
    return <></>;
};