import {Paper} from "grantfairy-web-common";
import {useState, useEffect} from "react";
import {connect} from "react-redux";
import { useParams } from "react-router-dom";
import {useAlert} from "../../Alerts";
import {linkForSubsection} from "../../Applications";
import {PrimaryButton, SecondaryButton} from "../../components/Buttons";
import * as Property from "../../Property";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {useSecondaryColour} from "../../theme/Theme";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import DataDependant from "../../views/DataDependant";
import PropertyPrice from "../../views/PropertyPrice";
import {SmallTitle, Subtitle} from "../../views/Text";
import FormMenu from "../main/applications/FormMenu";

const Header = ({children}) => <div style={{borderBottom: "1px solid #ccc", padding: 16}}><SmallTitle style={{margin: 0, color: useSecondaryColour()}}>{children}</SmallTitle></div>;

const TenancyInfo = ({property, tenancy}) => (
    <div style={{borderBottom: "1px solid #ccc", padding: 16, color: useSecondaryColour(), textAlign: "center"}}>
        <SmallTitle style={{margin: 0}}>{property.name}</SmallTitle>
        <Subtitle style={{margin: 0, marginTop: 4}}>{Property.tenancyDateRange(tenancy)}</Subtitle>
        <Subtitle style={{margin: 0, marginTop: 4}}><PropertyPrice property={tenancy} from={false}/></Subtitle>
    </div>
);

const ApplyScreen = ({application, answers, formSections, cancelRedirect, makeLinkForSubsection = null, sendApplication, goBack = null, formIsComplete = null}) => {
    const [alert, showAlert] = useAlert();

    const [showErrors, setShowErrors] = useState(false);
    const [countDownOpen, setCountDownOpen] = useState(false);
    const secondaryColour = useSecondaryColour();
    const [countDownMeter, setCountDownMeter] = useState(4);
    const [redirectCancelled, setRedirectCancelled] = useState(false);

    const handleSend = () => {
        if (formIsComplete) {
            if (FirebaseUtil.isAgent()) {
                showAlert(Str.only_learners_can_perform(), Str.only_learners_can_perform_exp(), Str.okay());
                return;
            }
            setCountDownOpen(true);
        } else {
            showAlert(Str.acc_app_form(), Str.need_to_complete_acc_app(), Str.okay());
            setShowErrors(true);
        }
    };

    useEffect(() => {
        if (countDownOpen) {
            const interval = setInterval(() => {
                setCountDownMeter((prevMeter) => prevMeter - 1);
                if (countDownMeter === 0) {
                    clearInterval(interval);
                    setCountDownOpen(false);
                    setCountDownMeter(4);
                    showAlert(Str.acc_app_form(), Str.sending(), "", "", null, null, "sm", true);
                    sendApplication(application.tenancy.id);
                }
            }, 1000);

            return () => clearInterval(interval);
        } else if (redirectCancelled) {
            cancelRedirect(application.id);
            setRedirectCancelled(false);
        }
        return () => {};
    }, [countDownOpen, countDownMeter, sendApplication, application.tenancy.id, application.id, cancelRedirect, showAlert, redirectCancelled]);

    const handleCancelRedirect = () => {
        setRedirectCancelled(true);
        setCountDownOpen(false);
        setCountDownMeter(4);
    };

    const countDown = (
        <div onClick={() => setCountDownOpen(false)} style={{width: "100%", height: "100%", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000, display: countDownOpen ? "flex" : "none", alignItems: "center", justifyContent: "center"}}>;
            <dialog style={{maxWidth: "40rem", borderRadius: "1rem", border: "none"}} open={countDownOpen}>
                <h2>{Str.sending_accommodation_application()}</h2>
                <p style={{color: "#15BCBC"}}>{Str.sending_your_details_in_time(countDownMeter)}</p>
                <p style={{display: "flex", alignItems: "center"}}><span className="material-symbols-outlined">mystery</span> {Str.transparency_matters()}</p>
                <p>{Str.ucas_receives_payment(Str.app_name())}</p>
                <button style={{color: secondaryColour, border: "none", background: "none"}} onClick={handleCancelRedirect}>{Str.go_back_to_app().toUpperCase()}</button>
            </dialog>
        </div>
    );

    return (
        <>
            {countDown}
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center"}} className="noSelect">
                {alert}
                <Paper style={{margin: 32, height: "calc(100% - 64px)", display: "flex", flexDirection: "column", width: "80vw"}}>
                    <TenancyInfo tenancy={application.tenancy} property={application.property}/>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Header>{Str.acc_app_form().toUpperCase()}</Header>
                        <FormMenu showErrors={showErrors} answers={answers} applicationID={application.id} formSections={formSections} makeLinkForSubsection={makeLinkForSubsection}/>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-around", margin: 16}}>
                        <div style={{flex: 1}}/>
                        <SecondaryButton style={{flex: 2}} onClick={goBack}>{Str.saveDraft()}</SecondaryButton>
                        <div style={{flex: 1}}/>
                        <PrimaryButton style={{flex: 2}} onClick={handleSend}>{Str.send()}</PrimaryButton>
                        <div style={{flex: 1}}/>
                    </div>
                </Paper>
            </div>
        </>
    );
};

const Container = ({application, formSections, answers, ...props}) => (
    <DataDependant data={application}>
        <ApplyScreen application={application?.payload} formSections={formSections?.payload ?? []} answers={answers?.payload ?? {}} {...props}/>
    </DataDependant>
);

const mapStateToProps = (state, {applicationID}) => ({
    application: selectors.accommodationApplicationByID(state, applicationID),
    formSections: selectors.accApplicationFormSections(state),
    answers: selectors.applicationFormAnswers(state),
    formIsComplete: selectors.accommodationFormCompletionPercent(state) === 100,
    makeLinkForSubsection: subsection => linkForSubsection(subsection, "/accommodation/applications/form/")
});

export const mapDispatchToProps = (dispatch, {applicationID}) => ({
    sendApplication: (tenancyID) => dispatch(actions.sendAccommodationApplication(tenancyID, applicationID)),
    goBack: () => dispatch(actions.goBack("/accommodation/applications")),
    cancelRedirect: (accApplicationID) => dispatch(actions.cancelAccommodationApplicationRedirect(accApplicationID))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {applicationID} = useParams();
    return (<Connected applicationID={applicationID} />);
};

export default WrappedAndConnected;