import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import {Dispatch, SetStateAction} from "react";
import {useControlColourName} from "../theme/Theme";


export const ShowPasswordInputAdornment = ({showPassword, setShowPassword}: { showPassword: boolean, setShowPassword: Dispatch<SetStateAction<boolean>> }) => (
    <InputAdornment position="end">
        <ShowPasswordButton setShowPassword={setShowPassword} showPassword={showPassword}/>
    </InputAdornment>
);

export const ShowPasswordButton = ({showPassword, setShowPassword}: { showPassword: boolean, setShowPassword: Dispatch<SetStateAction<boolean>> }) => (
    <ToggleButton isOn={showPassword} setIsOn={setShowPassword} iconOn="visibility" iconOff="visibility_off"/>
);

interface ToggleButtonProps {
    isOn: boolean,
    setIsOn: Dispatch<SetStateAction<boolean>>,
    iconOn: string,
    iconOff: string
}

export const ToggleButton = ({isOn, setIsOn, iconOn, iconOff}: ToggleButtonProps) => (
    <Button color={useControlColourName()} onClick={() => setIsOn(e => !e)}>
        <Icon>{isOn ? iconOn : iconOff}</Icon>
    </Button>
);