import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {connect} from "react-redux";
import {SanitizedHtml} from "../components/SanitizedHtml";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import {mainSectionTheme, useControlColourName} from "../theme/Theme";

const ServerSidePopup = ({popup, onLeft, onRight}) => {
    const controlColourName = useControlColourName();
    return (
        <Dialog modal={popup.modal} open={true} maxWidth="lg" fullWidth={true} className="noSelect">
            <DialogTitle>{popup.title}</DialogTitle>
            <DialogContent>
                <SanitizedHtml html={popup.body}/>
            </DialogContent>
            <DialogActions>
                {popup.negative && <Button color={controlColourName} onClick={onLeft}>{popup.negative}</Button>}
                {popup.positive && <Button color={controlColourName} onClick={onRight}>{popup.positive}</Button>}
            </DialogActions>
        </Dialog>
    );
};

const Container = ({popup, onLeft, onRight}) => {
    if (popup == null) return null;

    return (
        <MuiThemeProvider theme={mainSectionTheme}>
            <ServerSidePopup popup={popup} onLeft={() => onLeft(popup.id)} onRight={() => onRight(popup.id)}/>
        </MuiThemeProvider>
    );
};

const mapStateToProps = state => ({
    popup: selectors.serverSidePopup(state)
});

const mapDispatchToProps = dispatch => ({
    onRight: id => dispatch(actions.serverSidePopupOk(id)),
    onLeft: id => dispatch(actions.serverSidePopupCancel(id))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;