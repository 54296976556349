import {Autocomplete} from "@mui/material";
import {TextField} from "@mui/material";
import GoogleMapReact from "google-map-react";
import {Paper, useInterval} from "grantfairy-web-common";
import {useEffect, useRef, useState} from "react";
import DropDownOptionWithCheck from "../../components/DropDownOptionWithCheck";
import * as Constants from "../../Constants";
import * as Str from "../../strings/Str";
import {usePrimaryColour} from "../../theme/Theme";
import {Subtitle, Title} from "../../views/Text";

const AnimatedMap = () => {

    const mapRef = useRef();

    const time = useInterval(100);

    const [lat, setLat] = useState(51.2);
    const [lng, setLng] = useState(-1);

    const options = (maps) => ({
        zoomControlOptions: {
            position: maps.ControlPosition.LEFT_CENTER,
            style: maps.ZoomControlStyle.SMALL
        },
        fullscreenControl: false,
        rotateControl: true,
        scaleControl: true
    });

    const loadedMap = ({map}) => {
        mapRef.current = map;
    };

    useEffect(() => {
        setLat(l => l + 0.0015);
        setLng(l => l > 2 ? -1 : (l + 0.0009));
    }, [time]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            // @ts-ignore
            map.panTo(new window.google.maps.LatLng(lat, lng));
        }
    }, [lat, lng]);

    return <div style={{height: "100%", width: "100%"}}>
        <GoogleMapReact
            bootstrapURLKeys={{key: Constants.GOOGLE_MAPS_API_KEY}}
            defaultCenter={{
                lat: 54,
                lng: -1.5
            }}
            defaultZoom={10}
            onGoogleApiLoaded={loadedMap}
            options={options}
            yesIWantToUseGoogleMapApiInternals>
        </GoogleMapReact>
    </div>;
};

export const NoCampusView = ({campuses, onCampusChange}) => (
    <div style={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative"}}>
        <AnimatedMap/>
        <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center"}} onClick={e => e.stopPropagation()}>
            <div style={{marginTop: 64}}>
                <Paper className="contentColumn" style={{background: "white", padding: 32, textAlign: "center"}}>
                    <Title style={{color: usePrimaryColour(), margin: 32, fontSize: 32, marginTop: 0}}>{Str.digs()}</Title>
                    <Subtitle style={{color: usePrimaryColour(), margin: 0, marginBottom: 32, fontSize: 24}}>{Str.welcome_acc()}</Subtitle>
                    <CampusSelector campuses={campuses} placeholder={Str.university_or_campus()} onCampusChange={onCampusChange} style={{width: "100%"}}/>
                </Paper>
            </div>
        </div>
    </div>
);

export const CampusSelector = ({campuses, selectedCampus = null, onCampusChange, placeholder = "", style = {}}) => (
    <Autocomplete id="myriad-rooms-search-bar"
                  options={campuses}
        // @ts-ignore
                  getOptionLabel={e => e.name}
                  style={{width: 600, maxWidth: "50vw", ...style}}
                  value={selectedCampus} onChange={(e, v) => onCampusChange(v)}
        // @ts-ignore
                  renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option.name}/>}
                  renderInput={(params) => (
                      <TextField placeholder={placeholder} {...params} size="small" InputProps={{...params.InputProps, style: {background: "white"}}}/>
                  )}/>
);