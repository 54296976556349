import {makeSuccessResult} from "../../util/ApiResult";
import {mapStateToProps} from "./CoursesTab";

//TODO add more tests
describe("map state to props", () => {
    it("fave checker checks when faves exist", () => {
        const state = {
            courseFavourites: makeSuccessResult([
                {id: "a"},
                {id: "c"}
            ]),
            allCourses: {}
        };
        const props = mapStateToProps(state, {viewingCourseId: "1", tab: "1"});
        expect(props.checkFave({id: "a"})).toEqual(true);
        expect(props.checkFave({id: "b"})).toEqual(false);
        expect(props.checkFave({id: "c"})).toEqual(true);
        expect(props.checkFave({id: "d"})).toEqual(false);
    });
});


