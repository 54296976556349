import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import {ProfileImageView} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useAlert} from "../../../Alerts";
import * as Assets from "../../../Assets";
import {PrimaryButton} from "../../../components/Buttons";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import {useColourOnPrimary} from "../../../theme/GFThemeProvider";
import {usePrimaryColour} from "../../../theme/Theme";
import * as api from "../../../util/api";
import DataDependant from "../../../views/DataDependant";
import {SmallTitle, Subtitle, Title} from "../../../views/Text";
import AgentChatScreen from "./AgentChatScreen";

const InviteRow = ({icon, name, desc, onClick}) => (
    <div style={{display: "flex", alignItems: "center", borderBottom: "1px solid #ccc"}} className="hoverable" onClick={onClick}>
        <div style={{padding: 16}}>
            <ProfileImageView src={icon} size={48}/>
        </div>
        <div style={{margin: 16}}>
            <SmallTitle style={{margin: 0}}>{name}</SmallTitle>
            <Subtitle style={{margin: 0, marginTop: 8}}>{desc}</Subtitle>
        </div>
    </div>
);

const InviteChooser = ({open, invites, onChoose, onClose}) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <div style={{padding: 16}}>
            <Title style={{margin: 0, marginBottom: 16}}>{Str.select_agent()}</Title>

            <InviteRow onClick={() => onChoose(null)} icon={require("../../../" + Assets.UCAS_ROUND)} name={Str.no_agent()} desc={Str.no_agent_processed_by_gf()}/>
            {invites.map((e, index) => <InviteRow key={index} onClick={() => onChoose(e)} icon={e.profilePicture} name={e.name} desc={e.agency}/>)}
        </div>
        <DialogActions>
            <Button color="secondary" onClick={onClose}>{Str.cancel()}</Button>
        </DialogActions>
    </Dialog>
);

const ChangeAgentButton = ({invites, onSelect, style, changeAgent}) => {
    const [isChoosing, setIsChoosing] = useState(false);
    const buttonText = changeAgent ? Str.change_my_agent() : Str.select_agent();
    return (
        <>
            <InviteChooser open={isChoosing} onClose={() => setIsChoosing(false)} onChoose={onSelect} invites={invites}/>
            <PrimaryButton style={style} onClick={() => setIsChoosing(true)}>
                {buttonText}
            </PrimaryButton>
        </>
    );
};

const NoAgentScreen = ({invites, onChangeAgent}) => (
    <div style={{textAlign: "center", padding: 16}}>
        <Title>{Str.dont_have_agent()}</Title>
        <SmallTitle>{Str.agent_help_text_top()}</SmallTitle>
        <ChangeAgentButton style={{marginTop: 32, paddingLeft: 32, paddingRight: 32}} invites={invites} onSelect={onChangeAgent} changeAgent={false}/>
        <SmallTitle>{Str.agent_help_text_bottom(Str.select_agent())}</SmallTitle>
    </div>
);

const ReportAgentDialog = ({open, setOpen, onReport}) => {

    const [text, setText] = useState("");
    const [alert, showAlert] = useAlert();

    const handleReport = () => {
        onReport(text.trim());
        showAlert(Str.report_this_agent(), Str.report_sent(), Str.okay(), null, () => {
            setOpen(false);
        });
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth={true} className="noSelect">
            {alert}
            <DialogTitle>{Str.report_this_agent()}</DialogTitle>
            <DialogContent>
                <TextField value={text} onChange={e => setText(e.target.value)} variant="outlined" color="primary" multiline={true} rows={20}
                           style={{width: "100%"}} placeholder={Str.agent_report_exp()}/>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => setOpen(false)}>{Str.cancel()}</Button>
                <Button color="secondary" onClick={handleReport} disabled={text.trim().length === 0}>{Str.send()}</Button>
            </DialogActions>
        </Dialog>
    );
};

const SettingsScreen = ({agents, invites, onChangeAgent, onReport}) => {
    const [reporting, setReporting] = useState(false);

    return (
        <div style={{display: "flex", padding: 16, alignItems: "center", flexDirection: "column"}}>
            <ReportAgentDialog open={reporting} setOpen={setReporting} onReport={onReport}/>
            <SmallTitle>{Str.your_agent_is(agents[0].name)}</SmallTitle>
            <ProfileImageView size={96} src={agents[0].profilePicture}/>

            <div style={{display: "flex", flexDirection: "column"}}>
                <p style={{color: "#888"}}>{Str.agent_has_access()}</p>
                <ChangeAgentButton style={{marginTop: 32, paddingLeft: 32, paddingRight: 32}} invites={invites} onSelect={onChangeAgent} changeAgent={true}/>
                <PrimaryButton style={{marginTop: 8, paddingLeft: 32, paddingRight: 32}} onClick={() => setReporting(true)}>{Str.report_this_agent()}</PrimaryButton>
            </div>
        </div>
    );
};

const PAGE_CHAT = "chat";
const PAGE_SETTINGS = "settings";

const Screen = ({agents, invites, onChangeAgent, messages, agentsHistory, onClose, hasAgent, ...props}) => {
    const [page, setPage] = useState(PAGE_CHAT);

    const colourOnPrimary = useColourOnPrimary();

    return (
        <div>
            <div style={{background: usePrimaryColour(), padding: "8px 16px", color: colourOnPrimary, display: "flex", alignItems: "center"}}>
                {page === PAGE_SETTINGS && hasAgent && <Button onClick={() => setPage(PAGE_CHAT)}><Icon style={{color: colourOnPrimary}}>arrow_back</Icon></Button>}
                <SmallTitle style={{margin: 0}}>{Str.agent()}</SmallTitle>
                <div style={{flex: 1}}/>
                {page === PAGE_CHAT && hasAgent && <Button onClick={() => setPage(PAGE_SETTINGS)}><Icon style={{color: colourOnPrimary}}>settings</Icon></Button>}
                <Button style={{color: colourOnPrimary}} onClick={onClose}>
                    <Icon fontSize="large">clear</Icon>
                </Button>
            </div>
            <div style={{height: "calc(50vh)", overflowY: "auto"}}>
                {!hasAgent && <NoAgentScreen invites={invites} onChangeAgent={onChangeAgent}/>}
                {hasAgent && page === PAGE_CHAT && <AgentChatScreen agents={agents} messages={messages} agentsHistory={agentsHistory} {...props}/>}
                {hasAgent && page === PAGE_SETTINGS && <SettingsScreen agents={agents} invites={invites} onChangeAgent={onChangeAgent} {...props}/>}
            </div>
        </div>
    );
};

const Container = ({agents, hasAgent, onClose, ...props}) => {

    const onDownloadFile = (id, fileName) => {
        api.downloadFile("Agent/downloadFile", {id}, fileName);
    };

    return (
        <DataDependant data={agents}>
            <Screen agents={agents?.payload} onClose={onClose} hasAgent={hasAgent} onDownloadFile={onDownloadFile} {...props}/>
        </DataDependant>
    );
};

const AgentButton = ({open, onToggle, unreadMessagesCount}) => {
    return (
        <>
            <PrimaryButton onClick={onToggle} style={{height: 64, borderRadius: 32}}><Icon fontSize="large">{open ? "clear" : "message"}</Icon></PrimaryButton>
            {unreadMessagesCount > 0 && <div style={{position: "absolute", top: 0, right: 0, background: "red", borderRadius: 16, padding: 4, color: "white", fontSize: 12}}>{unreadMessagesCount}</div>}
        </>
    );
};

export const StrongPaper = ({children, style}) => {
    const styles = {borderRadius: 16, overflow: "hidden", boxShadow: "0 0 48px 0px rgba(92,92,92,0.71)", ...style};
    return <div style={styles}>{children}</div>;
};

const Popup = ({open, setOpen, ...props}) => {
    useEffect(() => {
        props.requestUnreadAgentMessagesCount();
    }, []);

    return (
        <div style={{position: "fixed", left: 32, bottom: 16, zIndex: 500}} className="noSelect">
            <StrongPaper style={{display: open ? "" : "none", background: "white", width: 700, minHeight: 300, marginBottom: 16}}>
                <Container {...props} open={open} onClose={() => setOpen(false)}/>
            </StrongPaper>
            <AgentButton open={open} onToggle={() => setOpen(!open)} unreadMessagesCount={props.unreadMessagesCount.payload?.count ?? 0}/>
        </div>
    );
};

const mapStateToProps = state => ({
    agents: selectors.currentAgents(state),
    hasAgent: selectors.hasAgent(state),
    invites: selectors.agentInvites(state)?.payload ?? [],
    messages: selectors.agentMessages(state)?.payload ?? [],
    unreadMessagesCount: selectors.unreadAgentMessagesCount(state),
    agentsHistory: selectors.agentsHistory(state)?.payload ?? [],
    open: selectors.isAgentChatOpen(state)
});

const mapDispatchToProps = dispatch => ({
    onChangeAgent: agent => {
        if (agent == null) {
            dispatch(actions.removeAgent());
        } else {
            dispatch(actions.changeAgent(agent.id));
        }
    },
    onSendMessage: message => dispatch(actions.sendAgentMessage(message)),
    onSendFile: file => dispatch(actions.sendAgentFile(file)),
    onReport: report => dispatch(actions.reportAgent(report)),
    onOpenDeepLink: (link) => dispatch(actions.openDeepLink(link)),
    setOpen: (open) => dispatch(actions.setAgentChatOpen(open)),
    requestUnreadAgentMessagesCount: () => dispatch(actions.requestUnreadMessagesCount())
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Popup);

export default Connected;