import BraintreeDropin from "braintree-web-drop-in";
import {ContainedButton} from "grantfairy-web-common";
import {useEffect, useRef, useState} from "react";
import * as Str from "../strings/Str";
import Colours from "../theme/Colours";

const PaymentScreen = ({braintreeToken, gotNonce}) => {

    const containerRef = useRef();
    const instanceRef = useRef();

    const [isBuying, setIsBuying] = useState(false);

    useEffect(() => {
        if (braintreeToken && containerRef.current) {
            BraintreeDropin.create({
                authorization: braintreeToken,
                container: containerRef.current
            }, function (createErr, instance) {
                instanceRef.current = instance;
            });
        }
    }, [braintreeToken, containerRef]);

    const onPurchase = () => {
        if (isBuying) return;
        setIsBuying(true);
        if (instanceRef.current) {
            instanceRef.current.requestPaymentMethod((err, payload) => {
                if (err) {
                    setIsBuying(false);
                } else {
                    gotNonce(payload.nonce);
                }
            });
        }
    };

    return (
        <div>
            <div ref={containerRef}/>
            <div style={{display: "flex", justifyContent: "center", marginTop: 32}}>
                <ContainedButton style={{paddingLeft: 32, paddingRight: 32, background: isBuying ? Colours.DisabledButtonBackground : ""}} onClick={onPurchase}>{Str.purchase()}</ContainedButton>
            </div>
        </div>
    );
};

export default PaymentScreen;