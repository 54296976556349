import {MenuItem, Select} from "@mui/material";
import Icon from "@mui/material/Icon";
import {AspectFitImage, Paper, usePrimaryColour} from "grantfairy-web-common";
import {useEffect, useRef, useState} from "react";
import LinesEllipsis from "react-lines-ellipsis";
import {Link} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import PlainLink from "../../components/PlainLink";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import * as Str from "../../strings/Str";
import {currentUser} from "../../util/FirebaseUtil";
import * as MIANUtil from "../../util/MIANUtil";
import FavouriteButton from "../../views/FavouriteButton";
import MIANView from "../../views/MIANView";
import PropertyPrice from "../../views/PropertyPrice";
import {Subtitle, Title} from "../../views/Text";
import LazyLoad from "react-lazyload";

const SORTS = Object.freeze({
    closeToCity: Str.city_close(),
    farFromCity: Str.city_far(),
    lowestPrice: Str.price_low(),
    highestPrice: Str.price_high(),
    closeToCampus: Str.uni_close(),
    farFromCampus: Str.uni_far()
});

const AccommodationSortSelector = ({sort, setSort}) => (
    <Select value={sort} onChange={e => setSort(e.target.value)} variant="outlined" style={{background: "white", width: "calc(100% - 32px)", margin: "0 16px"}}>
        {Object.values(SORTS).map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
    </Select>
);

const TravelTimeInfoItem = ({duration, icon, color}) => (
    <div style={{color, display: "flex", alignItems: "center"}}>
        <Icon>{icon}</Icon>
        &nbsp;&nbsp;
        {duration != null && <p>{Str.travel_time_mins(Math.round(duration / 60))}</p>}
        {duration == null && <p>{Str.travel_time_mins(0)}</p>}
    </div>
);

const TravelTimeInfo = ({directionInfo}) => (
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid rgba(0, 0, 0, 0.2)", marginTop: "15px"}}>
        <TravelTimeInfoItem icon="drive_eta" color="#f44336" duration={directionInfo?.driving?.duration}/>
        <TravelTimeInfoItem icon="directions_bike" color="#3f51b5" duration={directionInfo?.cycling?.duration}/>
        <TravelTimeInfoItem icon="directions_walk" color="#4caf50" duration={directionInfo?.walking?.duration}/>
    </div>
);

const PropertiesListItem = ({property, isSelected, onClickUrl, detailsUrl, isFave, onFave, directionInfo, showDirectionInfo, logClick}) => {
    const primary = usePrimaryColour();
    const border = isSelected ? `4px solid ${primary}` : "";
    const borderWidth = isSelected ? 4 : 0;

    const ref = useRef();
    const logClickHandler = () => logClick(property);

    useEffect(() => {
        if (isSelected && ref.current) {
            // @ts-ignore
            ref.current.scrollIntoView({block: "center", behavior: "smooth"});
        }
    }, [isSelected]);

    // const showTravelTimes = isSelected && showDirectionInfo;

    return (
        <div ref={ref.current}>
            <PlainLink to={onClickUrl} onClick={logClickHandler}>
                <Paper style={{background: "#FFF", margin: 16 - borderWidth, border, position: "relative"}} className="noSelect" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <div style={{display: property.sold_out ? "" : "none", position: "absolute", bottom: isSelected ? 60 : 16, right: 80}}>
                        <img src={require("../../assets/sold_out.png")} style={{height: 130}} alt="Sold out"/>
                    </div>
                    {/* TODO hardcoded strings */}
                    <LazyLoad overflow><AspectFitImage backgroundSize="cover" style={{width: "100%", height: 150}} src={property.image_url} alt="Image of accommodation"/></LazyLoad>
                    <div style={{padding: 16}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{flex: 1}}>
                                <Title style={{margin: 0}}>{property.name}</Title>
                                <Subtitle style={{margin: 0, marginTop: 8}}><PropertyPrice property={property}/></Subtitle>
                            </div>
                            <div style={{ top: "20px", left: "400px", background: "white", width: "40px",  height: "40px",  alignItems: "center",  position: "absolute",  justifyContent: "center",  display: "flex",  borderRadius: "50%",  border: "1px solid rgba(0, 0, 0, 0.2)"}}>
                                <FavouriteButton favourited={isFave} onFave={onFave} itemID={property.id}/>
                            </div>
                        </div>
                        {isSelected && (<>
                            <LinesEllipsis style={{color: "#888", margin: 0, marginTop: 16, whiteSpace: "pre-wrap"}} text={property.description_short} ellipsis="..." maxLine={3}/>
                            <div style={{display: "flex", margin: 16, justifyContent: "center"}}>
                                <PrimaryButton component={Link} to={detailsUrl} style={{paddingLeft: 64, paddingRight: 64}}>{Str.details()}</PrimaryButton>
                            </div>  
                        </>)}
                        <TravelTimeInfo directionInfo={directionInfo}/>
                    </div>
                </Paper>
            </PlainLink>
        </div>
    );
};

const PropertiesList = ({properties, selectedPropertyId, makeUrlForProperty, makeUrlForPropertyDetails, checkFave, onFave, searchCategory, searchTerm = "", directionInfo = null, showDirectionInfo = false, mians = []}) => {
    const [sort, setSort] = useState(SORTS.closeToCampus);

    const sortedProperties = [...properties];
    sortedProperties.sort((a, b) => {
        if (sort === SORTS.closeToCampus) return a.campus_distance - b.campus_distance;
        if (sort === SORTS.farFromCampus) return b.campus_distance - a.campus_distance;
        if (sort === SORTS.closeToCity) return a.city_center_distance - b.city_center_distance;
        if (sort === SORTS.farFromCity) return b.city_center_distance - a.city_center_distance;
        if (sort === SORTS.lowestPrice) return a.day_price - b.day_price;
        if (sort === SORTS.highestPrice) return b.day_price - a.day_price;
        return 1;
    });

    const logClickHandler = (clickedProperty) => {
        const user = currentUser();
        const propertyRanking = sortedProperties.findIndex((p) => (p.uid === clickedProperty.uid)) + 1;
        const roomUrl = makeUrlForProperty(clickedProperty);
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid, searchCategory, searchTerm, propertyRanking.toString() ?? "", clickedProperty.uid, roomUrl));
    };

    const propertyMapper = e => <PropertiesListItem key={e.uid} property={e} isSelected={e.uid === selectedPropertyId} onClickUrl={makeUrlForProperty(e)}
                                                    detailsUrl={makeUrlForPropertyDetails(e)} isFave={checkFave(e)} onFave={fave => onFave(e, fave)}
                                                    directionInfo={directionInfo} showDirectionInfo={showDirectionInfo} logClick={logClickHandler}/>;

    const list = MIANUtil.zipWithMians(sortedProperties, mians, propertyMapper, mian => (
        <div style={{paddingLeft: 16, paddingRight: 16}} key={mian.uid}>
            <MIANView mian={mian}/>
        </div>
    ));

    return (
        <>
            <Paper id="myriad-rooms-welcome-message"
                   style={{background: "white", paddingLeft: 16, paddingRight: 16, marginBottom: 8, marginLeft: 16, marginRight: 16, borderRadius: "8px", overflow: "hidden"}}
                   onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                <div style={{width: "calc(100% - 32px)"}}>
                    <h3>{Str.accommodation_welcome_title()}</h3>
                    <p>{Str.accommodation_welcome_text()}</p>
                </div>
            </Paper>
            <AccommodationSortSelector sort={sort} setSort={setSort}/>
            {list}
        </>
    );
};

export default PropertiesList;
