import {ApiResult, ApiResultState, ServerApiResult} from "./ApiResult";

export interface ActionWithServerResult {
    type: string;
    result: ServerApiResult;
}

export const fetchUnwrappedReducer = <T>(
    state: T,
    action: ActionWithServerResult,
    gotAction: string,
    makePayload: (rawResult: any) => T
): T => {
    if (action.type === gotAction) {
        const {result} = action;
        if (result.success) {
            return makePayload(result.result ?? {});
        }
    }
    return state;
};

export const fetchReducerWithLoading = <T>(
    state: ApiResult<T>,
    action: ActionWithServerResult,
    loadingAction: string,
    gotAction: string,
    makePayload: (rawResult: any) => T,
    allowNull: boolean = false
): ApiResult<T> => {
    if (action.type === gotAction) {
        const {result} = action;
        return makeResultForPayload(result, makePayload(result.result ?? {}), allowNull);
    }
    if (action.type === loadingAction) {
        return {state: ApiResultState.Loading};
    }
    return state;
};

export const fetchReducer = <T>(
    state: ApiResult<T>,
    action: ActionWithServerResult,
    gotAction: string,
    makePayload: (rawResult: any) => T,
    allowNull: boolean = false
): ApiResult<T> => {
    if (action.type === gotAction) {
        const {result} = action;
        return makeResultForPayload(result, makePayload(result.result ?? {}), allowNull);
    }
    return state;
};

export const fetchAndCacheReducer = <PayloadType>(
    state: { [key: string]: ApiResult<PayloadType> },
    action: ActionWithServerResult,
    gotAction: string,
    requestedAction: string,
    makePayload,
    makeKeyFromAction: (action: any) => string = a => a.uid,
    allowNull: boolean = false
): { [key: string]: ApiResult<PayloadType> } => {
    const key: string = makeKeyFromAction(action);
    if (action.type === gotAction) {
        const {result} = action;
        const payload: PayloadType = makePayload(result.result ?? {});
        const finalResult: ApiResult<PayloadType> = makeResultForPayload(result, payload, allowNull);
        return {...state, [key]: finalResult};
    }
    if (action.type === requestedAction) {
        return {...state, [key]: {state: ApiResultState.Loading}};
    }
    return state;
};

const makeResultForPayload = <T>(result: ServerApiResult, payload: T, allowNull: boolean = false): ApiResult<T> => {
    if (result.success && (allowNull || (payload !== null && payload !== undefined))) {
        return {state: ApiResultState.Success, payload};
    } else {
        return {state: ApiResultState.Error, error: result.error};
    }
};
