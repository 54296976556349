import {InfoOutlined} from "@mui/icons-material";
import Colours from "../../theme/Colours";
import Dimens from "../../theme/Dimens";

const BlockInfoView = ({item}: { item: string }) => {
    return (
        <div id={item} style={{display: "flex", alignItems: "center", marginTop: Dimens.StandardMargin, marginBottom: Dimens.HalfMargin}}>
            <p style={{margin: 0}}><InfoOutlined style={{color: Colours.secondaryText}}/></p>
            <div style={{width: Dimens.HalfMargin}}/>
            <i style={{color: Colours.secondaryText, whiteSpace: "pre-wrap"}}>{item}</i>
        </div>
    );
};

export default BlockInfoView;
