import {HomeScreenProgressIndicator} from "../../model/HomeScreenItem";
import {CSSProperties} from "react";
import Dimens from "../../theme/Dimens";
import PlainLink from "../../components/PlainLink";
import {DeepLinkUtil} from "../../util/DeepLink";
import {Paper} from "grantfairy-web-common";
import {PrimaryButton} from "../../components/Buttons";
import {Subtitle, Title} from "../../views/Text";
import {VSpacer} from "../../components/Spacers";
import {useElementSize} from "usehooks-ts";
import {useSecondaryColour} from "../../theme/Theme";

function ProgressCircle({progress}: { progress: number }) {

    const [svgHolderRef, {width, height}] = useElementSize();

    // Use the smaller length, halve it because it's a radius, and remove some padding space
    const radius = (Math.min((width as number), (height as number)) / 2) - 2;

    const portionOfCircleToUse = 0.75; // The bottom of the circle isn't complete, just to look better
    const stroke = 12;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    // SVG draws the circle from the middle right. We want it to start from the very bottom but then offset by half of portionOfCircleToUse. May use in the future to rotate hence comments are kept.

    // const degreesToLeave = (1 - portionOfCircleToUse) * 360;
    // const circleRotation = 90 + degreesToLeave / 2;

    // Work out how much of the circle to shade
    const shadedPortionOfCircumference = circumference * portionOfCircleToUse * progress;
    const strokeDashoffset = circumference - shadedPortionOfCircumference;

    // Work out how much of the track to shade (all of portionOfCircleToUse)
    const strokeDashoffsetForTrack = circumference - circumference * portionOfCircleToUse;

    const primaryColor = useSecondaryColour();

    return (
        <div ref={svgHolderRef} style={{height: "100%", width: "100%", textAlign: "center"}}>

            <svg
                height={radius * 2}
                width={radius * 2}>
                <circle
                    stroke="#CCCCCC"
                    fill="transparent"
                    strokeWidth={stroke}
                    style={{strokeDashoffset: strokeDashoffsetForTrack, strokeWidth: stroke}}
                    strokeDasharray={circumference}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke={primaryColor}
                    fill="transparent"
                    strokeWidth={stroke}
                    style={{strokeDashoffset, strokeWidth: stroke}}
                    strokeDasharray={circumference}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>

        </div>
    )
        ;
}

const HomeScreenProgressIndicatorView = ({data}: { data: HomeScreenProgressIndicator }) => {
    const cardStyle: CSSProperties = {
        height: "100%",
        background: data.backgroundColor,
        padding: Dimens.DoubleMargin,
        gap: Dimens.StandardMargin,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    };
    return (
        <PlainLink to={DeepLinkUtil.deepLinkFromUrl(data.link)?.toWebappUrl()}>
            <Paper style={cardStyle} onPointerEnterCapture={() => {
            }} onPointerLeaveCapture={() => {
            }}>
                <div style={{display: "flex", gap: Dimens.StandardMargin}}>
                    <div style={{flex: 1}}>
                        <Title style={{color: data.textColor, margin: 0}}>{data.title}</Title>
                        <VSpacer space={Dimens.HalfMargin}/>
                        <Subtitle style={{color: data.textColor, margin: 0}}>{data.subtitle}</Subtitle>
                    </div>
                    <PrimaryButton>{data.buttonText}</PrimaryButton>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", position: "relative", flex: 1}}>
                    <ProgressCircle progress={data.completionFraction}/>
                    <div style={{position: "absolute", textAlign: "center"}}>
                        <p style={{fontWeight: 600, fontSize: 30, margin: 0}}>{data.progressText}</p>
                        <VSpacer space={Dimens.StandardMargin}/>
                        <p style={{margin: 0}}>{data.progressSubText}</p>
                    </div>
                </div>
            </Paper>
        </PlainLink>
    );
};

export default HomeScreenProgressIndicatorView;
