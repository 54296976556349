import {useState} from "react";
import {useDispatch} from "react-redux";
import * as actions from "../redux/actions";
import * as Str from "../strings/Str";
import {useIsLightTheme} from "../theme/Theme";

const FavouriteButton = ({favourited, onFave, itemID}: { favourited: boolean, onFave: (isFave: boolean) => void, itemID: string}) => {
    const [isHovered, setIsHovered] = useState(false);
    const isLightTheme = useIsLightTheme();
    const heartPink = require(favourited ? "../assets/ic_heart_pink.png" : "../assets/ic_heart_outline_pink.png");
    const heartBlue = require(favourited ? "../assets/ic_heart_blue.png" : "../assets/ic_heart_outline_blue.png");

    const src = isLightTheme ? heartBlue : heartPink;

    const dispatch = useDispatch();

    const onClick = event => {
        event.stopPropagation();
        event.preventDefault();
        onFave(!favourited);
        if (favourited) {
            dispatch(actions.showToast(Str.unfavourited()));
        } else {
            dispatch(actions.showToast(Str.favourited()));
        }
    };

    return <img id={"btn-favourite-" + itemID} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={onClick} alt={favourited ? Str.unfavourite() : Str.favourite()}
                style={{width: 32, height: 32, filter: isHovered ? "brightness(0.7)" : ""}} src={src}/>;
};

export default FavouriteButton;