import {OpenInNew} from "@mui/icons-material";

interface TextWithHyperlinkPartsProps {
    text: string;
    hyperlinkSubstring: string;
    hyperlink?: string;
    linkColour: string;
    addIcon: boolean;
}

export const TextWithHyperlinkParts = ({text, hyperlinkSubstring, hyperlink, linkColour, addIcon }: TextWithHyperlinkPartsProps) => {
    if (hyperlinkSubstring != null && text.includes(hyperlinkSubstring)) {
        const [bitBeforeLink, bitAfterLink] = text.split(hyperlinkSubstring);
        const icon = addIcon ? <OpenInNew /> : null;
        return <span>{[bitBeforeLink, <a key={"hyperlink-part"} className="external-hoverable" target="_blank" rel="noopener noreferrer" style={{color: linkColour}} href={hyperlink ?? hyperlinkSubstring}>{hyperlinkSubstring} {icon}</a>, bitAfterLink]}</span>;
    }
    return <span>{text}</span>;
};