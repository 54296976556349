import {useState} from "react";
import * as Str from "../strings/Str";
import {ReactSearchAutocomplete} from "react-search-autocomplete";
import {Autocomplete, Checkbox, TextField} from "@mui/material";
import DropDownOptionWithCheck from "../components/DropDownOptionWithCheck";
import PlainLink from "../components/PlainLink";
import Colours from "../theme/Colours";
import {FilterOption, SubjectSearchOption} from "./CourseSearchInterfaces";
import Dimens from "../theme/Dimens";
import {Heading} from "./Text";

type CourseSearchHeaderProps = {
    handleOnBlur: () => void;
    searchKeyDown: (e: any) => void;
    localResults: SubjectSearchOption[];
    onSearchHandler: (e: any) => void;
    sendSearch: (subjectName: string) => void;
    handleOnFocus: () => void;
    handleOnClear: () => void;
    searchQuery?: string;
    searchBorderColour: string;
    allProvidersSorted: FilterOption<string>[];
    currentProvider: FilterOption<string>;
    localSetProviderId: (id?: string) => void;
    studyModes: FilterOption<string>[];
    localSetStudyMode: (val?: string) => void;
    currentStudyMode: FilterOption<string>;
    studyLevels: FilterOption<number>[];
    currentStudyLevel: FilterOption<number>;
    localSetStudyLevel: (level: any) => void;
    englishLanguageQualificationsPresent: boolean;
    controlColourName: "error" | "primary" | "secondary" | "default" | "info" | "success" | "warning" | undefined;
    filterByEnglishLanguageRequirements: boolean;
    formatResultHandler: (item: any) => JSX.Element;
    localSetFilterByEnglishLanguageRequirements: (isChecked: boolean) => void;
    showResultFilters: boolean;
};

export const CourseSearchHeader = ({
                                       handleOnBlur, searchKeyDown, localResults, onSearchHandler, sendSearch, handleOnFocus,
                                       handleOnClear, searchQuery, searchBorderColour, allProvidersSorted, currentProvider,
                                       localSetProviderId, studyModes, localSetStudyMode, currentStudyMode, studyLevels, currentStudyLevel,
                                       localSetStudyLevel, englishLanguageQualificationsPresent, controlColourName, filterByEnglishLanguageRequirements,
                                       formatResultHandler, localSetFilterByEnglishLanguageRequirements, showResultFilters
                                   }: CourseSearchHeaderProps) => {
    const [showSearchByUniversity, setShowSearchByUniversity] = useState(currentProvider != null);

    const showSearch = () => {
        setShowSearchByUniversity(!showSearchByUniversity);
    };

    return (
        <div style={{display: "flex", flexWrap: "wrap"}}>
            <div style={{flex: 2, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 10}}>
                <Heading>Discover your next opportunity in the UK</Heading>
                <div id={"course-input-search_text_field"} onBlur={handleOnBlur} onKeyDown={searchKeyDown}>
                    <p style={{marginBottom: Dimens.QuarterMargin, marginTop: Dimens.QuarterMargin}}>{Str.search_courses()}</p>
                    <ReactSearchAutocomplete items={localResults} onSearch={(e, _) => onSearchHandler(e)} onSelect={(e) => sendSearch(e.name)} placeholder={""} formatResult={formatResultHandler}
                                             onFocus={handleOnFocus} onClear={handleOnClear} showNoResults={false} inputSearchString={searchQuery}
                                             styling={{zIndex: 1, borderRadius: "4px", fontFamily: "Roboto", border: `1px solid ${searchBorderColour}`, height: "40px"}}/>
                </div>

                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: 10}}>
                    <div style={{marginTop: 8, minWidth: 470, flex: 1}}>
                        <p style={{marginBottom: Dimens.QuarterMargin, marginTop: Dimens.QuarterMargin}}>{Str.level_of_study()}</p>
                        <Autocomplete id={"input-level_of_study_dropdown"} options={studyLevels} getOptionLabel={e => e.label} style={{flex: 1}} disableClearable={true}
                                      value={currentStudyLevel} onChange={(e, v) => localSetStudyLevel(v?.id)}
                                      renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option.label}/>}
                                      renderInput={(params) => <TextField placeholder={""} {...params} size="small" variant="outlined" color="secondary"/>}/>
                    </div>
                    <div style={{marginTop: 8, minWidth: 470, flex: 1}}>
                        <p style={{marginBottom: Dimens.QuarterMargin, marginTop: Dimens.QuarterMargin}}>{Str.study_mode()}</p>
                        <Autocomplete id={"input-study_mode_dropdown"} options={studyModes} getOptionLabel={e => e.label} style={{flex: 1}}
                                      value={currentStudyMode ?? null} onChange={(e, v) => localSetStudyMode(v?.id)}
                                      renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option.label}/>}
                                      renderInput={(params) => <TextField
                                          placeholder={""} {...params} size="small" color="secondary"/>}/>
                    </div>
                </div>
                {showSearchByUniversity &&
                    <div style={{marginTop: 8}}>
                        <p style={{marginBottom: Dimens.QuarterMargin, marginTop: Dimens.QuarterMargin}}>{Str.university_lower()}</p>
                        <Autocomplete id={"input-university_dropdown"} options={allProvidersSorted} getOptionLabel={e => e.label} style={{width: "100%"}}
                                      value={currentProvider ?? null} onChange={(e, v) => localSetProviderId(v?.id)}
                                      renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option.label}/>}
                                      renderInput={(params) => <TextField placeholder={""} {...params} size="small" variant="outlined" color="secondary"/>}/>
                    </div>
                }
                {!showSearchByUniversity &&
                    <div style={{marginTop: 8}}>
                        <p>Or <PlainLink style={{textDecoration: "underline", color: "#0074A6"}} onClick={() => showSearch()}>Search by university</PlainLink></p>
                    </div>}
                {showResultFilters && <div style={{display: "flex", justifyContent: "space-between"}}>
                    <p>{Str.english_language_requirements()}</p>
                    {englishLanguageQualificationsPresent &&
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Checkbox id={"chk-english_language_requirement"} color={controlColourName} checked={filterByEnglishLanguageRequirements} onChange={e => localSetFilterByEnglishLanguageRequirements(e.target.checked)}/>
                            <p style={{color: Colours.secondaryText}}>{Str.english_language_requirements_scores_set_filter_prompt()}</p>
                        </div>}
                    {!englishLanguageQualificationsPresent && <p style={{color: Colours.secondaryText}}>{Str.english_language_requirements_no_scores_yet_set()}</p>}
                </div>
                }
            </div>
            {/*<div style={{display: "flex", flex: 1, minWidth: 250, alignItems: "center", justifyContent: "center"}}>
                <div style={{margin: "0 auto"}}>
                    <img style={{display: "inline-block", maxWidth: 300, marginLeft: 30, verticalAlign: "middle"}} src={isometricsearch}/>
                </div>
            </div>*/}
        </div>
    );
};