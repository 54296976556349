import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {APPLICATION_POST_SENT_STATUS} from "../../../Applications";
import * as Str from "../../../strings/Str";
import {ManualRegistrationContent} from "./ManualRegistrationContent";
import {PartialRegistrationContent} from "./PartialRegistrationContent";

const getRequiredCredentialsPartFromPostSentStatus = (postSentStatus) => {
    if (postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername) return Str.username();
    if (postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringPassword) return Str.password();
    return "";
};

const Popup = ({onSubmit, onClose, application}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [validate, setValidate] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const usernameValid = username.trim().length > 0;
    const passwordValid = password.trim().length > 0;
    const hasError = !usernameValid || !passwordValid;

    const registrationHelpText = application.registrationHelpText ?? "";

    const postSentStatus = application.postSentStatus;
    const isManualRegistration = postSentStatus === APPLICATION_POST_SENT_STATUS.manualRegistrationRequired;
    const isPartialManualRegistration = postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername || postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringPassword;
    const title = isPartialManualRegistration
        ? Str.partial_manual_registration_dialog_title()
        : Str.requires_manual_registration();

    const whichPart = getRequiredCredentialsPartFromPostSentStatus(postSentStatus);

    useEffect(() => {
        setUsername(application?.username ?? "");
        setPassword(application?.password ?? "");
    }, [application]);

    const handleSubmit = () => {
        if (hasError) {
            setValidate(true);
        } else {
            onSubmit(application, username, password);
            onClose();
        }
    };
    return (
        <Dialog open={true} maxWidth="md" fullWidth onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            {isManualRegistration && <ManualRegistrationContent application={application} registrationHelpText={registrationHelpText} setUsername={setUsername}
                                                                validate={validate} usernameValid={usernameValid} setPassword={setPassword} setShowPassword={setShowPassword}
                                                                showPassword={showPassword} passwordValid={passwordValid} hasError={hasError} username={username} password={password}/>}
            {!isManualRegistration && <PartialRegistrationContent providerName={application.course.providerName} whichPart={whichPart} registrationHelpText={registrationHelpText}
                                                                  postSentStatus={postSentStatus} setUsername={setUsername} validate={validate} usernameValid={usernameValid}
                                                                  setPassword={setPassword} showPassword={showPassword} passwordValid={passwordValid} setShowPassword={setShowPassword}
                                                                  hasError={hasError} username={username ?? ""} password={password ?? ""}/>}
            <DialogActions>
                <Button color="secondary" onClick={onClose}>{Str.later()}</Button>
                <Button color="secondary" onClick={handleSubmit}>{Str.save()}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Popup;