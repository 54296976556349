import {Title} from "./Text";

const FacilityCategoryView = ({category: {name, items}}) => {
    return (
        <div style={{flex: 1}}>
            <Title>{name}</Title>
            {items.map(item => (
                <div style={{display: "flex", alignItems: "center", margin: 8}} key={item.name}>
                    <img alt="" style={{width: 32, height: 32}} src={item.icon}/>
                    <p style={{margin: 0, marginLeft: 16}}>{item.name}</p>
                </div>
            ))}
        </div>
    );
};

const FacilitiesView = ({facilities}) => {
    const byCategory = {};
    facilities.forEach(e => {
        const old = byCategory[e.category] ?? [];
        byCategory[e.category] = [...old, e];
    });
    const categories = Object.keys(byCategory).map(key => {
        const items = byCategory[key];
        return {name: key, category_order: items[0].category_order, items};
    });
    categories.sort((a, b) => a.category_order - b.category_order);

    return (
        <div style={{display: "flex"}}>
            {categories.map((c, index) => <FacilityCategoryView key={index} category={c}/>)}
        </div>
    );
};

export default FacilitiesView;