import {HomeScreenScholarshipsList} from "../../model/HomeScreenItem";
import Dimens from "../../theme/Dimens";
import * as Str from "../../strings/Str";
import {ClickablePaper, Paper} from "grantfairy-web-common";
import {SmallTitle, Subtitle, Title} from "../../views/Text";
import {FlexFiller, VSpacer} from "../../components/Spacers";
import ScholarshipDeadline from "../main/ScholarshipDeadline";
import {BasicScholarshipInfo} from "../../model/Scholarship";
import PlainLink from "../../components/PlainLink";
import {PrimaryButton} from "../../components/Buttons";
import {Link} from "react-router-dom";
import Colours from "../../theme/Colours";
import {TextWithBoldParts} from "../../components/TextWithBoldParts";
import {getValueOfScholarship} from "../../util/GetValueOfScholarship";

const makeLinkForScholarship = (scholarship: BasicScholarshipInfo) => `/funding/${scholarship.uid}/${scholarship.version}`;

const HomeScreenScholarshipsListView = ({data}: { data: HomeScreenScholarshipsList }) => {
    return (
        <Paper style={{boxSizing: "border-box", height: "100%", background: "white", display: "flex", flexDirection: "column"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            <VSpacer space={Dimens.DoubleMargin}/>
            <div style={{display: "flex", gap: Dimens.StandardMargin, padding: `0 ${Dimens.DoubleMargin}px`}}>
                <div style={{flex: 1}}>
                    <Title style={{margin: 0,}}>{Str.funding()}</Title>
                    <VSpacer space={Dimens.HalfMargin}/>
                    {data.numberOfScholarships !== 0 && (
                        <Subtitle style={{margin: 0}}>
                            <TextWithBoldParts text={Str.eligible_for_opportunities(data.numberOfScholarships)} boldSubString={data.numberOfScholarships}/>
                        </Subtitle>
                    )}
                </div>
                {data.numberOfScholarships !== 0 && <PrimaryButton component={Link} to="/funding">{Str.view_all()}</PrimaryButton>}
            </div>
            {data.numberOfScholarships !== 0 && <Carousel scholarships={data.scholarships}/>}
            {data.numberOfScholarships === 0 && <EmptyState/>}
        </Paper>
    );
};

const Carousel = ({scholarships}: { scholarships: BasicScholarshipInfo[] }) => {
    return (
        <div style={{flex: 1, display: "flex", gap: Dimens.StandardMargin, padding: Dimens.StandardMargin, alignItems: "stretch", overflowX: "auto"}}>
            {scholarships.map(scholarship => {
                const amountText = getValueOfScholarship(scholarship);
                return (
                    <PlainLink to={makeLinkForScholarship(scholarship)} key={scholarship.uid}>
                        <ClickablePaper style={{height: "100%", background: "white", boxSizing: "border-box", boxShadow: "rgba(92, 92, 92, 0.71) 0px 0px 13px -5px"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <div style={{width: 400, padding: Dimens.StandardMargin, display: "flex", flexDirection: "column", height: "100%", boxSizing: "border-box"}}>
                                <SmallTitle style={{fontWeight: 500, margin: 0}}>{amountText}</SmallTitle>
                                <FlexFiller/>
                                <Subtitle style={{fontWeight: 600, margin: 0}}>{scholarship.name}</Subtitle>
                                <VSpacer space={Dimens.HalfMargin}/>
                                <Subtitle style={{fontWeight: 500, margin: 0}}><ScholarshipDeadline deadlineUnix={scholarship.deadline_end} highlightNearDeadline={true}/></Subtitle>
                                <FlexFiller/>
                            </div>
                        </ClickablePaper>
                    </PlainLink>
                );
            })}
        </div>
    );
};

const EmptyState = () => {
    return (
        <div style={{flex: 1, display: "flex", padding: Dimens.DoubleMargin, alignItems: "center", flexDirection: "column", textAlign: "center"}}>
            <FlexFiller/>
            <SmallTitle style={{margin: 0, zIndex: 200, color: Colours.secondaryText, whiteSpace: "pre-wrap"}}>{Str.cant_match_schols_check_later()}</SmallTitle>
            <FlexFiller/>
            <FlexFiller/>
            <PrimaryButton component={Link} to="/profile">{Str.update_profile()}</PrimaryButton>
        </div>
    );
};

export default HomeScreenScholarshipsListView;
