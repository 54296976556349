import * as StorageKeys from "../StorageKeys";
import stringData from "./strings.json";

const defaultLanguage = "en";
const getLanguageCode = (): string => localStorage.getItem(StorageKeys.LANGUAGE_CODE) ?? defaultLanguage;

const getDictionaryKey = getLanguageCode;

export const getString = (key: string): string => {
    const string = (stringData["strings"][getDictionaryKey()] ?? {})[key] ?? stringData["strings"][defaultLanguage][key] ?? "";
    return string.replace(/\\n/g, "\n");
};

export const getStringWithArgs = (key: string, ...args: any): string => {
    const value = getString(key);
    let result = value.replace(/%d/g, "%s");

    args.forEach(arg => {
        result = result.replace("%s", arg);
    });

    return result;
};

export const getArray = (key: string): string[] => {
    stringData["arrays"][defaultLanguage][key].forEach((element: string , index: string | number) => {
        stringData["arrays"][defaultLanguage][key][index] = element.replace(/\\n/g, "\n");
    }, stringData["arrays"][defaultLanguage][key]);

    return stringData["arrays"][getDictionaryKey()][key] ?? stringData["arrays"][defaultLanguage][key] ?? [];
};