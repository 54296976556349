import React, {useContext} from "react";
import {Section} from "../Tab";

const GFThemeContext = React.createContext<Section | null>(null);

export const GFThemeProvider = GFThemeContext.Provider;

export const useGfTheme = (): Section | null => {
    return useContext(GFThemeContext);
};

export const useTertiaryColour = (): string => {
    const gfTheme = useGfTheme();
    return gfTheme?.tertiaryColour ?? "";
};

export const useColourOnPrimary = (): string => {
    const gfTheme = useGfTheme();
    return gfTheme?.colourOnPrimary ?? "";
};