import {FormItemView} from "../../views/form/FormItemView";
import * as Str from "../../strings/Str";
import Dimens from "../../theme/Dimens";
import {Heading} from "../../views/Text";
import {useDispatch, useSelector} from "react-redux";
import * as selectors from "../../redux/selectors";
import {FormAnswerType, FormItem} from "../../util/form/FormItem";
import ApiCallStateDependant from "../../views/ApiCallStateDependant";
import {FormQuestionAnswerDictionary} from "../../util/form/FormUtil";
import * as notificationSettingsAnswersSlice from "../../redux/slices/settings/notificationSettingsAnswers";

const NotificationSettingsScreen = ({formItems, answers, setAnswer}: { formItems: FormItem[], answers: FormQuestionAnswerDictionary, setAnswer: (question: string, answer: FormAnswerType) => void }) => {

    return (
        <div style={{padding: Dimens.DoubleMargin}}>
            <Heading style={{margin: 0}}>{Str.notificationSettings()}</Heading>
            {formItems.map(item => (
                <FormItemView key={item.id}
                              item={item}
                              formName="Notification Settings"
                              answer={answers[item.id]}
                              setAnswer={a => setAnswer(item.id, a)}
                              validate={false}
                              allItems={formItems}
                              allAnswers={{}}
                              handleLink={() => {
                              }}
                              onViewFile={() => {
                              }}
                              onDeleteFile={() => {
                              }}
                              onUploadFile={() => {
                              }}/>
            ))}
        </div>
    );
};

const Container = () => {
    const formItemsResult = useSelector(selectors.notificationSettingsFormItems);
    const answers = useSelector(selectors.notificationSettingsAnswers);
    const dispatch = useDispatch();
    const setAnswer = (question: string, answer: FormAnswerType) => dispatch(notificationSettingsAnswersSlice.actions.setAnswer({question, answer}));
    return (
        <ApiCallStateDependant apiCallState={formItemsResult} makeChildren={(formItems => <NotificationSettingsScreen formItems={formItems} answers={answers} setAnswer={setAnswer}/>)}/>
    );
};

export default Container;
