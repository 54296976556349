import {mapResult} from "../../util/ApiResult";
import {fetchReducer} from "../../util/reducerUtil";
import * as actions from "../actions";

export const universityList = (state = {}, action) => fetchReducer(state, action, actions.GOT_UNIVERSITY_LIST, e => e.providers);

export const favouritedUniversities = (state = {}, action) => {
    if (action.type === actions.SET_UNIVERSITY_FAVE) {
        return mapResult(state, old => {
            const {university, fave} = action;
            if (fave) {
                return [...old, university];
            } else {
                return old.filter(e => e.id !== university.id);
            }
        });
    }
    return fetchReducer(state, action, actions.GOT_UNIVERSITY_FAVOURITES, e => e.providers);
};

export const reducers = {universityList, favouritedUniversities};