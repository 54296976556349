import {Table as MUITable, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useColourOnPrimary} from "../theme/GFThemeProvider";
import Colours from "../theme/Colours";
import {CSSProperties, ReactNode} from "react";
import {useTheme} from "@mui/material/styles";

export const StyledTableCell = ({children}) => {
    const theme = useTheme();
    const style = {
        head: {
            backgroundColor: theme.palette.primary.main,
            color: useColourOnPrimary(),
            fontWeight: 600
        },
        body: {
            height: 48
        }
    };

    return (<TableCell sx={style}>{children}</TableCell>);
};

export const StyledTableRow = ({children}) => {
    const style = {
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: Colours.TableStripe,
            }
        }
    };

    return (<TableRow sx={style}>{children}</TableRow>);
};

const Table = ({titles, children, style}: { titles: string[], children: ReactNode, style: CSSProperties }) => (
    <MUITable style={style} size="small">
        <TableHead>
            <StyledTableRow>
                {titles.map(t => <StyledTableCell>{t}</StyledTableCell>)}
            </StyledTableRow>
        </TableHead>
        <TableBody>
            {children}
        </TableBody>
    </MUITable>
);

export default Table;
