import Palette from "./Palette";

const Colours = Object.freeze({
    RowHighlight: Palette.iceberg,
    DisabledButtonBackground: Palette.silver,
    DisabledRowBackground: Palette.gallery,
    EmptyApplicationCartText: Palette.emperor,
    EmptyApplicationCartImage: Palette.mercury,
    secondaryText: Palette.emperor,
    TableStripe: Palette.mercury,
    HomeScreenBackground: Palette.concrete,
    StandardText: Palette.black,
    StandardTextActionBar: Palette.white,
    selectedTabColor: Palette.selectedTab,
    ContentDivider: Palette.silver,
    PathwayProviderIndicator: Palette.purple,
    PositiveStatusIndicator: Palette.limeade,
    NegativeStatusIndicator: Palette.emperor,
    UnfocusedBorder: Palette.alto,
    AsteriskColour: Palette.ucas_magenta,
    OptionalColour: Palette.ucas_peppermint,
    ProfileInfoPanelBackground: Palette.ucas_magenta,
    ProfileInfoPanelTitle: Palette.white,
    ProfileInfoPanelText: Palette.white,
    selectedText: Palette.white
});

export default Colours;