import {connect} from "react-redux";
import Button from "@mui/material/Button";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import QueryString from "query-string";
import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import * as Assets from "../../Assets";
import {SecondaryButton} from "../../components/Buttons";
import * as StorageKeys from "../../StorageKeys";
import * as Str from "../../strings/Str";
import {sections} from "../../Tab";
import {GFThemeProvider} from "../../theme/GFThemeProvider";
import {usePrimaryColour, loginSectionTheme} from "../../theme/Theme";
import {POSTWithoutToken} from "../../util/api";
import EmailLogin from "./EmailLogin";
import * as selectors from "../../redux/selectors";

const onSignInResult = (result, redirectUrl) => {
    if (result.success) {
        localStorage.setItem(StorageKeys.CURRENT_USER, JSON.stringify(result.result));
        window.location.replace(redirectUrl ?? "/");
    } else {
        window.alert(result.error);
        window.open("/login", "_self");
    }
};

const signInWithAccessToken = (providerID, accessToken, redirectUrl) => {
    POSTWithoutToken("Login/signInWithIdentityProvider", {accessToken, providerID}).then(result => onSignInResult(result, redirectUrl));
};

const signInWithFacebook = (redirectUrl) => {
    window.FB.login(response => {
        const token = response?.authResponse?.accessToken;
        if (token == null) return;
        signInWithAccessToken("facebook.com", token, redirectUrl);
    });
};

const signInWithApple = (redirectUrl) => {
    window.AppleID.auth.signIn().then((data) => {
        const idToken = data.authorization.id_token;
        const idTokenSecret = data.authorization.code;
        if (idToken != null && idTokenSecret != null) {
            POSTWithoutToken("Login/signInWithIdentityProvider", {idToken, idTokenSecret, providerID: "apple.com"}).then(result => onSignInResult(result, redirectUrl));
        }
    });
};

const LoginButton = ({color = "white", background, icon, title, onClick, selector}) => (
    <Button id={selector} variant="contained" style={{background, color, width: "100%", margin: "8px 0", fontFamily: "unset", textTransform: "unset"}} onClick={onClick}>
        <div style={{display: "flex", alignItems: "center", width: "100%", padding: "0 0"}}>
            <img src={require("../../" + icon)} style={{width: 24, height: 24}} alt=""/>
            <p style={{margin: 0, flex: 1, fontWeight: 500, fontSize: 17}}>{title}</p>
        </div>
    </Button>
);

const LoginView = ({redirectUrl, userCanLogin}) => {
    const [isInEmailLogin, setIsInEmailLogin] = useState(false);
    const [showSocialLogin, setShowSocialLogin] = useState(true);

    const showSocialLoginHandler = (show) => {
        setShowSocialLogin(show);
    };

    const googleSignInHolder = useRef();

    useEffect(() => {
        if (!userCanLogin) return;
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_KEY,
            callback: function (response) {
                const idToken = response.credential;
                POSTWithoutToken("Login/signInWithIdentityProvider", {idToken, providerID: "google.com"}).then(result => onSignInResult(result, redirectUrl));
            }
        });
    }, [redirectUrl, userCanLogin]);

    useEffect(() => {
        if (!userCanLogin) return;
        window.google.accounts.id.renderButton(
            googleSignInHolder.current,
            {theme: "outline", size: "large", width: 400}
        );
    }, [userCanLogin]);

    if (!userCanLogin) {
        return (
            <div style={{width: 400, padding: "15px 15px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <p style={{ fontSize: 36, fontWeight: 600, marginTop: 0, lineHeight: 1, fontFamily: "Oswald" }}>This app has now been retired</p>
                <p style={{ fontSize: 18, paddingRight: "60px" }}>Our international content can be found with the hub at UCAS.com</p>
                <p style={{ fontSize: 18 }}>Continue the journey through the UCAS hub; the best resource for access to information regarding higher education opportunities in the UK</p>
                <SecondaryButton onClick={() => window.open("https://www.ucas.com/what-are-my-options/create-your-ucas-hub-today", "_self")} style={{marginTop: 20, borderRadius: 24}}>Get started with the UCAS Hub</SecondaryButton>
            </div>
        );
    }

    return (
        <div style={{ padding: "0 16px"}}>
            <EmailLogin onClose={() => setIsInEmailLogin(false)} redirectUrl={redirectUrl} showSocialLogin={(e) => showSocialLoginHandler(e)}/>
            {showSocialLogin && <>
                <div style={{width: "100%", height: 6, borderBottom: "3px solid black", textAlign: "center", paddingTop: 52}}>
                    <span style={{fontSize: 12, backgroundColor: "#F3F5F6", padding: "10px 10px"}}>
                        OR
                    </span>
                </div>
                <div style={{width: "100%", height: 6, textAlign: "center", paddingTop: 52}}>
                    <span style={{fontSize: 14, padding: "10px 10px"}}>
                        Continue with Social
                    </span>
                </div>
                <div style={{width: 400, padding: "60px 0px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Button variant="contained" ref={googleSignInHolder} style={{width: "360", borderRadius: 0, padding: "0 0", margin: "8px 0"}} id="signin-btn-with-google"></Button>
                    <LoginButton
                        background={"#3b5998"}
                        icon={Assets.LOGIN_FACEBOOK}
                        title={Str.sign_in_with_facebook()}
                        onClick={() => signInWithFacebook(redirectUrl)}
                        selector="signin-btn-with-fb"/>

                    <LoginButton
                        background={"#000000"}
                        icon={Assets.LOGIN_APPLE}
                        title={Str.sign_in_with_apple()}
                        onClick={() => signInWithApple(redirectUrl)}
                        selector="signin-btn-with-apple"/>
                </div>
            </>}
        </div>
    );
};

const LoginScreen = ({redirectUrl, userCanLogin}) => {
    const backgroundImage = require("../../" + Assets.UCAS_LOGIN_BACKGROUND);
    return (
        <div style={{display:"flex", flexDirection: "row", flexWrap: "wrap"}}>
            <div style={{flex: 3}}>
                <div style={{ maxWidth: "calc(100vw - 600px)", display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "stretch"}}>
                    <LoginView redirectUrl={redirectUrl} userCanLogin={userCanLogin}/>
                </div>
            </div>
            <div style={{
                        display: "flex", flexDirection: "column", minHeight: "100vh", overflowY: "auto",
                        background: usePrimaryColour(),
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: "no-repeat", backgroundSize:
                        "cover", flex: 7, position: "relative"}}>
                <div style={{position: "absolute", top: 0, left: 50}}>
                    <img alt="" src={require("../../" + Assets.UCAS_LOGO_WHITE_SVG)} style={{width: 160, height: "auto"}}/>
                </div>
                <div style={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems: "center",}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems: "center", fontSize: 48, fontWeight: "bold", color: "#FFFFFF", padding:"40px", position: "absolute", top: 240}}>
                        <h2 style={{letterSpacing: -3, lineHeight: 1, textTransform: "uppercase", fontFamily: "Oswald"}}>
                            Make your UK Degree A<br />
                            Reality with UCAS
                        </h2>
                    </div>
                </div>
                <div style={{position: "absolute", bottom: 4, left: 60, background: "black", color: "white", padding: "0px 8px", textTransform: "uppercase", borderBottom: "solid"}}>
                    <p style={{fontSize: 16,fontWeight: "bold", letterSpacing: -1}}>International</p>
                </div>
            </div>
        </div>
    );
};

const Container = ({userCanLogin}) => {
    const queryString = useLocation().search;
    const queryParams = QueryString.parse(queryString);
    const redirectUrl = queryParams.redirectUrl;
    return (
        <MuiThemeProvider theme={loginSectionTheme}>
            <GFThemeProvider value={sections.Main}>
                <LoginScreen redirectUrl={redirectUrl} userCanLogin={userCanLogin}/>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

const mapStateToProps = state => ({
    userCanLogin: selectors.userCanLogin(state)
});

const Connected = connect(mapStateToProps)(Container);
export default Connected;
