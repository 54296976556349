import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import BaseApplicationFormScreen from "./BaseApplicationFormScreen";
import {useParams} from "react-router-dom";

const baseUrl = "/applications";

export const mapStateToProps = (state, {subsection}) => ({
    subsection: selectors.applicationFormSubsection(state, decodeURIComponent(subsection)),
    navigationOptions: selectors.universityFormNavigationOptions(state, decodeURIComponent(subsection), baseUrl + "/form"),
    allItems: selectors.applicationFormAllItems(state),
    answers: selectors.applicationFormAnswers(state),
    savingState: selectors.applicationFormSavingState(state),
    backLink: baseUrl + "/1"
});

export const mapDispatchToProps = dispatch => ({
    onSetAnswer: (questionID, answer) => dispatch(actions.setApplicationFormAnswer(questionID, answer)),
    onBack: () => dispatch(actions.goBack(baseUrl)),
    onSave: () => dispatch(actions.showToast(Str.saved())),
    onViewFile: (originalName: string, fileName: string) => dispatch(actions.viewFormDocument(fileName, originalName)),
    onDeleteFile: (questionID: string, fileName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => dispatch(actions.deleteFormDocument(questionID, fileName, isRecord, index, innerQuestionID)),
    onUploadFile: (questionID: string, file: string, originalName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => dispatch(actions.uploadFormDocument(questionID, file, originalName, isRecord, index, innerQuestionID))
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(BaseApplicationFormScreen);

const WrappedAndConnected = () => {
    const {subsection} = useParams();
    return (<Connected subsection={subsection} />);
};

export default WrappedAndConnected;