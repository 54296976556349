import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import sanitizeHtml from "sanitize-html";
import * as Str from "../../../strings/Str";
import {usePrimaryColour} from "../../../theme/Theme";
import * as api from "../../../util/api";
import {ApiResult, ApiResultState} from "../../../util/ApiResult";

const makeHtmlWithColouredLinks = (html, colour) => {
    return `<html>

                <head>
                    <style>
                        a {
                            color: ${colour}
                        }
                    </style>
                </head>
                
                <body>${sanitizeHtml(html)}</body>
                
            </html>`;
};

const ApplicationDeclarationPopup = ({declaration, open, onClose}) => {
    const primary = usePrimaryColour();
    return (
        <Dialog open={open} maxWidth="lg" onBackdropClick={onClose}>
            <DialogTitle>{Str.declaration()}</DialogTitle>
            <DialogContent>
                <div className="noSelect" dangerouslySetInnerHTML={{__html: makeHtmlWithColouredLinks(declaration, primary)}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" autoFocus>
                    {Str.close()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Container = ({open, onClose}: { open: boolean, onClose: () => void }) => {
    const [result, setResult] = useState<ApiResult<any> | null>(null);

    useEffect(() => {
        api.POST("Applications/getDeclaration", {}).then(result => {
            if (result.success) {
                setResult({state: ApiResultState.Success, payload: result.result.text});
            } else {
                setResult({state: ApiResultState.Error});
            }
        });
    }, []);

    if (result?.state !== ApiResultState.Success) return null;
    return (
        <ApplicationDeclarationPopup declaration={result?.payload} onClose={onClose} open={open}/>
    );
};

export default Container;