import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiCallState, apiCallStateUtil} from "grantfairy-web-common";
import {HomeScreenItem} from "../../model/HomeScreenItem";

const homeScreenItems = createSlice({
    name: "homeScreenItems",
    initialState: apiCallStateUtil.makeLoading<HomeScreenItem[]>(),
    reducers: {
        request(state) {

        },
        got(state, action: PayloadAction<ApiCallState<HomeScreenItem[]>>) {
            return action.payload;
        }
    }
});

export const reducer = homeScreenItems.reducer;
export const actions = homeScreenItems.actions;
