import * as Str from "../../strings/Str";
import {DateEntry, FileEntry, FormAnswerType, FormHeading, FormInfo, FormItem, MultiSelectEntry, MultiTextEntry, RadioEntry, RadioEntryWithId, RecordEntry, TelephoneEntry, TextEntry, TickEntry} from "../../util/form/FormItem";
import FormInfoView from "./FormInfoView";
import FormTextView from "./FormTextView";
import FormDateView from "./FormDateView";
import FormTickView from "./FormTickView";
import FormRecordView from "./FormRecordView";
import FormFileView from "./FormFileView";
import FormTelephoneView from "./FormTelephoneView";
import FormMultiSelectionView from "./FormMultiSelectionView";
import {FormRadioAnswerView, FormRadioAnswerWithIdView} from "./FormRadioView";
import FormHeaderView from "./FormHeaderView";
import {StringDictionary} from "../../types/StringDictionary";
import FormDividerView from "./FormDividerView";
import {MouseEventHandler} from "react";
import {currentUser} from "../../util/FirebaseUtil";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import {LearnerBeginsAnsweringQuestion} from "../../model/AnalyticsEvents";

export type FormAnswerSetter = (answer: FormAnswerType) => void;

export const UnansweredQuestionLabel = () => <p style={{margin: 0, marginTop: 4, color: "red"}}>{Str.please_fill_field()}</p>;

export const InvalidAnswerLabel = () => <p style={{margin: 0, marginTop: 4, color: "red"}}>{Str.invalid_input()}</p>;

interface FormItemViewProps {
    item: FormItem;
    formName: string;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    setAnswer: FormAnswerSetter;
    validate: boolean;
    onViewFile: (originalName: string, fileName: string) => void;
    onDeleteFile: (fileName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => void;
    onUploadFile: (file: string, originalName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => void;
    allAnswers: StringDictionary;
    allItems: FormItem[];
}

export const FormItemView = ({item, formName, handleLink, answer, setAnswer, validate, onDeleteFile, onViewFile, onUploadFile, allAnswers, allItems}: FormItemViewProps) => {
    const startAnswer = () => {
        const userId = currentUser()?.uid ?? "";
        new GoogleAnalyticsService().logEvent(new LearnerBeginsAnsweringQuestion(userId, formName, item.id));
    };

    if (item.type === "heading") {
        return <FormHeaderView item={item as FormHeading}/>;
    }
    if (item.type === "info") {
        return <FormInfoView item={item as FormInfo} handleLink={handleLink}/>;
    }
    const props = {answer, setAnswer, validate, handleLink};
    if (item.type === "radio") {
        return <FormRadioAnswerView item={item as RadioEntry} startAnswer={startAnswer} {...props}/>;
    } else if (item.type === "radioWithId") {
        return <FormRadioAnswerWithIdView item={item as RadioEntryWithId} startAnswer={startAnswer} {...props}/>;
    } else if (item.type === "text") {
        return <FormTextView item={item as TextEntry} startAnswer={startAnswer} {...props} multiLine={false}/>;
    } else if (item.type === "multiline_text") {
        return <FormTextView item={item as MultiTextEntry} startAnswer={startAnswer} {...props} multiLine={true}/>;
    } else if (item.type === "date") {
        return <FormDateView item={item as DateEntry} startAnswer={startAnswer} {...props}/>;
    } else if (item.type === "tickbox") {
        return <FormTickView item={item as TickEntry} startAnswer={startAnswer} {...props}/>;
    } else if (item.type === "records") {
        return <FormRecordView item={item as RecordEntry} {...props} onViewFile={onViewFile} onDeleteFile={onDeleteFile} onUploadFile={onUploadFile} allItems={allItems} allAnswers={allAnswers}/>;
    } else if (item.type === "file") {
        return <FormFileView item={item as FileEntry} {...props} onDelete={onDeleteFile} onView={onViewFile} onUpload={onUploadFile} startAnswer={startAnswer}/>;
    } else if (item.type === "telephone") {
        return <FormTelephoneView item={item as TelephoneEntry} startAnswer={startAnswer} {...props} />;
    } else if (item.type === "multi_select") {
        return <FormMultiSelectionView item={item as MultiSelectEntry} startAnswer={startAnswer} {...props} allItems={allItems} allAnswers={allAnswers}/>;
    } else if (item.type === "divider") {
        return <FormDividerView/>;
    }

    return <p>{Str.error()}</p>;
};
