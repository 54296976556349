import sanitizeHtml from "sanitize-html";

/**
 * @param string The string from the XML files
 * @return A JSX element which is this string but rendered as HTML supporting <b> and <i>
 */
const HTMLString = ({string}: { string: string }) => {
    const htmlWithLines = string.split("\n").join("<br/>");
    const cleanHtml = sanitizeHtml(htmlWithLines, {allowedTags: ["b", "i", "br"], allowedAttributes: {}});
    return <span dangerouslySetInnerHTML={{__html: cleanHtml}}/>;
};

export default HTMLString;
