import {Input, MenuItem, Select} from "@mui/material";
import {useIsLightTheme} from "../theme/Theme";
import {Language} from "../model/Language";

const underline = {
    "&:before": {
        borderBottom: "none"
    }
};

const LanguagePicker = ({languages, currentLanguage, onSetLanguage}: { languages: Language[], currentLanguage?: string, onSetLanguage: (id: string) => void }) => {
    const isLightTheme = useIsLightTheme();

    return (
        <Select
            input={<Input sx={underline}/>}
            value={currentLanguage ?? ""}
            style={{padding: "6px 8px", margin: "0px 16px", color: (isLightTheme ? "#000000" : "#FFFFFF")}}
            onChange={e => onSetLanguage(e.target.value as string)}>
            {languages.map(lang => (
                <MenuItem key={lang.uid} value={lang.uid}><span className={"flag-icon flag-icon-" + lang.flag}/>&nbsp;&nbsp;{lang.name}</MenuItem>
            ))}
        </Select>
    );
};

export default LanguagePicker;