import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import {ProfileImageView, useFileUpload} from "grantfairy-web-common";
import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {SecondaryButton} from "../../../components/Buttons";
import * as Str from "../../../strings/Str";
import {DeepLinkUtil} from "../../../util/DeepLink";

const AgentChatMessageType = {
    NormalMessage: 0,
    MajorNotificationMessage: 1,
    MinorNotificationMessage: 2,
    MessageAttachment: 3,
    MessageLink: 4
};

const MessageBubble = ({message}) => (
    <p style={{margin: 0, wordWrap: "break-word"}}>{message.message}</p>
);

const FileBubble = ({message, onDownload}) => (
    <div>
        <div style={{display: "flex", alignItems: "center"}}>
            <Icon color="secondary">attach_file</Icon>
            <p style={{margin: 0}}>{message.file}</p>
        </div>
        <SecondaryButton style={{marginTop: 8}} onClick={onDownload}>{Str.view()}</SecondaryButton>
    </div>
);

const LinkBubble = ({message, onView}) => (
    <div>
        <div style={{display: "flex", alignItems: "center"}}>
            <Icon color="secondary">link</Icon>
            <div style={{marginLeft: 16}}>
                <p style={{margin: 0}}>{message.metadata}</p>
                <SecondaryButton style={{marginTop: 8, width: "100%"}} onClick={onView}>{Str.view()}</SecondaryButton>
            </div>
        </div>
    </div>
);

const MessageView = ({agentImage, message, onDownload, openLink}) => {

    const isLhs = message.sent_from != 1;
    const bubbleColour = isLhs ? "#E0E0E0" : "#e5f3f8";
    const timestamp = moment.unix(message.time).format("DD/MM/YY HH:mm");
    const messageType = Number(message.type);

    const profileImageView = isLhs ? (
        <div style={{paddingTop: 16}}>
            <ProfileImageView src={agentImage} size={36} />
        </div>
    ) : null;

    const messageView = (
        <div style={{marginLeft: 8, marginRight: 8}}>
            <p style={{color: "#888", margin: 0, fontSize: 14, textAlign: isLhs ? "left" : "right"}}>{timestamp}</p>
            <div style={{background: bubbleColour, borderRadius: 16, padding: 16}}>
                {messageType === AgentChatMessageType.NormalMessage && <MessageBubble message={message}/>}
                {messageType === AgentChatMessageType.MessageAttachment && <FileBubble message={message} onDownload={() => onDownload(message.uid, message.file)}/>}
                {messageType === AgentChatMessageType.MessageLink && <LinkBubble message={message} onView={() => openLink(message.link)}/>}
            </div>
        </div>
    );

    const spacer = <div style={{flex: 1}}/>;

    const items = isLhs ? [profileImageView, messageView] : [spacer, messageView, profileImageView];

    if (messageType === AgentChatMessageType.MajorNotificationMessage || messageType === AgentChatMessageType.MinorNotificationMessage) {
        return <MessageAsNotification message={message} />;
    }

    return (
        <div style={{display: "flex", marginBottom: 16}}>
            {items}
        </div>
    );
};

const MessageAsNotification = ({message}) => (
    <>
        <div style={{height: "0.05rem", borderLeft: "3rem", borderRight: "3rem", backgroundColor: "black"}}/>
        <p style={{textAlign: "center"}}>{message.message}</p>
    </>
);

const MessagesList = ({messages, agents, agentsHistory, onDownload, openLink}) => {
    const allAgents = [...agents, ...agentsHistory];
    return (
        <div style={{padding: 16}}>
            {messages.map((message, index) => {
                let agentImage = "";
                const agentThatSentMessage = allAgents.find((agent) => (
                    agent.id === message.agentID
                ));
                if (agentThatSentMessage != null) {
                    agentImage = agentThatSentMessage.profilePicture;
                }
                return (
                    <MessageView key={index} agentImage={agentImage} message={message} onDownload={onDownload} openLink={openLink}/>
                );
            })}
        </div>
    );
};

const ChatScreen = ({agents, messages, agentsHistory, onSendMessage, onSendFile, onOpenDeepLink, onDownloadFile, open, ...props}) => {

    const scrollContainer = useRef();
    const [message, setMessage] = useState("");

    const [filePicker, chooseFile] = useFileUpload("", onSendFile);

    const canSend = message.trim().length > 0;

    useEffect(() => {
        const sc = scrollContainer.current;
        if (sc != null) {
            sc.scrollTop = sc.scrollHeight - sc.clientHeight;
        }
    }, [scrollContainer, messages, open]);

    const onSend = () => {
        onSendMessage(message);
        setMessage("");
    };

    const openLink = link => {
        const deepLink = DeepLinkUtil.deepLinkFromUrl(link);
        if (deepLink != null) {
            onOpenDeepLink(deepLink);
        }
    };

    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            {filePicker}
            <div style={{flex: 1, overflowY: "auto"}} ref={scrollContainer}>
                <MessagesList messages={messages} agents={agents} agentsHistory={agentsHistory} openLink={openLink} onDownload={onDownloadFile}/>
            </div>
            <div style={{display: "flex", marginBottom: 16, marginTop: 16}}>
                <Button><Icon fontSize="inherit" color="secondary" onClick={chooseFile}>attach_file</Icon></Button>
                <TextField value={message} onChange={e => setMessage(e.target.value)} style={{flex: 1}} variant="outlined" placeholder={Str.type_a_message()}
                           inputProps={{maxLength: 2000}} size="small" multiline maxRows={8}/>
                <Button disabled={!canSend} onClick={onSend} color="secondary"><Icon fontSize="inherit">send</Icon></Button>
            </div>
        </div>
    );
};

export default ChatScreen;
