import * as React from "react";
import {Icon} from "@mui/material";
import {useSecondaryColour} from "../theme/Theme";

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
    colour?: string;
    showPopoutIcon?: boolean;
}

const ColouredLink = (props: LinkProps) => {
    const defaultColor = useSecondaryColour();
    const color = props.colour ?? defaultColor;
    const showIcon = props.showPopoutIcon ?? true;
    const icon = showIcon ? <>&nbsp;&nbsp;<Icon fontSize="small">open_in_new</Icon></> : null;
    return <a className="hoverable" target="_blank" {...props} style={{color, ...props.style}}>{props.children}{icon}</a>;
};

export default ColouredLink;