import {useSecondaryColour} from "../theme/Theme";
import {ReactElement} from "react";

const Border = () => {
    const secondaryColour = useSecondaryColour();
    return (
        <div style={{
            background: secondaryColour,
            height: 2
        }}/>
    );
};

const ContentWithBorder = ({children, showContent}: { children: ReactElement, showContent: boolean }) => {
    if (!showContent) return children;
    return (
        <>
            <Border/>
            {children}
            <Border/>
        </>
    );
};

export default ContentWithBorder;