import {Util} from "grantfairy-web-common";
import {DeepLink} from "../util/DeepLink";
import {MIAN} from "../model/MIAN";

export const REQUEST_APP_INIT = "REQUEST_APP_INIT";
export const DONE_APP_INIT = "DONE_APP_INIT";

export const APP_FOCUSED = "APP_FOCUSED";
export const GOT_APP_FOCUSED = "GOT_APP_FOCUSED";

export const REQUEST_DASHBOARD = "REQUEST_DASHBOARD";
export const GOT_DASHBOARD = "GOT_DASHBOARD";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUESTED_LOGIN = "REQUESTED_LOGIN";
export const GOT_LOGIN = "GOT_LOGIN";
export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const REQUESTED_PROFILE = "REQUESTED_PROFILE";
export const GOT_PROFILE = "GOT_PROFILE";
export const REQUEST_ONBOARDING = "REQUEST_ONBOARDING";
export const REQUESTED_ONBOARDING = "REQUESTED_ONBOARDING";
export const GOT_ONBOARDING = "GOT_ONBOARDING";
export const SET_ONBOARDING_FIELD = "SET_ONBOARDING_FIELD";
export const DID_SET_ONBOARDING_FIELD = "DID_SET_ONBOARDING_FIELD";

export const REQUEST_COURSE_LIST = "REQUEST_COURSE_LIST";
export const REQUESTED_COURSE_LIST = "REQUESTED_COURSE_LIST";
export const GOT_COURSE_LIST = "GOT_COURSE_LIST";

export const REQUEST_COURSE_SEARCH_OPTIONS = "REQUEST_COURSE_SEARCH_OPTIONS";
export const REQUESTED_COURSE_SEARCH_OPTIONS = "REQUESTED_COURSE_SEARCH_OPTIONS";
export const GOT_COURSE_SEARCH_OPTIONS = "GOT_COURSE_SEARCH_OPTIONS";
export const SET_APPLICATION_FORM_CHANGED_SINCE_COURSE_LOAD = "SET_APPLICATION_FORM_CHANGED_SINCE_COURSE_LOAD";
export const SET_APPLICATION_FORM_CHANGED_SINCE_PROFILE_LOAD = "SET_APPLICATION_FORM_CHANGED_SINCE_PROFILE_LOAD";
export const SET_PROFILE_DETAILS_CHANGED_SINCE_APPLICATION_FORM_LOAD = "SET_PROFILE_DETAILS_CHANGED_SINCE_APPLICATION_FORM_LOAD";

export const REQUEST_POPULAR_SUBJECTS = "REQUEST_POPULAR_SUBJECTS";
export const GOT_POPULAR_SUBJECTS = "GOT_POPULAR_SUBJECTS";

export const SET_COURSE_SEARCH_QUERY = "SET_COURSE_SEARCH_QUERY";
export const SET_COURSE_SEARCH_PROVIDER_ID = "SET_COURSE_SEARCH_PROVIDER_ID";
export const SET_COURSE_SEARCH_LOS = "SET_COURSE_SEARCH_LOS";
export const SET_COURSE_SEARCH_STUDY_MODE = "SET_COURSE_SEARCH_STUDY_MODE";
export const SET_COURSE_SEARCH_ENGLISH_LANGUAGE_REQUIREMENTS = "SET_COURSE_SEARCH_ENGLISH_LANGUAGE_REQUIREMENTS";

export const REQUEST_COURSE_FAVOURITES_LIST = "REQUEST_COURSE_FAVOURITES_LIST";
export const GOT_COURSE_FAVOURITES_LIST = "GOT_COURSE_FAVOURITES_LIST";

export const REQUEST_SELECTED_COURSE = "REQUEST_SELECTED_COURSE";
export const GOT_SELECTED_COURSE = "GOT_SELECTED_COURSE";

export const REQUEST_FUNDING_LIST = "REQUEST_FUNDING_LIST";
export const REQUESTED_FUNDING_LIST = "REQUESTED_FUNDING_LIST";
export const GOT_FUNDING_LIST = "GOT_FUNDING_LIST";

export const REQUEST_ACCOMMODATION_LIST = "REQUEST_ACCOMMODATION_LIST";
export const REQUESTED_ACCOMMODATION_LIST = "REQUESTED_ACCOMMODATION_LIST";
export const GOT_ACCOMMODATION_LIST = "GOT_ACCOMMODATION_LIST";

export const REQUEST_ACCOMMODATION_FAVOURITES = "REQUEST_ACCOMMODATION_FAVOURITES";
export const REQUESTED_ACCOMMODATION_FAVOURITES = "REQUESTED_ACCOMMODATION_FAVOURITES";
export const GOT_ACCOMMODATION_FAVOURITES = "GOT_ACCOMMODATION_FAVOURITES";

export const REQUEST_ACCOMMODATION_DIRECTIONS = "REQUEST_ACCOMMODATION_DIRECTIONS";
export const REQUESTED_ACCOMMODATION_DIRECTIONS = "REQUESTED_ACCOMMODATION_DIRECTIONS";
export const GOT_ACCOMMODATION_DIRECTIONS = "GOT_ACCOMMODATION_DIRECTIONS";

export const REQUEST_COURSE_DETAILS = "REQUEST_COURSE_DETAILS";
export const REQUESTED_COURSE_DETAILS = "REQUESTED_COURSE_DETAILS";
export const GOT_COURSE_DETAILS = "GOT_COURSE_DETAILS";

export const REQUEST_COURSE_FUNDING_DETAILS = "REQUEST_COURSE_FUNDING_DETAILS";
export const REQUESTED_COURSE_FUNDING_DETAILS = "REQUESTED_COURSE_FUNDING_DETAILS";
export const GOT_COURSE_FUNDING_DETAILS = "GOT_COURSE_FUNDING_DETAILS";

export const REQUEST_COURSE_PROVIDER_DETAILS = "REQUEST_COURSE_PROVIDER_DETAILS";
export const REQUESTED_COURSE_PROVIDER_DETAILS = "REQUESTED_COURSE_PROVIDER_DETAILS";
export const GOT_COURSE_PROVIDER_DETAILS = "GOT_COURSE_PROVIDER_DETAILS";

export const REQUEST_JOBS_CONFIG = "REQUEST_JOBS_CONFIG";
export const GOT_JOBS_CONFIG = "GOT_JOBS_CONFIG";

export const SET_JOB_SEARCH_FILTERS = "SET_JOB_SEARCH_FILTERS";

export const REQUEST_JOB_LIST = "REQUEST_JOB_LIST";
export const REQUESTED_JOB_LIST = "REQUESTED_JOB_LIST";
export const GOT_JOB_LIST = "GOT_JOB_LIST";

export const REQUEST_JOB_DETAILS = "REQUEST_JOB_DETAILS";
export const REQUESTED_JOB_DETAILS = "REQUESTED_JOB_DETAILS";
export const GOT_JOB_DETAILS = "GOT_JOB_DETAILS";

export const REQUEST_JOBS_FAVOURITES = "REQUEST_JOBS_FAVOURITES";
export const GOT_JOBS_FAVOURITES = "GOT_JOBS_FAVOURITES";

export const REQUEST_UNIVERSITY_LIST = "REQUEST_UNIVERSITY_LIST";
export const GOT_UNIVERSITY_LIST = "GOT_UNIVERSITY_LIST";

export const REQUEST_UNIVERSITY_FAVOURITES = "REQUEST_UNIVERSITY_FAVOURITES";
export const GOT_UNIVERSITY_FAVOURITES = "GOT_UNIVERSITY_FAVOURITES";

export const REQUEST_PROPERTY_DETAILS = "REQUEST_PROPERTY_DETAILS";
export const REQUESTED_PROPERTY_DETAILS = "REQUESTED_PROPERTY_DETAILS";
export const GOT_PROPERTY_DETAILS = "GOT_PROPERTY_DETAILS";

export const SET_ACCOMMODATION_CAMPUS = "SET_ACCOMMODATION_CAMPUS";

export const REQUEST_APPLICATION_INFO = "REQUEST_APPLICATION_INFO";
export const GOT_APPLICATION_INFO = "GOT_APPLICATION_INFO";

export const REQUEST_ACC_APPLICATION_INFO = "REQUEST_ACC_APPLICATION_INFO";
export const GOT_ACC_APPLICATION_INFO = "GOT_ACC_APPLICATION_INFO";

export const REQUEST_UNI_APPLICATION_LIST = "REQUEST_UNI_APPLICATION_LIST";
export const GOT_UNI_APPLICATION_LIST = "GOT_UNI_APPLICATION_LIST";

export const REQUEST_UNI_APPLICATION = "REQUEST_UNI_APPLICATION";
export const REQUESTED_UNI_APPLICATION = "REQUESTED_UNI_APPLICATION";
export const GOT_UNI_APPLICATION = "GOT_UNI_APPLICATION";

export const REQUEST_ACCOMMODATION_APPLICATION_LIST = "REQUEST_ACCOMMODATION_APPLICATION_LIST";
export const GOT_ACCOMMODATION_APPLICATION_LIST = "GOT_ACCOMMODATION_APPLICATION_LIST";

export const SET_APPLICATION_FORM_ANSWER = "SET_APPLICATION_FORM_ANSWER";
export const DID_SET_APPLICATION_FORM_ANSWER = "DID_SET_APPLICATION_FORM_ANSWER";

export const MOVE_APPLICATION_TO_DRAFT = "MOVE_APPLICATION_TO_DRAFT";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const SUBMIT_FORMFILLER_ACCOUNT_CREDENTIALS = "SUBMIT_FORMFILLER_ACCOUNT_CREDENTIALS";
export const COMPLETE_APPLICATION_SUBMISSION = "COMPLETE_APPLICATION_SUBMISSION";

export const APPLY_FOR_COURSE = "APPLY_FOR_COURSE";
export const APPLY_FOR_TENANCY = "APPLY_FOR_TENANCY";

export const SELECT_DOCUMENT_FOR_APPLICATION = "SELECT_DOCUMENT_FOR_APPLICATION";

export const ADD_APPLICATION_TO_CART = "ADD_APPLICATION_TO_CART";
export const SEND_ACCOMMODATION_APPLICATION = "SEND_ACCOMMODATION_APPLICATION";
export const CANCEL_ACCOMMODATION_APPLICATION_REDIRECT = "CANCEL_ACCOMMODATION_APPLICATION_REDIRECT";
export const SEND_UNI_APPLICATIONS = "SEND_UNI_APPLICATIONS";
export const SET_SHOW_APPLICATION_FORM_ERRORS = "SET_SHOW_APPLICATION_FORM_ERRORS";

export const REQUEST_APPLICATION_PAYMENT_REQUIRED_INFO = "REQUEST_APPLICATION_PAYMENT_REQUIRED_INFO";
export const REQUESTED_APPLICATION_PAYMENT_REQUIRED_INFO = "REQUESTED_APPLICATION_PAYMENT_REQUIRED_INFO";
export const GOT_APPLICATION_PAYMENT_REQUIRED_INFO = "GOT_APPLICATION_PAYMENT_REQUIRED_INFO";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const VIEW_DOCUMENT = "VIEW_DOCUMENT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_COMPLETE = "UPLOAD_DOCUMENT_COMPLETE";
export const PASTE_DOCUMENT = "PASTE_DOCUMENT";

export const VIEW_FORM_DOCUMENT = "VIEW_FORM_DOCUMENT";
export const DELETE_FORM_DOCUMENT = "DELETE_FORM_DOCUMENT";
export const UPLOAD_FORM_DOCUMENT = "UPLOAD_FORM_DOCUMENT";

export const SET_PROFILE_FIELD = "SET_PROFILE_FIELD";
export const DID_SET_PROFILE_FIELD = "DID_SET_PROFILE_FIELD";

export const SET_COURSE_FAVE = "SET_COURSE_FAVE";
export const SET_ACCOMMODATION_FAVE = "SET_ACCOMMODATION_FAVE";
export const SET_JOB_FAVE = "SET_JOB_FAVE";
export const SET_UNIVERSITY_FAVE = "SET_UNIVERSITY_FAVE";

export const REQUEST_SCHOLARSHIP_DETAILS = "REQUEST_SCHOLARSHIP_DETAILS";
export const REQUESTED_SCHOLARSHIP_DETAILS = "REQUESTED_SCHOLARSHIP_DETAILS";
export const GOT_SCHOLARSHIP_DETAILS = "GOT_SCHOLARSHIP_DETAILS";

export const MOVE_SCHOLARSHIP = "MOVE_SCHOLARSHIP";
export const REPORT_SCHOLARSHIP = "REPORT_SCHOLARSHIP";

export const REQUEST_SETTINGS = "REQUEST_SETTINGS";
export const GOT_SETTINGS = "GOT_SETTINGS";
export const SET_SETTINGS_FIELD = "SET_SETTINGS_FIELD";

export const SEND_FEEDBACK = "SEND_FEEDBACK";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_ACCOMMODATION_FILTERS = "SET_ACCOMMODATION_FILTERS";
export const COMMIT_ACCOMMODATION_FILTERS = "COMMIT_ACCOMMODATION_FILTERS";
export const GOT_ACCOMMODATION_COUNT = "GOT_ACCOMMODATION_COUNT";

export const SET_SELECTED_COURSE = "SET_SELECTED_COURSE";
export const DESELECT_COURSE = "DESELECT_COURSE";

export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_EMAIL = "CHANGE_EMAIL";

export const PATH_CHANGED = "PATH_CHANGED";
export const GO_BACK = "GO_BACK";

export const SHOW_TOAST = "SHOW_TOAST";
export const SHOW_ALERT = "SHOW_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";

export const REQUEST_AGENT_INFO = "REQUEST_AGENT_INFO";
export const REQUESTED_AGENT_INFO = "REQUESTED_AGENT_INFO";
export const GOT_AGENT_INFO = "GOT_AGENT_INFO";

export const CHANGE_AGENT = "CHANGE_AGENT";
export const REPORT_AGENT = "REPORT_AGENT";

export const SEND_AGENT_MESSAGE = "SEND_AGENT_MESSAGE";
export const SEND_AGENT_FILE = "SEND_AGENT_FILE";
export const SEND_AGENT_COURSE = "SEND_AGENT_COURSE";

export const CLOSE_IAN = "CLOSE_IAN";

export const CANCEL_SERVER_SIDE_POPUP = "CANCEL_SERVER_SIDE_POPUP";
export const OK_SERVER_SIDE_POPUP = "OK_SERVER_SIDE_POPUP";

export const DID_SUBSCRIBE_FUNDING = "DID_SUBSCRIBE_FUNDING";

export const AUTO_SELECT_APPLICATION_DOCUMENTS = "AUTO_SELECT_APPLICATION_DOCUMENTS";

export const OPEN_DEEP_LINK = "OPEN_DEEP_LINK";

export const SET_AGENT_CHAT_OPEN = "SET_AGENT_CHAT_OPEN";

export const TURN_OFF_WELCOME_POPUP = "TURN_OFF_WELCOME_POPUP";

export const VIEWED_MIAN = "VIEW_MIAN";

export const CLICKED_MIAN = "CLICKED_MIAN";

export const REQUEST_SUBJECT_SEARCH_OPTIONS = "REQUEST_SUBJECT_SEARCH_OPTIONS";
export const GOT_SUBJECT_SEARCH_OPTIONS = "GOT_SUBJECT_SEARCH_OPTIONS";

export const REQUEST_UNREAD_AGENT_MESSAGES_COUNT = "REQUEST_UNREAD_AGENT_MESSAGES_COUNT";
export const REQUESTED_UNREAD_AGENT_MESSAGES_COUNT = "REQUESTED_UNREAD_AGENT_MESSAGES_COUNT";
export const GOT_UNREAD_AGENT_MESSAGES_COUNT = "GOT_UNREAD_AGENT_MESSAGES_COUNT";
export const MARK_AGENT_MESSAGES_READ = "MARK_AGENT_MESSAGES_READ";
export const REQUEST_REMOTE_CONFIG = "REQUEST_REMOTE_CONFIG";
export const GOT_REMOTE_CONFIG = "GOT_REMOTE_CONFIG";

export const requestAppInit = () => ({type: REQUEST_APP_INIT});
export const doneAppInit = () => ({type: DONE_APP_INIT});

export const viewedMIAN = (mian: MIAN) => ({type: VIEWED_MIAN, mian});
export const clickedMIAN = (mian: MIAN) => ({type: CLICKED_MIAN, mian});

export const appFocused = (tryPush: boolean) => ({type: APP_FOCUSED, tryPush});
export const gotAppFocused = result => ({type: GOT_APP_FOCUSED, result});

export const requestDashboard = () => ({type: REQUEST_DASHBOARD});
export const gotDashboard = result => ({type: GOT_DASHBOARD, result});
export const requestLogin = (forceVerify: boolean) => ({type: REQUEST_LOGIN, forceVerify});
export const requestedLogin = () => ({type: REQUESTED_LOGIN});
export const gotLogin = result => ({type: GOT_LOGIN, result});
export const requestProfile = () => ({type: REQUEST_PROFILE});
export const requestedProfile = () => ({type: REQUESTED_PROFILE});
export const gotProfile = result => ({type: GOT_PROFILE, result});

export const requestOnboarding = () => ({type: REQUEST_ONBOARDING});
export const requestedOnboarding = () => ({type: REQUESTED_ONBOARDING});
export const gotOnboarding = result => ({type: GOT_ONBOARDING, result});

export const requestCourseList = () => ({type: REQUEST_COURSE_LIST});
export const requestedCourseList = () => ({type: REQUESTED_COURSE_LIST});
export const gotCourseList = result => ({type: GOT_COURSE_LIST, result});

export const requestCourseSearchOptions = (courseListMustReload: boolean = true) => ({type: REQUEST_COURSE_SEARCH_OPTIONS, courseListMustReload});
export const requestedCourseSearchOptions = () => ({type: REQUESTED_COURSE_SEARCH_OPTIONS});
export const gotCourseSearchOptions = result => ({type: GOT_COURSE_SEARCH_OPTIONS, result});
export const setApplicationFormChangedSinceCourseLoad = recentChangesPresent => ({type: SET_APPLICATION_FORM_CHANGED_SINCE_COURSE_LOAD, recentChangesPresent});
export const setApplicationFormChangedSinceProfileLoad = recentChangesPresent => ({type: SET_APPLICATION_FORM_CHANGED_SINCE_PROFILE_LOAD, recentChangesPresent});
export const setProfileDetailsChangedSinceApplicationFormLoad = recentChangesPresent => ({type: SET_PROFILE_DETAILS_CHANGED_SINCE_APPLICATION_FORM_LOAD, recentChangesPresent});

export const requestPopularSubjects = () => ({type: REQUEST_POPULAR_SUBJECTS});
export const gotPopularSubjects = result => ({type: GOT_POPULAR_SUBJECTS, result});

export const setCourseSearchQuery = query => ({type: SET_COURSE_SEARCH_QUERY, query});
export const setCourseSearchProviderId = providerID => ({type: SET_COURSE_SEARCH_PROVIDER_ID, providerID});
export const setCourseSearchLos = los => ({type: SET_COURSE_SEARCH_LOS, los});
export const setCourseSearchStudyMode = studyMode => ({type: SET_COURSE_SEARCH_STUDY_MODE, studyMode});
export const setCourseSearchEnglishLanguageRequirements = filterByEnglishLanguageRequirements => ({type: SET_COURSE_SEARCH_ENGLISH_LANGUAGE_REQUIREMENTS, filterByEnglishLanguageRequirements});

export const requestCourseFavouritesList = () => ({type: REQUEST_COURSE_FAVOURITES_LIST});
export const gotCourseFavouritesList = result => ({type: GOT_COURSE_FAVOURITES_LIST, result});

export const requestSelectedCourse = () => ({type: REQUEST_SELECTED_COURSE});
export const gotSelectedCourse = result => ({type: GOT_SELECTED_COURSE, result});

export const requestFundingList = () => ({type: REQUEST_FUNDING_LIST});
export const requestedFundingList = () => ({type: REQUESTED_FUNDING_LIST});
export const gotFundingList = result => ({type: GOT_FUNDING_LIST, result});

export const requestAccommodationList = () => ({type: REQUEST_ACCOMMODATION_LIST});
export const requestedAccommodationList = () => ({type: REQUESTED_ACCOMMODATION_LIST});
export const gotAccommodationList = result => ({type: GOT_ACCOMMODATION_LIST, result});

export const requestAccommodationFavourites = () => ({type: REQUEST_ACCOMMODATION_FAVOURITES});
export const gotAccommodationFavourites = result => ({type: GOT_ACCOMMODATION_FAVOURITES, result});

export const requestAccommodationDirections = (fromLat, fromLng, toLat, toLng) => ({type: REQUEST_ACCOMMODATION_DIRECTIONS, fromLat, fromLng, toLat, toLng});
export const requestedAccommodationDirections = (fromLat, fromLng, toLat, toLng) => ({type: REQUESTED_ACCOMMODATION_DIRECTIONS, fromLat, fromLng, toLat, toLng});
export const gotAccommodationDirections = (fromLat, fromLng, toLat, toLng, result) => ({type: GOT_ACCOMMODATION_DIRECTIONS, fromLat, fromLng, toLat, toLng, result});

export const requestCourseDetails = (id) => ({type: REQUEST_COURSE_DETAILS, id});
export const requestedCourseDetails = (id) => ({type: REQUESTED_COURSE_DETAILS, id});
export const gotCourseDetails = (id, result) => ({type: GOT_COURSE_DETAILS, result, id});

export const requestCourseFundingDetails = (courseID) => ({type: REQUEST_COURSE_FUNDING_DETAILS, courseID});
export const requestedCourseFundingDetails = (courseID) => ({type: REQUESTED_COURSE_FUNDING_DETAILS, courseID});
export const gotCourseFundingDetails = (courseID, result) => ({type: GOT_COURSE_FUNDING_DETAILS, result, courseID});

export const requestJobsConfig = () => ({type: REQUEST_JOBS_CONFIG});
export const gotJobsConfig = result => ({type: GOT_JOBS_CONFIG, result});

export const setJobSearchFilters = (filters) => ({type: SET_JOB_SEARCH_FILTERS, filters});

export const requestJobList = () => ({type: REQUEST_JOB_LIST});
export const requestedJobList = (filters) => ({type: REQUESTED_JOB_LIST, filters});
export const gotJobList = (filters, result) => ({type: GOT_JOB_LIST, filters, result});

export const requestJobDetails = (id) => ({type: REQUEST_JOB_DETAILS, id});
export const requestedJobDetails = (id) => ({type: REQUESTED_JOB_DETAILS, id});
export const gotJobDetails = (id, result) => ({type: GOT_JOB_DETAILS, id, result});

export const requestJobsFavourites = () => ({type: REQUEST_JOBS_FAVOURITES});
export const gotJobsFavourites = result => ({type: GOT_JOBS_FAVOURITES, result});

export const requestCourseProviderDetails = id => ({type: REQUEST_COURSE_PROVIDER_DETAILS, id});
export const requestedCourseProviderDetails = id => ({type: REQUESTED_COURSE_PROVIDER_DETAILS, id});
export const gotCourseProviderDetails = (id, result) => ({type: GOT_COURSE_PROVIDER_DETAILS, result, id});

export const requestUniversityList = () => ({type: REQUEST_UNIVERSITY_LIST});
export const gotUniversityList = result => ({type: GOT_UNIVERSITY_LIST, result});

export const requestUniversityFavourites = () => ({type: REQUEST_UNIVERSITY_FAVOURITES});
export const gotUniversityFavourites = result => ({type: GOT_UNIVERSITY_FAVOURITES, result});

export const requestPropertyDetails = uid => ({type: REQUEST_PROPERTY_DETAILS, uid});
export const requestedPropertyDetails = uid => ({type: REQUESTED_PROPERTY_DETAILS, uid});
export const gotPropertyDetails = (uid, result) => ({type: GOT_PROPERTY_DETAILS, result, uid});

export const setAccommodationCampus = (campusID) => ({type: SET_ACCOMMODATION_CAMPUS, campusID});

export const requestApplicationInfo = () => ({type: REQUEST_APPLICATION_INFO});
export const gotApplicationInfo = result => ({type: GOT_APPLICATION_INFO, result});

export const requestAccApplicationInfo = () => ({type: REQUEST_ACC_APPLICATION_INFO});
export const gotAccApplicationInfo = result => ({type: GOT_ACC_APPLICATION_INFO, result});

export const requestUniApplicationList = () => ({type: REQUEST_UNI_APPLICATION_LIST});
export const gotUniApplicationList = result => ({type: GOT_UNI_APPLICATION_LIST, result});

export const requestUniApplication = uid => ({type: REQUEST_UNI_APPLICATION, uid});
export const requestedUniApplication = uid => ({type: REQUESTED_UNI_APPLICATION, uid});
export const gotUniApplication = (uid, result) => ({type: GOT_UNI_APPLICATION, uid, result});

export const autoSelectApplicationDocuments = (applicationID) => ({type: AUTO_SELECT_APPLICATION_DOCUMENTS, applicationID});

export const requestAccommodationApplicationList = () => ({type: REQUEST_ACCOMMODATION_APPLICATION_LIST});
export const gotAccommodationApplicationList = result => ({type: GOT_ACCOMMODATION_APPLICATION_LIST, result});

export const setApplicationFormAnswer = (questionID, answer) => ({type: SET_APPLICATION_FORM_ANSWER, questionID, answer});
export const didSetApplicationFormAnswer = (questionID, answer) => ({type: DID_SET_APPLICATION_FORM_ANSWER, questionID, answer});

export const moveApplicationToDraft = applicationID => ({type: MOVE_APPLICATION_TO_DRAFT, applicationID});
export const deleteApplication = applicationID => ({type: DELETE_APPLICATION, applicationID});

export const applyForCourse = (optionID) => ({type: APPLY_FOR_COURSE, optionID});
export const applyForTenancy = tenancyID => ({type: APPLY_FOR_TENANCY, tenancyID});

export const selectDocumentForApplication = (applicationID, document, isSelected) => ({type: SELECT_DOCUMENT_FOR_APPLICATION, applicationID, document, isSelected});

export const addApplicationToCart = applicationID => ({type: ADD_APPLICATION_TO_CART, applicationID});
export const sendAccommodationApplication = (tenancyID, applicationID) => ({type: SEND_ACCOMMODATION_APPLICATION, tenancyID, applicationID});
export const cancelAccommodationApplicationRedirect = (accApplicationID) => ({type: CANCEL_ACCOMMODATION_APPLICATION_REDIRECT, accApplicationID});
export const setShowApplicationFormErrors = (applicationID, showErrors) => ({type: SET_SHOW_APPLICATION_FORM_ERRORS, applicationID, showErrors});

export const requestApplicationPaymentRequiredInfo = numberApplications => ({type: REQUEST_APPLICATION_PAYMENT_REQUIRED_INFO, numberApplications});
export const requestedApplicationPaymentRequiredInfo = () => ({type: REQUESTED_APPLICATION_PAYMENT_REQUIRED_INFO});
export const gotApplicationPaymentRequiredInfo = result => ({type: GOT_APPLICATION_PAYMENT_REQUIRED_INFO, result});

export const sendUniApplications = ids => ({type: SEND_UNI_APPLICATIONS, ids});
export const submitFormFillerCredentials = (applicationID, username, password) => ({type: SUBMIT_FORMFILLER_ACCOUNT_CREDENTIALS, applicationID, username, password});
export const completeApplicationSubmission = applicationID => ({type: COMPLETE_APPLICATION_SUBMISSION, applicationID});

export const setProfileField = (questionID, answer) => ({type: SET_PROFILE_FIELD, questionID, answer});
export const didSetProfileField = () => ({type: DID_SET_PROFILE_FIELD});
export const setOnboardingField = (questionID, answer) => ({type: SET_ONBOARDING_FIELD, questionID, answer});
export const didSetOnboardingField = () => ({type: DID_SET_ONBOARDING_FIELD});

export const setCourseFave = (course, fave) => ({type: SET_COURSE_FAVE, course, fave});
export const setAccommodationFave = (property, fave) => ({type: SET_ACCOMMODATION_FAVE, property, fave});
export const setJobFave = (job, fave) => ({type: SET_JOB_FAVE, job, fave});
export const setUniversityFave = (university, fave) => ({type: SET_UNIVERSITY_FAVE, university, fave});

export const requestScholarshipDetails = (uid, version) => ({type: REQUEST_SCHOLARSHIP_DETAILS, uid, version});
export const requestedScholarshipDetails = (uid, version) => ({type: REQUESTED_SCHOLARSHIP_DETAILS, uid, version});
export const gotScholarshipDetails = (uid, version, result) => ({type: GOT_SCHOLARSHIP_DETAILS, uid, version, result});

export const moveScholarship = (scholarshipID, version, tab) => ({type: MOVE_SCHOLARSHIP, scholarshipID, version, tab});
export const reportScholarship = (scholarshipID, version, text) => ({type: REPORT_SCHOLARSHIP, scholarshipID, version, text});

export const requestSettings = () => ({type: REQUEST_SETTINGS});
export const gotSettings = result => ({type: GOT_SETTINGS, result});
export const setSettingsField = (field, value) => ({type: SET_SETTINGS_FIELD, field, value});

export const sendFeedback = (text: string) => ({type: SEND_FEEDBACK, text});

export const setLanguage = (languageID: string) => ({type: SET_LANGUAGE, languageID});

export const setAccommodationFilters = (filters) => ({type: SET_ACCOMMODATION_FILTERS, filters});
export const commitAccommodationFilters = (filters) => ({type: COMMIT_ACCOMMODATION_FILTERS, filters});
export const gotAccommodationCount = result => ({type: GOT_ACCOMMODATION_COUNT, result});

export const setSelectedCourse = course => ({type: SET_SELECTED_COURSE, course});
export const deselectCourse = () => ({type: DESELECT_COURSE});

export const deleteDocument = document => ({type: DELETE_DOCUMENT, document});
export const viewDocument = document => ({type: VIEW_DOCUMENT, document});
export const uploadDocument = (file, fullFileName, documentTypeId) => ({type: UPLOAD_DOCUMENT, file, fullFileName, documentTypeId});
export const uploadDocumentComplete = () => ({type: UPLOAD_DOCUMENT_COMPLETE});
export const pasteDocument = (text, fullFileName, documentTypeId) => ({type: PASTE_DOCUMENT, text, fullFileName, documentTypeId});

export const viewFormDocument = (fileName: string, originalName: string) => ({type: VIEW_FORM_DOCUMENT, originalName, fileName});
export const deleteFormDocument = (questionID: string, fileName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => (
    {type: DELETE_FORM_DOCUMENT, questionID, fileName, isRecord, index, innerQuestionID}
);
export const uploadFormDocument = (questionID: string, file: string, originalName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => (
    {type: UPLOAD_FORM_DOCUMENT, questionID, file, fileName: `${Util.rn()}`, originalName, uploaded: Util.rn(), isRecord, index, innerQuestionID}
);

export const logout = () => ({type: LOGOUT});
export const changePassword = () => ({type: CHANGE_PASSWORD});
export const changeEmail = (newEmail: string) => ({type: CHANGE_EMAIL, newEmail});

export const pathChanged = (path: string) => ({type: PATH_CHANGED, path});
export const goBack = (desiredPath: string) => ({type: GO_BACK, desiredPath});

export const showToast = (text: string) => ({type: SHOW_TOAST, text});
export const showAlert = (title, message, positive, negative) => ({type: SHOW_ALERT, title, message, positive, negative});
export const dismissAlert = () => ({type: DISMISS_ALERT});

export const requestAgentInfo = (avoidReload = false) => ({type: REQUEST_AGENT_INFO, avoidReload});
export const requestedAgentInfo = () => ({type: REQUESTED_AGENT_INFO});
export const gotAgentInfo = result => ({type: GOT_AGENT_INFO, result});

export const changeAgent = agentID => ({type: CHANGE_AGENT, agentID});
export const removeAgent = () => ({type: CHANGE_AGENT, agentID: null});
export const reportAgent = (report: string) => ({type: REPORT_AGENT, report});

export const sendAgentMessage = message => ({type: SEND_AGENT_MESSAGE, message});
export const sendAgentFile = file => ({type: SEND_AGENT_FILE, file});
export const sendAgentCourse = (courseID, courseName, providerName) => ({type: SEND_AGENT_COURSE, courseID, courseName, providerName});

export const closeIan = () => ({type: CLOSE_IAN});

export const serverSidePopupOk = id => ({type: OK_SERVER_SIDE_POPUP, id});
export const serverSidePopupCancel = id => ({type: CANCEL_SERVER_SIDE_POPUP, id});

export const didSubscribeFunding = () => ({type: DID_SUBSCRIBE_FUNDING});

export const turnOffWelcomePopup = () => ({type: TURN_OFF_WELCOME_POPUP});

export const openDeepLink = (link: DeepLink) => ({type: OPEN_DEEP_LINK, link});

export const setAgentChatOpen = (isOpen: boolean) => ({type: SET_AGENT_CHAT_OPEN, isOpen});

export const requestSubjectSearchOptions = (searchSeed: string) => ({type: REQUEST_SUBJECT_SEARCH_OPTIONS, searchSeed});
export const gotSubjectSearchOptions = (result: [string]) => ({type: GOT_SUBJECT_SEARCH_OPTIONS, result});

export const requestUnreadMessagesCount = () => ({type: REQUEST_UNREAD_AGENT_MESSAGES_COUNT});
export const requestedUnreadMessagesCount = () => ({type: REQUESTED_UNREAD_AGENT_MESSAGES_COUNT});
export const gotUnreadMessagesCount = result => ({type: GOT_UNREAD_AGENT_MESSAGES_COUNT, result});
export const requestRemoteConfig = () => ({type: REQUEST_REMOTE_CONFIG});
export const gotRemoteConfig = result => ({type: GOT_REMOTE_CONFIG, result});