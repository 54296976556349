import {EventTypes} from "../../model/AnalyticsEvents";
import {AnalyticsService} from "./AnalyticsService";

const windowObject = window as any;
windowObject.dataLayer = windowObject.dataLayer || [];

function gtag(...args: any[]) {
    windowObject.dataLayer.push(arguments);
}

export const initialiseGoogleAnalytics = () => {
    gtag("js", new Date());
    gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
};

export class GoogleAnalyticsService implements AnalyticsService {

    logEvent<T>(event: EventTypes<T>): void {
        const params = {event_category: event.category, ...event.params};
        gtag("event", event.eventName, params);
    }

    logPageView(page: string): void {
        gtag("page_view", page, {page});
    }
}