import {DateEntry, FormAnswerType} from "../../util/form/FormItem";
import {MouseEventHandler, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import {FormAnswerSetter, UnansweredQuestionLabel} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {TextField} from "@mui/material";
import Dimens from "../../theme/Dimens";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const FormDateView = ({item, startAnswer, handleLink, answer, setAnswer, validate}: { item: DateEntry, startAnswer: () => void, handleLink: MouseEventHandler<HTMLAnchorElement>, answer: FormAnswerType, setAnswer: FormAnswerSetter, validate: boolean }) => {

    const [text, setText] = useState<Moment | null>(null);

    useEffect(() => {
        if (answer != null && answer !== "") {
            setText(moment(answer, "DD/MM/yyyy"));
        }
    }, [answer]);

    useEffect(() => {
        if (text != null && text.isValid()) {
            const newAnswer = text.format("DD/MM/yyyy");
            if (newAnswer !== answer) {
                setAnswer(newAnswer);
            }
        }
    }, [text]);

    const isError = validate && item.required && !item.numberAnswered(answer);
    const [errorFromInput, setErrorFromInput] = useState<string | null>(null);

    return (
        <div id={item.id}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormQuestionText item={item} handleLink={handleLink}/>
                {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
                <FormQuestionInfoText item={item} handleLink={handleLink}/>
                <div onFocus={startAnswer}>
                    <DatePicker
                        inputFormat="DD/MM/yyyy"
                        value={text}
                        onChange={date => setText(date)}
                        onYearChange={date => setText(date)}
                        minDate={item.minDate()}
                        maxDate={item.maxDate()}
                        onError={error => setErrorFromInput(error as string)}
                        defaultCalendarMonth={item.initialFocusDate()}
                        renderInput={(props) => <TextField style={{marginTop: Dimens.HalfMargin}} size="small" color="secondary" error={isError} {...props} />}
                    />
                </div>
                {/* If there's an error from the input then we don't need to show our own */}
                {isError && (errorFromInput == null || errorFromInput === "") && <UnansweredQuestionLabel/>}
            </LocalizationProvider>
        </div>
    );
};

export default FormDateView;
