import {useDispatch, useSelector} from "react-redux";
import * as selectors from "../../redux/selectors";
import ApiCallStateDependant from "../../views/ApiCallStateDependant";
import {HomeScreenItem, HomeScreenItemContent} from "../../model/HomeScreenItem";
import Dimens from "../../theme/Dimens";
import {GFThemeProvider} from "../../theme/GFThemeProvider";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {homeTheme} from "../../theme/Theme";
import {sections} from "../../Tab";
import HomeScreenBannerView from "./HomeScreenBannerView";
import * as homeScreenItemsSlice from "../../redux/slices/homeScreenItems";
import HomeScreenLinkCardView from "./HomeScreenLinkCardView";
import Colours from "../../theme/Colours";
import HomeScreenCourseListView from "./HomeScreenCourseListView";
import HomeScreenProgressIndicatorView from "./HomeScreenProgressIndicatorView";
import HomeScreenScholarshipsListView from "./HomeScreenScholarshipsListView";
import TitleBar from "../../views/TitleBar";
import {useEffect} from "react";
import * as Assets from "../../Assets";
import TagLine from "../../components/TagLine";
import * as Str from "../../strings/Str";

const HomeScreenItemContentView = ({content}: { content: HomeScreenItemContent }) => {
    switch (content.type) {
        case "Banner":
            return <HomeScreenBannerView banner={content}/>;
        case "LinkCard":
            return <HomeScreenLinkCardView card={content}/>;
        case "CourseFavouriteList":
            return <HomeScreenCourseListView data={content}/>;
        case "ScholarshipsList":
            return <HomeScreenScholarshipsListView data={content}/>;
        case "ProgressIndicator":
            return <HomeScreenProgressIndicatorView data={content}/>;
        default:
            return null;
    }
};

const HomeScreenHeader = () => {
    const backgroundImage = require("../../" + Assets.UCAS_LOGIN_BACKGROUND);
    return (
        <div style={{position: "relative", marginBottom: 80, flex: 1, backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vw", minHeight: "250px", maxHeight: "400px"}}>
            <div style={{display:"flex", flexDirection: "row", justifyContent:"center", alignItems: "center", fontSize: "50px", fontWeight: "bold", color: "#FFFFFF", padding:"40px", textAlign: "left"}}>
                <TagLine />
                <h2 style={{flex: 1, letterSpacing: 0, lineHeight: 1, textTransform: "uppercase", fontFamily: "Oswald"}}>
                    {/* TODO: convert to string */}
                    {Str.web_heading()}
                </h2>
                <div style={{flex: 1, textAlign: "center"}}>
                    <img style={{maxHeight: "400px", position: "absolute", top: 30}} src={require("../../" + Assets.LUGGAGE_TAG)} />
                </div>
            </div>
        </div>
    );
};

const HomeScreen = ({items}: { items: HomeScreenItem[] }) => {
    const columnCount = 8;
    const gridTemplateColumns = (new Array(columnCount)).fill("1fr").join(" ");
    return (
        <div style={{background: Colours.HomeScreenBackground, width: "100%", height: "100vh", display: "flex", flexDirection: "column"}}>
            <TitleBar currentSection={sections.Home}/>
            <div style={{backgroundColor: "#706CB2", height: "8px"}}>&nbsp;</div>
            <div style={{overflowY: "auto"}}>
                <HomeScreenHeader />
                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                    <div style={{display: "grid", gridTemplateColumns, gap: Dimens.StandardMargin, maxWidth: "40cm", padding: Dimens.StandardMargin, boxSizing: "border-box", width: "100%"}}>
                        {items.map((item, index) => (
                            <div key={index} style={{gridColumn: "span " + item.columnSpan, aspectRatio: item.columnSpan + "/" + item.rowSpan, boxSizing: "border-box", width: "100%"}}>
                                <HomeScreenItemContentView content={item.content}/>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const Container = () => {
    const data = useSelector(selectors.homeScreenItems);
    const dispatch = useDispatch();

    // We must refresh the homescreen every time it is visited so it'll have the latest data
    useEffect(() => {
        dispatch(homeScreenItemsSlice.actions.request());
    }, []);

    return (
        <MuiThemeProvider theme={homeTheme}>
            <GFThemeProvider value={sections.Main}>
                <ApiCallStateDependant apiCallState={data} makeChildren={(homeScreenItems) => (
                    <HomeScreen items={homeScreenItems}/>
                )}/>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default Container;
