import {Info, ShoppingCartOutlined, WarningAmber} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useAlert} from "../../../Alerts";
import {APPLICATION_FORMS, APPLICATION_POST_SENT_STATUS, COURSE_APPLICATION_STATUS} from "../../../Applications";
import {useApplicationNoLongerAvailableAlert} from "../../../components/ApplicationNoLongerAvailableAlert";
import {PrimaryButton, SecondaryButton, SecondaryFilledButton} from "../../../components/Buttons";
import HTMLString from "../../../components/HTMLString";
import {TextWithBoldParts} from "../../../components/TextWithBoldParts";
import {gfWindow} from "../../../model/GFWindow";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import Colours from "../../../theme/Colours";
import Dimens from "../../../theme/Dimens";
import {useControlColourName} from "../../../theme/Theme";
import {eventStopper} from "../../../util/EventStopper";
import * as FirebaseUtil from "../../../util/FirebaseUtil";
import DataDependant from "../../../views/DataDependant";
import {SmallTitle, Subtitle} from "../../../views/Text";
import ApplicationCredentialsCapturePopup from "./ApplicationCredentialsCapturePopup";
import ApplicationFinalCheckPopup from "./ApplicationFinalCheckPopup";
import ApplicationInfoPopup from "./ApplicationInfoPopup";
import ApplicationPurchasingPopup from "./ApplicationPurchasingPopup";

const listBorder = "1px solid #ccc";

const getPostSentStatusesText = application => {
    if (!application) return Str.unable_determine_status();

    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.awaitingFormFilling) return Str.awaiting_preparation();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.awaitingFinalCheck) return Str.awaiting_final_check();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.complete) return Str.submitted_and_with_provider();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.manualRegistrationRequired) return Str.requires_manual_registration();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.awaitingAgentSubmission) return Str.submitted_and_with_agent();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername) return Str.partial_manual_registration_status();
    if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringPassword) return Str.partial_manual_registration_status();

    return Str.unable_determine_status();
};

const shouldShowNoLongerAcceptingWarning = application => !application.accepting && (application.status === COURSE_APPLICATION_STATUS.draft || application.status === COURSE_APPLICATION_STATUS.cart);

const ApplicationListItemButtons = props => {
    const {application} = props;

    const buttonStyle = {margin: 8, height: 36};

    const deleteButton = <SecondaryButton style={buttonStyle} onClick={eventStopper(() => props.onDelete(application))}>{Str.delet()}</SecondaryButton>;

    if (shouldShowNoLongerAcceptingWarning(application)) {
        const searchButton = <SecondaryFilledButton style={buttonStyle} component={Link} to="/courses">{Str.go_to_search()}</SecondaryFilledButton>;
        return [searchButton, deleteButton];
    } else if (application.status === COURSE_APPLICATION_STATUS.draft) {
        const editButton = <PrimaryButton style={buttonStyle} component={Link} to={props.editLink}>{Str.cont()}</PrimaryButton>;
        return [editButton, deleteButton];
    } else if (application.status === COURSE_APPLICATION_STATUS.cart) {
        const moveButton = <SecondaryButton style={buttonStyle} onClick={eventStopper(() => props.onMove(application))}>{Str.move_to_drafts()}</SecondaryButton>;
        const viewButton = <SecondaryButton style={buttonStyle} onClick={eventStopper(() => props.onView(application))}>{Str.preview()}</SecondaryButton>;
        return [viewButton, moveButton];
    } else if (application.status === COURSE_APPLICATION_STATUS.processing) {
        const finalCheckButton = <PrimaryButton style={buttonStyle} onClick={eventStopper(() => props.onPostSentJourney(application))}>{Str.check_application_button()}</PrimaryButton>;
        const registerButton = <PrimaryButton style={buttonStyle} onClick={eventStopper(() => props.onPostSentJourney(application))}>{Str.register_with_provider_button()}</PrimaryButton>;
        const viewButton = <SecondaryButton style={buttonStyle} onClick={eventStopper(() => props.onView(application))}>{Str.preview()}</SecondaryButton>;
        const partialRegisterButton = <PrimaryButton style={buttonStyle} onClick={eventStopper(() => props.onPostSentJourney(application))}>{Str.cont()}</PrimaryButton>;
        if (application.shouldHandlePostSentActions) {
            if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.awaitingFinalCheck) {
                return [finalCheckButton, viewButton];
            } else if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.manualRegistrationRequired) {
                return [registerButton, viewButton];
            } else if (application.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringPassword || application.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername) {
                return [partialRegisterButton, viewButton];
            }
        }
        return [viewButton];
    } else if (application.status === COURSE_APPLICATION_STATUS.sent) {
        const viewButton = <SecondaryButton style={buttonStyle} onClick={eventStopper(() => props.onView(application))}>{Str.preview()}</SecondaryButton>;
        return [viewButton];
    } else if (application.status === COURSE_APPLICATION_STATUS.rejected) {
        return [deleteButton];
    } else {
        return null;
    }
};

const ErrorMessage = ({children}) => (
    <div style={{margin: 4, color: "red", display: "flex", alignItems: "center", gap: 4}}>
        <Icon>warning</Icon>
        <Subtitle style={{margin: 0, color: "red"}}>{children}</Subtitle>
    </div>
);

const ApplicationListItem = ({application, showUnapprovedMessage, showUnavailableOptionMessage, showTick, ticked, setTicked, ...props}) => {

    const [alert, showNotAcceptingAlert] = useApplicationNoLongerAvailableAlert();
    const history = useNavigate();

    const defaultAction = () => {
        if (shouldShowNoLongerAcceptingWarning(application)) {
            showNotAcceptingAlert();
        } else if (application.status === COURSE_APPLICATION_STATUS.draft) {
            history(props.editLink);
        } else {
            props.onView(application);
        }
    };

    const handleTick = event => {
        setTicked(!ticked);
        event.preventDefault();
        event.stopPropagation();
    };

    const controlColourName = useControlColourName();

    const NoteForLearner = ({title, note}) => {
        return (
            <div style={{borderRadius: "0.5rem", boxShadow: "0 0 4px 0px rgba(92,92,92,0.71)", margin: "1rem", padding: "0 1rem"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Icon style={{color: "red"}}><WarningAmber/></Icon><h3 style={{marginLeft: "1rem"}}>{title}</h3>
                </div>
                <p style={{fontSize: "12px", margin: "0 0 1rem 0"}}>{note}</p>
            </div>
        );
    };

    return (
        <div style={{display: "flex", borderBottom: listBorder, flexDirection: "column"}}>
            <div style={{
                padding: "4px 16px",
                display: "flex",
                alignItems: "center",
                background: shouldShowNoLongerAcceptingWarning(application) ? Colours.DisabledRowBackground : ""
            }}
                 className="noSelect hoverable" onClick={defaultAction}>
                {showTick && <Checkbox color={controlColourName} checked={ticked} onClick={handleTick} style={{marginRight: 8}}
                                       disabled={!application.approvedToSend}/>}
                {alert}
                <img alt="" style={{width: 64, height: 64}} src={application.course.providerIconUrl}/>
                <div style={{flex: 1, margin: 16}}>
                    <p style={{margin: 4, fontWeight: 700}}>{application.course.title}</p>
                    <Subtitle style={{margin: 4}}>{application.course.providerName}</Subtitle>
                    <Subtitle style={{margin: 4, whiteSpace: "pre-wrap"}}>{application.option.info}</Subtitle>
                    {showUnapprovedMessage && !application.approvedToSend && !shouldShowNoLongerAcceptingWarning(application) && <ErrorMessage>{Str.approval_needed_message()}</ErrorMessage>}
                    {showUnavailableOptionMessage && shouldShowNoLongerAcceptingWarning(application) && <ErrorMessage>{Str.course_no_longer_available_for_application()}</ErrorMessage>}
                    {application.status === COURSE_APPLICATION_STATUS.cart && application.mayChargeFees &&
                        <Subtitle style={{margin: 4, display: "inline-block", paddingLeft: 8, paddingRight: 8, background: Colours.RowHighlight}}>
                            {Str.university_may_charge_independently()}</Subtitle>
                    }
                </div>
                <div style={{display: "flex", flexDirection: "column", width: 200}}>
                    {application.status === COURSE_APPLICATION_STATUS.processing && (
                        <p><span style={{fontWeight: 700}}>Status:</span><br/>{getPostSentStatusesText(application)}</p>
                    )}
                    <ApplicationListItemButtons application={application} onView={props.onView} {...props}/>
                </div>
            </div>
            {application.learnerFacingNotes != null && <NoteForLearner title={application.learnerNotesHeader} note={application.learnerFacingNotes}/>}
        </div>
    );
};

const EmptyCartView = () => {
    const emptyApplicationCartColour = Colours.EmptyApplicationCartImage;
    const emptyApplicationTextColour = Colours.EmptyApplicationCartText;

    return (
        <div style={{margin: 32, height: "calc(100% - 64px)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <SmallTitle style={{color: emptyApplicationTextColour}}>{Str.no_complete_applications_top()}</SmallTitle>
            <ShoppingCartOutlined style={{margin: 32, color: emptyApplicationCartColour, fontSize: 240}}/>
            <SmallTitle style={{color: emptyApplicationTextColour}}>{Str.no_complete_applications_bottom(Str.app_name())}</SmallTitle>
        </div>
    );
};

const EmptyListView = ({isCart, emptyText}) => {
    if (isCart) return <EmptyCartView/>;
    return (
        <div style={{margin: 32, height: "calc(100% - 64px)", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <SmallTitle>{Str.no_applications(emptyText)}</SmallTitle>
        </div>
    );
};

const ApplicationList = ({applications, makeEditLink, showUnapprovedMessage, showUnavailableOptionMessage, showTicks, checkTicked, setTicked, ...props}) => {
    return applications.map(a => <ApplicationListItem key={a.id}
                                                      showTick={showTicks}
                                                      showUnavailableOptionMessage={showUnavailableOptionMessage}
                                                      showUnapprovedMessage={showUnapprovedMessage}
                                                      ticked={checkTicked(a)}
                                                      setTicked={t => setTicked(a, t)}
                                                      application={a}
                                                      editLink={makeEditLink(a)} {...props}/>);
};

const ListHeader = ({cartExplanatoryText, explanatoryTextSubString, handleSend, sendButtonText, status}) => {
    if (status !== COURSE_APPLICATION_STATUS.cart && status !== COURSE_APPLICATION_STATUS.sent) return null;
    return (
        <div style={{textAlign: "center", padding: Dimens.DoubleMargin, borderBottom: listBorder}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Info/>
                <p style={{margin: 0, marginLeft: Dimens.StandardMargin}}>
                    {status === COURSE_APPLICATION_STATUS.cart && <TextWithBoldParts text={cartExplanatoryText} boldSubString={explanatoryTextSubString}/>}
                    {status === COURSE_APPLICATION_STATUS.sent && <HTMLString string={Str.sent_explanatory_text()}/>}
                </p>
            </div>
            {status === COURSE_APPLICATION_STATUS.cart && <PrimaryButton style={{marginTop: Dimens.DoubleMargin}} onClick={handleSend}>{sendButtonText}</PrimaryButton>}
        </div>
    );
};

const Container = ({
                       applications,
                       promotion,
                       onMoveApplicationToDraft,
                       onDeleteApplication,
                       makeEditLink,
                       emptyText,
                       status,
                       onSubmitCredentials,
                       onCompleteApplication,
                       requestApplicationPaymentRequiredInfo
                   }) => {

    const isCart = status === COURSE_APPLICATION_STATUS.cart;
    const showUnavailableOptionMessage = status === COURSE_APPLICATION_STATUS.draft || status === COURSE_APPLICATION_STATUS.cart;

    const applicationList = useMemo(() => applications?.payload ?? [], [applications]);
    const promotionInfo = promotion?.payload ?? [];

    const [alert, showAlert] = useAlert();

    const [viewingApplicationID, setViewingApplicationID] = useState(null);
    const viewingApplication = applicationList.filter(e => e.id === viewingApplicationID)[0];

    const [tickedIDs, setTickedIDs] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [hasAutoTicked, setHasAutoTicked] = useState(false);

    const needsCredentials = selectedApplication != null && selectedApplication.postSentStatus === APPLICATION_POST_SENT_STATUS.manualRegistrationRequired;

    const needsPartialCredentials = selectedApplication != null &&
        (selectedApplication.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername
            || selectedApplication.postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringPassword);

    const needsFinalReview = selectedApplication && selectedApplication.postSentStatus === APPLICATION_POST_SENT_STATUS.awaitingFinalCheck;

    const cartExplanatoryText = promotionInfo.promotionDetails ?? Str.cart_explanatory_text();
    const explanatoryTextSubString = promotionInfo.promotionDetailsSubStringToMakeBold ?? null;

    const sendButtonText = promotionInfo.promotionButtonText ?? Str.purchase();

    // Tick all tickable applications to begin with
    useEffect(() => {
        if (!hasAutoTicked) {
            setTickedIDs(applicationList.filter(a => a.accepting && a.approvedToSend).map(e => e.id));
            setHasAutoTicked(true);
        }
    }, [applicationList, hasAutoTicked]);

    const setTicked = (app, ticked) => {
        if (!ticked) {
            setTickedIDs(tickedIDs.filter(e => e !== app.id));
        } else if (app.accepting) {
            setTickedIDs([...tickedIDs, app.id]);
        } else {
            showAlert(Str.uni_not_accepting_apps_apologies(), Str.no_longer_accepting_apps(), Str.okay(), "");
        }
    };

    const checkTicked = app => tickedIDs.includes(app.id);

    const handleSend = () => {
        if (FirebaseUtil.isAgent()) {
            showAlert(Str.only_learners_can_perform(), Str.only_learners_can_perform_exp(), Str.okay());
            return;
        }
        if (tickedIDs.length === 0) {
            showAlert(Str.send_application(), Str.please_select_apps(), Str.okay());
            return;
        }
        requestApplicationPaymentRequiredInfo(tickedIDs.length);
        setIsSending(true);
    };

    const onDelete = application => {
        showAlert(Str.delete_application(), Str.confirm_delete_application(), Str.delet(), Str.cancel(), () => onDeleteApplication(application));
    };

    const onMove = application => {
        if (FirebaseUtil.isAgent()) {
            showAlert(Str.only_learners_can_perform(), Str.only_learners_can_perform_exp(), Str.okay());
            return;
        }
        showAlert(Str.move_to_drafts(), Str.move_to_drafts_confirm(), Str.move_to_drafts(), Str.cancel(), () => onMoveApplicationToDraft(application));
    };

    const openPostSentJourney = application => {
        if (FirebaseUtil.isAgent()) {
            showAlert(Str.only_learners_can_perform(), Str.only_learners_can_perform_exp(), Str.okay());
            return;
        }
        setSelectedApplication(application);
    };

    const completeApplication = () => {
        if (FirebaseUtil.isAgent()) {
            showAlert(Str.only_learners_can_perform(), Str.only_learners_can_perform_exp(), Str.okay());
            return;
        }
        onCompleteApplication(selectedApplication);
        setSelectedApplication(null);
    };

    const tickedApplications = tickedIDs.map(id => applicationList.find(a => a.id === id));

    return (
        <DataDependant data={applications}>
            {isSending && <ApplicationPurchasingPopup onClose={() => setIsSending(false)} tickedApplications={tickedApplications}/>}
            {alert}
            {selectedApplication && needsFinalReview && (
                <ApplicationFinalCheckPopup onSubmit={completeApplication} onClose={() => setSelectedApplication(null)} application={selectedApplication}
                                            username={selectedApplication.username} password={selectedApplication.password}/>
            )}
            {selectedApplication && needsCredentials && (
                <ApplicationCredentialsCapturePopup onClose={() => setSelectedApplication(null)} application={selectedApplication} onSubmit={onSubmitCredentials}/>
            )}
            {selectedApplication && needsPartialCredentials && (
                <ApplicationCredentialsCapturePopup onClose={() => setSelectedApplication(null)} application={selectedApplication} onSubmit={onSubmitCredentials}/>
            )}
            {viewingApplication && (
                <ApplicationInfoPopup application={viewingApplication} onClose={() => setViewingApplicationID(null)} applicationForm={APPLICATION_FORMS.university}/>
            )}
            <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                {applicationList.length > 0 && (
                    <ListHeader status={status}
                                cartExplanatoryText={cartExplanatoryText}
                                explanatoryTextSubString={explanatoryTextSubString}
                                handleSend={handleSend}
                                sendButtonText={sendButtonText}/>
                )}
                <div style={{overflowY: "auto", flex: 1}}>
                    {applicationList.length === 0 && <EmptyListView emptyText={emptyText} isCart={isCart}/>}
                    {applicationList.length > 0 && (
                        <ApplicationList emptyText={emptyText} applications={applicationList} onDelete={onDelete} onMove={onMove} onView={app => setViewingApplicationID(app.id)}
                                         makeEditLink={makeEditLink} showTicks={isCart} showUnapprovedMessage={isCart && !gfWindow.isAgent} showUnavailableOptionMessage={showUnavailableOptionMessage}
                                         checkTicked={checkTicked} setTicked={setTicked} onPostSentJourney={openPostSentJourney}/>
                    )}
                </div>
            </div>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {status, emptyText}) => ({
    applications: selectors.universityApplicationsWithStatus(state, status),
    promotion: selectors.applicationPromotionInfo(state),
    makeEditLink: app => "/applications/apply/" + app.id,
    status,
    emptyText
});

export const mapDispatchToProps = dispatch => ({
    onMoveApplicationToDraft: application => dispatch(actions.moveApplicationToDraft(application.id)),
    onDeleteApplication: application => dispatch(actions.deleteApplication(application.id)),
    requestApplicationPaymentRequiredInfo: numberOfApplications => dispatch(actions.requestApplicationPaymentRequiredInfo(numberOfApplications)),
    onSubmitCredentials: (application, username, password) => dispatch(actions.submitFormFillerCredentials(application.id, username, password)),
    onCompleteApplication: application => dispatch(actions.completeApplicationSubmission(application.id))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;
