import {EventTypes} from "../../model/AnalyticsEvents";
import {AnalyticsService} from "./AnalyticsService";
import {postRequest} from "../../util/api2";

export class CustomAnalyticsService implements AnalyticsService {
    logEvent<T>(event: EventTypes<T>): void {
        const extendedEvent = {
            timestamp: Math.round(Date.now() / 1000),
            deviceID: "WEB",
            ...event
        };
        postRequest("analytics/events", {"event": JSON.stringify(extendedEvent)});
    }

    logPageView(page: string): void {
        // not implemented
    }
}