import {useEffect, useState} from "react";
import DropDownOptionWithCheck from "./DropDownOptionWithCheck";
import {Autocomplete} from "@mui/material";
import {TextField} from "@mui/material";

export type SearchOptionResultProvider = (text: string, listener: (result: string[]) => void) => void;

export interface TextFieldWithSuggestionsProps {
    id: string;
    value: string;
    onChange: (newValue: string) => void;
    getOptionsForText: SearchOptionResultProvider;
    allowAnyInput: boolean;
    error: boolean;
}

export const TextFieldWithSuggestions = ({id, value, onChange, getOptionsForText, allowAnyInput, error}: TextFieldWithSuggestionsProps) => {

    // For a given search term, what options should be available? Stored as an object. Acts as a cache
    const [optionsCache, setOptionsCache] = useState<{ [key: string]: string[] }>({});
    const [localText, setLocalText] = useState<string>("");

    const currentOptions = optionsCache[localText.trim()];
    useEffect(() => {
        const text = localText.trim();
        getOptionsForText(text, options => {
            setOptionsCache(oldCache => {
                return {...oldCache, [text]: options};
            });
        });
    }, [localText]);

    useEffect(() => {
        setLocalText(value);
    }, [value]);

    return (
        <Autocomplete id={id} options={currentOptions ?? []} onChange={(e, value) => onChange(value as string ?? "")}
                      inputValue={localText} onInputChange={(e, v) => setLocalText(v)} freeSolo={allowAnyInput}
                      renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option}/>}
                      renderInput={(params) => <TextField {...params} size="small" variant="outlined" color="secondary" error={error}/>}
        />
    );

};

export default TextFieldWithSuggestions;