export enum ApiResultState {
    Success = "success",
    Error = "error",
    Loading = "loading"
}

/**
 * Shape of api results within our redux store, having been converted from {@link ServerApiResult}
 */
export interface ApiResult<T> {
    state?: ApiResultState; // undefined means loading. TODO can we phase that out across the codebase, and explicitly say Loading?
    payload?: T;
    error?: string;
}

/**
 * Shape of JSON that comes from the server in API v1. We use this shape for all incoming api data, even for V (api2.ts converts data into this shape)
 */
export interface ServerApiResult {
    success?: boolean;
    result?: any;
    error?: string;
    errorTitle?: string;
    canRetry?: boolean;
}

export const mapResult = <OriginalType, NewType>(result: ApiResult<OriginalType>, mapper: (original: OriginalType) => NewType): ApiResult<NewType> => {
    const state = result?.state;
    if (state === ApiResultState.Success) {
        return {...result, payload: mapper(result.payload!)};
    }
    // It's not success so there is no payload so just return the same state
    // Since there's no payload, the type argument is irrelevant, so we can cast it
    return result as unknown as ApiResult<NewType>;
};

export const makeSuccessResult = <T>(payload: T): ApiResult<T> => ({state: ApiResultState.Success, payload});
