import * as Applications from "../../Applications";
import * as Property from "../../Property";
import {mapResult} from "../../util/ApiResult";
import {fetchAndCacheReducer, fetchReducer, fetchReducerWithLoading} from "../../util/reducerUtil";
import * as actions from "../actions";

export const accommodationProperties = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_ACCOMMODATION_LIST, actions.GOT_ACCOMMODATION_LIST, a => a.properties ?? []);
export const accommodationCampuses = (state = {}, action) => fetchReducer(state, action, actions.GOT_ACCOMMODATION_LIST, a => a.campuses);
export const accommodationSelectedCampusId = (state = {}, action) => fetchReducer(state, action, actions.GOT_ACCOMMODATION_LIST, a => a.selectedCampus ?? null);

export const accommodationPropertyDetails = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_PROPERTY_DETAILS, actions.REQUESTED_PROPERTY_DETAILS, r => r.property);
};

export const accommodationFavourites = (state = {}, action) => {
    if (action.type === actions.SET_ACCOMMODATION_FAVE) {
        return mapResult(state, old => {
            const {property, fave} = action;
            if (fave) {
                return [...old, property];
            } else {
                return old.filter(e => e.uid !== property.uid);
            }
        });
    }
    return fetchReducerWithLoading(state, action, actions.REQUESTED_ACCOMMODATION_FAVOURITES, actions.GOT_ACCOMMODATION_FAVOURITES, a => a.properties);
};

export const accommodationFiltersDraft = (state = Property.INITIAL_FILTERS, action) => {
    if (action.type === actions.SET_ACCOMMODATION_FILTERS) {
        return action.filters;
    }
    return state;
};

export const accommodationFilters = (state = Property.INITIAL_FILTERS, action) => {
    if (action.type === actions.COMMIT_ACCOMMODATION_FILTERS) {
        return action.filters;
    }
    return state;
};

export const accommodationFilterResultCount = (state = {}, action) => fetchReducer(state, action, actions.GOT_ACCOMMODATION_COUNT, r => r.properties);

export const accApplicationFormSections = (state = {}, action) => {
    return fetchReducer(state, action, actions.GOT_ACC_APPLICATION_INFO, res => {
        if (res == null || res.form == null) return null;
        return Applications.getSectionsFromForm(res.form);
    });
};

export const accommodationApplications = (state = {}, action) => fetchReducer(state, action, actions.GOT_ACCOMMODATION_APPLICATION_LIST, r => {
    if (r == null || r.applications == null) return null;
    return r.applications.map(a => ({...a, status: parseInt(a.status)}));
});

export const accommodationDirections = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_ACCOMMODATION_DIRECTIONS, actions.REQUESTED_ACCOMMODATION_DIRECTIONS, r => r, a => `${a.fromLat}-${a.fromLng}-${a.toLat}-${a.toLng}`);
};

export const accommodationMians = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_ACCOMMODATION_LIST, actions.GOT_ACCOMMODATION_LIST, e => e.mians);

export const reducers = {
    accommodationProperties,
    accommodationCampuses,
    accommodationSelectedCampusId,
    accommodationPropertyDetails,
    accommodationFavourites,
    accommodationFilters,
    accommodationFiltersDraft,
    accommodationFilterResultCount,
    accApplicationFormSections,
    accommodationApplications,
    accommodationDirections,
    accommodationMians
};