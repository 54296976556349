import {Tab, Tabs} from "@mui/material";
import Colours from "../theme/Colours";
import {CSSProperties} from "react";

const borderWidth = 2;
const borderRadius = 8;

const flexContainerStyle = {
    height: "100%"
};
const tabsRootStyle = {
    minHeight: 0
};

const wrapperStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 12px"
};

const selectedStyle = ({color} : {color: string}) => {
    return {
        opacity: 1,
        background: color,
        "&:hover": {
            background: color,
        }
    };
};

const tabRootStyle = ({ color, unselectedColor, selectedColor }: {color: string, unselectedColor: string, selectedColor: string}) => {
    return {
        textTransform: "none",
        font: "inherit",
        opacity: 1,
        minWidth: 0,
        display: "flex",
        alignItems: "stretch",
        borderColor: color,
        borderStyle: "solid",
        borderWidth: `${borderWidth}px ${borderWidth}px ${borderWidth}px 0`,
        color: unselectedColor,
        padding: "0px 16px",
        fontSize: 17,
        "&:hover": {
            background: color + "55"
        },
        borderLeftWidth: 0,
        "&:first-child": {
            borderLeftWidth: borderWidth,
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius
        },
        "&:last-child": {
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius
        },
        "&.Mui-selected": {
            color: selectedColor,
        }
    };
};

const SegmentedControl = ({options, startAnswer, selectedIndex, setSelectedIndex, style}: { options: string[], startAnswer: () => void, selectedIndex?: number, setSelectedIndex: (index: number) => void, style?: CSSProperties }) => {
    const rootStyle = {...tabsRootStyle, ...flexContainerStyle, ...style};
    const tabStyle = {...tabRootStyle({color: Colours.selectedTabColor, selectedColor: Colours.selectedText,unselectedColor: Colours.StandardText }), ...wrapperStyle};
    const selectedTabStyle = {...tabStyle, ...selectedStyle({color: Colours.selectedTabColor})};

    return (
        <Tabs
            sx={rootStyle}
            onFocus={startAnswer}
            style={{marginTop: 8}}
            value={selectedIndex}
            onChange={(e, v) => setSelectedIndex(v)}
            TabIndicatorProps={{style: {height: 0}}}>
            {options.map((o,index) => {
                const thisStyle = selectedIndex === index ? selectedTabStyle : tabStyle;
                return (<Tab label={o} key={index} sx={thisStyle}/>);
            })}
        </Tabs>
    );
};

export default SegmentedControl;
