import * as Assets from "./Assets";
import * as FeatureFlags from "./FeatureFlags";
import * as Str from "./strings/Str";

export interface Section {
    id: number,
    root: string,
    default: string,
    name: string,
    description: string,
    icon: string,
    colour: string,
    tertiaryColour: string,
    paletteType: string,
    actionbarName?: string,
    secondaryColour: string,
    tabBarColour: string,
    tabBarSelectedColour: string,
    tabBarUnselectedColour: string,
    colourOnPrimary: string,
    primaryButtonColor: string,
    primaryButtonForeground: string,
    secondaryButtonColor: string,
    secondaryButtonForeground: string,
    hyperlinkColour: string,
    enabled: boolean
}

export interface Sections {
    Home: Section,
    Main: Section,
    Accommodation: Section,
    Universities: Section,
    Jobs: Section,
    InformationAndGuidance: Section,
    EnglishLanguageTests: Section
}

export const sections: Sections = Object.freeze({
    Home: {
        id: 1,
        root: "",
        default: "/",
        name: Str.home(),
        description: "",
        icon: Assets.ICON_GF,
        colour: "#fff",
        tertiaryColour: "#812990",
        paletteType: "light",
        actionbarName: Str.app_name() + " " + Str.courses_scholarships(),
        secondaryColour: "#812990",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#59b5d4",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    Main: {
        id: 2,
        root: "",
        default: "/courses",
        name: Str.courses_scholarships(),
        description: Str.section_desc_gf(),
        icon: Assets.ICON_GF,
        colour: "#fff",
        tertiaryColour: "#555555",
        paletteType: "light",
        actionbarName: Str.app_name() + " " + Str.courses_scholarships(),
        secondaryColour: "#706CB2",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#59b5d4",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    Universities: {
        id: 3,
        root: "/universities",
        default: "/search",
        name: Str.universities_tab(),
        description: Str.section_desc_uni(),
        icon: Assets.ICON_UNI,
        colour: "#FFFFFF",
        paletteType: "light",
        secondaryColour: "#706CB2",
        tertiaryColour: "#555",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#59b5d4",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#FBAF17",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    Accommodation: {
        id: 4,
        root: "/accommodation",
        default: "/search",
        name: Str.digs(),
        description: Str.section_desc_acc(),
        icon: Assets.ICON_ACC,
        colour: "#FFFFFF",
        paletteType: "dark",
        secondaryColour: "#706CB2",
        tertiaryColour: "#fff",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#59b5d4",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    Jobs: {
        id: 5,
        root: "/jobs",
        default: "/search",
        name: Str.just_jobs(),
        description: Str.section_desc_jobs(),
        icon: Assets.ICON_JOBS,
        colour: "#FFFFFF",
        paletteType: "light",
        secondaryColour: "#706CB2",
        tertiaryColour: "#95c11e",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#95c11e",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    InformationAndGuidance: {
        id: 6,
        root: "/information_and_guidance",
        default: "/information",
        name: Str.information_and_guidance(),
        description: Str.htg_info(),
        icon: Assets.ICON_GF,
        colour: "#FFFFFF",
        tertiaryColour: "#fff",
        paletteType: "dark",
        secondaryColour: "#706CB2",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#fff",
        secondaryButtonColor: "#F45096",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: true
    },
    EnglishLanguageTests: {
        id: 7,
        root: "/english_language",
        default: "/information",
        name: Str.myriad_english(),
        description: Str.section_desc_english(),
        icon: Assets.ICON_MYRIAD_ENGLISH,
        colour: "#FFFFFF",
        paletteType: "dark",
        secondaryColour: "#706CB2",
        tertiaryColour: "#0074A6",
        tabBarColour: "#CDCCE4",
        tabBarSelectedColour: "#706CB2",
        tabBarUnselectedColour: "#000000",
        colourOnPrimary: "black",
        primaryButtonColor: "#706CB2",
        primaryButtonForeground: "#FFFFFF",
        secondaryButtonColor: "#0074A6",
        secondaryButtonForeground: "#FFFFFF",
        hyperlinkColour: "#59b5d4",
        enabled: FeatureFlags.ENGLISH_LANGUAGE_SECTION_ENABLED
    }
});

export const enabledSections = Object.values(sections).filter(s => s.enabled);
