import * as Str from "../strings/Str";
import {ApiResult, ApiResultState} from "../util/ApiResult";
import LoadingScreen from "./LoadingScreen";
import {ReactElement} from "react";

/**
 * If loaded then returns children, if loading then a loading indicator, if error then an error message
 */
const DataDependant = ({data, children}: { data?: ApiResult<unknown>, children: ReactElement | ReactElement[] | boolean }): ReactElement => {
    if (data?.state == null || data.state === ApiResultState.Loading) {
        return <LoadingScreen/>;
    }
    if (data.state === ApiResultState.Success) {
        return <>{children}</>;
    }
    if (data.state === ApiResultState.Error) {
        return <p>{Str.error()} {data.error}</p>;
    }
    return <>Unknown state</>;
};

export default DataDependant;
