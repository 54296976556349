import {useEffect} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../Alerts";
import {COURSE_APPLICATION_STATUS} from "../Applications";
import {PrimaryButton, SecondaryButton} from "../components/Buttons";
import {FEE_STATUS} from "../Profile";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import * as Str from "../strings/Str";

const makeTextForButton = status => {
    if (status === COURSE_APPLICATION_STATUS.draft) return Str.view();
    if (status === COURSE_APPLICATION_STATUS.cart) return Str.view();
    if (status === COURSE_APPLICATION_STATUS.processing) return Str.view();
    if (status === COURSE_APPLICATION_STATUS.sent) return Str.view();
    if (status === COURSE_APPLICATION_STATUS.rejected) return Str.view();
    return Str.apply();
};

const ApplyButton = ({status, canApply, style, onClick, userCanApplyCourses}) => {
    const hasApplication = status != null;
    if (userCanApplyCourses) {
        if (hasApplication) {
            return <SecondaryButton style={style} onClick={onClick}>{makeTextForButton(status)}</SecondaryButton>;
        } else {
            return <PrimaryButton disabled={!canApply} style={style} onClick={onClick}>{makeTextForButton(status)}</PrimaryButton>;
        }
    } else {
        return <PrimaryButton disabled={true} style={style} onClick={onClick}>{makeTextForButton(status)}</PrimaryButton>;
    }
};

const Container = ({status, applicationID, onApply, canApply, feeStatus, userCanApplyCourses, remoteConfigLoaded, fetchRemoteConfig,  ...props}) => {
    const [alert, showAlert] = useAlert();
    const history = useNavigate();

    useEffect(() => {
        if (!remoteConfigLoaded) {
            fetchRemoteConfig();
        }
    }, [remoteConfigLoaded]);

    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (status === COURSE_APPLICATION_STATUS.draft) {
            history("/applications/apply/" + applicationID);
        } else if (status === COURSE_APPLICATION_STATUS.cart) {
            history("/applications/3");
        } else if (status === COURSE_APPLICATION_STATUS.processing) {
            history("/applications/4");
        } else if (status === COURSE_APPLICATION_STATUS.sent) {
            history("/applications/5");
        } else if (status === COURSE_APPLICATION_STATUS.rejected) {
            history("/applications/6");
        } else if (canApply) {
            onApply();
        } else {
            const message = feeStatus === FEE_STATUS.home ? Str.uni_not_accepting_apps_body_home() : Str.uni_not_accepting_apps_body_int();
            showAlert(Str.uni_not_accepting_apps_apologies(), message, Str.okay());
        }
    };
    return (
        <>
            {alert}
            <ApplyButton style={undefined} status={status} canApply={canApply} userCanApplyCourses={userCanApplyCourses} {...props} onClick={onClick}/>
        </>
    );
};

const mapStateToProps = (state, {option, ...props}) => ({
    status: selectors.applicationStatus(state, option.id),
    applicationID: selectors.applicationIdForCourse(state, option.id),
    canApply: true,//TODO?
    feeStatus: selectors.currentFeeStatus(state),
    userCanApplyCourses: selectors.userCanApplyCourses(state),
    remoteConfigLoaded: selectors.remoteConfigLoaded(state),
    ...props
});

const mapDispatchToProps = (dispatch, {option}) => ({
    onApply: () => dispatch(actions.applyForCourse(option.id)),
    fetchRemoteConfig: () => dispatch(actions.requestRemoteConfig())
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;