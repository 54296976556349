import {Paper, usePrimaryColour} from "grantfairy-web-common";
import {connect} from "react-redux";
import { useParams } from "react-router-dom";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import * as Property from "../../Property";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {mapResult} from "../../util/ApiResult";
import AccApplyButton from "../../views/AccApplyButton";
import BackButton from "../../views/BackButton";
import DataDependant from "../../views/DataDependant";
import FacilitiesView from "../../views/FacilitiesView";
import ImageCarousel from "../../views/ImageCarousel";
import PropertyPrice from "../../views/PropertyPrice";
import {Subtitle, Title} from "../../views/Text";

const TenancyList = ({tenancies}) => tenancies.map(tenancy => <TenancyCard tenancy={tenancy}/>);

const TenancyCard = ({tenancy}) => (
    <div style={{display: "flex", alignItems: "center", borderBottom: "1px solid #ccc"}}>

        <div style={{flex: 1}}>
            <Subtitle>{Property.tenancyDateRange(tenancy)}</Subtitle>
            <Subtitle><PropertyPrice property={tenancy} from={false}/></Subtitle>
            <Subtitle>{Property.tenancyLengthWeeks(tenancy)}</Subtitle>
        </div>

        <AccApplyButton style={{paddingLeft: 64, paddingRight: 64}} tenancyID={tenancy.id} available={tenancy.available == 1}/>
    </div>
);


const makeDescriptionHTML = property => {
    if (property.description_translation) {
        return `${property.description_translation}<hr/>${property.description}`;
    }
    return property.description;
};

const RoomDetailsView = ({property, room, backLink}) => {
    const images = room.images.length > 0 ? room.images.map(e => e.image) : property.images.map(e => e.url);
    return (
        <div style={{display: "flex", justifyContent: "center", height: "100%"}} className="noSelect">
            <div style={{flex: 1, textAlign: "right"}}>
                <BackButton link={backLink} style={{margin: 32}}/>
            </div>
            <Paper className="contentColumn" style={{padding: 16, margin: 32, flex: 1, height: "calc(100% - 96px)", overflowY: "scroll"}}>
                <ImageCarousel primary={usePrimaryColour()} urls={images}/>

                <Title>{room.name}, {property.name}</Title>

                <SanitizedHtml html={makeDescriptionHTML(room)}/>

                <FacilitiesView facilities={Property.joinFacilities(room.facilities, property.facilities)}/>
                <Title style={{margin: 0, marginTop: 16}}>{Str.tenancy_options()}</Title>
                <TenancyList tenancies={room.tenancies}/>
            </Paper>
            <div style={{flex: 1}}/>
        </div>
    );
};

const Container = ({propertyID, room, property, backLink, getPropertyDetails}) => {
    if (room == null) {
        getPropertyDetails(propertyID);
    }
    return (
        <DataDependant data={room}>
            <RoomDetailsView room={room?.payload} property={property?.payload ?? {}} backLink={backLink}/>
        </DataDependant>
    );
};

const mapStateToProps = (state, {propertyID, roomID}) => {
    const propertyResult = selectors.accommodationPropertyDetails(state)[propertyID];
    const room = mapResult(propertyResult, property => {
        return property.rooms.filter(r => r.id === roomID)[0];
    });
    return {propertyID, room, property: propertyResult};
};

const mapDispatchToProps = dispatch => ({
    getPropertyDetails: propertyID => {
        dispatch(actions.requestPropertyDetails(propertyID));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = ({backLink}) => {
    const {propertyID, roomID} = useParams();
    const processedBackLink = backLink.replace(":propertyID", propertyID);
    return (<Connected propertyID={propertyID} roomID={roomID} backLink={processedBackLink} />);
};

export default WrappedAndConnected;