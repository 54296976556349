export function getValueOfScholarship(scholarship) {
    let amountText = scholarship.amount;
    let unitText = scholarship.units != null ? scholarship.units : "";
    if (unitText.length > 0) {
        if (unitText.toLowerCase().startsWith("per") || unitText.toLowerCase().endsWith("ly")) {
            unitText = " " + unitText;
        } else {
            unitText = " / " + unitText;
        }
    }
    switch (scholarship.value_type) {
        case "fixed":
            amountText = "£" + scholarship.amount_fixed + unitText;
            break;
        case "ranged":
            amountText = "£" + scholarship.amount_min + " - £" + scholarship.amount_max + unitText;
            break;
        case "goods":
            amountText = scholarship.amount_goods + unitText;
            break;
        default:
            amountText = scholarship.amount_arbitrary ? scholarship.amount_arbitrary : amountText;
            break;
    }
    return amountText;
}