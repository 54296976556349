import * as actions from "../../redux/actions";
import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import {mapDispatchToProps, mapStateToProps} from "./AccommodationSearchTab";

describe("map state to props", () => {
    it("mapStateToProps has properties", () => {
        const properties = [1, 3, 5];
        const result = {state: ApiResultState.Success, payload: properties};
        const props = mapStateToProps({accommodationProperties: result}, {propertyID: null});
        expect(props.properties).toEqual(result);
    });

    it("mapStateToProps has selected campus", () => {
        const accommodationCampuses = {
            state: ApiResultState.Success,
            payload: [
                {id: 1, name: "im 1"},
                {id: 14, name: "im 14"},
                {id: 84, name: "im 84"}
            ]
        };
        const accommodationSelectedCampusId = makeSuccessResult(14);
        const props = mapStateToProps({accommodationCampuses, accommodationSelectedCampusId}, {propertyID: null});
        expect(props.selectedCampus).toEqual({id: 14, name: "im 14"});
    });

    it("mapStateToProps has campus list", () => {
        const campuses = [8, 3, 6];
        const result = {state: ApiResultState.Success, payload: campuses};
        const props = mapStateToProps({accommodationCampuses: result}, {propertyID: null});
        expect(props.campuses).toEqual(result);
    });

    it("mapStateToProps has details url", () => {
        const props = mapStateToProps({}, {propertyID: null});
        expect(props.makeUrlForPropertyDetails({uid: 94})).toEqual("/accommodation/search/94/details");
    });

    it("mapStateToProps has select url", () => {
        const props = mapStateToProps({}, {propertyID: null});
        expect(props.makeUrlForProperty({uid: 94})).toEqual("/accommodation/search/94");
    });

    it("mapStateToProps passes through selected", () => {
        const testID = 342;
        const props = mapStateToProps({}, {propertyID: testID});
        expect(props.selectedPropertyId).toEqual(testID);
    });
});

describe("map dispatch to props", () => {
    it("onCampusChange", () => {
        const dispatch = jest.fn();
        const {onCampusChange} = mapDispatchToProps(dispatch);
        onCampusChange({id: 2});
        expect(dispatch).toBeCalledTimes(1);
        expect(dispatch).toBeCalledWith(actions.setAccommodationCampus(2));
    });
});