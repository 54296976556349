import {ContainedButton} from "grantfairy-web-common";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import {ProviderWebsiteButton, ProviderWebsiteButtonType} from "../../views/ProviderWebsiteButton";
import {Subtitle, Title} from "../../views/Text";
import {handleProviderAnalytics} from "./HandleProviderAnalytics";

const makeDescription = uni => {
    if (uni.description == null) return null;
    return `<p><img src="${uni.logo}" width="120" height="120" style="padding: 24px" align="right">
            ${uni.descriptionTranslation ? (uni.descriptionTranslation + "<hr/>") : ""}
            ${uni.description}</p>
            `;
};

const ViewCoursesButton = ({onClick, count}) => (
    <ContainedButton id="btn-university-courses-view" style={{paddingLeft: 64, paddingRight: 64, background: count ? "" : Colours.DisabledButtonBackground}} onClick={onClick}>{Str.view()}</ContainedButton>
);

const UniversityDetailsScreen = ({university, onCoursesView}) => {
    const addressLines = [university.address1, university.address2, university.address3, university.address4].filter(e => e != null && e !== "");
    const handleAnalytics = handleProviderAnalytics(university.name, university.id, university.address4, university.website);

    return (
        <div style={{padding: "32px"}} className="noSelect">
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <Title style={{margin: 0}}>{university.name}</Title>
                    {addressLines.map(e => <Subtitle style={{margin: "8px 0"}}>{e}</Subtitle>)}
                </div>
                <div style={{textAlign: "center"}}>
                    <p id="txt-university-course-count" style={{margin: 8}}>{Str.number_courses(university.courseCount)}</p>
                    <ViewCoursesButton onClick={onCoursesView} count={parseInt(university.courseCount)}/>
                </div>
            </div>
            <hr style={{margin: "16px 0", border: "1px solid #CCC"}}/>
            <div dangerouslySetInnerHTML={{__html: makeDescription(university) ?? ""}}/>
            <div style={{padding: 16, display: "flex", justifyContent: "center"}}>
                <ProviderWebsiteButton
                    link={university.website}
                    providerID={university.id}
                    buttonType={ProviderWebsiteButtonType.ProviderDetails}
                    analyticsHandler={handleAnalytics}
                />
            </div>
        </div>
    );
};

export default UniversityDetailsScreen;