export interface AnalyticsEvent<T> {
    readonly category: string;
    readonly eventName: string;
    readonly params: T;
}


export class ProviderUrlSelected {
    readonly category = "providers";
    readonly eventName = "provider_url_selected";
    readonly eventAction = "clicked";
    readonly params: {
        courseID: string | null,
        providerID: string,
        providerURL: string,
        buttonType: string
    };

    constructor(courseID: string | null, providerID: string, providerURL: string, buttonType: string) {
        this.params = {courseID, providerID, providerURL, buttonType};
    }
}

export class EnglishProviderUrlSelected {
    readonly category = "english_providers";
    readonly eventName = "english_provider_url_selected";
    readonly eventAction = "clicked";
    readonly params: {
        providerID: string,
        providerURL: string,
        buttonType: string
    };

    constructor(providerID: string, providerURL: string, buttonType: string) {
        this.params = {providerID, providerURL, buttonType};
    }
}

export class ArticleExternalUrlSelected {
    readonly category = "articleLinks";
    readonly eventName = "article_url_selected";
    readonly eventAction = "clicked";
    readonly params: {
        article: string,
        contentID: string
        linkUrl: string,
        linkText: string
    };

    constructor(article: string, contentID: string, linkUrl: string, linkText: string) {
        this.params = {article, contentID, linkUrl, linkText};
    }
}

export class LoginEvent {
    readonly category = "user";
    readonly eventName = "login";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string) {
        const event = "user";
        const event_name = "login";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product};
    }
}

export class ChatOpenEvent {
    readonly category = "live_chat";
    readonly eventName = "open_chat";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string) {
        const event = "live_chat";
        const event_name = "open_chat";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product};
    }
}

export class ChatSendMessageEvent {
    readonly category = "live_chat";
    readonly eventName = "start_chat";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string) {
        const event = "live_chat";
        const event_name = "start_chat";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product};
    }
}

export class CourseApplicationCreatedEvent {
    readonly category = "application_step";
    readonly eventName = "application_pre";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        event_label: string,
        application_number: string,
        application_year: string,
        application_id: string
    };

    constructor(userID: string, applicationID: string) {
        const event = "application_step";
        const event_name = "application_pre";
        const event_action = "New";
        const event_label = "Started";
        const journey_type = "b2c";
        const product = "myriad";
        const application_number = "0";
        const application_year = "2023/2024";
        this.params = {event, event_name, userID, journey_type, product, event_action, event_label, application_number, application_year, application_id: applicationID};
    }
}

export class LearnerViewsSearchResultEvent {
    readonly category = "search";
    readonly eventName = "view_search_results";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        search_category: string,
        search_term: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string, search_category: string, search_term: string) {
        const event = "search";
        const event_name = "view_search_results";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, search_category, search_term, journey_type, product};
    }
}

export class LearnerClicksSearchResultEvent {
    readonly category = "search";
    readonly eventName = "click_search_results";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        search_category: string,
        search_term: string,
        search_result_ranking: string,
        search_result_category: string,
        search_result_url: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string, search_category: string, search_term: string, search_result_ranking: string, search_result_category: string, search_result_url: string) {
        const event = "search";
        const event_name = "click_search_results";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, search_category, search_term, search_result_ranking, search_result_category, search_result_url, journey_type, product};
    }
}

export class LearnerFavouritesSearchResultEvent {
    readonly category = "search";
    readonly eventName = "add_to_favourites";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        item_name: string,
        item_brand: string,
        search_result_category: string,
        journey_type: string,
        product: string
    };

    constructor(userID: string, item_name: string, item_brand: string, search_result_category: string) {
        const event = "search";
        const event_name = "add_to_favourites";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, item_name, item_brand, search_result_category, journey_type, product};
    }
}

export class LearnerProgressesApplicationThroughCartEvent {
    readonly category = "form";
    readonly eventName = "form_submit";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        form_name: string,
        application_id: string
    };

    constructor(userID: string, applicationID: string) {
        const event = "form";
        const event_name = "form_submit";
        const event_action = "Form submit";
        const journey_type = "b2c";
        const product = "myriad";
        const form_name = "Course application";
        this.params = {event, event_name, userID, journey_type, product, event_action, form_name, application_id: applicationID};
    }
}

export class LearnerBeginsAccommodationApplicationEvent {
    readonly category = "form";
    readonly eventName = "form_start";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        form_name: string,
        application_id: string
        item_brand: string,
        item_name: string,
    };

    constructor(userID: string, applicationID: string, propertyID: string) {
        const event = "form";
        const event_name = "form_start";
        const event_action = "Form start";
        const journey_type = "b2c";
        const product = "myriad";
        const form_name = "Enquiry form (" + propertyID + ")";
        this.params = {event, event_name, userID, journey_type, product, event_action, form_name, application_id: applicationID, item_name: propertyID, item_brand: propertyID};
    }
}

export class LearnerSwitchesLanguagesEvent {
    readonly category = "custom_event";
    readonly eventName = "language_select";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        language: string,
    };

    constructor(userID: string, language: string) {
        const event = "custom_event";
        const event_name = "language_select";
        const event_action = "language_select";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product, event_action, language};
    }
}

export class LearnerSubmitsAccommodationApplicationEvent {
    readonly category = "form";
    readonly eventName = "form_submit";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        form_name: string,
        application_id: string
        item_brand: string,
        item_name: string,
    };

    constructor(userID: string, applicationID: string, propertyID: string) {
        const event = "form";
        const event_name = "form_submit";
        const event_action = "Form submit";
        const journey_type = "b2c";
        const product = "myriad";
        const form_name = "Enquiry form (" + propertyID + ")";
        this.params = {event, event_name, userID, journey_type, product, event_action, form_name, application_id: applicationID, item_name: propertyID, item_brand: propertyID};
    }
}

export class LearnerSelectsDashboardItemEvent {
    readonly category = "cta";
    readonly eventName = "click_onsite_cta";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        click_text: string,
        click_section: string
    };

    constructor(userID: string, click_text: string, click_section: string) {
        const event = "cta";
        const event_name = "click_onsite_cta";
        const event_action = "cta";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product, event_action, click_text, click_section};
    }
}

export class LearnerClicksReferralButtonEvent {
    readonly category = "cta";
    readonly eventName = "click_onsite_cta";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        provider_name: string,
        provider_location: string,
        provider_id: string,
        click_text: string,
        url: string
    };

    constructor(event_name: string, userID: string, provider_name: string, provider_location: string, provider_id: string, click_text: string, url: string) {
        const event = "cta";
        const event_action = "cta";
        const journey_type = "b2c";
        const product = "myriad";
        this.params = {event, event_name, userID, journey_type, product, provider_name, provider_location, provider_id, event_action, click_text, url};
    }
}

export class LearnerBeginsAnsweringQuestion {
    readonly category = "form";
    readonly eventName = "form_start";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        form_name: string,
        form_field_name: string,
        form_field_value: string
    };

    constructor(userID: string, formName: string, formFieldName: string) {
        const event = "form";
        const event_name = "form_start";
        const event_action = "Form field click";
        const journey_type = "b2c";
        const product = "myriad";
        const form_field_value = "";
        this.params = {event, event_name, userID, journey_type, product, event_action, form_name: formName, form_field_name: formFieldName, form_field_value};
    }
}

export class LearnerEntersApplicationSection {
    readonly category = "application_step";
    readonly eventName = "application_section";
    readonly params: {
        event: string,
        event_name: string,
        userID: string,
        journey_type: string,
        product: string,
        event_action: string,
        event_label: string,
        application_number: string,
        application_year: string,
        application_progress: string,
        application_id: string
    };

    constructor(userID: string, sectionName: string, applicationProgress: string, applicationID: string) {
        const event = "application_step";
        const event_name = "application_section";
        const event_label = "Started";
        const journey_type = "b2c";
        const product = "myriad";
        const application_number = "0";
        const application_year = "2023/2024";
        this.params = {event, event_name, userID, journey_type, product, event_action: sectionName, event_label, application_number, application_year, application_progress: applicationProgress, application_id: applicationID};
    }
}

export type EventTypes<T> = AnalyticsEvent<T> & (
    (ProviderUrlSelected) |
    (EnglishProviderUrlSelected) |
    (LoginEvent) |
    (ChatSendMessageEvent) |
    (ChatOpenEvent) |
    (CourseApplicationCreatedEvent) |
    (ArticleExternalUrlSelected) |
    (LearnerViewsSearchResultEvent) |
    (LearnerClicksSearchResultEvent) |
    (LearnerFavouritesSearchResultEvent) |
    (LearnerProgressesApplicationThroughCartEvent) |
    (LearnerBeginsAccommodationApplicationEvent) |
    (LearnerSwitchesLanguagesEvent) |
    (LearnerSubmitsAccommodationApplicationEvent) |
    (LearnerSelectsDashboardItemEvent) |
    (LearnerClicksReferralButtonEvent) |
    (LearnerBeginsAnsweringQuestion) |
    (LearnerEntersApplicationSection));

