import {Button, Tabs} from "@mui/material";
import Dimens from "../theme/Dimens";
import {useGfTheme} from "../theme/GFThemeProvider";

export const Tabbar = ({tabs, selected, setSelected, style = {}, styleIfSelected = {}}) => {
    const gfTheme = useGfTheme();

    //  This should never happen, but it keeps tslint happy to have it here
    if (gfTheme === null) {
        return (<></>);
    }

    return (
        <Tabs variant="fullWidth" value={selected} onChange={(e, t) => setSelected(t)} style={style} sx={{ backgroundColor: gfTheme.tabBarColour}}
              TabIndicatorProps={{style: {backgroundColor: gfTheme.tabBarSelectedColour, height: Dimens.TabIndicatorHeight}}}>
            {tabs.map((tab, index) => {
                    const isSelected = index === selected;
                    const styleSelected = isSelected ? styleIfSelected : null;
                    const color = isSelected ? gfTheme.colour : gfTheme.tabBarUnselectedColour;
                    const background = isSelected ? gfTheme.tabBarSelectedColour : gfTheme.tabBarColour;
                    const id = "btn-" + String(tab).replaceAll(" ", "-").toLowerCase();
                    return (
                        <Button id={id} onClick={() => setSelected(index)} key={tab} style={{color, background, height: 48, ...styleSelected}}>
                            {tab}
                        </Button>
                    );
                }
            )}
        </Tabs>
    );
};