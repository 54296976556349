import * as _ from "lodash";
import * as Applications from "../Applications";
import {JobSearchFilters, serialiseJobFilters} from "../model/JobSearchFilters";
import * as MIANUtil from "../util/MIANUtil";
import * as StringUtil from "../util/StringUtil";
import {AppState} from "./store";
import {ApiResult, ApiResultState, makeSuccessResult, mapResult} from "../util/ApiResult";
import {BasicCourseInfo, CourseSearchResult} from "../model/Course";
import {JobsConfig} from "../model/JobsApiConfig";
import {Job} from "../model/Job";
import {MIAN} from "../model/MIAN";
import {FormSection, FormSubsection} from "../util/form/FormSection";
import {FormItem} from "../util/form/FormItem";
import {StringDictionary} from "../types/StringDictionary";
import {SavingState} from "../views/SavingIndicator";
import {Language} from "../model/Language";
import {FormNavigationOptions} from "../screens/main/applications/BaseApplicationFormScreen";
import {BasicCourseProviderInfo} from "../model/CourseProvider";
import {ApiCallState} from "grantfairy-web-common";
import {FormQuestionAnswerDictionary} from "../util/form/FormUtil";
import {EnglishLanguageArticle} from "../model/EnglishLanguageArticle";
import {HomeScreenItem} from "../model/HomeScreenItem";
import {EnglishLanguageConfig} from "../model/EnglishLanguageConfig";

/**
 *
 * @param state
 * @returns {null|{providers: [], studyModes: [], defaultLevelOfStudy: Number, englishQualificationsPresent: boolean}}
 */
export const courseSearchOptions = state => state.courseSearchOptions;

/**
 * @param state
 * @returns boolean true if application has recently been changed
 */
export const applicationFormChangedSinceCourseLoad = (state): boolean => state.applicationFormChangedSinceCourseLoad;

/**
 * @param state
 * @return boolean true if application has recently been changed
 */
export const applicationFormChangedSinceProfileLoad = (state): boolean => state.applicationFormChangedSinceProfileLoad;

/**
 * @param state
 * @returns {{list: []}}
 */
export const popularSubjects = state => state.popularSubjects;

/**
 * @param state
 * @return boolean true if profile details have recently been changed
 */
export const profileChangedSinceApplicationLoad = (state): boolean => state.profileChangedSinceApplicationLoad;

/**
 * Users current filters for course search
 *
 * @param state
 * @returns {null|{levelOfStudy: number, searchQuery: string | undefined, studyMode: string, filterByEnglishLanguageRequirements, providerID: string}}
 */
export const courseSearchFilters = state => state.courseSearchFilters;

export const courseSearchResult = (state): ApiResult<CourseSearchResult> => state.courseSearchResult;

/**
 * @returns Result<[MIAN]>
 */
export const courseMians = (state): ApiResult<MIAN[]> => state.courseMians;

/**
 *
 * @param state
 * @returns Result of A list of courses which are favourited
 */
export const courseFavourites = (state): ApiResult<BasicCourseInfo[]> => state.courseFavourites;

/**
 *
 * @param state
 * @returns A list of courses which are favourited, in order by name
 */
export const courseFavouritesSortedByName = state => mapResult(courseFavourites(state), f => {
    const sorted = [...f];
    sorted.sort((a, b) => a.title.localeCompare(b.title));
    return sorted;
});

export const isCourseFavourited = (state, course: BasicCourseInfo): boolean => {
    const faves = courseFavourites(state)?.payload ?? [];
    return faves.filter(e => e.id === course.id).length > 0;
};

/**
 *
 * @param state
 * @returns An object keyed by course ID with value of type Result<Course>
 */
export const courseDetails = state => state.courseDetails;

/**
 *
 * @param state
 * @param courseID
 * @return Result<Course>
 */
export const courseDetailsForCourse = (state, courseID: string) => state.courseDetails[courseID];

/**
 * @returns Result of funding Info which is {amount, count} for a given courseID
 */
export const courseFundingDetails = (state, courseID: string) => {
    return state.courseFundingDetails[courseID];
};

/**
 * @returns Object or null - currently selected course {id, title, providerName}
 */
export const selectedCourseObject = state => state.selectedCourse?.payload ?? {};

/**
 *
 * @param state
 * @returns An object keyed by providerID with value of type Result<CourseProvider>
 */
export const courseProviderDetails = state => state.courseProviderDetails;

export const jobsConfig = (state): ApiResult<JobsConfig> => state.jobsConfig;

export const jobSearchFilters = (state): JobSearchFilters => state.jobSearchFilters;

/**
 *
 * @param state
 * @return Map of string to ApiResult<Job[]> where the key is a serialised version of the filters used to obtain said result
 */
export const jobSearchResults = state => state.jobSearchResults;

/**
 * @return The result corresponding to the provided filters
 */
export const jobSearchResultForFilters = (state, filters): ApiResult<Job[]> | undefined => {
    const result = jobSearchResults(state)[serialiseJobFilters(filters)];
    return result;
};

/**
 * @return The result corresponding to the currently selected filters
 */
export const currentJobSearchResult = (state): ApiResult<Job[]> | undefined => {
    const filters = jobSearchFilters(state);
    return jobSearchResultForFilters(state, filters);
};

/**
 * Gets a Result<Job> for a given id, if it exists
 */
export const jobDetails = (state, id: string): ApiResult<Job> | undefined => state.jobDetails[id];

export const favouritedJobs = (state): ApiResult<Job[]> => state.favouritedJobs;

export const favouritedJobsSortedByValidity = (state): ApiResult<Job[]> => mapResult(favouritedJobs(state), (jobs: Job[]) => {
    const sorted = _.sortBy(jobs, (j) => !j.valid);
    return sorted;
});

export const favouritedJobIDs = (state): number[] => (favouritedJobs(state)?.payload ?? []).map(e => e.id);

/**
 *
 * @param state
 * @returns Result<[University]> all unis
 */
export const universityList = (state): ApiResult<BasicCourseProviderInfo[]> => state.universityList;

/**
 *
 * @param state
 * @returns Result<[University]> all unis sorted by name
 */
export const universityListSortedByName = (state): ApiResult<any[]> => mapResult(universityList(state), unis => {
    const sorted = [...unis];
    sorted.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
});

/**
 *
 * @returns Result<[University]> all unis the user has favourited
 */
export const favouritedUniversities = (state): ApiResult<any[]> => state.favouritedUniversities;

/**
 *
 * @returns Result<[University]> all unis the user has favourited, sorted by name
 */
export const favouritedUniversitiesSortedByName = state => mapResult(favouritedUniversities(state), unis => {
    const sorted = [...unis];
    sorted.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
});

/**
 *
 * @returns favourited university IDs
 */
export const favouritedUniversityIDs = (state): string[] => (favouritedUniversities(state)?.payload ?? []).map(e => e.id);

/**
 * @returns {String} Currently logged in user's name, from the profile, or from the dashboard if not available
 */
export const userName = (state): string | undefined => state.userProfile?.payload?.name ?? state.userName;

/**
 *
 * @returns Result of a list of scholarships which represent the users Wishlist
 */
export const fundingWishlist = (state): ApiResult<any[]> => state.fundingWishlist;

/**
 *
 * @returns Result of a list of scholarships which represent the users Applied list
 */
export const fundingAppliedList = (state): ApiResult<any[]> => state.fundingAppliedList;

/**
 *
 * @returns Result of a list of scholarships which represent the users Success list
 */
export const fundingSuccessList = (state): ApiResult<any[]> => state.fundingSuccessList;

/**
 * @returns Result of a list of scholarships which represent the users Deleted list
 */
export const fundingDeletedList = (state): ApiResult<any[]> => state.fundingDeletedList;

export const fundingMians = (state, tab: number): MIAN[] => {
    const allMians = state.fundingMians?.payload ?? [];
    const listLengths = [fundingWishlist(state), fundingAppliedList(state), fundingSuccessList(state), fundingDeletedList(state)].map(e => (e.payload ?? []).length);
    const mianCountPerList = listLengths.map(l => Math.floor(l / MIANUtil.MIAN_FREQUENCY));
    let numberMiansBeforeCurrentTab = 0;
    for (let i = 0; i < tab; i++) {
        numberMiansBeforeCurrentTab += mianCountPerList[i];
    }
    if (numberMiansBeforeCurrentTab >= allMians.length) return [];
    return allMians.slice(numberMiansBeforeCurrentTab, numberMiansBeforeCurrentTab + mianCountPerList[tab]);
};

export const fundingWishlistCount = (state) => mapResult(fundingWishlist(state), list => list.length);

export const fundingWishlistAmount = (state) => mapResult(fundingWishlist(state), list => {
    return list.map(e => StringUtil.parseMoney(e.amount)).reduce((a, b) => a + b, 0);
});

/**
 * @returns Result of scholarship details for given uid and version
 */
export const scholarshipDetails = (state, uid, version) => {
    return state.scholarshipDetails[uid + version];
};

/**
 * @returns Result<[Campus]> all campuses available in the system
 */
export const accommodationCampuses = state => state.accommodationCampuses;

/**
 * @returns Result<String> id of currently selected campus
 */
export const accommodationSelectedCampusIdResult = state => state.accommodationSelectedCampusId;

/**
 * @returns {String} id of currently selected campus
 */
export const accommodationSelectedCampusId = state => accommodationSelectedCampusIdResult(state)?.payload ?? "";

export const accommodationSelectedCampus = state => accommodationSelectedCampusResult(state)?.payload ?? null;

export const accommodationSelectedCampusResult = state => {
    const campuses = accommodationCampuses(state);
    const selectedID = accommodationSelectedCampusIdResult(state);
    if (campuses == null || campuses.state !== ApiResultState.Success) return campuses;
    if (selectedID && selectedID.state === ApiResultState.Success) {
        const filtered = (campuses.payload ?? []).filter(e => e.id === selectedID.payload);
        return makeSuccessResult(filtered[0] ?? null);
    }
    return makeSuccessResult(null);
};

/**
 * @returns Result<[AccommodationProperty]> latest search results
 */
export const accommodationProperties = state => state.accommodationProperties;

/**
 * @returns Result<[MIAN]>
 */
export const accommodationMians = state => state.accommodationMians;

/**
 * @returns An object keyed by property uid with value of type Result<Property>
 */
export const accommodationPropertyDetails = state => state.accommodationPropertyDetails;

/**
 * @returns Result<[AccommodationProperty]> favourited properties
 */
export const accommodationFavourites = state => state.accommodationFavourites;

/**
 * @returns boolean true if no campus has been selected by this user, false if it has or we dont know (loading)
 */
export const hasNoCampus = (state): boolean => {
    const result = accommodationSelectedCampusResult(state);
    return result.state === ApiResultState.Success && result.payload == null;
};

/**
 * @returns the entire university application form
 */
export const applicationFormSections = (state): ApiResult<FormSection[]> => state.applicationFormSections;

/**
 * @returns the entire university application form for a given applicationID
 */
export const applicationFormSectionsForApplication = (state, applicationID: number): ApiResult<FormSection[]> => state.universityApplicationSpecificForm[applicationID];

export const applicationSpecificFormSubsection = (state, subsection, applicationID) => {
    return mapResult(applicationFormSectionsForApplication(state, applicationID), e => e.flatMap(s => s.subsections).filter(e => e.name === subsection)[0]);
};

export const applicationSpecificFormAllItems = (state, applicationID: number): FormItem[] => {
    const sections = applicationFormSectionsForApplication(state, applicationID)?.payload ?? [];
    const subsections = sections.flatMap(s => s.subsections);
    const items = subsections.flatMap(s => s.items);
    return items;
};

/**
 * @returns the entire accommodation application form
 */
export const accApplicationFormSections = (state): ApiResult<FormSection[]> => state.accApplicationFormSections;

export const accApplicationFormAllItems = (state): FormItem[] => {
    const sections = accApplicationFormSections(state)?.payload ?? [];
    const subsections = sections.flatMap(s => s.subsections);
    const items = subsections.flatMap(s => s.items);
    return items;
};

/**
 * @returns Result<{questionID: answer}> dictionary of answer to every question, keyed by question id
 */
export const applicationFormAnswers = (state): ApiResult<StringDictionary> => state.applicationFormAnswers;

/**
 * @returns SavingState, see SavingIndicator.js
 */
export const applicationFormSavingState = (state): SavingState => state.applicationFormSavingState;

/**
 * @returns a specific subsection, by name
 */
export const applicationFormSubsection = (state, subsection: string): ApiResult<FormSubsection | undefined> => {
    return mapResult(applicationFormSections(state), sections => sections.flatMap(e => e.subsections).filter(e => e.name === subsection)[0]);
};

export const applicationFormAllItems = (state): FormItem[] => {
    const sections = applicationFormSections(state)?.payload ?? [];
    const subsections = sections.flatMap(s => s.subsections);
    const items = subsections.flatMap(s => s.items);
    return items;
};

/**
 * @returns a specific subsection, by name
 */
export const accApplicationFormSubsection = (state, subsection: string): ApiResult<FormSubsection | undefined> => {
    return mapResult(accApplicationFormSections(state), sections => sections.flatMap(e => e.subsections).filter(e => e.name === subsection)[0]);
};

/**
 * @returns {number} 0-100 percent of form which has a valid answer
 */
export const universityFormCompletionPercent = (state): number => {
    const sections = applicationFormSections(state)?.payload ?? [];
    const answers = applicationFormAnswers(state)?.payload ?? {};

    return formPercent(sections, answers);
};

export const universityFormIsComplete = (state, applicationID: number): boolean => {
    const sections = applicationFormSectionsForApplication(state, applicationID)?.payload ?? [];
    const answers = applicationFormAnswers(state)?.payload ?? {};

    return sections.length > 0 && formPercent(sections, answers) === 100;
};

/**
 * @returns {number} 0-100 percent of form which has a valid answer
 */
export const accommodationFormCompletionPercent = (state): number => {
    const sections = accApplicationFormSections(state)?.payload ?? [];
    const answers = applicationFormAnswers(state)?.payload ?? {};

    return formPercent(sections, answers);
};

const formPercent = (sections: FormSection[], answers: StringDictionary): number => {
    let questions = 0;
    let numAns = 0;

    const allItems = sections.flatMap(s => s.subsections.flatMap(t => t.items));

    sections.forEach(section => {
        section.subsections.forEach(subsection => {
            questions += subsection.totalShowingRequired(allItems, answers);
            numAns += subsection.totalAnsweredRequired(allItems, answers);
        });
    });

    return 100 * numAns / questions;
};

/**
 * @returns Result<[DocumentType]> All document types
 */
export const applicationFormDocumentTypes = state => state.applicationFormDocumentTypes;

/**
 * @returns Result<[Document]> All documents
 */
export const applicationFormDocuments = state => state.applicationFormDocuments;

/**
 * @returns {[{name, documentType, fileType}]} All documents
 */
export const applicationFormDocumentsArray = state => state.applicationFormDocuments?.payload ?? [];

/**
 * @returns Result<[Item]>
 */
export const applicationFormDocumentAgreements = state => state.applicationFormDocumentAgreements;

/**
 * @returns boolean true if all agreements are ticked
 */
export const documentAgreementsComplete = state => {
    const agreements = applicationFormDocumentAgreements(state)?.payload;
    const answers = applicationFormAnswers(state)?.payload ?? {};
    if (agreements == null) return false;
    return agreements.map(ag => answers[ag.id]).filter(e => e !== "true").length === 0;
};

export const documentCompletionPercent = state => {
    const documentTypes = applicationFormDocumentTypes(state)?.payload ?? [];
    const documents = applicationFormDocuments(state)?.payload ?? [];
    const typesOfExistingDocuments = documents.map(e => e.documentType);
    const requiredTypes = documentTypes.filter(e => e.required).map(e => e.id);
    const completedDocumentsOutOfRequired = requiredTypes.filter(e => typesOfExistingDocuments.includes(e)).length;

    const agreementsComplete = documentAgreementsComplete(state);

    return (completedDocumentsOutOfRequired + (agreementsComplete ? 1 : 0)) / (requiredTypes.length + 1) * 100;
};

export const areDocumentsComplete = state => documentCompletionPercent(state) === 100;

export const isApplicationComplete = (state, applicationID) => {
    if (!areDocumentsComplete(state)) return false;
    if (!universityFormIsComplete(state, applicationID)) return false;
    const application = universityApplicationByID(state, applicationID)?.payload;
    const documentTypes = applicationFormDocumentTypes(state)?.payload;
    const allDocuments = applicationFormDocuments(state)?.payload;
    if (application == null || documentTypes == null || allDocuments == null) return false;
    return Applications.areApplicationDocumentsReady(application, documentTypes, allDocuments);
};

export const isDocumentSelectedForApplication = (state, applicationID, document) => {
    const application = universityApplicationByID(state, applicationID)?.payload;
    const docs = application?.documents ?? [];
    const matches = docs.filter(doc => {
        const {type, name} = Applications.parseDocumentFromString(doc);
        return type === document.documentType && name === (document.name + "." + document.fileType);
    });
    return matches.length !== 0;
};

/**
 * @param  {{}} state - redux state object
 * @param  {number} applicationID - id of the application
 * @return {boolean} - true if errors should be shown
 */
export const showApplicationFormErrors = (state, applicationID) => {
    return (state?.showApplicationFormErrors ?? {})[applicationID] ?? false;
};

/**
 * @returns Result<Application> all university applications
 */
export const universityApplications = (state): ApiResult<any[]> => state.universityApplications;

export const universityApplicationsWithStatus = (state, status) => {
    return mapResult(universityApplications(state), apps => apps.filter(e => e.status === status));
};

/**
 * @returns Result<Application> all accommodation applications
 */
export const accommodationApplications = (state): ApiResult<any[]> => state.accommodationApplications;

export const accommodationApplicationsWithStatus = (state, status) => {
    return mapResult(accommodationApplications(state), apps => apps.filter(e => e.status === status));
};

export const accommodationApplicationCountWithStatus = (state, status) => {
    const result = accommodationApplicationsWithStatus(state, status);
    return result.payload?.length ?? 0;
};

/**
 * @returns Result<Application>
 */
export const universityApplicationByID = (state, applicationID) => {
    return state.universityApplicationsByID[applicationID];
};

/**
 * @returns Result of Application
 */
export const accommodationApplicationByID = (state, applicationID) => {
    const list = accommodationApplications(state)?.payload ?? [];
    const application = list.filter(e => e.id === applicationID)[0];
    if (application == null) return {state: ApiResultState.Loading};
    return makeSuccessResult(application);
};

/**
 * @returns user's profile
 */
export const userProfile = state => state.userProfile;

/**
 * @returns user onboarding profile
 */
export const userOnboarding = state => state.userOnboarding;

/**
 *
 * @param state user's login state
 */
export const userLogin = state => state.userLogin;

export const profileForm = state => state.profileForm;

export const onboardingForm = state => state.onboardingForm;
export const needsSignIn = (state): boolean | null => {
    const needsSignInResult = state.needsSignIn;
    return needsSignInResult?.payload ?? null;
};

export const hasSentVerificationEmail = (state): ApiResult<boolean> => state.hasSentVerificationEmail;

export const needsEmailVerification = (state): ApiResult<boolean> => state.needsEmailVerification;

/**
 * @returns Result<Settings>. Settings has pref_contact_emails, pref_contact_deadline, pref_contact_match, pref_contact_update. These are booleans
 */
export const settings = state => state.settings;

/**
 * @returns current school name the user is in, or blank
 */
export const currentSchoolName = (state): string => state.currentSchoolName;

export const languageOptions = (state): Language[] => state.languageOptions;

/**
 * @returns uid of current language
 */
export const currentLanguageID = (state): string | null => state.currentLanguageID;

/**
 * @returns Accommodation Filters which are currently in draft, ie the user has set them but they havent clicked search so theyre not applied yet, see Property.js
 */
export const accommodationFiltersDraft = state => state.accommodationFiltersDraft;

/**
 * @returns Accommodation Filters which are currently applied, see Property.js
 */
export const accommodationFilters = state => state.accommodationFilters;

/**
 * @returns Result<Number> number of properties found for current accommodationFiltersDraft
 */
export const accommodationFilterResultCount = state => state.accommodationFilterResultCount;

/**
 * @returns Result<{[walking/cycling/driving]: {geometry, duration}}>
 */
export const accommodationDirectionInfo = (state, fromLat, fromLng, toLat, toLng) => state.accommodationDirections[`${fromLat}-${fromLng}-${toLat}-${toLng}`];

/**
 * @returns Application - the University Application for a given course option, or null if this user hasn't applied to this course option
 */
const applicationForCourseOption = (state, optionID) => {
    const applications = universityApplications(state)?.payload ?? [];
    return applications.filter(a => a.option.id === optionID)[0];
};

/**
 * @returns The status of this users application to this option, or null if they have not applied
 */
export const applicationStatus = (state, optionID) => applicationForCourseOption(state, optionID)?.status ?? null;

export const applicationIdForCourse = (state, optionID) => applicationForCourseOption(state, optionID)?.id;

/**
 * @returns Application - the Application for a given tenancy, or null if this user hasn't applied for this tenancy
 */
const applicationForTenancy = (state, tenancyID) => {
    const applications = accommodationApplications(state)?.payload ?? [];
    const application = applications.filter(a => a.tenancy_id === tenancyID)[0];
    return application;
};

/**
 * @returns The status of this users application for this tenancy, or null if they have not applied
 */
export const accApplicationStatus = (state, tenancyID) => applicationForTenancy(state, tenancyID)?.status ?? null;

export const applicationIdForTenancy = (state, tenancyID) => applicationForTenancy(state, tenancyID)?.id;

/**
 * @returns Result<{needsPayment, amountToPayPence, amountToPayFormatted, numberOfTokens}> how much this user needs to pay, if any, to buy this many tokens
 */
export const applicationPaymentRequiredInfo = state => state.applicationPaymentRequiredInfo;

export const currentFeeStatus = state => userProfile(state)?.payload?.feeStatus;

export const latestToast = state => state.latestToast;
export const latestAlert = state => state.latestAlert;

/**
 * @returns Result<Agent>
 */
export const currentAgents = state => state.currentAgents;

/**
 *
 * @returns boolean
 */
export const hasAgent = (state): boolean => state.currentAgents?.payload?.length != 0; 

/**
 * @returns Result<[agent invites]>
 */
export const agentInvites = state => state.agentInvites;

/**
 * @returns Result<[agent messages]>
 */
export const agentMessages = state => state.agentMessages;

/**
 * @returns Result<[agents history]>
 */
export const agentsHistory = state => state.agentsHistory;

/**
 * @returns IAN
 */
export const viewingIan = state => state.viewingIan;

/**
 * @returns ServerSidePopup
 * id (int)
 * title (string)
 * body (string)
 * positive (button title)
 * negative (button title)
 * modal (bool)
 */
export const serverSidePopup = state => state.serverSidePopup;

/**
 *
 * @param state
 * @return {string[]} pathnames of the routes the user has visited including the current route. Latest route comes last
 */
export const routeHistory = (state): string[] => state.routeHistory;

/**
 * @param state
 * @returns Result<Promotion>
 */
export const applicationPromotionInfo = state => state.applicationPromotionInfo;

/**
 * @returns false if there are enough answers in the application form
 */
const shouldApplicationTabShowWelcomePopup = (state): ApiResult<boolean> => state.shouldApplicationTabShowWelcomePopup;

/**
 * @returns false if pop up has been closed for that session
 */
const applicationWelcomePopupNotAlreadyDisplayed = (state): boolean => state.applicationWelcomePopupNotAlreadyDisplayed;

export const applicationWelcomePopupIsOpen = state => (
    shouldApplicationTabShowWelcomePopup(state).payload && applicationWelcomePopupNotAlreadyDisplayed(state)
);

export const isAgentChatOpen = (state): boolean => state.isAgentChatOpen;

export const uploadingDocument = (state): boolean => state.uploadingDocument;

const formSectionExpandedState = (state: AppState) => state.formSectionExpandedState;
export const applicationSectionIsExpanded = (state: AppState, sectionID: string) => formSectionExpandedState(state)[sectionID];

export const accFormNavigationOptions = (state, currentSubsectionName: string, baseUrl: string): ApiResult<FormNavigationOptions> => {
    return mapResult(accApplicationFormSections(state), ((sections: FormSection[]) => (
        Applications.getNavigationOptionsForSubsection(currentSubsectionName, Applications.allSubsectionsFromSections(sections), baseUrl)
    )));
};

export const universityFormNavigationOptions = (state, currentSubsectionName: string, baseUrl: string): ApiResult<FormNavigationOptions> => {
    return mapResult(applicationFormSections(state), ((sections: FormSection[]) => (
        Applications.getNavigationOptionsForSubsection(currentSubsectionName, Applications.allSubsectionsFromSections(sections), baseUrl)
    )));
};

export const universitySpecificFormNavigationOptions = (state, currentSubsectionName: string, baseUrl: string, applicationID: number): ApiResult<FormNavigationOptions> => {
    return mapResult(applicationFormSectionsForApplication(state, applicationID), ((sections: FormSection[]) => (
        Applications.getNavigationOptionsForSubsection(currentSubsectionName, Applications.allSubsectionsFromSections(sections), baseUrl)
    )));
};

export const notificationSettingsFormItems = (state: AppState): ApiCallState<FormItem[]> => state.notificationSettingsFormItems;
export const notificationSettingsAnswers = (state: AppState): FormQuestionAnswerDictionary => state.notificationSettingsAnswers;

export const englishLanguageArticles = (state: AppState): ApiCallState<EnglishLanguageArticle[]> => state.englishLanguageArticles;

export const homeScreenItems = (state: AppState): ApiCallState<HomeScreenItem[]> => state.homeScreenItems;

export const englishLanguageConfig = (state: AppState): ApiCallState<EnglishLanguageConfig> => state.englishLanguageConfig;

export const subjectSearchOptions = (state: AppState) => state.subjectSearchOptions;

export const unreadAgentMessagesCount = (state: AppState) => state.unreadAgentMessagesCount;

const showRemoteConfig = (state: AppState) => state.showRemoteConfig;

export const remoteConfigLoaded = (state: AppState) => Object.keys(state.showRemoteConfig).length !== 0;

export const userCanLogin = (state: AppState) => showRemoteConfig(state)?.learnerCanLogin ?? true;
export const userCanSignUp = (state: AppState) => showRemoteConfig(state)?.learnerCanSignUp ?? true;
export const userCanApplyCourses = (state: AppState) => showRemoteConfig(state)?.learnerCanApplyCourses ?? true;