import {queryParamsToMap} from "./api2";
import {StringDictionary} from "../types/StringDictionary";
import {httpUtil} from "grantfairy-web-common";

export interface DeepLink {
    readonly id: string;
    readonly parametersAsDictionary: () => StringDictionary;
    readonly toWebappUrl: () => string;
}

export class CourseDeepLink implements DeepLink {
    static readonly deepLinkId: string = "course";

    readonly courseID: string;
    readonly id: string = CourseDeepLink.deepLinkId;

    constructor(courseID: string) {
        this.courseID = courseID;
    }

    parametersAsDictionary(): StringDictionary {
        return {courseID: this.courseID};
    }

    toWebappUrl(): string {
        return `/courses/${this.courseID}`;
    }

    static fromDictionary(dictionary: StringDictionary): CourseDeepLink | null {
        const courseID = dictionary["courseID"];
        if (courseID == null) {
            return null;
        } else {
            return new CourseDeepLink(courseID);
        }
    }

}

export class SpecificTabDeepLink implements DeepLink {
    static readonly deepLinkId: string = "section";

    readonly sectionId: string;
    readonly tabId?: string;
    readonly id: string = CourseDeepLink.deepLinkId;

    constructor(sectionId: string, tabId?: string) {
        this.sectionId = sectionId;
        this.tabId = tabId;
    }

    parametersAsDictionary(): StringDictionary {
        if (this.tabId !== undefined) {
            return {section: this.sectionId, tab: this.tabId};
        } else {
            return {section: this.sectionId};
        }
    }

    sectionIdToUrl(sectionId: string): string {
        if (sectionId === "informationAndGuidance") return "information_and_guidance";
        return sectionId;
    }

    toWebappUrl(): string {
        // The courses section has no url prefix just straight to /{tabName}
        // Whereas other sections are /{sectionName}/{tabName}
        const sectionUrl = this.sectionId === "courses" ? "" : (this.sectionIdToUrl(this.sectionId) + "/");
        return `/${sectionUrl}${this.tabId ?? ""}`;
    }

    static fromDictionary(dictionary: StringDictionary): SpecificTabDeepLink | null {
        const {section, tab} = dictionary;
        if (section == null) {
            return null;
        } else {
            return new SpecificTabDeepLink(section, tab);
        }
    }

}

export class DeepLinkUtil {

    static protocol = "myriad:";

    static deepLinkToUrl(deepLink: DeepLink): string {
        const params = httpUtil.makeQueryString(queryParamsToMap(deepLink.parametersAsDictionary()));
        return `${this.protocol}//${deepLink.id}?${params}`;
    }

    static deepLinkFromUrl(urlString: string): DeepLink | null {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return null;
        }
        if (url.protocol === this.protocol) {
            const pathname = url.pathname.substr("//".length);
            const host = url.hostname;
            // Some browsers will parse myriad://whatever as having a hostname of whatever and a pathname of '' whereas others give a hostname of '' and a pathname of //whatever
            const params: StringDictionary = {};
            url.searchParams.forEach((value, key) => {
                params[key] = value;
            });
            if (pathname === CourseDeepLink.deepLinkId || host === CourseDeepLink.deepLinkId) {
                return CourseDeepLink.fromDictionary(params);
            } else if (pathname === SpecificTabDeepLink.deepLinkId || host === SpecificTabDeepLink.deepLinkId) {
                return SpecificTabDeepLink.fromDictionary(params);
            }
        }
        return null;
    }

}