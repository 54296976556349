import moment from "moment";

/**
 * Unparsed, as it comes from CV Library
 */
export interface CVLibraryJob {
    id: number;
    title: string;
    logo: string;
    posted?: string;
    salary?: string;
    description: string;
    description_formatted: string;
    url: string;
    valid: Boolean;
}

/**
 * Job as represented in our system, it's parsed on the way in from CVLibraryJob
 */
export interface Job {
    id: number;
    title: string;
    logo: string;
    posted?: Date;
    salary?: string;
    description: string;
    descriptionFormatted: string;
    url: string;
    valid: Boolean;
}

export const parseCvLibraryDate = (date?: string): Date | null => {
    if (date == null) return null;
    // CV Library sends jobs either as yyyy-MM-DDTHH:mm:ssZ or yyyy-MM-DDTHH:mm:ss
    // If there is no Z then we have no idea what time zone it is in. May as well assume it's UTC, so add the Z
    const dateWithZone = date.endsWith("Z") ? date : `${date}Z`;
    const unix = moment(dateWithZone, "yyyy-MM-DDTHH:mm:ssZ").unix();
    return new Date(unix * 1000);
};

export const parseCvLibraryJob = (job: CVLibraryJob | null): Job | null => {
    if (job == null) return null;
    try {
        const cleanDescription = job.description.replaceAll("<em>", "").replaceAll("</em>", "");
        return {
            id: job.id,
            posted: parseCvLibraryDate(job.posted) ?? undefined,
            descriptionFormatted: job.description_formatted,
            description: cleanDescription,
            salary: job.salary,
            logo: job.logo,
            title: job.title,
            url: job.url,
            valid: job.valid ?? true
        };
    } catch (e) {
        return null;
    }
};