import sanitizeHtml from "sanitize-html";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    html: string | null;
    allowImages?: boolean;
}

const defaultAllowedTags = sanitizeHtml.defaults.allowedTags.concat(["details", "summary"]);
const defaultAllowedAttributes = {"*": ["href", "align", "alt", "center", "bgcolor", "style", "src"]};

export const SanitizedHtml = ({html, allowImages = false, ...props}: Props) => {
    const additionalAllowedTags = allowImages ? ["img"] : [];
    const allowedTags = [...defaultAllowedTags, ...additionalAllowedTags];
    const cleanHtml = sanitizeHtml(html, {allowedTags, allowedAttributes: defaultAllowedAttributes});
    return <div {...props} dangerouslySetInnerHTML={{__html: cleanHtml}}/>;
};
