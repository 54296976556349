import {FormItem} from "./FormItem";
import * as FormUtil from "./FormUtil";
import {StringDictionary} from "../../types/StringDictionary";

export class FormSection {

    readonly name: string;
    readonly subsections: FormSubsection[];

    constructor(name: string, subsections: FormSubsection[]) {
        this.name = name;
        this.subsections = subsections;
    }

    getPercent(allItems: FormItem[], answers: StringDictionary): number {
        let q = 0;
        let a = 0;
        this.subsections.forEach(s => {
            q += s.totalShowingRequired(allItems, answers);
            a += s.totalAnsweredRequired(allItems, answers);
        });

        return 100 * a / q;
    }

    isComplete(allItems: FormItem[], answers: StringDictionary): boolean {
        for (let i = 0; i < this.subsections.length; i++) {
            if (!this.subsections[i].isComplete(allItems, answers)) {
                return false;
            }
        }
        return true;
    }

    containsSubsectionsWithRequiredFields(allItems: FormItem[], answers: StringDictionary): boolean {
        const requiredSubsections = this.subsections.filter(subsection => {
            return subsection.totalShowingRequired(allItems, answers) > 0;
        });
        return requiredSubsections.length > 0;
    }

    containsSubsectionsWithUniversitySpecificQuestions(allItems: FormItem[], answers: StringDictionary): boolean {
        return this.subsections.filter(subsection => subsection.containsUniversitySpecificQuestions(allItems, answers)).length > 0;
    }

}

export class FormSubsection {
    readonly name: string;
    readonly items: FormItem[];

    constructor(name: string, items: FormItem[]) {
        this.name = name;
        this.items = items;
    }

    /**
     * Every item with satisfied show-if rules
     */
    allShowingItems(allItems: FormItem[], answers: StringDictionary): FormItem[] {
        return FormUtil.filterShowingItems(this.items, allItems, answers).map(x => {
            Object.setPrototypeOf(x, FormItem.getPrototype(x.type));
            return x;
        });
    }

    /**
     * Every item to count towards the percentage completion. That's every visible showing item
     */
    itemsToCount(allItems: FormItem[], answers: StringDictionary): FormItem[] {
        return this.allShowingItems(allItems, answers).filter(item => {
            //If it's a record we must delegate down. Otherwise count iff required
            return (item.required || item.type === "records");
        });
    }

    totalShowingRequired(allItems: FormItem[], answers: StringDictionary): number {
        const items = this.itemsToCount(allItems, answers);
        let total = 0;
        items.forEach(x => {
            const toAdd = x.numberOfQuestions((answers || {})[x.id], answers || {});
            total += toAdd;
        });
        return total;
    }

    totalAnsweredRequired(allItems: FormItem[], answers: StringDictionary): number {
        const items = this.itemsToCount(allItems, answers);
        let total = 0;
        items.forEach(x => {
            const toAdd = x.numberAnswered((answers || {})[x.id], answers || {});

            total += toAdd;
        });

        return total;
    }

    getPercent(allItems: FormItem[], answers: StringDictionary): number {
        const q = this.totalShowingRequired(allItems, answers);
        const a = this.totalAnsweredRequired(allItems, answers);

        if (q === 0) {
            return 0;
        }

        return 100 * a / q;
    }

    isComplete(allItems: FormItem[], answers: StringDictionary): boolean {
        const q = this.totalShowingRequired(allItems, answers);
        const a = this.totalAnsweredRequired(allItems, answers);
        return q === a;
    }

    containsUniversitySpecificQuestions(allItems: FormItem[], answers: StringDictionary): boolean {
        return this.allShowingItems(allItems, answers).filter(item => item.containsVisibleUniversitySpecificQuestion(answers[item.id], answers)).length > 0;
    }

}
