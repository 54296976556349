import {ActionWithServerResult, fetchAndCacheReducer, fetchReducer} from "../../util/reducerUtil";
import {emptyJobSearchFilters, JobSearchFilters, serialiseJobFilters} from "../../model/JobSearchFilters";
import * as actions from "../actions";
import {CVLibraryJob, Job, parseCvLibraryJob} from "../../model/Job";
import {AnyAction} from "redux";
import {JobsConfig} from "../../model/JobsApiConfig";
import {ApiResult, mapResult} from "../../util/ApiResult";

export interface JobsState {
    jobsConfig: ApiResult<JobsConfig>;
    jobSearchFilters: JobSearchFilters;
    jobSearchResults?: { [key: string]: ApiResult<Job[]> };
    jobDetails: { [key: string]: ApiResult<Job> };
    favouritedJobs: ApiResult<Job[]>;
}

export const jobsConfig = (state = {}, action: ActionWithServerResult): ApiResult<JobsConfig> => {
    return fetchReducer(state, action, actions.GOT_JOBS_CONFIG, (e: JobsConfig) => e);
};

export const jobSearchFilters = (state = emptyJobSearchFilters, action: AnyAction) => {
    if (action.type === actions.SET_JOB_SEARCH_FILTERS) {
        return action.filters;
    }
    return state;
};

export const jobSearchResults = (state = {}, action: ActionWithServerResult) => {
    return fetchAndCacheReducer(state, action, actions.GOT_JOB_LIST, actions.REQUESTED_JOB_LIST, (e: CVLibraryJob[]) => e.map(parseCvLibraryJob), (a: any) => serialiseJobFilters(a.filters));
};

export const jobDetails = (state = {}, action: ActionWithServerResult) => {
    return fetchAndCacheReducer(state, action, actions.GOT_JOB_DETAILS, actions.REQUESTED_JOB_DETAILS, (r: CVLibraryJob) => parseCvLibraryJob(r), (a: any) => a.id);
};

export const favouritedJobs = (state = {}, action: ActionWithServerResult) => {
    if (action.type === actions.SET_JOB_FAVE) {
        return mapResult(state, (old: Job[]) => {
            const {job, fave} = action as any;
            if (fave) {
                return [...old, job];
            } else {
                return old.filter(e => e.id !== job.id);
            }
        });
    }
    return fetchReducer(state, action, actions.GOT_JOBS_FAVOURITES, (e: { jobs: CVLibraryJob[] } | null) => e?.jobs?.map(parseCvLibraryJob) ?? []);
};

export const reducers = {
    jobsConfig,
    jobSearchResults,
    jobSearchFilters,
    jobDetails,
    favouritedJobs
};