import {FormAnswerType, TickEntry} from "../../util/form/FormItem";
import {useControlColourName} from "../../theme/Theme";
import {Checkbox} from "@mui/material";
import {FormAnswerSetter} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import {MouseEventHandler} from "react";

const FormTickView = ({item, startAnswer, handleLink, answer, setAnswer, validate}: { item: TickEntry, startAnswer: () => void, handleLink: MouseEventHandler<HTMLAnchorElement>, answer: FormAnswerType, setAnswer: FormAnswerSetter, validate: boolean }) => {
    const setChecked = checked => setAnswer(checked ? "true" : "");
    const isChecked = answer === "true";
    const isError = validate && item.required && !item.numberAnswered(answer);
    const colour = useControlColourName();
    return <div id={item.id} onFocus={startAnswer} style={{display: "flex", alignItems: "center"}}>
        <Checkbox color={colour} checked={isChecked} onChange={e => setChecked(e.target.checked)}/>
        <FormQuestionText handleLink={handleLink} item={item} style={{color: isError ? "red" : "", margin: 8}} onClick={() => setChecked(!isChecked)} showOptionalLabel={false}/>
    </div>;
};

export default FormTickView;
