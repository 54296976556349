import {Chip} from "@mui/material";
import * as Str from "../../../strings/Str";
import Dimens from "../../../theme/Dimens";
import {useSecondaryColour} from "../../../theme/Theme";
import * as FormUtil from "../../../util/form/FormUtil";

/**
 * @param item A form item. Does NOT handle records
 * @param answer The raw answer entered by the user
 * @return {React.ReactNode} Null if the answer can't be parsed, else the formatted answer
 */
const getAnswerForItem = (item, answer) => {
    if (item.type === "file") {
        try {
            const {originalName} = JSON.parse(answer);
            return Str.file_colon(originalName);
        } catch (e) {
            return null;
        }
    } else if (item.type === "telephone") {
        try {
            const {code, text} = JSON.parse(answer);
            return code + text;
        } catch (e) {
            return null;
        }
    } else if (item.type === "radioWithId") {
        const currentOption = item.options.find(e => e.uid == answer);
        return currentOption?.name;
    } else if (item.type === "multi_select") {
        try {
            const ids = JSON.parse(answer);
            const options = item.options;
            const selectedOptions = options.filter(e => ids.includes(e.id));
            const selectedOptionLabels = selectedOptions.map(e => e.label);
            return (
                <div style={{display: "flex", flexWrap: "wrap", gap: Dimens.HalfMargin}}>
                    {selectedOptionLabels.map(label => <Chip key={label} label={label}/>)}
                </div>
            );
        } catch (e) {
            return null;
        }
    } else {
        return answer;
    }
};

export const FormItemAnswerPreview = ({item, answer}) => {
    const secondary = useSecondaryColour();
    if (item.type === "records") {
        let result = [];
        try {
            answer = JSON.parse(answer);
        } catch (e) {
            answer = [];
        }

        answer.forEach((a, i) => {
            const title = item.question + " " + (i + 1);

            result.push(<h2 style={{color: secondary}}>{title}</h2>);

            const ans = {};
            a.forEach(thisAns => {
                ans[thisAns.question] = thisAns.answer;
            });

            result = result.concat(item.items.map(item => <FormItemAnswerPreview key={item.id} answer={ans[item.id]} item={item}/>));
        });
        return <div style={{marginLeft: 64}}>{result}</div>;
    } else {
        const formattedAnswer = getAnswerForItem(item, answer);
        const hasAnswered = formattedAnswer != null && formattedAnswer !== "";
        return (
            <div>
                <p style={{fontWeight: 600}}>{item.question}</p>
                {hasAnswered ? formattedAnswer : <i>{Str.unanswered()}</i>}
            </div>
        );
    }
};

const Subsection = ({subsection, allItems, answers}) => {
    const items = FormUtil.filterShowingItems(subsection.items, allItems, answers).filter(item => {
        return item.type !== "heading" && item.type !== "info" && item.type !== "tickbox";
    });
    const secondaryColor = useSecondaryColour();
    if (items.length === 0) return null;
    return (
        <div>
            <p style={{fontWeight: 600, fontSize: 24, color: secondaryColor}}>{subsection.name}</p>
            {items.map(item => <FormItemAnswerPreview key={item.id} item={item} answer={answers[item.id]}/>)}
        </div>
    );
};

const Section = ({section, allItems, answers}) => section.subsections.map(subsection => <Subsection key={subsection.name} answers={answers} subsection={subsection} allItems={allItems}/>);

const DocumentType = ({type, documents}) => {
    const secondary = useSecondaryColour();
    const items = documents;
    if (items.length === 0) return null;
    return (
        <div>
            <p style={{fontWeight: 600, fontSize: 24, color: secondary}}>{type.name}</p>
            {items.map(d => <p key={d.name}>{d.name}.{d.fileType}</p>)}
        </div>
    );
};

const ViewFormDetails = ({formSections, answers, documents, documentTypes}) => {
    const allItems = formSections.flatMap(section => section.subsections.flatMap(subsection => subsection.items));
    return <div style={{padding: 16}} className="noSelect">
        {formSections.map((section, index) => <Section key={index} answers={answers} section={section} allItems={allItems}/>)}
        {documentTypes.map(documentType => <DocumentType key={documentType.id} type={documentType} documents={documents.filter(d => d.documentType === documentType.id)}/>)}
    </div>;
};

export default ViewFormDetails;
