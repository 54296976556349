import {enabledSections, Section} from "../Tab";
import {Tab, Tabs} from "@mui/material";
import {ChangeEvent, ReactNode} from "react";
import Dimens from "../theme/Dimens";
import {useGfTheme} from "../theme/GFThemeProvider";
import Colours from "../theme/Colours";

const tabRoot = {
    textTransform: "none",
    font: "inherit",
    flex: "0 1 100%",
    opacity: 1,
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    padding: "6px 12px",
    "&:hover": {
        opacity: 0.8
    }
};

const selected = {
    background: Colours.selectedTabColor,
    "&:hover": {
        opacity: 1
    }
};

const rootWithBorder = {
    background: "linear-gradient(to bottom, rgba(150, 150, 150, 0.5), rgba(150, 150, 150, 0.5))",
    backgroundRepeat: "no-repeat",
    backgroundSize: "2px 90%",
    backgroundPosition: 0
};

interface HomeTabType {
    id: number;
}

export const HomeTab: HomeTabType = {
    id: -1
};

export type SectionTabDestination = Section | HomeTabType;

export const SectionTabs = ({currentSection, onSectionSelected, onHomeSelected}: { currentSection: SectionTabDestination, onSectionSelected: (section: Section) => void, onHomeSelected: () => void }) => {
    const handleChange = (event: ChangeEvent<{}>, index: number) => { 
        onSectionSelected(enabledSections[index]);
    };
    const theme = useGfTheme()!;
    const selectedIndex = (enabledSections.indexOf(currentSection));
    const sectionTabItems = enabledSections.map((section, index) => {
        return makeSectionTab(section.name, index, selectedIndex, theme);
    });
    const allTabItems = [ ...sectionTabItems];
    return (
        <Tabs
            value={selectedIndex}
            onChange={handleChange}
            sx={{height: "100%"}}
            TabIndicatorProps={{style: {background: "#000000", height: Dimens.TabIndicatorHeight}}}>
            {allTabItems}
        </Tabs>
    );
};

/**
 * Although this function looks like it could be a component, it can not
 * That's because of the way Tabs works, it expects its children to be instances of Tab, the tabs can't be wrapped in another component
 */
const makeSectionTab = (name: ReactNode, index: number, selectedIndex: number, theme: Section) => {
    const isFirst = index === 0;
    const isSelected = index === selectedIndex;
    const followsSelected = index === selectedIndex + 1;
    const showBorder = !isFirst && !isSelected && !followsSelected;
    const tabID = "tab-" + String(name).replaceAll(" ", "-").toLowerCase();
    const colour = {
        color: isSelected ? Colours.StandardTextActionBar : theme.colourOnPrimary
    };
    const style = { ...tabRoot, ...(showBorder && rootWithBorder), ...(isSelected && selected)};

    return (
        <Tab
            key={tabID}
            id={tabID}
            label={name}
            wrapped={true}
            sx={style}
            style={colour}
        />
    );
};