import {ApiCallError, ApiCallState, apiCallStateUtil} from "grantfairy-web-common";
import {ReactElement} from "react";
import LoadingScreen from "./LoadingScreen";

const ErrorView = ({error}: { error: ApiCallError }) => {
    return <p>{error.errorTitle} {error.errorMessage}</p>;
};

interface DataDependantProps<T> {
    apiCallState: ApiCallState<T>;
    makeChildren: (payload: T) => (ReactElement<any, any>);
}

/**
 * Renders a loading indicator, message message, or children, based on an apiCallState
 * @param props
 * @constructor
 */
function DataDependant<T>(props: DataDependantProps<T>) {
    const apiResponse = props.apiCallState;
    return apiCallStateUtil.unwrapResult(
        apiResponse,
        result => props.makeChildren(result.payload),
        error => <ErrorView error={error}/>,
        () => <LoadingScreen/>
    );
}

export default DataDependant;
