import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EnglishLanguageArticle} from "../../model/EnglishLanguageArticle";
import {ApiCallState, apiCallStateUtil} from "grantfairy-web-common";

const agencies = createSlice({
    name: "englishLanguageArticles",
    initialState: apiCallStateUtil.makeLoading<EnglishLanguageArticle[]>(),
    reducers: {
        request(state) {

        },
        got(state, action: PayloadAction<ApiCallState<EnglishLanguageArticle[]>>) {
            return action.payload;
        }
    }
});

export const reducer = agencies.reducer;
export const actions = agencies.actions;
