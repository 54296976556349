import {CourseDeepLink, DeepLinkUtil} from "./DeepLink";

it("Creates urls from deeplinks", () => {
    const deepLink = new CourseDeepLink("someID");
    const url = DeepLinkUtil.deepLinkToUrl(deepLink);
    expect(url).toBe("myriad://course?courseID=someID");
});

it("Creates deeplinks from urls", () => {
    const url = "myriad://course?courseID=someID";
    const actual = DeepLinkUtil.deepLinkFromUrl(url);
    const expected = new CourseDeepLink("someID");
    expect(actual).toEqual(expected);
});

describe("Returns null when creating deeplinks from invalid urls", () => {
    const urls = [
        "",
        "myriad://",
        "wrong://",
        "courses",
        "myriad://iDontExist?what=isThis&idontknow"
    ];
    test.each(urls)("Returns for for url '%s'", (url) => {
        const actual = DeepLinkUtil.deepLinkFromUrl(url);
        expect(actual).toBeNull();
    });
});
