import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {OutlinedButton, Paper} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as Assets from "../../Assets";
import {PrimaryButton} from "../../components/Buttons";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {sections} from "../../Tab";
import {GFThemeProvider} from "../../theme/GFThemeProvider";
import {mainSectionTheme, useControlColourName} from "../../theme/Theme";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import * as FormUtil from "../../util/form/FormUtil";
import DataDependant from "../../views/DataDependant";
import {FormItemView} from "../../views/form/FormItemView";
import {SignUpTitle, SignUpSubtitle} from "../../views/Text";
import ServerSidePopup from "../ServerSidePopup";
import {mapProfileFormItems} from "../../Profile";
import { BackButton } from "../../components/Buttons";
import Colours from "../../theme/Colours";
import {Title} from "@mantine/core";

const ProfileScreen = ({form, answers, onSetAnswer}) => {
    const items = form[0].subsections[0].items;
    const itemsToShow = FormUtil.filterShowingItems(items, items, answers.payload);
    const [validate, setValidate] = useState(false);
    const parsedAnswers = answers.payload ?? {};
    mapProfileFormItems(items);

    const hasInvalidAnswers = () => {
        const invalids = items.filter(e => {
            if (!e.required) return false;
            const mappedAnswer = parsedAnswers[e.id];
            return !e.numberAnswered(mappedAnswer, parsedAnswers);
        });
        return invalids.length > 0;
    };

    const onNext = () => {
        if (hasInvalidAnswers()) {
            setValidate(true);
        } else {
            setValidate(false);
            window.open("/", "_self");
        }
    };

    const handleSetAnswer = (item, answer) => {
        onSetAnswer(item.id, answer);
    };

    return (
        <DataDependant data={answers}>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start"}} className="noSelect">
                <Paper
                    style={{padding: "16px 32px", margin: 32, width: "40cm", maxWidth: "90vw", maxHeight: "calc(100% - 96px)", overflowY: "auto"}}>
                    <div style={{display: "flex", alignItems: "flex-start", gap: 64, background: "#ffffff", padding: 40, borderRadius: 16}}>
                        <div style={{textAlign: "center", flex: "0 0 calc(40% - 32px)", paddingTop: 32}}>
                            <img alt="logo" src={require("../../" + Assets.UCAS_LOGO_BRANDED)} style={{width: 200, height: 200, marginBottom: 64}}/>
                            <div style={{
                                backgroundColor: Colours.ProfileInfoPanelBackground,
                                textAlign: "start",
                                justifyContent: "center",
                                width: 400,
                                borderRadius: 12,
                                paddingTop: 225,
                                paddingBottom: 32,
                                paddingLeft: 16,
                                paddingRight: 16,
                                marginTop: 200
                            }}>
                                <Title style={{color: Colours.ProfileInfoPanelTitle, fontSize: 48, fontFamily: "Oswald", paddingBottom: 4}}>UCAS INTERNATIONAL IN YOUR POCKET</Title>
                                <p style={{whiteSpace: "pre-line", color: Colours.ProfileInfoPanelText, paddingBottom: 32}}>Scan the QR code below from your mobile device to download the Android and
                                    iOS app.</p>
                                <div style={{textAlign: "center"}}>
                                    <img style={{width: 200}} src={require("../../" + Assets.DOWNLOAD_QR)} alt="download_qr"/>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: "0 0 calc(60% - 32px)"}}>
                            {itemsToShow.map(item => (<FormItemView key={item.id} item={item} formName="Sign up" answer={parsedAnswers[item.id]} setAnswer={a => handleSetAnswer(item, a)} validate={validate} allItems={items}
                                                                     allAnswers={parsedAnswers} handleLink={()=>{}} onViewFile={()=>{}} onDeleteFile={()=>{}} onUploadFile={()=>{}}/>))}

                            <div style={{display: "flex", justifyContent: "center", padding: 16}}>
                                <PrimaryButton style={{paddingLeft: 64, paddingRight: 64}} onClick={onNext}>{Str.complete()}</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </DataDependant>
    );
};

const VerificationScreen = ({email, onReload, onResend, onCancel}) => {
    const buttonColor = useControlColourName();
    return (
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} className="noSelect">
            <Paper className="contentColumn" style={{padding: "16px 32px", margin: 32, maxHeight: "calc(100% - 96px)", overflowY: "auto"}}>
                <div onClick={onCancel}>
                    <BackButton title="Back to sign up" />
                </div>
                <div style={{textAlign: "center", width: "100%", paddingTop: 0}}>
                    <img alt="logo" src={require("../../" + Assets.UCAS_LOGO_BRANDED)} style={{width: 150, height: 124, marginBottom: -30}}/>
                </div>
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <SignUpTitle>
                        {Str.verifyEmail()}
                    </SignUpTitle>
                    <SignUpSubtitle style={{whiteSpace: "pre-wrap"}}>
                        {Str.email_not_verified_title()}
                    </SignUpSubtitle>
                    <SignUpSubtitle style={{whiteSpace: "pre-wrap", maxWidth: "calc(100% - 96px)", textAlign: "center"}}>
                        {Str.email_not_verified(email)}
                    </SignUpSubtitle>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 24 }}>
                    <PrimaryButton buttonColor={buttonColor} style={{padding:"8px 50px"}} onClick={onReload}>{Str.reload_signup()}</PrimaryButton>
                    <div style={{height: 16}}/>
                    <OutlinedButton buttonColor={buttonColor} style={{padding:"8px 50px"}} onClick={onResend}>{Str.resend_signup_verification()}</OutlinedButton>
                </div>
            </Paper>
        </div>
    );
};

const SignupScreen = ({needsVerification, ...props}) => {
    const backgroundImage = require("../../" + Assets.UCAS_LOGIN_BACKGROUND);
    useEffect(() => {
        if (needsVerification && props.form == null)
        {
            props.fetchOnboarding();
        }
    }, [needsVerification, props.form]);
    return (
        <div style={{height: "100vh", backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <ServerSidePopup/>
            {needsVerification && <VerificationScreen email={props.email} onReload={props.onReload} onResend={props.onResend} onCancel={props.onCancel} {...props}/>}
            {!needsVerification && <DataDependant data={props.form}><ProfileScreen form={props.form.payload ?? []} answers={props.answers} onSetAnswer={props.onSetAnswer}/></DataDependant>}
        </div>
    );
};

const Container = ({answers, ...props}) => {
    useEffect(() => {
        if (!props.needsVerification){
            props.onOpenOnboarding();
        }
    }, [props.onOpenOnboarding, props.needsVerification]);
    return (
        <MuiThemeProvider theme={mainSectionTheme}>
            <GFThemeProvider value={sections.Main}>
                <SignupScreen needsVerification={props.needsVerification} answers={answers} {...props}/>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export const mapStateToProps = (state) => ({
    form: selectors.onboardingForm(state) ?? null,
    answers: selectors.userOnboarding(state) ?? null,
    email: FirebaseUtil.currentUser()?.email,
    needsVerification: selectors.needsEmailVerification(state)?.payload ?? true
});

export const mapDispatchToProps = dispatch => ({
    onOpenOnboarding: () => dispatch(actions.requestOnboarding()),
    onSetAnswer: (questionID, answer) => {
        dispatch(actions.setOnboardingField(questionID, answer));
    },
    onReload: () => dispatch(actions.requestLogin(false)),
    onResend: () => dispatch(actions.requestLogin(true)),
    onCancel: () => dispatch(actions.logout()),
    fetchOnboarding: () => dispatch(actions.requestOnboarding())
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;
