import {LearnerClicksReferralButtonEvent} from "../../model/AnalyticsEvents";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import * as Str from "../../strings/Str";
import {currentUser} from "../../util/FirebaseUtil";

export function handleProviderAnalytics(universityName, universityID, universityLocation, universityWebsite) {
    return () => {
        const userId = currentUser()?.uid ?? "";
        new GoogleAnalyticsService().logEvent(new LearnerClicksReferralButtonEvent("apply_university", userId, universityName, universityLocation, universityID.toString(), Str.website(), universityWebsite));
    };
}