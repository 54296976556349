import {createContext, useContext, useEffect} from "react";
import {AnalyticsService, StandardAnalyticsService} from "./AnalyticsService";
import {CustomAnalyticsService} from "./CustomAnalyticsService";
import {GoogleAnalyticsService} from "./GoogleAnalyticsService";
import {createBrowserHistory} from "history";

const AnalyticsContext = createContext<AnalyticsService>(new StandardAnalyticsService([]));

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({children, service}) => (
    <AnalyticsContext.Provider value={service}>
        {children}
    </AnalyticsContext.Provider>
);

export const StandardAnalyticsProvider = ({children}) => {
    const childServices = [new GoogleAnalyticsService(), new CustomAnalyticsService()];
    const service = new StandardAnalyticsService(childServices);
    const history = createBrowserHistory();

    useEffect(() => {
        const cleanup = history.listen(event => {
            service.logPageView(event.location.pathname);
        });
        return cleanup;
    }, []);

    return (<AnalyticsProvider service={service} children={children}/>);
};