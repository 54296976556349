import {TextField} from "@mui/material";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import * as Str from "../strings/Str";

const SearchBar = ({search, setSearch, ...props}) => {
    const id = "input-search_text_field";
    const inputProps = {
        endAdornment: (
            <InputAdornment position="end">
                <Icon>search</Icon>
            </InputAdornment>
        )
    };
    return (
        <TextField id={id} {...props} InputProps={inputProps} size="small" variant="outlined" color="secondary" style={{width: "100%"}} value={search} onChange={e => setSearch(e.target.value)}
                   placeholder={Str.search()}/>
    );
};

export default SearchBar;