import Dimens from "../../theme/Dimens";
import {useTextColour} from "../../theme/Theme";
import * as Str from "../../strings/Str";

const UniversitySpecificQuestionIndicator = ({providerName}: { providerName: string }) => {
    return (
        <div>
            <div style={{background: "#EEE", color: useTextColour(), padding: `${Dimens.HalfMargin}px ${Dimens.StandardMargin}px`, margin: `${Dimens.StandardMargin}px 0`, width: "auto", display: "inline-block", borderRadius: 4}}>
                <b>{Str.question_asked_by(providerName)}</b>
            </div>
        </div>
    );
};

export default UniversitySpecificQuestionIndicator;
