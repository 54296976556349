import {ApiResultState} from "../../util/ApiResult";
import {mapStateToProps} from "./AccommodationFavouritesTab";

describe("map state to props", () => {
    it("mapStateToProps has properties", () => {
        const properties = [1, 3, 5];
        const result = {state: ApiResultState.Success, payload: properties};
        const props = mapStateToProps({accommodationFavourites: result}, {propertyID: null});
        expect(props.properties).toEqual(result);
    });

    it("mapStateToProps has details url", () => {
        const props = mapStateToProps({}, {propertyID: null});
        expect(props.makeUrlForPropertyDetails({uid: 94})).toEqual("/accommodation/favourites/94/details");
    });

    it("mapStateToProps has select url", () => {
        const props = mapStateToProps({}, {propertyID: null});
        expect(props.makeUrlForProperty({uid: 94})).toEqual("/accommodation/favourites/94");
    });

    it("mapStateToProps has browse url", () => {
        const props = mapStateToProps({}, {propertyID: null});
        expect(props.browseURL).toEqual("/accommodation/search");
    });

    it("mapStateToProps passes through selected", () => {
        const testID = 342;
        const props = mapStateToProps({}, {propertyID: testID});
        expect(props.selectedPropertyId).toEqual(testID);
    });
});