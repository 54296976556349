import {AspectFitImage} from "grantfairy-web-common";
import {Subtitle} from "../../views/Text";
import FavouriteButton from "../../views/FavouriteButton";
import {Job} from "../../model/Job";
import * as Str from "../../strings/Str";
import moment from "moment";
import {ReactElement} from "react";
import Colours from "../../theme/Colours";
import "../../theme/Card.css";
import {useNavigate} from "react-router-dom";
import {currentUser} from "../../util/FirebaseUtil";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";

interface JobListItemProps {
    job: Job;
    isSelected: boolean;
    isFave: boolean;
    onFave: (isFave: boolean) => void;
    url: string;
    logSearchQuery: (jobId: number) => void;
}

export const JobListItem = ({job, isSelected, isFave, onFave, url, logSearchQuery}: JobListItemProps) => {
    const datePosted = job.posted == null ? "" : moment(job.posted).format("DD/MM/yyyy");
    const infoColor = job.valid ? "#555" : "#AAA";
    const mainColor = job.valid ? "" : "#AAA";
    const style = {borderBottom: "1px solid #CCC", background: isSelected ? Colours.RowHighlight : "", color: mainColor, padding: "10px"};
    const nav = useNavigate();

    const onClick = () => {
        if (job.valid) {
            nav(url);
        }
        logSearchQuery(job.id);
    };

    return (
        <div style={style} className="card noSelect" onClick={onClick}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", alignItems: "center", maxHeight: "150px", minHeight: "120px", padding: "16px"}}>
                    <AspectFitImage backgroundSize="contain" style={{width: 100, height: 100}} src={job.logo}/>
                    <div style={{flex: 1, marginLeft: 16, marginRight: 16}}>
                        <p style={{color: infoColor, margin: "8px 0 0 0"}}>{Str.date_posted(datePosted)}</p>
                        <h1 className="salary-header">{job.title}</h1>
                    </div>
                    <div className="favourite-item">
                        <FavouriteButton favourited={isFave} onFave={onFave} itemID={String(job.id)}/>
                    </div>
                </div>
                <p className="salary-rate-block" style={{padding: 16}}>
                    {job.salary != null && job.salary}
                    {job.salary == null && "No salary information available"}
                </p>
                {!job.valid && <p style={{color: "red", textAlign: "center"}}>{Str.job_unavailable_exp()}</p>}
            </div>
        </div>
    );
};

interface JobListProps {
    jobs: Job[];
    selectedJobId: number | null;
    makeUrlForJob: (job: Job) => string;
    favouriteIDs: number[];
    onFave: (job: Job, isFave: boolean) => void;
    searchCategory: string;
    searchTerm: string;
}

export const JobList = ({jobs, selectedJobId, makeUrlForJob, favouriteIDs, onFave, searchCategory, searchTerm = ""}: JobListProps): ReactElement => {
    if (jobs.length === 0) {
        return <Subtitle style={{textAlign: "center"}}>{Str.no_search_results()}</Subtitle>;
    }
    const justifyContent = (jobs.length > 2) ? "space-between" : "space-around";
    const logSearchQueryHandler = (jobId) => {
        const user = currentUser();
        const jobRanking = (jobs.findIndex(j => j.id === jobId) + 1).toString() ?? "";
        const jobUrl = makeUrlForJob(jobs.find(j => j.id === jobId)!);
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid, searchCategory, searchTerm, jobRanking, jobId, jobUrl));
    };

    const listItems = jobs.map(e => <JobListItem key={e.id} isFave={favouriteIDs.includes(e.id)} job={e} isSelected={e.id === selectedJobId}
                                                 url={makeUrlForJob(e)} onFave={fave => onFave(e, fave)} logSearchQuery={logSearchQueryHandler}/>);
    return <div style={{overflowY: "auto", flexFlow: "wrap", justifyContent: justifyContent, display: "flex", padding: "10px"}}>{listItems}</div>;
};