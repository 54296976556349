import {useTextColour} from "../../../theme/Theme";
import * as formSectionExpandedStateSlice from "../../../redux/slices/formSectionExpandedState";
import Icon from "@mui/material/Icon";
import Dimens from "../../../theme/Dimens";
import {Subtitle} from "../../../views/Text";
import {OptionalityIndicator} from "../../../components/OptionalityIndicator";
import * as Str from "../../../strings/Str";
import {PercentIcon} from "../../../components/PercentIcon";
import PlainLink from "../../../components/PlainLink";
import * as selectors from "../../../redux/selectors";
import {connect} from "react-redux";
import {FormSection, FormSubsection} from "../../../util/form/FormSection";
import {FormItem} from "../../../util/form/FormItem";
import {StringDictionary} from "../../../types/StringDictionary";
import ContentWithBorder from "../../../components/Border";
import {GoogleAnalyticsService} from "../../../services/analytics/GoogleAnalyticsService";
import {LearnerEntersApplicationSection} from "../../../model/AnalyticsEvents";
import {currentUser} from "../../../util/FirebaseUtil";

interface SubsectionListProps {
    subsections: FormSubsection[];
    showErrors: boolean;
    allItems: FormItem[];
    answers: StringDictionary;
    makeLinkForSubsection: (subsection: FormSubsection) => string;
    applicationID: string;
}

const SubsectionList = ({subsections, showErrors, allItems, answers, makeLinkForSubsection, applicationID = ""}: SubsectionListProps) => {
    const subsectionList = subsections.map(subsection => {
        const titleColour = (subsection.isComplete(allItems, answers) || !showErrors) ? "" : "red";
        const subsectionContainsRequiredFields = subsection.totalShowingRequired(allItems, answers) > 0;
        const logSectionOpenHandler = () => {
            const userID = currentUser()?.uid ?? "";
            new GoogleAnalyticsService().logEvent(new LearnerEntersApplicationSection(userID, subsection.name, subsection.getPercent(allItems, answers).toString(), applicationID));
        };
        return (
            <PlainLink to={makeLinkForSubsection(subsection)} key={subsection.name} onClick={logSectionOpenHandler}>
                <div  id={subsection.name} style={{padding: "12px 16px", borderBottom: "1px solid #CCC", display: "flex", alignItems: "center"}} className="noSelect">
                    <div style={{flex: 1, marginLeft: 48}}>
                        <Subtitle style={{margin: 0, color: titleColour}}>{subsection.name}<OptionalityIndicator markWithAsterisk={subsectionContainsRequiredFields} showOptionalLabel={true}/></Subtitle>
                    </div>
                    <PercentIcon percent={subsection.getPercent(allItems, answers)} size={48}/>
                    <div style={{width: 8}}/>
                </div>
            </PlainLink>
        );
    });

    return (
        <>{subsectionList}</>
    );
};

interface FormMenuItemProps {
    sectionID: string;
    section: FormSection;
    showErrors: boolean;
    allItems: FormItem[];
    answers: StringDictionary;
    applicationID: string;
    makeLinkForSubsection: (subsection: FormSubsection) => string;
    providerName: string;
    sectionIsExpanded: boolean;
    setSectionIsExpanded: (sectionID: string) => void;
}

const FormMenuItem = ({sectionID, section, showErrors, allItems, answers, applicationID, makeLinkForSubsection, providerName, sectionIsExpanded, setSectionIsExpanded}: FormMenuItemProps) => {
    const expandable = section.subsections.length > 1;
    const titleColour = (section.isComplete(allItems, answers) || !showErrors) ? "" : "red";
    const sectionContainsSubsectionsWithRequiredFields = section.containsSubsectionsWithRequiredFields(allItems, answers);
    const sectionContainsUSQs = section.containsSubsectionsWithUniversitySpecificQuestions(allItems, answers);

    const accordion = !expandable ? "" : sectionIsExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down";
    const textColor = useTextColour();
    const handleSectionExpansion = () => {
        if (expandable) {
            setSectionIsExpanded(sectionID);
        }
    };

    const header = (
        <ContentWithBorder showContent={sectionIsExpanded}>
            <div id={sectionID + "-header"} style={{padding: "4px 16px", borderBottom: "1px solid #CCC", display: "flex", alignItems: "center"}} onClick={() => handleSectionExpansion()} className="noSelect">
                <Icon style={{color: "#888"}}>{accordion}</Icon>
                <div style={{flex: 1, marginLeft: 16, display: "flex", flexDirection: "column", gap: Dimens.QuarterMargin}}>
                    <Subtitle style={{margin: 0, color: titleColour}}>
                        {section.name}<OptionalityIndicator markWithAsterisk={sectionContainsSubsectionsWithRequiredFields} showOptionalLabel={true}/>
                    </Subtitle>
                    {sectionContainsUSQs && <p style={{margin: 0, color: textColor, fontWeight: 600}}>{Str.provider_requires_info(providerName)}</p>}
                </div>
                <PercentIcon percent={section.getPercent(allItems, answers)} size={64}/>
            </div>
        </ContentWithBorder>
    );
    if (sectionIsExpanded) {
        return (
            <>
                {header}
                <SubsectionList showErrors={showErrors} allItems={allItems} answers={answers} applicationID={applicationID} subsections={section.subsections} makeLinkForSubsection={makeLinkForSubsection}/>
            </>
        );
    }
    return expandable ? header : <PlainLink to={makeLinkForSubsection(section.subsections[0])}>{header}</PlainLink>;
};

export const mapStateToProps = (state, {sectionID, ...props}) => ({
    sectionIsExpanded: selectors.applicationSectionIsExpanded(state, sectionID),
    sectionID,
    ...props
});

export const mapDispatchToProps = (dispatch) => ({
    setSectionIsExpanded: (sectionID: string) => dispatch(formSectionExpandedStateSlice.actions.toggleSectionExpanded(sectionID))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(FormMenuItem);

export default Connected;