export enum JobLevel {
    PartTime = "PART_TIME",
    FullTime = "FULL_TIME",
    Graduate = "GRADUATE"
}

export enum JobSort {
    Relevance = "RELEVANCE",
    SalaryHigh = "SALARY_HIGH",
    SalaryLow = "SALARY_LOW",
    Recency = "RECENCY",
    Closest = "CLOSEST",
}

export interface JobSearchFilters {
    searchQuery: string;
    jobLevel: JobLevel;
    location: string;
    distance: number;
    sort: JobSort;
}

export const emptyJobSearchFilters: JobSearchFilters = {searchQuery: "", jobLevel: JobLevel.PartTime, location: "", distance: 5, sort: JobSort.Relevance};

export const serialiseJobFilters = (filters: JobSearchFilters): string => {
    return JSON.stringify(filters);
};