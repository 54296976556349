import {useEffect} from "react";
import {connect} from "react-redux";
import * as selectors from "../redux/selectors";
import * as StorageKeys from "../StorageKeys";
import {Language} from "../model/Language";

/**
 * This component listens to the current language and the language options. If the current language is changed from what is stored in localStorage,
 * it updates localStorage then reloads the entire webpage, which results in all the strings on the page being updated
 */

const LanguageService = ({currentLanguageID, languageOptions}: { currentLanguageID: string | null, languageOptions: Language[] }) => {

    useEffect(() => {
        if (languageOptions.length > 0 && currentLanguageID !== null) {
            const current = languageOptions.filter(e => e.uid === currentLanguageID)[0];
            if (current == null) return;
            const currentCode = current.code;
            const savedCode = localStorage.getItem(StorageKeys.LANGUAGE_CODE);
            if (savedCode !== currentCode) {
                localStorage.setItem(StorageKeys.LANGUAGE_CODE, currentCode);
                window.location.reload();
            }
        }
    }, [currentLanguageID, languageOptions]);

    return null;
};

const mapStateToProps = state => ({
    currentLanguageID: selectors.currentLanguageID(state),
    languageOptions: selectors.languageOptions(state)
});

const Connected = connect(mapStateToProps)(LanguageService);

export default Connected;