import {Dialog, DialogActions, DialogContent} from "@mui/material";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import FundingPaymentView from "./FundingPaymentView";

const Container = ({option, onPaid}) => {
    return (
        <div style={{padding: 32, textAlign: "center"}}>
            {/* TODO hardcoded strings */}
            <h2>Purchasing {Str.app_name()} for {option.length} {option.period} for £{option.totalPrice}</h2>

            <FundingPaymentView planID={option.sku} onPaid={onPaid}/>
        </div>
    );
};

const Popup = ({onClose, ...props}) => (
    <Dialog open={true} maxWidth="lg" fullWidth>
        <DialogContent style={{padding: 0}}>
            <div style={{minHeight: 200}}>
                <Container {...props}/>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={onClose}>{Str.cancel()}</Button>
        </DialogActions>
    </Dialog>
);

const mapStateToProps = (state, props) => ({
    wishlistCount: selectors.fundingWishlistCount(state)?.payload ?? 0,
    wishlistAmount: selectors.fundingWishlistAmount(state)?.payload ?? 0,
    ...props
});

const mapDispatchToProps = (dispatch, {onClose}) => ({
    onPaid: () => {
        dispatch(actions.didSubscribeFunding());
        onClose();
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Popup);

export default Connected;
