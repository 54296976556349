import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Route, Routes} from "react-router-dom";
import Alerts from "../Alerts";
import UploadingDocumentPopup from "../components/UploadingDocumentPopup";
import MoreTab from "../screens/main/MoreTab";
import UniversityFavouritesTab from "../screens/universities/UniversityFavouritesTab";
import UniversitySearchTab from "../screens/universities/UniversitySearchTab";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {universitiesSectionTheme} from "../theme/Theme";
import {UniversitySectionActionBar} from "../views/ActionBar";

const Router = () => {
    return (
        <MuiThemeProvider theme={universitiesSectionTheme}>
            <GFThemeProvider value={sections.Universities}>
                <Alerts/>
                <UploadingDocumentPopup/>
                <div className="universitySection" style={{display: "flex", flexDirection: "column", height: "100vh"}}>
                    <UniversitySectionActionBar/>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Routes>
                            <Route key="search" path="/search/:universityID" element={<UniversitySearchTab />}/>
                            <Route key="searchNoSelection" path="/search" element={<UniversitySearchTab />}/>

                            <Route key="favourites" path="/favourites/:universityID" element={<UniversityFavouritesTab />}/>
                            <Route key="favouritesNoSelection" path="/favourites" element={<UniversityFavouritesTab />}/>

                            <Route key="more" path="/more" element={<MoreTab />}/>
                        </Routes>
                    </div>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default Router;