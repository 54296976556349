import {useEffect} from "react";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import {history} from "../redux/store";

/**
 * This component listens to the browser history and keeps the redux store up to date with it
 */

const HistoryService = ({onPathChanged}: { onPathChanged: (newPath: string) => void }) => {
    useEffect(() => {
        const cleanup = history.listen(event => {
            onPathChanged(event.location.pathname);
        });
        return cleanup;
    }, []);

    return null;
};

const mapDispatchToProps = dispatch => ({
    onPathChanged: path => dispatch(actions.pathChanged(path))
});

const Connected = connect(null, mapDispatchToProps)(HistoryService);

export default Connected;