import {BasicCourseInfo} from "../model/Course";
import Colours from "../theme/Colours";
import PlainLink from "../components/PlainLink";
import {Subtitle} from "./Text";
import FavouriteButton from "./FavouriteButton";
import * as FeatureFlags from "../FeatureFlags";
import LinkToApplyPage from "./LinkToApplyPage";
import Styles from "../theme/Styles";
import Dimens from "../theme/Dimens";

export interface CourseListItemProps {
    course: BasicCourseInfo;
    isSelected: boolean;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (isFave: boolean) => void;
    isFave: boolean;
    makeApplyUrlForCourse: (courseId: string) => string;
    showProviderInfo: boolean;
}

export const CourseListItem = ({course, isSelected, makeUrlForCourse, isFave, onFave, makeApplyUrlForCourse, showProviderInfo}: CourseListItemProps) => {
    const style = {padding: Dimens.StandardMargin, borderBottom: Styles.standardBorder, display: "flex", alignItems: "center", background: isSelected ? Colours.RowHighlight : ""};
    return (
        <PlainLink to={makeUrlForCourse(course.id)} id={"course-list-course-" + course.id}>
            <div style={style} className="noSelect">
                {showProviderInfo && <img style={{width: 48, height: 48}} src={course.providerIconUrl} alt=""/>}
                <div style={{flex: 1, marginLeft: Dimens.StandardMargin}}>
                    <Subtitle style={{margin: 0}}>{course.title}</Subtitle>
                    {showProviderInfo && <p style={{color: Colours.secondaryText, margin: `${Dimens.HalfMargin}px 0 0 0`}}>{course.providerName}</p>}
                    <p style={{color: Colours.secondaryText, margin: `${Dimens.HalfMargin}px 0 0 0`}}>{course.outcomes}</p>
                </div>

                <div style={{textAlign: "center"}}>
                    <FavouriteButton onFave={onFave} favourited={isFave} itemID={course.id}/><br/>
                    {FeatureFlags.COURSE_LIST_APPLY_BUTTONS_ENABLED && <LinkToApplyPage link={makeApplyUrlForCourse(course.id)} canApply={course.canApply}/>}
                </div>
            </div>
        </PlainLink>
    );
};

export default CourseListItem;
