import {Delete} from "@mui/icons-material";
import {DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import {Asterisk, useFileUpload} from "grantfairy-web-common";
import moment from "moment";
import {useState} from "react";
import {connect} from "react-redux";
import {useAlert} from "../../../Alerts";
import * as Applications from "../../../Applications";
import {PrimaryButton} from "../../../components/Buttons";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import {useControlColourName, useTextColour} from "../../../theme/Theme";
import DataDependant from "../../../views/DataDependant";
import {FormItemView} from "../../../views/form/FormItemView";
import {Subtitle} from "../../../views/Text";
import BlockInfoView from "../../../views/form/BlockInfoView";

const PasteTextAlert = ({documentType, onDone, onClose, open}) => {

    const [text, setText] = useState("");
    const colour = useControlColourName();

    const charactersUsed = text.trim().replace(/\n/g, "").length;

    const tooManyChars = charactersUsed > documentType.characterLimit;

    const handleDone = () => {
        if (!tooManyChars) {
            onDone(text.trim());
        }
    };

    return (
        <Dialog open={open} maxWidth="lg" fullWidth>
            <DialogTitle style={{margin: "16px 16px 0 16px", padding: 0}}>{documentType.name}</DialogTitle>
            <div style={{padding: 16}}>
                <TextField variant="outlined" multiline rows={20} style={{width: "100%"}} value={text} onChange={e => setText(e.target.value)}/>
            </div>
            <p style={{color: tooManyChars ? "red" : "#888", margin: 0, marginLeft: 16}}>{charactersUsed} / {documentType.characterLimit}</p>
            <DialogActions>
                <Button color={colour} onClick={onClose}>{Str.cancel()}</Button>
                <Button disabled={charactersUsed === 0} color={colour} onClick={handleDone}>{Str.save()}</Button>
            </DialogActions>
        </Dialog>
    );
};

const AddDocumentRow = ({documentType, onUpload, onPaste}) => {
    const style = {padding: 16, borderBottom: "1px solid #CCC", background: "#EEE", textAlign: "center"};

    const [alert, showAlert] = useAlert();

    const [pasteOpen, setPasteOpen] = useState(false);

    const onFilePicked = file => {
        if (file == null) return;
        Applications.validateFileForDocument(documentType, file, validate => {
            if (validate.valid) {
                onUpload(file, file.name, documentType.id);
            } else {
                showAlert(validate.errorTitle, validate.error, Str.okay());
            }
        });
    };

    const handlePaste = text => {
        setPasteOpen(false);
        onPaste(text, documentType.id, documentType.name + "-" + moment().format("DD.MM.YY-HH.mm.ss") + ".txt");
    };

    const [filePicker, openFilePicker] = useFileUpload(documentType.fileTypes.map(e => "." + e).join(","), onFilePicked);
    return (
        <div
            // @ts-ignore
            style={style} className="noSelect">
            {filePicker}
            {alert}
            <PasteTextAlert onDone={handlePaste} onClose={() => setPasteOpen(false)} open={pasteOpen} documentType={documentType}/>
            <p>{Str.accepted_file_types(documentType.fileTypes.join(", "))}</p>
            {documentType.fileTypes.includes("txt") && <PrimaryButton style={{marginRight: 64}} onClick={() => setPasteOpen(true)}>{Str.paste_text()}</PrimaryButton>}
            <PrimaryButton onClick={openFilePicker}>{Str.upload_new()}</PrimaryButton>
        </div>
    );
};

const DocumentListItem = ({document, onDelete, onView, showCheckboxes, isChecked, onChecked}) => {
    const style = {padding: 16, borderBottom: "1px solid #CCC", display: "flex", alignItems: "center", background: "#EEE"};
    const date = moment.unix(document.uploaded).format("DD/MM/YY HH:mm");

    const [alert, showAlert] = useAlert();
    const colour = useControlColourName();

    const handleDelete = () => {
        showAlert(Str.delete_doc(), Str.sure_want_delete(document.name), Str.delet(), Str.cancel(), onDelete);
    };

    const handleClick = () => {
        if (showCheckboxes) {
            onChecked(document, !isChecked);
        }
    };

    return (
        <div style={style} className="noSelect">
            {alert}
            <div style={{width: 48}}>
                {showCheckboxes && <Checkbox color={colour} checked={isChecked} onChange={e => onChecked(document, e.target.checked)}/>}
            </div>
            <div style={{flex: 1}} onClick={handleClick}>
                <Subtitle style={{margin: 0}}>{document.name}.{document.fileType}</Subtitle>
                <p style={{color: "#888", margin: "8px 0 0 0"}}>{Str.uploaded_colon(date)}</p>
            </div>
            <PrimaryButton onClick={onView}>{Str.view()}</PrimaryButton>
            <Button color={useControlColourName()} onClick={handleDelete}><Delete/></Button>
        </div>
    );
};

const DocumentList = ({documents, onDelete, onView, showCheckboxes, checkChecked, onChecked}) => {
    return documents.map((e, index) => <DocumentListItem document={e} onDelete={() => onDelete(e)} onView={() => onView(e)} showCheckboxes={showCheckboxes}
                                                         key={index} isChecked={showCheckboxes && checkChecked(e)} onChecked={onChecked}/>);
};

const ListItemHeader = ({isOpen, setIsOpen, showErrors, required, complete, title, info}) => {
    const accordion = isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down";
    const tickSrc = complete ? "tick_green.png" : "tick_grey.png";
    const tickAlt = complete ? Str.complete() : Str.incomplete();
    const toggleOpen = () => setIsOpen(e => !e);
    const isError = showErrors && required && !complete;
    const titleComponent = isError ? <span style={{color: "red"}}>{title}</span> : title;
    const asteriskColour = useTextColour();
    return (
        <div style={{padding: 16, borderBottom: "1px solid #CCC", display: "flex", alignItems: "center"}} onClick={toggleOpen} className="noSelect">
            <Icon style={{color: "#888"}}>{accordion}</Icon>
            <div style={{flex: 1, marginLeft: 16, marginRight: 16}}>
                <Subtitle style={{margin: 0}}>{titleComponent}{required ? <Asterisk colour={asteriskColour}/> : null}</Subtitle>
                {info && <BlockInfoView item={info}/>}
            </div>
            {required && <img alt={tickAlt} src={require("../../../assets/" + tickSrc)} style={{width: 48, height: 48}}/>}
        </div>
    );
};

const DocumentTypeListItem = ({
                                  documentType, documents, onUpload, onPaste, onDelete, onView = () => {
    }, showErrors = false, showCheckboxes = false, checkChecked = (_) => {
    }, onChecked = () => {
    }, startOpen = false
                              }) => {
    const [isOpen, setIsOpen] = useState(startOpen && documents.length > 0);

    const needsChecking = showCheckboxes && documents.filter(e => checkChecked(e)).length === 0;

    const header = (
        <ListItemHeader showErrors={showErrors} isOpen={isOpen} required={documentType.required} complete={documents.length > 0 && !needsChecking} title={documentType.name}
                        info={documentType.info} setIsOpen={setIsOpen}/>
    );

    if (isOpen) {
        const addRow = (documents.length === 0 || documentType.multiple) ? <AddDocumentRow documentType={documentType} onUpload={onUpload} onPaste={onPaste}/> : null;
        return (
            <>
                {header}
                <DocumentList documents={documents} onDelete={onDelete} onView={onView} showCheckboxes={showCheckboxes} checkChecked={checkChecked} onChecked={onChecked}/>
                {addRow}
            </>
        );
    }

    return header;
};

const AgreementsRow = ({agreements, answers, isComplete, onSetAnswer, showErrors = false}) => {
    const [isOpen, setIsOpen] = useState(false);

    const header = (
        <ListItemHeader showErrors={showErrors} isOpen={isOpen} required={true} complete={isComplete} title={Str.agreements()} info="" setIsOpen={setIsOpen}/>
    );

    if (isOpen) {
        const formView = agreements.map(item => <FormItemView key={item.id} formName="Agreements" item={item} answer={answers[item.id]} setAnswer={a => onSetAnswer(item.id, a)}
                                                              handleLink={() => {}} validate={false} onViewFile={() => {}} onDeleteFile={() => {}} onUploadFile={() => {}}
                                                              allAnswers={{}} allItems={[]}/>);
        return (
            <>
                {header}
                <div style={{borderBottom: "1px solid #ccc", padding: "8px 40px"}}>{formView}</div>
            </>
        );
    }

    return header;
};

const DocumentTypeList = ({documentTypes, documents, onUploadDocument = () => {}, onPasteDocument = () => {}, onDeleteDocument = () => {}, onViewDocument = () => {}, ...props}) => {
    return documentTypes.map(documentType => {
        const docs = documents.filter(d => d.documentType === documentType.id);
        return <DocumentTypeListItem {...props} documentType={documentType} documents={docs} onUpload={onUploadDocument} onPaste={onPasteDocument}
                                     onView={onViewDocument} onDelete={onDeleteDocument} key={documentType.id}/>;
    });
};

const Container = ({documentTypes, documents, agreements, onSetAnswer, answers, agreementsComplete, ...props}) => (
    <DataDependant data={documentTypes}>
        <AgreementsRow onSetAnswer={onSetAnswer} agreements={agreements?.payload ?? []} answers={answers?.payload ?? {}} isComplete={agreementsComplete} {...props}/>
        <DocumentTypeList documentTypes={documentTypes?.payload} documents={documents?.payload ?? []} {...props}/>
    </DataDependant>
);

const mapStateToProps = (state, {showErrors = false, showCheckboxes = false, checkChecked = () => {}, onChecked = () => {}, startOpen = false}) => ({
    documentTypes: selectors.applicationFormDocumentTypes(state),
    documents: selectors.applicationFormDocuments(state),
    answers: selectors.applicationFormAnswers(state),
    agreements: selectors.applicationFormDocumentAgreements(state),
    agreementsComplete: selectors.documentAgreementsComplete(state),
    showErrors,
    showCheckboxes,
    checkChecked,
    onChecked,
    startOpen
});

const mapDispatchToProps = dispatch => ({
    onDeleteDocument: document => dispatch(actions.deleteDocument(document)),
    onViewDocument: document => dispatch(actions.viewDocument(document)),
    onUploadDocument: (file, fullFileName, documentTypeId) => dispatch(actions.uploadDocument(file, fullFileName, documentTypeId)),
    onPasteDocument: (text, documentTypeId, fullFileName) => dispatch(actions.pasteDocument(text, fullFileName, documentTypeId)),
    onSetAnswer: (questionID, answer) => dispatch(actions.setApplicationFormAnswer(questionID, answer))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;
