import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import * as actions from "../actions";
import * as reducers from "./accommodationReducers";

it("Accommodation properties gets populated", () => {
    const payload = {
        properties: [1, 2, 3]
    };
    const result = reducers.accommodationProperties({}, actions.gotAccommodationList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: [1, 2, 3]});
});

it("Accommodation properties becomes loading", () => {
    const result = reducers.accommodationProperties({}, actions.requestedAccommodationList());
    expect(result).toEqual({state: ApiResultState.Loading});
});

it("property details gets new details", () => {
    const uid = "testUID";
    const testResult = {success: true, result: {property: {uid}}};
    const action = actions.gotPropertyDetails(uid, testResult);
    const actual = reducers.accommodationPropertyDetails({}, action);
    const expected = {[uid]: {state: ApiResultState.Success, payload: {uid}}};
    expect(actual).toEqual(expected);
});

it("property details becomes loading", () => {
    const uid = "testUID";
    const action = actions.requestedPropertyDetails(uid);
    const actual = reducers.accommodationPropertyDetails({}, action);
    const expected = {[uid]: {state: ApiResultState.Loading}};
    expect(actual).toEqual(expected);
});

it("Accommodation campuses gets populated", () => {
    const payload = {
        campuses: [1, 2, 3]
    };
    const result = reducers.accommodationCampuses({}, actions.gotAccommodationList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: [1, 2, 3]});
});

it("Accommodation selected campus gets populated", () => {
    const payload = {
        selectedCampus: "34"
    };
    const result = reducers.accommodationSelectedCampusId({}, actions.gotAccommodationList({success: true, result: payload}));
    expect(result).toEqual(makeSuccessResult("34"));
});

it("Accommodation selected campus handles no result", () => {
    const payload = {};
    const result = reducers.accommodationSelectedCampusId({}, actions.gotAccommodationList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Error});
});