import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import {mapStateToProps} from "./CourseDetails";

describe("map state to props", () => {
    it("mapStateToProps has course details", () => {
        const courseID = "testUid";
        const testCourse = {id: courseID, title: "im a course"};
        const result = {state: ApiResultState.Success, payload: testCourse};
        const props = mapStateToProps({courseDetails: {[courseID]: result}, courseProviderDetails: {}, courseFundingDetails: {}}, {courseID, tab: 0, makeUrlForCourse: () => ""});
        expect(props.course).toEqual(result);
    });
    it("mapStateToProps has provider details", () => {
        const providerID = "testUid";
        const courseID = "testUid";
        const testCourse = {
            id: courseID,
            providerID
        };
        const testUni = {id: providerID, name: "im a uni"};
        const result = {state: ApiResultState.Success, payload: testUni};
        const courseResult = {state: ApiResultState.Success, payload: testCourse};
        const props = mapStateToProps({
            courseDetails: {[courseID]: courseResult},
            courseProviderDetails: {[providerID]: result},
            courseFundingDetails: {}
        }, {courseID, tab: 0, makeUrlForCourse: () => ""});
        expect(props.provider).toEqual(result);
    });
    it("mapStateToProps has funding details", () => {
        const courseID = "testUid";
        const testFunding = {amount: "4", count: "50"};
        const result = makeSuccessResult(testFunding);
        const props = mapStateToProps({courseDetails: {}, universityDetails: {}, courseFundingDetails: {[courseID]: result}}, {courseID, tab: 0, makeUrlForCourse: () => ""});
        expect(props.fundingInfo).toEqual(result);
    });
});