import {useAlert as useAlertFromCommon} from "grantfairy-web-common";
import {useEffect} from "react";
import {connect} from "react-redux";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import {useIsLightTheme} from "./theme/Theme";

/**
 This component handles showing alerts which come from the store
 **/

/**
 *
 * @return {[React.ReactElement, CallableFunction, () => void]}
 */
export const useAlert = () => {
    const isLightTheme = useIsLightTheme();
    const [component, baseShow, close] = useAlertFromCommon();

    const newShow = (title, message, positive, negative, onPositive, onNegative, size, modal = false, buttonColour = null) => {
        if (isLightTheme) {
            buttonColour = "secondary";
        }
        baseShow(title, message, positive, negative, onPositive, onNegative, size, modal, buttonColour);
    };

    return [component, newShow, close];
};

const AlertHandler = ({alert, onDismiss}) => {

    const [component, showAlert] = useAlert();

    useEffect(() => {
        if (alert != null) {
            showAlert(alert.title, alert.message, alert.positive, alert.negative);
            onDismiss();
        }
    }, [alert]);

    return component;
};

const mapStateToProps = state => ({
    alert: selectors.latestAlert(state)
});

const mapDispatchToProps = dispatch => ({
    onDismiss: () => dispatch(actions.dismissAlert())
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(AlertHandler);

export default Connected;