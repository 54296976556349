import Snackbar from "@mui/material/Snackbar";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as selectors from "../redux/selectors";

/**
 This component handles showing toasts / snackbars which come from the store
 **/

const ToastHandler = ({toast}: { toast: { text: string } }) => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(toast.text != null);
    }, [toast]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;
        setIsOpen(false);
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={3000}
            message={toast.text}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            onClose={handleClose}
        />
    );

};

const mapStateToProps = state => ({
    toast: selectors.latestToast(state)
});

const Connected = connect(mapStateToProps)(ToastHandler);

export default Connected;