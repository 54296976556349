import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";
import {Util} from "grantfairy-web-common";
import {gfWindow} from "../model/GFWindow";
import * as StorageKeys from "../StorageKeys";
import * as Str from "../strings/Str";
import {POSTWithoutToken} from "./api";

let cachedToken: string | null = "";
let cachedExpiry: number = 0;
// A promise which will return an access token soon
let cachedAccessTokenPromise: Promise<string> | null = null;

const apps = firebase.apps;

function configSettings() {
    return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
    };
}

const initFirebase = () => {
    if (gfWindow.firebaseApp != null) {
        return gfWindow.firebaseApp;
    }
    const config = configSettings();

    const app = apps.length === 0 ? firebase.initializeApp(config) : apps[0];
    gfWindow.firebaseApp = app;
    return app;
};

export const currentUser = () => {
    const json = localStorage.getItem(StorageKeys.CURRENT_USER);
    if (json != null) {
        return JSON.parse(json);
    }
    return null;
};

export const getLoginProvider = () => currentUser()?.provider;

export const sendPasswordResetEmail = () => {
    if (getLoginProvider() != null) return null;
    const email = currentUser().email;
    return POSTWithoutToken("Login/forgotPassword", {email});
};

export const getFcmToken = () => {
    if (isAgent()) {
        return new Promise((onResolve) => {
            onResolve("");
        });
    }
    initFirebase();
    return new Promise((onResolve) => {
        try {
            const messaging = firebase.messaging();
            messaging.getToken().then((currentToken) => {
                onResolve(currentToken);
            }).catch((err) => {
                onResolve("");
                console.log(err);
            });
        } catch (e) {
            console.log(e);
            onResolve("");
        }
    });
};

export const isAgent = (): boolean => gfWindow.isAgent ?? false;

export const isSignedIn = (): boolean => {
    return isAgent() || currentUser() != null;
};

export const getApiToken = (): Promise<string> => {
    if (isAgent()) {
        return new Promise((onResolve) => {
            onResolve(gfWindow.agentToken!);
        });
    }

    if (cachedToken != null && cachedExpiry != null && (cachedExpiry - Util.rn() > 10 * 60)) {
        //we have a token which has at least 10 mins left on it so use that
        return new Promise((onResolve) => {
            onResolve(cachedToken!);
        });
    }

    const refreshToken = currentUser().token;
    const accessTokenPromise = cachedAccessTokenPromise ?? POSTWithoutToken("Login/getAccessToken", {refreshToken});
    cachedAccessTokenPromise = accessTokenPromise;

    return accessTokenPromise.then(e => {
        const token = e.result.token;

        cachedToken = token;
        cachedExpiry = e.result.expiry;

        cachedAccessTokenPromise = null;

        return token;
    });
};

export const logout = () => {
    cachedToken = null;
    cachedExpiry = 0;
    const refreshToken = currentUser().token;
    localStorage.removeItem(StorageKeys.CURRENT_USER);
    return POSTWithoutToken("Login/signOut", {refreshToken}).then(e => e.result.token);
};

export const checkSignIn = (email: string, password: string, onSuccess: () => void, onFailure: (error: string) => void) => {
    if (password.trim().length === 0) {
        onFailure(Str.no_password());
        return;
    }
    POSTWithoutToken("Login/signIn", {email, password}).then(result => {
        if (result.success) {
            onSuccess();
        } else {
            onFailure(Str.password_dont_match());
        }
    });
};

export const activateRemoteConfig = async () => {
    try {
        const config = configSettings();

        const app = apps.length === 0 ? firebase.initializeApp(config) : apps[0];

        const remoteConfig = firebase.remoteConfig(app);
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
        const rcDefaultsFile = await fetch("/remote_config_defaults.json");
        remoteConfig.defaultConfig = await rcDefaultsFile.json();
        await remoteConfig.fetch();
        await remoteConfig.activate();

        return {
            learnerCanLogin: remoteConfig.getBoolean("learner_can_login"),
            learnerCanSignUp: remoteConfig.getBoolean("learner_can_signup"),
            learnerCanApplyCourses: remoteConfig.getBoolean("learner_can_apply_courses")
        };
    } catch (err) {
        console.log(err);
        return {};
    }
};
