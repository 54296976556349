import {CVLibraryJob} from "../model/Job";
import {JobsConfig} from "../model/JobsApiConfig";
import {JobLevel, JobSearchFilters, JobSort} from "../model/JobSearchFilters";
import {StringDictionary} from "../types/StringDictionary";

/**
 * Represents a result, compatible with what api.js and api2.js return
 */
interface CVLibraryApiResult<T> {
    success: boolean;
    result?: T;
}

export const getJob = async (config: JobsConfig, id: number): Promise<CVLibraryApiResult<CVLibraryJob>> => {
    const url = `https://partners.cv-library.co.uk/api/v1/jobs/${id}`;

    const params: StringDictionary[] = [
        {key: "key", value: config.api_key},
        {key: "description_formatted", value: "1"},
    ];

    const result = await apiRequest(url, params);
    if (result.statusCode === 200) {
        const parsed: CVLibraryJob = JSON.parse(result.response);
        if (parsed == null) {
            return {success: false};
        }
        return {success: true, result: parsed};
    } else {
        return {success: false};
    }
};

const getKeywordString = (config: JobsConfig, searchTerm: string, jobLevel: JobLevel): string => {
    if (searchTerm.trim() === "") {
        switch (jobLevel) {
            case JobLevel.PartTime:
                return config.search_string_part_time_blank;
            case JobLevel.FullTime:
                return config.search_string_full_time_blank;
            case JobLevel.Graduate:
                return config.search_string_grad_blank;
        }
    } else {
        switch (jobLevel) {
            case JobLevel.PartTime:
                return config.search_string_part_time.replaceAll("$userInput", searchTerm);
            case JobLevel.FullTime:
                return config.search_string_full_time.replaceAll("$userInput", searchTerm);
            case JobLevel.Graduate:
                return config.search_string_grad.replaceAll("$userInput", searchTerm);
        }
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

export const getTempPermKeywords = (jobLevel: JobLevel): string[] => {
    switch (jobLevel) {
        case JobLevel.PartTime:
            return ["Contract", "Part Time", "Temporary"];
        case JobLevel.FullTime:
            return ["Contract", "Permanent", "Apprenticeships"];
        case JobLevel.Graduate:
            return [];
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return [];
};

export const getSortParameter = (jobSort: JobSort): string => {
    switch (jobSort) {
        case JobSort.Relevance:
            return "sm";
        case JobSort.SalaryHigh:
            return "salarymaxDESC";
        case JobSort.SalaryLow:
            return "salarymaxASC";
        case JobSort.Recency:
            return "date";
        case JobSort.Closest:
            return "distance";
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

export const searchJobs = async (config: JobsConfig, filters: JobSearchFilters): Promise<CVLibraryApiResult<CVLibraryJob[]>> => {
    const url = "https://www.cv-library.co.uk/search-jobs-json";

    const params: StringDictionary[] = [
        {key: "key", value: config.api_key},
        {key: "q", value: getKeywordString(config, filters.searchQuery, filters.jobLevel)},
        {key: "geo", value: filters.location},
        {key: "distance", value: String(filters.distance)},
        {key: "perpage", value: "100"},
        {key: "order", value: getSortParameter(filters.sort)}
    ];

    const tempPermKeywords = getTempPermKeywords(filters.jobLevel);
    tempPermKeywords.forEach(keyword => {
        params.push({key: "tempperm", value: keyword});
    });

    const result = await apiRequest(url, params);
    if (result.statusCode === 200) {
        const parsed = JSON.parse(result.response);
        if (parsed == null) return {success: false};
        return {success: true, result: parsed.jobs ?? []};
    } else {
        return {success: false};
    }
};

const apiRequest = (url: string, queryParams: StringDictionary[]): Promise<{ response: string, statusCode: number }> => {
    return new Promise(onDone => {
        const urlWithQueryString = url + "?" + makeQueryParamsString(queryParams);

        const request = new XMLHttpRequest();
        request.onreadystatechange = function () {
            if (this.readyState === 4) {
                onDone({response: this.responseText, statusCode: this.status});
            }
        };
        request.open("GET", urlWithQueryString, true);
        request.send();
    });
};
const makeQueryParamsString = (params: StringDictionary[]): string => {
    return params.map(({key, value}) => encodeURIComponent(key) + "=" + encodeURIComponent(value)).join("&");
};