import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import DataDependant from "../../views/DataDependant";
import UniversityListScreen from "./UniversityListScreen";

const Container = ({universities, selectedUniversity, onCoursesView, ...props}) => {
    const history = useNavigate();
    const onViewCourses = () => {
        onCoursesView(history);
    };

    return (
        <DataDependant data={universities}>
            <UniversityListScreen {...props} universities={universities.payload} selectedUniversity={selectedUniversity} onCoursesView={onViewCourses} searchCategory="universities"/>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {universityID}) => ({
    universities: selectors.universityListSortedByName(state),
    favouriteIDs: selectors.favouritedUniversityIDs(state),
    selectedUniversityID: universityID,
    selectedUniversity: selectors.courseProviderDetails(state)[universityID],
    makeUrlForUniversity: (uni) => `/universities/search/${uni.id}`
});

const mapDispatchToProps = (dispatch, {universityID}) => {
    return {
        onFave: (university, fave) => dispatch(actions.setUniversityFave(university, fave)),
        onCoursesView: (history) => {
            dispatch(actions.setCourseSearchProviderId(universityID));
            history("/courses");
        }
    };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {universityID} = useParams();
    return (<Connected universityID={universityID} />);
};

export default WrappedAndConnected;