import {httpUtil} from "grantfairy-web-common";
import * as Constants from "../Constants";
import * as FirebaseUtil from "./FirebaseUtil";

const API_URL = process.env.REACT_APP_API_URL;

const addBaseParamsToForm = form => {
    form.append("OS", Constants.OS);
    form.append("app_version", Constants.VERSION);
    form.append("userAgent", window.navigator.userAgent);
    form.append("deviceID", "WEB");
};

const buildForm = params => {
    const data = new FormData();
    for (const key in params) {
        data.append(key, params[key]);
    }
    return data;
};

/**
 *
 * @param url {String}
 * @param params {{}}
 * @returns {Promise|Promise<*>}
 */
export const POST = (url, params) => {
    return POSTform(url, buildForm(params));
};

export const POSTWithFile = (url, params, fileKey, file) => {
    const data = buildForm(params);
    data.append(fileKey, file);
    return POSTform(url, data);
};

export const POSTform = (url, form) => {
    const error = new Promise(onDone => {
        onDone({
            success: false,
            errorTitle: "Could not login",//TODO hardcoded strings
            error: "Please check your internet connection then try again.",
            canRetry: true,
            result: {}
        });
    });
    if (!FirebaseUtil.isSignedIn()) {
        return error;
    }
    return FirebaseUtil.getApiToken().then(token => {
        if (token == null) {
            FirebaseUtil.logout();
            //Take the user back through the login experience
            window.location.reload();
            return error;
        }
        form.append("token", token);
        addBaseParamsToForm(form);
        return POSTraw(API_URL + url, form);
    }).catch(e => {
        console.log(e);
        return error;
    });
};

export const POSTWithoutToken = (endpoint, params) => {
    const form = buildForm(params);
    addBaseParamsToForm(form);
    return POSTraw(API_URL + endpoint, form);
};

export const POSTraw = (url, form) => {
    return new Promise(onDone => {
        const request = new XMLHttpRequest();
        request.onreadystatechange = function () {
            if (this.readyState === 4) {
                try {
                    const json = JSON.parse(this.responseText);
                    onDone(json);
                } catch (e) {
                    console.log(e);
                    onDone({
                        success: false,
                        errorTitle: "Could not connect",//TODO hardcoded strings
                        error: "Please check your internet connection then try again.",
                        canRetry: true,
                        result: {}
                    });
                }
            }
        };

        request.open("POST", url, true);
        request.send(form);
    });
};

export const downloadFile = (endpoint, params, fileName) => {
    return FirebaseUtil.getApiToken().then(token => {
        return httpUtil.downloadFile(API_URL + endpoint, new Map(), undefined, httpUtil.HTTPMethod.POST, buildForm({...params, token}));
    });
};
