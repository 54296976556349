import * as stringUtil from "./StringUtil";

/**
 * @returns {String} 
 */
export const blank = (): string => stringUtil.getStringWithArgs("blank");

/**
 * @returns {String} UCAS
 */
export const app_name = (): string => stringUtil.getStringWithArgs("app_name");

/**
 * @returns {String} UCAS
 */
export const toolbar_name = (): string => stringUtil.getStringWithArgs("toolbar_name");

/**
 * @returns {String} UCAS
 */
export const splashscreen_name = (): string => stringUtil.getStringWithArgs("splashscreen_name");

/**
 * @returns {String} 119689255335583
 */
export const facebook_app_id = (): string => stringUtil.getStringWithArgs("facebook_app_id");

/**
 * @returns {String} ca8434ba38cc2a06a9ffa43060c70e23
 */
export const facebook_client_token = (): string => stringUtil.getStringWithArgs("facebook_client_token");

/**
 * @returns {String} fb119689255335583
 */
export const fb_login_protocol_scheme = (): string => stringUtil.getStringWithArgs("fb_login_protocol_scheme");

/**
 * @returns {String} [{"include": "https://web.myriadbyucas.co.uk/.well-known/assetlinks.json"}, {"include": "https://web.thanksmo.com/.well-known/assetlinks.json"}]
 */
export const asset_statements = (): string => stringUtil.getStringWithArgs("asset_statements");

/**
 * @returns {String} Open
 */
export const open = (): string => stringUtil.getStringWithArgs("open");

/**
 * @returns {String} Close
 */
export const close = (): string => stringUtil.getStringWithArgs("close");

/**
 * @returns {String} Menu
 */
export const menu = (): string => stringUtil.getStringWithArgs("menu");

/**
 * @returns {String} Details
 */
export const detail_title = (): string => stringUtil.getStringWithArgs("detail_title");

/**
 * @returns {String} Profile
 */
export const profile = (): string => stringUtil.getStringWithArgs("profile");

/**
 * @returns {String} How-To Guides
 */
export const resources = (): string => stringUtil.getStringWithArgs("resources");

/**
 * @returns {String} Settings
 */
export const settings = (): string => stringUtil.getStringWithArgs("settings");

/**
 * @returns {String} Account
 */
export const accountSettings = (): string => stringUtil.getStringWithArgs("accountSettings");

/**
 * @returns {String} Notifications
 */
export const notifSettings = (): string => stringUtil.getStringWithArgs("notifSettings");

/**
 * @returns {String} HINTS AND TIPS
 */
export const hintsSettings = (): string => stringUtil.getStringWithArgs("hintsSettings");

/**
 * @returns {String} About
 */
export const about = (): string => stringUtil.getStringWithArgs("about");

/**
 * @returns {String} Open Source
 */
export const licenses = (): string => stringUtil.getStringWithArgs("licenses");

/**
 * @returns {String} YOUR UNIVERSITY
 */
export const yourUni = (): string => stringUtil.getStringWithArgs("yourUni");

/**
 * @returns {String} YOUR COURSE
 */
export const yourCourse = (): string => stringUtil.getStringWithArgs("yourCourse");

/**
 * @returns {String} About You
 */
export const aboutYou = (): string => stringUtil.getStringWithArgs("aboutYou");

/**
 * @returns {String} Options
 */
export const misc = (): string => stringUtil.getStringWithArgs("misc");

/**
 * @returns {String} Nationality
 */
export const whatcountry = (): string => stringUtil.getStringWithArgs("whatcountry");

/**
 * @returns {String} Parental Income
 */
export const parental_income = (): string => stringUtil.getStringWithArgs("parental_income");

/**
 * @returns {String} Show All
 */
export const all = (): string => stringUtil.getStringWithArgs("all");

/**
 * @returns {String} UNIVERSITIES
 */
export const universities = (): string => stringUtil.getStringWithArgs("universities");

/**
 * @returns {String} UNIVERSITY
 */
export const university = (): string => stringUtil.getStringWithArgs("university");

/**
 * @returns {String} Terms of Service
 */
export const terms = (): string => stringUtil.getStringWithArgs("terms");

/**
 * @returns {String} Privacy Policy
 */
export const privacy = (): string => stringUtil.getStringWithArgs("privacy");

/**
 * @returns {String} Subscription Info
 */
export const subInfo = (): string => stringUtil.getStringWithArgs("subInfo");

/**
 * @returns {String} Welcome
 */
export const welcome = (): string => stringUtil.getStringWithArgs("welcome");

/**
 * @returns {String} UCAS International in your pocket
 */
export const welcomeTextHeader = (): string => stringUtil.getStringWithArgs("welcomeTextHeader");

/**
 * @returns {String} Download our Android and iOS App to access your UCAS International account on the go
 */
export const welcomeText = (): string => stringUtil.getStringWithArgs("welcomeText");

/**
 * @returns {String} Get Started!
 */
export const getStarted = (): string => stringUtil.getStringWithArgs("getStarted");

/**
 * @returns {String} No Network
 */
export const noNetwork = (): string => stringUtil.getStringWithArgs("noNetwork");

/**
 * @returns {String} Please ensure you have an internet connection then try again
 */
export const noNetworkInfo = (): string => stringUtil.getStringWithArgs("noNetworkInfo");

/**
 * @returns {String} Cancel
 */
export const cancel = (): string => stringUtil.getStringWithArgs("cancel");

/**
 * @returns {String} Retry
 */
export const retry = (): string => stringUtil.getStringWithArgs("retry");

/**
 * @returns {String} Could not retrieve email address. Please ensure you have allowed this app to access your email
 */
export const emailError = (): string => stringUtil.getStringWithArgs("emailError");

/**
 * @returns {String} Please enter your email
 */
export const enter_email = (): string => stringUtil.getStringWithArgs("enter_email");

/**
 * @returns {String} Please verify your email
 */
export const verifyEmail = (): string => stringUtil.getStringWithArgs("verifyEmail");

/**
 * @returns {String} Email Change Verification
 */
export const verifyEmailChange = (): string => stringUtil.getStringWithArgs("verifyEmailChange");

/**
 * @returns {String} Courses
 */
export const courses = (): string => stringUtil.getStringWithArgs("courses");

/**
 * @returns {String} Find your perfect course...
 */
export const find_perfect_course = (): string => stringUtil.getStringWithArgs("find_perfect_course");

/**
 * @returns {String} Favourites
 */
export const favourites = (): string => stringUtil.getStringWithArgs("favourites");

/**
 * @returns {String} Universities
 */
export const universities_tab = (): string => stringUtil.getStringWithArgs("universities_tab");

/**
 * @returns {String} Funding
 */
export const funding = (): string => stringUtil.getStringWithArgs("funding");

/**
 * @returns {String} Choose an institution below
 */
export const chooseUni = (): string => stringUtil.getStringWithArgs("chooseUni");

/**
 * @returns {String} Skip
 */
export const skip = (): string => stringUtil.getStringWithArgs("skip");

/**
 * @returns {String} Which university do you want scholarships for?
 */
export const profile_uni_heading = (): string => stringUtil.getStringWithArgs("profile_uni_heading");

/**
 * @returns {String} Start typing an institution name...
 */
export const start_typing_uni = (): string => stringUtil.getStringWithArgs("start_typing_uni");

/**
 * @returns {String} Search
 */
export const search = (): string => stringUtil.getStringWithArgs("search");

/**
 * @returns {String} What course do you want to study?
 */
export const profile_course_heading = (): string => stringUtil.getStringWithArgs("profile_course_heading");

/**
 * @returns {String} Tick all which apply, it helps us find you more scholarships!
 */
export const options_info = (): string => stringUtil.getStringWithArgs("options_info");

/**
 * @returns {String} You're all set!
 */
export const all_set = (): string => stringUtil.getStringWithArgs("all_set");

/**
 * @returns {String} Find advice and guidance for studying, living and working in the UK
 */
export const htg_info = (): string => stringUtil.getStringWithArgs("htg_info");

/**
 * @returns {String} No results found. Please search for another course.
 */
export const emptyCourses = (): string => stringUtil.getStringWithArgs("emptyCourses");

/**
 * @returns {String} Choose a University below
 */
export const choose_uni_below = (): string => stringUtil.getStringWithArgs("choose_uni_below");

/**
 * @returns {String} Study Mode
 */
export const study_mode = (): string => stringUtil.getStringWithArgs("study_mode");

/**
 * @returns {String} Level of Study
 */
export const level_of_study = (): string => stringUtil.getStringWithArgs("level_of_study");

/**
 * @returns {String} Subject Category
 */
export const subject_category = (): string => stringUtil.getStringWithArgs("subject_category");

/**
 * @returns {String} University
 */
export const university_lower = (): string => stringUtil.getStringWithArgs("university_lower");

/**
 * @returns {String} Continue
 */
export const cont = (): string => stringUtil.getStringWithArgs("cont");

/**
 * @returns {String} Change
 */
export const change = (): string => stringUtil.getStringWithArgs("change");

/**
 * @returns {String} You don't have any Favourites yet. Tap on the heart icon next to a course to add it to this list.
 */
export const emptyFavourites = (): string => stringUtil.getStringWithArgs("emptyFavourites");

/**
 * @returns {String} Find Funding
 */
export const find_funding = (): string => stringUtil.getStringWithArgs("find_funding");

/**
 * @returns {String} Browse Universities
 */
export const browse_universities = (): string => stringUtil.getStringWithArgs("browse_universities");

/**
 * @returns {String} Choose Course
 */
export const browse_courses = (): string => stringUtil.getStringWithArgs("browse_courses");

/**
 * @returns {String} Press again to exit
 */
export const exit_warning = (): string => stringUtil.getStringWithArgs("exit_warning");

/**
 * @returns {String} Message to share
 */
export const message_to_share = (): string => stringUtil.getStringWithArgs("message_to_share");

/**
 * @returns {String} Share
 */
export const share = (): string => stringUtil.getStringWithArgs("share");

/**
 * @returns {String} Share via
 */
export const share_via = (): string => stringUtil.getStringWithArgs("share_via");

/**
 * @returns {String} Logout
 */
export const logout = (): string => stringUtil.getStringWithArgs("logout");

/**
 * @returns {String} Are you sure you want to logout?
 */
export const logout_confirm = (): string => stringUtil.getStringWithArgs("logout_confirm");

/**
 * @returns {String} Filters
 */
export const filters = (): string => stringUtil.getStringWithArgs("filters");

/**
 * @returns {String} Not so fast...
 */
export const no_so_fast = (): string => stringUtil.getStringWithArgs("no_so_fast");

/**
 * @returns {String} You need to choose a Course and a University to access scholarships.
 */
export const funding_no_course = (): string => stringUtil.getStringWithArgs("funding_no_course");

/**
 * @returns {String} OK
 */
export const ok = (): string => stringUtil.getStringWithArgs("ok");

/**
 * @returns {String} Resend
 */
export const resend = (): string => stringUtil.getStringWithArgs("resend");

/**
 * @returns {String} reload
 */
export const reload = (): string => stringUtil.getStringWithArgs("reload");

/**
 * @returns {String} Your profile has been saved.
 */
export const profile_saved = (): string => stringUtil.getStringWithArgs("profile_saved");

/**
 * @returns {String} Search for a course
 */
export const search_course = (): string => stringUtil.getStringWithArgs("search_course");

/**
 * @returns {String} Favourited
 */
export const favourited = (): string => stringUtil.getStringWithArgs("favourited");

/**
 * @returns {String} Unfavourited
 */
export const unfavourited = (): string => stringUtil.getStringWithArgs("unfavourited");

/**
 * @returns {String} Your changes could not be saved. Please check your internet connection and try again.
 */
export const save_error = (): string => stringUtil.getStringWithArgs("save_error");

/**
 * @returns {String} Yes
 */
export const yes = (): string => stringUtil.getStringWithArgs("yes");

/**
 * @returns {String} No
 */
export const no = (): string => stringUtil.getStringWithArgs("no");

/**
 * @returns {String} Not Available
 */
export const no_data = (): string => stringUtil.getStringWithArgs("no_data");

/**
 * @returns {String} Error
 */
export const error = (): string => stringUtil.getStringWithArgs("error");

/**
 * @returns {String} Go to Website
 */
export const website = (): string => stringUtil.getStringWithArgs("website");

/**
 * @returns {String} %s Website
 */
export const myriad_website = (arg0: string): string => stringUtil.getStringWithArgs("myriad_website", arg0);

/**
 * @returns {String} View
 */
export const view = (): string => stringUtil.getStringWithArgs("view");

/**
 * @returns {String} How-To Guides
 */
export const navdraw_htg = (): string => stringUtil.getStringWithArgs("navdraw_htg");

/**
 * @returns {String} Settings
 */
export const navdraw_setting = (): string => stringUtil.getStringWithArgs("navdraw_setting");

/**
 * @returns {String} Favourite
 */
export const favourite = (): string => stringUtil.getStringWithArgs("favourite");

/**
 * @returns {String} Unfavourite
 */
export const unfavourite = (): string => stringUtil.getStringWithArgs("unfavourite");

/**
 * @returns {String} Distance Learning:
 */
export const distance_learning = (): string => stringUtil.getStringWithArgs("distance_learning");

/**
 * @returns {String} Sandwich Year:
 */
export const sandwich_year = (): string => stringUtil.getStringWithArgs("sandwich_year");

/**
 * @returns {String} Level of Study:
 */
export const level_of_study_title = (): string => stringUtil.getStringWithArgs("level_of_study_title");

/**
 * @returns {String} Location:
 */
export const location_title = (): string => stringUtil.getStringWithArgs("location_title");

/**
 * @returns {String} Study Mode:
 */
export const study_mode_title = (): string => stringUtil.getStringWithArgs("study_mode_title");

/**
 * @returns {String} Course Duration:
 */
export const course_duration_title = (): string => stringUtil.getStringWithArgs("course_duration_title");

/**
 * @returns {String} Show
 */
export const show = (): string => stringUtil.getStringWithArgs("show");

/**
 * @returns {String} Your Name
 */
export const your_name = (): string => stringUtil.getStringWithArgs("your_name");

/**
 * @returns {String} Gender
 */
export const gender = (): string => stringUtil.getStringWithArgs("gender");

/**
 * @returns {String} Date of Birth
 */
export const date_of_birth = (): string => stringUtil.getStringWithArgs("date_of_birth");

/**
 * @returns {String} Tap to select
 */
export const tap_to_select = (): string => stringUtil.getStringWithArgs("tap_to_select");

/**
 * @returns {String} Religion
 */
export const religion = (): string => stringUtil.getStringWithArgs("religion");

/**
 * @returns {String} NEXT
 */
export const next = (): string => stringUtil.getStringWithArgs("next");

/**
 * @returns {String} View More
 */
export const view_more = (): string => stringUtil.getStringWithArgs("view_more");

/**
 * @returns {String} Filter
 */
export const filter = (): string => stringUtil.getStringWithArgs("filter");

/**
 * @returns {String} Level of Study (BA, MA, PhD)
 */
export const los_filter_title = (): string => stringUtil.getStringWithArgs("los_filter_title");

/**
 * @returns {String} Study Mode (Full-time, Part-time, Distance Learning)
 */
export const sm_filter_title = (): string => stringUtil.getStringWithArgs("sm_filter_title");

/**
 * @returns {String} Subjects and Subcategories
 */
export const subjects_and_subcategories = (): string => stringUtil.getStringWithArgs("subjects_and_subcategories");

/**
 * @returns {String} \u0020\u0020Course\u0020\u0020
 */
export const course_tab_title = (): string => stringUtil.getStringWithArgs("course_tab_title");

/**
 * @returns {String} \u0020\u0020University\u0020\u0020
 */
export const university_tab_title = (): string => stringUtil.getStringWithArgs("university_tab_title");

/**
 * @returns {String} \u0020\u0020Apply\u0020\u0020
 */
export const apply_tab_title = (): string => stringUtil.getStringWithArgs("apply_tab_title");

/**
 * @returns {String} \u0020\u0020Funding\u0020\u0020
 */
export const funding_tab_title = (): string => stringUtil.getStringWithArgs("funding_tab_title");

/**
 * @returns {String} \u0020\u0020Wishlist\u0020\u0020
 */
export const wishlist_tab_title = (): string => stringUtil.getStringWithArgs("wishlist_tab_title");

/**
 * @returns {String} \u0020\u0020Applied\u0020\u0020
 */
export const applied_tab_title = (): string => stringUtil.getStringWithArgs("applied_tab_title");

/**
 * @returns {String} \u0020\u0020Success\u0020\u0020
 */
export const success_tab_title = (): string => stringUtil.getStringWithArgs("success_tab_title");

/**
 * @returns {String} \u0020\u0020Deleted\u0020\u0020
 */
export const deleted_tab_title = (): string => stringUtil.getStringWithArgs("deleted_tab_title");

/**
 * @returns {String} Course
 */
export const course = (): string => stringUtil.getStringWithArgs("course");

/**
 * @returns {String} Options
 */
export const options = (): string => stringUtil.getStringWithArgs("options");

/**
 * @returns {String} Save
 */
export const save = (): string => stringUtil.getStringWithArgs("save");

/**
 * @returns {String} We have identified
 */
export const grantfairy_has_identified = (): string => stringUtil.getStringWithArgs("grantfairy_has_identified");

/**
 * @returns {String} worth
 */
export const worth = (): string => stringUtil.getStringWithArgs("worth");

/**
 * @returns {String} Click to update your profile
 */
export const click_to_update_your_profile = (): string => stringUtil.getStringWithArgs("click_to_update_your_profile");

/**
 * @returns {String} View Course Funding
 */
export const view_course_funding = (): string => stringUtil.getStringWithArgs("view_course_funding");

/**
 * @returns {String} Choose a Course?
 */
export const choose_a_course = (): string => stringUtil.getStringWithArgs("choose_a_course");

/**
 * @returns {String} Change to a new course?
 */
export const change_course = (): string => stringUtil.getStringWithArgs("change_course");

/**
 * @returns {String} All Courses
 */
export const all_courses = (): string => stringUtil.getStringWithArgs("all_courses");

/**
 * @returns {String} No results found. Please search for another university.
 */
export const emptyUnis = (): string => stringUtil.getStringWithArgs("emptyUnis");

/**
 * @returns {String} No Website
 */
export const no_website = (): string => stringUtil.getStringWithArgs("no_website");

/**
 * @returns {String} This scholarship doesn't have a website. Please read the description for application details.
 */
export const no_website_info = (): string => stringUtil.getStringWithArgs("no_website_info");

/**
 * @returns {String} REPORT A PROBLEM
 */
export const report_a_problem = (): string => stringUtil.getStringWithArgs("report_a_problem");

/**
 * @returns {String} MOVE TO
 */
export const move_to = (): string => stringUtil.getStringWithArgs("move_to");

/**
 * @returns {String} Report Sent
 */
export const report_sent = (): string => stringUtil.getStringWithArgs("report_sent");

/**
 * @returns {String} Thank you for your feedback, we will try to fix this as soon as possible.
 */
export const report_thank = (): string => stringUtil.getStringWithArgs("report_thank");

/**
 * @returns {String} Dismiss
 */
export const dismiss = (): string => stringUtil.getStringWithArgs("dismiss");

/**
 * @returns {String} Send report
 */
export const send_report = (): string => stringUtil.getStringWithArgs("send_report");

/**
 * @returns {String} Explain what's wrong
 */
export const report_explain = (): string => stringUtil.getStringWithArgs("report_explain");

/**
 * @returns {String} Congratulations
 */
export const congratulations = (): string => stringUtil.getStringWithArgs("congratulations");

/**
 * @returns {String} found
 */
export const found = (): string => stringUtil.getStringWithArgs("found");

/**
 * @returns {String} Sign up now from £%s / month
 */
export const sign_up_now = (arg0: string): string => stringUtil.getStringWithArgs("sign_up_now", arg0);

/**
 * @returns {String} SIGN UP
 */
export const sign_up = (): string => stringUtil.getStringWithArgs("sign_up");

/**
 * @returns {String} Details
 */
export const details = (): string => stringUtil.getStringWithArgs("details");

/**
 * @returns {String} Find a course
 */
export const find_course = (): string => stringUtil.getStringWithArgs("find_course");

/**
 * @returns {String} Check out Funding
 */
export const check_out_funding = (): string => stringUtil.getStringWithArgs("check_out_funding");

/**
 * @returns {String} View scholarship opportunities for this course now.
 */
export const view_schol_opp = (): string => stringUtil.getStringWithArgs("view_schol_opp");

/**
 * @returns {String} Lets Go!
 */
export const lets_go = (): string => stringUtil.getStringWithArgs("lets_go");

/**
 * @returns {String} Saved!
 */
export const saved = (): string => stringUtil.getStringWithArgs("saved");

/**
 * @returns {String} Message Sent
 */
export const message_sent = (): string => stringUtil.getStringWithArgs("message_sent");

/**
 * @returns {String} send
 */
export const send = (): string => stringUtil.getStringWithArgs("send");

/**
 * @returns {String} Your Message
 */
export const your_message = (): string => stringUtil.getStringWithArgs("your_message");

/**
 * @returns {String} Do you like the app?
 */
export const do_you_love = (): string => stringUtil.getStringWithArgs("do_you_love");

/**
 * @returns {String} Thank You!
 */
export const thank_you = (): string => stringUtil.getStringWithArgs("thank_you");

/**
 * @returns {String} Rate
 */
export const rate = (): string => stringUtil.getStringWithArgs("rate");

/**
 * @returns {String} Remind Me Later
 */
export const remind_me_later = (): string => stringUtil.getStringWithArgs("remind_me_later");

/**
 * @returns {String} No Thanks
 */
export const no_thanks = (): string => stringUtil.getStringWithArgs("no_thanks");

/**
 * @returns {String} We are delighted to hear that you like our app.\n\nIt would be really helpful if you could also rate us in the PlayStore -- that helps other students find the app. Thank you!
 */
export const does_love_delighted = (): string => stringUtil.getStringWithArgs("does_love_delighted");

/**
 * @returns {String} Tell us how we can help
 */
export const tell_us_help = (): string => stringUtil.getStringWithArgs("tell_us_help");

/**
 * @returns {String} Please let us know how we can improve the app to make it better for you.
 */
export const let_know_better = (): string => stringUtil.getStringWithArgs("let_know_better");

/**
 * @returns {String} Thanks for your feedback!
 */
export const thanks_feedback = (): string => stringUtil.getStringWithArgs("thanks_feedback");

/**
 * @returns {String} Thanks! Your feedback has been sent.
 */
export const thanks_feedback_rate = (): string => stringUtil.getStringWithArgs("thanks_feedback_rate");

/**
 * @returns {String} Please enter some feedback.
 */
export const please_feedback = (): string => stringUtil.getStringWithArgs("please_feedback");

/**
 * @returns {String} We're glad you're enjoying the app, but you've hit your daily limit for searches today. How about applying for a scholarship now?
 */
export const too_many_updates = (): string => stringUtil.getStringWithArgs("too_many_updates");

/**
 * @returns {String} Whoa there! Not so fast!
 */
export const whoa_there = (): string => stringUtil.getStringWithArgs("whoa_there");

/**
 * @returns {String} Contact Support
 */
export const contact_support = (): string => stringUtil.getStringWithArgs("contact_support");

/**
 * @returns {String} Open Source Licenses
 */
export const open_source_licenses = (): string => stringUtil.getStringWithArgs("open_source_licenses");

/**
 * @returns {String} No Password
 */
export const no_password = (): string => stringUtil.getStringWithArgs("no_password");

/**
 * @returns {String} Account Suspended
 */
export const banned_title = (): string => stringUtil.getStringWithArgs("banned_title");

/**
 * @returns {String} Please contact customer support for more information
 */
export const banned_text = (): string => stringUtil.getStringWithArgs("banned_text");

/**
 * @returns {String} Could not connect
 */
export const could_not_connect = (): string => stringUtil.getStringWithArgs("could_not_connect");

/**
 * @returns {String} Don't show again
 */
export const dont_show_again = (): string => stringUtil.getStringWithArgs("dont_show_again");

/**
 * @returns {String} We will reply shortly.
 */
export const will_reply = (): string => stringUtil.getStringWithArgs("will_reply");

/**
 * @returns {String} Okay
 */
export const okay = (): string => stringUtil.getStringWithArgs("okay");

/**
 * @returns {String} Quick Apply
 */
export const quick_apply = (): string => stringUtil.getStringWithArgs("quick_apply");

/**
 * @returns {String} Change Email
 */
export const change_email = (): string => stringUtil.getStringWithArgs("change_email");

/**
 * @returns {String} Change Password
 */
export const change_password = (): string => stringUtil.getStringWithArgs("change_password");

/**
 * @returns {String} Sign Out
 */
export const sign_out = (): string => stringUtil.getStringWithArgs("sign_out");

/**
 * @returns {String} New Email
 */
export const new_email = (): string => stringUtil.getStringWithArgs("new_email");

/**
 * @returns {String} Confirm Current Password
 */
export const confirm_password = (): string => stringUtil.getStringWithArgs("confirm_password");

/**
 * @returns {String} Done
 */
export const done = (): string => stringUtil.getStringWithArgs("done");

/**
 * @returns {String} move
 */
export const move = (): string => stringUtil.getStringWithArgs("move");

/**
 * @returns {String} Are you sure you want to move this item to %s?
 */
export const confirm_move_tab = (arg0: string): string => stringUtil.getStringWithArgs("confirm_move_tab", arg0);

/**
 * @returns {String} %d selected
 */
export const number_selected = (arg0: number): string => stringUtil.getStringWithArgs("number_selected", arg0);

/**
 * @returns {String} month
 */
export const month = (): string => stringUtil.getStringWithArgs("month");

/**
 * @returns {String} months
 */
export const months = (): string => stringUtil.getStringWithArgs("months");

/**
 * @returns {String} Send me news about grants & scholarships.
 */
export const send_news = (): string => stringUtil.getStringWithArgs("send_news");

/**
 * @returns {String} Deadline Notifications
 */
export const deadline_notifs = (): string => stringUtil.getStringWithArgs("deadline_notifs");

/**
 * @returns {String} Send me reminders when scholarship application deadlines are getting close.
 */
export const deadline_notif_exp = (): string => stringUtil.getStringWithArgs("deadline_notif_exp");

/**
 * @returns {String} New Matches
 */
export const new_matches = (): string => stringUtil.getStringWithArgs("new_matches");

/**
 * @returns {String} Tell me when new scholarships are added to the app which fit my profile.
 */
export const new_matches_exp = (): string => stringUtil.getStringWithArgs("new_matches_exp");

/**
 * @returns {String} Scholarship Updates
 */
export const schol_updates = (): string => stringUtil.getStringWithArgs("schol_updates");

/**
 * @returns {String} Tell me if there are updates to the scholarships in my lists.
 */
export const schol_updates_exp = (): string => stringUtil.getStringWithArgs("schol_updates_exp");

/**
 * @returns {String} Current Email
 */
export const current_email = (): string => stringUtil.getStringWithArgs("current_email");

/**
 * @returns {String} Please check your internet connection then try again.
 */
export const check_internet = (): string => stringUtil.getStringWithArgs("check_internet");

/**
 * @returns {String} General Contact Emails
 */
export const send_news_title = (): string => stringUtil.getStringWithArgs("send_news_title");

/**
 * @returns {String} Loading
 */
export const loading = (): string => stringUtil.getStringWithArgs("loading");

/**
 * @returns {String} replay
 */
export const replay = (): string => stringUtil.getStringWithArgs("replay");

/**
 * @returns {String} Language
 */
export const language = (): string => stringUtil.getStringWithArgs("language");

/**
 * @returns {String} Communication Preferences
 */
export const notificationSettings = (): string => stringUtil.getStringWithArgs("notificationSettings");

/**
 * @returns {String} Account Settings
 */
export const accSettings = (): string => stringUtil.getStringWithArgs("accSettings");

/**
 * @returns {String} Gift Card
 */
export const giftCard = (): string => stringUtil.getStringWithArgs("giftCard");

/**
 * @returns {String} Manage your password and account settings.
 */
export const account_settings_exp = (): string => stringUtil.getStringWithArgs("account_settings_exp");

/**
 * @returns {String} Manage your preferences for communications from UCAS International.
 */
export const notif_settings_exp = (): string => stringUtil.getStringWithArgs("notif_settings_exp");

/**
 * @returns {String} Tell all your friends about the UCAS International App.
 */
export const share_exp = (): string => stringUtil.getStringWithArgs("share_exp");

/**
 * @returns {String} Check out your gift card.
 */
export const gift_exp = (): string => stringUtil.getStringWithArgs("gift_exp");

/**
 * @returns {String} Copyright, licensing and legal information.
 */
export const about_exp = (): string => stringUtil.getStringWithArgs("about_exp");

/**
 * @returns {String} Login Expired
 */
export const login_expired = (): string => stringUtil.getStringWithArgs("login_expired");

/**
 * @returns {String} You must sign in again. This may be because you changed your email or password.
 */
export const login_expired_exp = (): string => stringUtil.getStringWithArgs("login_expired_exp");

/**
 * @returns {String} %s scholarships
 */
export const num_scholarships = (arg0: string): string => stringUtil.getStringWithArgs("num_scholarships", arg0);

/**
 * @returns {String} Moving scholarship
 */
export const moving_schol = (): string => stringUtil.getStringWithArgs("moving_schol");

/**
 * @returns {String} SAVE 50%
 */
export const save_50 = (): string => stringUtil.getStringWithArgs("save_50");

/**
 * @returns {String} BEST VALUE
 */
export const best_value = (): string => stringUtil.getStringWithArgs("best_value");

/**
 * @returns {String} Email Change
 */
export const email_change_title = (): string => stringUtil.getStringWithArgs("email_change_title");

/**
 * @returns {String} Your email has been successfully changed.
 */
export const email_changed = (): string => stringUtil.getStringWithArgs("email_changed");

/**
 * @returns {String} Your message has been sent.
 */
export const your_message_sent = (): string => stringUtil.getStringWithArgs("your_message_sent");

/**
 * @returns {String} Sending
 */
export const sending = (): string => stringUtil.getStringWithArgs("sending");

/**
 * @returns {String} Incorrect password
 */
export const incorrect_password = (): string => stringUtil.getStringWithArgs("incorrect_password");

/**
 * @returns {String} Would you like to change the email associated with your account? This will change the email you use for signing in as well as for sending university applications.
 */
export const change_email_exp = (): string => stringUtil.getStringWithArgs("change_email_exp");

/**
 * @returns {String} Would you like to change the email associated with your account? This will change the email you use for signing in.
 */
export const change_email_exp_no_apps = (): string => stringUtil.getStringWithArgs("change_email_exp_no_apps");

/**
 * @returns {String} Nice job!
 */
export const nice_job = (): string => stringUtil.getStringWithArgs("nice_job");

/**
 * @returns {String} Congratulations!
 */
export const congrats = (): string => stringUtil.getStringWithArgs("congrats");

/**
 * @returns {String} Scholarship has been deleted.
 */
export const schol_deleted = (): string => stringUtil.getStringWithArgs("schol_deleted");

/**
 * @returns {String} Notifications
 */
export const notif_permission_title = (): string => stringUtil.getStringWithArgs("notif_permission_title");

/**
 * @returns {String} Allow notifications for:\n+ New Scholarships \n+ Scholarship Updates \n+ Application Deadlines
 */
export const notif_permission_exp = (): string => stringUtil.getStringWithArgs("notif_permission_exp");

/**
 * @returns {String} Yes, please
 */
export const yes_please = (): string => stringUtil.getStringWithArgs("yes_please");

/**
 * @returns {String} No, thanks
 */
export const no_thanks2 = (): string => stringUtil.getStringWithArgs("no_thanks2");

/**
 * @returns {String} Email
 */
export const email = (): string => stringUtil.getStringWithArgs("email");

/**
 * @returns {String} Create Your Password
 */
export const create_pass = (): string => stringUtil.getStringWithArgs("create_pass");

/**
 * @returns {String} A verification email has been sent to %s. Please use the link in the email to authenticate your account and then 'reload' the app to continue.
 */
export const email_not_verified = (arg0: string): string => stringUtil.getStringWithArgs("email_not_verified", arg0);

/**
 * @returns {String} Sign In
 */
export const sign_in = (): string => stringUtil.getStringWithArgs("sign_in");

/**
 * @returns {String} Back
 */
export const back = (): string => stringUtil.getStringWithArgs("back");

/**
 * @returns {String} Password
 */
export const password = (): string => stringUtil.getStringWithArgs("password");

/**
 * @returns {String} Recovery Password
 */
export const recover_password = (): string => stringUtil.getStringWithArgs("recover_password");

/**
 * @returns {String} The email and password you entered don't match.
 */
export const password_dont_match = (): string => stringUtil.getStringWithArgs("password_dont_match");

/**
 * @returns {String} By tapping Save you are indicating that you agree to the Terms of Service and the Privacy Policy
 */
export const tapping_save_tos = (): string => stringUtil.getStringWithArgs("tapping_save_tos");

/**
 * @returns {String} Payment of %s will be billed for the full \n1-month period when you sign up.
 */
export const payment1 = (arg0: string): string => stringUtil.getStringWithArgs("payment1", arg0);

/**
 * @returns {String} Payment of %s will be billed for the full \n6-month period when you sign up.
 */
export const payment6 = (arg0: string): string => stringUtil.getStringWithArgs("payment6", arg0);

/**
 * @returns {String} Payment of %s will be billed for the full \n12-month period when you sign up.
 */
export const payment12 = (arg0: string): string => stringUtil.getStringWithArgs("payment12", arg0);

/**
 * @returns {String} Contact
 */
export const contact_grantfairy = (): string => stringUtil.getStringWithArgs("contact_grantfairy");

/**
 * @returns {String} Applications
 */
export const applications = (): string => stringUtil.getStringWithArgs("applications");

/**
 * @returns {String} Fee Status
 */
export const fee_status = (): string => stringUtil.getStringWithArgs("fee_status");

/**
 * @returns {String} Checkout
 */
export const checkout = (): string => stringUtil.getStringWithArgs("checkout");

/**
 * @returns {String} Apply
 */
export const apply = (): string => stringUtil.getStringWithArgs("apply");

/**
 * @returns {String} You don't have any applications in your %s!
 */
export const no_applications = (arg0: string): string => stringUtil.getStringWithArgs("no_applications", arg0);

/**
 * @returns {String} You have no complete applications, all sections of an application must be complete before you are able to move it to the cart.
 */
export const no_complete_applications_top = (): string => stringUtil.getStringWithArgs("no_complete_applications_top");

/**
 * @returns {String} When an application is added to the cart you will be required to pay a platform fee to enable %s to process and prepare your application.
 */
export const no_complete_applications_bottom = (arg0: string): string => stringUtil.getStringWithArgs("no_complete_applications_bottom", arg0);

/**
 * @returns {String} Cart
 */
export const cart = (): string => stringUtil.getStringWithArgs("cart");

/**
 * @returns {String} Documents
 */
export const documents = (): string => stringUtil.getStringWithArgs("documents");

/**
 * @returns {String} Your Application Details
 */
export const application_form = (): string => stringUtil.getStringWithArgs("application_form");

/**
 * @returns {String} Edit
 */
export const edit = (): string => stringUtil.getStringWithArgs("edit");

/**
 * @returns {String} Delete
 */
export const delet = (): string => stringUtil.getStringWithArgs("delete");

/**
 * @returns {String} Save as draft
 */
export const saveDraft = (): string => stringUtil.getStringWithArgs("saveDraft");

/**
 * @returns {String} Add to cart
 */
export const add_cart = (): string => stringUtil.getStringWithArgs("add_cart");

/**
 * @returns {String} Add more documents
 */
export const add_more_documents = (): string => stringUtil.getStringWithArgs("add_more_documents");

/**
 * @returns {String} Draft Saved!
 */
export const saved_draft = (): string => stringUtil.getStringWithArgs("saved_draft");

/**
 * @returns {String} The university application has been added to your cart.
 */
export const saved_to_cart = (): string => stringUtil.getStringWithArgs("saved_to_cart");

/**
 * @returns {String} Go to cart
 */
export const go_cart = (): string => stringUtil.getStringWithArgs("go_cart");

/**
 * @returns {String} SEND APPLICATION (%d)
 */
export const send_application_button = (arg0: number): string => stringUtil.getStringWithArgs("send_application_button", arg0);

/**
 * @returns {String} SEND APPLICATION
 */
export const send_application_button_none = (): string => stringUtil.getStringWithArgs("send_application_button_none");

/**
 * @returns {String} Send Applications
 */
export const send_application = (): string => stringUtil.getStringWithArgs("send_application");

/**
 * @returns {String} You have no complete applications, all sections of an application must be complete before you are able to move it to the cart. When an application is added to the cart you will be required to pay a platform fee to enable %s to process and prepare your application.
 */
export const no_complete_applications = (arg0: string): string => stringUtil.getStringWithArgs("no_complete_applications", arg0);

/**
 * @returns {String} Do you want to send this application? \n\nYou will be charged %s.
 */
export const confirm_send_app = (arg0: string): string => stringUtil.getStringWithArgs("confirm_send_app", arg0);

/**
 * @returns {String} Do you want to send these applications? \n\nYou will be charged %s per application.
 */
export const confirm_send_apps = (arg0: string): string => stringUtil.getStringWithArgs("confirm_send_apps", arg0);

/**
 * @returns {String} Please select which applications you would like to purchase.
 */
export const please_select_apps = (): string => stringUtil.getStringWithArgs("please_select_apps");

/**
 * @returns {String} Delete University Application
 */
export const delete_application = (): string => stringUtil.getStringWithArgs("delete_application");

/**
 * @returns {String} Are you sure you want to delete this application?
 */
export const confirm_delete_application = (): string => stringUtil.getStringWithArgs("confirm_delete_application");

/**
 * @returns {String} Unsent Items
 */
export const unsent_items = (): string => stringUtil.getStringWithArgs("unsent_items");

/**
 * @returns {String} You have %d unsent applications in your cart.
 */
export const unsent_applications_in_cart = (arg0: number): string => stringUtil.getStringWithArgs("unsent_applications_in_cart", arg0);

/**
 * @returns {String} You have 1 unsent application in your cart.
 */
export const unsent_application_in_cart = (): string => stringUtil.getStringWithArgs("unsent_application_in_cart");

/**
 * @returns {String} Move to Draft
 */
export const move_to_drafts = (): string => stringUtil.getStringWithArgs("move_to_drafts");

/**
 * @returns {String} Are you sure you want to move this application back to Drafts?
 */
export const move_to_drafts_confirm = (): string => stringUtil.getStringWithArgs("move_to_drafts_confirm");

/**
 * @returns {String} Are you sure you want to delete this %s?
 */
export const sure_delete_record = (arg0: string): string => stringUtil.getStringWithArgs("sure_delete_record", arg0);

/**
 * @returns {String} Delete %s
 */
export const delete_record = (arg0: string): string => stringUtil.getStringWithArgs("delete_record", arg0);

/**
 * @returns {String} Type a message
 */
export const type_a_message = (): string => stringUtil.getStringWithArgs("type_a_message");

/**
 * @returns {String} Messages
 */
export const messages = (): string => stringUtil.getStringWithArgs("messages");

/**
 * @returns {String} Send Message
 */
export const send_message = (): string => stringUtil.getStringWithArgs("send_message");

/**
 * @returns {String} Are you sure you want to send this message?
 */
export const sure_send_message = (): string => stringUtil.getStringWithArgs("sure_send_message");

/**
 * @returns {String} Send File
 */
export const send_file = (): string => stringUtil.getStringWithArgs("send_file");

/**
 * @returns {String} Are you sure you want to send this file? It will be added to the Other Documents section of your application.
 */
export const sure_send_file = (): string => stringUtil.getStringWithArgs("sure_send_file");

/**
 * @returns {String} Upload your documents here to the Documents section: personal statements, ID documents, references, etc. \n\nYou will be able to choose which documents you attach to each university application, so you can write a customized personal statement for each if you want.
 */
export const documents_first_time = (): string => stringUtil.getStringWithArgs("documents_first_time");

/**
 * @returns {String} Welcome to the University Applications section! \n\nThe details you provide on this application form will be used for all your university applications, so you can apply to multiple universities with ease. \n\nThe form itself will take about 15 mins to complete, but you can save a draft and come back at any time. \n\nLet's get you started...
 */
export const applications_first_time = (): string => stringUtil.getStringWithArgs("applications_first_time");

/**
 * @returns {String} You have already applied to this course.
 */
export const already_applied_course = (): string => stringUtil.getStringWithArgs("already_applied_course");

/**
 * @returns {String} You already have a draft application in progress. Do you want to edit this now?
 */
export const already_in_draft = (): string => stringUtil.getStringWithArgs("already_in_draft");

/**
 * @returns {String} Draft Application
 */
export const draft_app = (): string => stringUtil.getStringWithArgs("draft_app");

/**
 * @returns {String} Draft Applications
 */
export const draft_apps = (): string => stringUtil.getStringWithArgs("draft_apps");

/**
 * @returns {String} Sent Applications
 */
export const sent_apps = (): string => stringUtil.getStringWithArgs("sent_apps");

/**
 * @returns {String} Processing Applications
 */
export const processing_apps = (): string => stringUtil.getStringWithArgs("processing_apps");

/**
 * @returns {String} Rejected Applications
 */
export const rejected_apps = (): string => stringUtil.getStringWithArgs("rejected_apps");

/**
 * @returns {String} Invalid Applications
 */
export const invalid_apps = (): string => stringUtil.getStringWithArgs("invalid_apps");

/**
 * @returns {String} Sent
 */
export const sent = (): string => stringUtil.getStringWithArgs("sent");

/**
 * @returns {String} Draft
 */
export const draft = (): string => stringUtil.getStringWithArgs("draft");

/**
 * @returns {String} In Cart
 */
export const in_cart = (): string => stringUtil.getStringWithArgs("in_cart");

/**
 * @returns {String} Processing
 */
export const processing = (): string => stringUtil.getStringWithArgs("processing");

/**
 * @returns {String} New Messages
 */
export const new_messages = (): string => stringUtil.getStringWithArgs("new_messages");

/**
 * @returns {String} View Messages
 */
export const view_messages = (): string => stringUtil.getStringWithArgs("view_messages");

/**
 * @returns {String} You have new messages regarding your university application.
 */
export const new_messages_info = (): string => stringUtil.getStringWithArgs("new_messages_info");

/**
 * @returns {String} paste text
 */
export const paste_text = (): string => stringUtil.getStringWithArgs("paste_text");

/**
 * @returns {String} upload new
 */
export const upload_new = (): string => stringUtil.getStringWithArgs("upload_new");

/**
 * @returns {String} Saving...
 */
export const saving = (): string => stringUtil.getStringWithArgs("saving");

/**
 * @returns {String} You need to complete the application form and select documents to attach before you can apply.
 */
export const need_to_complete_app = (): string => stringUtil.getStringWithArgs("need_to_complete_app");

/**
 * @returns {String} University Applications
 */
export const application = (): string => stringUtil.getStringWithArgs("application");

/**
 * @returns {String} Application Sent
 */
export const application_sent = (): string => stringUtil.getStringWithArgs("application_sent");

/**
 * @returns {String} Applications Sent
 */
export const applications_sent = (): string => stringUtil.getStringWithArgs("applications_sent");

/**
 * @returns {String} Your applications have been sent.
 */
export const applications_sent_info = (): string => stringUtil.getStringWithArgs("applications_sent_info");

/**
 * @returns {String} Your application has been sent.
 */
export const application_sent_info = (): string => stringUtil.getStringWithArgs("application_sent_info");

/**
 * @returns {String} Please select 10 applications or fewer.
 */
export const too_many_apps = (): string => stringUtil.getStringWithArgs("too_many_apps");

/**
 * @returns {String} Select documents to attach
 */
export const select_documents_to_attach = (): string => stringUtil.getStringWithArgs("select_documents_to_attach");

/**
 * @returns {String} Accept
 */
export const accept = (): string => stringUtil.getStringWithArgs("accept");

/**
 * @returns {String} Decline
 */
export const decline = (): string => stringUtil.getStringWithArgs("decline");

/**
 * @returns {String} Accept Offer
 */
export const accept_offer = (): string => stringUtil.getStringWithArgs("accept_offer");

/**
 * @returns {String} Are you sure you want to accept this offer as your firm choice?
 */
export const accept_firm = (): string => stringUtil.getStringWithArgs("accept_firm");

/**
 * @returns {String} Are you sure you want to accept this offer as your insurance choice?
 */
export const accept_backup = (): string => stringUtil.getStringWithArgs("accept_backup");

/**
 * @returns {String} Decline Offer
 */
export const decline_offer = (): string => stringUtil.getStringWithArgs("decline_offer");

/**
 * @returns {String} Are you sure you want to decline this offer?
 */
export const decline_confirm = (): string => stringUtil.getStringWithArgs("decline_confirm");

/**
 * @returns {String} Accept Firm Choice
 */
export const accept_firm_title = (): string => stringUtil.getStringWithArgs("accept_firm_title");

/**
 * @returns {String} Accept Insurance Choice
 */
export const accept_backup_title = (): string => stringUtil.getStringWithArgs("accept_backup_title");

/**
 * @returns {String} Change to Firm Choice
 */
export const change_firm_title = (): string => stringUtil.getStringWithArgs("change_firm_title");

/**
 * @returns {String} Change to Insurance Choice
 */
export const change_backup_title = (): string => stringUtil.getStringWithArgs("change_backup_title");

/**
 * @returns {String} Are you sure you want to accept this offer as your firm choice? Your existing firm choice, %s, will %s
 */
export const change_firm_confirm_replace = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("change_firm_confirm_replace", arg0, arg1);

/**
 * @returns {String} Are you sure you want to accept this offer as your insurance choice? Your existing insurance choice, %s, will %s
 */
export const change_insurance_confirm_replace = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("change_insurance_confirm_replace", arg0, arg1);

/**
 * @returns {String} become your insurance choice.
 */
export const change_replace_will_become_insurance = (): string => stringUtil.getStringWithArgs("change_replace_will_become_insurance");

/**
 * @returns {String} become your firm choice.
 */
export const change_replace_will_become_firm = (): string => stringUtil.getStringWithArgs("change_replace_will_become_firm");

/**
 * @returns {String} be declined.
 */
export const change_replace_will_become_declined = (): string => stringUtil.getStringWithArgs("change_replace_will_become_declined");

/**
 * @returns {String} Are you sure you want to accept this offer as your firm choice? Your existing firm choice, %s, will become your insurance choice.
 */
export const change_firm_confirm_replace_become_insurance = (arg0: string): string => stringUtil.getStringWithArgs("change_firm_confirm_replace_become_insurance", arg0);

/**
 * @returns {String} Are you sure you want to accept this offer as your firm choice? Your existing firm choice, %s, will be declined.
 */
export const change_firm_confirm_replace_be_declined = (arg0: string): string => stringUtil.getStringWithArgs("change_firm_confirm_replace_be_declined", arg0);

/**
 * @returns {String} Are you sure you want to accept this offer as your insurance choice? Your existing insurance choice, %s, will become your firm choice.
 */
export const change_insurance_confirm_replace_become_firm = (arg0: string): string => stringUtil.getStringWithArgs("change_insurance_confirm_replace_become_firm", arg0);

/**
 * @returns {String} Are you sure you want to accept this offer as your insurance choice? Your existing insurance choice, %s, will be declined.
 */
export const change_insurance_confirm_replace_be_declined = (arg0: string): string => stringUtil.getStringWithArgs("change_insurance_confirm_replace_be_declined", arg0);

/**
 * @returns {String} Accepted as "firm" (first choice)
 */
export const accepted_firm = (): string => stringUtil.getStringWithArgs("accepted_firm");

/**
 * @returns {String} Accepted as "insurance" (backup choice)
 */
export const accepted_ins = (): string => stringUtil.getStringWithArgs("accepted_ins");

/**
 * @returns {String} Upload Document
 */
export const upload_document = (): string => stringUtil.getStringWithArgs("upload_document");

/**
 * @returns {String} Scan with camera
 */
export const scan_camera = (): string => stringUtil.getStringWithArgs("scan_camera");

/**
 * @returns {String} Upload File
 */
export const upload_file = (): string => stringUtil.getStringWithArgs("upload_file");

/**
 * @returns {String} Browse Photos
 */
export const browse_photos = (): string => stringUtil.getStringWithArgs("browse_photos");

/**
 * @returns {String} Delete document
 */
export const delete_doc = (): string => stringUtil.getStringWithArgs("delete_doc");

/**
 * @returns {String} Deleting File
 */
export const deleting_file = (): string => stringUtil.getStringWithArgs("deleting_file");

/**
 * @returns {String} File Deleted
 */
export const file_deleted = (): string => stringUtil.getStringWithArgs("file_deleted");

/**
 * @returns {String} Invalid file
 */
export const invalid_file = (): string => stringUtil.getStringWithArgs("invalid_file");

/**
 * @returns {String} Uploading File
 */
export const uploading_file = (): string => stringUtil.getStringWithArgs("uploading_file");

/**
 * @returns {String} File Uploaded
 */
export const file_uploaded = (): string => stringUtil.getStringWithArgs("file_uploaded");

/**
 * @returns {String} Edit Document
 */
export const edit_document = (): string => stringUtil.getStringWithArgs("edit_document");

/**
 * @returns {String} Download
 */
export const download = (): string => stringUtil.getStringWithArgs("download");

/**
 * @returns {String} Downloading File
 */
export const downloading_file = (): string => stringUtil.getStringWithArgs("downloading_file");

/**
 * @returns {String} Invalid file type:
 */
export const invalid_type_colon = (): string => stringUtil.getStringWithArgs("invalid_type_colon");

/**
 * @returns {String} Please upload a file of one of the following types:
 */
export const please_upload_types = (): string => stringUtil.getStringWithArgs("please_upload_types");

/**
 * @returns {String} Are you sure you want to delete %s?
 */
export const sure_want_delete = (arg0: string): string => stringUtil.getStringWithArgs("sure_want_delete", arg0);

/**
 * @returns {String} Please ensure the length of your document does not exceed %d characters. The file you uploaded has %d characters
 */
export const too_many_char = (arg0: number, arg1: number): string => stringUtil.getStringWithArgs("too_many_char", arg0, arg1);

/**
 * @returns {String} Please specify
 */
export const please_specify = (): string => stringUtil.getStringWithArgs("please_specify");

/**
 * @returns {String} In Review
 */
export const in_review = (): string => stringUtil.getStringWithArgs("in_review");

/**
 * @returns {String} Awaiting Information
 */
export const awaiting_info = (): string => stringUtil.getStringWithArgs("awaiting_info");

/**
 * @returns {String} Offer Received
 */
export const offer_made = (): string => stringUtil.getStringWithArgs("offer_made");

/**
 * @returns {String} Application Declined
 */
export const uni_rejected = (): string => stringUtil.getStringWithArgs("uni_rejected");

/**
 * @returns {String} Offer Rejected
 */
export const offer_rejected = (): string => stringUtil.getStringWithArgs("offer_rejected");

/**
 * @returns {String} Sending Applications
 */
export const sending_applications = (): string => stringUtil.getStringWithArgs("sending_applications");

/**
 * @returns {String} Preview Application Details
 */
export const view_all_dets = (): string => stringUtil.getStringWithArgs("view_all_dets");

/**
 * @returns {String} Preview
 */
export const preview = (): string => stringUtil.getStringWithArgs("preview");

/**
 * @returns {String} drafts
 */
export const drafts = (): string => stringUtil.getStringWithArgs("drafts");

/**
 * @returns {String} You
 */
export const you = (): string => stringUtil.getStringWithArgs("you");

/**
 * @returns {String} This university is not accepting applications.
 */
export const not_accepting_apps = (): string => stringUtil.getStringWithArgs("not_accepting_apps");

/**
 * @returns {String} This university is no longer accepting applications.
 */
export const no_longer_accepting_apps = (): string => stringUtil.getStringWithArgs("no_longer_accepting_apps");

/**
 * @returns {String} Add Referee
 */
export const add_referee = (): string => stringUtil.getStringWithArgs("add_referee");

/**
 * @returns {String} Clear
 */
export const clear = (): string => stringUtil.getStringWithArgs("clear");

/**
 * @returns {String} Enter File Name
 */
export const enter_file_name = (): string => stringUtil.getStringWithArgs("enter_file_name");

/**
 * @returns {String} From: %s
 */
export const from_email = (arg0: string): string => stringUtil.getStringWithArgs("from_email", arg0);

/**
 * @returns {String} Accepted File Types: %s
 */
export const accepted_file_types = (arg0: string): string => stringUtil.getStringWithArgs("accepted_file_types", arg0);

/**
 * @returns {String} Other
 */
export const radio_entry_other_option = (): string => stringUtil.getStringWithArgs("radio_entry_other_option");

/**
 * @returns {String} Status: %s
 */
export const status_colon = (arg0: string): string => stringUtil.getStringWithArgs("status_colon", arg0);

/**
 * @returns {String} PhD courses
 */
export const phd_courses = (): string => stringUtil.getStringWithArgs("phd_courses");

/**
 * @returns {String} %d courses
 */
export const number_courses = (arg0: number): string => stringUtil.getStringWithArgs("number_courses", arg0);

/**
 * @returns {String} Select a Category
 */
export const select_a_category = (): string => stringUtil.getStringWithArgs("select_a_category");

/**
 * @returns {String} Uploaded: %s
 */
export const uploaded_colon = (arg0: string): string => stringUtil.getStringWithArgs("uploaded_colon", arg0);

/**
 * @returns {String} Unanswered
 */
export const unanswered = (): string => stringUtil.getStringWithArgs("unanswered");

/**
 * @returns {String} Declaration
 */
export const declaration = (): string => stringUtil.getStringWithArgs("declaration");

/**
 * @returns {String} Click to select
 */
export const click_to_select = (): string => stringUtil.getStringWithArgs("click_to_select");

/**
 * @returns {String} Please select an option
 */
export const please_select_an_option = (): string => stringUtil.getStringWithArgs("please_select_an_option");

/**
 * @returns {String} Sending %d Applications
 */
export const sending_x_applications = (arg0: number): string => stringUtil.getStringWithArgs("sending_x_applications", arg0);

/**
 * @returns {String} Platform fee for %d Applications
 */
export const fee_for_x_applications = (arg0: number): string => stringUtil.getStringWithArgs("fee_for_x_applications", arg0);

/**
 * @returns {String} Application Status
 */
export const application_status = (): string => stringUtil.getStringWithArgs("application_status");

/**
 * @returns {String} Processing Payment
 */
export const processing_payment = (): string => stringUtil.getStringWithArgs("processing_payment");

/**
 * @returns {String} Agreements
 */
export const agreements = (): string => stringUtil.getStringWithArgs("agreements");

/**
 * @returns {String} Course Information
 */
export const course_information = (): string => stringUtil.getStringWithArgs("course_information");

/**
 * @returns {String} Start typing a university name
 */
export const start_typing_uni_name = (): string => stringUtil.getStringWithArgs("start_typing_uni_name");

/**
 * @returns {String} %s courses
 */
export const type_courses = (arg0: string): string => stringUtil.getStringWithArgs("type_courses", arg0);

/**
 * @returns {String} View All
 */
export const view_all = (): string => stringUtil.getStringWithArgs("view_all");

/**
 * @returns {String} View All Courses
 */
export const view_all_courses = (): string => stringUtil.getStringWithArgs("view_all_courses");

/**
 * @returns {String} Browse Courses
 */
export const browse_courses2 = (): string => stringUtil.getStringWithArgs("browse_courses2");

/**
 * @returns {String} You must choose a file name.
 */
export const must_choose_file_name = (): string => stringUtil.getStringWithArgs("must_choose_file_name");

/**
 * @returns {String} Name is too long.
 */
export const name_too_long = (): string => stringUtil.getStringWithArgs("name_too_long");

/**
 * @returns {String} Name contains invalid characters.
 */
export const name_invalid = (): string => stringUtil.getStringWithArgs("name_invalid");

/**
 * @returns {String} File too large
 */
export const file_too_large = (): string => stringUtil.getStringWithArgs("file_too_large");

/**
 * @returns {String} Please ensure all files are under 5mb.
 */
export const file_limit_5mb = (): string => stringUtil.getStringWithArgs("file_limit_5mb");

/**
 * @returns {String} Edit Profile
 */
export const edit_profile = (): string => stringUtil.getStringWithArgs("edit_profile");

/**
 * @returns {String} Purchasing
 */
export const purchasing = (): string => stringUtil.getStringWithArgs("purchasing");

/**
 * @returns {String} Make Payment
 */
export const purchase = (): string => stringUtil.getStringWithArgs("purchase");

/**
 * @returns {String} Thank you for subscribing. Click "next" to start browsing the available scholarships in your WishList.
 */
export const thank_subscribing = (): string => stringUtil.getStringWithArgs("thank_subscribing");

/**
 * @returns {String} Simply click a guide on the left to get started!
 */
export const htg_get_started = (): string => stringUtil.getStringWithArgs("htg_get_started");

/**
 * @returns {String} Welcome to How-To Guides
 */
export const welcome_htg = (): string => stringUtil.getStringWithArgs("welcome_htg");

/**
 * @returns {String} Search Courses
 */
export const search_courses = (): string => stringUtil.getStringWithArgs("search_courses");

/**
 * @returns {String} Subscribe
 */
export const subscribe = (): string => stringUtil.getStringWithArgs("subscribe");

/**
 * @returns {String} Cancel Subscription
 */
export const unsubscribe = (): string => stringUtil.getStringWithArgs("unsubscribe");

/**
 * @returns {String} Sorry to see you go...
 */
export const sorry_see_go = (): string => stringUtil.getStringWithArgs("sorry_see_go");

/**
 * @returns {String} Do not cancel
 */
export const do_not_cancel = (): string => stringUtil.getStringWithArgs("do_not_cancel");

/**
 * @returns {String} Cancel Subscription
 */
export const cancel_sub = (): string => stringUtil.getStringWithArgs("cancel_sub");

/**
 * @returns {String} Are you sure you want to cancel your subscription? \n\nYou will still have full access until the next payment is due, but will not be charged any further.
 */
export const sure_cancel_sub = (): string => stringUtil.getStringWithArgs("sure_cancel_sub");

/**
 * @returns {String} More
 */
export const more = (): string => stringUtil.getStringWithArgs("more");

/**
 * @returns {String} Are you sure you want to leave this page? \nYour profile will not be saved.
 */
export const sure_leave_page = (): string => stringUtil.getStringWithArgs("sure_leave_page");

/**
 * @returns {String} Your browser notifications are currently turned OFF which means you won't see information about: \n+ New Scholarships \n+ Scholarship Updates \n+ Application Deadlines \nPlease click the PADLOCK in the address bar to turn notifications on.
 */
export const notif_is_off = (): string => stringUtil.getStringWithArgs("notif_is_off");

/**
 * @returns {String} Later
 */
export const later = (): string => stringUtil.getStringWithArgs("later");

/**
 * @returns {String} Now
 */
export const now = (): string => stringUtil.getStringWithArgs("now");

/**
 * @returns {String} Apologies…
 */
export const uni_not_accepting_apps_apologies = (): string => stringUtil.getStringWithArgs("uni_not_accepting_apps_apologies");

/**
 * @returns {String} This university is not currently accepting applications from Home students via the app.
 */
export const uni_not_accepting_apps_body_home = (): string => stringUtil.getStringWithArgs("uni_not_accepting_apps_body_home");

/**
 * @returns {String} This university is not currently accepting applications from International students via the app.
 */
export const uni_not_accepting_apps_body_int = (): string => stringUtil.getStringWithArgs("uni_not_accepting_apps_body_int");

/**
 * @returns {String} Approval needed
 */
export const approval_needed_title = (): string => stringUtil.getStringWithArgs("approval_needed_title");

/**
 * @returns {String} Your agent needs to approve this application before it can be sent
 */
export const approval_needed_message = (): string => stringUtil.getStringWithArgs("approval_needed_message");

/**
 * @returns {String} Sent Application
 */
export const sent_application = (): string => stringUtil.getStringWithArgs("sent_application");

/**
 * @returns {String} You have already applied to this course. Would you like to view your messages?
 */
export const already_applied_view_messages = (): string => stringUtil.getStringWithArgs("already_applied_view_messages");

/**
 * @returns {String} Bookings
 */
export const bookings = (): string => stringUtil.getStringWithArgs("bookings");

/**
 * @returns {String} Chat
 */
export const chat = (): string => stringUtil.getStringWithArgs("chat");

/**
 * @returns {String} You don't have any favourites yet. Tap the heart icon next to a property to add it to the list.
 */
export const no_favourite_properties = (): string => stringUtil.getStringWithArgs("no_favourite_properties");

/**
 * @returns {String} Map
 */
export const map = (): string => stringUtil.getStringWithArgs("map");

/**
 * @returns {String} University or Campus
 */
export const university_or_campus = (): string => stringUtil.getStringWithArgs("university_or_campus");

/**
 * @returns {String} No results were found
 */
export const no_search_results = (): string => stringUtil.getStringWithArgs("no_search_results");

/**
 * @returns {String} No results were found. Please try a different campus or filters.
 */
export const no_search_results_try_filters = (): string => stringUtil.getStringWithArgs("no_search_results_try_filters");

/**
 * @returns {String} Select a university or campus below
 */
export const select_uni_campus_below = (): string => stringUtil.getStringWithArgs("select_uni_campus_below");

/**
 * @returns {String} Select your university or campus to get started.
 */
export const welcome_acc = (): string => stringUtil.getStringWithArgs("welcome_acc");

/**
 * @returns {String} Property Details
 */
export const property_details = (): string => stringUtil.getStringWithArgs("property_details");

/**
 * @returns {String} Accommodation Application Form
 */
export const acc_app_form = (): string => stringUtil.getStringWithArgs("acc_app_form");

/**
 * @returns {String} No Limit
 */
export const no_limit = (): string => stringUtil.getStringWithArgs("no_limit");

/**
 * @returns {String} From %s / %s
 */
export const price_from = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("price_from", arg0, arg1);

/**
 * @returns {String} %s / %s
 */
export const price = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("price", arg0, arg1);

/**
 * @returns {String} %s - %s
 */
export const range = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("range", arg0, arg1);

/**
 * @returns {String} Book Now
 */
export const book_now = (): string => stringUtil.getStringWithArgs("book_now");

/**
 * @returns {String} Book with Pearson
 */
export const book_pearson = (): string => stringUtil.getStringWithArgs("book_pearson");

/**
 * @returns {String} View Less
 */
export const view_less = (): string => stringUtil.getStringWithArgs("view_less");

/**
 * @returns {String} Facilities
 */
export const facilities = (): string => stringUtil.getStringWithArgs("facilities");

/**
 * @returns {String} Rooms
 */
export const rooms = (): string => stringUtil.getStringWithArgs("rooms");

/**
 * @returns {String} Terms and Conditions
 */
export const term_and_cond = (): string => stringUtil.getStringWithArgs("term_and_cond");

/**
 * @returns {String} FAQ
 */
export const faq = (): string => stringUtil.getStringWithArgs("faq");

/**
 * @returns {String} Cancellation Policy
 */
export const cancellation_policy = (): string => stringUtil.getStringWithArgs("cancellation_policy");

/**
 * @returns {String} View Rooms
 */
export const view_rooms = (): string => stringUtil.getStringWithArgs("view_rooms");

/**
 * @returns {String} Close to city
 */
export const city_close = (): string => stringUtil.getStringWithArgs("city_close");

/**
 * @returns {String} Far from city
 */
export const city_far = (): string => stringUtil.getStringWithArgs("city_far");

/**
 * @returns {String} Lowest price
 */
export const price_low = (): string => stringUtil.getStringWithArgs("price_low");

/**
 * @returns {String} Highest Price
 */
export const price_high = (): string => stringUtil.getStringWithArgs("price_high");

/**
 * @returns {String} Close to Campus
 */
export const uni_close = (): string => stringUtil.getStringWithArgs("uni_close");

/**
 * @returns {String} Far from Campus
 */
export const uni_far = (): string => stringUtil.getStringWithArgs("uni_far");

/**
 * @returns {String} Sold Out
 */
export const sold_out = (): string => stringUtil.getStringWithArgs("sold_out");

/**
 * @returns {String} Room Details
 */
export const room_details = (): string => stringUtil.getStringWithArgs("room_details");

/**
 * @returns {String} Tenancy Options
 */
export const tenancy_options = (): string => stringUtil.getStringWithArgs("tenancy_options");

/**
 * @returns {String} Rooms
 */
export const digs = (): string => stringUtil.getStringWithArgs("digs");

/**
 * @returns {String} All
 */
export const all_2 = (): string => stringUtil.getStringWithArgs("all_2");

/**
 * @returns {String} %d mins
 */
export const travel_time_mins = (arg0: number): string => stringUtil.getStringWithArgs("travel_time_mins", arg0);

/**
 * @returns {String} %d weeks
 */
export const x_weeks = (arg0: number): string => stringUtil.getStringWithArgs("x_weeks", arg0);

/**
 * @returns {String} 1 week
 */
export const one_week = (): string => stringUtil.getStringWithArgs("one_week");

/**
 * @returns {String} Shared
 */
export const shared_room = (): string => stringUtil.getStringWithArgs("shared_room");

/**
 * @returns {String} Private
 */
export const private_room = (): string => stringUtil.getStringWithArgs("private_room");

/**
 * @returns {String} Studio
 */
export const studio_room = (): string => stringUtil.getStringWithArgs("studio_room");

/**
 * @returns {String} Apartment
 */
export const entire_place = (): string => stringUtil.getStringWithArgs("entire_place");

/**
 * @returns {String} Share your room along with bathroom, kitchen & common areas.
 */
export const shared_room_exp = (): string => stringUtil.getStringWithArgs("shared_room_exp");

/**
 * @returns {String} Have the entire place to yourself - room, study spaces, bathroom, kitchen and living room etc.
 */
export const entire_place_exp = (): string => stringUtil.getStringWithArgs("entire_place_exp");

/**
 * @returns {String} Have your own room, study spaces, bathroom and kitchen.
 */
export const studio_room_exp = (): string => stringUtil.getStringWithArgs("studio_room_exp");

/**
 * @returns {String} Have your own room & study spaces. Share some common spaces like kitchen & living room.
 */
export const private_room_exp = (): string => stringUtil.getStringWithArgs("private_room_exp");

/**
 * @returns {String} Show %d properties
 */
export const show_x_properties = (arg0: number): string => stringUtil.getStringWithArgs("show_x_properties", arg0);

/**
 * @returns {String} View Map
 */
export const view_map = (): string => stringUtil.getStringWithArgs("view_map");

/**
 * @returns {String} Distance to %s
 */
export const distance_to = (arg0: string): string => stringUtil.getStringWithArgs("distance_to", arg0);

/**
 * @returns {String} Room Types
 */
export const room_types = (): string => stringUtil.getStringWithArgs("room_types");

/**
 * @returns {String} Availability
 */
export const availability = (): string => stringUtil.getStringWithArgs("availability");

/**
 * @returns {String} Show All
 */
export const show_all = (): string => stringUtil.getStringWithArgs("show_all");

/**
 * @returns {String} Hide Sold
 */
export const hide_sold = (): string => stringUtil.getStringWithArgs("hide_sold");

/**
 * @returns {String} Price Per Week
 */
export const price_per_week = (): string => stringUtil.getStringWithArgs("price_per_week");

/**
 * @returns {String} Tenancy Duration
 */
export const tenancy_duration = (): string => stringUtil.getStringWithArgs("tenancy_duration");

/**
 * @returns {String} %s, %s
 */
export const room_name_property_name = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("room_name_property_name", arg0, arg1);

/**
 * @returns {String} 1 day
 */
export const one_day = (): string => stringUtil.getStringWithArgs("one_day");

/**
 * @returns {String} Move out by %s
 */
export const move_out_by = (arg0: string): string => stringUtil.getStringWithArgs("move_out_by", arg0);

/**
 * @returns {String} Move in after %s
 */
export const move_in_after = (arg0: string): string => stringUtil.getStringWithArgs("move_in_after", arg0);

/**
 * @returns {String} Tenancy
 */
export const tenancy = (): string => stringUtil.getStringWithArgs("tenancy");

/**
 * @returns {String} Sent / Track Booking
 */
export const sent_track_booking = (): string => stringUtil.getStringWithArgs("sent_track_booking");

/**
 * @returns {String} Delete Accommodation Application
 */
export const delete_acc_application = (): string => stringUtil.getStringWithArgs("delete_acc_application");

/**
 * @returns {String} Accommodation Applications
 */
export const acc_apps = (): string => stringUtil.getStringWithArgs("acc_apps");

/**
 * @returns {String} You need to complete the application form before you can apply.
 */
export const need_to_complete_acc_app = (): string => stringUtil.getStringWithArgs("need_to_complete_acc_app");

/**
 * @returns {String} You have already applied for this tenancy.
 */
export const already_applied_acc = (): string => stringUtil.getStringWithArgs("already_applied_acc");

/**
 * @returns {String} This room is currently fully booked. Please choose another from the list.
 */
export const sold_out_message = (): string => stringUtil.getStringWithArgs("sold_out_message");

/**
 * @returns {String} Jobs
 */
export const jobs = (): string => stringUtil.getStringWithArgs("jobs");

/**
 * @returns {String} Distance: Within %d miles
 */
export const distance_x_miles = (arg0: number): string => stringUtil.getStringWithArgs("distance_x_miles", arg0);

/**
 * @returns {String} Full Time
 */
export const full_time = (): string => stringUtil.getStringWithArgs("full_time");

/**
 * @returns {String} Part Time
 */
export const part_time = (): string => stringUtil.getStringWithArgs("part_time");

/**
 * @returns {String} Undergraduate
 */
export const undergraduate = (): string => stringUtil.getStringWithArgs("undergraduate");

/**
 * @returns {String} Graduate
 */
export const graduate = (): string => stringUtil.getStringWithArgs("graduate");

/**
 * @returns {String} Enter a city
 */
export const search_city_hint = (): string => stringUtil.getStringWithArgs("search_city_hint");

/**
 * @returns {String} You must specify either a search term or a location.
 */
export const must_search_or_city = (): string => stringUtil.getStringWithArgs("must_search_or_city");

/**
 * @returns {String} Closest
 */
export const closest = (): string => stringUtil.getStringWithArgs("closest");

/**
 * @returns {String} Most Recent
 */
export const most_recent = (): string => stringUtil.getStringWithArgs("most_recent");

/**
 * @returns {String} Lowest Salary
 */
export const lowest_salary = (): string => stringUtil.getStringWithArgs("lowest_salary");

/**
 * @returns {String} Highest Salary
 */
export const highest_salary = (): string => stringUtil.getStringWithArgs("highest_salary");

/**
 * @returns {String} Most Relevant
 */
export const most_relevant = (): string => stringUtil.getStringWithArgs("most_relevant");

/**
 * @returns {String} Posted: %s
 */
export const date_posted = (arg0: string): string => stringUtil.getStringWithArgs("date_posted", arg0);

/**
 * @returns {String} Jobs
 */
export const just_jobs = (): string => stringUtil.getStringWithArgs("just_jobs");

/**
 * @returns {String} You don't have any favourites yet. Tap the heart icon next to a job to add it to the list.
 */
export const no_favourite_jobs = (): string => stringUtil.getStringWithArgs("no_favourite_jobs");

/**
 * @returns {String} Any
 */
export const any = (): string => stringUtil.getStringWithArgs("any");

/**
 * @returns {String} Find your ideal university course and apply for scholarships.
 */
export const section_desc_gf = (): string => stringUtil.getStringWithArgs("section_desc_gf");

/**
 * @returns {String} Check out the best student accommodation and book it via your mobile phone.
 */
export const section_desc_acc = (): string => stringUtil.getStringWithArgs("section_desc_acc");

/**
 * @returns {String} Find part-time jobs and graduate employment opportunities in your area.
 */
export const section_desc_jobs = (): string => stringUtil.getStringWithArgs("section_desc_jobs");

/**
 * @returns {String} Detailed profiles of universities and degree-offering colleges we work with in the UK.
 */
export const section_desc_uni = (): string => stringUtil.getStringWithArgs("section_desc_uni");

/**
 * @returns {String} Learn more about us and what we offer.
 */
export const section_desc_uni_wl = (): string => stringUtil.getStringWithArgs("section_desc_uni_wl");

/**
 * @returns {String} University Profile
 */
export const uni_profile = (): string => stringUtil.getStringWithArgs("uni_profile");

/**
 * @returns {String} There are no results for your search. Try a different query.
 */
export const no_search_results_try_diff = (): string => stringUtil.getStringWithArgs("no_search_results_try_diff");

/**
 * @returns {String} Do you want to submit this application?
 */
export const confirm_send_app_wl = (): string => stringUtil.getStringWithArgs("confirm_send_app_wl");

/**
 * @returns {String} Do you want to submit these applications?
 */
export const confirm_send_apps_wl = (): string => stringUtil.getStringWithArgs("confirm_send_apps_wl");

/**
 * @returns {String} Courses and Scholarships
 */
export const courses_scholarships = (): string => stringUtil.getStringWithArgs("courses_scholarships");

/**
 * @returns {String} Search Term
 */
export const search_term = (): string => stringUtil.getStringWithArgs("search_term");

/**
 * @returns {String} Job Level
 */
export const job_level = (): string => stringUtil.getStringWithArgs("job_level");

/**
 * @returns {String} Job Location
 */
export const job_location = (): string => stringUtil.getStringWithArgs("job_location");

/**
 * @returns {String} Distance: Within 5 miles
 */
export const distance_within_5_miles = (): string => stringUtil.getStringWithArgs("distance_within_5_miles");

/**
 * @returns {String} No minimum tenancy
 */
export const no_min_tenancy = (): string => stringUtil.getStringWithArgs("no_min_tenancy");

/**
 * @returns {String} %d to %d weeks
 */
export const tenancy_range_weeks = (arg0: number, arg1: number): string => stringUtil.getStringWithArgs("tenancy_range_weeks", arg0, arg1);

/**
 * @returns {String} Tenancy: %d weeks
 */
export const tenancy_x_weeks = (arg0: number): string => stringUtil.getStringWithArgs("tenancy_x_weeks", arg0);

/**
 * @returns {String} Tenancy: 1 week
 */
export const tenancy_one_week = (): string => stringUtil.getStringWithArgs("tenancy_one_week");

/**
 * @returns {String} Tenancy: min %d weeks
 */
export const tenancy_min_x_weeks = (arg0: number): string => stringUtil.getStringWithArgs("tenancy_min_x_weeks", arg0);

/**
 * @returns {String} Tenancy: min 1 week
 */
export const tenancy_min_one_week = (): string => stringUtil.getStringWithArgs("tenancy_min_one_week");

/**
 * @returns {String} Tenancy: max %d weeks
 */
export const tenancy_max_x_weeks = (arg0: number): string => stringUtil.getStringWithArgs("tenancy_max_x_weeks", arg0);

/**
 * @returns {String} Tenancy: max 1 week
 */
export const tenancy_max_one_week = (): string => stringUtil.getStringWithArgs("tenancy_max_one_week");

/**
 * @returns {String} Move in any time
 */
export const move_in_anytime = (): string => stringUtil.getStringWithArgs("move_in_anytime");

/**
 * @returns {String} Sort by...
 */
export const sort_by = (): string => stringUtil.getStringWithArgs("sort_by");

/**
 * @returns {String} Job Unavailable
 */
export const job_unavailable = (): string => stringUtil.getStringWithArgs("job_unavailable");

/**
 * @returns {String} This job is no longer available.
 */
export const job_unavailable_exp = (): string => stringUtil.getStringWithArgs("job_unavailable_exp");

/**
 * @returns {String} Munch
 */
export const recipes_section_title = (): string => stringUtil.getStringWithArgs("recipes_section_title");

/**
 * @returns {String} Cheap, tasty recipes from top chefs, in support of FareShare, the Trussell Trust and UK foodbank charities.
 */
export const section_desc_recipes = (): string => stringUtil.getStringWithArgs("section_desc_recipes");

/**
 * @returns {String} Information
 */
export const information = (): string => stringUtil.getStringWithArgs("information");

/**
 * @returns {String} Food Banks
 */
export const foodbanks = (): string => stringUtil.getStringWithArgs("foodbanks");

/**
 * @returns {String} Location
 */
export const location = (): string => stringUtil.getStringWithArgs("location");

/**
 * @returns {String} Begin typing a postcode
 */
export const search_postcode_hint = (): string => stringUtil.getStringWithArgs("search_postcode_hint");

/**
 * @returns {String} List
 */
export const list = (): string => stringUtil.getStringWithArgs("list");

/**
 * @returns {String} Clear Search
 */
export const clear_search = (): string => stringUtil.getStringWithArgs("clear_search");

/**
 * @returns {String} Browse
 */
export const browse = (): string => stringUtil.getStringWithArgs("browse");

/**
 * @returns {String} You have not favourited any recipes. Find some now!
 */
export const no_favourite_recipes = (): string => stringUtil.getStringWithArgs("no_favourite_recipes");

/**
 * @returns {String} %s Food Bank - %s
 */
export const food_bank_name = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("food_bank_name", arg0, arg1);

/**
 * @returns {String} Donate
 */
export const donate = (): string => stringUtil.getStringWithArgs("donate");

/**
 * @returns {String} FOOD VOUCHERS
 */
export const food_vouchers = (): string => stringUtil.getStringWithArgs("food_vouchers");

/**
 * @returns {String} WHAT'S IN A PARCEL?
 */
export const whats_in_a_parcel = (): string => stringUtil.getStringWithArgs("whats_in_a_parcel");

/**
 * @returns {String} WHAT'S IN A FOOD PARCEL?
 */
export const whats_in_a_food_parcel = (): string => stringUtil.getStringWithArgs("whats_in_a_food_parcel");

/**
 * @returns {String} VISITING A FOOD BANK
 */
export const visiting_a_food_bank = (): string => stringUtil.getStringWithArgs("visiting_a_food_bank");

/**
 * @returns {String} Your local food banks work with referral agencies to issue food vouchers to those in need of emergency food in times of crisis.
 */
export const food_vouchers_exp = (): string => stringUtil.getStringWithArgs("food_vouchers_exp");

/**
 * @returns {String} Food banks provide a minimum of three days' nutritionally balanced, tinned and dried food donated by the local community.
 */
export const whats_in_a_parcel_exp = (): string => stringUtil.getStringWithArgs("whats_in_a_parcel_exp");

/**
 * @returns {String} Food banks provide emergency food on presentation of a voucher. Our trained volunteers will offer a warm welcome…
 */
export const visiting_a_food_bank_exp = (): string => stringUtil.getStringWithArgs("visiting_a_food_bank_exp");

/**
 * @returns {String} Getting a food voucher
 */
export const getting_food_vouchers = (): string => stringUtil.getStringWithArgs("getting_food_vouchers");

/**
 * @returns {String} Recipes
 */
export const recipes = (): string => stringUtil.getStringWithArgs("recipes");

/**
 * @returns {String} FIND YOUR LOCAL FOOD BANK
 */
export const find_local_foodbank = (): string => stringUtil.getStringWithArgs("find_local_foodbank");

/**
 * @returns {String} The food banks in our network welcome and support everyone who is referred to them. Volunteers will provide a minimum of three days’ emergency food, and offer people support to resolve the difficulties they face.\nDonations are also welcome and there are many ways to [[get involved as a volunteer.]]\nTogether we can end hunger and poverty in the UK.
 */
export const find_local_foodbank_info = (): string => stringUtil.getStringWithArgs("find_local_foodbank_info");

/**
 * @returns {String} This foodbank is looking for:
 */
export const foodbank_looking_for = (): string => stringUtil.getStringWithArgs("foodbank_looking_for");

/**
 * @returns {String} Whoops...!
 */
export const whoops = (): string => stringUtil.getStringWithArgs("whoops");

/**
 * @returns {String} FaceBook, Twitter, and Google logins aren't working right now. Please log in using your email address.
 */
export const donate_social_not_working = (): string => stringUtil.getStringWithArgs("donate_social_not_working");

/**
 * @returns {String} Agent Chat
 */
export const agent_chat = (): string => stringUtil.getStringWithArgs("agent_chat");

/**
 * @returns {String} You can use the chat below to talk to your agent
 */
export const use_chat_talk_agent = (): string => stringUtil.getStringWithArgs("use_chat_talk_agent");

/**
 * @returns {String} Agent
 */
export const agent = (): string => stringUtil.getStringWithArgs("agent");

/**
 * @returns {String} Change agent
 */
export const change_my_agent = (): string => stringUtil.getStringWithArgs("change_my_agent");

/**
 * @returns {String} Report this agent
 */
export const report_this_agent = (): string => stringUtil.getStringWithArgs("report_this_agent");

/**
 * @returns {String} Your agent has full access to your account so they can help you with your applications.
 */
export const agent_has_access = (): string => stringUtil.getStringWithArgs("agent_has_access");

/**
 * @returns {String} Your agent does not have access to your account. Granting access will allow your agent to see all information you have input into the app.
 */
export const agent_doesnt_have_access = (): string => stringUtil.getStringWithArgs("agent_doesnt_have_access");

/**
 * @returns {String} Revoke Access
 */
export const revoke_access = (): string => stringUtil.getStringWithArgs("revoke_access");

/**
 * @returns {String} Grant Access
 */
export const grant_access = (): string => stringUtil.getStringWithArgs("grant_access");

/**
 * @returns {String} Are you sure you want to revoke access? Your agent will no longer have access to your information in the app.
 */
export const revoke_access_confirm = (): string => stringUtil.getStringWithArgs("revoke_access_confirm");

/**
 * @returns {String} Are you sure you want to grant access? This will give your agent full access to all your information in the app.
 */
export const grant_access_confirm = (): string => stringUtil.getStringWithArgs("grant_access_confirm");

/**
 * @returns {String} Please detail why you are reporting this agent
 */
export const agent_report_exp = (): string => stringUtil.getStringWithArgs("agent_report_exp");

/**
 * @returns {String} Select Agent
 */
export const select_agent = (): string => stringUtil.getStringWithArgs("select_agent");

/**
 * @returns {String} Your university applications will be processed by yourself without the assistance of an Agent
 */
export const no_agent_processed_by_gf = (): string => stringUtil.getStringWithArgs("no_agent_processed_by_gf");

/**
 * @returns {String} No Agent
 */
export const no_agent = (): string => stringUtil.getStringWithArgs("no_agent");

/**
 * @returns {String} Agent (has access)
 */
export const agent_has_access_title = (): string => stringUtil.getStringWithArgs("agent_has_access_title");

/**
 * @returns {String} You do not currently have an agent
 */
export const dont_have_agent = (): string => stringUtil.getStringWithArgs("dont_have_agent");

/**
 * @returns {String} Your agent is %s
 */
export const your_agent_is = (arg0: string): string => stringUtil.getStringWithArgs("your_agent_is", arg0);

/**
 * @returns {String} Get the UCAS International App
 */
export const get_mo = (): string => stringUtil.getStringWithArgs("get_mo");

/**
 * @returns {String} Courses
 */
export const main_section_title = (): string => stringUtil.getStringWithArgs("main_section_title");

/**
 * @returns {String} Send to agent
 */
export const sendToAgent = (): string => stringUtil.getStringWithArgs("sendToAgent");

/**
 * @returns {String} Send to student
 */
export const sendToStudent = (): string => stringUtil.getStringWithArgs("sendToStudent");

/**
 * @returns {String} Would you like to send this course to your agent?
 */
export const sendToAgentConfirm = (): string => stringUtil.getStringWithArgs("sendToAgentConfirm");

/**
 * @returns {String} Share this content…
 */
export const share_this_content = (): string => stringUtil.getStringWithArgs("share_this_content");

/**
 * @returns {String} Share with agent
 */
export const share_with_agent = (): string => stringUtil.getStringWithArgs("share_with_agent");

/**
 * @returns {String} Mode: %s
 */
export const mode_colon = (arg0: string): string => stringUtil.getStringWithArgs("mode_colon", arg0);

/**
 * @returns {String} Start: %s
 */
export const start_colon = (arg0: string): string => stringUtil.getStringWithArgs("start_colon", arg0);

/**
 * @returns {String} Code: %s
 */
export const code_colon = (arg0: string): string => stringUtil.getStringWithArgs("code_colon", arg0);

/**
 * @returns {String} Sequence: %s
 */
export const sequence_colon = (arg0: string): string => stringUtil.getStringWithArgs("sequence_colon", arg0);

/**
 * @returns {String} You have been logged out for your security. Please enter your password again to continue
 */
export const logged_out_security = (): string => stringUtil.getStringWithArgs("logged_out_security");

/**
 * @returns {String} Security Settings
 */
export const security_settings = (): string => stringUtil.getStringWithArgs("security_settings");

/**
 * @returns {String} Manage your app security
 */
export const security_settings_exp = (): string => stringUtil.getStringWithArgs("security_settings_exp");

/**
 * @returns {String} App Lock
 */
export const app_lock = (): string => stringUtil.getStringWithArgs("app_lock");

/**
 * @returns {String} Enter your password again after 30 minutes of inactivity
 */
export const app_lock_exp = (): string => stringUtil.getStringWithArgs("app_lock_exp");

/**
 * @returns {String} Are you sure you want to delete this file?
 */
export const sure_want_delete_file = (): string => stringUtil.getStringWithArgs("sure_want_delete_file");

/**
 * @returns {String} Hide app contents
 */
export const app_contents_hide = (): string => stringUtil.getStringWithArgs("app_contents_hide");

/**
 * @returns {String} Prevent app contents from appearing in the recents switcher or in screenshots
 */
export const app_contents_hide_exp = (): string => stringUtil.getStringWithArgs("app_contents_hide_exp");

/**
 * @returns {String} Audio Device
 */
export const audio_device = (): string => stringUtil.getStringWithArgs("audio_device");

/**
 * @returns {String} Ringing
 */
export const ringing = (): string => stringUtil.getStringWithArgs("ringing");

/**
 * @returns {String} Incoming Voice Call
 */
export const incoming_voice_call = (): string => stringUtil.getStringWithArgs("incoming_voice_call");

/**
 * @returns {String} Incoming Video Call
 */
export const incoming_video_call = (): string => stringUtil.getStringWithArgs("incoming_video_call");

/**
 * @returns {String} Video Call
 */
export const video_call = (): string => stringUtil.getStringWithArgs("video_call");

/**
 * @returns {String} Voice Call
 */
export const voice_call = (): string => stringUtil.getStringWithArgs("voice_call");

/**
 * @returns {String} Apply for course
 */
export const apply_for_course = (): string => stringUtil.getStringWithArgs("apply_for_course");

/**
 * @returns {String} Select a course to apply to
 */
export const select_a_course_to_apply_to = (): string => stringUtil.getStringWithArgs("select_a_course_to_apply_to");

/**
 * @returns {String} Login
 */
export const login = (): string => stringUtil.getStringWithArgs("login");

/**
 * @returns {String} Create Account
 */
export const create_account = (): string => stringUtil.getStringWithArgs("create_account");

/**
 * @returns {String} Your Password
 */
export const your_password = (): string => stringUtil.getStringWithArgs("your_password");

/**
 * @returns {String} New password
 */
export const new_password = (): string => stringUtil.getStringWithArgs("new_password");

/**
 * @returns {String} Enter your password
 */
export const enter_password = (): string => stringUtil.getStringWithArgs("enter_password");

/**
 * @returns {String} Create a password
 */
export const create_password = (): string => stringUtil.getStringWithArgs("create_password");

/**
 * @returns {String} Enter your password to log back in.
 */
export const welcome_existing_account = (): string => stringUtil.getStringWithArgs("welcome_existing_account");

/**
 * @returns {String} It looks like you don't have an account yet. Choose a password to get started.\n\nPasswords should include at least 8 characters and be a mix of letters and numbers or symbols
 */
export const welcome_no_account = (): string => stringUtil.getStringWithArgs("welcome_no_account");

/**
 * @returns {String} Welcome back
 */
export const welcome_header_existing_account = (): string => stringUtil.getStringWithArgs("welcome_header_existing_account");

/**
 * @returns {String} Register with UCAS
 */
export const welcome_header_no_account = (): string => stringUtil.getStringWithArgs("welcome_header_no_account");

/**
 * @returns {String} Invalid Email
 */
export const invalid_email = (): string => stringUtil.getStringWithArgs("invalid_email");

/**
 * @returns {String} You cannot sign in with your email address because you previously signed in with %s.
 */
export const cant_sign_in_used_provider = (arg0: string): string => stringUtil.getStringWithArgs("cant_sign_in_used_provider", arg0);

/**
 * @returns {String} Your email address
 */
export const your_email_address = (): string => stringUtil.getStringWithArgs("your_email_address");

/**
 * @returns {String} Password not strong enough. Use at least 8 characters and a mix of letters and numbers or symbols.
 */
export const password_weak = (): string => stringUtil.getStringWithArgs("password_weak");

/**
 * @returns {String} Logo
 */
export const logo = (): string => stringUtil.getStringWithArgs("logo");

/**
 * @returns {String} Welcome to the UCAS International App
 */
export const welcome_to_mo = (): string => stringUtil.getStringWithArgs("welcome_to_mo");

/**
 * @returns {String} The one stop shop for higher education study in the UK
 */
export const welcome_subtitle = (): string => stringUtil.getStringWithArgs("welcome_subtitle");

/**
 * @returns {String} Sign in with Email
 */
export const sign_in_with_email = (): string => stringUtil.getStringWithArgs("sign_in_with_email");

/**
 * @returns {String} Sign in with Google
 */
export const sign_in_with_google = (): string => stringUtil.getStringWithArgs("sign_in_with_google");

/**
 * @returns {String} Sign in with Twitter
 */
export const sign_in_with_twitter = (): string => stringUtil.getStringWithArgs("sign_in_with_twitter");

/**
 * @returns {String} Sign in with Facebook
 */
export const sign_in_with_facebook = (): string => stringUtil.getStringWithArgs("sign_in_with_facebook");

/**
 * @returns {String} Sign in with Apple
 */
export const sign_in_with_apple = (): string => stringUtil.getStringWithArgs("sign_in_with_apple");

/**
 * @returns {String} Forgot Password
 */
export const forgot_password = (): string => stringUtil.getStringWithArgs("forgot_password");

/**
 * @returns {String} Password Reset
 */
export const password_reset = (): string => stringUtil.getStringWithArgs("password_reset");

/**
 * @returns {String} An email has been sent to %s\n\nPlease follow the instructions in the email to reset your password
 */
export const password_reset_info = (arg0: string): string => stringUtil.getStringWithArgs("password_reset_info", arg0);

/**
 * @returns {String} You're signed in with %s. You cannot change your email address.
 */
export const signedInWithProviderNoEmailChange = (arg0: string): string => stringUtil.getStringWithArgs("signedInWithProviderNoEmailChange", arg0);

/**
 * @returns {String} You're signed in with %s. You don't have a password on this app.
 */
export const signedInWithProviderNoPassword = (arg0: string): string => stringUtil.getStringWithArgs("signedInWithProviderNoPassword", arg0);

/**
 * @returns {String} Photo library access is required to allow you to choose a file.
 */
export const photo_library_usage_description = (): string => stringUtil.getStringWithArgs("photo_library_usage_description");

/**
 * @returns {String} Camera access is required for scanning documents.
 */
export const camera_usage_description = (): string => stringUtil.getStringWithArgs("camera_usage_description");

/**
 * @returns {String} Go to settings
 */
export const go_to_settings = (): string => stringUtil.getStringWithArgs("go_to_settings");

/**
 * @returns {String} No Permission
 */
export const no_permissions = (): string => stringUtil.getStringWithArgs("no_permissions");

/**
 * @returns {String} You don't have any favourites yet. Tap the heart icon next to a university to add it to the list!
 */
export const no_favourite_unis = (): string => stringUtil.getStringWithArgs("no_favourite_unis");

/**
 * @returns {String} Undo
 */
export const undo = (): string => stringUtil.getStringWithArgs("undo");

/**
 * @returns {String} Academic Year
 */
export const academic_year = (): string => stringUtil.getStringWithArgs("academic_year");

/**
 * @returns {String} Please continue to the university's website to make your application
 */
export const cant_apply_this_course = (): string => stringUtil.getStringWithArgs("cant_apply_this_course");

/**
 * @returns {String} Total: %s
 */
export const total_price = (arg0: string): string => stringUtil.getStringWithArgs("total_price", arg0);

/**
 * @returns {String} You may optionally provide more information about yourself to help us match you with scholarships. By ticking this box, you provide consent for your data to be used for this purpose.
 */
export const special_category_data_consent_info = (): string => stringUtil.getStringWithArgs("special_category_data_consent_info");

/**
 * @returns {String} Final check required
 */
export const final_check_required_title = (): string => stringUtil.getStringWithArgs("final_check_required_title");

/**
 * @returns {String} Your application form has now been filled in. Please log in to the application portal using the details below to access your application form.\n\nYou will need to review, complete and submit this form to complete your application.
 */
export const final_check_required_message = (): string => stringUtil.getStringWithArgs("final_check_required_message");

/**
 * @returns {String} Application Registration: %s
 */
export const link_to_register = (arg0: string): string => stringUtil.getStringWithArgs("link_to_register", arg0);

/**
 * @returns {String} Application Login: %s
 */
export const link_to_login = (arg0: string): string => stringUtil.getStringWithArgs("link_to_login", arg0);

/**
 * @returns {String} Email/Username
 */
export const your_email_or_username = (): string => stringUtil.getStringWithArgs("your_email_or_username");

/**
 * @returns {String} Manual registration required
 */
export const requires_manual_registration = (): string => stringUtil.getStringWithArgs("requires_manual_registration");

/**
 * @returns {String} UCAS International is preparing your form
 */
export const awaiting_preparation = (): string => stringUtil.getStringWithArgs("awaiting_preparation");

/**
 * @returns {String} Final check required
 */
export const awaiting_final_check = (): string => stringUtil.getStringWithArgs("awaiting_final_check");

/**
 * @returns {String} Submitted and with provider
 */
export const submitted_and_with_provider = (): string => stringUtil.getStringWithArgs("submitted_and_with_provider");

/**
 * @returns {String} Awaiting agent processing
 */
export const submitted_and_with_agent = (): string => stringUtil.getStringWithArgs("submitted_and_with_agent");

/**
 * @returns {String} Unknown status, please try again later
 */
export const unable_determine_status = (): string => stringUtil.getStringWithArgs("unable_determine_status");

/**
 * @returns {String} Check
 */
export const check_application_button = (): string => stringUtil.getStringWithArgs("check_application_button");

/**
 * @returns {String} Check and Submit
 */
export const check_and_submit = (): string => stringUtil.getStringWithArgs("check_and_submit");

/**
 * @returns {String} This chat is for communication with your agent, if you are using one.
 */
export const agent_help_text_top = (): string => stringUtil.getStringWithArgs("agent_help_text_top");

/**
 * @returns {String} Your agent will invite you to chat and you can find them using the '%s' button
 */
export const agent_help_text_bottom = (arg0: string): string => stringUtil.getStringWithArgs("agent_help_text_bottom", arg0);

/**
 * @returns {String} A platform fee is required for us to prepare your application(s). Select the application(s) below that you want to proceed with and click 'make payment' to go to the checkout
 */
export const cart_explanatory_text = (): string => stringUtil.getStringWithArgs("cart_explanatory_text");

/**
 * @returns {String} Register
 */
export const register_with_provider_button = (): string => stringUtil.getStringWithArgs("register_with_provider_button");

/**
 * @returns {String} I have submitted this application form using the details above
 */
export const check_application_button_confirm_text = (): string => stringUtil.getStringWithArgs("check_application_button_confirm_text");

/**
 * @returns {String} Tap here
 */
export const tap_here = (): string => stringUtil.getStringWithArgs("tap_here");

/**
 * @returns {String} Click here
 */
export const click_here = (): string => stringUtil.getStringWithArgs("click_here");

/**
 * @returns {String} Copy
 */
export const copy = (): string => stringUtil.getStringWithArgs("copy");

/**
 * @returns {String} Copied to clipboard
 */
export const copied_to_clipboard = (): string => stringUtil.getStringWithArgs("copied_to_clipboard");

/**
 * @returns {String} Please continue to UCAS.com to make your application
 */
export const must_appply_ucas = (): string => stringUtil.getStringWithArgs("must_appply_ucas");

/**
 * @returns {String} View at UCAS
 */
export const view_on_ucas = (): string => stringUtil.getStringWithArgs("view_on_ucas");

/**
 * @returns {String} Somethings gone wrong. Please check back soon or contact us for further assistance
 */
export const generic_cant_apply = (): string => stringUtil.getStringWithArgs("generic_cant_apply");

/**
 * @returns {String} This course may require ATAS certification
 */
export const course_may_require_atas = (): string => stringUtil.getStringWithArgs("course_may_require_atas");

/**
 * @returns {String} Before you can start your study on this course, you may be required to apply to The Academic Technology Approval Scheme (ATAS).
 */
export const atas_details = (): string => stringUtil.getStringWithArgs("atas_details");

/**
 * @returns {String} Learn more about ATAS certification
 */
export const learn_more_atas = (): string => stringUtil.getStringWithArgs("learn_more_atas");

/**
 * @returns {String} ATAS Information
 */
export const atas_information = (): string => stringUtil.getStringWithArgs("atas_information");

/**
 * @returns {String} Entry Requirements
 */
export const entry_requirements = (): string => stringUtil.getStringWithArgs("entry_requirements");

/**
 * @returns {String} Some course options require:
 */
export const some_course_options_require = (): string => stringUtil.getStringWithArgs("some_course_options_require");

/**
 * @returns {String} We are currently unable to display the entry requirements for this course. Please visit the providers website to learn more.
 */
export const no_entry_reqs_available = (): string => stringUtil.getStringWithArgs("no_entry_reqs_available");

/**
 * @returns {String} You need at least one of the above qualifications to meet this requirement
 */
export const need_one_entry_requirement = (): string => stringUtil.getStringWithArgs("need_one_entry_requirement");

/**
 * @returns {String} English Language Requirements
 */
export const english_language_requirements = (): string => stringUtil.getStringWithArgs("english_language_requirements");

/**
 * @returns {String} Test Type
 */
export const test_type = (): string => stringUtil.getStringWithArgs("test_type");

/**
 * @returns {String} Required score
 */
export const required_score = (): string => stringUtil.getStringWithArgs("required_score");

/**
 * @returns {String} related to this course
 */
export const related_to_this_course = (): string => stringUtil.getStringWithArgs("related_to_this_course");

/**
 * @returns {String} Match scholarships to this course?
 */
export const match_scholarships_to_course = (): string => stringUtil.getStringWithArgs("match_scholarships_to_course");

/**
 * @returns {String} Many scholarships are dependent on the course you study. The funding tab will now display scholarships you are eligible for if you study this course.\n\nUpdate what course you are matched to by selecting 'View course funding' in any course.
 */
export const match_scholarships_to_course_info = (): string => stringUtil.getStringWithArgs("match_scholarships_to_course_info");

/**
 * @returns {String} Update Profile
 */
export const update_profile = (): string => stringUtil.getStringWithArgs("update_profile");

/**
 * @returns {String} Scholarships that you are eligible for will display here. We match you to relevant scholarships based on the details in your profile and the course you intend to study.
 */
export const wishlist_empty = (): string => stringUtil.getStringWithArgs("wishlist_empty");

/**
 * @returns {String} Move scholarships to this applied tab to keep track of the opportunities you have applied for
 */
export const applied_empty = (): string => stringUtil.getStringWithArgs("applied_empty");

/**
 * @returns {String} Move scholarships to this favourites tab to keep track of opportunities you might want to apply to in the future.
 */
export const favourites_empty = (): string => stringUtil.getStringWithArgs("favourites_empty");

/**
 * @returns {String} You can move scholarships to this success tab to keep track of your successful scholarship applications
 */
export const success_empty = (): string => stringUtil.getStringWithArgs("success_empty");

/**
 * @returns {String} Scholarships you no longer want to see in your wishlist can be moved to deleted
 */
export const deleted_empty = (): string => stringUtil.getStringWithArgs("deleted_empty");

/**
 * @returns {String} You haven't yet selected a course to match scholarships to. Please select 'view course funding' in the funding tab of any course to discover personalised funding opportunities.
 */
export const wishlist_no_course = (): string => stringUtil.getStringWithArgs("wishlist_no_course");

/**
 * @returns {String} Course Matched: %s
 */
export const course_matched = (arg0: string): string => stringUtil.getStringWithArgs("course_matched", arg0);

/**
 * @returns {String} Create a free account today to
 */
export const signup_required_title = (): string => stringUtil.getStringWithArgs("signup_required_title");

/**
 * @returns {String} Create Free Account
 */
export const create_free_account = (): string => stringUtil.getStringWithArgs("create_free_account");

/**
 * @returns {String} Please create a free account
 */
export const signup_required_popup_title = (): string => stringUtil.getStringWithArgs("signup_required_popup_title");

/**
 * @returns {String} To use this feature you need a verified account.\n\nPlease sign up now to continue
 */
export const signup_required_popup_info = (): string => stringUtil.getStringWithArgs("signup_required_popup_info");

/**
 * @returns {String} Information and Guidance
 */
export const information_and_guidance = (): string => stringUtil.getStringWithArgs("information_and_guidance");

/**
 * @returns {String} This university may require a submission fee for some courses.
 */
export const university_may_charge_independently = (): string => stringUtil.getStringWithArgs("university_may_charge_independently");

/**
 * @returns {String} This platform fee pays for services associated with %s processing your application, this does not cover any university specific submission fees.
 */
export const platform_fee_does_not_cover_university_fee = (arg0: string): string => stringUtil.getStringWithArgs("platform_fee_does_not_cover_university_fee", arg0);

/**
 * @returns {String} Please be aware you may incur submission fees for some applications to courses at:
 */
export const raise_awareness_of_independent_fees = (): string => stringUtil.getStringWithArgs("raise_awareness_of_independent_fees");

/**
 * @returns {String} %d x %s processing %s
 */
export const show_number_of_applications = (arg0: number, arg1: string, arg2: string): string => stringUtil.getStringWithArgs("show_number_of_applications", arg0, arg1, arg2);

/**
 * @returns {String} Select File
 */
export const select_file = (): string => stringUtil.getStringWithArgs("select_file");

/**
 * @returns {String} %d options available
 */
export const x_options_available = (arg0: number): string => stringUtil.getStringWithArgs("x_options_available", arg0);

/**
 * @returns {String} Option %d
 */
export const option_x = (arg0: number): string => stringUtil.getStringWithArgs("option_x", arg0);

/**
 * @returns {String} Checkout Complete
 */
export const platform_payment_received = (): string => stringUtil.getStringWithArgs("platform_payment_received");

/**
 * @returns {String} Thank you for completing the checkout. We will now start processing your forms, check the processing tab to see the status of your applications.
 */
export const platform_payment_received_info = (): string => stringUtil.getStringWithArgs("platform_payment_received_info");

/**
 * @returns {String} Complete
 */
export const complete = (): string => stringUtil.getStringWithArgs("complete");

/**
 * @returns {String} Incomplete
 */
export const incomplete = (): string => stringUtil.getStringWithArgs("incomplete");

/**
 * @returns {String} Invalid Input
 */
export const invalid_input = (): string => stringUtil.getStringWithArgs("invalid_input");

/**
 * @returns {String} Please fill this field
 */
export const please_fill_field = (): string => stringUtil.getStringWithArgs("please_fill_field");

/**
 * @returns {String} %s requires you to register an account to enable UCAS International to proceed with your application. Please use the link below to register an account with them.\n\nKeep a copy of the registration details you use and save them in the form below. These details will be stored securely.\n\nWhen you have saved these details, UCAS International will be able to continue processing your application. You will be required to perform a final check before the application will be submitted.
 */
export const myriad_requires_manual_registration = (arg0: string): string => stringUtil.getStringWithArgs("myriad_requires_manual_registration", arg0);

/**
 * @returns {String} Contact support
 */
export const contact_support_title = (): string => stringUtil.getStringWithArgs("contact_support_title");

/**
 * @returns {String} If you have a query about UCAS’s products or services, please initially check our FAQ’S page. If you can’t find an answer to your question there please contact us using the form below.
 */
export const contact_support_part_1 = (): string => stringUtil.getStringWithArgs("contact_support_part_1");

/**
 * @returns {String} Is your message to report a problem or bug?
 */
export const contact_support_part_2_title = (): string => stringUtil.getStringWithArgs("contact_support_part_2_title");

/**
 * @returns {String} If you have experienced a problem or bug with UCAS Internation App please start your message with ‘BUG’, and make sure to tell us what you were doing in the app when you found the problem - this information is essential to help us investigate and resolve the issue.
 */
export const contact_support_part_2 = (): string => stringUtil.getStringWithArgs("contact_support_part_2");

/**
 * @returns {String} Is your message a feature request or improvement?
 */
export const contact_support_part_3_title = (): string => stringUtil.getStringWithArgs("contact_support_part_3_title");

/**
 * @returns {String} If you have suggestions to help us improve please start your message with the title ‘REQUEST’.
 */
export const contact_support_part_3 = (): string => stringUtil.getStringWithArgs("contact_support_part_3");

/**
 * @returns {String} UCAS International aims to respond to all support requests within a timely manner.
 */
export const contact_support_signoff = (): string => stringUtil.getStringWithArgs("contact_support_signoff");

/**
 * @returns {String} UCAS. Registered in England 12083693
 */
export const registered_trademark = (): string => stringUtil.getStringWithArgs("registered_trademark");

/**
 * @returns {String} Enter your English language scores in your application profile to use this filter
 */
export const english_language_requirements_no_scores_yet_set = (): string => stringUtil.getStringWithArgs("english_language_requirements_no_scores_yet_set");

/**
 * @returns {String} Filter by any English language scores entered in your application profile
 */
export const english_language_requirements_scores_set_filter_prompt = (): string => stringUtil.getStringWithArgs("english_language_requirements_scores_set_filter_prompt");

/**
 * @returns {String} Only learners can perform this action
 */
export const only_learners_can_perform = (): string => stringUtil.getStringWithArgs("only_learners_can_perform");

/**
 * @returns {String} As an agent you cannot perform this action, please advise the learner to do so
 */
export const only_learners_can_perform_exp = (): string => stringUtil.getStringWithArgs("only_learners_can_perform_exp");

/**
 * @returns {String} Reset Password
 */
export const reset_password = (): string => stringUtil.getStringWithArgs("reset_password");

/**
 * @returns {String} English Language Score
 */
export const english_language_score = (): string => stringUtil.getStringWithArgs("english_language_score");

/**
 * @returns {String} Subject Areas
 */
export const subject_areas = (): string => stringUtil.getStringWithArgs("subject_areas");

/**
 * @returns {String} Welcome to the Application Tab!
 */
export const application_explanatory_text_title = (): string => stringUtil.getStringWithArgs("application_explanatory_text_title");

/**
 * @returns {String} You can start by filling out '%s' section, even if you haven't decided what course you want to apply to yet\n\nThe data you enter in '%s' will be used to pre-populate all university applications made through %s, %s
 */
export const application_explanatory_text_details = (arg0: string, arg1: string, arg2: string, arg3: string): string => stringUtil.getStringWithArgs("application_explanatory_text_details", arg0, arg1, arg2, arg3);

/**
 * @returns {String} so you can make multiple applications without having to repeatedly fill out similar forms.
 */
export const application_explanatory_text_details_bold_substring = (): string => stringUtil.getStringWithArgs("application_explanatory_text_details_bold_substring");

/**
 * @returns {String} Scholarships are matched to the details in your profile. Complete all profile questions (including optional questions) for more relevant results
 */
export const scholarships_match_minimal_profile_data = (): string => stringUtil.getStringWithArgs("scholarships_match_minimal_profile_data");

/**
 * @returns {String} Scholarships are matched to the details in your profile and any courses you have selected.
 */
export const scholarships_match = (): string => stringUtil.getStringWithArgs("scholarships_match");

/**
 * @returns {String} are specific to students studying '%s' courses
 */
export const scholarships_specific_to_course = (arg0: string): string => stringUtil.getStringWithArgs("scholarships_specific_to_course", arg0);

/**
 * @returns {String} Add this course to your profile to see more scholarships in your funding wishlist
 */
export const add_course_to_profile = (): string => stringUtil.getStringWithArgs("add_course_to_profile");

/**
 * @returns {String} Add to profile and view wishlist
 */
export const add_to_profile_and_view_wishlist = (): string => stringUtil.getStringWithArgs("add_to_profile_and_view_wishlist");

/**
 * @returns {String} Go to UCAS.com
 */
export const go_to_ucas = (): string => stringUtil.getStringWithArgs("go_to_ucas");

/**
 * @returns {String} Your uk university experience starts here
 */
export const university_experience = (): string => stringUtil.getStringWithArgs("university_experience");

/**
 * @returns {String} Register with %s
 */
export const register_prompt = (arg0: string): string => stringUtil.getStringWithArgs("register_prompt", arg0);

/**
 * @returns {String} It looks like you don't have an account yet. Create a password to get started with your %s account.\n\nPasswords should include at least 8 characters and be a mix of letters and numbers or symbols
 */
export const ucas_no_account = (arg0: string): string => stringUtil.getStringWithArgs("ucas_no_account", arg0);

/**
 * @returns {String} Welcome back
 */
export const welcome_existing_account_no_exclamation = (): string => stringUtil.getStringWithArgs("welcome_existing_account_no_exclamation");

/**
 * @returns {String} Enter your password to log back in.
 */
export const enter_password_prompt = (): string => stringUtil.getStringWithArgs("enter_password_prompt");

/**
 * @returns {String} Discover all the possibilities of uk study
 */
export const discover_possibilities = (): string => stringUtil.getStringWithArgs("discover_possibilities");

/**
 * @returns {String} Our tools empower international students to make better decisions
 */
export const tools_empowerment = (): string => stringUtil.getStringWithArgs("tools_empowerment");

/**
 * @returns {String} From Visas to loans, from applications to accommodation
 */
export const from_visas_to_loans = (): string => stringUtil.getStringWithArgs("from_visas_to_loans");

/**
 * @returns {String} Sign up now
 */
export const sign_up_prompt = (): string => stringUtil.getStringWithArgs("sign_up_prompt");

/**
 * @returns {String} Score of %s
 */
export const score_of = (arg0: string): string => stringUtil.getStringWithArgs("score_of", arg0);

/**
 * @returns {String} Field Error
 */
export const field_error = (): string => stringUtil.getStringWithArgs("field_error");

/**
 * @returns {String} (Optional)
 */
export const optional_question = (): string => stringUtil.getStringWithArgs("optional_question");

/**
 * @returns {String} Apply on Website
 */
export const apply_on_website = (): string => stringUtil.getStringWithArgs("apply_on_website");

/**
 * @returns {String} MANAGE
 */
export const manage_scholarship = (): string => stringUtil.getStringWithArgs("manage_scholarship");

/**
 * @returns {String} English
 */
export const myriad_english = (): string => stringUtil.getStringWithArgs("myriad_english");

/**
 * @returns {String} Find resources and book your English Language Test
 */
export const section_desc_english = (): string => stringUtil.getStringWithArgs("section_desc_english");

/**
 * @returns {String} Ready to book your English Language test?
 */
export const book_english_test_prompt = (): string => stringUtil.getStringWithArgs("book_english_test_prompt");

/**
 * @returns {String} Home
 */
export const home = (): string => stringUtil.getStringWithArgs("home");

/**
 * @returns {String} Favourite Jobs
 */
export const favourite_jobs = (): string => stringUtil.getStringWithArgs("favourite_jobs");

/**
 * @returns {String} Favourite Rooms
 */
export const favourite_rooms = (): string => stringUtil.getStringWithArgs("favourite_rooms");

/**
 * @returns {String} Course Applications
 */
export const course_applications = (): string => stringUtil.getStringWithArgs("course_applications");

/**
 * @returns {String} Course Favourites
 */
export const course_favourites = (): string => stringUtil.getStringWithArgs("course_favourites");

/**
 * @returns {String} Course Details
 */
export const course_details = (): string => stringUtil.getStringWithArgs("course_details");

/**
 * @returns {String} University Details
 */
export const university_details = (): string => stringUtil.getStringWithArgs("university_details");

/**
 * @returns {String} Funding Details
 */
export const funding_details = (): string => stringUtil.getStringWithArgs("funding_details");

/**
 * @returns {String} username
 */
export const username = (): string => stringUtil.getStringWithArgs("username");

/**
 * @returns {String} password
 */
export const partial_manual_registration_password = (): string => stringUtil.getStringWithArgs("partial_manual_registration_password");

/**
 * @returns {String} We have registered an account for you with %s.
 */
export const partial_manual_registration_info_part_1 = (arg0: string): string => stringUtil.getStringWithArgs("partial_manual_registration_info_part_1", arg0);

/**
 * @returns {String} %s will have sent you a %s via email.
 */
export const partial_manual_registration_info_part_2 = (arg0: string, arg1: string): string => stringUtil.getStringWithArgs("partial_manual_registration_info_part_2", arg0, arg1);

/**
 * @returns {String} Please check your email and enter the %s below so we can continue with your application.
 */
export const partial_manual_registration_info_part_3 = (arg0: string): string => stringUtil.getStringWithArgs("partial_manual_registration_info_part_3", arg0);

/**
 * @returns {String} We need your help with registration
 */
export const partial_manual_registration_dialog_title = (): string => stringUtil.getStringWithArgs("partial_manual_registration_dialog_title");

/**
 * @returns {String} More information is required to complete registration
 */
export const partial_manual_registration_status = (): string => stringUtil.getStringWithArgs("partial_manual_registration_status");

/**
 * @returns {String} We are currently unable to display the tuition fees for this course. Please visit the providers website to learn more.
 */
export const no_tuition_fee_data_available = (): string => stringUtil.getStringWithArgs("no_tuition_fee_data_available");

/**
 * @returns {String} Tuition Fees
 */
export const tuition_fees = (): string => stringUtil.getStringWithArgs("tuition_fees");

/**
 * @returns {String} Fee Type
 */
export const tution_fee_type = (): string => stringUtil.getStringWithArgs("tution_fee_type");

/**
 * @returns {String} You can move a scholarship into different states to keep your wishlist organised.
 */
export const manage_scholarship_explanation = (): string => stringUtil.getStringWithArgs("manage_scholarship_explanation");

/**
 * @returns {String} %d Favourites
 */
export const num_favourites = (arg0: number): string => stringUtil.getStringWithArgs("num_favourites", arg0);

/**
 * @returns {String} Back to search
 */
export const back_to_search = (): string => stringUtil.getStringWithArgs("back_to_search");

/**
 * @returns {String} Eligible for %d opportunities
 */
export const eligible_for_opportunities = (arg0: number): string => stringUtil.getStringWithArgs("eligible_for_opportunities", arg0);

/**
 * @returns {String} We can't match you to any scholarships at the moment.\nMake sure your profile is update to date and check back soon
 */
export const cant_match_schols_check_later = (): string => stringUtil.getStringWithArgs("cant_match_schols_check_later");

/**
 * @returns {String} File: %s
 */
export const file_colon = (arg0: string): string => stringUtil.getStringWithArgs("file_colon", arg0);

/**
 * @returns {String} Unspecified
 */
export const unspecified = (): string => stringUtil.getStringWithArgs("unspecified");

/**
 * @returns {String} Go to search
 */
export const go_to_search = (): string => stringUtil.getStringWithArgs("go_to_search");

/**
 * @returns {String} This course option is no longer available for application
 */
export const course_no_longer_available_for_application = (): string => stringUtil.getStringWithArgs("course_no_longer_available_for_application");

/**
 * @returns {String} Course option no longer available
 */
export const course_no_longer_available_for_application_title = (): string => stringUtil.getStringWithArgs("course_no_longer_available_for_application_title");

/**
 * @returns {String} This course option is no longer available, so you cannot submit this application. Please find an alternative course to apply to.\n\nYour application form data is saved across all your applications, so you will be able to continue where you left off when you select a new course option
 */
export const course_no_longer_available_for_application_exp = (): string => stringUtil.getStringWithArgs("course_no_longer_available_for_application_exp");

/**
 * @returns {String} Find a new course
 */
export const find_new_course = (): string => stringUtil.getStringWithArgs("find_new_course");

/**
 * @returns {String} Learn More
 */
export const learn_more = (): string => stringUtil.getStringWithArgs("learn_more");

/**
 * @returns {String} Delete Account
 */
export const delete_account = (): string => stringUtil.getStringWithArgs("delete_account");

/**
 * @returns {String} Are you sure you want to delete your UCAS International account?\n\nDeleting your account will result in losing all your data including your profile, personalised scholarships, course application, access to accommodation search and more.\n\nIf you wish to continue, an email will be sent to %s to confirm
 */
export const delete_account_info = (arg0: string): string => stringUtil.getStringWithArgs("delete_account_info", arg0);

/**
 * @returns {String} Account Deletion Requested
 */
export const account_deletion_requested = (): string => stringUtil.getStringWithArgs("account_deletion_requested");

/**
 * @returns {String} An email has been sent to %s\n\nPlease follow the instructions in the email to delete your account
 */
export const account_deletion_requested_exp = (arg0: string): string => stringUtil.getStringWithArgs("account_deletion_requested_exp", arg0);

/**
 * @returns {String} Asked by %s
 */
export const question_asked_by = (arg0: string): string => stringUtil.getStringWithArgs("question_asked_by", arg0);

/**
 * @returns {String} %s requires additional information
 */
export const provider_requires_info = (arg0: string): string => stringUtil.getStringWithArgs("provider_requires_info", arg0);

/**
 * @returns {String} Back to %s
 */
export const back_to_section = (arg0: string): string => stringUtil.getStringWithArgs("back_to_section", arg0);

/**
 * @returns {String} Save and continue
 */
export const save_and_continue = (): string => stringUtil.getStringWithArgs("save_and_continue");

/**
 * @returns {String} Save and complete
 */
export const save_and_complete = (): string => stringUtil.getStringWithArgs("save_and_complete");

/**
 * @returns {String} Save and exit
 */
export const save_and_exit = (): string => stringUtil.getStringWithArgs("save_and_exit");

/**
 * @returns {String} Course matched
 */
export const course_matched_plain = (): string => stringUtil.getStringWithArgs("course_matched_plain");

/**
 * @returns {String} Congratulations on submitting your application via the UCAS International App! The university will contact you directly with next steps.\n\nIf you need to make any changes or have any questions about the status of your applications, please contact the university directly.
 */
export const sent_explanatory_text = (): string => stringUtil.getStringWithArgs("sent_explanatory_text");

/**
 * @returns {String} Congratulations on submitting your accommodation booking. The provider will contact you directly to proceed.
 */
export const acc_sent_explanatory_text = (): string => stringUtil.getStringWithArgs("acc_sent_explanatory_text");

/**
 * @returns {String} Some visas may have restrictions on employment. Please check the terms of your visa before applying. %s
 */
export const job_visa_restrictions = (arg0: string): string => stringUtil.getStringWithArgs("job_visa_restrictions", arg0);

/**
 * @returns {String} Pathway Provider
 */
export const pathway_provider = (): string => stringUtil.getStringWithArgs("pathway_provider");

/**
 * @returns {String} Open Now
 */
export const open_now = (): string => stringUtil.getStringWithArgs("open_now");

/**
 * @returns {String} Closed
 */
export const closed = (): string => stringUtil.getStringWithArgs("closed");

/**
 * @returns {String} Opens %s
 */
export const opens = (arg0: string): string => stringUtil.getStringWithArgs("opens", arg0);

/**
 * @returns {String} Deadline: %s
 */
export const deadline = (arg0: string): string => stringUtil.getStringWithArgs("deadline", arg0);

/**
 * @returns {String} No deadline
 */
export const no_deadline = (): string => stringUtil.getStringWithArgs("no_deadline");

/**
 * @returns {String} Open all year
 */
export const open_all_year = (): string => stringUtil.getStringWithArgs("open_all_year");

/**
 * @returns {String} No date given
 */
export const no_date_given = (): string => stringUtil.getStringWithArgs("no_date_given");

/**
 * @returns {String} January
 */
export const january = (): string => stringUtil.getStringWithArgs("january");

/**
 * @returns {String} February
 */
export const february = (): string => stringUtil.getStringWithArgs("february");

/**
 * @returns {String} March
 */
export const march = (): string => stringUtil.getStringWithArgs("march");

/**
 * @returns {String} April
 */
export const april = (): string => stringUtil.getStringWithArgs("april");

/**
 * @returns {String} May
 */
export const may = (): string => stringUtil.getStringWithArgs("may");

/**
 * @returns {String} June
 */
export const june = (): string => stringUtil.getStringWithArgs("june");

/**
 * @returns {String} July
 */
export const july = (): string => stringUtil.getStringWithArgs("july");

/**
 * @returns {String} August
 */
export const august = (): string => stringUtil.getStringWithArgs("august");

/**
 * @returns {String} September
 */
export const september = (): string => stringUtil.getStringWithArgs("september");

/**
 * @returns {String} October
 */
export const october = (): string => stringUtil.getStringWithArgs("october");

/**
 * @returns {String} November
 */
export const november = (): string => stringUtil.getStringWithArgs("november");

/**
 * @returns {String} December
 */
export const december = (): string => stringUtil.getStringWithArgs("december");

/**
 * @returns {String} Find out more...
 */
export const find_out_more = (): string => stringUtil.getStringWithArgs("find_out_more");

/**
 * @returns {String} What do you want to study?
 */
export const what_to_study = (): string => stringUtil.getStringWithArgs("what_to_study");

/**
 * @returns {String} Popular subjects
 */
export const popular_subjects = (): string => stringUtil.getStringWithArgs("popular_subjects");

/**
 * @returns {String} Course Titles
 */
export const course_titles = (): string => stringUtil.getStringWithArgs("course_titles");

/**
 * @returns {String} Looking for UK undergraduate courses?
 */
export const looking_for_uk_undergraduate_courses = (): string => stringUtil.getStringWithArgs("looking_for_uk_undergraduate_courses");

/**
 * @returns {String} Currently %s only displays postgraduate courses.
 */
export const currently_myriad_only_displays_postgraduate_courses = (arg0: string): string => stringUtil.getStringWithArgs("currently_myriad_only_displays_postgraduate_courses", arg0);

/**
 * @returns {String} %s is the best place to explore UK undergraduate courses.
 */
export const the_best_place_to_explore_uk_undergraduate_courses = (arg0: string): string => stringUtil.getStringWithArgs("the_best_place_to_explore_uk_undergraduate_courses", arg0);

/**
 * @returns {String} I have verified email. Reload app
 */
export const reload_signup = (): string => stringUtil.getStringWithArgs("reload_signup");

/**
 * @returns {String} back to sign up
 */
export const back_to_signup = (): string => stringUtil.getStringWithArgs("back_to_signup");

/**
 * @returns {String} You must verify your email to continue using the UCAS International App.
 */
export const email_not_verified_title = (): string => stringUtil.getStringWithArgs("email_not_verified_title");

/**
 * @returns {String} resend verification email
 */
export const resend_signup_verification = (): string => stringUtil.getStringWithArgs("resend_signup_verification");

/**
 * @returns {String} Field cannot be empty
 */
export const field_empty = (): string => stringUtil.getStringWithArgs("field_empty");

/**
 * @returns {String} How do I apply?
 */
export const how_to_apply = (): string => stringUtil.getStringWithArgs("how_to_apply");

/**
 * @returns {String} First, find the course that you want to apply to using our course search within Courses.
 */
export const apply_info_bullet_one = (): string => stringUtil.getStringWithArgs("apply_info_bullet_one");

/**
 * @returns {String} Go to the Apply tab at the top of the Course Details area.
 */
export const apply_info_bullet_two = (): string => stringUtil.getStringWithArgs("apply_info_bullet_two");

/**
 * @returns {String} If the course is from one of our partner universities then you will need to select which course option you want to apply to, and then complete the application form.
 */
export const apply_info_bullet_three = (): string => stringUtil.getStringWithArgs("apply_info_bullet_three");

/**
 * @returns {String} If the course is not from one of our partner universities then you can apply to the university directly, by clicking the Go To Website button in the Apply tab.
 */
export const apply_info_bullet_four = (): string => stringUtil.getStringWithArgs("apply_info_bullet_four");

/**
 * @returns {String} Accommodation gets booked up quickly!
 */
export const accommodation_welcome_title = (): string => stringUtil.getStringWithArgs("accommodation_welcome_title");

/**
 * @returns {String} UK student accommodation is limited. We recommend booking early to avoid disappointment
 */
export const accommodation_welcome_text = (): string => stringUtil.getStringWithArgs("accommodation_welcome_text");

/**
 * @returns {String} Your UK university experience starts here
 */
export const your_uk_university_experience_starts_here = (): string => stringUtil.getStringWithArgs("your_uk_university_experience_starts_here");

/**
 * @returns {String} Make your UK degree a reality
 */
export const make_your_uk_degree_a_reality = (): string => stringUtil.getStringWithArgs("make_your_uk_degree_a_reality");

/**
 * @returns {String} Log in to continue
 */
export const sign_in_to_continue = (): string => stringUtil.getStringWithArgs("sign_in_to_continue");

/**
 * @returns {String} Sign up to continue
 */
export const sign_up_to_continue = (): string => stringUtil.getStringWithArgs("sign_up_to_continue");

/**
 * @returns {String} Kickstart your journey to the UK with UCAS
 */
export const web_heading = (): string => stringUtil.getStringWithArgs("web_heading");

/**
 * @returns {String} Purposefully designed for international students
 */
export const web_strapline = (): string => stringUtil.getStringWithArgs("web_strapline");

/**
 * @returns {String} Make your UK degree a reality
 */
export const home_screen_banner_header = (): string => stringUtil.getStringWithArgs("home_screen_banner_header");

/**
 * @returns {String} Please enter the message to be sent to support.
 */
export const empty_form_error = (): string => stringUtil.getStringWithArgs("empty_form_error");

/**
 * @returns {String} Sending accommodation application
 */
export const sending_accommodation_application = (): string => stringUtil.getStringWithArgs("sending_accommodation_application");

/**
 * @returns {String} Sending your details in %d...
 */
export const sending_your_details_in_time = (arg0: number): string => stringUtil.getStringWithArgs("sending_your_details_in_time", arg0);

/**
 * @returns {String} Transparency matters
 */
export const transparency_matters = (): string => stringUtil.getStringWithArgs("transparency_matters");

/**
 * @returns {String} %s receives payment for accommodation booked through this app. This is used to enhance our apps, and further our charitable objectives to advance and support access to higher education
 */
export const ucas_receives_payment = (arg0: string): string => stringUtil.getStringWithArgs("ucas_receives_payment", arg0);

/**
 * @returns {String} Go back to app
 */
export const go_back_to_app = (): string => stringUtil.getStringWithArgs("go_back_to_app");

/**
 * @returns {String} We are retiring this app soon…
 */
export const retiring_app_soon = (): string => stringUtil.getStringWithArgs("retiring_app_soon");

/**
 * @returns {String} This app has now been retired…
 */
export const app_retired = (): string => stringUtil.getStringWithArgs("app_retired");

/**
 * @returns {String} Our International content can be found with The Hub at UCAS.com
 */
export const our_content_at_ucas = (): string => stringUtil.getStringWithArgs("our_content_at_ucas");

/**
 * @returns {String} Instead of signing up here, continue your journey through the UCAS hub; the best resource for access to information regarding higher education opportunities in the UK
 */
export const sign_up_at_hub = (): string => stringUtil.getStringWithArgs("sign_up_at_hub");

/**
 * @returns {String} Continue to UCAS
 */
export const continue_to_ucas = (): string => stringUtil.getStringWithArgs("continue_to_ucas");
/**
 * @returns {string[]} [Male, Female, Other]
 */
export const array_gender = (): string[] => stringUtil.getArray("gender");
/**
 * @returns {string[]} [Home (UK / EU), Overseas (Non-EU)]
 */
export const array_fee_statuses = (): string[] => stringUtil.getArray("fee_statuses");
/**
 * @returns {string[]} [UnderGrad / Bachelor's \n(BA, BSc, LLB, BEng, usw.), PostGrad / Master's \n(MA, MSc, MBA, etc.), Doctorate \n(PhD, MD, DPhil, etc.)]
 */
export const array_study_level = (): string[] => stringUtil.getArray("study_level");
/**
 * @returns {string[]} [Undergrad, PostGrad / Master's, Doctorate]
 */
export const array_study_level_trunc = (): string[] => stringUtil.getArray("study_level_trunc");
/**
 * @returns {string[]} [Undergraduate Courses, Postgraduate Courses, PhD Courses]
 */
export const array_study_level_course_titles = (): string[] => stringUtil.getArray("study_level_course_titles");
/**
 * @returns {string[]} [WISHLIST, FAVOURITES, SUCCESS, DELETED]
 */
export const array_tabs = (): string[] => stringUtil.getArray("tabs");
/**
 * @returns {string[]} [UnderGraduate, PostGraduate, PhD]
 */
export const array_study_level_short = (): string[] => stringUtil.getArray("study_level_short");
/**
 * @returns {string[]} [View scholarships matched to your personal profile, Make multiple applications in one place, Favourite courses, universities, accommodation and jobs, Access your account on other devices]
 */
export const array_why_signup_reasons = (): string[] => stringUtil.getArray("why_signup_reasons");
/**
 * @returns {string[]} [Part Time, Full Time, Graduate]
 */
export const array_job_levels = (): string[] => stringUtil.getArray("job_levels");
/**
 * @returns {string[]} [Part-time jobs opportunities, including temporary contracts and seasonal work., Full-time employment opportunities, including apprenticeships, contracts and permanent placements., Graduate-level entry jobs for final-year students including graduate schemes and internships.]
 */
export const array_job_level_desc = (): string[] => stringUtil.getArray("job_level_desc");