import {Paper, usePrimaryColour} from "grantfairy-web-common";
import {connect} from "react-redux";
import { useParams } from "react-router-dom";
import {useAlert} from "../../../Alerts";
import {linkForSubsection} from "../../../Applications";
import {useApplicationNoLongerAvailableAlert} from "../../../components/ApplicationNoLongerAvailableAlert";
import {PrimaryButton, SecondaryButton} from "../../../components/Buttons";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import Dimens from "../../../theme/Dimens";
import {useTertiaryColour} from "../../../theme/GFThemeProvider";
import {useIsLightTheme} from "../../../theme/Theme";
import DataDependant from "../../../views/DataDependant";
import {SmallTitle, Subtitle} from "../../../views/Text";
import DocumentTypeList from "./DocumentsList";
import FormMenu from "./FormMenu";

const Header = ({children}) => {
    return <div style={{borderBottom: "1px solid #ccc", padding: 16}}><SmallTitle style={{margin: 0}}>{children}</SmallTitle></div>;
};

const CourseInfo = ({course, option}) => {
    const isLightTheme = useIsLightTheme();
    const tertiary = useTertiaryColour();
    const primary = usePrimaryColour();

    const titleColour = isLightTheme ? tertiary : primary;

    return (
        <div style={{borderBottom: "1px solid #ccc", padding: 16, color: titleColour}}>
            <SmallTitle style={{margin: 0}}>{course.title}</SmallTitle>
            <Subtitle style={{margin: 0, marginTop: 4, whiteSpace: "pre-wrap"}}>{option.info}</Subtitle>
            <Subtitle style={{margin: 0, marginTop: 4}}>{course.providerName}</Subtitle>
        </div>
    );
};

const ApplyScreen = ({
                         application,
                         answers,
                         isApplicationComplete = false,
                         formSections,
                         makeLinkForSubsection = () => {},
                         goBack = () => {},
                         addToCart = () => {},
                         checkDocChecked = () => {},
                         onDocChecked = () => {},
                         showErrors = false,
                         setShowApplicationFormErrors = (_) => {}
                     }) => {

    const [alert, showAlert] = useAlert();
    const [notAcceptingAlert, showNotAcceptingAlert] = useApplicationNoLongerAvailableAlert();

    const handleAddCart = () => {
        setShowApplicationFormErrors(true);
        if (isApplicationComplete) {
            showAlert(Str.application_form(), Str.saving(), "", "", null, null, "sm", true);
            addToCart();
        } else {
            showAlert(Str.applications(), Str.need_to_complete_app(), Str.okay());
        }
    };

    const handleSaveAsDraft = () => {
        setShowApplicationFormErrors(false);
        goBack();
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center"}} className="noSelect">
            {alert}
            {notAcceptingAlert}
            <Paper style={{margin: Dimens.DoubleMargin, height: "calc(100% - 64px)", display: "flex", flexDirection: "column", width: "70vw"}}>
                <div style={{padding: Dimens.StandardMargin, overflowY: "auto"}}>
                    <CourseInfo course={application.course} option={application.option}/>
                    <div style={{flex: 1}}>
                        <Header>{Str.application_form()}</Header>
                        <FormMenu showErrors={showErrors} answers={answers} applicationID={application.id} formSections={formSections} makeLinkForSubsection={makeLinkForSubsection} providerName={application.course.providerName}/>
                        <Header>{Str.select_documents_to_attach()}</Header>
                        <DocumentTypeList showErrors={showErrors} showCheckboxes={true} checkChecked={checkDocChecked} onChecked={onDocChecked} startOpen/>
                    </div>
                </div>
                {application.accepting && (
                    <div style={{display: "flex", justifyContent: "space-around", margin: 16}}>
                        <div style={{flex: 1}}/>
                        <SecondaryButton style={{flex: 2}} onClick={handleSaveAsDraft}>{Str.saveDraft()}</SecondaryButton>
                        <div style={{flex: 1}}/>
                        <PrimaryButton style={{flex: 2}} onClick={handleAddCart}>{Str.add_cart()}</PrimaryButton>
                        <div style={{flex: 1}}/>
                    </div>
                )}
                {!application.accepting && (
                    <div style={{display: "flex", alignItems: "center", flexDirection: "column", margin: 16}}>
                        <p>{Str.course_no_longer_available_for_application()}</p>
                        <PrimaryButton onClick={showNotAcceptingAlert}>{Str.learn_more()}</PrimaryButton>
                    </div>
                )}
            </Paper>
        </div>
    );
};

const Container = ({application, formSections, answers, getApplication,  ...props}) => {
    if (application == null) {
        getApplication();
    }
    return (
        <DataDependant data={application}>
            <ApplyScreen application={application?.payload} formSections={formSections?.payload ?? []} answers={answers?.payload ?? {}}
                         {...props}/>
        </DataDependant>
    );
};

const mapStateToProps = (state, {applicationID}) => ({
    application: selectors.universityApplicationByID(state, applicationID),
    formSections: selectors.applicationFormSectionsForApplication(state, applicationID),
    answers: selectors.applicationFormAnswers(state),
    isApplicationComplete: selectors.isApplicationComplete(state, applicationID),
    makeLinkForSubsection: subsection => linkForSubsection(subsection, `/applications/apply/${applicationID}/form`),
    checkDocChecked: doc => selectors.isDocumentSelectedForApplication(state, applicationID, doc),
    showErrors: selectors.showApplicationFormErrors(state, applicationID)
});

const mapDispatchToProps = (dispatch, {applicationID}) => ({
    goBack: () => dispatch(actions.goBack("/applications/2")),
    addToCart: () => dispatch(actions.addApplicationToCart(applicationID)),
    onDocChecked: (doc, selected) => dispatch(actions.selectDocumentForApplication(applicationID, doc, selected)),
    setShowApplicationFormErrors: showErrors => dispatch(actions.setShowApplicationFormErrors(applicationID, showErrors)),
    getApplication: () => dispatch(actions.requestUniApplication(applicationID))
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {applicationID} = useParams();
    return (<Connected applicationID={applicationID} />);
};

export default WrappedAndConnected;