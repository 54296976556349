import * as stringUtil from "./StringUtil";

it("Gets string from current language", () => {
    Storage.prototype.getItem = jest.fn(() => "es");
    expect(stringUtil.getString("courses")).toEqual("Cursos");
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Gets string from default language when unselected", () => {
    Storage.prototype.getItem = jest.fn(() => null);
    expect(stringUtil.getString("courses")).toEqual("Courses");
});

it("Gets string from default language when unavailable", () => {
    Storage.prototype.getItem = jest.fn(() => "es");
    //Only english has app_name
    expect(stringUtil.getString("app_name")).toEqual("UCAS");
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Gets string from default language when invalid choice", () => {
    Storage.prototype.getItem = jest.fn(() => "not_real");
    expect(stringUtil.getString("courses")).toEqual("Courses");
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Handles language codes with -", () => {
    Storage.prototype.getItem = jest.fn(() => "zh-rTW");
    expect(stringUtil.getString("courses")).toEqual("課程");
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Inserts args into string", () => {
    expect(stringUtil.getStringWithArgs("num_scholarships", 10)).toEqual("10 scholarships");
});

it("Inserts multiple args into string", () => {
    expect(stringUtil.getStringWithArgs("price", 50, "week")).toEqual("50 / week");
});

it("Gets array from current language", () => {
    Storage.prototype.getItem = jest.fn(() => "es");
    expect(stringUtil.getArray("gender")).toEqual(["Masculino", "Femenino", "Otro"]);
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Gets string from default language", () => {
    Storage.prototype.getItem = jest.fn(() => null);
    expect(stringUtil.getArray("gender")).toEqual(["Male", "Female", "Other"]);
});