import {validatePassword} from "./EmailLogin";

it("Validate Password", () => {
    expect(validatePassword("aaaaaaa1")).toEqual(true);
    expect(validatePassword("AAAAAAA1")).toEqual(true);
    expect(validatePassword("@@@@@@@A")).toEqual(true);
    expect(validatePassword("````aaaa")).toEqual(true);
    expect(validatePassword("zzzz{{{{")).toEqual(true);

    expect(validatePassword("Aa1")).toEqual(false);
    expect(validatePassword("aaaaaaaa")).toEqual(false);
    expect(validatePassword("@@@@@@@@")).toEqual(false);
    expect(validatePassword("12345678")).toEqual(false);
    expect(validatePassword("AaAaAaAa")).toEqual(false);

});