import {mapStateToProps} from "./CourseFavouritesTab";

describe("map state to props", () => {

    it("mapStateToProps has favourites list", () => {
        const params = {viewingCourseId: "someotherid", tab: "sometab"};
        const props = mapStateToProps({courseFavourites: [1, 2, 3]}, params);
        expect(props.favourites).toEqual([1, 2, 3]);
    });

    it("mapStateToProps passes through params", () => {
        const params = {viewingCourseId: "someotherid", tab: "sometab"};
        const props = mapStateToProps({}, params);
        expect(props.viewingCourseId).toEqual(params.viewingCourseId);
    });

    it("creates makeUrlForCourse", () => {
        const params = {viewingCourseId: "someotherid", tab: "sometab"};
        const props = mapStateToProps({}, params);
        expect(props.makeUrlForCourse("course")).toEqual("/favourites/course");
    });

    it("creates findCoursesUrl", () => {
        const params = {viewingCourseId: "someotherid", tab: "sometab"};
        const props = mapStateToProps({}, params);
        expect(props.findCoursesUrl).toEqual("/courses");
    });

});


