import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import * as _ from "lodash";
import {useEffect} from "react";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import DataDependant from "../../../views/DataDependant";
import {Title} from "../../../views/Text";
import ApplicationPaymentScreen from "./ApplicationPaymentScreen";

const ProviderMayChargeFeeNotice = ({providersWhichMayCharge}) => {
    const providersThatMayCharge = providersWhichMayCharge.map((provider, index) => (
        <li key={index}>{provider}</li>
    ));

    return (
        <div>
            <p>{Str.raise_awareness_of_independent_fees()}</p>
            <ul>
                {providersThatMayCharge}
            </ul>
        </div>
    );
};

const Container = ({paymentInfo, onPaid, onSend, applications}) => {

    useEffect(() => {
        if (paymentInfo.needsPayment === false) {
            onSend();
        }
    }, [paymentInfo]);

    const providersThatMayChargeFees = applications.filter(a => a.mayChargeFees === true).map(e => e.course.providerName);
    const theseMayChargeFees = _.uniq(providersThatMayChargeFees);

    if (paymentInfo.needsPayment === false) {
        return (
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 64}}>
                <CircularProgress size={140} thickness={2}/>
            </div>
        );
    } else {
        const totalCostFormatted = paymentInfo.amountToPayFormatted;

        return (
            <div style={{padding: 32}}>
                <Title style={{margin: 0}}>{Str.fee_for_x_applications(paymentInfo.numberOfTokens)}</Title>
                <br/><p>{Str.platform_fee_does_not_cover_university_fee(Str.app_name())}</p>
                {theseMayChargeFees.length > 0 && <ProviderMayChargeFeeNotice providersWhichMayCharge={theseMayChargeFees}/>}
                <br/>
                <p>{Str.show_number_of_applications(paymentInfo.numberOfTokens, Str.app_name(), paymentInfo.formattedUnitPrice)}</p>
                <p style={{fontSize: 16, fontWeight: 600}}>{Str.total_price(totalCostFormatted)}</p><br/>
                <ApplicationPaymentScreen paymentInfo={paymentInfo} onPaid={onPaid}/>
            </div>
        );
    }
};

const LoadingContainer = ({paymentInfoResult, onPaid, onSend, tickedApplications}) => {
    return <DataDependant data={paymentInfoResult}>
        <Container paymentInfo={paymentInfoResult?.payload} onPaid={onPaid} onSend={onSend} applications={tickedApplications}/>
    </DataDependant>;
};

const Popup = ({onClose, ...props}) => (
    <Dialog open={true} maxWidth="lg" fullWidth>
        <DialogContent style={{padding: 0}}>
            <div style={{minHeight: 200}}>
                <LoadingContainer {...props}/>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={onClose}>{Str.cancel()}</Button>
        </DialogActions>
    </Dialog>
);

const mapStateToProps = (state, props) => ({
    paymentInfoResult: selectors.applicationPaymentRequiredInfo(state),
    ...props
});

const mapDispatchToProps = (dispatch, {tickedApplications, onClose}) => ({
    onPaid: () => dispatch(actions.requestApplicationPaymentRequiredInfo(tickedApplications.length)),
    onSend: () => {
        dispatch(actions.sendUniApplications(tickedApplications.map(a => a.id)));
        onClose();
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Popup);

export default Connected;
