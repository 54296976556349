import {useAlert} from "../Alerts";
import {ReactElement} from "react";
import * as Str from "../strings/Str";
import {useNavigate} from "react-router-dom";

export const useApplicationNoLongerAvailableAlert = (): [ReactElement, () => void] => {
    const [alert, showAlert] = useAlert();
    const history = useNavigate();

    const newShow = () => {
        showAlert(Str.course_no_longer_available_for_application_title(), (
            <span style={{whiteSpace: "pre-wrap"}}>
                {Str.course_no_longer_available_for_application_exp()}
            </span>
        ), Str.find_new_course(), Str.cancel(), () => history("/courses"));
    };

    return [alert, newShow];
};
