import {connect} from "react-redux";
import * as selectors from "../../redux/selectors";
import {AppState} from "../../redux/store";
import {ApiCallState, Paper} from "grantfairy-web-common";
import {EnglishLanguageArticle} from "../../model/EnglishLanguageArticle";
import ApiCallStateDependant from "../../views/ApiCallStateDependant";
import {SmallTitle} from "../../views/Text";
import {MasterDetailView} from "../../components/TabContainer";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import Dimens from "../../theme/Dimens";
import * as Str from "../../strings/Str";
import Palette from "../../theme/Palette";
import * as Constants from "../../Constants";
import * as Assets from "../../Assets";
import {EnglishLanguageConfig} from "../../model/EnglishLanguageConfig";
import {EnglishProviderWebsiteButton, EnglishProviderWebsiteButtonType} from "../../views/EnglishProviderWebsiteButton";
import {KeyboardArrowRight} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Link} from "react-router-dom";
import {useAnalytics} from "../../services/analytics/AnalyticsProvider";
import {ArticleExternalUrlSelected, LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import {currentUser} from "../../util/FirebaseUtil";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";


const IdentityErrorAlert = ({errors}) => {
    const [isOpen, setIsOpen] = useState(true);
    const missing_fields = errors[1].missing_fields;
    if(missing_fields.length === 0) {
        return (
            <></>
        );
    }
    const handleClose = () => {
        setIsOpen(false);
    };
    const myJSON = JSON.stringify(errors); 
    const profileLink = "/profile?msg=" + myJSON;

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle>
                Opps! We need more information...
            </DialogTitle>
            <DialogContent>
                <p>Pearsons need some additional details from you before you can access more English Language content. Please go back to your Myriad app and complete the missing details in your profile to continue.</p>
                <p>Following items are missing</p>
                <ul>
                    {missing_fields.map((error, index) => (
                        <li style={{marginTop: 0}} key={index}>{error.message}</li>
                    ))}
                </ul>
            </DialogContent>
            <div style={{display: "flex", justifyContent: "space-between", padding: 16}}>
                <Button onClick={handleClose}>{Str.cancel()}</Button>
                <Link to={profileLink}><Button>Update profile</Button></Link>
            </div>
        </Dialog>
    );
};

const ArticlesList = ({articles, setSelectedID, selectedID, showImages}: { articles: EnglishLanguageArticle[], setSelectedID: (id: number) => void, selectedID: number, showImages: boolean }) => {
    return (
        <div style={{overflowY: "auto", flexShrink: 2, flexFlow: "wrap", justifyContent: "space-between", display: "flex", marginTop:"16px"}}>
            {articles.map((article, index) => (
                <div className="hoverable card holding-image noSelect" key={index} style={{display: "flex", flexDirection: "column"}} onClick={() => setSelectedID(article.uid)}>
                    <div className="card-header-image lazyload-wrapper">
                        <img src={article.thumbnailUrl} alt="" />
                    </div>
                    <div className="card-contents">
                        <div className="card-body">
                            <div className="card-body-item">
                                <h1>{article.name}</h1>
                                <p>{article.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const ArticleView = ({article, config}: { article: EnglishLanguageArticle, config: EnglishLanguageConfig }) => {
    useEffect(() => {
        const element = document.getElementById("english-language-tests-article-view");
        element?.scroll(0, 0);
    }, [article]);

    const analytics = useAnalytics();
    const linkClicked = (e: any) => {
        const linkUrl = e.target.getAttribute("href");
        const linkText = e.target.innerText;
        if (linkUrl != null) {
            analytics.logEvent(new ArticleExternalUrlSelected("english-language-tests", article.uid.toString(), linkUrl, linkText));
        }
    };

    return (
        <Paper id="english-language-tests-article-view" style={{height: "100%", overflowY: "scroll", boxSizing: "border-box"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            {article.headerImageUrl != null && <img src={article.headerImageUrl} style={{width: "100%", aspectRatio: "4", objectFit: "cover"}} alt=""/>}
            <div style={{padding: Dimens.StandardMargin}}>
                <SanitizedHtml html={article.resource} onClick={linkClicked} allowImages/>
            </div>
            <div style={{margin: Dimens.StandardMargin, width: 500}}>
                <BookEnglishTestBanner url={config.referUrl}/>
            </div>
        </Paper>
    );
};

const BookEnglishTestBanner = ({url}: { url: string }) => {
    const [isActive, setIsActive] = useState(false);
    const handleHover = () => {setIsActive(current => !current);};
    return (
        <Paper style={{overflowY: "auto", flexShrink: 0}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            <EnglishProviderWebsiteButton
                link={url}
                providerID={"pearson"}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{padding: Dimens.StandardMargin, width: "100%", backgroundColor: isActive ? Palette.lightGraphite : Palette.darkGraphite, color:"white"}}
                buttonType={EnglishProviderWebsiteButtonType.EnglishReferral}>
                <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-start", verticalAlign: "center", justifyContent: "space-between", gap: Dimens.StandardMargin}}>
                    <div style={{flex:1}}>
                        <div style={{height: 16}}></div>
                        <img alt="" src={require("../../" + Assets.ENGLISH_TEST_BOOKING_LOGO)} style={{width: 100, height: 100, alignItems: "flex-start"}}/>
                    </div>
                    <div style={{flex:5}}>
                        <SmallTitle style={{fontSize: 24}}>{Str.book_english_test_prompt()}</SmallTitle>
                        <p color={Palette.emperor} style={{whiteSpace: "nowrap", textTransform: "none"}}>{Constants.ENGLISH_TEST_BOOKING_LINK_LABEL}</p>
                    </div>
                    <div style={{flex:1, alignItems: "center",display: "inline-flex"}}>
                        <SmallTitle color="secondary" style={{whiteSpace: "nowrap", fontSize: 16, textTransform: "uppercase", flex:1, display: "inline-flex", alignItems: "center", paddingTop:"70px"}}>
                            {Str.book_now()}<KeyboardArrowRight style={{ paddingBottom:"2.5px"}}/>
                        </SmallTitle>
                    </div>
                </div>
            </EnglishProviderWebsiteButton>
        </Paper>
    );
};

const Screen = ({articles, config, setSelectedID, selectedID}: { articles: EnglishLanguageArticle[], config: EnglishLanguageConfig, setSelectedID: (id: number) => void, selectedID: number }) => {
    const selectedArticle = articles.find(article => article.uid === selectedID);
    return (
        <MasterDetailView showDetailView={selectedArticle != null} listView={(
            <>
                <BookEnglishTestBanner url={config.referUrl}/>
                <ArticlesList articles={articles} setSelectedID={setSelectedID} selectedID={selectedID} showImages={selectedArticle == null}/>
            </>
        )} detailView={(
            <>{selectedArticle != null && <ArticleView config={config} article={selectedArticle}/>}</>
        )}/>
    );
};

const Container = ({articles, config, selectedID}: { articles: ApiCallState<EnglishLanguageArticle[]>, config: ApiCallState<EnglishLanguageConfig>, selectedID: string }) => {
    const intSelectedID = parseInt(selectedID);
    const history = useNavigate();

    const setSelectedID = (id: number) => {
        const user = currentUser();
        const articleSearchRanking = articles ? (articles!.result!.payload.findIndex(a => a.uid === id) + 1).toString() : "";
        const articleUrl = "/english_language/information/" + id;
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid, "english_language_tests", "", articleSearchRanking.toString(), id.toString(), articleUrl));
        history(articleUrl);
    };

    const errors = checkIdentityErrorString();
    return (
        <>
            {errors.length > 0 && <IdentityErrorAlert errors={errors} />}
            <ApiCallStateDependant apiCallState={articles} makeChildren={articles => (
                <ApiCallStateDependant apiCallState={config} makeChildren={config => (
                    <Screen articles={articles} config={config} selectedID={intSelectedID} setSelectedID={setSelectedID}/>
                )}/>
            )}/>
        </>
    );
};

export const mapStateToProps = (state: AppState, {selectedID} : {selectedID : string}) => ({
    articles: selectors.englishLanguageArticles(state),
    config: selectors.englishLanguageConfig(state),
    selectedID
});

export const checkIdentityErrorString = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const msg = urlParams.get("msg");
    if(msg !== null) {
        try {
            const res: string[] = [];
            const obj = JSON.parse(msg);
            if(obj != null){
                for(const i in obj){
                    res.push(obj[i]);
                }
            }
            return res;
        } catch (e) {
            return [];
        }
    }
    return [];
};

const Connected = connect(mapStateToProps)(Container);

const WrappedAndConnected = () => {
    const {selectedArticleID} = useParams();
    return (<Connected selectedID={selectedArticleID ?? ""} />);
};

export default WrappedAndConnected;
