import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Paper} from "grantfairy-web-common";
import {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PrimaryButton, SecondaryButton} from "../../components/Buttons";
import {FormattedDate} from "../../components/FormattedDate";
import MonthTranslator from "../../components/MonthTranslator";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import Dimens from "../../theme/Dimens";
import {useControlColourName, useSecondaryColour} from "../../theme/Theme";
import {currentUser} from "../../util/FirebaseUtil";
import * as MIANUtil from "../../util/MIANUtil";
import DataDependant from "../../views/DataDependant";
import MIANView from "../../views/MIANView";
import {Tabbar} from "../../views/Tabbar";
import {Subtitle, Title} from "../../views/Text";
import {FormItemAnswerPreview} from "./applications/ViewFormDetails";
import FundingPaymentPopup from "./FundingPaymentPopup";
import FundingPaywallPopup from "./FundingPaywallPopup";
import {getValueOfScholarship} from "../../util/GetValueOfScholarship";
import ScholarshipDeadline from "./ScholarshipDeadline";
import ScholarshipDetailsPopup from "./ScholarshipDetailsPopup";

const SCHOLARSHIPS_TABS = Object.freeze({
    wishlist: 0,
    favourites: 1,
    success: 2,
    deleted: 3
});

const CongratsAlert = ({text, onClose}) => (
    <Dialog open={true} fullWidth={true} maxWidth={"sm"} onClose={onClose}>
        <div style={{textAlign: "center", padding: 48}}>
            <Title>{text}</Title>
        </div>
        <DialogActions>
            <Button color={useControlColourName()} onClick={onClose}>{Str.close()}</Button>
        </DialogActions>
    </Dialog>
);

const MoveScholarshipAlert = ({initial, onChange, onClose}) => {
    const [selectedTab, setSelectedTab] = useState(initial);

    const optionsNames = Str.array_tabs();
    const handleDone = () => {
        onClose();
        onChange(selectedTab);
    };

    const controlColor = useControlColourName();

    return (
        <Dialog open={true} fullWidth={true} maxWidth={"sm"} onClose={onClose}>
            <DialogTitle>{Str.manage_scholarship()}</DialogTitle>
            <DialogContent>
                <p style={{marginTop: 0}}>{Str.manage_scholarship_explanation()}</p>
                <RadioGroup value={selectedTab} onChange={e => setSelectedTab(parseInt(e.target.value))}>
                    {optionsNames.map((option, index) => (
                        <FormControlLabel key={index} value={index} control={<Radio color={controlColor}/>} label={option}/>
                    ))}
                </RadioGroup>
            </DialogContent>
            <div style={{display: "flex", justifyContent: "space-between", padding: 16}}>
                <Button onClick={onClose} color={controlColor}>{Str.cancel()}</Button>
                <Button onClick={handleDone} color={controlColor}>{Str.done()}</Button>
            </div>
        </Dialog>
    );
};

const RedactedScholarshipListItem = ({onPay}) => {
    const blurNumber = useMemo(() => Math.floor(Math.random() * 3), []);

    return (
        <div style={{borderBottom: "1px solid #CCC"}} onClick={onPay}>
            <div className="hoverable"
                 style={{
                     backgroundImage: `url(${require("../../assets/blurs/" + blurNumber + ".png")})`, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                     margin: "16px 0", display: "flex", alignItems: "center", justifyContent: "center", height: 60
                 }}>
                <img style={{width: 48, height: 48}} src={require("../../assets/lock.png")} alt="Scholarship Locked"/>
            </div>
        </div>
    );
};

const ScholarshipListItem = ({tab, scholarship, isExpanded, toggleExpand, highlightNearDeadline, onView, onMove, onPay}) => {
    const [moving, setMoving] = useState(false);
    const secondary = useSecondaryColour();

    const handleMove = (e) => {
        e.stopPropagation();
        setMoving(true);
    };

    if (scholarship.redacted) return <RedactedScholarshipListItem onPay={onPay}/>;
    const scholarshipDeadlineStart = scholarship.deadline_start * 1000;
    const scholarshipDeadlineEnd = scholarship.deadline_end * 1000;
    const scholarshipIsOpen = (scholarshipDeadlineStart < Date.now()) && (scholarshipDeadlineEnd === 0 || scholarshipDeadlineEnd > Date.now());
    const scholarshipWillBeOpen = scholarshipDeadlineStart > Date.now();

    const amountText = getValueOfScholarship(scholarship);

    const style = {padding: 16, borderBottom: "1px solid #CCC", background: isExpanded ? Colours.RowHighlight : ""};
    return (
        <>
            {moving && <MoveScholarshipAlert initial={tab} onChange={onMove} onClose={() => setMoving(false)}/>}
            <div style={style} className="hoverable" onClick={toggleExpand}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{flex: 1}}>
                        <Subtitle style={{margin: 0}}>{scholarship.name}</Subtitle>
                        {scholarship.deadline_start === 0 && scholarship.deadline_end === 0 && <p style={{margin: 0, marginTop: 8}}><i>{Str.open_all_year()}</i></p>}
                        {scholarship.deadline_start !== 0 && <p style={{margin: 0, marginTop: 8}}><i><ScholarshipDeadline deadlineUnix={scholarship.deadline_end} highlightNearDeadline={highlightNearDeadline}/></i></p>}
                    </div>
                    <div>
                        <p style={{color: secondary, width: 100, fontWeight: "bold"}}>{amountText}</p>
                        {scholarshipIsOpen && <p style={{fontWeight: "bold", color: Colours.PositiveStatusIndicator}}>{Str.open_now().toUpperCase()}</p>}
                        {!scholarshipIsOpen && !scholarshipWillBeOpen && <p style={{fontWeight: "bold", color: Colours.NegativeStatusIndicator}}>{Str.closed().toUpperCase()}</p>}
                        {scholarshipWillBeOpen && <p style={{width: 100, overflowWrap: "break-word"}}>{Str.opens(MonthTranslator({month: FormattedDate({format: "MMMM", children: scholarship.deadline_start})})).toUpperCase()}</p>}
                    </div>
                </div>
                {isExpanded && (
                    <>
                        <hr style={{border: "1px solid #ccc", margin: "16px 0px"}}/>
                        <p><i>{scholarship.details_short}</i></p>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                            <PrimaryButton style={{paddingLeft: 64, paddingRight: 64}} onClick={onView}>{Str.details()}</PrimaryButton>
                            <SecondaryButton style={{paddingLeft: 64, paddingRight: 64}} onClick={handleMove}>{Str.manage_scholarship()}</SecondaryButton>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const ScholarshipList = ({tab, scholarships, mians, highlightNearDeadline, onView, onMove, onPay}) => {
    const [expandedUid, setExpandedUid] = useState();

    const localScholarships = JSON.parse(JSON.stringify(scholarships));
    const toggleExpander = uid => () => {
        if (uid === expandedUid) {
            setExpandedUid(undefined);
        } else {
            setExpandedUid(uid);
        }
        const user = currentUser();
        const scholarshipRanking = localScholarships.findIndex((s) => (s.uid === uid)) + 1;
        const scholarship = localScholarships.find((s) => (s.uid === uid));
        const fundingUrl = "/funding/" + scholarship.uid + "/" + scholarship.version;
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid, "funding", "", scholarshipRanking.toString() ?? "", uid, fundingUrl));
    };

    const viewHandler = scholarship => event => {
        onView(scholarship.uid, scholarship.version);
        event.stopPropagation();
    };

    const scholarshipMapper = e => <ScholarshipListItem tab={tab} scholarship={e} highlightNearDeadline={highlightNearDeadline} isExpanded={expandedUid === e.uid}
                                                        toggleExpand={toggleExpander(e.uid)} onView={viewHandler(e)} onMove={newTab => onMove(e, newTab)} onPay={onPay}/>;

    const sortedScholarships = localScholarships.sort((a, b) => {
        const firstIsOpen = ((a.deadline_start * 1000) <= Date.now()) && (a.deadline_end == 0 || (a.deadline_end * 1000) >= Date.now());
        const firstIsClosed = a.deadline_end != 0 && (a.deadline_end * 1000) <= Date.now();
        const secondIsOpen = ((b.deadline_start * 1000) <= Date.now()) && (b.deadline_end == 0 || (b.deadline_end * 1000) >= Date.now());
        const secondIsClosed = b.deadline_end != 0 && (b.deadline_end * 1000) <= Date.now();

        if ((firstIsOpen && secondIsOpen) || (firstIsClosed && secondIsClosed)) {
            return a.deadline_end - b.deadline_end;
        }
        if (firstIsOpen && !secondIsOpen) {
            return -1;
        }
        if (!firstIsOpen && secondIsOpen) {
            return 1;
        }
        if (secondIsClosed) {
            return -1;
        }
        if (firstIsClosed) {
            return 1;
        }
        return a.deadline_start - b.deadline_start;
    });

    return MIANUtil.zipWithMians(sortedScholarships, mians, scholarshipMapper, mian => <MIANView mian={mian}/>);
};

const ScholarshipTab = ({result, tab, highlightNearDeadline, emptyText, onView, onMove, onPay, showButton, mians}) => {
    const scholarships = result?.payload ?? [];
    return <DataDependant data={result}>
        {scholarships.length === 0 && (
            <div style={{height: "100%", width: "100%", maxWidth: "512px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "auto", gap: "16px"}}>
                <p style={{fontSize: 24, whiteSpace: "pre-line", textAlign: "center"}}>{emptyText}</p>
                {showButton && <p style={{fontSize: 24, whiteSpace: "pre-line", textAlign: "center"}}>{Str.wishlist_no_course()}</p>}
                {showButton && <SecondaryButton style={{paddingLeft: 64, paddingRight: 64}} component={Link} to="/courses">{Str.find_course()}</SecondaryButton>}
            </div>
        )}
        {scholarships.length > 0 && (
            <ScholarshipList mians={mians} tab={tab} onView={onView} onMove={onMove} scholarships={scholarships} highlightNearDeadline={highlightNearDeadline} onPay={onPay}/>
        )}
    </DataDependant>;
};

const tabStateMapper = (tab, resultSelector, highlightNearDeadline, showButton, emptyText) => (state, {onView, onMove, onPay}) => ({
    result: resultSelector(state), tab, onView, onMove, highlightNearDeadline, emptyText, showButton, onPay, mians: selectors.fundingMians(state, tab)
});

const Wishlist = connect(tabStateMapper(SCHOLARSHIPS_TABS.wishlist, selectors.fundingWishlist, true, true, Str.wishlist_empty()))(ScholarshipTab);
const FavouritesList = connect(tabStateMapper(SCHOLARSHIPS_TABS.favourites, selectors.fundingAppliedList, false, false, Str.favourites_empty()))(ScholarshipTab);
const SuccessList = connect(tabStateMapper(SCHOLARSHIPS_TABS.success, selectors.fundingSuccessList, false, false, Str.success_empty()))(ScholarshipTab);
const DeletedList = connect(tabStateMapper(SCHOLARSHIPS_TABS.deleted, selectors.fundingDeletedList, false, false, Str.deleted_empty()))(ScholarshipTab);

const ProfileInformation = ({items, answers, profileLink, selectedCourse, courseLink}) => {

    const secondary = useSecondaryColour();

    return (
        <div style={{padding: Dimens.DoubleMargin}}>
            <Title style={{margin: 0}}>{Str.profile()}</Title>
            {items.filter(item => !item.hideFromSummary).map((item, index) => (
                <div>
                    {index > 0 && <hr style={{border: "1px solid #ccc"}}/>}
                    <FormItemAnswerPreview answer={answers[item.id]} item={item} key={item.id}/>
                </div>
            ))}
            {selectedCourse.id !== null && (
                <div>
                    <hr style={{border: "1px solid #ccc"}}/>
                    <div>
                        <p style={{fontWeight: 600}}>{Str.course_matched_plain()}</p>
                        <Link style={{color: secondary}} to={courseLink}><p>{selectedCourse.title}</p></Link>
                    </div>
                </div>
            )}
            <div style={{textAlign: "center", marginTop: Dimens.DoubleMargin}}>
                <SecondaryButton component={Link} to={profileLink}>{Str.update_profile()}</SecondaryButton>
            </div>
        </div>
    );
};

const FundingTab = ({profileFormItems, profileFormAnswers, selectedCourse, profileLink, courseLink, setViewingScholarship, onMove, viewingScholarship, onOpenProfile}) => {
    const [tab, setTab] = useState(0);
    const history = useNavigate();

    const [congratsAlertText, setCongratsAlertText] = useState();

    const [isShowingPaywall, setIsShowingPaywall] = useState(false);
    const [payingOption, setPayingOption] = useState(null);

    const handleViewScholarship = (uid, version) => {
        setViewingScholarship({uid, version}, history);
    };

    useEffect(() => {
        onOpenProfile();
    }, [onOpenProfile]);

    const parsedFormAnswers = profileFormAnswers?.payload;

    const handleMoveScholarship = (scholarship, newTab) => {
        onMove(scholarship.uid, scholarship.version, newTab);

        if (newTab === SCHOLARSHIPS_TABS.favourites) setCongratsAlertText(Str.nice_job());
        if (newTab === SCHOLARSHIPS_TABS.success) setCongratsAlertText(Str.congrats());
        if (newTab === SCHOLARSHIPS_TABS.deleted) setCongratsAlertText(Str.schol_deleted());
    };

    const onPay = () => {
        setIsShowingPaywall(true);
    };

    const initiatePay = (option) => {
        setIsShowingPaywall(false);
        setPayingOption(option);
    };

    const closeScholarshipDetailsPopup = () => {
        setViewingScholarship(null, history);
    };

    return (
        <DataDependant data={profileFormAnswers}>
            <div style={{height: "calc(100% - 64px)", display: "flex", padding: 32}} className="noSelect">
                {congratsAlertText && <CongratsAlert onClose={() => setCongratsAlertText(null)} text={congratsAlertText}/>}
                {viewingScholarship && <ScholarshipDetailsPopup uid={viewingScholarship.uid} version={viewingScholarship.version} onClose={closeScholarshipDetailsPopup}/>}
                {isShowingPaywall && <FundingPaywallPopup onClose={() => setIsShowingPaywall(false)} onPay={initiatePay}/>}
                {payingOption != null && <FundingPaymentPopup onClose={() => setPayingOption(null)} option={payingOption}/>}
                <Paper style={{width: 500, height: "100%", marginTop: Dimens.HalfMargin, overflowY: "auto"}}>
                    <ProfileInformation items={profileFormItems.payload ?? []} answers={parsedFormAnswers} profileLink={profileLink} selectedCourse={selectedCourse} courseLink={courseLink}/>
                </Paper>
                <div style={{width: 32}}/>
                <div style={{flex: 1}}>
                    <Paper style={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <Tabbar tabs={Str.array_tabs()} selected={tab} setSelected={setTab}/>
                        <div style={{flex: 1, overflowY: "scroll"}}>
                            {tab === 0 && <Wishlist onView={handleViewScholarship} onMove={handleMoveScholarship} onPay={onPay}/>}
                            {tab === 1 && <FavouritesList onView={handleViewScholarship} onMove={handleMoveScholarship} onPay={onPay}/>}
                            {tab === 2 && <SuccessList onView={handleViewScholarship} onMove={handleMoveScholarship} onPay={onPay}/>}
                            {tab === 3 && <DeletedList onView={handleViewScholarship} onMove={handleMoveScholarship} onPay={onPay}/>}
                        </div>
                    </Paper>
                </div>
            </div>
        </DataDependant>
    );
};

const Container = ({profileFormAnswers, ...props}) => (
    <FundingTab profileFormItems={props.profileFormItems} selectedCourse={props.selectedCourse} profileLink={props.profileLink} courseLink={props.courseLink} setViewingScholarship={props.setViewingScholarship} onMove={props.onMove}
                viewingScholarship={props.viewingScholarship} onOpenProfile={props.onOpenProfile} profileFormAnswers={profileFormAnswers}/>
);

export const mapStateToProps = (state, {uid, version}) => {
    const selectedCourse = selectors.selectedCourseObject(state);
    const viewingScholarship = uid && version ? {uid, version} : null;

    return {
        profileFormAnswers: selectors.userProfile(state),
        profileFormItems: selectors.profileForm(state),
        selectedCourse,
        profileLink: "/profile",
        courseLink: "/courses/" + selectedCourse.id,
        viewingScholarship
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setViewingScholarship: (scholarship, history) => scholarship ? history("/funding/" + scholarship.uid + "/" + scholarship.version) : history("/funding"),
        onMove: (uid, version, tab) => dispatch(actions.moveScholarship(uid, version, tab)),
        onOpenProfile: () => dispatch(actions.requestProfile())
    };
};

export const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {uid, version} = useParams();
    return (<Connected uid={uid} version={version} />);
};

export default WrappedAndConnected;
