import {FormItemQuestion} from "../../util/form/FormItem";
import {useControlColour} from "../../theme/Theme";
import {MouseEventHandler, ReactNode} from "react";
import ColouredLink from "../../components/ColouredLink";
import {InfoOutlined} from "@mui/icons-material";
import Dimens from "../../theme/Dimens";
import Colours from "../../theme/Colours";

const FormQuestionInfoText = ({item, handleLink}: { item: FormItemQuestion, handleLink: MouseEventHandler<HTMLAnchorElement> }) => {
    const tintColour = useControlColour();

    if (item == null || item.info == null) return null;
    const text = item.info;
    let textToRender: ReactNode = text;
    if (item.action) {
        const {on, link} = item.action;
        if (text.includes(on)) {
            const [before, after] = text.split(on);
            const linkAnchor = <ColouredLink colour={tintColour} rel="noopener noreferrer" onClick={handleLink} href={link}>{on}</ColouredLink>;
            textToRender = [before, linkAnchor, after];
        }
    }
    return (
        <div style={{display: "flex", alignItems: "center", marginTop: 8}}>
            <p><InfoOutlined style={{color: Colours.secondaryText}}/></p>
            <div style={{width: Dimens.StandardMargin}}/>
            <i style={{color: Colours.secondaryText, whiteSpace: "pre-wrap"}}>{textToRender}</i>
        </div>
    );
};

export default FormQuestionInfoText;
