import {MouseEventHandler, useEffect, useState} from "react";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import {FormControl, TextField} from "@mui/material";
import DropDownOptionWithCheck from "../../components/DropDownOptionWithCheck";
import {FormAnswerSetter, InvalidAnswerLabel, UnansweredQuestionLabel} from "./FormItemView";
import {FormAnswerType, TelephoneEntry} from "../../util/form/FormItem";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {createFilterOptions} from "@mui/material/useAutocomplete";
import {Autocomplete} from "@mui/material";

interface TelephoneAnswerProps {
    item: TelephoneEntry;
    startAnswer: () => void;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    setAnswer: FormAnswerSetter;
    validate: boolean;
}

const FormTelephoneView = ({item, startAnswer, handleLink, answer, setAnswer, validate}: TelephoneAnswerProps) => {

    const [text, setText] = useState("");
    const [code, setCode] = useState("");

    const currentAnswerJson = JSON.stringify({text, code});
    const isUnanswered = text === "" || code === "";
    const isError = isUnanswered || !item.isValidAnswer(currentAnswerJson);

    const showError = item.required && isError && validate;
    const currentSelectedOption = item.options.filter(e => e.code === code)[0] ?? null;

    const sendAnswer = () => {
        if (currentAnswerJson !== answer && !isError) {
            setAnswer(currentAnswerJson);
        }
    };

    useEffect(() => {
        try {
            const parsedAnswer = JSON.parse(answer!);
            if (parsedAnswer.text && parsedAnswer.code) {
                setText(parsedAnswer.text);
                setCode(parsedAnswer.code);
            }
        } catch (e) {
        }
    }, [answer]);

    return (
        <div id={item.id}>
            <FormQuestionText item={item} handleLink={handleLink} style={{margin: 8}}/>
            {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
            <FormQuestionInfoText item={item} handleLink={handleLink}/>
            <div style={{display: "flex", flexDirection: "row"}}>
                <FormControl margin="dense" style={{width: 150}}>
                    <Autocomplete options={item.options} onFocus={startAnswer} size="small" style={{width: "100%"}} value={currentSelectedOption}
                                onChange={(e, value) => setCode(value?.code ?? "")} renderInput={(params) => <TextField {...params} error={showError}/>}
                                renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={<div><b>{option.code}</b><br/>{option.name}</div>}/>}
                                getOptionLabel={item => item.code} disableClearable={item.required} onBlur={sendAnswer}
                                filterOptions={createFilterOptions({
                                    // Custom filterer allows searching by either name or code
                                    stringify: (option) => option.code + " " + option.name
                                })}
                    />
                </FormControl>
                <FormControl margin="dense" style={{flex: 1}}>
                    <TextField error={showError} onBlur={sendAnswer} style={{width: "100%"}} variant="outlined" color="primary" size="small" onChange={e => setText(e.target.value)}
                        value={text} inputProps={{maxLength: item.maxLength}}/>
                </FormControl>
            </div>
            {!validate && isError && isUnanswered && <UnansweredQuestionLabel/>}
            {!validate && isError && !isUnanswered && <InvalidAnswerLabel/>}
        </div>
    );
};

export default FormTelephoneView;
