import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {PrimaryButton, SecondaryButton} from "../components/Buttons";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {accSectionTheme, jobsSectionTheme, mainSectionTheme, universitiesSectionTheme} from "../theme/Theme";
import Dimens from "../theme/Dimens";

const Buttons = () => (
    <div>
        <PrimaryButton>Im a primary button</PrimaryButton>
        <br/><br/>
        <PrimaryButton disabled>Im a primary button disabled</PrimaryButton>
        <br/><br/>
        <SecondaryButton>Im a secondary button</SecondaryButton>
        <br/><br/>
        <SecondaryButton disabled>Im a secondary button disabled</SecondaryButton>
        <br/><br/>
    </div>
);

export const ButtonTest = () => (
    <div style={{margin: Dimens.DoubleMargin}}>
        <h1>Buttons demo</h1>

        <h2>Main theme</h2>
        <MuiThemeProvider theme={mainSectionTheme}>
            <GFThemeProvider value={sections.Main}>
                <Buttons/>
            </GFThemeProvider>
        </MuiThemeProvider>

        <h2>Accommodation theme</h2>
        <MuiThemeProvider theme={accSectionTheme}>
            <GFThemeProvider value={sections.Accommodation}>
                <Buttons/>
            </GFThemeProvider>
        </MuiThemeProvider>

        <h2>Jobs theme</h2>
        <MuiThemeProvider theme={jobsSectionTheme}>
            <GFThemeProvider value={sections.Jobs}>
                <Buttons/>
            </GFThemeProvider>
        </MuiThemeProvider>

        <h2>Universities theme</h2>
        <MuiThemeProvider theme={universitiesSectionTheme}>
            <GFThemeProvider value={sections.Universities}>
                <Buttons/>
            </GFThemeProvider>
        </MuiThemeProvider>
    </div>

);

export default ButtonTest;