import {DateEntry, MultiSelectEntry, RadioEntry, RecordEntry, TelephoneEntry, TextEntry, TickEntry} from "./FormItem";

describe("Form answer validation", () => {
    test("Text answers can't be blank", () => {
        expect(TextEntry.prototype.numberAnswered("hi")).toEqual(1);
        expect(TextEntry.prototype.numberAnswered("")).toEqual(0);
    });

    test("Date answers must be a date", () => {
        expect(DateEntry.prototype.numberAnswered("")).toEqual(0);
        expect(DateEntry.prototype.numberAnswered("hi")).toEqual(0);
        expect(DateEntry.prototype.numberAnswered("22/12/2020")).toEqual(1);
        expect(DateEntry.prototype.numberAnswered("22/13/2020")).toEqual(0);
    });

    test("Date answers must be in range", () => {
        const question = {
            type: "date",
            min_date: "02/05/2006",
            max_date: "04/07/2006"
        };
        Object.setPrototypeOf(question, DateEntry.prototype);

        expect(question.numberAnswered("01/05/2006")).toEqual(0);
        expect(question.numberAnswered("02/05/2006")).toEqual(1);
        expect(question.numberAnswered("24/06/2006")).toEqual(1);
        expect(question.numberAnswered("01/07/2006")).toEqual(1);
        expect(question.numberAnswered("04/07/2006")).toEqual(1);
        expect(question.numberAnswered("05/07/2006")).toEqual(0);
    });

    test("Date answers must be in range when using 'now' as min", () => {
        const question = {
            type: "date",
            min_date: "now"
        };
        Object.setPrototypeOf(question, DateEntry.prototype);

        jest.useFakeTimers("modern");
        jest.setSystemTime(new Date(1643104678000)); // 25/01/2022 09:57:59 GMT

        // The minimum allowed date should be 25/01/2022 because that's 'now'. Even though technically it was 9 hours and 57 minutes ago, it's the same day
        expect(question.numberAnswered("24/01/2022")).toEqual(0);
        expect(question.numberAnswered("25/01/2022")).toEqual(1);
    });

    test("Date answers must be in range when using 'now' as max", () => {
        const question = {
            type: "date",
            max_date: "now"
        };
        Object.setPrototypeOf(question, DateEntry.prototype);

        jest.useFakeTimers("modern");
        jest.setSystemTime(new Date(1643104678000)); // 25/01/2022 09:57:59 GMT

        // The max allowed date should be 25/01/2022 because that's 'now'
        expect(question.numberAnswered("24/01/2022")).toEqual(1);
        expect(question.numberAnswered("25/01/2022")).toEqual(1);
        expect(question.numberAnswered("26/01/2022")).toEqual(0);
    });

    test("All date answers are ok when there is no min or max", () => {
        const question = {
            type: "date"
        };
        Object.setPrototypeOf(question, DateEntry.prototype);

        expect(question.numberAnswered("01/05/2006")).toEqual(1);
        expect(question.numberAnswered("02/05/2006")).toEqual(1);
        expect(question.numberAnswered("24/06/2006")).toEqual(1);
        expect(question.numberAnswered("01/07/2006")).toEqual(1);
        expect(question.numberAnswered("04/07/2006")).toEqual(1);
        expect(question.numberAnswered("05/07/2006")).toEqual(1);
    });

    test("Radio answers must be one of the options", () => {
        const question = {
            type: "radio",
            id: "personal_details-personal-gender",
            question: "Gender",
            required: true,
            options: ["Female", "Male", "Other"]
        };
        Object.setPrototypeOf(question, RadioEntry.prototype);

        expect(question.numberAnswered("Male")).toEqual(1);
        expect(question.numberAnswered("Monkey")).toEqual(0);
    });

    test("Radio answers can be 'other'", () => {
        const question = {
            type: "radio",
            id: "personal_details-personal-gender",
            question: "Gender",
            required: true,
            options: ["Female", "Male", "Other"],
            other_option: true
        };
        Object.setPrototypeOf(question, RadioEntry.prototype);

        expect(question.numberAnswered("Male")).toEqual(1);
        expect(question.numberAnswered("Monkey")).toEqual(1);
    });

    test("Tickbox must be true", () => {
        expect(TickEntry.prototype.numberAnswered("true")).toEqual(1);
        expect(TickEntry.prototype.numberAnswered("false")).toEqual(0);
        expect(TickEntry.prototype.numberAnswered("hello")).toEqual(0);
    });

    test("Telephone must parse, must not support invalid json, must ensure matches to ref data, and matches required length", () => {
        const tests = [{
            testObject: {code: "+1", text: "23456789"},
            expectedOutcome: 1
        }, {
            testObject: {code: "+1", text: "2345 56789"},
            expectedOutcome: 1
        }, {
            testObject: {code: "+1", text: "(2345) 56789"},
            expectedOutcome: 1
        }, {
            testObject: {code: "+1", text: "2345-6789"},
            expectedOutcome: 1
        }, {
            testObject: {code: "+1", text: "2.345.6789"},
            expectedOutcome: 1
        }, {
            testObject: {code: "+2", text: "23456789"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+2", text: "1234567890"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+2", text: "12(345)678 90"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+2", text: "1234"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+1", text: "Not a number"},
            expectedOutcome: 0
        }, {
            testObject: "Not an object",
            expectedOutcome: 0
        }, {
            testObject: "A",
            expectedOutcome: 0
        }, {
            testObject: {code: "+1", text: "123"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+1", text: "12345678901"},
            expectedOutcome: 0
        }, {
            testObject: {code: "+1", text: "()()()123"},
            expectedOutcome: 0
        }];

        const question = {
            type: "telephone",
            options: [{code: "+1", name: "Canada", minPhoneLength: 4, maxPhoneLength: 10}]
        };

        Object.setPrototypeOf(question, TelephoneEntry.prototype);

        tests.forEach(item => {
            expect(question.numberAnswered(JSON.stringify(item.testObject))).toEqual(item.expectedOutcome);
        });

    });

    test("Record entries correctly count records", () => {
        const question = {
            type: "records",
            id: "experienceemployment-experienceemployment-workexp",
            question: "Work Experience",
            items: [
                {
                    type: "text",
                    id: "experienceemployment-experienceemployment-workexp-employer_name",
                    question: "Employer name",
                    required: false
                },
                {
                    type: "radio",
                    id: "experienceemployment-experienceemployment-workexp-employer_based_in",
                    question: "Employer based in",
                    required: true,
                    options: ["France", "Spain"]
                },
                {
                    type: "multiline_text",
                    id: "experienceemployment-experienceemployment-workexp-employer_address",
                    question: "Employer address",
                    required: true
                },
                {
                    type: "text",
                    id: "experienceemployment-experienceemployment-workexp-job_title",
                    question: "Job title",
                    required: true
                },
                {
                    type: "text",
                    id: "experienceemployment-experienceemployment-workexp-main_responsibilities",
                    question: "Main responsibilities",
                    required: false
                },
                {
                    type: "radio",
                    id: "experienceemployment-experienceemployment-workexp-full_timepart_time",
                    question: "Full time / Part time",
                    required: false,
                    options: ["Full time", "Part time"]
                },
                {
                    type: "date",
                    id: "experienceemployment-experienceemployment-workexp-date_of_appointment",
                    question: "Date of appointment",
                    required: false,
                    max_date: "now",
                    min_date: "01/01/1900"
                },
                {
                    type: "date",
                    id: "experienceemployment-experienceemployment-workexp-end_date_if_applicable",
                    question: "End date (if applicable)",
                    required: false
                }
            ]
        };

        const answer = [
            [
                {"question": "experienceemployment-experienceemployment-workexp-employer_address", "answer": "addr1"},
                {"question": "experienceemployment-experienceemployment-workexp-employer_based_in", "answer": "Antigua and Barbuda"},//this one is invalid
                {"question": "experienceemployment-experienceemployment-workexp-job_title", "answer": "job title 1"}
            ],
            [
                {"question": "experienceemployment-experienceemployment-workexp-employer_address", "answer": "addr 2"},
                {"question": "experienceemployment-experienceemployment-workexp-employer_based_in", "answer": "France"},
                {"question": "experienceemployment-experienceemployment-workexp-job_title", "answer": "title 2"}
            ]
        ];

        Object.setPrototypeOf(question, RecordEntry.prototype);

        const answerJson = JSON.stringify(answer);
        const allAnswers = {[question.id]: answerJson};

        expect(question.numberOfQuestions(answerJson, allAnswers)).toEqual(6);
        expect(question.numberAnswered(answerJson, allAnswers)).toEqual(5);

    });

    test("Multiselect answers must be non-empty", () => {
        expect(MultiSelectEntry.prototype.numberAnswered("[]")).toEqual(0);
        expect(MultiSelectEntry.prototype.numberAnswered("notJson")).toEqual(0);
        expect(MultiSelectEntry.prototype.numberAnswered("[1, 2]")).toEqual(0);
        expect(MultiSelectEntry.prototype.numberAnswered("[\"valid\"]")).toEqual(1);
        expect(MultiSelectEntry.prototype.numberAnswered("[\"double\", \"answers\"]")).toEqual(1);
    });

});

describe("Form contains USQs", () => {
    test("Basic scenario", () => {
        const noUsq = new TextEntry("", undefined, false, undefined, false, undefined, "", "", "", "", 0);
        const withUsq = new TextEntry("", undefined, false, undefined, true, undefined, "", "", "", "", 0);
        expect(noUsq.containsVisibleUniversitySpecificQuestion(undefined, {})).toBe(false);
        expect(withUsq.containsVisibleUniversitySpecificQuestion(undefined, {})).toBe(true);
    });
    test("Record with no USQs at all", () => {
        const childItem = new TextEntry("", undefined, false, undefined, false, undefined, "", "", "", "", 0);
        const record = new RecordEntry("", undefined, true, undefined, false, undefined, "", "", "", "", [childItem]);
        expect(record.containsVisibleUniversitySpecificQuestion(undefined, {})).toBe(false);
    });
    test("Record with USQs hidden by lack of answers", () => {
        // Since the user hasn't actually added a record, the USQ isn't relevant
        const childItem = new TextEntry("", undefined, false, undefined, true, undefined, "", "", "", "", 0);
        const record = new RecordEntry("", undefined, true, undefined, false, undefined, "", "", "", "", [childItem]);
        expect(record.containsVisibleUniversitySpecificQuestion(undefined, {})).toBe(false);
    });
    test("Record with USQs hidden by show-if", () => {
        const nonUsqChild = new TextEntry("notUsq", undefined, false, undefined, false, undefined, "", "", "", "", 0);
        const usqChild = new TextEntry("", {id: "notUsq", answers: ["yes"]}, false, undefined, true, undefined, "", "", "", "", 0);
        const recordChildren = [nonUsqChild, usqChild];
        const record = new RecordEntry("", undefined, true, undefined, false, undefined, "", "", "", "", recordChildren);
        expect(record.containsVisibleUniversitySpecificQuestion(undefined, {})).toBe(false);
    });
    test("Record with USQs visible (no show-if rules)", () => {
        const childItem = new TextEntry("", undefined, false, undefined, true, undefined, "", "", "", "", 0);
        const record = new RecordEntry("", undefined, true, undefined, false, undefined, "", "", "", "", [childItem]);
        expect(record.containsVisibleUniversitySpecificQuestion("[[]]", {})).toBe(true);
    });
    test("Record with USQs visible (satisfied show-if rules)", () => {
        const nonUsqChild = new TextEntry("notUsq", undefined, false, undefined, false, undefined, "", "", "", "", 0);
        const usqChild = new TextEntry("", {id: "notUsq", answers: ["yes"]}, false, undefined, true, undefined, "", "", "", "", 0);
        const recordChildren = [nonUsqChild, usqChild];
        const record = new RecordEntry("", undefined, true, undefined, false, undefined, "", "", "", "", recordChildren);
        expect(record.containsVisibleUniversitySpecificQuestion("[[{\"question\":\"notUsq\",\"answer\":\"yes\"}]]", {})).toBe(true);
    });
});
