import {TextField} from "@mui/material";
import * as stringUtil from "../util/StringUtil";
import DropDownOptionWithCheck from "./DropDownOptionWithCheck";
import {Autocomplete} from "@mui/material";

export interface MultiSelectOption<IdType> {
    id: IdType;
    label: string;
}

export interface MultiSelectViewProps<IdType> {
    options: MultiSelectOption<IdType>[];
    startAnswer: () => void;
    selectedItemIds: IdType[];
    setSelectedItemIds: (ids: IdType[]) => void;
}

export const MultiSelectView = <IdType extends any>({options, startAnswer, selectedItemIds, setSelectedItemIds}: MultiSelectViewProps<IdType>) => {
    const selectedOptions: MultiSelectOption<IdType>[] = options.filter(o => selectedItemIds.includes(o.id));

    return (
        <Autocomplete
            multiple
            options={options}
            onFocus={startAnswer}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option) => stringUtil.ellipsizeText(option.label, 35)}
            value={selectedOptions}
            onChange={(e, value) => setSelectedItemIds(value.map(e => e.id))}
            renderOption={(attrs, option, {selected}) => (<DropDownOptionWithCheck props={attrs} selected={selected} label={option.label}/>)}
            renderInput={(params) => (
                <TextField variant="outlined" color="secondary" {...params}  />
            )}
        />
    );
};
