import ButtonBase from "@mui/material/ButtonBase";
import Icon from "@mui/material/Icon";
import {AspectFitImage, Paper, usePrimaryColour} from "grantfairy-web-common";
import {useState} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {useAlert} from "../../Alerts";
import * as Assets from "../../Assets";
import {PrimaryButton} from "../../components/Buttons";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import * as Property from "../../Property";
import {ROOM_TYPES} from "../../Property";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import BackButton from "../../views/BackButton";
import DataDependant from "../../views/DataDependant";
import FacilitiesView from "../../views/FacilitiesView";
import ImageCarousel from "../../views/ImageCarousel";
import PropertyPrice from "../../views/PropertyPrice";
import {SmallTitle, Subtitle, Title} from "../../views/Text";

const RoomTypeButton = ({title, desc, selected, onToggle, style}) => (
    <ButtonBase
        style={{display: "block", textAlign: "left", flex: 1, margin: 8, borderRadius: 8, padding: 8, background: selected ? Colours.RowHighlight : "", border: "4px solid #eee", ...style}}
        onClick={onToggle}>
        <div style={{display: "flex", alignItems: "center"}}>
            <SmallTitle style={{margin: 0, flex: 1}}>{title}</SmallTitle>
            <img alt="" src={selected ? require("../../" + Assets.CHECK_ON) : require("../../" + Assets.CHECK_OFF)} style={{width: 24, height: 24}}/>
        </div>
        <p style={{marginTop: 8}}>{desc}</p>
    </ButtonBase>
);

const RoomTypeInfo = ({viewingRoomTypes, setViewingRoomTypes}) => {

    const toggler = type => () => {
        if (viewingRoomTypes.includes(type)) {
            setViewingRoomTypes(viewingRoomTypes.filter(e => e !== type));
        } else {
            setViewingRoomTypes([...viewingRoomTypes, type]);
        }
    };

    const stylesForRow = (row, col) => ({
        msGridRow: row,
        msGridColumn: col,
        msGridColumnSpan: 1
    });

    return (
        <Paper style={{background: "white", padding: 8}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            <div className="gridHolder">

                <RoomTypeButton selected={viewingRoomTypes.includes(ROOM_TYPES.shared)} onToggle={toggler(ROOM_TYPES.shared)} title={Str.shared_room()}
                                desc={Str.shared_room_exp()} style={stylesForRow(1, 1)}/>

                <RoomTypeButton selected={viewingRoomTypes.includes(ROOM_TYPES.private)} onToggle={toggler(ROOM_TYPES.private)} title={Str.private_room()}
                                desc={Str.private_room_exp()} style={stylesForRow(1, 2)}/>

                <RoomTypeButton selected={viewingRoomTypes.includes(ROOM_TYPES.studio)} onToggle={toggler(ROOM_TYPES.studio)} title={Str.studio_room()}
                                desc={Str.studio_room_exp()} style={stylesForRow(2, 1)}/>

                <RoomTypeButton selected={viewingRoomTypes.includes(ROOM_TYPES.entirePlace)} onToggle={toggler(ROOM_TYPES.entirePlace)} title={Str.entire_place()}
                                desc={Str.entire_place_exp()} style={stylesForRow(2, 2)}/>

            </div>
        </Paper>

    );
};

const RoomsList = ({rooms, defaultImage, viewingRoomTypes, makeRoomLink}) => {
    const filtered = rooms.filter(e => viewingRoomTypes.includes(parseInt(e.category)));
    if (filtered.length === 0) {
        return (
            <div>
                <Subtitle>{Str.no_search_results()}</Subtitle>
            </div>
        );
    }
    return (
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
            {filtered.map(room => <RoomCard link={makeRoomLink(room)} room={room} defaultImage={defaultImage}/>)}
        </div>
    );
};

const RoomCard = ({room, defaultImage, link}) => {
    const cheapestTenancy = Property.cheapestTenancy(room.tenancies);
    const soldOut = room.tenancies.filter(e => e.available == 1).length === 0;
    return (
        <Paper style={{width: "calc(50% - 8px)", marginBottom: 16, background: "white"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>

            <div style={{padding: 0, position: "relative"}}>
                <div style={{display: soldOut ? "" : "none", position: "absolute", bottom: 16, right: 16}}>
                    <img src={require("../../assets/sold_out.png")} style={{height: 120}} alt="Sold out"/>
                </div>
                <div style={{display: "flex", alignItems: "center", padding: 8}}>
                    <AspectFitImage style={{width: 180, height: 80}} src={room.images[0]?.url ?? defaultImage} />
                    <div style={{marginLeft: 16}}>
                        <SmallTitle style={{flex: 1, margin: 0}}>{room.name}</SmallTitle>
                        <Subtitle><PropertyPrice property={cheapestTenancy}/></Subtitle>
                    </div>
                </div>
                <SanitizedHtml style={{margin: "0 8px", color: "#888"}} html={room.description_translation ?? room.description}/>
                <div style={{textAlign: "center", margin: 16}}>
                    <PrimaryButton component={Link} to={link} style={{paddingLeft: 64, paddingRight: 64}}>{Str.view()}</PrimaryButton>
                </div>
            </div>
        </Paper>
    );
};

const LinkIfAvailable = ({text, name}) => {
    const [alert, showAlert] = useAlert();
    if (text == null || text.trim().length === 0) return null;

    const onOpen = () => {
        const content = <SanitizedHtml html={text}/>;
        showAlert(name, content, Str.close(), "");
    };

    return (
        <>
            {alert}
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderTop: "1px solid #CCC"}} className="hoverable" onClick={onOpen}>
                <Subtitle>{name}</Subtitle>
                <Icon style={{color: "#888"}}>arrow_forward_ios</Icon>
            </div>
        </>
    );
};

const makeDescriptionHTML = property => {
    if (property.description_translation) {
        return `${property.description_translation}<hr/>${property.description}`;
    }
    return property.description;
};

const AccommodationDetailsView = ({property, makeRoomLink, backLink}) => {
    const [viewingRoomTypes, setViewingRoomTypes] = useState(Object.values(Property.ROOM_TYPES));
    return (
        <div style={{display: "flex", height: "100%"}} className="noSelect">
            <div style={{padding: 16, flex: 1, height: "calc(100% - 32px)", overflowY: "auto"}}>
                <BackButton link={backLink}/>
                <ImageCarousel primary={usePrimaryColour()} urls={property.images.map(e => e.url)}/>

                <Title>{property.name}</Title>
                <Subtitle><PropertyPrice property={property}/></Subtitle>

                <SanitizedHtml html={makeDescriptionHTML(property)}/>

                <FacilitiesView facilities={property.facilities}/>

                <LinkIfAvailable text={property.faq} name={Str.faq()}/>
                <LinkIfAvailable text={property.cancellation_policy} name={Str.cancellation_policy()}/>
                <LinkIfAvailable text={property.terms} name={Str.term_and_cond()}/>
            </div>
            <div style={{padding: 16, flex: 1, height: "calc(100% - 32px)", overflowY: "auto", background: "#ddd"}}>
                <Title style={{marginTop: 0, color: "black"}}>{Str.room_types()} - {Str.click_to_select()}</Title>
                <RoomTypeInfo viewingRoomTypes={viewingRoomTypes} setViewingRoomTypes={setViewingRoomTypes}/>
                <Title style={{color: "black"}}>{Str.rooms()}</Title>
                <RoomsList viewingRoomTypes={viewingRoomTypes} rooms={property.rooms} defaultImage={property.image_url} makeRoomLink={makeRoomLink}/>
            </div>
        </div>
    );
};

const Container = ({propertyID, property, makeRoomLink, backLink, getPropertyDetails}) => {
    if (property == null) {
        getPropertyDetails(propertyID);
    }
    return <DataDependant data={property}>
        <AccommodationDetailsView property={property?.payload} makeRoomLink={makeRoomLink} backLink={backLink}/>
    </DataDependant>;
};

const mapStateToProps = (state, {propertyID}) => ({
    propertyID,
    property: selectors.accommodationPropertyDetails(state)[propertyID],
    makeRoomLink: room => "room/" + room.id
});

const mapDispatchToProps = dispatch => ({
    getPropertyDetails: propertyID => {
        dispatch(actions.requestPropertyDetails(propertyID));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = ({backLink}) => {
    const {propertyID} = useParams();
    const processedBackLink = backLink.replace(":propertyID", propertyID);
    return (<Connected propertyID={propertyID} backLink={processedBackLink} />);
};

export default WrappedAndConnected;