import * as Str from "../strings/Str";

const MonthTranslator = ({month}: { month: string }) => {
    switch (month) {
        case "January":
            return Str.january();
        case "February":
            return Str.february();
        case "March":
            return Str.march();
        case "April":
            return Str.april();
        case "May":
            return Str.may();
        case "June":
            return Str.june();
        case "July":
            return Str.july();
        case "August":
            return Str.august();
        case "September":
            return Str.september();
        case "October":
            return Str.october();
        case "November":
            return Str.november();
        case "December":
            return Str.december();
        default:
            return "Error";
    }
};

export default MonthTranslator;