import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Paper} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import * as Assets from "../Assets";
import {SecondaryButton} from "../components/Buttons";
import * as Str from "../strings/Str";
import {mainSectionTheme, usePrimaryColour, useTextColour} from "../theme/Theme";
import {Title} from "../views/Text";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import Dimens from "../theme/Dimens";

const MobileScreen = () => {

    const [screenSize, setScreenSize] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenSize(window.innerWidth);
        });
        setScreenSize(window.innerWidth);
    }, []);

    const allowedWebapp = screenSize > 700;

    const onWebapp = () => {
        localStorage.setItem("dontRemindMobile", "true");
        window.location.replace("/");
    };

    const assetsRoot = process.env.REACT_APP_ASSETS_ROOT;

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", overflowY: "auto", background: usePrimaryColour()}}>
            <img alt="logo" src={require("../" + Assets.LOGO)} style={{width: 100, height: 100, margin: Dimens.HalfMargin}}/>
            <Paper style={{display: "flex", padding: Dimens.StandardMargin, background: "white", minHeight: 300, marginBottom: 64, marginLeft: 20, marginRight: 20}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                <div style={{width: "100%", textAlign: "center", color: useTextColour()}}>
                    {/* TODO hardcoded strings */}
                    <Title style={{fontSize: 28, margin: 8}}>{Str.welcome_to_mo()}</Title>
                    <Title style={{fontSize: 18}}>{Str.welcome_subtitle()}</Title>
                    <p style={{color: "#444", fontSize: 16}}>
                        You appear to be on a mobile device.
                        <br/><br/>
                        The {Str.app_name()} WebApp is designed for computers and laptops... for the best experience on your mobile phone or tablet please download the mobile app instead.
                    </p>

                    <a href="https://play.google.com/store/apps/details?id=com.grantfairy.android">
                        <img style={{width: "80%", maxWidth: "10cm"}} alt={"Get " + Str.app_name() + " on Google Play"} src={assetsRoot + "/store_icons/icon_play.png"}/>
                    </a>
                    <br/><br/>
                    <a href="https://apps.apple.com/gb/app/mo-uk-university-degrees/id1289700601">
                        <img style={{width: "80%", maxWidth: "10cm"}} alt={"Get " + Str.app_name() + " on the AppStore"} src={assetsRoot + "/store_icons/icon_appstore.png"}/>
                    </a>
                    <br/>
                    <br/>
                    {allowedWebapp && <SecondaryButton onClick={onWebapp} style={{width: "80%", maxWidth: "10cm", paddingTop: Dimens.StandardMargin, paddingBottom: Dimens.StandardMargin}}>I want to use the WebApp</SecondaryButton>}
                </div>
            </Paper>
        </div>
    );
};

const Container = () => (
    <MuiThemeProvider theme={mainSectionTheme}>
        <GFThemeProvider value={sections.Main}>
            <MobileScreen/>
        </GFThemeProvider>
    </MuiThemeProvider>
);

export default Container;
