import {CircularProgress, Dialog, DialogContent, DialogTitle} from "@mui/material";
import * as Str from "../strings/Str";
import Dimens from "../theme/Dimens";
import {useSecondaryColour} from "../theme/Theme";
import * as selectors from "../redux/selectors";
import {connect} from "react-redux";

const UploadingDocumentPopup = ({open}: { open: boolean }) => {
    return (
        <Dialog open={open} maxWidth="md" fullWidth={true}>
            <DialogTitle style={{fontSize: 16}}>{Str.uploading_file()}</DialogTitle>
            <DialogContent>
                <div style={{width: "100%", display: "flex", justifyContent: "center", paddingBottom: Dimens.DoubleMargin}}>
                    <CircularProgress size={128} thickness={2} style={{color: useSecondaryColour()}}/>
                </div>
            </DialogContent>
        </Dialog>
    );
};
const mapStateToProps = (state) => ({
    open: selectors.uploadingDocument(state)
});

const Connected = connect(mapStateToProps)(UploadingDocumentPopup);

export default Connected;