import {HTMLAttributes} from "react";
import {ProviderUrlSelected} from "../model/AnalyticsEvents";
import {useAnalytics} from "../services/analytics/AnalyticsProvider";
import WebButton from "./WebButton";

export enum ProviderWebsiteButtonType {
    CourseDetailsApply = "course-details-apply",
    CourseDetailsProvider = "course-details-provider",
    CourseDetailsMissingRequirements = "course-details-missing-requirements",
    ProviderDetails = "provider-details"
}

type ProviderWebsiteButtonProps = {
    link: string
    buttonType: ProviderWebsiteButtonType,
    courseID?: string | null
    providerID: string
    analyticsHandler: () => void
}

export const ProviderWebsiteButton = ({link, buttonType, courseID = null, providerID, analyticsHandler, ...props}: ProviderWebsiteButtonProps & HTMLAttributes<HTMLButtonElement>) => {
    const analytics = useAnalytics();
    const logProviderWebsiteClicked = () => {
        analytics.logEvent(new ProviderUrlSelected(courseID, providerID, link, buttonType));
        analyticsHandler();
    };

    return (<WebButton id="btn-university-go-to-website" link={link} {...props} onClick={logProviderWebsiteClicked}/>);
};