import Icon from "@mui/material/Icon";
import {Paper} from "grantfairy-web-common";
import PlainExternalLink from "../../components/PlainExternalLink";
import PlainLink from "../../components/PlainLink";
import * as Constants from "../../Constants";
import * as Str from "../../strings/Str";
import * as Tab from "../../Tab";
import {useControlColourName} from "../../theme/Theme";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import {Title} from "../../views/Text";
import Styles from "../../theme/Styles";
import Dimens from "../../theme/Dimens";
import Colours from "../../theme/Colours";
import {ReactElement} from "react";

const OtherSections = (): ReactElement => (
    <>
        {
            Tab.enabledSections.map(section => (
                <PlainLink to={section.root + section.default} key={section.id}>
                    <div style={{borderBottom: Styles.standardBorder, display: "flex", alignItems: "center", padding: Dimens.StandardMargin}}>
                        <div style={{marginLeft: Dimens.StandardMargin}}>
                            <Title style={{margin: 0, fontWeight: "bold"}}>{section.name}</Title>
                            <p style={{color: Colours.secondaryText, margin: 0, marginTop: Dimens.StandardMargin}}>{section.description}</p>
                        </div>
                    </div>
                </PlainLink>
            ))
        }
    </>
);

const More = () => {
    const iconColour = useControlColourName();

    return (
        <div>
            {!FirebaseUtil.isAgent() && <PlainLink to="/more/settings/contact">
                <div className="hoverable" style={{borderBottom: Styles.standardBorder, display: "flex", alignItems: "center", padding: Dimens.StandardMargin}}>
                    <Icon fontSize="large" color={iconColour}>email</Icon>
                    <Title style={{margin: 0, marginLeft: Dimens.StandardMargin}}>{Str.contact_support()}</Title>
                </div>
            </PlainLink>}
            {!FirebaseUtil.isAgent() && <PlainLink to="/more/settings">
                <div className="hoverable" style={{borderBottom: Styles.standardBorder, display: "flex", alignItems: "center", padding: Dimens.StandardMargin}}>
                    <Icon fontSize="large" color={iconColour}>settings</Icon>
                    <Title style={{margin: 0, marginLeft: Dimens.StandardMargin}}>{Str.settings()}</Title>
                </div>
            </PlainLink>}
            <PlainExternalLink href={Constants.UCAS_WEBSITE}>
                <div style={{borderBottom: Styles.standardBorder, display: "flex", alignItems: "center", padding: Dimens.StandardMargin}}>
                    <Icon fontSize="large" color={iconColour}>website</Icon>
                    <Title style={{margin: 0, marginLeft: Dimens.StandardMargin}}>{Str.myriad_website(Str.app_name())}</Title>
                </div>
            </PlainExternalLink>
        </div>
    );
};

const MoreTab = () => {
    return (
        <div style={{height: `calc(100% - ${Dimens.DoubleMargin * 2}px)`, padding: Dimens.DoubleMargin}} className="noSelect">
            <div style={{display: "flex", alignItems: "flex-start"}}>
                <Paper style={{flex: 1, maxHeight: "100%", overflowY: "auto"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <><OtherSections/></>
                </Paper>
                <div style={{width: Dimens.DoubleMargin}}/>
                <Paper style={{flex: 1, maxHeight: "100%", overflowY: "auto"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <More/>
                </Paper>
            </div>
        </div>
    );
};

export default MoreTab;