import {Icon} from "@mui/material";
import {Link} from "react-router-dom";
import {CSSProperties} from "react";
import * as Str from "../strings/Str";
import {SecondaryButton} from "../components/Buttons";
import {ArrowBack} from "@mui/icons-material";
import Colours from "../theme/Colours";

const baseStyles: CSSProperties = {borderRadius: 16};

const BackButtonContent = ({title}: { title: string }) => (
    <><Icon>arrow_back</Icon>&nbsp;&nbsp;{title}</>
);

const BackButton = ({link, style = {}, title = Str.back()}: { link: string, style?: CSSProperties, title?: string }) => (
    <SecondaryButton component={Link} to={link} style={{...baseStyles, ...style}}><BackButtonContent title={title}/></SecondaryButton>
);

export const BackButtonWithoutLink = ({onClick, style = {}, title = Str.back()}: { onClick: () => void, style?: CSSProperties, title?: string }) => (
    <SecondaryButton onClick={onClick} style={{...baseStyles, ...style}}><BackButtonContent title={title}/></SecondaryButton>
);

export const BackArrowButtonWithoutLink = ({onClick, style = {}, title = ""}: { onClick: () => void, style?: CSSProperties, title?: string }) => (
    <span onClick={onClick} style={{color: Colours.selectedTabColor, textDecoration: "underline", cursor: "pointer"}}>
        <div style={{display: "inline-flex", alignItems: "center", justifyContent: "flex-start"}}>
            <ArrowBack style={{fontSize: 32}}/>
            <span>{title}</span>
        </div>
    </span>
);

export default BackButton;