import {FormAnswerType, FormRadioEntryInputType, RadioEntry, RadioEntryWithId, RadioOptionWithId} from "../../util/form/FormItem";
import {MouseEventHandler, useEffect, useState} from "react";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import {FormControl, TextField} from "@mui/material";
import DropDownOptionWithCheck from "../../components/DropDownOptionWithCheck";
import * as Str from "../../strings/Str";
import SegmentedControl from "../../components/SegmentedControl";
import Dimens from "../../theme/Dimens";
import {FormAnswerSetter, UnansweredQuestionLabel} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {Autocomplete} from "@mui/material";
import {calculateFieldWidth, FormFieldWidth} from "../../util/form/FormFieldWidth";

interface RadioAnswerProps {
    item: RadioEntry;
    startAnswer: () => void;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    setAnswer: FormAnswerSetter;
    validate: boolean;
}

const FormRadioAnswerAsDropdown = ({item, startAnswer, handleLink, answer, setAnswer, validate}: RadioAnswerProps) => {
    const [isOther, setIsOther] = useState(false);
    const [localAnswer, setLocalAnswer] = useState<FormAnswerType>("");

    const isError = validate && item.required && !item.numberAnswered(answer);
    const hasOtherOption = item.inputTypeToUse() == FormRadioEntryInputType.AutoWithOther;
    // Note that 'Other' is intentionally not i18n because it is the actual value required
    const options = hasOtherOption ? [...item.options, "Other"] : item.options;
    const valueRaw = isOther ? "Other" : (answer ?? null);
    const value = valueRaw === "" ? null : valueRaw;

    useEffect(() => {
        setIsOther(answer !== "" && answer != null && hasOtherOption && !item.options.includes(answer));
    }, [item, hasOtherOption]);

    useEffect(() => {
        setLocalAnswer(answer);
    }, [answer]);

    const handleChange = newValue => {
        if (hasOtherOption && newValue === "Other") {
            setIsOther(true);
            setAnswer("");
        } else {
            setIsOther(false);
            setAnswer(newValue ?? "");
        }
    };

    const handleTextfieldBlur = () => {
        setAnswer(localAnswer);
    };

    const fieldWidth = calculateFieldWidth(item.field_width ?? FormFieldWidth.Natural, item.options);

    return (
        <div id={item.id}>
            <FormQuestionText item={item} handleLink={handleLink}/>
            {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
            <FormQuestionInfoText item={item} handleLink={handleLink}/>
            <FormControl margin="dense" style={fieldWidth.getCSS("400 16px Roboto", 56 + (item.required ? 0 : 28))}>
                <Autocomplete options={options}
                              onFocus={startAnswer}
                              size="small"
                              value={value}
                              onChange={(e, value) => handleChange(value)}
                              renderOption={(attrs, option, {selected}) => <DropDownOptionWithCheck props={attrs} selected={selected} label={option}/>}
                              renderInput={(params) => <TextField error={isError} color="secondary" {...params}/>}
                              getOptionLabel={e => e}
                              disableClearable={item.required}
                />
            </FormControl>
            {isOther && (
                <FormControl margin="dense" style={{width: "100%"}}>
                    <TextField error={isError} style={{width: "100%"}} variant="outlined" color="primary" size="small" value={localAnswer}
                               onChange={e => setLocalAnswer(e.target.value)} placeholder={Str.please_specify()} onFocus={startAnswer} onBlur={handleTextfieldBlur}/>
                </FormControl>
            )}
            {isError && <UnansweredQuestionLabel/>}
        </div>
    );
};


const FormRadioAnswerAsSegmentedControl = ({item, startAnswer, handleLink, answer, setAnswer, validate}: RadioAnswerProps) => {
    const selectedItemIndex: number | undefined = answer == undefined ? undefined : item.options.indexOf(answer);
    const isError = validate && item.required && !item.numberAnswered(answer);

    const handleChange = (newSelectedItemIndex: number) => {
        setAnswer(item.options[newSelectedItemIndex]);
    };

    return (
        <div id={item.id}>
            <FormQuestionText item={item} handleLink={handleLink}/>
            {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
            <FormQuestionInfoText item={item} handleLink={handleLink}/>
            <SegmentedControl options={item.options} startAnswer={startAnswer} selectedIndex={selectedItemIndex} setSelectedIndex={handleChange} style={{marginTop: Dimens.HalfMargin}}/>
            {isError && <UnansweredQuestionLabel/>}
        </div>
    );
};


export const FormRadioAnswerView = (props: RadioAnswerProps) => {
    const item = props.item;
    switch (item.inputTypeToUse()) {
        case FormRadioEntryInputType.Auto:
        case FormRadioEntryInputType.AutoWithSearch:
        case FormRadioEntryInputType.AutoWithOther:
            return <FormRadioAnswerAsDropdown {...props}/>;
        case FormRadioEntryInputType.SegmentedControl:
            return <FormRadioAnswerAsSegmentedControl {...props}/>;
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return null;
};

/**
 * This type only exists in the webapp frontend. There is no such thing as a RadioAnswerWithId within the form JSON, nor the backend, nor the mobile apps
 * The way it works is by having options of type {@link RadioOptionWithId} and each option has a name and uid. The uid is stored as the answer
 * Conversely, the normal Radio form item works with options just being a string[]. The value of the option itself is the thing that is stored
 * This component handles a translation which allows us to just pass through to the normal radio UI
 */
export const FormRadioAnswerWithIdView = ({
                                              item,
                                              startAnswer,
                                              handleLink,
                                              answer,
                                              setAnswer,
                                              validate
                                          }: { item: RadioEntryWithId, startAnswer: () => void, handleLink: MouseEventHandler<HTMLAnchorElement>, answer: FormAnswerType, setAnswer: FormAnswerSetter, validate: boolean }) => {

    const options: RadioOptionWithId[] = item.options;
    const transformedOptions: string[] = options.map(e => e.name);
    const transformedAnswer: string | null = options.find(o => o.uid === answer)?.name ?? null;

    const radioItem = new RadioEntry(item.id, item.show_if, item.required, item.action, item.isUniversitySpecific, item.fieldProviderName, item.question, item.info, transformedOptions, false, false, item.input_type, item.field_width);

    const handleChange = (newValue: FormAnswerType) => {
        const selectedOption = options.find(o => o.name === newValue);
        if (selectedOption == null) return;
        setAnswer(selectedOption.uid);
    };

    return (
        <FormRadioAnswerView item={radioItem} startAnswer={startAnswer} handleLink={handleLink} answer={transformedAnswer} setAnswer={handleChange} validate={validate}/>
    );
};
