import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import { useEffect } from "react";
import {connect} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Alerts from "../Alerts";
import UploadingDocumentPopup from "../components/UploadingDocumentPopup";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import AccommodationApplicationFormScreen from "../screens/accommodation/AccommodationApplicationFormScreen";
import AccommodationApplicationsTab from "../screens/accommodation/AccommodationApplicationsTab";
import AccommodationDetailsView from "../screens/accommodation/AccommodationDetailsView";
import AccommodationFavouritesTab from "../screens/accommodation/AccommodationFavouritesTab";
import AccommodationSearchTab from "../screens/accommodation/AccommodationSearchTab";
import ApplyScreen from "../screens/accommodation/ApplyScreen";
import {NoCampusView} from "../screens/accommodation/NoCampusView";
import RoomDetailsView from "../screens/accommodation/RoomDetailsView";
import MoreTab from "../screens/main/MoreTab";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {accSectionTheme} from "../theme/Theme";
import {AccSectionActionBar} from "../views/ActionBar";

const RedirectToRoute = ({route, nav}) => {
    nav(route);
    return (<></>);
};

const RoutesRequiringCampus = ({hasNoCampus, campuses, onCampusChange}) => {
    const nav = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (hasNoCampus && location.pathname !== "/accommodation/noCampus") {
            nav("/accommodation/noCampus");
        }
    }, [hasNoCampus]);

    const onChangeCampus = (campus) => {
        onCampusChange(campus);
        nav("/accommodation/search");
    };

    return (
        <Routes>
            <Route key="searchRoomDetails" path="/search/:propertyID/details/room/:roomID" element={<RoomDetailsView backLink={"/accommodation/search/:propertyID/details"}/>}/>
            <Route key="searchDetails" path="/search/:propertyID/details" element={<AccommodationDetailsView backLink={"/accommodation/search/:propertyID"} />}/>
            <Route key="search" path="/search/:propertyID" element={<AccommodationSearchTab />}/>
            <Route key="search" path="/search" element={<AccommodationSearchTab />}/>

            <Route key="applicationsApply" path="/applications/apply/:applicationID" element={<ApplyScreen />}/>
            <Route key="applicationsForm" path="/applications/form/:subsection" element={<AccommodationApplicationFormScreen />}/>
            <Route key="applicationsFormNoSection" path="/applications/form" element={<RedirectToRoute nav={nav} route={"/accommodation/applications"} />}/>
            <Route key="applicationsList" path="/applications/:sectionId" element={<AccommodationApplicationsTab />}/>
            <Route key="applicationsRoot" path="/applications" element={<AccommodationApplicationsTab />}/>

            <Route key="favouritesRoomDetails" path="/favourites/:propertyID/room/:roomID" element={<RoomDetailsView backLink={"/accommodation/favourites/:propertyID/details"}/>}/>
            <Route key="favouritesDetails" path="/favourites/:propertyID/details" element={<AccommodationDetailsView backLink={"/accommodation/favourites/:propertyID"} />}/>
            <Route key="favourites" path="/favourites/:propertyID" element={<AccommodationFavouritesTab />}/>
            <Route key="favourites" path="/favourites" element={<AccommodationFavouritesTab />}/>

            <Route key="noCampus" path="/noCampus" element={<NoCampusView campuses={campuses?.payload ?? []} onCampusChange={onChangeCampus}/>}/>
        </Routes>
    );
};

const mapStateToProps = state => ({
    hasNoCampus: selectors.hasNoCampus(state),
    campuses: selectors.accommodationCampuses(state)
});

export const mapDispatchToProps = dispatch => ({
    onCampusChange: campus => {
        if (campus && campus.id) {
            dispatch(actions.setAccommodationCampus(campus.id));
        }
    }
});

const ConnectedRoutesRequiringCampus = connect(mapStateToProps, mapDispatchToProps)(RoutesRequiringCampus);

const Router = () => {
    return (
        <MuiThemeProvider theme={accSectionTheme}>
            <GFThemeProvider value={sections.Accommodation}>
                <Alerts/>
                <UploadingDocumentPopup/>
                <div className="accommodationSection" style={{display: "flex", flexDirection: "column", height: "100vh"}}>
                    <AccSectionActionBar/>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Routes>
                            <Route key="more" path="/more" element={<MoreTab />}/>
                            <Route path="/*" element={ <ConnectedRoutesRequiringCampus/>}/>
                        </Routes>
                    </div>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default Router;