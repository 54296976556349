import {EnglishLanguageTestRequirement} from "../model/Course";
import * as Str from "../strings/Str";
import Table, {StyledTableCell, StyledTableRow} from "../components/Table";
import Dimens from "../theme/Dimens";

const EnglishRequirementsTable = ({tests}: { tests: EnglishLanguageTestRequirement[] }) => (
    <Table style={{maxWidth: 1200, marginBottom: Dimens.StandardMargin}} titles={[Str.test_type(), Str.required_score(), Str.details()]}>
        {tests.map(test => (
            <StyledTableRow key={test.name}>
                <StyledTableCell>{test.name}</StyledTableCell>
                <StyledTableCell><b>{test.score}</b></StyledTableCell>
                <StyledTableCell>{test.info}</StyledTableCell>
            </StyledTableRow>
        ))}
    </Table>
);

export default EnglishRequirementsTable;