import {EventTypes} from "../../model/AnalyticsEvents";

export interface AnalyticsService {
    logEvent<T>(event: EventTypes<T>): void;

    logPageView(page: string): void;
}

export class StandardAnalyticsService implements AnalyticsService {
    services: AnalyticsService[];

    constructor(services: AnalyticsService[]) {
        this.services = services;
    }

    logEvent<T>(event: EventTypes<T>): void {
        this.services.forEach(service => service.logEvent(event));
    }

    logPageView(page: string): void {
        this.services.forEach(service => service.logPageView(page));
    }
}