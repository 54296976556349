import {Util} from "grantfairy-web-common";
import moment from "moment";
import * as Str from "./strings/Str";

export const cheapestTenancy = tenancies => {
    const sorted = [...tenancies];
    sorted.sort((a, b) => a.day_price - b.day_price);
    return sorted[0];
};

export const ROOM_TYPES = Object.freeze({
    shared: 1,
    private: 2,
    studio: 3,
    entirePlace: 4
});

export const INITIAL_FILTERS = Object.freeze({
    roomTypes: [ROOM_TYPES.shared, ROOM_TYPES.private, ROOM_TYPES.studio, ROOM_TYPES.entirePlace],//Array of which room types to include. Blank means all
    hideSold: false,//True hides sold, false shows all properties
    minPrice: 0,//Minimum price in £
    maxPrice: null,//Maximum price in £ or null for no max. Must be more than minPrice
    minTenancyDays: 1,//Min tenancy length in days. Must be either 1 or a multiple of 7
    maxTenancyDays: null//Max tenancy length in days. Null means no max. Must be a multiple of 7
});

export const filtersToApiParams = filters => {
    const room_categories = filters.roomTypes.length == 0 ? null : filters.roomTypes.join(",");

    const {minPrice, maxPrice} = filters;
    const minTenancy = filters.minTenancyDays === 1 ? null : filters.minTenancyDays / 7;
    const maxTenancy = filters.maxTenancyDays == null ? null : filters.maxTenancyDays / 7;

    const hide_sold = filters.hideSold ? 1 : 0;

    const result = {room_categories, minPrice, maxPrice, minTenancy, maxTenancy, hide_sold};

    Object.keys(result).filter(key => result[key] == null).forEach(badKey => {
        delete result[badKey];
    });

    return result;
};

export const joinFacilities = (a, b) => {
    const all = [...a, ...b];
    const allIDs = all.map(e => e.id);
    // @ts-ignore
    const uniqueIDs = [...new Set(allIDs)];
    return uniqueIDs.map(e => all.filter(a => a.id === e)[0]);
};

export const tenancyDateRange = (tenancy) => {
    const startF = moment.unix(tenancy.start).format("DD/MM/YY");
    const endF = moment.unix(tenancy.end).format("DD/MM/YY");
    if (tenancy.start == 0 && tenancy.end == 0) {
        return (<>{Str.move_in_anytime()}</>);
    }
    if (tenancy.start == 0) {
        return (<>{Str.move_out_by(endF)}</>);
    }
    if (tenancy.end == 0) {
        if (tenancy.start < Util.rn()) return (<>{Str.move_in_anytime}</>);
        return (<>{Str.move_in_after(startF)}</>);
    }
    return (<>{Str.range(startF, endF)}</>);
};

export const tenancyLengthWeeks = tenancy => {
    if (tenancy.min_duration == tenancy.max_duration) {
        if (tenancy.min_duration == 0) {
            return Str.no_min_tenancy();
        }
        return Str.tenancy_x_weeks(tenancy.min_duration);
    }
    if (tenancy.min_duration == 0) {
        return Str.tenancy_max_x_weeks(tenancy.max_duration);
    }
    if (tenancy.max_duration == 0) {
        return Str.tenancy_min_x_weeks(tenancy.min_duration);
    }
    return Str.tenancy_range_weeks(tenancy.min_duration, tenancy.max_duration);
};