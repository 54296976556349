import {Util} from "grantfairy-web-common";
import {FormattedDate} from "../../components/FormattedDate";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";

const ScholarshipDeadline = ({deadlineUnix, highlightNearDeadline}) => {
    // eslint-disable-next-line eqeqeq
    if (deadlineUnix == 0) return <span style={{color: Colours.secondaryText}}>{Str.deadline(Str.no_deadline())}</span>;
    const color = (highlightNearDeadline && deadlineUnix < Util.rn() + 14 * 24 * 60 * 60) ? "#dc6c16" : Colours.secondaryText;
    return <span style={{color}}>{Str.deadline(FormattedDate({format: "DD MMMM YYYY", children: deadlineUnix}))}</span>;
};

export default ScholarshipDeadline;
