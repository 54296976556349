import {Button, Icon} from "@mui/material";
import useEventListener from "@use-it/event-listener";
import {AspectFitImage} from "grantfairy-web-common";
import {useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";

const Arrow = ({icon, left = undefined, right = undefined, onClick}) => (
    <Button onClick={onClick} style={{position: "absolute", top: "calc(50vh - 100px)", left, right, color: "white"}}><Icon style={{fontSize: 128}}>{icon}</Icon></Button>
);

const IANScreen = ({ian, onClose, onOpen}) => {

    const eatEventThen = then => event => {
        event.preventDefault();
        event.stopPropagation();
        then();
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = ian.imagesL.map((e, index) => {
        //if its blank then use the portrait image instead
        if (e == null || e === "") return ian.imagesP[index];
        return e;
    });
    const slide = slides[currentSlide];

    const canPrev = currentSlide > 0;
    const canNext = currentSlide < slides.length - 1;

    const onPrev = () => setCurrentSlide(e => e - 1);
    const onNext = () => setCurrentSlide(e => e + 1);

    useEventListener("keydown", ({key}) => {
        if (canPrev && (key === "ArrowLeft" || key === "ArrowUp")) onPrev();
        if (canNext && (key === "ArrowRight" || key === "ArrowDown")) onNext();
        if (key === "Escape") onClose();
    });

    return (
        <div style={{position: "fixed", zIndex: 5000, left: 0, right: 0, top: 0, bottom: 0, background: "#00000088"}} onClick={onClose}>
            <div style={{position: "absolute", left: 128, right: 128, top: 64, bottom: 64}} onClick={eatEventThen(onOpen)}>
                <AspectFitImage backgroundSize="contain" style={{width: "100%", height: "100%"}} src={slide}/>
            </div>

            <Button style={{position: "absolute", top: 8, right: 64, color: "white"}}><Icon fontSize="large">clear</Icon></Button>

            {canPrev && <Arrow icon="keyboard_arrow_left" left={0} onClick={eatEventThen(onPrev)}/>}
            {canNext && <Arrow icon="keyboard_arrow_right" right={0} onClick={eatEventThen(onNext)}/>}
        </div>
    );
};

const Container = ({ian, dismissIan, onClose}) => {
    const history = useNavigate();
    if (ian == null) return null;

    const onHandleClose = () => {
        onClose();
        dismissIan();
    };

    const onOpen = () => {
        onHandleClose();
        if (ian.link_type === "1") {
            window.open(ian.link_data);
        } else if (ian.link_type === "2") {
            history("/funding/" + ian.link_data + "/-1");
        } else if (ian.link_type === "3") {
            history("/universities/search/" + ian.link_data);
        }
    };

    return <IANScreen ian={ian} onClose={onHandleClose} onOpen={onOpen}/>;
};

const mapStateToProps = state => ({
    ian: selectors.viewingIan(state)
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(actions.closeIan())
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;