/** Keys for LocalStorage items **/

/**
 * Stores a String representing the users current language choice eg "gb"
 */
export const LANGUAGE_CODE = "language_code";

/**
 * Stores a JSON String with keys token, uid and email, representing the currently logged in user
 */
export const CURRENT_USER = "current_user";

/**
 * The token being used for the twitter login currently in progress
 */
export const TWITTER_OAUTH = "twitter_oauth";

/**
 * The token being used for the twitter login currently in progress
 */
export const TWITTER_OAUTH_SECRET = "twitter_oauth_secret";