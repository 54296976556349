import {connect} from "react-redux";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import BaseApplicationFormScreen from "../main/applications/BaseApplicationFormScreen";
import {useParams} from "react-router-dom";

const baseUrl = "/accommodation/applications/form";

export const mapStateToProps = (state, {subsection}) => ({
    navigationOptions: selectors.accFormNavigationOptions(state, decodeURIComponent(subsection), baseUrl),
    subsection: selectors.accApplicationFormSubsection(state, decodeURIComponent(subsection)),
    allItems: selectors.accApplicationFormAllItems(state),
    answers: selectors.applicationFormAnswers(state),
    savingState: selectors.applicationFormSavingState(state),
    backLink: baseUrl
});

export const mapDispatchToProps = dispatch => ({
    onSetAnswer: (questionID, answer) => dispatch(actions.setApplicationFormAnswer(questionID, answer)),
    onBack: () => dispatch(actions.goBack(baseUrl)),
    onSave: () => dispatch(actions.showToast(Str.saved()))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(BaseApplicationFormScreen);

const WrappedAndConnected = () => {
    const {subsection} = useParams();
    return (<Connected subsection={subsection} />);
};

export default WrappedAndConnected;