import {CircularProgress} from "@mui/material";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import {Paper, usePrimaryColour} from "grantfairy-web-common";
import {useState} from "react";
import {PrimaryButton} from "../../../components/Buttons";
import * as Str from "../../../strings/Str";
import * as api2 from "../../../util/api2";
import {SmallTitle} from "../../../views/Text";

const ApplicationPaymentState = Object.freeze({
    idle: "idle", //Just got here
    sendingPaymentMethod: "sendingPaymentMethod", //Sent the payment method and awaiting response
    handlingActions: "handlingActions", //Further action is needed and is being carried out now
    sendingConfirmation: "sendingConfirmation", //Further action has been done and now sending again
    completed: "completed",
    failed: "failed"
});

const CheckoutForm = ({error, handleNewCard, visible}) => {

    const elements = useElements();
    const primary = usePrimaryColour();

    const onPurchase = async () => {
        if (!elements) return;

        const cardElement = elements.getElement(CardElement);
        handleNewCard(cardElement);
    };

    return (
        <div style={{visibility: visible ? "visible" : "hidden", height: visible ? "auto" : "0px"}}>
            <SmallTitle>Payment Details</SmallTitle>
            <Paper style={{padding: 16}}>
                <CardElement options={{
                    iconStyle: "solid",
                    style: {
                        base: {
                            iconColor: primary,
                            fontSize: "16px"
                        }
                    }
                }}/>
            </Paper>
            {error != null && <p style={{color: "red"}}>{error}</p>}
            <div style={{display: "flex", justifyContent: "center", marginTop: 32}}>
                <PrimaryButton style={{paddingLeft: 32, paddingRight: 32}} onClick={onPurchase}>{Str.purchase()}</PrimaryButton>
            </div>
        </div>
    );
};

const Container = ({paymentInfo, onPaid}) => {

    const [state, setState] = useState(ApplicationPaymentState.idle);
    const [error, setError] = useState(null);

    const stripe = useStripe();

    /**
     * Step 1. Get card info from UI and make a payment method
     */
    const handleNewCard = async cardElement => {
        setState(ApplicationPaymentState.sendingPaymentMethod);
        const {error, paymentMethod} = await stripe.createPaymentMethod({type: "card", card: cardElement});

        if (error) {
            setState(ApplicationPaymentState.idle);
            setError(error.message);
        } else {
            onGotPaymentMethod(paymentMethod.id);
        }
    };

    /**
     * Step 2, send the payment method from stripe to the server. Server will tell us what to do next
     */
    const onGotPaymentMethod = paymentMethodId => {
        setState(ApplicationPaymentState.sendingPaymentMethod);
        api2.postRequest("applicationTokens/tokenPaymentMethod", {paymentMethodId, numberTokens: paymentInfo.numberOfTokens}).then(result => {
            if (result.success) {
                const {requiresAction, clientSecret} = result.result;
                if (requiresAction) {
                    setState(ApplicationPaymentState.handlingActions);
                    handleActions(clientSecret);
                } else {
                    setState(ApplicationPaymentState.completed);
                    onPaid();
                }
            } else {
                setState(ApplicationPaymentState.failed);
            }
        });
    };

    /**
     * Step 3, if result from step 2 says we need to handle actions, then do so. Once handled, confirm the payment intent again
     * Skip this step if no actions needed
     */
    const handleActions = clientSecret => {
        stripe.handleCardAction(clientSecret).then(result => {
            confirmPaymentIntent(result.paymentIntent.id);
        }).catch(error => {
            console.log(error);
        });
    };

    /**
     * Step 4, only happens if step 3 happened, confirm the payment intent once the security measures have been handled
     * @param paymentIntentId
     */
    const confirmPaymentIntent = paymentIntentId => {
        setState(ApplicationPaymentState.sendingConfirmation);
        api2.postRequest("applicationTokens/tokenPaymentConfirm", {paymentIntentId}).then(result => {
            if (result.success) {
                setState(ApplicationPaymentState.completed);
                onPaid();
            } else {
                setState(ApplicationPaymentState.failed);
            }
        });
    };

    const confirmingOrSending = state === ApplicationPaymentState.sendingConfirmation || state === ApplicationPaymentState.sendingPaymentMethod;

    if (state === ApplicationPaymentState.completed) {
        return "Payment completed"; // TODO hardcoded string
    } else if (state === ApplicationPaymentState.idle || state === ApplicationPaymentState.failed || confirmingOrSending) {
        return (
            <>
                <CheckoutForm paymentInfo={paymentInfo} handleNewCard={handleNewCard} error={error} visible={!confirmingOrSending}/>
                {confirmingOrSending && <div style={{width: "100px", margin: "0 auto"}}><CircularProgress size={100} thickness={2}/></div>}
            </>
        );
    } else if (state === ApplicationPaymentState.handlingActions) {
        return <p>Handling additional security</p>;
    }

    return null;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const StripeContainer = ({paymentInfo, onPaid}) => (
    <Elements stripe={stripePromise}>
        <Container paymentInfo={paymentInfo} onPaid={onPaid}/>
    </Elements>
);

export default StripeContainer;
