import * as Str from "./Str";

it("Gets string from current language", () => {
    Storage.prototype.getItem = jest.fn(() => "es");
    expect(Str.courses()).toEqual("Cursos");
    Storage.prototype.getItem = jest.fn(() => null);
});

it("Gets string from default language", () => {
    Storage.prototype.getItem = jest.fn(() => null);
    expect(Str.courses()).toEqual("Courses");
});

it("Inserts args into string", () => {
    expect(Str.num_scholarships("10")).toEqual("10 scholarships");
});

it("Inserts multiple args into string", () => {
    expect(Str.price("50", "week")).toEqual("50 / week");
});

it("Gets array", () => {
    expect(Str.array_gender()).toEqual(["Male", "Female", "Other"]);
});