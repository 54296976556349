import {DialogContent, TextField} from "@mui/material";
import {APPLICATION_POST_SENT_STATUS} from "../../../Applications";
import {ShowPasswordInputAdornment} from "../../../components/ToggleButtons";
import * as Str from "../../../strings/Str";
import {Dispatch, SetStateAction} from "react";

type PartialRegistrationContentProps = {
    providerName: string,
    whichPart: string
    registrationHelpText: string
    postSentStatus: number
    validate: boolean
    username: string,
    setUsername: (value: string) => void
    usernameValid: boolean
    password: string,
    setPassword: (value: string) => void
    passwordValid: boolean
    showPassword: boolean
    setShowPassword: Dispatch<SetStateAction<boolean>>
    hasError: boolean
}

export const PartialRegistrationContent = ({
                                               providerName,
                                               whichPart,
                                               registrationHelpText,
                                               postSentStatus,
                                               validate,
                                               username,
                                               setUsername,
                                               usernameValid,
                                               password,
                                               setPassword,
                                               passwordValid,
                                               showPassword,
                                               setShowPassword,
                                               hasError
                                           }: PartialRegistrationContentProps) => (
    <DialogContent style={{padding: "0 24px"}}>
        <p style={{marginTop: 0, whiteSpace: "pre-wrap"}}>{Str.partial_manual_registration_info_part_1(providerName)}&nbsp;
            <b>{Str.partial_manual_registration_info_part_2(providerName, whichPart)}</b> {Str.partial_manual_registration_info_part_3(whichPart)}</p>
        {registrationHelpText.length !== 0 && <p>{registrationHelpText}</p>}
        <TextField color="primary" style={{width: "100%"}} label={postSentStatus === APPLICATION_POST_SENT_STATUS.partialManualRegistrationRequiringUsername ? Str.username() : Str.email()}
                   onChange={e => setUsername(e.target.value)} variant="outlined" size="small"
                   value={username} error={validate && !usernameValid}/>
        <TextField color="primary" style={{width: "100%", marginTop: 16}} label={Str.your_password()} onChange={e => setPassword(e.target.value)}
                   type={showPassword ? "text" : "password"} variant="outlined" size="small" value={password} error={validate && !passwordValid}
                   InputProps={{endAdornment: <ShowPasswordInputAdornment showPassword={showPassword} setShowPassword={setShowPassword}/>}}/>
        {validate && hasError && <p style={{color: "red"}}>{Str.please_fill_field()}</p>}
    </DialogContent>);