import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import {ACCOMMODATION_APPLICATION_STATUS} from "../Applications";
import {PrimaryButton, SecondaryButton} from "../components/Buttons";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import * as Str from "../strings/Str";

const makeTextForStatus = (status, available) => {
    if (!available) return Str.sold_out();
    if (status === ACCOMMODATION_APPLICATION_STATUS.draft) return Str.apply();
    if (status === ACCOMMODATION_APPLICATION_STATUS.sent) return Str.view();
    return Str.apply();
};

const ApplyButton = ({status, available, style, onClick}) => {
    const text = makeTextForStatus(status, available);
    const hasApplication = status === ACCOMMODATION_APPLICATION_STATUS.draft || status === ACCOMMODATION_APPLICATION_STATUS.sent;
    if (hasApplication) {
        return <SecondaryButton style={style} onClick={onClick}>{text}</SecondaryButton>;
    } else {
        return <PrimaryButton disabled={!available} style={style} onClick={onClick}>{text}</PrimaryButton>;
    }
};

const Container = ({status, applicationID, onApply, ...props}) => {
    const history = useNavigate();
    const onClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (status === ACCOMMODATION_APPLICATION_STATUS.draft) {
            history("/accommodation/applications/apply/" + applicationID);
        } else if (status === ACCOMMODATION_APPLICATION_STATUS.sent) {
            history("/accommodation/applications");
        } else if (!props.available) {

        } else {
            onApply();
        }
    };
    return <ApplyButton status={status} {...props} onClick={onClick}/>;
};

const mapStateToProps = (state, {tenancyID, ...props}) => ({
    status: selectors.accApplicationStatus(state, tenancyID),
    applicationID: selectors.applicationIdForTenancy(state, tenancyID),
    ...props
});

const mapDispatchToProps = (dispatch, {tenancyID}) => ({
    onApply: () => dispatch(actions.applyForTenancy(tenancyID))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;