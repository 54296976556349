import {FormAnswerType, FormItem, MultiSelectEntry} from "../../util/form/FormItem";
import {StringDictionary} from "../../types/StringDictionary";
import * as FormUtil from "../../util/form/FormUtil";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import {VSpacer} from "../../components/Spacers";
import {MultiSelectView} from "../../components/MultiSelectView";
import {FormAnswerSetter} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {MouseEventHandler} from "react";


interface MultiSelectAnswerProps {
    item: MultiSelectEntry;
    startAnswer: () => void;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    setAnswer: FormAnswerSetter;
    validate: boolean;
    allItems: FormItem[];
    allAnswers: StringDictionary;
}

const FormMultiSelectionView = ({item, startAnswer, handleLink, answer, setAnswer, allItems, allAnswers}: MultiSelectAnswerProps) => {
    const selectedIds = item.answerFromString(answer) ?? [];

    const setSelectedItemIds = (ids) => {
        setAnswer(item.answerToString(ids));
    };

    const availableOptions = item.options.filter(option => option.show_if == null || FormUtil.isShowIfRuleSatisfied(option.show_if, allItems, allAnswers));

    return (
        <div id={item.id}>
            <FormQuestionText item={item} handleLink={handleLink} showOptionalLabel={true}/>
            {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
            <FormQuestionInfoText item={item} handleLink={handleLink}/>
            <VSpacer space={8}/>
            <MultiSelectView options={availableOptions} startAnswer={startAnswer} selectedItemIds={selectedIds} setSelectedItemIds={setSelectedItemIds}/>
        </div>
    );
};

export default FormMultiSelectionView;