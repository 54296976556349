import {TuitionFeeSetForLocale} from "../model/Course";
import Table, {StyledTableCell, StyledTableRow} from "../components/Table";
import * as StringUtil from "../util/StringUtil";
import * as _ from "lodash";
import * as Str from "../strings/Str";
import Dimens from "../theme/Dimens";

const TuitionFeeLocaleSetView = ({sets}: { sets: TuitionFeeSetForLocale[] }) => {
    const allFeeDurationPeriods = sets.flatMap(e => e.fees.map(f => f.feeDurationPeriod));
    const feeDurationPeriods: string[] = _.uniq(allFeeDurationPeriods);
    const tableHeaders = [Str.tution_fee_type(), ...feeDurationPeriods];
    return (
        <Table style={{maxWidth: 1200, marginBottom: Dimens.StandardMargin}} titles={tableHeaders}>
            {sets.map(set => {
                return (
                    <StyledTableRow>
                        <StyledTableCell>{set.locale}</StyledTableCell>
                        {feeDurationPeriods.map(feeDurationPeriod => {
                            const fee = set.fees.find(f => f.feeDurationPeriod === feeDurationPeriod);
                            if (fee == null) return " - ";
                            return <StyledTableCell><b>{StringUtil.getTextForMoneyRange(fee.minFees, fee.maxFees, fee.currencyCode, false)}</b></StyledTableCell>;
                        })}
                    </StyledTableRow>
                );
            })}
        </Table>
    );
};

export default TuitionFeeLocaleSetView;
