import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import ColouredLink from "../../../components/ColouredLink";
import {ShowPasswordButton, ToggleButton} from "../../../components/ToggleButtons";
import * as Str from "../../../strings/Str";
import {useControlColourName, useSecondaryColour} from "../../../theme/Theme";

export const CopyButton = ({copied, onClick}) => <ToggleButton isOn={copied} setIsOn={onClick} iconOff="content_copy" iconOn="check"/>;

const CopyableField = ({text, extraButton = null, obfuscate = false}) => {

    const [copied, setCopied] = useState(false);

    const browserSupportsCopy = navigator.clipboard != null;

    useEffect(() => {
        if (copied) {
            const handle = setTimeout(() => {
                setCopied(false);
            }, 2000);
            return () => clearTimeout(handle);
        }
        return undefined;
    }, [copied]);

    const onCopy = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    };

    return (
        <div color="primary" style={{width: "100%", background: "#ECECEC", display: "flex", borderRadius: 16, padding: "4px 16px", boxSizing: "border-box"}}>
            <input style={{flex: 1, borderWidth: 0, background: "#00000000", fontSize: 17, outlineWidth: 0}} type={obfuscate ? "password" : ""} value={text} readOnly/>
            {extraButton}
            {browserSupportsCopy && <CopyButton onClick={onCopy} copied={copied}/>}
        </div>
    );
};

const RevealableCopyableField = ({text}) => {
    const [showPassword, setShowPassword] = useState(false);

    return <CopyableField text={text} extraButton={<ShowPasswordButton showPassword={showPassword} setShowPassword={setShowPassword}/>} obfuscate={!showPassword}/>;
};

const Popup = ({onSubmit, onClose, application, username, password}) => {

    const [checked, setChecked] = useState(false);

    const handleSubmit = () => {
        onSubmit(application);
    };

    const controlColourName = useControlColourName();

    return (
        <Dialog open={true} maxWidth="md" fullWidth onClose={onClose}>
            <DialogTitle>{Str.final_check_required_title()}</DialogTitle>
            <DialogContent style={{padding: "0 24px"}}>
                <p>
                    {Str.final_check_required_message()}
                </p>
                <p><span style={{fontWeight: "bold"}}>{Str.link_to_login("")}</span> <ColouredLink colour={useSecondaryColour()} href={application.applyUrl}>{Str.click_here()}</ColouredLink></p>
                <p><span style={{fontWeight: "bold"}}>{Str.your_email_or_username()}:</span></p>
                <CopyableField text={username}/>
                <p><span style={{fontWeight: "bold"}}>{Str.your_password()}:</span></p>
                <RevealableCopyableField text={password}/>
                <div style={{marginTop: 8, flexDirection: "row", display: "flex"}}>
                    <Checkbox color={controlColourName} onChange={() => setChecked(!checked)} checked={checked}/>
                    <span style={{lineHeight: 3}}>{Str.check_application_button_confirm_text()}</span>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color={controlColourName} onClick={onClose}>{Str.later()}</Button>
                <Button color={controlColourName} disabled={!checked} onClick={handleSubmit}>{Str.save()}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Popup;