import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Route} from "react-router";
import {unstable_HistoryRouter as HistoryRouter, Routes, useNavigate} from "react-router-dom";
import * as FeatureFlags from "./FeatureFlags";
import * as selectors from "./redux/selectors";
import AccommodationSectionRouter from "./routers/AccommodationSectionRouter";
import EnglishLanguageTestsSectionRouter from "./routers/EnglishLanguageTestsSectionRouter";
import JobsSectionRouter from "./routers/JobsSectionRouter";
import MainSectionRouter from "./routers/MainSectionRouter";
import UniversitiesSectionRouter from "./routers/UniversitiesSectionRouter";
import HomeScreen from "./screens/homescreen/HomeScreen";
import LoginScreen from "./screens/login/LoginScreen";
import SignupScreen from "./screens/login/SignupScreen";
import IANScreen from "./screens/main/IANScreen";
import MobileScreen from "./screens/MobileScreen";
import ServerSidePopup from "./screens/ServerSidePopup";
import TestScreen from "./testPages/TestScreen";
import InformationAndGuidanceRouter from "./routers/InformationAndGuidanceRouter";
import {history} from "./redux/store";
import EnglishLanguageSSOFailureRouter from "./screens/identity/EnglishLanguageSSOFailureRouter";
import * as actions from "./redux/actions";

const RootsRequiringProfile = ({showSplash, needsSignIn}: { showSplash: boolean, needsSignIn?: boolean }) => {

    const history = useNavigate();
    const [sessionIsNew, setSessionIsNew] = useState(true);
    useEffect(() => {
        if (needsSignIn) {
            history("/signup");
        }
    }, [needsSignIn]);

    const handleDismissIan = () => {
        setSessionIsNew(false);
    };

    if (showSplash) return null;
    if (needsSignIn) return <p>Need sign in</p>;
    return <>
        {sessionIsNew && <IANScreen dismissIan={handleDismissIan}/>}
        <ServerSidePopup/>
        <Routes>
            {FeatureFlags.HOME_SCREEN_ENABLED && <Route path="/" element={<HomeScreen/>}/>}
            <Route path="/jobs/*" element={<JobsSectionRouter/>}/>
            <Route path="/accommodation/*" element={<AccommodationSectionRouter/>}/>
            <Route path="/universities/*" element={<UniversitiesSectionRouter/>}/>
            <Route path="/information_and_guidance/*" element={<InformationAndGuidanceRouter/>}/>
            {FeatureFlags.ENGLISH_LANGUAGE_SECTION_ENABLED && <Route path="/english_language/*" element={<EnglishLanguageTestsSectionRouter/>}/>}
            <Route path="*" element={<MainSectionRouter/>}/>
        </Routes>
    </>;
};

const mapStateToProps = state => {
    const needsSignInResult = selectors.needsSignIn(state);
    if (needsSignInResult === null) {
        return {showSplash: true};
    } else if (needsSignInResult === true) {
        return {showSplash: false, needsSignIn: true};
    } else {
        return {showSplash: false, needsSignIn: false};
    }
};

const RootsRequiringProfileContainer = connect(mapStateToProps)(RootsRequiringProfile);


const Root = ({getRemoteConfig} : {getRemoteConfig: () => void}) => {
    useEffect(() => {
        getRemoteConfig();
    }, []);

    return (
        // @ts-ignore
        <HistoryRouter history={history}>
            <Routes>
                {process.env.REACT_APP_ENABLE_TEST_PAGES && <Route path="/test" element={<TestScreen/>}/>}
                <Route path="/login" element={<LoginScreen/>}/>
                <Route path="/signup" element={<SignupScreen/>}/>
                <Route key="identityFailure" path="/identity/english_sso_failure" element={<EnglishLanguageSSOFailureRouter />} />
                <Route path="/mobile" element={<MobileScreen/>}/>
                <Route path="*" element={<RootsRequiringProfileContainer/>}/>
            </Routes>
        </HistoryRouter>
    );
};

const mapDispatchToProps = dispatch => ({
    getRemoteConfig: () => dispatch(actions.requestRemoteConfig())
});

const Connected = connect(null, mapDispatchToProps)(Root);
export default Connected;
