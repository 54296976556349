import {FormItem} from "./util/form/FormItem";

export const mapProfileFormItems = items => {
    items.forEach(item => {
        Object.setPrototypeOf(item, FormItem.getPrototype(item.type));
    });
};

export const FEE_STATUS = Object.freeze({
    home: 0,
    overseas: 1
});
