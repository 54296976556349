import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Route, Routes} from "react-router-dom";
import Alerts from "../Alerts";
import UploadingDocumentPopup from "../components/UploadingDocumentPopup";
import JobsFavouritesTab from "../screens/jobs/JobsFavouritesTab";
import JobsSearchTab from "../screens/jobs/JobsSearchTab";
import MoreTab from "../screens/main/MoreTab";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {jobsSectionTheme} from "../theme/Theme";
import {JobsSectionActionBar} from "../views/ActionBar";

const Router = () => {
    return (
        <MuiThemeProvider theme={jobsSectionTheme}>
            <GFThemeProvider value={sections.Jobs}>
                <Alerts/>
                <UploadingDocumentPopup/>
                <div className="jobsSection" style={{display: "flex", flexDirection: "column", height: "100vh"}}>
                    <JobsSectionActionBar/>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Routes>
                            <Route key="search" path="/search/:jobID" element={<JobsSearchTab />}/>
                            <Route key="search" path="/search" element={<JobsSearchTab />}/>

                            <Route key="favourites" path="/favourites/:jobID" element={<JobsFavouritesTab />}/>
                            <Route key="favourites" path="/favourites" element={<JobsFavouritesTab />}/>

                            <Route key="more" path="/more" element={<MoreTab />}/>
                        </Routes>
                    </div>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default Router;