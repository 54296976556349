import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {Paper} from "grantfairy-web-common";
import React from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {COURSE_APPLICATION_STATUS, linkForSubsection} from "../../../Applications";
import {PrimaryButton} from "../../../components/Buttons";
import NumberIcon from "../../../components/NumberIcon";
import {PercentIcon} from "../../../components/PercentIcon";
import PlainLink from "../../../components/PlainLink";
import {MasterDetailView} from "../../../components/TabContainer";
import {TextWithBoldParts} from "../../../components/TextWithBoldParts";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import Colours from "../../../theme/Colours";
import Dimens from "../../../theme/Dimens";
import Fonts from "../../../theme/Fonts";
import {useSecondaryColour} from "../../../theme/Theme";
import DataDependant from "../../../views/DataDependant";
import {Subtitle} from "../../../views/Text";
import ApplicationList from "./ApplicationList";
import DocumentTypeList from "./DocumentsList";
import FormMenu from "./FormMenu";
import ViewFormDetails from "./ViewFormDetails";

const SectionsListItem = ({item, link, isSelected}) => {
    const promotionPromptColour = useSecondaryColour();
    return (
        <PlainLink to={link}>
            <div style={{
                padding: "4px 16px",
                height: 64,
                borderBottom: "1px solid #CCC",
                display: "flex",
                alignItems: "center",
                background: isSelected ? Colours.RowHighlight : "",
                justifyContent: "space-between"
            }} className="noSelect">
                <div style={{display: "flex", alignItems: "center"}}>
                    <Subtitle style={{margin: 0, flex: 1}}>{item.title}</Subtitle>
                    {item.accessory != null && <Subtitle style={{
                        marginLeft: "12px",
                        border: "2px solid",
                        borderColor: promotionPromptColour,
                        padding: "4px",
                        background: "white",
                        color: promotionPromptColour
                    }}>{item.accessory}</Subtitle>}
                </div>
                {item.percent != null && <PercentIcon size={64} percent={item.percent}/>}
                {item.number != null && <div style={{padding: 12}}><NumberIcon size={40} number={item.number} color={item.numberColor}/></div>}
            </div>
        </PlainLink>
    );
};

const SectionsList = ({items, selectedItem, makeLinkForTab}) => {
    return items.map((item, index) => <SectionsListItem key={item.title} item={item} link={makeLinkForTab(index)} isSelected={selectedItem === index}/>);
};

const ApplicationsTab = ({
                             promotion,
                             formSections,
                             documentTypes,
                             documents,
                             formCompletionPercent,
                             documentsPercent,
                             answers,
                             makeLinkForSubsection,
                             tab,
                             makeLinkForTab,
                             welcomePopupIsOpen,
                             turnOffWelcomePopup,
                             ...props
                         }) => {
    const promotionInfo = promotion?.payload;
    const items = [
        {
            title: <p style={{fontWeight: "bold"}}>{Str.how_to_apply()}</p>,
            accessory: null,
            component:
                <div style={{width: "100%", justifyContent: "space-between", gap: Dimens.StandardMargin}}>
                    <p style={{fontWeight: "bold", fontSize: Fonts.title, paddingLeft: "23px"}}>{Str.how_to_apply()}</p>
                    <ul style={{fontSize: Fonts.normal}}>
                        <li style={{paddingBottom: "10px"}} >{Str.apply_info_bullet_one()}</li>
                        <li style={{paddingBottom: "10px"}} >{Str.apply_info_bullet_two()}</li>
                        <li style={{paddingBottom: "10px"}} >{Str.apply_info_bullet_three()}</li>
                        <li style={{paddingBottom: "10px"}} >{Str.apply_info_bullet_four()}</li>
                    </ul>
                    <div style={{width: "100%", paddingLeft: "23px"}}>
                        <PrimaryButton component={Link} to={"/courses"} style={{paddingTop: "10px"}}>{Str.search_courses()}</PrimaryButton>
                    </div>
                </div>,
            percent: null
        },
        {
            title: Str.application_form(),
            accessory: null,
            component: <FormMenu answers={answers} formSections={formSections} makeLinkForSubsection={makeLinkForSubsection}/>,
            percent: formCompletionPercent
        },
        {title: Str.documents(), accessory: null, component: <DocumentTypeList documentTypes={documentTypes} documents={documents} {...props}/>, percent: documentsPercent},
        {title: Str.draft_apps(), accessory: null, component: <ApplicationList status={COURSE_APPLICATION_STATUS.draft} emptyText={Str.drafts()}/>, number: props.draftAppCount, numberColor: "black"},
        {
            title: Str.cart(),
            accessory: promotionInfo?.promotionPrompt,
            component: <ApplicationList status={COURSE_APPLICATION_STATUS.cart} emptyText={Str.cart().toLowerCase()}/>,
            number: props.cartAppCount,
            numberColor: "black"
        },
        {
            title: Str.processing_apps(),
            accessory: null,
            component: <ApplicationList status={COURSE_APPLICATION_STATUS.processing} emptyText={Str.processing_apps().toLowerCase()}/>,
            number: props.processingAppCount,
            numberColor: "black"
        },
        {
            title: Str.sent_apps(),
            accessory: null,
            component: <ApplicationList status={COURSE_APPLICATION_STATUS.sent} emptyText={Str.sent_apps().toLowerCase()}/>,
            number: props.sentAppCount,
            numberColor: "black"
        },
        {
            title: Str.invalid_apps(),
            accessory: null,
            component: <ApplicationList status={COURSE_APPLICATION_STATUS.rejected} emptyText={Str.invalid_apps().toLowerCase()}/>,
            number: props.rejectedAppCount,
            numberColor: "black"
        },
        {title: Str.view_all_dets(), accessory: null, component: <ViewFormDetails answers={answers} formSections={formSections} documents={documents} documentTypes={documentTypes}/>}
    ];

    const viewingItem = items[tab];

    const closeAlertScreen = () => {
        turnOffWelcomePopup();
    };

    return (
        <React.Fragment>
            <WelcomePopup onClose={closeAlertScreen} open={welcomePopupIsOpen}/>
            <MasterDetailView listView={(
                <Paper style={{flex: 1, overflowY: "auto"}}>
                    <SectionsList items={items} selectedItem={tab} makeLinkForTab={makeLinkForTab}/>
                </Paper>
            )} detailView={(
                <Paper style={{height: "100%", overflowY: "auto"}}>
                    {viewingItem?.component}
                </Paper>
            )} showDetailView={true}/>
        </React.Fragment>
    );
};

const WelcomePopup = ({onClose, open}) => {
    const applicationExplanatoryTextDetails = Str.application_explanatory_text_details(Str.application_form(), Str.application_form(), Str.app_name(), Str.application_explanatory_text_details_bold_substring());

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogContent style={{padding: Dimens.StandardMargin}}>
                <div>
                    <h2>{Str.application_explanatory_text_title()}</h2>
                    <p style={{whiteSpace: "pre-wrap"}}><TextWithBoldParts text={applicationExplanatoryTextDetails} boldSubString={Str.application_explanatory_text_details_bold_substring()}/></p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>{Str.okay()}</Button>
            </DialogActions>
        </Dialog>
    );
};

const Container = ({promotion, formSections, answers, documents, documentTypes, welcomePopupIsOpen, turnOffWelcomePopup, ...props}) => (
    <DataDependant data={formSections}>
        <ApplicationsTab formCompletionPercent={undefined} documentsPercent={undefined} makeLinkForSubsection={undefined} tab={undefined}
                         makeLinkForTab={undefined} promotion={promotion}
                         formSections={formSections?.payload} answers={answers?.payload ?? {}} documentTypes={documentTypes?.payload ?? {}}
                         documents={documents?.payload ?? {}} welcomePopupIsOpen={welcomePopupIsOpen} turnOffWelcomePopup={turnOffWelcomePopup} {...props}/>
    </DataDependant>
);

export const mapStateToProps = (state, {tab}) => ({
    promotion: selectors.applicationPromotionInfo(state),
    formSections: selectors.applicationFormSections(state),
    answers: selectors.applicationFormAnswers(state),
    formCompletionPercent: selectors.universityFormCompletionPercent(state),
    documentTypes: selectors.applicationFormDocumentTypes(state),
    documents: selectors.applicationFormDocuments(state),
    documentsPercent: selectors.documentCompletionPercent(state),
    draftAppCount: selectors.universityApplicationsWithStatus(state, COURSE_APPLICATION_STATUS.draft)?.payload?.length ?? 0,
    cartAppCount: selectors.universityApplicationsWithStatus(state, COURSE_APPLICATION_STATUS.cart)?.payload?.length ?? 0,
    processingAppCount: selectors.universityApplicationsWithStatus(state, COURSE_APPLICATION_STATUS.processing)?.payload?.length ?? 0,
    sentAppCount: selectors.universityApplicationsWithStatus(state, COURSE_APPLICATION_STATUS.sent)?.payload?.length ?? 0,
    rejectedAppCount: selectors.universityApplicationsWithStatus(state, COURSE_APPLICATION_STATUS.rejected)?.payload?.length ?? 0,
    welcomePopupIsOpen: selectors.applicationWelcomePopupIsOpen(state),
    makeLinkForSubsection: subsection => linkForSubsection(subsection, "/applications/form/"),
    tab: tab == null ? 0 : parseInt(tab),
    makeLinkForTab: tab => "/applications/" + tab
});

export const mapDispatchToProps = (dispatch) => ({
    turnOffWelcomePopup: () => {
        dispatch(actions.turnOffWelcomePopup());
    }
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {tab} = useParams();
    return (<Connected tab={tab}/>);
};

export default WrappedAndConnected;
