import {ApiResultState} from "../../util/ApiResult";
import * as actions from "../actions";
import * as reducers from "./mainSectionReducers";

it("Uni details gets new details", () => {
    const id = "testUID";
    const testResult = {success: true, result: {provider: {id}}};
    const action = actions.gotCourseProviderDetails(id, testResult);
    const actual = reducers.courseProviderDetails({}, action);
    const expected = {[id]: {state: ApiResultState.Success, payload: {id}}};
    expect(actual).toEqual(expected);
});

it("Uni details becomes loading", () => {
    const id = "testUID";
    const action = actions.requestedCourseProviderDetails(id);
    const actual = reducers.courseProviderDetails({}, action);
    const expected = {[id]: {state: ApiResultState.Loading}};
    expect(actual).toEqual(expected);
});