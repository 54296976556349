import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";
import * as FeatureFlags from "../../FeatureFlags";
import * as Str from "../../strings/Str";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import {Subtitle} from "../../views/Text";

const ChangeEmailDialog = ({open, onClose, onChangeEmail, originalEmail}) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const checkPasswordAndUpdateEmail = () => {
        FirebaseUtil.checkSignIn(originalEmail, password, () => {
            onChangeEmail(email);
            onClose();
        }, (err) => {
            setError(err);
        });
    };

    return (
        <Dialog open={open} maxWidth="sm" onClose={onClose}>
            <DialogTitle>{Str.change_email()}</DialogTitle>
            <DialogContent>
                <Subtitle style={{margin: 0}}>{FeatureFlags.APPLICATIONS_ENABLED ? Str.change_email_exp() : Str.change_email_exp_no_apps()}</Subtitle>
                <div>
                    <Subtitle style={{fontWeight: "bold"}}>{Str.current_email()}</Subtitle>
                    <TextField variant="outlined" size="small" disabled={true} value={originalEmail} style={{width: "100%"}}/>
                    <Subtitle style={{fontWeight: "bold"}}>{Str.new_email()}</Subtitle>
                    <TextField variant="outlined" size="small" type="email" value={email} onChange={e => setEmail(e.target.value)} style={{width: "100%"}}/>
                    <Subtitle style={{fontWeight: "bold"}}>{Str.confirm_password()}</Subtitle>
                    <TextField variant="outlined" size="small" type="password" onChange={e => setPassword(e.target.value)} style={{width: "100%"}}/>
                    {(error.length > 0) && <p style={{color: "red"}}>{error}</p>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {Str.cancel()}
                </Button>
                <Button onClick={checkPasswordAndUpdateEmail} color="secondary" autoFocus>
                    {Str.done()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeEmailDialog;