export interface MIANImage {
    width: number;
    height: number;
    url: string;
}

export interface MIANSlide {
    images: MIANImage[],
    expanded_images: MIANImage[]
}

export interface MIAN {
    uid: string;
    arrowColor: string;
    bleedColor: string;
    buttonColor: string;
    linkColor: string;
    linkText: string;
    link_data: string;
    link_type: MIANLinkType;
    name: string;
    slides: MIANSlide[];
}

export enum MIANLinkType {
    link = "1",
    scholarship = "2",
    tab = "3",
    options = "4",
    course = "5",
    university = "6"
}
