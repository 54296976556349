import {HomeScreenCourseFavouriteList} from "../../model/HomeScreenItem";
import Dimens from "../../theme/Dimens";
import * as Str from "../../strings/Str";
import {ClickablePaper, Paper} from "grantfairy-web-common";
import {SmallTitle, Subtitle, Title} from "../../views/Text";
import {FlexFiller, VSpacer} from "../../components/Spacers";
import PlainLink from "../../components/PlainLink";
import {BasicCourseInfo} from "../../model/Course";
import {Favorite} from "@mui/icons-material";
import Colours from "../../theme/Colours";
import {PrimaryButton} from "../../components/Buttons";
import {Link} from "react-router-dom";
import {TextWithBoldParts} from "../../components/TextWithBoldParts";

const makeLinkForCourse = (course: BasicCourseInfo) => `/favourites/${course.id}`;

const HomeScreenCourseListView = ({data}: { data: HomeScreenCourseFavouriteList }) => {
    return (
        <Paper id={"home-favourites-card"} style={{boxSizing: "border-box", height: "100%", background: "white", display: "flex", flexDirection: "column"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            <VSpacer space={Dimens.DoubleMargin}/>
            <div style={{display: "flex", gap: Dimens.StandardMargin, padding: `0 ${Dimens.DoubleMargin}px`}}>
                <div style={{flex: 1}}>
                    <Title style={{margin: 0}}>{Str.courses()}</Title>
                    <VSpacer space={Dimens.HalfMargin}/>
                    {data.numberOfCourses !== 0 && (
                        <Subtitle style={{margin: 0}}>
                            <TextWithBoldParts text={Str.num_favourites(data.numberOfCourses)} boldSubString={data.numberOfCourses}/>
                        </Subtitle>
                    )}
                </div>
                {data.numberOfCourses !== 0 && <PrimaryButton component={Link} to="/favourites">{Str.view_all()}</PrimaryButton>}
            </div>
            {data.numberOfCourses !== 0 && <CourseCarousel courses={data.courses}/>}
            {data.numberOfCourses === 0 && <EmptyState/>}
        </Paper>
    );
};

const CourseCarousel = ({courses}: { courses: BasicCourseInfo[] }) => {
    return (
        <div style={{flex: 1, display: "flex", gap: Dimens.StandardMargin, padding: Dimens.StandardMargin, alignItems: "stretch", overflowX: "auto"}}>
            {courses.map(course => (
                <PlainLink to={makeLinkForCourse(course)} key={course.id}>
                    <ClickablePaper style={{height: "100%", background: "white", boxSizing: "border-box", boxShadow: "rgba(92, 92, 92, 0.71) 0px 0px 13px -5px"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        <div style={{width: 320, padding: Dimens.StandardMargin, display: "flex", flexDirection: "column", height: "100%", boxSizing: "border-box"}}>
                            <Subtitle style={{fontWeight: 500, margin: 0}}>{course.title}</Subtitle>
                            <VSpacer space={Dimens.HalfMargin}/>
                            <Subtitle style={{margin: 0}}>{course.outcomes}</Subtitle>
                            <FlexFiller/>
                            <div style={{display: "flex", gap: Dimens.StandardMargin}}>
                                <img style={{width: 48, height: 48}} src={course.providerIconUrl} alt=""/>
                                <p style={{color: "#555", margin: "8px 0 0 0"}}>{course.providerName}</p>
                            </div>
                        </div>
                    </ClickablePaper>
                </PlainLink>
            ))}
        </div>
    );
};

const EmptyState = () => {
    return (
        <div id={"home-empty-favourites-card"} style={{flex: 1, display: "flex", padding: Dimens.DoubleMargin, alignItems: "center", flexDirection: "column"}}>
            <FlexFiller/>
            <div style={{position: "relative", display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                <Favorite style={{fontSize: 96, position: "absolute", color: "#EEEEEE", zIndex: 100}}/>
                <SmallTitle id={"home-empty-favourites-str"} style={{margin: 0, zIndex: 200, color: Colours.secondaryText, textAlign: "center"}}>{Str.emptyFavourites()}</SmallTitle>
            </div>
            <FlexFiller weight={2}/>
            <PrimaryButton id={"search-courses-empty-favourites-btn"} component={Link} to="/courses">{Str.search_courses()}</PrimaryButton>
        </div>
    );
};

export default HomeScreenCourseListView;
