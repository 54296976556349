// https://chir.ag/projects/name-that-color/

const Palette = Object.freeze({
    iceberg: "#D1E8F0",
    silver: "#CCCCCC",
    gray: "#808080",
    mercury: "#e8e8e8",
    emperor: "#555555",
    concrete: "#ffffff",
    gallery: "#eeeeee",
    black: "#000000",
    white: "#FFFFFF",
    purple: "#5503C5",
    lightGraphite: "#5F6369",
    darkGraphite: "#53565B",
    limeade: "#509500",
    alto: "#D3D3D3",
    selectedTab: "#706CB2",
    ucas_lilac: "#706CB2",
    ucas_dark_purple: "#54497F",
    ucas_purple: "#54497F",
    ucas_midnight: "#2C296A",
    ucas_dark_grey: "#132433",
    ucas_magenta: "#ED2881",
    ucas_peppermint: "#15BCBC"
});

export default Palette;