import * as Str from "../strings/Str";
import {CSSProperties} from "react";

export enum SavingState {
    saving = "saving",
    saved = "saved"
}

export const SavingIndicator = ({state, style}: { state: SavingState, style: CSSProperties }) => {
    if (state == null) return null;
    return (
        <div style={{background: "#e8e8e8", color: "#000", borderRadius: "0 0 16px 16px", opacity: 0.7, ...style}}>
            <p style={{margin: "12px 16px"}}>{state === SavingState.saving ? Str.saving() : Str.saved()}</p>
        </div>
    );
};