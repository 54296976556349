import {HomeScreenLinkCard} from "../../model/HomeScreenItem";
import {CSSProperties} from "react";
import Dimens from "../../theme/Dimens";
import PlainLink from "../../components/PlainLink";
import {DeepLinkUtil} from "../../util/DeepLink";
import {ClickablePaper} from "grantfairy-web-common";
import {KeyboardArrowRight} from "@mui/icons-material";
import {Title} from "../../views/Text";

const LinkCardView = ({card}: { card: HomeScreenLinkCard }) => {
    const cardStyle: CSSProperties = {
        height: "100%",
        background: card.backgroundColor,
        display: "flex",
        flexDirection: card.orientation === "Vertical" ? "column" : "row",
        justifyContent: "space-between",
        alignItems: card.orientation === "Vertical" ? "flex-start" : "center",
        padding: Dimens.DoubleMargin,
        gap: Dimens.StandardMargin,
        boxSizing: "border-box"
    };
    return (
        <PlainLink to={DeepLinkUtil.deepLinkFromUrl(card.link)?.toWebappUrl()}>
            <ClickablePaper style={{width: "100%", height: "100%"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                <div style={cardStyle}>
                    <Title style={{color: card.titleColor, margin: 0}}>{card.title}</Title>
                    <div style={{display: "flex", alignItems: "center", gap: 0, color: card.buttonColor}}>
                        <p>{card.buttonText}</p>
                        <KeyboardArrowRight/>
                    </div>
                </div>
            </ClickablePaper>
        </PlainLink>
    );
};

export default LinkCardView;
