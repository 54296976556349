import {StringDictionary} from "../../types/StringDictionary";
import {FormAnswerType, FormItem, ShowIfRule} from "./FormItem";
import _ from "lodash";

/**
 * Filter items to the ones which are currently visible, based on their show-if rules
 * @param itemsToFilter {FormItem[]} The item which are to be filtered
 * @param allItems {FormItem[]} Every item in the form, a show-if rule might depend on an item which isn't currently being considered
 * @param answers {{}}
 * @return {FormItem[]}
 */
export const filterShowingItems = (itemsToFilter: FormItem[], allItems: FormItem[], answers: StringDictionary): FormItem[] => {
    return itemsToFilter.filter(item => isShowIfSatisfied(item, allItems, answers));
};

/**
 *
 * @param item {FormItem} item to check
 * @param allItems {FormItem[]} all items in the form
 * @param answers {{}} answers
 * @returns {boolean} true if the show_if rule is satisfied
 */
export const isShowIfSatisfied = (item: FormItem, allItems: FormItem[], answers: StringDictionary): boolean => {
    const showIf = item.show_if;
    if (showIf == null) {
        return true;
    }
    return isShowIfRuleSatisfied(showIf, allItems, answers);
};

/**
 *
 * @param rule {{id: string, answers: string[]}} The rule to check
 * @param allItems {FormItem[]} all items in the form
 * @param answers {{}} answers
 * @returns {boolean} true if the show_if rule is satisfied
 */
export const isShowIfRuleSatisfied = (rule: ShowIfRule, allItems: FormItem[], answers: StringDictionary): boolean => {
    const dependsOnItem = allItems.filter(x => x.id === rule.id)[0];
    if (dependsOnItem == null) {
        return false;
    }
    const allowed = rule.answers;
    const actual = answers[rule.id];
    const thisShowIfSatisfied = allowed.indexOf(actual) !== -1;
    if (!thisShowIfSatisfied) return false;
    //If the rule is satisfied make sure the thing we depend on is also satisfied
    return isShowIfSatisfied(dependsOnItem, allItems, answers);
};

export type FormQuestionAnswerPair = { question: string, answer: FormAnswerType };

export interface FormQuestionAnswerDictionary {
    [key: string]: FormAnswerType;
}

export const modifyRecordEntry = (records: FormQuestionAnswerPair[][], index: number, questionID: string, answer: FormAnswerType): FormQuestionAnswerPair[][] => {
    const newRecords = [...records];
    const oldRecord = newRecords[index];
    const answersDic = formQuestionAnswerPairsToDictionary(oldRecord);
    answersDic[questionID] = answer;
    newRecords[index] = formQuestionAnswerDictionaryToPairs(answersDic);
    return newRecords;
};

export const convertRecordAnswersToDictionary = (answer: FormAnswerType, allAnswers: FormQuestionAnswerDictionary): FormQuestionAnswerDictionary[] => {
    const result: FormQuestionAnswerDictionary[] = [];
    try {
        const answersRaw: FormQuestionAnswerPair[][] = JSON.parse(answer!);
        answersRaw.forEach(a => {
            //a is an array of {question, answer} objects, need it to be question: answer dic
            //Use all answers as a base to allow show-ifs to reference a question outside the record
            const dic: FormQuestionAnswerDictionary = _.cloneDeep(allAnswers);
            result.push(formQuestionAnswerPairsToDictionary(a, dic));
        });
    } catch (e) {
    }
    return result;
};

export const formQuestionAnswerPairsToDictionary = (answers: FormQuestionAnswerPair[], base: FormQuestionAnswerDictionary = {}): FormQuestionAnswerDictionary => {
    const result = base;
    answers.forEach(pair => {
        result[pair.question] = pair.answer;
    });
    return result;
};

export const formQuestionAnswerDictionaryToPairs = (dictionary: FormQuestionAnswerDictionary): FormQuestionAnswerPair[] => {
    const result: FormQuestionAnswerPair[] = [];
    Object.keys(dictionary).forEach(question => result.push({question, answer: dictionary[question]}));
    return result;
};
