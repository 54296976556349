import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import {FormSection, FormSubsection} from "../../util/form/FormSection";
import * as actions from "../actions";
import * as reducers from "./mainSectionReducers";

it("course list gets populated", () => {
    const serverResult = {isGroupedByProvider: true, providers: ["a", "b", "c"]};
    const reducerResult = reducers.courseSearchResult([], actions.gotCourseList({success: true, result: serverResult}));
    expect(reducerResult).toEqual({state: ApiResultState.Success, payload: serverResult});
});

it("course favourites list gets populated", () => {
    const courses = ["a", "b", "c"];
    const result = reducers.courseFavourites({}, actions.gotCourseFavouritesList({success: true, result: {courses}}));
    expect(result).toEqual({state: ApiResultState.Success, payload: courses});
});

it("Course details gets new details", () => {
    const id = "testUID";
    const testResult = {success: true, result: {course: {id}}};
    const action = actions.gotCourseDetails(id, testResult);
    const actual = reducers.courseDetails({}, action);
    const expected = {[id]: {state: ApiResultState.Success, payload: {id}}};
    expect(actual).toEqual(expected);
});

it("Course details becomes loading", () => {
    const uid = "testUID";
    const action = actions.requestedCourseDetails(uid);
    const actual = reducers.courseDetails({}, action);
    const expected = {[uid]: {state: ApiResultState.Loading}};
    expect(actual).toEqual(expected);
});

it("Course details handles error result", () => {
    const uid = "testUID";
    const testError = "this is an error";
    const testResult = {success: false, error: testError};
    const action = actions.gotCourseDetails(uid, testResult);
    const actual = reducers.courseDetails({}, action);
    const expected = {[uid]: {state: ApiResultState.Error, error: testError}};
    expect(actual).toEqual(expected);
});

it("Course details handles invalid result", () => {
    const uid = "testUID";
    const testResult = {success: true};
    const action = actions.gotCourseDetails(uid, testResult);
    const actual = reducers.courseDetails({}, action);
    const expected = {[uid]: {state: ApiResultState.Error}};
    expect(actual).toEqual(expected);
});

it("funding wishlist gets populated sorted", () => {
    const payload = {
        scholarships: [{uid: 1, deadline_end: 50}, {uid: 2, deadline_end: ""}, {uid: 3, deadline_end: 30}, {uid: 4, deadline_end: 90}]
    };
    const expected = [{uid: 3, deadline_end: 30}, {uid: 1, deadline_end: 50}, {uid: 4, deadline_end: 90}, {uid: 2, deadline_end: ""}];
    const result = reducers.fundingWishlist({}, actions.gotFundingList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: expected});
});

it("funding applied gets populated sorted", () => {
    const payload = {
        appliedList: [{uid: 1, deadline_end: 50}, {uid: 2, deadline_end: ""}, {uid: 3, deadline_end: 30}, {uid: 4, deadline_end: 90}]
    };
    const expected = [{uid: 3, deadline_end: 30}, {uid: 1, deadline_end: 50}, {uid: 4, deadline_end: 90}, {uid: 2, deadline_end: ""}];
    const result = reducers.fundingAppliedList({}, actions.gotFundingList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: expected});
});

it("funding success gets populated sorted", () => {
    const payload = {
        successList: [{uid: 1, deadline_end: 50}, {uid: 2, deadline_end: ""}, {uid: 3, deadline_end: 30}, {uid: 4, deadline_end: 90}]
    };
    const expected = [{uid: 3, deadline_end: 30}, {uid: 1, deadline_end: 50}, {uid: 4, deadline_end: 90}, {uid: 2, deadline_end: ""}];
    const result = reducers.fundingSuccessList({}, actions.gotFundingList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: expected});
});

it("funding deleted gets populated sorted", () => {
    const payload = {
        deletedList: [{uid: 1, deadline_end: 50}, {uid: 2, deadline_end: ""}, {uid: 3, deadline_end: 30}, {uid: 4, deadline_end: 90}]
    };
    const expected = [{uid: 3, deadline_end: 30}, {uid: 1, deadline_end: 50}, {uid: 4, deadline_end: 90}, {uid: 2, deadline_end: ""}];
    const result = reducers.fundingDeletedList({}, actions.gotFundingList({success: true, result: payload}));
    expect(result).toEqual({state: ApiResultState.Success, payload: expected});
});

it("Scholarship details gets new details", () => {
    const uid = "testUID";
    const version = "2";
    const scholarship = {uid, version, name: "test"};
    const testResult = {success: true, result: {scholarship}};
    const action = actions.gotScholarshipDetails(uid, version, testResult);
    const actual = reducers.scholarshipDetails({}, action);
    const expected = {testUID2: makeSuccessResult(scholarship)};
    expect(actual).toEqual(expected);
});

it("scholarship details becomes loading", () => {
    const uid = "testUID";
    const version = "2";
    const action = actions.requestedScholarshipDetails(uid, version);
    const actual = reducers.scholarshipDetails({}, action);
    const expected = {[uid + version]: {state: ApiResultState.Loading}};
    expect(actual).toEqual(expected);
});

it("Parses application form sections with variables", () => {
    const rawForm = `{
  "sections": [
    {
      "name": "Personal Details",
      "subsections": [
        {
          "name": "Nationality",
          "items": [
            {
              "type": "radio",
              "id": "personal_details-nationality-nationality",
              "question": "Nationality",
              "required": true,
              "options": "$nationality"
            }
          ]
        },
        {
          "name": "Contact Details",
          "items": [
            {
              "type": "heading",
              "text": "Home Address"
            }
          ]
        }
      ]
    },
    {
      "name": "Experience / Employment",
      "subsections": [
        {
          "name": "Experience / Employment",
          "items": [
            {
              "type": "heading",
              "text": "Current Role"
            },
            {
              "type": "radio",
              "id": "experienceemployment-experienceemployment-do_you_have_any_relevant_work_ex",
              "question": "Do you have any relevant work experience to support your application?",
              "required": true,
              "options": [
                "Yes",
                "No"
              ]
            },
            {
              "type": "records",
              "question": "Work Experience",
              "addMessage": "Tap to add an experience",
              "id": "experienceemployment-experienceemployment-workexp",
              "required": true,
              "show_if": {
                "id": "experienceemployment-experienceemployment-do_you_have_any_relevant_work_ex",
                "answers": [
                  "Yes"
                ]
              },
              "items": [
                {
                  "type": "radio",
                  "id": "experienceemployment-experienceemployment-workexp-full_timepart_time",
                  "question": "Full time / Part time",
                  "required": false,
                  "options": [
                    "Full time",
                    "Part time"
                  ]
                },
                {
              "type": "radio",
              "id": "personal_details-nationality-nationality",
              "question": "Nationality",
              "required": true,
              "options": "$nationality"
            }
              ]
            }
          ]
        }
      ]
    }
  ],
  "variables": [
    {
      "name": "$nationality",
      "items": [
        "UK national",
        "Zambian",
        "Zimbabwean"
      ]
    }
  ]
}`;

    const result = {success: true, result: {form: rawForm}};

    const reduced = reducers.applicationFormSections({}, actions.gotApplicationInfo(result));

    const expected = [
        {
            "name": "Personal Details",
            "subsections": [
                {
                    "name": "Nationality",
                    "items": [{
                        "type": "radio",
                        "id": "personal_details-nationality-nationality",
                        "question": "Nationality",
                        "required": true,
                        "options": ["UK national", "Zambian", "Zimbabwean"]
                    }]
                },
                {
                    "name": "Contact Details",
                    "items": [
                        {"type": "heading", "text": "Home Address"}
                    ]
                }
            ]
        },
        {
            "name": "Experience / Employment",
            "subsections": [
                {
                    "name": "Experience / Employment",
                    "items": [
                        {"type": "heading", "text": "Current Role"},
                        {
                            "type": "radio",
                            "id": "experienceemployment-experienceemployment-do_you_have_any_relevant_work_ex",
                            "question": "Do you have any relevant work experience to support your application?",
                            "required": true,
                            "options": ["Yes", "No"]
                        },
                        {
                            "type": "records",
                            "question": "Work Experience",
                            "addMessage": "Tap to add an experience",
                            "id": "experienceemployment-experienceemployment-workexp",
                            "required": true,
                            "show_if": {"id": "experienceemployment-experienceemployment-do_you_have_any_relevant_work_ex", "answers": ["Yes"]},
                            "items": [
                                {
                                    "type": "radio",
                                    "id": "experienceemployment-experienceemployment-workexp-full_timepart_time",
                                    "question": "Full time / Part time",
                                    "required": false,
                                    "options": ["Full time", "Part time"]
                                },
                                {
                                    "type": "radio",
                                    "id": "personal_details-nationality-nationality",
                                    "question": "Nationality",
                                    "required": true,
                                    "options": ["UK national", "Zambian", "Zimbabwean"]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    expected.forEach(section => {
        Object.setPrototypeOf(section, FormSection.prototype);
        section.subsections.forEach(subsection => {
            Object.setPrototypeOf(subsection, FormSubsection.prototype);
        });
    });

    expect(reduced).toEqual({state: ApiResultState.Success, payload: expected});
});

it("Parses application form answers", () => {
    const answers = [
        {
            "question_id": "additional_information-additional_information-disability",
            "answer": "You are deaf or have a serious hearing impediment"
        },
        {
            "question_id": "additional_information-additional_information-do_you_have_any_criminal_convict",
            "answer": "No"
        },
        {
            "question_id": "additional_information-additional_information-do_you_have_any_dependants",
            "answer": "No dependants"
        },
        {
            "question_id": "additional_information-additional_information-do_you_have_caring_responsibilities",
            "answer": "Prefer not to say"
        },
        {
            "question_id": "additional_information-additional_information-have_you_been_in_care",
            "answer": "Yes"
        }
    ];
    const expected = {
        "additional_information-additional_information-disability": "You are deaf or have a serious hearing impediment",
        "additional_information-additional_information-do_you_have_any_criminal_convict": "No",
        "additional_information-additional_information-do_you_have_any_dependants": "No dependants",
        "additional_information-additional_information-do_you_have_caring_responsibilities": "Prefer not to say",
        "additional_information-additional_information-have_you_been_in_care": "Yes"
    };

    const result = {success: true, result: {answers}};
    const reduced = reducers.applicationFormAnswers({}, actions.gotApplicationInfo(result));
    expect(reduced).toEqual({state: ApiResultState.Success, payload: expected});
});

it("Parses application form document types", () => {

    const form = {
        "documentTypes": [
            {
                "id": "personal_statement",
                "name": "Personal Statement",
                "info": "4000 character limit (including spaces and blank lines)",
                "required": true,
                "multiple": true,
                "fileTypes": [
                    "txt"
                ],
                "characterLimit": 4000
            },
            {
                "id": "references",
                "name": "References",
                "info": "Add a Referee and we will contact them for a reference. Alternatively, upload a reference if you have one (4000 character limit including spaces and blank lines).",
                "required": false,
                "multiple": true,
                "fileTypes": [
                    "jpg",
                    "jpeg",
                    "png",
                    "pdf"
                ]
            }
        ]
    };
    const result = {success: true, result: {form: JSON.stringify(form)}};

    expect(reducers.applicationFormDocumentTypes({}, actions.gotApplicationInfo(result))).toEqual(makeSuccessResult(form.documentTypes));
});

it("Parses documents", () => {
    const result = {
        success: true,
        result: {
            documents: [
                {
                    documentType: "other",
                    name: "dummy",
                    uploaded: 1553900731,
                    fileType: "pdf"
                },
                {
                    documentType: "transcripts",
                    name: "a_text",
                    uploaded: 1556312510,
                    fileType: "txt"
                },
                {
                    documentType: "personal_statement",
                    name: "another test",
                    uploaded: 1556538003,
                    fileType: "txt"
                }
            ]
        }
    };
    expect(reducers.applicationFormDocuments({}, actions.gotApplicationInfo(result))).toEqual(makeSuccessResult(result.result.documents));
});

it("Deletes documents", () => {
    const documents = [
        {
            documentType: "other",
            name: "dummy",
            uploaded: 1553900731,
            fileType: "pdf"
        },
        {
            documentType: "transcripts",
            name: "a_text",
            uploaded: 1556312510,
            fileType: "txt"
        },
        {
            documentType: "personal_statement",
            name: "another test",
            uploaded: 1556538003,
            fileType: "txt"
        }
    ];
    const newDocuments = [documents[0], documents[2]];
    expect(reducers.applicationFormDocuments(makeSuccessResult(documents), actions.deleteDocument(documents[1]))).toEqual(makeSuccessResult(newDocuments));
});

it("Responds to new form answers", () => {
    const old = {
        q1: "a1",
        q2: "a2",
        q3: "a3"
    };
    const action = actions.setApplicationFormAnswer("q4", "a4");
    const expected = {
        q1: "a1",
        q2: "a2",
        q3: "a3",
        q4: "a4"
    };
    const actual = reducers.applicationFormAnswers(makeSuccessResult(old), action);
    expect(actual).toEqual(makeSuccessResult(expected));
});

it("Responds to updated form answers", () => {
    const old = {
        q1: "a1",
        q2: "a2",
        q3: "a3"
    };
    const action = actions.setApplicationFormAnswer("q2", "a9");
    const expected = {
        q1: "a1",
        q2: "a9",
        q3: "a3"
    };
    const actual = reducers.applicationFormAnswers(makeSuccessResult(old), action);
    expect(actual).toEqual(makeSuccessResult(expected));
});

it("updates user profile", () => {
    const old = {
        name: "Hamzah Malik",
        gender: 0,
        dob: "01/08/1984",
        los: 0,
        parentalIncome: 7,
        religion: 5,
        nationality: 227,
        feeStatus: 1,
        options: ["a", "b", "c"]
    };

    const reduced = reducers.userProfile(makeSuccessResult(old), actions.setProfileField("name", "new name"));

    const expected = {
        name: "new name",
        gender: 0,
        dob: "01/08/1984",
        los: 0,
        parentalIncome: 7,
        religion: 5,
        nationality: 227,
        feeStatus: 1,
        options: ["a", "b", "c"]
    };

    expect(reduced).toEqual(makeSuccessResult(expected));
});

it("parses hasSentVerificationEmail", () => {
    const action = actions.gotLogin({success: true, result: {sentVerificationEmail: false}});
    expect(reducers.hasSentVerificationEmail({}, action)).toEqual(makeSuccessResult(false));
});

it("parses hasSentVerificationEmail", () => {
    const action = actions.gotLogin({success: true, result: {sentVerificationEmail: true}});
    expect(reducers.hasSentVerificationEmail({}, action)).toEqual(makeSuccessResult(true));
});

it("hasSentVerificationEmail never becomes false once true", () => {
    const action = actions.gotLogin({success: true, result: {sentVerificationEmail: false}});
    expect(reducers.hasSentVerificationEmail(makeSuccessResult(true), action)).toEqual(makeSuccessResult(true));
});

it("hasSentVerificationEmail may become true once false", () => {
    const action = actions.gotLogin({success: true, result: {sentVerificationEmail: true}});
    expect(reducers.hasSentVerificationEmail(makeSuccessResult(false), action)).toEqual(makeSuccessResult(true));
});

it("parses needsEmailVerification", () => {
    const action = actions.gotLogin({success: true, result: {verified: true}});
    expect(reducers.needsEmailVerification({}, action)).toEqual(makeSuccessResult(false));
});

it("parses needsEmailVerification", () => {
    const action = actions.gotLogin({success: true, result: {verified: false}});
    expect(reducers.needsEmailVerification({}, action)).toEqual(makeSuccessResult(true));
});

it("parses languages list", () => {
    const languages = [
        {uid: "0", name: "English"},
        {uid: "1", name: "Chinese (Simplified) / 简体中文"}
    ];
    const result = {success: true, result: {languages}};
    const reduced = reducers.languageOptions([], actions.gotSettings(result));
    expect(reduced).toEqual(languages);
});

it("parses settings", () => {
    const pref = {
        pref_contact_emails: "0",
        pref_contact_deadline: "1",
        pref_contact_match: "1",
        pref_contact_update: "0"
    };
    const result = {success: true, result: {pref}};
    const reduced = reducers.settings({}, actions.gotSettings(result));
    expect(reduced.state === ApiResultState.Success);
    const payload = reduced.payload;
    expect(payload.pref_contact_emails).toEqual(false);
    expect(payload.pref_contact_deadline).toEqual(true);
    expect(payload.pref_contact_match).toEqual(true);
    expect(payload.pref_contact_update).toEqual(false);
});

it("updates settings", () => {
    const existing = {
        pref_contact_emails: false,
        pref_contact_deadline: true,
        pref_contact_match: true,
        pref_contact_update: false
    };
    const expected = {
        pref_contact_emails: false,
        pref_contact_deadline: false,
        pref_contact_match: true,
        pref_contact_update: false
    };
    const action = actions.setSettingsField("pref_contact_deadline", false);
    const newSettings = reducers.settings(makeSuccessResult(existing), action);
    expect(newSettings).toEqual(makeSuccessResult(expected));
});

it("can select first document for application", () => {
    const application = {id: 5};
    const state = {5: makeSuccessResult(application)};
    const action = actions.selectDocumentForApplication(5, {documentType: "typec", name: "4", fileType: "gif"}, true);
    const reduced = reducers.universityApplicationsByID(state, action);
    const newDocuments = ["typec/4.gif"];
    expect(reduced["5"].payload.documents).toEqual(newDocuments);
});

it("can select document for application", () => {
    const documents = ["typea/1.png", "typeb/2.png"];
    const application = {id: 5, documents};
    const state = {5: makeSuccessResult(application)};
    const action = actions.selectDocumentForApplication(5, {documentType: "typec", name: "4", fileType: "gif"}, true);
    const reduced = reducers.universityApplicationsByID(state, action);
    const newDocuments = ["typea/1.png", "typeb/2.png", "typec/4.gif"];
    expect(reduced["5"].payload.documents).toEqual(newDocuments);
});

it("can deselect document for application", () => {
    const documents = ["typea/1.png", "typeb/2.png"];
    const application = {id: 5, documents};
    const state = {5: makeSuccessResult(application)};
    const action = actions.selectDocumentForApplication(5, {documentType: "typea", name: "1", fileType: "png"}, false);
    const reduced = reducers.universityApplicationsByID(state, action);
    const newDocuments = ["typeb/2.png"];
    expect(reduced["5"].payload.documents).toEqual(newDocuments);
});

describe("mainSectionReducers showApplicationFormErrors", () => {
    it("should set show errors to true", () => {
        const action = {type: actions.SET_SHOW_APPLICATION_FORM_ERRORS, applicationID: 1, showErrors: true};
        expect(reducers.showApplicationFormErrors({}, action)).toEqual({"1": true});
    });

    it("should set multiple application ids correctly", () => {
        const action1 = {type: actions.SET_SHOW_APPLICATION_FORM_ERRORS, applicationID: 65468, showErrors: true};
        const action2 = {type: actions.SET_SHOW_APPLICATION_FORM_ERRORS, applicationID: "35432", showErrors: false};

        const state1 = reducers.showApplicationFormErrors({}, action1);
        const state2 = reducers.showApplicationFormErrors(state1, action2);
        expect(state2).toEqual({"65468": true, "35432": false});
    });

    it("should not modify state for actions other than SET_SHOW_APPLICATION_FORM_ERRORS", () => {
        const state = {key: "value", nested: {key: "otherValue"}};
        Object.keys(actions)
            .filter(action => typeof action === "string")
            .filter(action => action !== "SET_SHOW_APPLICATION_FORM_ERRORS")
            .forEach(type => {
                const action = {type, applicationID: 1, showErrors: true};
                expect(reducers.showApplicationFormErrors(state, action)).toEqual(state);
            });
    });
});