import {Paper} from "grantfairy-web-common";
import {useMemo} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import * as actions from "../../redux/actions";
import * as  selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import PropertiesList from "./AccommodationPropertyList";
import Map from "./AccommodationPropertyMap";

const AccommodationFavouritesTab = ({properties, selectedPropertyId = -1, makeUrlForProperty, makeUrlForPropertyDetails = () => {}, checkFave = () => {}, onFave = () => {}}) => {

    //cache the faves as they are. We never stop showing a fave even if it becomes unfaved, we still show it
    const cachedFaves = useMemo(() => properties, []);

    return (
        <div style={{height: "calc(100%)", display: "flex", position: "relative"}} className="noSelect">
            <Map properties={cachedFaves} makeUrlForProperty={makeUrlForProperty} selectedPropertyId={selectedPropertyId} checkFave={checkFave}/>
            <div style={{position: "absolute", right: 16, top: 16, bottom: 16, width: 500, overflowY: "auto"}}>
                <PropertiesList properties={cachedFaves} selectedPropertyId={selectedPropertyId} makeUrlForProperty={makeUrlForProperty}
                                makeUrlForPropertyDetails={makeUrlForPropertyDetails} checkFave={checkFave} onFave={onFave} searchCategory="rooms_favourites"/>
            </div>
        </div>
    );
};

const AccommodationFavouritesTabBlank = ({browseURL = ""}) => (
    <div style={{display: "flex", padding: 64, alignItems: "center", justifyContent: "center"}}>
        <Paper id="myriad-rooms-no-favourites-message" style={{overflowY: "auto", padding: 48, textAlign: "center"}}>
            <Subtitle id="myriad-rooms-no-favourites-str">{Str.no_favourite_properties()}</Subtitle>
            <PrimaryButton id="myriad-rooms-favourites-browse-button" component={Link} to={browseURL} style={{marginTop: 16}}>{Str.browse()}</PrimaryButton>
        </Paper>
    </div>
);

const Container = ({properties, ...props}) => {
    const hasProperties = (properties?.payload ?? []).length > 0;
    return (
        <DataDependant data={properties}>
            <>{hasProperties && <AccommodationFavouritesTab properties={properties?.payload} {...props}/>}</>
            <>{!hasProperties && <AccommodationFavouritesTabBlank {...props}/>}</>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {propertyID}) => ({
    properties: selectors.accommodationFavourites(state),
    makeUrlForProperty: property => "/accommodation/favourites/" + property.uid,
    makeUrlForPropertyDetails: property => "/accommodation/favourites/" + property.uid + "/details",
    selectedPropertyId: propertyID,
    browseURL: "/accommodation/search",
    checkFave: property => (selectors.accommodationFavourites(state)?.payload ?? []).map(e => e.uid).includes(property.uid)
});

export const mapDispatchToProps = dispatch => ({
    onCampusChange: campus => {
        if (campus && campus.id) {
            dispatch(actions.setAccommodationCampus(campus.id));
        }
    },
    onFave: (property, fave) => dispatch(actions.setAccommodationFave(property, fave))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {propertyID} = useParams();
    return (<Connected propertyID={propertyID} />);
};

export default WrappedAndConnected;