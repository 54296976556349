const iconName = (p: number): number => {
    if (p === 0) return 0;
    if (p < 15) return 10;
    if (p < 25) return 20;
    if (p < 35) return 30;
    if (p < 45) return 40;
    if (p < 55) return 50;
    if (p < 65) return 60;
    if (p < 75) return 70;
    if (p < 85) return 80;
    if (p < 100) return 90;
    return 100;
};

export const PercentIcon = ({percent, size}: { percent: number, size: number }) => {
    return <img alt={percent + " percent"} src={require("../assets/percentIcons/" + iconName(percent) + ".png")} style={{width: size, height: size}}/>;
};
