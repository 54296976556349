import * as Str from "../strings/Str";
import Colours from "../theme/Colours";
import {Tooltip, Zoom} from "@mui/material";

export const OptionalityIndicator = ({markWithAsterisk, showOptionalLabel}: { markWithAsterisk: boolean, showOptionalLabel: boolean }) => {
    if (markWithAsterisk) {
        return <Tooltip followCursor={true} title="Required" placement="top" TransitionComponent={Zoom} arrow><span color={Colours.AsteriskColour} style={{color: Colours.AsteriskColour}}> * </span></Tooltip>;
    } else if (showOptionalLabel) {
        return <span style={{color: Colours.OptionalColour, fontWeight: 600}}>&nbsp;{Str.optional_question()}</span>;
    }
    return null;
};
