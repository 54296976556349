import {ellipsizeText, formatAsMoney, getTextForMoneyRange, isValidEmail, moneyfy, parseMoney} from "./StringUtil";

it("Moneyfy", () => {
    expect(moneyfy("4")).toEqual("£4");
    expect(moneyfy("40")).toEqual("£40");
    expect(moneyfy("400")).toEqual("£400");
    expect(moneyfy("4000")).toEqual("£4,000");
    expect(moneyfy("40000")).toEqual("£40,000");
    expect(moneyfy("400000")).toEqual("£400,000");
    expect(moneyfy("4000000")).toEqual("£4,000,000");
    expect(moneyfy("40000000")).toEqual("£40,000,000");
    expect(moneyfy("400000000")).toEqual("£400,000,000");
});

it("Formats currency", () => {
    expect(formatAsMoney("USD", 400, true)).toEqual("$400.00");
    expect(formatAsMoney("USD", 400, false)).toEqual("$400");
    expect(formatAsMoney("GBP", 123.45, true)).toEqual("£123.45");
    expect(formatAsMoney("GBP", 123.45, false)).toEqual("£123");
    expect(formatAsMoney("GBP", 123.99, false)).toEqual("£124");
});

it("Formats money range", () => {
    expect(getTextForMoneyRange(100, 400, "USD", false)).toEqual("$100 - $400");
    expect(getTextForMoneyRange(100, 400, "USD", true)).toEqual("$100.00 - $400.00");
    expect(getTextForMoneyRange(400, 400, "USD", false)).toEqual("$400");
});

it("parses money", () => {
    expect(parseMoney("gibberish")).toEqual(0);
    expect(parseMoney("£4")).toEqual(4);
    expect(parseMoney("£")).toEqual(0);
    expect(parseMoney("£5,003")).toEqual(5003);
    expect(parseMoney("£1,234,567")).toEqual(1234567);
    expect(parseMoney("£1,234,567.34")).toEqual(1234567.34);
    expect(parseMoney("67.34")).toEqual(67.34);
    expect(parseMoney("£1 000 000")).toEqual(1000000);
    expect(parseMoney("£1 000,000")).toEqual(1000000);
});

it("Validates email", () => {
    expect(isValidEmail("a@a.com")).toEqual(true);
    expect(isValidEmail("absge@a.com")).toEqual(true);
    expect(isValidEmail("absge.com.com@a.com.comcom")).toEqual(true);
    expect(isValidEmail("absge-com@a.com.comcom")).toEqual(true);
    expect(isValidEmail("bob99@google.comcom")).toEqual(true);
    expect(isValidEmail("alt.dl-e85kea@google.com")).toEqual(true);
    expect(isValidEmail("alt.dl-0e85kea@yopmail.com")).toEqual(true);

    expect(isValidEmail("missingAt.com")).toEqual(false);
    expect(isValidEmail("missingDot@com")).toEqual(false);
    expect(isValidEmail("invalidChar$@google.com")).toEqual(false);
});
it("Adds ellipsis to text when needed", () => {
    expect(ellipsizeText("hello world", 5)).toEqual("he...");
    expect(ellipsizeText("hello world", 10)).toEqual("hello w...");
    expect(ellipsizeText("hello world", 11)).toEqual("hello world");
    expect(ellipsizeText("hello world", 1000)).toEqual("hello world");
    expect(ellipsizeText("hello world", 3)).toEqual("...");
    expect(ellipsizeText("hello world", 2)).toEqual("...");
    expect(ellipsizeText("hello world", 1)).toEqual("...");
    expect(ellipsizeText("hello world", 0)).toEqual("...");
    expect(ellipsizeText("hello world", -1)).toEqual("...");
    expect(ellipsizeText("hello world", -20)).toEqual("...");
});