import {Paper} from "grantfairy-web-common";
import {connect} from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {ACCOMMODATION_APPLICATION_STATUS, linkForSubsection} from "../../Applications";
import NumberIcon from "../../components/NumberIcon";
import {PercentIcon} from "../../components/PercentIcon";
import {MasterDetailView} from "../../components/TabContainer";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import FormMenu from "../main/applications/FormMenu";
import ApplicationList from "./ApplicationList";

const ACCOMMODATION_BOOKING_SECTIONS = Object.freeze({
    Form: "form",
    Drafts: "drafts",
    Sent: "sent"
});

const SectionsListItem = ({item, onSelect, isSelected}) => (
    <div style={{padding: "4px 16px", minHeight: 64, borderBottom: "1px solid #CCC", display: "flex", alignItems: "center", background: isSelected ? Colours.RowHighlight : ""}}
         onClick={onSelect} className="noSelect">
        <Subtitle style={{margin: 0, flex: 1}}>{item.title}</Subtitle>
        {item.percent != null && <PercentIcon size={64} percent={item.percent}/>}
        {item.number != null && <div style={{padding: 12}}><NumberIcon size={40} number={item.number} color="black"/></div>}
    </div>
);

const SectionsList = ({items, selectedItem, setSelectedItem}) => {
    return items.map(item => <SectionsListItem key={item.title} item={item} onSelect={() => setSelectedItem(item.sectionId)} isSelected={selectedItem === item.sectionId}/>);
};

const ApplicationsTab = ({formSections, applicationID, formCompletionPercent = 0, answers , makeLinkForSubsection = () => {}, draftApplicationCount = 0, sentApplicationCount = 0, sectionId = "0", setSectionId = (id, nav) => {}}) => {

    const nav = useNavigate();

    const items = [
        {
            sectionId: ACCOMMODATION_BOOKING_SECTIONS.Form,
            title: Str.acc_app_form(),
            component: <FormMenu answers={answers} applicationID={applicationID} formSections={formSections} makeLinkForSubsection={makeLinkForSubsection}/>,
            percent: formCompletionPercent
        },
        {sectionId: ACCOMMODATION_BOOKING_SECTIONS.Drafts, title: Str.draft_apps(), number: draftApplicationCount, component: <ApplicationList status={ACCOMMODATION_APPLICATION_STATUS.draft}/>},
        {sectionId: ACCOMMODATION_BOOKING_SECTIONS.Sent, title: Str.sent_apps(), number: sentApplicationCount, component: <ApplicationList status={ACCOMMODATION_APPLICATION_STATUS.sent}/>}
    ];

    const viewingItem = items.find(i => i.sectionId === sectionId);

    const localSetSectionId = (sectionId) => {
        setSectionId(sectionId, nav);
    };

    return (
        <MasterDetailView listView={(
            <Paper style={{flex: 1, overflowY: "auto"}}>
                <SectionsList items={items} selectedItem={sectionId} setSelectedItem={localSetSectionId}/>
            </Paper>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "auto"}}>
                {viewingItem?.component}
            </Paper>
        )} showDetailView={true}/>
    );
};

const Container = ({formSections, applicationID, answers, ...props}) => (
    <DataDependant data={formSections}>
        <ApplicationsTab formSections={formSections?.payload} applicationID={applicationID} answers={answers?.payload ?? {}} {...props}/>
    </DataDependant>
);

export const mapStateToProps = (state, {sectionId, applicationID}) => ({
    applicationID: selectors.accommodationApplicationByID(state, applicationID),
    formSections: selectors.accApplicationFormSections(state),
    answers: selectors.applicationFormAnswers(state),
    formCompletionPercent: selectors.accommodationFormCompletionPercent(state),
    makeLinkForSubsection: subsection => linkForSubsection(subsection, "/accommodation/applications/form/"),
    draftApplicationCount: selectors.accommodationApplicationCountWithStatus(state, ACCOMMODATION_APPLICATION_STATUS.draft),
    sentApplicationCount: selectors.accommodationApplicationCountWithStatus(state, ACCOMMODATION_APPLICATION_STATUS.sent),
    sectionId: sectionId ?? ACCOMMODATION_BOOKING_SECTIONS.Form
});

export const mapDispatchToProps = () => {
    return {
        setSectionId: (id, history) => history(`/accommodation/applications/${id}`)
    };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {sectionId} = useParams();
    return (<Connected sectionId={sectionId} />);
};

export default WrappedAndConnected;