import {HTMLAttributes} from "react";
import {EnglishProviderUrlSelected} from "../model/AnalyticsEvents";
import {useAnalytics} from "../services/analytics/AnalyticsProvider";
import Button from "@mui/material/Button";

export enum EnglishProviderWebsiteButtonType {
    EnglishReferral = "english-referral",
}

type EnglishProviderWebsiteButtonProps = {
    link: string
    buttonType: EnglishProviderWebsiteButtonType,
    providerID: string
}

export const EnglishProviderWebsiteButton = ({link, buttonType, providerID, ...props}: EnglishProviderWebsiteButtonProps & HTMLAttributes<HTMLButtonElement>) => {
    const analytics = useAnalytics();
    const logEnglishProviderWebsiteClicked = () => {
        analytics.logEvent(new EnglishProviderUrlSelected(providerID, link, buttonType));
    };

    return (<Button href={link} {...props} color="secondary" sx={{textTransform: "none"}} onClick={logEnglishProviderWebsiteClicked}></Button>);
};