import {Title} from "@mantine/core";
import {Paper} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as Applications from "../../Applications";
import * as Assets from "../../Assets";
import {PrimaryButton} from "../../components/Buttons";
import {UG_COURSES_ENABLED} from "../../FeatureFlags";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {mapProfileFormItems} from "../../Profile";
import DataDependant from "../../views/DataDependant";
import {FormItemView} from "../../views/form/FormItemView";
import Colours from "../../theme/Colours";
import * as FormUtil from "../../util/form/FormUtil";

const ProfileTab = ({form, answers, onSetAnswer, onDone}) => {
    let items = form[0].subsections[0].items;
    const parsedAnswers = Applications.parseAnswers(Object.values(answers.payload ?? null));

    const itemsToShow = FormUtil.filterShowingItems(items, items, parsedAnswers);
    const [validate, setValidate] = useState(false);
    const history = useNavigate();

    if (!UG_COURSES_ENABLED) {
        items = items.filter(e => {
            if (e.id != null) {
                return !e.id.endsWith("study_level");
            }
            return true;
        });
    }
    mapProfileFormItems(items);

    const hasInvalidAnswers = () => {
        const invalids = items.filter(e => {
            if (!e.required) return false;
            const mappedAnswer = parsedAnswers[e.id];
            return !e.numberAnswered(mappedAnswer, parsedAnswers);
        });
        return invalids.length > 0;
    };

    const onSave = () => {
        if (hasInvalidAnswers()) {
            setValidate(true);
        } else {
            onDone(history);
        }
    };

    const handleSetAnswer = (item, answer) => {
        // Check to prevent invalidating a previously valid profile (e.g. blanking out the name)
        if (item.type === "radio" || !item.required || item.numberAnswered(answer, parsedAnswers)) {
            onSetAnswer(item.id, answer);
        }
    };

    return (
        <DataDependant data={answers}>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center"}} className="noSelect">
                <Paper
                    style={{padding: "16px 32px", margin: 32, width: "40cm", maxWidth: "90vw", height: "calc(100% - 96px)", overflowY: "auto"}}>
                    <div style={{display: "flex", alignItems: "flex-start", gap: 32, flexWrap: "wrap"}}>
                        <div style={{textAlign: "center", flex: "0 0 calc(40% - 32px)", display: "flex", position: "relative", alignItems: "center", justifyContent: "center", paddingTop: 32}}>
                            <img alt="logo" src={require("../../" + Assets.PROFILE_MOBILE_IMAGE)}
                                 style={{position: "absolute", justifyContent: "center", textAlign: "center", width: 500, top: 40}}/>
                            <div style={{
                                backgroundColor: Colours.ProfileInfoPanelBackground,
                                textAlign: "start",
                                justifyContent: "center",
                                width: 400,
                                borderRadius: 12,
                                paddingTop: 225,
                                paddingBottom: 32,
                                paddingLeft: 16,
                                paddingRight: 16,
                                marginTop: 200
                            }}>
                                <Title style={{color: Colours.ProfileInfoPanelTitle, fontSize: 48, fontFamily: "Oswald", paddingBottom: 4}}>UCAS INTERNATIONAL IN YOUR POCKET</Title>
                                <p style={{whiteSpace: "pre-line", color: Colours.ProfileInfoPanelText, paddingBottom: 32}}>Scan the QR code below from your mobile device to download the Android and
                                    iOS app.</p>
                                <div style={{textAlign: "center"}}>
                                    <img style={{width: 200}} alt="" src={require("../../" + Assets.DOWNLOAD_QR)}/>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: "0 0 calc(60% - 32px)"}}>
                            {itemsToShow.map(item => (<FormItemView
                                                        formName="Profile" 
                                                        handleLink={()=>{}} 
                                                        validate={validate} 
                                                        item={item} 
                                                        answer={parsedAnswers[item.id]} 
                                                        allAnswers={parsedAnswers} 
                                                        allItems={items}
                                                        key={item.id} 
                                                        setAnswer={a => handleSetAnswer(item, a)} 
                                                        onViewFile={()=>{}} 
                                                        onDeleteFile={()=>{}} 
                                                        onUploadFile={()=>{}}
                                                    />
                                            ))}
                            <div style={{display: "flex", justifyContent: "center", padding: 16}}>
                                <PrimaryButton style={{minWidth: 200}} onClick={onSave}>{Str.save()}</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </DataDependant>
    );
};

const Container = ({answers, ...props}) => {
    useEffect(() => {
        props.onOpenProfile();
    }, [props.onOpenProfile]);
    return (
        <DataDependant data={props.form}>
            <ProfileTab
                form={props.form.payload}
                onSetAnswer={props.onSetAnswer}
                onDone={props.onDone}
                answers={answers}
            />
        </DataDependant>);
};

export const mapStateToProps = (state, {optionsOpen}) => {
    return {
        answers: selectors.userProfile(state),
        form: selectors.profileForm(state),
        optionsOpen
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        onOpenProfile: () => {
            dispatch(actions.requestProfile());
        },
        onSetAnswer: (questionID, answer) => {
            dispatch(actions.setProfileField(questionID, answer));
        },
        onDone: (history) => {
            dispatch(actions.showToast(Str.profile_saved()));
            history("/courses");
        },
        onSave: () => dispatch(actions.showToast(Str.saved())),
    };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;
