import * as MIAN from "./MIANUtil";

it("Interleaving ignores spare mians", () => {
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const mians = ["m1", "m2", "m3", "m4", "m5", "m6", "m7"];
    const itemMapper = e => "i" + e;
    const mianMapper = e => "m" + e;
    const expected = ["i1", "i2", "i3", "i4", "i5", "i6", "mm1", "i7", "i8", "i9", "i10", "i11", "i12", "mm2", "i13", "i14", "i15", "i16", "i17", "i18", "mm3", "i19"];
    expect(MIAN.zipWithMians(items, mians, itemMapper, mianMapper)).toEqual(expected);
});

it("Interleaving can handle not enough mians", () => {
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const mians = ["m1", "m2"];
    const itemMapper = e => "i" + e;
    const mianMapper = e => "m" + e;
    const expected = ["i1", "i2", "i3", "i4", "i5", "i6", "mm1", "i7", "i8", "i9", "i10", "i11", "i12", "mm2", "i13", "i14", "i15", "i16", "i17", "i18", "i19"];
    expect(MIAN.zipWithMians(items, mians, itemMapper, mianMapper)).toEqual(expected);
});

it("Interleaving can handle no mians", () => {
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const mians = [];
    const itemMapper = e => "i" + e;
    const mianMapper = e => "m" + e;
    const expected = ["i1", "i2", "i3", "i4", "i5", "i6", "i7", "i8", "i9", "i10", "i11", "i12", "i13", "i14", "i15", "i16", "i17", "i18", "i19"];
    expect(MIAN.zipWithMians(items, mians, itemMapper, mianMapper)).toEqual(expected);
});

it("Interleaving can handle no items", () => {
    const items = [];
    const mians = ["m1", "m2"];
    const itemMapper = e => "i" + e;
    const mianMapper = e => "m" + e;
    const expected = [];
    expect(MIAN.zipWithMians(items, mians, itemMapper, mianMapper)).toEqual(expected);
});