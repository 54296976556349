import {Paper} from "grantfairy-web-common";
import {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {MasterDetailView} from "../../components/TabContainer";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import * as MIANUtil from "../../util/MIANUtil";
import MIANView from "../../views/MIANView";
import {SmallTitle} from "../../views/Text";
import CourseDetails from "./CourseDetails";
import {SecondaryButton} from "../../components/Buttons";
import {BasicCourseInfo, CourseSearchResult, SelectedCourse} from "../../model/Course";
import {ApiResult} from "../../util/ApiResult";
import {MIAN} from "../../model/MIAN";
import Styles from "../../theme/Styles";
import CourseCardItem from "../../views/CourseCard";
import Dimens from "../../theme/Dimens";
import {useControlColourName, useSecondaryColour} from "../../theme/Theme";
import {useNavigate, useParams} from "react-router-dom";
import {Heading} from "../../views/Text";
import {Overlay} from "@mantine/core";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import DataDependant from "../../views/DataDependant";
import {UG_COURSES_ENABLED} from "../../FeatureFlags";
import {FilterOption, SubjectSearchOption, iPopularSubject} from "../../views/CourseSearchInterfaces";
import {CourseSearchHeader} from "../../views/CourseSearchHeader";
import {HeadingWithFlash} from "../../components/HeadingWithFlash";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import {currentUser} from "../../util/FirebaseUtil";

interface CourseListProps {
    courseCount: number;
    courses: BasicCourseInfo[];
    selectedCourse: SelectedCourse;
    onViewMore?: () => void;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    checkFave: (course: BasicCourseInfo) => boolean;
    makeApplyUrlForCourse: (courseId: string) => string;
    showProviderInfo: boolean;
    mians: MIAN[];
    isGrid: boolean;
    logClick: (courseId: string) => void;
}

const CourseList = ({courseCount, courses, selectedCourse, onViewMore, makeUrlForCourse, onFave, checkFave, makeApplyUrlForCourse, showProviderInfo, mians, isGrid, logClick}: CourseListProps) => {
    const needsViewMore = courseCount > (courses?.length ?? 0);
    const courseMapper = (course: BasicCourseInfo) => {
        const isSelected = course.id === selectedCourse.id;
        const isFave = checkFave(course);
        return (
                <CourseCardItem key={course.id} course={course} isSelected={isSelected} isFave={isFave} makeUrlForCourse={makeUrlForCourse}
                                onFave={fave => onFave(course, fave)} makeApplyUrlForCourse={makeApplyUrlForCourse} showProviderInfo={showProviderInfo} onClick={logClick}/>
        );
    };
    return (
        <>
            {MIANUtil.zipWithMians(courses, mians, courseMapper, mian => <MIANView mian={mian}/>)}
            {needsViewMore && (
                <div style={{padding: Dimens.StandardMargin, borderBottom: Styles.standardBorder, textAlign: "center"}}>
                    <SecondaryButton style={{margin: "0 auto"}} onClick={onViewMore}>{Str.view_more()}</SecondaryButton>
                </div>
            )}
        </>
    );
};

interface SearchResultListProps {
    searchResult: CourseSearchResult;
    selectedCourse: SelectedCourse;
    onViewMore: (providerId: string) => void;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    checkFave: (course: BasicCourseInfo) => boolean;
    mians: MIAN[];
    makeApplyUrlForCourse: (courseId: string) => string;
    isGrid: boolean;
    logClick: (courseId: string) => void;
}

const SearchResultList = ({searchResult, selectedCourse, makeUrlForCourse, onFave, checkFave, mians, onViewMore, makeApplyUrlForCourse, logClick}: SearchResultListProps) => {
    if (!searchResult.courses) {
        return (<NoResults />);
    }
    return (
        <div style={{flexDirection:"column"}}>
            <p style={{margin: "16px 16px 0px 16px", color: Colours.secondaryText, fontSize: 18, fontWeight: 600, textAlign: "right"}}>{Str.number_courses(searchResult.numberOfCourses)}</p>
            <div style={{flexDirection:"row", flexWrap: "wrap", justifyContent: "space-between", display:"flex"}}>
                {searchResult.courses && <CourseList checkFave={checkFave} courses={searchResult.courses} courseCount={searchResult.courses?.length ?? 0} makeUrlForCourse={makeUrlForCourse}
                            onFave={onFave} selectedCourse={selectedCourse} makeApplyUrlForCourse={makeApplyUrlForCourse} showProviderInfo={true} mians={mians} isGrid={true} logClick={logClick}/>}
            </div>
        </div>
    );
};

const NoResults = () => (
    <div style={{textAlign: "center", padding: 32}}>
        <SmallTitle>{Str.no_search_results_try_diff()}</SmallTitle>
    </div>
);

interface PassthroughProps {
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    checkFave: (course: BasicCourseInfo) => boolean;
    searchQuery?: string;
    setSearchQuery: (val: string) => void;
    setProviderId: (val?: string) => void;
    allProviders: FilterOption<string>[];
    currentProviderId?: string;
    studyModes: FilterOption<string>[];
    studyMode?: string;
    setStudyMode: (val?: string) => void;
    studyLevels: FilterOption<number>[];
    studyLevel?: number;
    setStudyLevel: (val?: number) => void;
    makeApplyUrlForCourse: (courseId: string) => string;
    englishLanguageQualificationsPresent: boolean;
    filterByEnglishLanguageRequirements: boolean;
    setFilterByEnglishLanguageRequirements: (val: boolean) => void;
    subjectSearchOptions: [SubjectSearchOption];
    setSubjectSearchOptions: (seed?: string) => void;
}

type CoursesTabProps = PassthroughProps & {
    popularSubjects: iPopularSubject[];
    searchResult: ApiResult<CourseSearchResult>;
    mians: MIAN[];
    viewingCourseId: string;
    courseDetailsComponent: ReactNode;
}

const CoursesTab = ({
                        popularSubjects,
                        searchResult,
                        mians,
                        viewingCourseId,
                        courseDetailsComponent,
                        makeUrlForCourse,
                        onFave,
                        checkFave,
                        searchQuery,
                        setSearchQuery,
                        setProviderId,
                        allProviders,
                        currentProviderId,
                        studyModes,
                        studyMode,
                        setStudyMode,
                        studyLevels,
                        studyLevel,
                        setStudyLevel,
                        makeApplyUrlForCourse,
                        englishLanguageQualificationsPresent,
                        filterByEnglishLanguageRequirements,
                        setFilterByEnglishLanguageRequirements,
                        subjectSearchOptions,
                        setSubjectSearchOptions
                    }: CoursesTabProps) => {

    const history = useNavigate();
    const clearViewingCourse = () => {
        history("/courses");
    };
    const selectedCourse: SelectedCourse = {id: viewingCourseId};
    const [localSearch, setLocalSearch] = useState("");
    const [localResults, setLocalResults] = useState<SubjectSearchOption[]>([]);
    const secondaryColour = useSecondaryColour();
    const [searchBorderColour, setSearchBorderColour] = useState(Colours.UnfocusedBorder as string);
    const [showReturnToTopButton, setShowReturnToTopButton] = useState(false);
    const [courseDetailsNotVisible, setCourseDetailsNotVisible] = useState(true);
    let pgIndex = 1;
    const hasResults: boolean = searchResult.payload != null && "courses" in searchResult.payload;

    if (!UG_COURSES_ENABLED) {
        studyLevels = studyLevels.filter(e => e.id !== 1);
        pgIndex = 0;
    }

    const studyModesSorted = [...studyModes];
    studyModesSorted.sort((e, f) => e.label.localeCompare(f.label));

    useEffect(() => {

        const scrollElement = document.getElementById("scrollableContent");
        const handleScrollButtonVisibility = () => {
            if (scrollElement != null) {
                if (scrollElement.scrollTop > 120) {
                    setShowReturnToTopButton(true);
                } else {
                    setShowReturnToTopButton(false);
                }
            }
        };

        if (scrollElement != null) {
            scrollElement.addEventListener("scroll", handleScrollButtonVisibility);
        }

        return () => {
            if (scrollElement != null) {
                scrollElement.removeEventListener("scroll", handleScrollButtonVisibility);
            }
        };
    }, []);

    const scrollToTop = () => {
        const scrollElement = document.getElementById("scrollableContent");
        if (scrollElement != null) {
            scrollElement.scrollTo(0, 0);
        }
    };

    const sendSearch = (subjectName) => {
        handleOnBlur();
        setSearchQuery(subjectName);
        setLocalResults([]);
    };

    // TODO: MOD-3612
    window.onpopstate = () => {
        if (searchQuery && searchQuery.trim() !== "") {
            sendSearch("");
            clearViewingCourse();
        } else if (currentProviderId) {
            setProviderId("");
        }
    };

    useEffect(() => {
        setLocalResults(subjectSearchOptions);
    }, [subjectSearchOptions]);

    const searchKeyDown = e => {
        if (e.key === "Enter") sendSearch(localSearch);
    };

    const allProvidersSorted = [...allProviders];
    allProvidersSorted.sort((a, b) => a.label.localeCompare(b.label));

    const currentProvider = allProviders.filter(e => e.id === currentProviderId)[0];
    const currentStudyMode = studyModes.filter(e => e.id === studyMode)[0];
    const currentStudyLevel = studyLevels.filter(e => e.id === studyLevel)[0] ?? studyLevels[pgIndex];

    const controlColourName = useControlColourName();

    const localSetStudyLevel = (level) => {
        setStudyLevel(level);
        clearViewingCourse();
    };

    const localSetProviderId = (id) => {
        setProviderId(id);
        clearViewingCourse();
    };

    const localSetStudyMode = (studyMode) => {
        setStudyMode(studyMode);
        clearViewingCourse();
    };

    const localSetFilterByEnglishLanguageRequirements = (isChecked) => {
        setFilterByEnglishLanguageRequirements(isChecked);
        clearViewingCourse();
    };

    const subjectSearchHandler = (e) => {
        setSearchQuery(e);
    };

    const onSearchHandler = (e) => {
        setLocalSearch(e);
        if (e.length >= 3) {
            setSubjectSearchOptions(e);
        }
    };

    const formatResultHandler = (item) => (
        <span style={{display: "block", textAlign: "left"}}>{item.name}</span>
    );

    const handleOnFocus = () => {
        setSearchBorderColour(secondaryColour);
    };

    const handleOnBlur = () => {
        setSearchBorderColour(Colours.UnfocusedBorder);
    };

    const handleOnClear = () => {
        sendSearch("");
    };

    const clickLogHandler = (courseId) => {
        if (searchQuery && searchQuery.trim() !== "" && searchResult.payload?.courses) {
            setCourseDetailsNotVisible(false);
            const user = currentUser();
            const courseRanking = (searchResult.payload.courses.findIndex(course => course.id === courseId) + 1).toString() ?? "";
            const courseUrl = makeUrlForCourse(courseId ?? "");
            new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user?.uid ?? "", "courses", searchQuery, courseRanking.toString(), courseId, courseUrl));
        }
    };

    return (
        <MasterDetailView fixedHeight={false} showDetailView={viewingCourseId != null} listView={(
            <>
                <CourseSearchHeader handleOnClear={handleOnClear} handleOnFocus={handleOnFocus} formatResultHandler={formatResultHandler} onSearchHandler={onSearchHandler}
                                    handleOnBlur={handleOnBlur} searchKeyDown={searchKeyDown} localResults={localResults} sendSearch={sendSearch} searchQuery={searchQuery}
                                    searchBorderColour={searchBorderColour} allProvidersSorted={allProvidersSorted} currentProvider={currentProvider}
                                    localSetProviderId={localSetProviderId} currentStudyLevel={currentStudyLevel} localSetStudyLevel={localSetStudyLevel}
                                    studyModes={studyModesSorted} localSetStudyMode={localSetStudyMode} currentStudyMode={currentStudyMode} studyLevels={studyLevels}
                                    englishLanguageQualificationsPresent={englishLanguageQualificationsPresent} controlColourName={controlColourName}
                                    filterByEnglishLanguageRequirements={filterByEnglishLanguageRequirements} localSetFilterByEnglishLanguageRequirements={localSetFilterByEnglishLanguageRequirements}
                                    showResultFilters={hasResults}
                />
                {searchQuery === "" && !currentProviderId && popularSubjects && <PopularSubjectsSection popularSubjects={popularSubjects} subjectSearch={subjectSearchHandler}/>}
                {(searchQuery !== "" || currentProviderId) && searchResult &&
                    <DataDependant data={searchResult}>
                            <>{searchResult.payload && <SearchResultList searchResult={searchResult.payload} selectedCourse={selectedCourse} makeUrlForCourse={makeUrlForCourse}
                                      onFave={onFave} checkFave={checkFave}
                                      mians={mians} onViewMore={setProviderId} makeApplyUrlForCourse={makeApplyUrlForCourse} isGrid={true} logClick={clickLogHandler}/>}</>
                    </DataDependant>}
                {showReturnToTopButton && courseDetailsNotVisible && (
                    <ScrollToTopButton onClick={scrollToTop} id={"not-required"} buttonText={Str.back_to_search()}/>
                )}
            </>
        )} detailView={(
            <Paper style={{height: "calc(90vh - 14rem)", overflowY: "auto", position: "sticky", top: "2rem"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {courseDetailsComponent}
            </Paper>
        )}/>
    );
};

interface PopularSubjectsProps {
    popularSubjects: iPopularSubject[];
    subjectSearch: (val: string) => void;
}

interface PopularSubjectProps {
    subjectSearch: (val: string) => void;
    popularSubject: iPopularSubject;
}

const PopularSubject = (props: PopularSubjectProps) => {
    const [hoverFilter, setHoverFilter] = useState("brightness(100%)");

    const hoverOn = () => {
        setHoverFilter("brightness(75%)");
    };

    const hoverOff = () => {
        setHoverFilter("brightness(100%)");
    };
    const subjectSearchHandler = () => props.subjectSearch(props.popularSubject.subject);
    const subjectImage = "url(" + require(`../../assets/popularSubjects/${props.popularSubject.subject
        .replaceAll("&", "and")
        .replaceAll(",", "")
        .replaceAll(" ", "_").toLowerCase()}.jpeg`) + ")";
    return (
        <Heading style={{
            color: "white",
            minWidth: 200,
            minHeight: 200,
            maxWidth: 200,
            maxHeight: 200,
            margin: Dimens.StandardMargin,
            borderRadius: "1rem",
            backgroundImage: subjectImage,
            flex: 1,
            backgroundSize: "cover",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            cursor: "pointer",
            boxShadow: "0 3px 3px #999",
            filter: hoverFilter
        }}
                 key={props.popularSubject.subject}
                 onClick={subjectSearchHandler}
                 onMouseEnter={hoverOn}
                 onMouseLeave={hoverOff}
        >
            <div style={{position: "relative", width: 200, height: 200, borderRadius: "1rem"}}>
                <div style={{padding: 20, zIndex: 300, position: "relative"}}>
                    {props.popularSubject.subject}
                </div>
                <Overlay
                    gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.70) 5%, rgba(0, 0, 0, 0) 100%)"
                    opacity={0.9}
                    style={{borderRadius: "1rem"}}
                />
            </div>
        </Heading>
    );
};

const PopularSubjectsSection = (props: PopularSubjectsProps) => {
    return (
        <div style={{marginTop: "1rem"}}>
            <HeadingWithFlash title={Str.popular_subjects()} />
            <div style={{justifyContent: "center", display: "flex", flexWrap: "wrap"}}>
                {props.popularSubjects.map(subject => {
                    return <PopularSubject popularSubject={subject} subjectSearch={props.subjectSearch}/>;
                })}
            </div>
        </div>
    );
};

type ContainerProps = PassthroughProps & {
    popularSubjects: ApiResult<iPopularSubject[]>
    searchResult: ApiResult<CourseSearchResult>
    viewingCourseId: string
    mians: ApiResult<MIAN[]>
    tab: string
}

const Container = (props: ContainerProps) => {
    const {searchResult, viewingCourseId, mians, makeUrlForCourse} = props;

    const details = <CourseDetails courseID={viewingCourseId} tab={props.tab} makeUrlForCourse={makeUrlForCourse}/>;
    return <>
        {!UG_COURSES_ENABLED &&
            <div style={{backgroundColor: Colours.HomeScreenBackground, padding: Dimens.HalfMargin, paddingLeft: Dimens.StandardMargin, paddingRight: Dimens.StandardMargin}}>
                <h3>{Str.looking_for_uk_undergraduate_courses()}</h3>
                <p>{Str.currently_myriad_only_displays_postgraduate_courses(Str.app_name())} <a href="https://www.ucas.com" target="_blank">UCAS.com</a> {Str.the_best_place_to_explore_uk_undergraduate_courses("")}</p>
            </div>
        }
        <DataDependant data={props.popularSubjects}>
            <CoursesTab courseDetailsComponent={details} {...props} popularSubjects={props.popularSubjects?.payload ?? []} searchResult={searchResult} mians={mians.payload ?? []}/>
        </DataDependant>
    </>;
};

export const mapStateToProps = (state, {viewingCourseId, tab}) => ({
    searchResult: selectors.courseSearchResult(state),
    viewingCourseId,
    makeUrlForCourse: (id) => `/courses/${id}`,
    checkFave: (course) => selectors.isCourseFavourited(state, course),
    searchQuery: selectors.courseSearchFilters(state)?.searchQuery ?? "",
    mians: selectors.courseMians(state),
    popularSubjects: selectors.popularSubjects(state),
    allProviders: selectors.courseSearchOptions(state)?.providers ?? [],
    englishLanguageQualificationsPresent: selectors.courseSearchOptions(state)?.englishQualificationsPresent ?? false,
    filterByEnglishLanguageRequirements: selectors.courseSearchFilters(state)?.filterByEnglishLanguageRequirements ?? false,
    currentProviderId: selectors.courseSearchFilters(state)?.providerID,
    studyMode: selectors.courseSearchFilters(state)?.studyMode,
    studyModes: selectors.courseSearchOptions(state)?.studyModes ?? [],
    studyLevel: selectors.courseSearchFilters(state)?.levelOfStudy,
    studyLevels: Str.array_study_level().map((label, index) => ({id: index + 1, label})),
    tab,
    makeApplyUrlForCourse: (id) => `/courses/${id}/apply`,
    subjectSearchOptions: selectors.subjectSearchOptions(state)?.list
});

const mapDispatchToProps = (dispatch) => ({
    onFave: (course, fave) => dispatch(actions.setCourseFave(course, fave)),
    setSearchQuery: query => {
        dispatch(actions.setCourseSearchQuery(query));
    },
    setStudyLevel: level => {
        dispatch(actions.setCourseSearchLos(level));
    },
    setProviderId: providerID => {
        dispatch(actions.setCourseSearchProviderId(providerID));
    },
    setStudyMode: mode => {
        dispatch(actions.setCourseSearchStudyMode(mode));
    },
    setFilterByEnglishLanguageRequirements: checkBoxChecked => {
        dispatch(actions.setCourseSearchEnglishLanguageRequirements(checkBoxChecked));
    },
    setSubjectSearchOptions: searchSeed => {
        dispatch(actions.requestSubjectSearchOptions(searchSeed));
    }
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {viewingCourseId, tab} = useParams();
    return (<Connected viewingCourseId={viewingCourseId} tab={tab}/>);
};

export default WrappedAndConnected;