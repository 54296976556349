import {Dialog} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import {useBasicAlert} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useAlert} from "../../Alerts";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {useControlColour, useControlColourName} from "../../theme/Theme";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import WebButton from "../../views/WebButton";
import {getValueOfScholarship} from "../../util/GetValueOfScholarship";
import ScholarshipDeadline from "./ScholarshipDeadline";
import {currentUser} from "../../util/FirebaseUtil";
import {LearnerClicksReferralButtonEvent} from "../../model/AnalyticsEvents";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";

const makeDescriptionHTML = scholarship => {
    if (scholarship.translation) {
        return scholarship.translation + "<hr/>" + scholarship.details_full;
    } else {
        return scholarship.details_full;
    }
};

const ReportingAlert = ({open, onClose, onReport}) => {

    const [option, setOption] = useState("0");
    const [text, setText] = useState("");
    const [reportErrorAlert, showReportErrorAlert] = useAlert();

    // TODO hardcoded strings?
    const options = ["Web link is broken", "Scholarship is out of date", "Spelling Mistake", "Other"];

    const handleReport = () => {
        if (option === "3") {
            if (text.trim().length > 0) {
                onReport(text);
            } else {
                showReportErrorAlert(Str.please_fill_field(), "", Str.okay());
            }
        } else {
            onReport(options[parseInt(option)]);
        }
    };

    const controlColor = useControlColourName();

    return (
        <Dialog open={open} onClose={onClose} size="md" fullWidth>
            {reportErrorAlert}
            <DialogContent>
                <FormControl>
                    <RadioGroup name="problem" value={option} onChange={e => setOption(e.target.value)}>
                        {options.map((option, index) => <FormControlLabel key={index} value={String(index)} control={<Radio color={controlColor}/>} label={option}/>)}
                    </RadioGroup>
                </FormControl>
                {option === "3" && (
                    <div style={{marginTop: 8}}>
                        <TextField style={{width: "100%"}} variant="outlined" value={text} onChange={e => setText(e.target.value)} multiline rows={3}/>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color={controlColor} onClick={onClose}>{Str.cancel()}</Button>
                <Button color={controlColor} onClick={handleReport}>{Str.send_report()}</Button>
            </DialogActions>
        </Dialog>
    );

};

const Screen = ({scholarship, onClose, onReport}) => {

    const [reporting, setReporting] = useState(false);
    const [alert, showAlert] = useBasicAlert();

    const handleReport = text => {
        setReporting(false);
        onReport(scholarship, text);
        showAlert(Str.report_sent(), Str.report_thank());
    };

    const buttonColor = useControlColourName();
    const highlight = useControlColour();
    const analyticsHandler = () => {
        const userId = currentUser()?.uid ?? "";
        new GoogleAnalyticsService().logEvent(new LearnerClicksReferralButtonEvent("apply_scholarship", userId, scholarship.name, "", scholarship.uid.toString(), Str.apply_on_website(), scholarship.website));
    };

    const amountText = getValueOfScholarship(scholarship);

    return (
        <div style={{padding: 16, display: "flex", flexDirection: "column", height: "calc(100vh - 120px)"}} className="noSelect">
            <ReportingAlert onReport={handleReport} open={reporting} onClose={() => setReporting(false)}/>
            {alert}
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <Subtitle style={{margin: 0}}>{scholarship.name}</Subtitle>
                    <p style={{margin: 0, marginTop: 8}}><i><ScholarshipDeadline deadlineUnix={scholarship.deadline_end} highlightNearDeadline={false}/></i></p>
                </div>
                <div>
                    <p style={{color: highlight, textAlign: "center", width: 100}}>{amountText}</p>
                </div>
            </div>
            <hr/>
            <SanitizedHtml style={{overflowY: "auto", flex: 1}} html={makeDescriptionHTML(scholarship)}/>
            <div style={{display: "flex", width: "100%", marginTop: 16}}>
                <div style={{flex: 1, textAlign: "left"}}>{<Button onClick={() => setReporting(true)} color={buttonColor}>{Str.report_a_problem()}</Button>}</div>
                <div style={{flex: 1, textAlign: "center"}}><WebButton title={Str.apply_on_website()} link={scholarship.website} onClick={analyticsHandler}/></div>
                <div style={{flex: 1, textAlign: "right"}}><Button onClick={onClose} color={buttonColor}>{Str.close()}</Button></div>
            </div>
        </div>
    );
};

const Container = ({result, uid, version, onClose, onReport, getScholarship}) => {
    useEffect(() => {
        if (result == null) {
            getScholarship(uid, version);
        }
    }, [result, uid, version, getScholarship]);
    return (
        <DataDependant data={result}>
            <Screen scholarship={result?.payload} onClose={onClose} onReport={onReport}/>
        </DataDependant>
    );
};

const Popup = ({onClose, ...props}) => (
    <Dialog open={true} maxWidth="lg" fullWidth onBackdropClick={onClose}>
        <div style={{minHeight: 400}}>
            <Container result={props.result} uid={props.uid} version={props.version} onReport={props.onReport} getScholarship={props.getScholarship} onClose={onClose}/>
        </div>
    </Dialog>
);

const mapStateToProps = (state, {uid, version, onClose}) => ({
    result: selectors.scholarshipDetails(state, uid, version),
    uid,
    version,
    onClose
});

const mapDispatchToProps = dispatch => ({
    onReport: (scholarship, text) => dispatch(actions.reportScholarship(scholarship.uid, scholarship.version, text)),
    getScholarship: (uid, version) => dispatch(actions.requestScholarshipDetails(uid, version))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Popup);

export default Connected;
