import {configureStore, Middleware} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {reducers as accommodationReducers} from "./reducers/accommodationReducers";
import {reducers as jobsReducers} from "./reducers/jobsReducers";
import {reducers as mainSectionReducers} from "./reducers/mainSectionReducers";
import {reducers as universityReducers} from "./reducers/universityReducers";
import rootSaga from "./sagas";
import * as englishLanguageArticlesSlice from "./slices/englishLanguageArticles";
import * as englishLanguageConfigSlice from "./slices/englishLanguageConfig";
import * as homeScreenItemsSlice from "./slices/homeScreenItems";
import * as formSectionExpandedStateSlice from "./slices/formSectionExpandedState";
import * as notificationSettingsFormItemsSlice from "./slices/settings/notificationSettingsFormItems";
import * as notificationSettingsAnswersSlice from "./slices/settings/notificationSettingsAnswers";
import {createBrowserHistory} from "history";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore<any, any, ReadonlyArray<Middleware>>({
    reducer: {
        ...mainSectionReducers,
        ...accommodationReducers,
        ...jobsReducers,
        ...universityReducers,
        englishLanguageArticles: englishLanguageArticlesSlice.reducer,
        englishLanguageConfig: englishLanguageConfigSlice.reducer,
        homeScreenItems: homeScreenItemsSlice.reducer,
        formSectionExpandedState: formSectionExpandedStateSlice.reducer,
        notificationSettingsFormItems: notificationSettingsFormItemsSlice.reducer,
        notificationSettingsAnswers: notificationSettingsAnswersSlice.reducer,
    },
    middleware: getDefaultMiddleware => [sagaMiddleware, ...getDefaultMiddleware({
        serializableCheck: false
    })]
});

export type AppState = ReturnType<typeof store.getState>;

export const init = () => {
    sagaMiddleware.run(rootSaga);
};
