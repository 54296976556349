import {FormAnswerType, TextEntry} from "../../util/form/FormItem";
import {MouseEventHandler, useEffect, useState} from "react";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import {FormControl, TextField} from "@mui/material";
import {FormAnswerSetter, InvalidAnswerLabel, UnansweredQuestionLabel} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {calculateFieldWidth, FormFieldWidth} from "../../util/form/FormFieldWidth";

const FormTextView = ({
                          item,
                          startAnswer,
                          handleLink,
                          answer,
                          setAnswer,
                          multiLine,
                          validate
                      }: { item: TextEntry, startAnswer: () => void, handleLink: MouseEventHandler<HTMLAnchorElement>, answer: FormAnswerType, setAnswer: FormAnswerSetter, multiLine: boolean, validate: boolean }) => {

    const [text, setText] = useState<FormAnswerType>("");

    useEffect(() => setText(answer), [answer]);

    const sendAnswer = () => {
        if (text != null) {
            setAnswer(text);
        }
    };

    const isUnanswered = text == null || text === "";
    const isError = validate && item.required && !item.numberAnswered(text);

    const fieldWidth = calculateFieldWidth(item.field_width ?? FormFieldWidth.Full, []);

    return <div id={item.id}>
        <FormQuestionText item={item} handleLink={handleLink}/>
        {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
        <FormQuestionInfoText item={item} handleLink={handleLink}/>
        <FormControl margin="dense" style={fieldWidth.getCSS("400 16px Roboto", 56)}>
            <TextField
                error={isError}
                onFocus={startAnswer}
                onBlur={sendAnswer}
                variant="outlined"
                color="secondary"
                size="small"
                onChange={e => setText(e.target.value)}
                value={text}
                rows={5}
                multiline={multiLine}
                inputProps={{
                    maxLength: item.maxLength,
                }}
            />
        </FormControl>
        {isError && isUnanswered && <UnansweredQuestionLabel/>}
        {isError && !isUnanswered && <InvalidAnswerLabel/>}
    </div>;
};

export default FormTextView;
