import {Paper} from "grantfairy-web-common";
import {useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import PlainLink from "../../components/PlainLink";
import {MasterDetailView} from "../../components/TabContainer";
import {LearnerClicksSearchResultEvent, LearnerViewsSearchResultEvent} from "../../model/AnalyticsEvents";
import * as actions from "../../redux/actions";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import {currentUser} from "../../util/FirebaseUtil";
import DataDependant from "../../views/DataDependant";
import FavouriteButton from "../../views/FavouriteButton";
import SearchBar from "../../views/SearchBar";
import {Subtitle} from "../../views/Text";
import UniversityDetailsScreen from "./UniversityDetailsScreen";
import ProviderCard from "../../views/ProviderCard";
import LazyLoad from "react-lazyload";

const UniversityListItem = ({university, isSelected, isFave, onFave, makeUrlForUniversity, universityIdSelector, logClickQuery}) => {
    const style = {padding: 16, borderBottom: "1px solid #CCC", display: "flex", alignItems: "center", background: isSelected ? Colours.RowHighlight : ""};
    const logClickQueryHandler = () => logClickQuery(university);
    return (
        <PlainLink to={makeUrlForUniversity(university)} id={universityIdSelector(university)} onClick={logClickQueryHandler}>
            <div style={style} className="noSelect">
                <div className="card-header-image lazyload-wrapper">
                    {/** TODO hardcoded string **/}
                    <LazyLoad overflow><img alt="University Logo" style={{width: 40, height: 40}} src={university.logo}/></LazyLoad>
                    <div style={{flex: 1, marginLeft: 16}}>
                        <Subtitle style={{margin: 0}}>{university.name}</Subtitle>
                    </div>
                    <FavouriteButton favourited={isFave} onFave={onFave} itemID={university.id}/>
                </div>
            </div>
        </PlainLink>
    );
};

const UniversityList = ({universities, selectedUniversityID, makeUrlForUniversity, favouriteIDs, onFave, searchCategory, searchTerm = ""}) => {
    const isGrid = true;
    if (universities.length === 0) {
        return <Subtitle style={{textAlign: "center"}}>{Str.no_search_results()}</Subtitle>;
    }

    const logClickQuery = (provider) => {
        const user = currentUser();
        const universitySearchRanking = universities.findIndex((p) => (p.id === provider.id)) + 1;
        const universityUrl = makeUrlForUniversity(provider);
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid ?? "", searchCategory, searchTerm, universitySearchRanking.toString() ?? "", provider.id, universityUrl));
    };

    if (isGrid) {
        return( 
                universities.map(e => <ProviderCard isFave={favouriteIDs.includes(e.id)} university={e} isSelected={e.id === selectedUniversityID}
                    makeUrlForUniversity={makeUrlForUniversity} onFave={fave => onFave(e, fave)} universityIdSelector={universityIdSelector} logClickQuery={logClickQuery}/>)
            );        
    }
    return universities.map(e => <UniversityListItem isFave={favouriteIDs.includes(e.id)} university={e} isSelected={e.id === selectedUniversityID}
                                                 makeUrlForUniversity={makeUrlForUniversity} onFave={fave => onFave(e, fave)} universityIdSelector={universityIdSelector} logClickQuery={logClickQuery}/>);
};

const universityIdSelector = (university) => {
    return "lnk-university-" + university.id;
};

const UniversityListScreen = ({universities, selectedUniversityID=-1, selectedUniversity = null, favouriteIDs, searchCategory, makeUrlForUniversity = () => {}, onCoursesView = (providerID, nav) => {}, onFave = () => {}, getProviderDetails = (providerID) => {}}) => {
    const [search, setSearch] = useState("");
    const nav = useNavigate();
    const query = search.trim().toLowerCase();

    const localOnCoursesView = (providerID) => {
        onCoursesView(providerID, nav);
    };

    if (selectedUniversityID != -1 && selectedUniversity == null) {
        getProviderDetails(selectedUniversityID);
    }
    
    const filtered = universities.filter(e => {
        if (query !== "") {
            const user = currentUser();
            new GoogleAnalyticsService().logEvent(new LearnerViewsSearchResultEvent(user.uid ?? "", "universities", query));
        }
        return e.name.toLowerCase().includes(query);
    });

    return (
        <MasterDetailView showDetailView={selectedUniversity != null} listView={(
            <>
                <SearchBar setSearch={setSearch} search={search}/>
                <div style={{overflowY: "auto", marginTop: 16, flexDirection:"row", flexWrap: "wrap", justifyContent: "space-around", display:"flex"}}>
                    <UniversityList onFave={onFave} searchCategory={searchCategory} favouriteIDs={favouriteIDs} universities={filtered} selectedUniversityID={selectedUniversityID}
                                    makeUrlForUniversity={makeUrlForUniversity} searchTerm={query}/>
                </div>
            </>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "scroll"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* @ts-ignore */}
                <DataDependant data={selectedUniversity}>
                    {/* @ts-ignore */}
                    <UniversityDetailsScreen university={selectedUniversity?.payload} onCoursesView={localOnCoursesView}/>
                </DataDependant>
            </Paper>
        )}/>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProviderDetails: (universityID) => {
            dispatch(actions.requestCourseProviderDetails(universityID));
        }
    };
};

const Connected = connect(() => {}, mapDispatchToProps)(UniversityListScreen);

export default Connected;