import {PrimaryButton} from "../components/Buttons";
import * as Str from "../strings/Str";

const WebButton = ({link, title = Str.website(), ...props}) => {
    if (link == null || link.trim().length === 0) {
        return <PrimaryButton disabled>{title}</PrimaryButton>;
    }
    if (link.indexOf("http") !== 0) {
        // noinspection HttpUrlsUsage
        link = "http://" + link;
    }
    return <PrimaryButton href={link} target="_blank" {...props}>{title}</PrimaryButton>;
};

export default WebButton;