import {ApiCallState, apiCallStateUtil, httpUtil} from "grantfairy-web-common";
import * as Constants from "../Constants";
import * as FirebaseUtil from "./FirebaseUtil";
import {ServerApiResult} from "./ApiResult";
import {StringDictionary} from "../types/StringDictionary";

const API_V2_URL = process.env.REACT_APP_API_V2_URL;

type QueryParams = StringDictionary;

/**
 * HTTP GET request
 *
 * @param endpoint {String} Endpoint on the API without leading slash e.g. 'courses/2020'
 * @param queryParams {{}} key-value pairs become query parameters
 * @returns {Promise} 
 */
export const getRequest = (endpoint: string, queryParams: QueryParams = {}): Promise<ServerApiResult> => apiRequest(endpoint, httpUtil.HTTPMethod.GET, queryParams, {});

/**
 * HTTP POST request
 *
 * @param endpoint {String} Endpoint on the API without leading slash e.g. 'courses/2020'
 * @param body {{}} key-value pairs become the body
 * @returns {Promise}
 */
export const postRequest = (endpoint: string, body: QueryParams = {}): Promise<ServerApiResult> => apiRequest(endpoint, httpUtil.HTTPMethod.POST, {}, body);

/**
 * HTTP DELETE request
 *
 * @param endpoint {String} Endpoint on the API without leading slash e.g. 'courses/2020'
 * @param body {{}} key-value pairs become the body
 * @returns {Promise} 
 */
export const deleteRequest = (endpoint: string, body: QueryParams = {}): Promise<ServerApiResult> => apiRequest(endpoint, httpUtil.HTTPMethod.DELETE, {}, body);

/**
 * @returns {{}} Key-value pairs representing some headers which should be sent with every request
 */
const getHeaders = (): QueryParams => {
    const headers = {};
    headers["GF-Client-Version"] = Constants.VERSION;
    headers["GF-Client-Device-OS"] = Constants.OS;
    return headers;
};

export const queryParamsToMap = (params: QueryParams): Map<string, string> => {
    const paramsMap = new Map<string, string>();
    Object.keys(params).forEach(key => {
        const value = params[key];
        paramsMap.set(key, value);
    });
    return paramsMap;
};

/**
 *
 * @param endpoint {String} Endpoint on the API without leading slash e.g. 'courses/2020'
 * @param method {String} HTTP method e.g. GET or POST
 * @param queryParams {{}} key-value pairs become query parameters
 * @param body {{}} key-value pairs become body
 * @returns {Promise} 
 */
const apiRequest = (endpoint: string, method: httpUtil.HTTPMethod, queryParams: QueryParams, body: QueryParams): Promise<ServerApiResult> => {
    return rawApiRequest(endpoint, method, queryParams, body).then(result => {
        //We're going to create an object that looks like an api v1 result. That way we don't need to change the rest of the code base
        try {
            const payload = JSON.parse(result.response);
            return {
                success: result.responseCode === 200,
                result: payload,
                error: payload.error,
                errorTitle: payload.errorTitle,
                canRetry: payload.canRetry
            };
        } catch (e) {
            if (result.responseCode == 401) {
                // Somehow the user has been logged out, we can't recover from this here (we need V3 api) so just restart the app
                window.location.reload();
            }
            return {
                success: false,
                error: "Could not parse result"
            };
        }
    });
};

/**
 * Fetches auth credentials and default headers, then makes an API request
 * Returns the raw HTTPResult that comes back
 */
const rawApiRequest = (endpoint: string, method: httpUtil.HTTPMethod, queryParams: QueryParams, body: QueryParams): Promise<httpUtil.HTTPResult> => {
    const headers = getHeaders();
    if (!FirebaseUtil.isSignedIn()) {
        return new Promise(onDone => onDone({responseCode: 401, response: ""}));
    }

    return FirebaseUtil.getApiToken().then(token => {
        if (token == null) {
            FirebaseUtil.logout();
            //Take the user back through the login experience
            window.location.reload();
            return new Promise(onDone => onDone({responseCode: 401, response: ""}));
        }
        headers["Authorization"] = "Bearer " + token;
        return httpUtil.httpRequest(API_V2_URL + endpoint, method, queryParamsToMap(queryParams), queryParamsToMap(body), queryParamsToMap(headers));
    });
};

export const parseApiResult = <RawResultType, PayloadType>(result: httpUtil.HTTPResult, deserializer: (json: RawResultType) => PayloadType | null): ApiCallState<PayloadType> => {
    try {
        const json = JSON.parse(result.response);
        if (result.responseCode !== 200) {
            return apiCallStateUtil.makeError({errorTitle: json.errorTitle, errorMessage: json.error});
        }

        const deserialized = deserializer(json);
        if (deserialized != null) {
            return apiCallStateUtil.makeSuccessFromPayload<PayloadType>(deserialized);
        }
    } catch (e) {

    }
    return apiCallStateUtil.makeError({errorTitle: "Error", errorMessage: "Could not parse result"});
};

export const getAndParse = <RawResultType, PayloadType>(endpoint: string, queryParams: QueryParams, deserializer: (json: RawResultType) => PayloadType | null): Promise<ApiCallState<PayloadType>> => {
    return rawApiRequest(endpoint, httpUtil.HTTPMethod.GET, queryParams, {}).then(rawResult => {
        return parseApiResult(rawResult, deserializer);
    });
};
