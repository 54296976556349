import GoogleMapReact from "google-map-react";
import {useEffect, useRef} from "react";
import PlainLink from "../../components/PlainLink";
import * as Constants from "../../Constants";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import {currentUser} from "../../util/FirebaseUtil";

const makeIcon = (isSelected, isFave) => {
    if (isFave && isSelected) return "house_fave_highlighted";
    if (isFave && !isSelected) return "house_fave";
    if (!isFave && isSelected) return "house_highlighted";
    return "house";
};

const PropertyMarker = ({isSelected, isFave, link, lat, lng, selectedCampusId, propertyId}) => {
    const icon = makeIcon(isSelected, isFave);
    const zIndex = isSelected ? 2000 : 1;
    const logClickHandler = () => {
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user?.uid ?? "", "rooms_map", selectedCampusId ?? "", "", propertyId, link));
    };
    return (
        <PlainLink to={link} style={{zIndex, position: "relative"}} onClick={logClickHandler}>
            {/* TODO hardcoded strings */}
            <img alt="Property" src={require("../../assets/" + icon + ".png")} style={{width: 40, height: 40, zIndex}}/>
        </PlainLink>
    );
};

// TODO hardcoded string
const CampusMarker = ({lat, lng}) => <img alt="Campus" src={require("../../assets/uni_map_marker.png")} style={{width: 40, height: 40, zIndex: 3000}}/>;

const Map = ({properties, selectedPropertyId, campus = null, makeUrlForProperty, checkFave, directionInfo = null, showDirectionInfo = false}) => {

    const mapRef = useRef();

    const options = (maps) => ({
        zoomControlOptions: {
            position: maps.ControlPosition.LEFT_CENTER,
            style: maps.ZoomControlStyle.SMALL
        },
        fullscreenControl: false,
        rotateControl: true,
        scaleControl: true
    });

    const loadedMap = ({map}) => {
        // @ts-ignore
        const bounds = new window.google.maps.LatLngBounds();
        let leastLat = null;
        let leastLng = null;
        let mostLat = null;
        let mostLng = null;
        properties.forEach(({lat, lng}) => {
            if (leastLat == null || lat < leastLat) {
                leastLat = lat;
            }
            if (leastLng == null || lng < leastLng) {
                leastLng = lng;
            }
            if (mostLat == null || lat > mostLat) {
                mostLat = lat;
            }
            if (mostLng == null || lng > mostLng) {
                mostLng = lng;
            }
        });
        // @ts-ignore
        bounds.extend(new window.google.maps.LatLng(leastLat, leastLng));
        // @ts-ignore
        bounds.extend(new window.google.maps.LatLng(mostLat, mostLng));
        map.fitBounds(bounds);
        mapRef.current = map;
        zoomCurrentProperty();
        addPropertyDirections();
    };

    const zoomCurrentProperty = () => {
        const map = mapRef.current;
        const selected = properties.filter(e => e.uid === selectedPropertyId)[0];
        if (map) {
            if (selected && properties.length !== 0) {
                // @ts-ignore
                map.panTo(new window.google.maps.LatLng(selected.lat, selected.lng));
                // @ts-ignore
                if (map.getZoom() < 14) {
                    // @ts-ignore
                    map.setZoom(16);
                }
            } else if (properties.length === 0) {
                // @ts-ignore
                map.panTo(new window.google.maps.LatLng(campus.lat, campus.lng));
                // @ts-ignore
                map.setZoom(12);
            }
        }
    };

    const addPropertyDirections = () => {
        if (showDirectionInfo && directionInfo && mapRef.current) {
            const map = mapRef.current;

            // @ts-ignore
            map.data.forEach(feature => map.data.remove(feature));

            const addLine = (geometry, color) => {
                const geojson = {
                    geometry,
                    properties: {
                        color
                    },
                    type: "Feature"
                };
                // @ts-ignore
                map.data.addGeoJson(geojson);
            };

            // @ts-ignore
            addLine(directionInfo.walking.geometry, "#4caf50");
            // @ts-ignore
            addLine(directionInfo.driving.geometry, "#f44336");
            // @ts-ignore
            addLine(directionInfo.cycling.geometry, "#3f51b5");

            // @ts-ignore
            map.data.setStyle(feature => {
                const color = feature.getProperty("color");
                return {fillColor: color, strokeColor: color, strokeWeight: 6, strokeOpacity: 0.7};
            });
        }
    };

    useEffect(zoomCurrentProperty, [selectedPropertyId]);

    useEffect(addPropertyDirections, [directionInfo, mapRef, showDirectionInfo]);

    return <div style={{height: "100%", width: "100%"}}>
        <GoogleMapReact
            bootstrapURLKeys={{key: Constants.GOOGLE_MAPS_API_KEY}}
            defaultCenter={{
                lat: 54,
                lng: -1.5
            }}
            defaultZoom={7}
            onGoogleApiLoaded={loadedMap}
            options={options}
            yesIWantToUseGoogleMapApiInternals>

            {properties.map(e => (
                <PropertyMarker link={makeUrlForProperty(e)} lat={e.lat} lng={e.lng} key={e.uid} isSelected={e.uid === selectedPropertyId} isFave={checkFave(e)} selectedCampusId={campus?.id ?? ""}
                                propertyId={e.uid}/>
            ))}

            {/* @ts-ignore */}
            {campus !== null && <CampusMarker lat={campus.lat} lng={campus.lng}/>}

        </GoogleMapReact>
    </div>;
};

export default Map;
