import * as Str from "../strings/Str";

export const moneyfy = (str: string) => formatAsMoney("GBP", parseInt(str), false);

export const formatAsMoney = (currencyCode: string, amount: number, showFractionalAmount: boolean = true) => {
    const formatter = new Intl.NumberFormat([], {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "narrowSymbol",
        // Setting to 0 means no decimals, setting to undefined means the standard decimals for that currency, e.g. in GBP everything is to 2 decimal points
        maximumFractionDigits: showFractionalAmount ? undefined : 0,
        minimumFractionDigits: showFractionalAmount ? undefined : 0
    });
    return formatter.format(amount);
};

export const getTextForMoneyRange = (min: number, max: number, currency: string, showFractionalAmount: boolean): string => {
    const formattedMin = formatAsMoney(currency, min, showFractionalAmount);
    const formattedMax = formatAsMoney(currency, max, showFractionalAmount);
    if (min === max) {
        return formattedMin;
    }
    return Str.range(formattedMin, formattedMax);
};
/**
 * @param str A string like £40,500
 * @returns {Number} the number of £, eg 40500
 */
export const parseMoney = (str: string): number => {
    const cleaned = str.replace(/,/g, "").replace(/£/g, "").replace(/ /g, "");
    const result = parseFloat(cleaned);
    if (isNaN(result)) return 0;
    return result;
};

export const isValidEmail = (str: string): boolean => {
    const regex = /[A-z0-9-.'_]+?@[A-z0-9-.'_]+\.(\w)+/;
    return regex.test(str);
};

export const ellipsizeText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) return text;
    if (maxLength <= 3) return "...";
    return text.substring(0, maxLength - 3) + "...";
};
