import {FileEntry, FormAnswerType} from "../../util/form/FormItem";
import {useControlColourName, useControlColour} from "../../theme/Theme";
import {OutlinedButton, useFileUpload} from "grantfairy-web-common";
import moment from "moment";
import UniversitySpecificQuestionIndicator from "./UniversitySpecificQuestionIndicator";
import * as Str from "../../strings/Str";
import {PrimaryButton} from "../../components/Buttons";
import {Button, Icon} from "@mui/material";
import {UnansweredQuestionLabel} from "./FormItemView";
import FormQuestionText from "./FormQuestionText";
import FormQuestionInfoText from "./FormQuestionInfoText";
import {MouseEventHandler} from "react";

interface FileAnswerProps {
    item: FileEntry;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    onView: (originalName: string, fileName: string) => void;
    onDelete: (fileName: string) => void;
    onUpload: (file: string, originalName: string) => void;
    startAnswer: () => void;
    validate: boolean;
}

const FormFileView = ({item, handleLink, answer, onView, onDelete, onUpload, startAnswer, validate}: FileAnswerProps) => {

    const parsed = (() => {
        try {
            return JSON.parse(answer!);
        } catch (e) {
            return null;
        }
    })();

    const paletteColour = useControlColour();
    const buttonColour = useControlColourName();

    const hasUploaded = parsed != null;

    const fileTypesString = item.fileTypes.join(", ");
    const fileTypeAccept = item.fileTypes.map(e => "." + e).join(",");

    const handleUpload = file => onUpload(file, file.name);

    const handleDelete = () => onDelete(parsed.fileName);

    const [filePicker, openFilePicker] = useFileUpload(fileTypeAccept, handleUpload);

    const uploadTime = parsed == null ? "" : moment.unix(parsed.uploaded).format("DD/MM/YY HH:mm");

    const hasError = answer === "" || answer == null;

    const showError = item.required && hasError && validate;

    const colour = showError ? "#f00" : paletteColour;
    const filePickerHandler = () => {
        startAnswer();
        openFilePicker();
    };

    return <div id={item.id}>
        <FormQuestionText item={item} handleLink={handleLink}/>
        {item.isUniversitySpecific && item.fieldProviderName != null && <UniversitySpecificQuestionIndicator providerName={item.fieldProviderName}/>}
        <FormQuestionInfoText item={item} handleLink={handleLink}/>
        {filePicker}
        {!hasUploaded && (
            <div style={{borderRadius: 16, marginTop: 16, padding: 16, border: "1px solid " + colour, textAlign: "center"}}>
                <p style={{color: "#444"}}>{Str.accepted_file_types(fileTypesString)}</p>
                <OutlinedButton buttonColor={buttonColour} onClick={filePickerHandler} style={{width: "100%", marginTop: 8}}>{Str.select_file()}</OutlinedButton>
            </div>
        )}
        {hasUploaded && (
            <div style={{borderRadius: 16, marginTop: 16, padding: 16, border: "1px solid " + colour, display: "flex", alignItems: "center"}}>
                <div>
                    <p>{parsed.originalName}</p>
                    <p>{Str.uploaded_colon(uploadTime)}</p>
                </div>
                <div style={{flex: 1}}/>
                <PrimaryButton onClick={() => onView(parsed.originalName, parsed.fileName)}>{Str.view()}</PrimaryButton>
                <Button onClick={handleDelete}><Icon>clear</Icon></Button>
            </div>
        )}
        {showError && <UnansweredQuestionLabel/>}
    </div>;
};


export default FormFileView;
