import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {mainSectionTheme} from "../../theme/Theme";
import * as Assets from "../../Assets";
import Dimens from "../../theme/Dimens";
import {Paper} from "grantfairy-web-common";
import {GFThemeProvider} from "../../theme/GFThemeProvider";
import {sections} from "../../Tab";
import {useNavigate, useSearchParams} from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import {useEffect} from "react";

const EnglishLanguageSSOFailureRouter = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const nav = useNavigate();
    const [params, _] = useSearchParams();

    useEffect(() => {
        if (!isMobile) {
            //  Web users should never see this screen but should continue on their merry way
            const webRedirectUrl = "/english_language/information?msg=" + params.get("msg");
            nav(webRedirectUrl);
        } else {
        }
    }, [nav, params, isMobile]);

    const mobileRedirectUrl = "myriad://section?section=english_language&tab=information&msg=" + params.get("msg");

    return (
        <MuiThemeProvider theme={mainSectionTheme}>
            <GFThemeProvider value={sections.Main}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", overflowY: "auto", background: "#FFDC1A"}}>
                    <img alt="logo" src={require("../../" + Assets.LOGO)} style={{width: 100, height: 100, margin: Dimens.HalfMargin}}/>
                    <Paper style={{display: "flex", padding: Dimens.StandardMargin, background: "white", minHeight: 220, flexDirection: "column", alignContent: "space-between"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        <h1 style={{flex: 1, marginTop: 0, marginBottom: 0}}>Oops! Something has gone wrong...</h1>
                        <p style={{flex: 1}}>Please go back to Myriad for further information</p>
                        <div style={{flex: 1, textAlign: "center"}}>
                            <LinkButton href={mobileRedirectUrl} text="Return to Myriad" />
                        </div>
                    </Paper>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

 export default EnglishLanguageSSOFailureRouter;