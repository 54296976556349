import {Util} from "grantfairy-web-common";
import {isString} from "lodash";
import {isArray} from "lodash/lang";
import moment from "moment";
import {MultiSelectOption} from "../../components/MultiSelectView";
import {convertRecordAnswersToDictionary, FormQuestionAnswerDictionary} from "./FormUtil";
import {FormFieldWidth} from "./FormFieldWidth";

export type FormAnswerType = string | null | undefined;

interface ReferenceOption {
    uid: number;
    name: string;
}

export interface ShowIfRule {
    id: string;
    answers: string[];
    mutate_options?: {
        type: string;
        reference_data: {
            [key: string]: ReferenceOption[];
        };
    }
}

export interface FormItemAction {
    on: string;
    link: string;
}

export abstract class FormItem {

    readonly type: string;

    readonly id: string;
    readonly show_if?: ShowIfRule;
    readonly required: boolean;
    readonly action?: FormItemAction;
    readonly isUniversitySpecific: boolean;
    readonly fieldProviderName?: string;

    protected constructor(type: string, id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined) {
        this.type = type;
        this.id = id;
        this.show_if = show_if;
        this.required = required;
        this.action = action;
        this.isUniversitySpecific = isUniversitySpecific;
        this.fieldProviderName = fieldProviderName;
    }

    static getPrototype(type) {
        switch (type) {
            case "text":
                return TextEntry.prototype;
            case "multiline_text":
                return MultiTextEntry.prototype;
            case "tickbox":
                return TickEntry.prototype;
            case "date":
                return DateEntry.prototype;
            case "radio":
                return RadioEntry.prototype;
            case "radioWithId":
                return RadioEntryWithId.prototype;
            case "records":
                return RecordEntry.prototype;
            case "file":
                return FileEntry.prototype;
            case "heading":
                return FormHeading.prototype;
            case "info":
                return FormInfo.prototype;
            case "telephone":
                return TelephoneEntry.prototype;
            case "multi_select":
                return MultiSelectEntry.prototype;
            case "divider":
                return FormDivider.prototype;
        }

        throw new Error("Invalid type " + type);
    }

    numberOfQuestions(an: FormAnswerType, answers: FormQuestionAnswerDictionary = {}): number {
        return 1;
    }

    numberAnswered(an: FormAnswerType, answers: FormQuestionAnswerDictionary = {}): number {
        return 0;
    }

    containsVisibleUniversitySpecificQuestion(an: FormAnswerType, allAnswers: FormQuestionAnswerDictionary): boolean {
        return this.isUniversitySpecific;
    }
}

export abstract class FormItemQuestion extends FormItem {
    readonly question: string;
    readonly info?: string;

    protected constructor(type: string, id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info?: string) {
        super(type, id, show_if, required, action, isUniversitySpecific, fieldProviderName);
        this.question = question;
        this.info = info;
    }
}

export class TextEntry extends FormItemQuestion {

    readonly text_type: "email" | "number" | "" | null;
    readonly hint?: string;
    readonly maxLength?: number;
    readonly multiLine: boolean;
    readonly field_width: FormFieldWidth | undefined;

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, text_type: "email" | "number" | "" | null, hint: string, maxLength: number, multiLine: boolean = false, field_width: FormFieldWidth | undefined) {
        super("text", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.text_type = text_type;
        this.hint = hint;
        this.maxLength = maxLength;
        this.multiLine = multiLine;
        this.field_width = field_width;
    }

    numberAnswered(an, answers = {}) {
        const {text_type} = this;
        if (an == null || an === "") return 0;
        if (text_type === "email" && !Util.isValidEmail(an)) return 0;
        //TODO other text_type's
        return 1;
    }

}

export class MultiTextEntry extends TextEntry {

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, text_type: "email" | "number" | "" | null, hint: string, maxLength: number, field_width: FormFieldWidth | undefined) {
        super(id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info, text_type, hint, maxLength, true, field_width);
    }
}

export class DateEntry extends FormItemQuestion {

    readonly min_date?: string;
    readonly max_date?: string;
    readonly initial_date?: string;

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, min_date: string, max_date: string, initial_date: string) {
        super("date", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.min_date = min_date;
        this.max_date = max_date;
        this.initial_date = initial_date;
    }

    /**
     *
     * @param dateString The string to be parsed, either literally "now" or a date in dd/MM/yyyy format
     * @param roundToStart If true, "now" means "start of current day". If false, "now" means "end of current day"
     */
    parseJsonDate(dateString: string, roundToStart: boolean) {
        if (dateString === "now") {
            if (roundToStart) {
                return moment().startOf("day");
            } else {
                return moment().endOf("day");
            }
        }
        return moment(dateString, "DD/MM/yyyy");
    };

    maxDate() {
        return this.max_date == null ? undefined : this.parseJsonDate(this.max_date, false);
    }

    minDate() {
        return this.min_date == null ? undefined : this.parseJsonDate(this.min_date, true);
    }

    initialFocusDate() {
        return this.initial_date == null ? undefined : this.parseJsonDate(this.initial_date, true);
    }

    numberAnswered(an: string | null | undefined, answers: FormQuestionAnswerDictionary = {}): number {
        if (an == null || an === "") return 0;
        //validate the date
        const date = moment(an, "DD/MM/yyyy").unix();
        if (isNaN(date)) return 0;

        const maxDate = this.maxDate();
        const minDate = this.minDate();

        if (maxDate != null && date > maxDate.unix()) return 0;
        if (minDate != null && date < minDate.unix()) return 0;

        return 1;
    }

}

export class TickEntry extends FormItemQuestion {

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string) {
        super("tickbox", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
    }

    numberAnswered(an: string | null | undefined, answers: FormQuestionAnswerDictionary = {}): number {
        return an === "true" ? 1 : 0;
    }

}

export enum FormRadioEntryInputType {
    Auto = "auto",
    AutoWithSearch = "auto_with_search",
    AutoWithOther = "auto_with_other",
    SegmentedControl = "segmented_control"
}

export class RadioEntry extends FormItemQuestion {

    readonly options: string[];
    readonly searchable: boolean;
    readonly other_option: boolean;
    readonly input_type: FormRadioEntryInputType | undefined;
    readonly field_width: FormFieldWidth | undefined;

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string | undefined, options: string[], searchable: boolean, other_option: boolean, input_type: FormRadioEntryInputType | undefined, field_width: FormFieldWidth | undefined) {
        super("radio", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.options = options;
        this.searchable = searchable;
        this.other_option = other_option;
        this.input_type = input_type;
        this.field_width = field_width;
    }

    numberAnswered(an: string | null | undefined, answers: FormQuestionAnswerDictionary = {}): number {
        const valid = an != null && an !== "" && (this.other_option || this.options.indexOf(an) !== -1);

        return valid ? 1 : 0;
    }

    inputTypeToUse(): FormRadioEntryInputType {
        return this.input_type ?? this.inferredInputType();
    }

    private inferredInputType(): FormRadioEntryInputType {
        // Old form schema specifies other_option and searchable rather than input_type
        if (this.other_option) {
            return FormRadioEntryInputType.AutoWithOther;
        } else if (this.searchable) {
            return FormRadioEntryInputType.AutoWithSearch;
        } else {
            return FormRadioEntryInputType.Auto;
        }
    }
}

export interface RadioOptionWithId {
    uid: string;
    name: string;
}

export class RadioEntryWithId extends FormItemQuestion {

    readonly options: RadioOptionWithId[];
    readonly input_type: FormRadioEntryInputType | undefined;
    readonly field_width: FormFieldWidth | undefined;

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, options: RadioOptionWithId[], input_type: FormRadioEntryInputType | undefined, field_width: FormFieldWidth | undefined) {
        super("radioWithId", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.options = options;
        this.input_type = input_type;
        this.field_width = field_width;
    }

    numberAnswered(an: string | null | undefined, answers: FormQuestionAnswerDictionary = {}): number {
        const valid = an != null && an !== "" && this.options.map(e => e.uid).includes(an);
        return valid ? 1 : 0;
    }

}

interface TelephoneEntryOption {
    readonly code: string;
    readonly name: string;
    readonly minPhoneLength: number;
    readonly maxPhoneLength: number;
}

export class TelephoneEntry extends FormItemQuestion {

    static MAX_PHONE_LENGTH = 20;

    readonly options: TelephoneEntryOption[];
    readonly maxLength: number;

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, options: TelephoneEntryOption[], maxLength: number) {
        super("telephone", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.options = options;
        this.maxLength = maxLength;
    }

    numberAnswered(an, answers = {}) {
        return this.isValidAnswer(an) ? 1 : 0;
    }

    isValidAnswer(answer) {
        const phoneRegex = /^[0-9\s().-]+$/;
        try {
            const parsed = JSON.parse(answer);
            const selectedOption = this.options.find(e => e.code === parsed.code);
            if (selectedOption == null || parsed.text == null) return false;
            const characters = parsed.text.split("");
            const numberCharacters = characters.filter(c => c >= "0" && c <= "9");
            const numberLength = numberCharacters.length;
            const totalLength = parsed.text.length;
            return phoneRegex.test(parsed.text) && numberLength >= selectedOption.minPhoneLength && numberLength <= selectedOption.maxPhoneLength && totalLength <= TelephoneEntry.MAX_PHONE_LENGTH;
        } catch (e) {
        }

        return false;
    }

}

export class RecordEntry extends FormItemQuestion {

    readonly addMessage?: string;
    readonly deleteMessage?: string;
    readonly items: FormItem[];

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, addMessage: string, deleteMessage: string, items: FormItem[]) {
        super("records", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.addMessage = addMessage;
        this.deleteMessage = deleteMessage;
        this.items = items;
    }

    /**
     *
     * @param answers Object mapping question ids to answers
     */
    allShowingItems(answers: FormQuestionAnswerDictionary) {
        return this.items.filter(item => {
            Object.setPrototypeOf(item, FormItem.getPrototype(item.type));
            let showIfValid = true;
            if (item.show_if != null) {
                showIfValid = false;
                const targetAnswer = answers[item.show_if.id];
                if (targetAnswer != null && item.show_if.answers.indexOf(targetAnswer) !== -1) {
                    showIfValid = true;
                }
            }

            return showIfValid;
        });
    }

    /**
     *
     * @param answers Object mapping question ids to answers
     */
    allShowingRequiredItems(answers: FormQuestionAnswerDictionary) {
        return this.allShowingItems(answers).filter(item => (item.required || item.type === "records"));
    }

    numberOfQuestions(an: FormAnswerType, allAnswers: FormQuestionAnswerDictionary = {}): number {
        let total = 0;

        const answers: FormQuestionAnswerDictionary[] = convertRecordAnswersToDictionary(an, allAnswers);

        answers.forEach(answer => {
            const theseItems = this.allShowingRequiredItems(answer);

            theseItems.forEach(ti => {
                if (ti.type === "records" || ti.required) {
                    total += ti.numberOfQuestions(answer[ti.id], allAnswers);
                }
            });
        });

        if (total === 0 && this.required) {
            this.items.forEach(ti => {
                Object.setPrototypeOf(ti, FormItem.getPrototype(ti.type));
                if (ti.type === "records" || ti.required) {
                    total += ti.numberOfQuestions("", allAnswers);
                }
            });
        }

        return total;
    }

    numberAnswered(an, allAnswers) {
        let total = 0;

        const answers: FormQuestionAnswerDictionary[] = convertRecordAnswersToDictionary(an, allAnswers);

        answers.forEach(answer => {
            const theseItems = this.allShowingRequiredItems(answer);

            theseItems.forEach(ti => {
                if (ti.type === "records" || ti.required) {
                    const num = ti.numberAnswered(answer[ti.id], allAnswers);
                    total += num;
                }
            });
        });

        return total;
    }

    containsVisibleUniversitySpecificQuestion(an: FormAnswerType, allAnswers: FormQuestionAnswerDictionary): boolean {
        const answers: FormQuestionAnswerDictionary[] = convertRecordAnswersToDictionary(an, allAnswers);
        let result = false;
        answers.forEach(answer => {
            const theseItems = this.allShowingItems(answer);
            theseItems.forEach(ti => {
                result = result || ti.containsVisibleUniversitySpecificQuestion(answer[ti.id], answer);
            });
        });
        return result;
    }

}

export class FileEntry extends FormItemQuestion {

    readonly fileTypes: string[];

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, fileTypes: string[]) {
        super("file", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.fileTypes = fileTypes;
    }

    numberAnswered(an: string | null | undefined, answers: FormQuestionAnswerDictionary = {}): number {
        return (an != null && an !== "") ? 1 : 0;
    }

}

type MultiSelectEntryOption = MultiSelectOption<string> & { show_if?: ShowIfRule }

export class MultiSelectEntry extends FormItemQuestion {

    readonly options: MultiSelectEntryOption[];

    constructor(id: string, show_if: ShowIfRule | undefined, required: boolean, action: FormItemAction | undefined, isUniversitySpecific: boolean, fieldProviderName: string | undefined, question: string, info: string, options: MultiSelectEntryOption[]) {
        super("multi_select", id, show_if, required, action, isUniversitySpecific, fieldProviderName, question, info);
        this.options = options;
    }

    answerToString(answer: string[]): string {
        return JSON.stringify(answer);
    }

    answerFromString(string: FormAnswerType): string[] | null {
        try {
            const parsed = JSON.parse(string!);
            if (!isArray(parsed)) return null;
            const areElementsStrings = parsed.map(e => isString(e));
            if (areElementsStrings.includes(false)) return null;
            return parsed;
        } catch {
            return null;
        }
    }

    numberAnswered(an, answers = {}) {
        const parsed = this.answerFromString(an);
        if (parsed == null) return 0;
        return parsed.length === 0 ? 0 : 1;
    }
}

export class FormHeading extends FormItem {
    readonly text: string;

    constructor(id: string, show_if: ShowIfRule | undefined, text: string) {
        super("heading", id, show_if, false, undefined, false, undefined);
        this.text = text;
    }
}

export class FormInfo extends FormItem {
    readonly text: string;

    constructor(id: string, show_if: ShowIfRule | undefined, action: FormItemAction | undefined, text: string) {
        super("info", id, show_if, false, action, false, undefined);
        this.text = text;
    }
}

export class FormDivider extends FormItem {

    constructor(id: string, show_if: ShowIfRule | undefined) {
        super("divider", id, show_if, false, undefined, false, undefined);
    }

}
