import DataDependant from "../../views/DataDependant";
import {JobLevel, JobSearchFilters, JobSort} from "../../model/JobSearchFilters";
import {JobsConfig} from "../../model/JobsApiConfig";
import {MasterDetailView} from "../../components/TabContainer";
import {JobsState} from "../../redux/reducers/jobsReducers";
import {connect} from "react-redux";
import {ApiResult} from "../../util/ApiResult";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import {Dispatch} from "redux";
import * as Str from "../../strings/Str";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {Paper} from "grantfairy-web-common";
import * as api from "../../util/api";
import {Job} from "../../model/Job";
import {JobList} from "./JobListView";
import JobDetailsView from "./JobDetailsView";
import {SmallTitle, Subtitle} from "../../views/Text";
import {FormControlLabel, Icon, MenuItem, Radio, RadioGroup, Select, Slider} from "@mui/material";
import TextFieldWithSuggestions, {SearchOptionResultProvider} from "../../components/TextFieldWithSuggestions";
import {PrimaryButton} from "../../components/Buttons";
import {Info} from "@mui/icons-material";
import {JOBS_VISA_RESTRICTION_INFORMATION_LINK} from "../../Constants";
import Dimens from "../../theme/Dimens";
import {TextWithHyperlinkParts} from "../../components/TextWithHyperlinkParts";
import {sections} from "../../Tab";
import {useParams} from "react-router-dom";
import ScrollToTopButton from "../../components/ScrollToTopButton";

interface JobSearchResultsContainerProps {
    result: ApiResult<Job[]>;
    makeUrlForJob: (job: Job) => string;
    favouriteIDs: number [];
    onFave: (job: Job, isFave: boolean) => void;
    selectedJobId: number | null;
    searchCategory: string;
    searchTerm: string;
}

const JobSearchResultsContainer = (props: JobSearchResultsContainerProps) => {
    // minHeight here is to avoid the component being just about the right size for the loading indicator, which changes size as it spins, which makes the scrollbar dance
    return (
        <div style={{minHeight: 200}}>
            <DataDependant data={props.result}>
                <JobList jobs={props.result.payload!} favouriteIDs={props.favouriteIDs} onFave={props.onFave} makeUrlForJob={props.makeUrlForJob} selectedJobId={props.selectedJobId} searchCategory={props.searchCategory} searchTerm={props.searchTerm}/>
            </DataDependant>
        </div>
    );
};

const getJobLevelText = (level: JobLevel): string => {
    switch (level) {
        case JobLevel.PartTime:
            return Str.array_job_levels()[0];
        case JobLevel.FullTime:
            return Str.array_job_levels()[1];
        case JobLevel.Graduate:
            return Str.array_job_levels()[2];
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};
const getJobLevelDescription = (level: JobLevel): string => {
    switch (level) {
        case JobLevel.PartTime:
            return Str.array_job_level_desc()[0];
        case JobLevel.FullTime:
            return Str.array_job_level_desc()[1];
        case JobLevel.Graduate:
            return Str.array_job_level_desc()[2];
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

const getJobLevelId = (level: JobLevel): string => {
    switch (level) {
        case JobLevel.PartTime:
            return "jobs-search-view-job-level-radio-part-time";
        case JobLevel.FullTime:
            return "jobs-search-view-job-level-radio-full-time";
        case JobLevel.Graduate:
            return "jobs-search-view-job-level-radio-graduate";
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

const getJobSortText = (level: JobSort): string => {
    switch (level) {
        case JobSort.Relevance:
            return Str.most_relevant();
        case JobSort.SalaryHigh:
            return Str.highest_salary();
        case JobSort.SalaryLow:
            return Str.lowest_salary();
        case JobSort.Recency:
            return Str.most_recent();
        case JobSort.Closest:
            return Str.closest();
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

const getJobSortId = (level: JobSort): string => {
    switch (level) {
        case JobSort.Relevance:
            return "jobs-search-view-job-sort-most-relevance";
        case JobSort.SalaryHigh:
            return "jobs-search-view-job-sort-highest-salary";
        case JobSort.SalaryLow:
            return "jobs-search-view-job-sort-lowest-salary";
        case JobSort.Recency:
            return "jobs-search-view-job-sort-most-recent";
        case JobSort.Closest:
            return "jobs-search-view-job-sort-closest";
    }
    // @ts-ignore complains about this code being unreachable, removing it complains about inconsistent returns!
    return "";
};

interface JobFiltersViewProps {
    filters: JobSearchFilters;
    setFilters: (filters: JobSearchFilters) => void;
    config: JobsConfig;
    showSortBy: boolean;
    getAutoCompleteForSearch: SearchOptionResultProvider;
    getAutoCompleteForLocation: SearchOptionResultProvider;
}

const UnansweredQuestionLabel = () => (
    <div style={{margin: 0, marginTop: 8, color: "red", display: "flex", alignItems: "center", gap: 4}}>
        <Icon>warning</Icon>
        <p style={{margin: 0}}>{Str.must_search_or_city()}</p>
    </div>
);

const JobFiltersView = ({filters, setFilters, showSortBy, getAutoCompleteForSearch, getAutoCompleteForLocation}: JobFiltersViewProps) => {

    const [localFilters, setLocalFilters] = useState<JobSearchFilters>(filters);
    const [validate, setValidate] = useState(false);

    useEffect(() => setLocalFilters(filters), [filters]);

    const localFiltersUpdater = (field: string) => (value: any) => setLocalFilters({...localFilters, [field]: value});
    const localFiltersUpdaterFromEvent = (field: string) => (event: ChangeEvent<HTMLInputElement>) => localFiltersUpdater(field)(event.target.value);

    const isSearchQueryValid = localFilters.searchQuery.trim() !== "";
    const isLocationValid = localFilters.location.trim() !== "";
    const areFiltersValid = isSearchQueryValid || isLocationValid;
    const showError = !areFiltersValid && validate;

    const handleSearch = () => {
        if (areFiltersValid) {
            setValidate(false);
            setFilters(localFilters);
        } else {
            setValidate(true);
        }
    };

    const handleSortChange = (sort: string) => {
        const jobSort = sort as JobSort;
        setFilters({...localFilters, sort: jobSort});
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <SmallTitle style={{margin: 8}}>{Str.search()}</SmallTitle>
            <TextFieldWithSuggestions id={"jobs-search-view-search-box"} value={localFilters.searchQuery} onChange={localFiltersUpdater("searchQuery")} getOptionsForText={getAutoCompleteForSearch} allowAnyInput={true} error={showError}/>
            {showError && <UnansweredQuestionLabel/>}

            <SmallTitle style={{margin: 8, marginTop: 16}}>{Str.job_level()}</SmallTitle>
            <RadioGroup style={{paddingLeft: 8}} value={localFilters.jobLevel} onChange={localFiltersUpdaterFromEvent("jobLevel")}>
                {Object.values(JobLevel).map(e => {
                    const label = getJobLevelText(e);
                    const levelId = getJobLevelId(e);
                    return <FormControlLabel id={levelId} key={levelId} style={{marginTop: 0}} value={e} control={<Radio color="secondary"/>} label={label}/>;
                })}
            </RadioGroup>
            <Subtitle style={{margin: "4px 8px 0 8px"}}><i>{getJobLevelDescription(localFilters.jobLevel)}</i></Subtitle>

            <SmallTitle style={{margin: 8, marginTop: 16}}>{Str.job_location()}</SmallTitle>
            <TextFieldWithSuggestions id={"jobs-search-view-location-dropdown"} value={localFilters.location} onChange={localFiltersUpdater("location")} getOptionsForText={getAutoCompleteForLocation} allowAnyInput={false} error={showError}/>
            {showError && <UnansweredQuestionLabel/>}

            <SmallTitle style={{margin: 8, marginTop: 16}}>{Str.distance_x_miles(localFilters.distance)}</SmallTitle>
            <Slider id={"jobs-search-view-distance-slider"} min={1} max={20} color="secondary" value={localFilters.distance} onChange={(event, newValue) => localFiltersUpdater("distance")(String(newValue))}/>

            <PrimaryButton id={"jobs-search-view-search-button"} onClick={handleSearch} style={{marginTop: 16, marginLeft: "auto", marginRight: "auto", width: "20rem"}}>{Str.search()}</PrimaryButton>

            <div style={{marginTop: 16, display: showSortBy ? "" : "none"}}>
                {/* Unlike the filters above, this one should update the filters live and not require hitting the 'search' button */}
                <Select value={localFilters.sort} variant="outlined" onChange={e => handleSortChange(e.target.value as string)}>
                    {Object.values(JobSort).map(e => {
                        const label = getJobSortText(e);
                        const sortId = getJobSortId(e);
                        return <MenuItem value={e} id={sortId} key={sortId}>{label}</MenuItem>;
                    })}
                </Select>
            </div>
        </div>
    );
};

interface JobsSearchTabProps {
    filters: JobSearchFilters;
    setFilters: (filters: JobSearchFilters) => void;
    config: JobsConfig;
    jobListResult?: ApiResult<Job[]>;
    makeUrlForJob: (job: Job) => string;
    favouriteIDs: number [];
    onFave: (job: Job, isFave: boolean) => void;
    selectedJobId: number | null;
    getAutoCompleteForSearch: SearchOptionResultProvider;
    getAutoCompleteForLocation: SearchOptionResultProvider;
}

const JobsSearchTab = ({filters, setFilters, config, jobListResult, makeUrlForJob, onFave, favouriteIDs, selectedJobId, getAutoCompleteForSearch, getAutoCompleteForLocation}: JobsSearchTabProps) => {

    const [showScrollButton, setShowScrollButton] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const backToTop = () => {
        const current = scrollContainerRef.current;
        if (current != null) {
            current.scroll({top: 0, behavior: "smooth"});
        }
    };

    const onScroll = () => {
        const current = scrollContainerRef.current;
        if (current != null) {
            setShowScrollButton(current.scrollTop > 300);
        }
    };

    return (
        <MasterDetailView listView={(
            <div style={{margin: 0, maxHeight: "100%", overflowY: "auto", boxSizing: "border-box"}} ref={scrollContainerRef} onScroll={onScroll}>
                <Paper style={{padding: 16, margin: 16}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <JobsVisaRequirementInfo />
                    <JobFiltersView filters={filters} setFilters={setFilters} config={config} showSortBy={jobListResult != null}
                                getAutoCompleteForSearch={getAutoCompleteForSearch} getAutoCompleteForLocation={getAutoCompleteForLocation}/>
                </Paper>
                <div style={{height: 16}}/>
                {jobListResult != null && (
                    <JobSearchResultsContainer result={jobListResult} makeUrlForJob={makeUrlForJob} favouriteIDs={favouriteIDs} onFave={onFave} searchCategory="jobs" searchTerm={filters.searchQuery} selectedJobId={selectedJobId}/>
                )}
                {showScrollButton && (
                    <ScrollToTopButton onClick={backToTop} id={"jobs-search-view-back-to-search-button"} buttonText={Str.back_to_search()} />
                )}
            </div>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "auto"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                <JobDetailsView jobID={selectedJobId}/>
            </Paper>
        )} showDetailView={selectedJobId != null}/>
    );
};

const JobsVisaRequirementInfo = () => {
    const jobsVisaLink = JOBS_VISA_RESTRICTION_INFORMATION_LINK;
    const linkColour = sections.Main.hyperlinkColour;
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: Dimens.HalfMargin}}>
            <Info/>
            <p id={"jobs-search-view-jobs-visa-restriction-text"}><TextWithHyperlinkParts text={Str.job_visa_restrictions(jobsVisaLink)} hyperlinkSubstring={jobsVisaLink} linkColour={linkColour} addIcon={true}/></p>
        </div>
    );
};

interface ContainerProps {
    filters: JobSearchFilters;
    setFilters: (filters: JobSearchFilters) => void;
    configResult: ApiResult<JobsConfig>;
    jobListResult?: ApiResult<Job[]>;
    makeUrlForJob: (job: Job) => string;
    favouriteIDs: number [];
    onFave: (job: Job, isFave: boolean) => void;
    selectedJobId: number | null;
}

const Container = ({configResult, filters, setFilters, jobListResult, makeUrlForJob, onFave, favouriteIDs, selectedJobId}: ContainerProps) => {

    const getAutoCompleteForSearch = (text: string, listener: (result: string[]) => void) => {
        if (text.trim() === "") {
            listener([]);
            return;
        }
        api.POSTWithoutToken("Jobs/nameSearch", {search: text}).then(result => {
            if (result.success) {
                const results = result.result.names.slice(0, 8);
                if (results.includes(text)) {
                    listener(results);
                } else {
                    listener([text].concat(results));
                }
            }
        });
    };

    const getAutoCompleteForLocation = (text: string, listener: (result: string[]) => void) => {
        if (text.trim() === "") {
            listener([]);
            return;
        }
        api.POSTWithoutToken("Jobs/locationSearch", {search: text}).then(result => {
            if (result.success) {
                listener(result.result.locations.slice(0, 8));
            }
        });
    };

    return (
        <DataDependant data={configResult}>
            <JobsSearchTab config={configResult.payload!} filters={filters} setFilters={setFilters} jobListResult={jobListResult}
                           makeUrlForJob={makeUrlForJob} favouriteIDs={favouriteIDs} onFave={onFave} selectedJobId={selectedJobId}
                           getAutoCompleteForSearch={getAutoCompleteForSearch} getAutoCompleteForLocation={getAutoCompleteForLocation}/>
        </DataDependant>
    );
};

const mapStateToProps = (state: JobsState, {jobID}: { jobID: string | undefined }) => ({
    configResult: selectors.jobsConfig(state),
    filters: selectors.jobSearchFilters(state),
    jobListResult: selectors.currentJobSearchResult(state),
    makeUrlForJob: (job: Job) => "/jobs/search/" + job.id,
    favouriteIDs: selectors.favouritedJobIDs(state),
    selectedJobId: jobID == null ? null : parseInt(jobID)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setFilters: (filters: JobSearchFilters) => dispatch(actions.setJobSearchFilters(filters)),
    onFave: (job: Job, isFave: boolean) => dispatch(actions.setJobFave(job, isFave))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {jobID} = useParams();
    return (<Connected jobID={jobID} />);
};

export default WrappedAndConnected;