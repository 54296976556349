import {useEffect, useState} from "react";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import * as api2 from "../../util/api2";
import {ApiResult, ApiResultState, ServerApiResult} from "../../util/ApiResult";
import DataDependant from "../../views/DataDependant";
import {InformationAndGuidanceArticle, InformationAndGuidanceSummary} from "../../model/InformationAndGuidanceArticle";
import {MasterDetailView} from "../../components/TabContainer";
import {Paper} from "grantfairy-web-common";
import Dimens from "../../theme/Dimens";
import {useNavigate, useParams} from "react-router-dom";
import "../../theme/Card.css";
import {useAnalytics} from "../../services/analytics/AnalyticsProvider";
import {ArticleExternalUrlSelected, LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";
import {currentUser} from "../../util/FirebaseUtil";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import LoadingScreen from "../../views/LoadingScreen";

const ArticlesList = ({articles, setLink}: { articles: InformationAndGuidanceSummary[], setLink: (id: string) => void}) => (
    <div style={{overflowY: "auto", flexFlow: "wrap", justifyContent: "space-around", display: "flex"}}>
        {articles.map((article, index) => (
            <div className="hoverable" key={index} style={{display: "flex"}} onClick={() => setLink(article.uid)}>
                <div className="card holding-image noSelect">
                    <div className="card-header-image lazyload-wrapper">
                        <img src={article.thumbnailUrl} alt="" />
                    </div>
                    <div className="card-contents">
                        <div className="card-body">
                            <div className="card-body-item">
                                <h1>{article.name}</h1>
                                <p>{article.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const ArticleView = ({article}: { article: InformationAndGuidanceArticle }) => {
    useEffect(() => {
        const element = document.getElementById("information-and-guidance-article-view");
        element?.scroll(0, 0);
    }, [article]);

    const analytics = useAnalytics();
    const linkClicked = (e: any) => {
        const linkUrl = e.target.getAttribute("href");
        const linkText = e.target.innerText;
        if (linkUrl != null) {
            analytics.logEvent(new ArticleExternalUrlSelected("information-and-guidance", article.uid, linkUrl, linkText));
        }
    };

    return (
        <Paper id="information-and-guidance-article-view" style={{height: "100%", overflowY: "scroll", boxSizing: "border-box"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            {article.headerImageUrl != null && <img src={article.headerImageUrl} style={{width: "100%", aspectRatio: "4", objectFit: "cover"}} alt=""/>}
            <div style={{padding: Dimens.StandardMargin}}>
                <SanitizedHtml html={article.resource} onClick={linkClicked} allowImages/>
            </div>
        </Paper>
    );
};

const Screen = ({articleSummaries, selectedArticle, selectedArticleID, setLink}: { articleSummaries: InformationAndGuidanceSummary[], selectedArticle: InformationAndGuidanceArticle | undefined, selectedArticleID: string, setLink: (id: string) => void }) => {

    return (
        <MasterDetailView showDetailView={selectedArticleID != ""} listView={(
            <ArticlesList articles={articleSummaries} setLink={setLink} />
        )} detailView={(
            <>
                {selectedArticle != null && <ArticleView article={selectedArticle}/>}
                {selectedArticle == null && <LoadingScreen/>}
            </>
        )}/>
    );
};

const Container = (params : {selectedArticleID: string}) => {
    const [result, setResult] = useState<ApiResult<InformationAndGuidanceSummary[]>>({});
    const [articleResult, setArticleResult] = useState<ApiResult<InformationAndGuidanceArticle>>({});

    // TODO move this to redux/saga
    useEffect(() => {
        api2.getRequest("howtoguides/list", {}).then((result: ServerApiResult) => {
            if (result.success) {
                setResult({state: ApiResultState.Success, payload: result.result.guides});
            } else {
                setResult({state: ApiResultState.Error});
            }
        });
    }, []);

    useEffect(() => {
        if (articleResult?.payload !== null && articleResult?.payload?.uid !== params.selectedArticleID) {
            setArticleResult({state: ApiResultState.Error});
        }
        if (params.selectedArticleID !== null) {
            api2.getRequest(`howtoguides/${params.selectedArticleID}`, {}).then((result: ServerApiResult) => {
                if (result.success) {
                    setArticleResult({state: ApiResultState.Success, payload: result.result.guide});
                } else {
                    setArticleResult({state: ApiResultState.Error});
                }
            });
        }
    }, [params]);

    const history = useNavigate();
    const setLink = (id: string) => {
        const user = currentUser();
        const articleSearchRanking = result ? (result!.payload!.findIndex(a => a.uid === id) + 1).toString() : "";
        const articleUrl = `/information_and_guidance/information/${id}`;
        new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user.uid, "information_and_guidance", "", articleSearchRanking.toString(), id, articleUrl));
        history(articleUrl);
    };

    return (
        <DataDependant data={result}>
            <Screen articleSummaries={result?.payload ?? []} selectedArticle={articleResult?.payload} selectedArticleID={params.selectedArticleID} setLink={setLink}/>
        </DataDependant>
    );
};

const WrappedContainer = () => {
    const {selectedArticleID} = useParams();
    return (<Container selectedArticleID={selectedArticleID ?? ""}/>);
};

export default WrappedContainer;