import {HTMLProps} from "react";

export const Subtitle = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 16, fontWeight: 400, ...props.style}}>{props.children}</p>;

export const SmallTitle = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 20, fontWeight: 500, ...props.style}}>{props.children}</p>;

export const Title = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 24, fontWeight: 500, ...props.style}}>{props.children}</p>;

export const Heading = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 24, fontWeight: 600, marginTop: 0, ...props.style}}>{props.children}</p>;

export const SignUpSubtitle = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 16, fontWeight: 400, ...props.style}}>{props.children}</p>;

export const SignUpTitle = (props: HTMLProps<HTMLParagraphElement>) => <p {...props} style={{fontSize: 24, fontWeight: 500, ...props.style}}>{props.children}</p>;