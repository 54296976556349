export const GOOGLE_MAPS_API_KEY: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;

export const WEBSITE: string = process.env.REACT_APP_WEBSITE_URL!;
export const UCAS_WEBSITE: string = process.env.REACT_APP_UCAS_WEB_URL!;
export const TERMS_LINK: string = process.env.REACT_APP_TERMS_OF_SERVICE_URL!;
export const PRIVACY_LINK: string = process.env.REACT_APP_PRIVACY_POLICY_URL!;

export const REACT_APP_ASSETS_PATH = process.env.REACT_APP_ASSETS_PATH;
export const ASSETS_IMG_EXT = ".jpg";
export const ASSETS_LOGO_EXT = ".png";

export const OS = 3;
export const VERSION = 8200;
export const VERSION_HUMAN_READABLE = "8.2.0";

export const LANGUAGES = ["en", "de", "es", "fr", "hi", "ind", "it", "ja", "ko", "ms", "pt", "ru", "th", "vi", "zh", "zh-rTW"];

export const MAX_DOCUMENT_SIZE_BYTES = 5 * 1024 * 1024;

export const ENGLISH_TEST_BOOKING_LINK_LABEL = "https://www.pearsonpte.com";
export const JOBS_VISA_RESTRICTION_INFORMATION_LINK = "https://www.gov.uk/student-visa";

/**
 * Which URLs this app may be hosted on
 * @type {string[]}
 */
export const BASE_URLS: string[] = process.env.REACT_APP_BASE_URLS!.split(",");
