import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiCallState, apiCallStateUtil} from "grantfairy-web-common";
import {FormItem} from "../../../util/form/FormItem";

const slice = createSlice({
    name: "notificationSettingsFormItems",
    initialState: apiCallStateUtil.makeLoading<FormItem[]>(),
    reducers: {
        request(state) {
        },
        got(state, action: PayloadAction<ApiCallState<FormItem[]>>) {
            return action.payload;
        }
    }
});

export const reducer = slice.reducer;
export const actions = slice.actions;
