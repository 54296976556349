import * as Applications from "./Applications";

it("Checks application documents are ready", () => {
    const documents = [
        "typea/file1.jpg",
        "typeb/file2.jpg",
        "typec/file3.jpg",
        "typec/file4.png",
        "typed/file5.png"
    ];
    const types = [
        {id: "typea", required: true},
        {id: "typeb", required: true},
        {id: "typec", required: false},
        {id: "typed", required: true}
    ];
    const allDocuments = [
        {documentType: "typea", name: "file1", fileType: "jpg"},
        {documentType: "typeb", name: "file2", fileType: "jpg"},
        {documentType: "typec", name: "file3", fileType: "jpg"},
        {documentType: "typec", name: "file4", fileType: "png"},
        {documentType: "typed", name: "file5", fileType: "png"},
        {documentType: "typed", name: "file6", fileType: "png"}
    ];
    const application = {documents};
    expect(Applications.areApplicationDocumentsReady(application, types, allDocuments)).toEqual(true);
});

it("Checks application documents aren't ready", () => {
    const documents = [
        "typea/file1.jpg",
        "typec/file2.jpg",
        "typec/file3.png",
        "typed/file4.png"
    ];
    const types = [
        {id: "typea", required: true},
        {id: "typeb", required: true},
        {id: "typec", required: false},
        {id: "typed", required: true}
    ];
    const allDocuments = [
        {documentType: "typea", name: "file1", fileType: "jpg"},
        {documentType: "typec", name: "file2", fileType: "jpg"},
        {documentType: "typec", name: "file3", fileType: "png"},
        {documentType: "typed", name: "file4", fileType: "png"}
    ];
    const application = {documents};
    expect(Applications.areApplicationDocumentsReady(application, types, allDocuments)).toEqual(false);
});

it("Checks application documents aren't ready when they don't exist", () => {
    const documents = [
        "typea/file1.jpg",
        "typeb/file2.jpg",
        "typec/file3.jpg",
        "typec/file4.png",
        "typed/file5.png"
    ];
    const types = [
        {id: "typea", required: true},
        {id: "typeb", required: true},
        {id: "typec", required: false},
        {id: "typed", required: true}
    ];
    const allDocuments = [
        {documentType: "typea", "name": "file1", fileType: "jpg"},
        {documentType: "typec", "name": "file2", fileType: "jpg"},
        {documentType: "typec", "name": "file3", fileType: "png"},
        {documentType: "typed", "name": "file4.", fileType: "png"}
    ];
    const application = {documents};
    expect(Applications.areApplicationDocumentsReady(application, types, allDocuments)).toEqual(false);
});