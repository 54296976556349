import {Paper} from "grantfairy-web-common";
import {useEffect, useRef, useState} from "react";
import {PrimaryButton, SecondaryButton} from "../../../components/Buttons";
import {FormAnswerType, FormItem} from "../../../util/form/FormItem";
import * as FormUtil from "../../../util/form/FormUtil";
import BackButton from "../../../views/BackButton";
import DataDependant from "../../../views/DataDependant";
import {FormItemView} from "../../../views/form/FormItemView";
import {SavingIndicator, SavingState} from "../../../views/SavingIndicator";
import {Heading} from "../../../views/Text";
import * as Str from "../../../strings/Str";
import ApplicationDeclarationPopup from "./ApplicationDeclarationPopup";
import {FormSubsection} from "../../../util/form/FormSection";
import {StringDictionary} from "../../../types/StringDictionary";
import {ApiResult} from "../../../util/ApiResult";
import {Check, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import Dimens from "../../../theme/Dimens";
import {Link, useNavigate} from "react-router-dom";

const setProtoTypeOnFormItems = items => {
    items.forEach(e => {
        Object.setPrototypeOf(e, FormItem.getPrototype(e.type));
        if (e.type === "records") {
            setProtoTypeOnFormItems(e.items);
        }
    });
};

export interface FormNavigationOption {
    link: string;
    name: string;
}

export interface FormNavigationOptions {
    previousSection?: FormNavigationOption;
    nextSection?: FormNavigationOption;
}

interface PassthroughProps {
    allItems: FormItem[];
    onSetAnswer?: (id: string, answer: FormAnswerType) => void;
    backLink: string;
    onBack?: () => void;
    onSave?: () => void;
    savingState: SavingState;
    onViewFile?: (originalName: string, fileName: string) => void;
    onDeleteFile?: (questionID: string, fileName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => void;
    onUploadFile?: (questionID: string, file: string, originalName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => void;
}

interface ApplicationFormScreenProps extends PassthroughProps {
    subsection: FormSubsection;
    answers: StringDictionary;
    navigationOptions: FormNavigationOptions;
}

function FormNavigationBar({navigationOptions, handleSave}: { handleSave: (link?: string) => void, navigationOptions: FormNavigationOptions }) {
    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: Dimens.TripleMargin, marginBottom: Dimens.StandardMargin}}>
            {navigationOptions.previousSection != null && <SecondaryButton component={Link} to={navigationOptions.previousSection.link}><KeyboardArrowLeft/>{Str.back_to_section(navigationOptions.previousSection.name)}</SecondaryButton>}
            <div style={{flex: 1}}/>
            {navigationOptions.nextSection != null && <PrimaryButton onClick={() => handleSave(navigationOptions.nextSection?.link)}>{Str.save_and_continue()}<KeyboardArrowRight/></PrimaryButton>}
            {navigationOptions.nextSection == null && <PrimaryButton onClick={() => handleSave()}>{Str.save_and_complete()}<Check/></PrimaryButton>}
        </div>
    );
}

const ApplicationFormScreen = ({subsection, allItems = [], answers, onSetAnswer = () => {}, backLink, onBack = () => {}, onSave = () => {}, savingState, onDeleteFile = () => {}, onUploadFile = () => {}, onViewFile = () => {}, navigationOptions}: ApplicationFormScreenProps) => {

    const [validate, setValidate] = useState(false);
    const [viewingDec, setViewingDec] = useState(false);
    const history = useNavigate();

    const items = FormUtil.filterShowingItems(subsection.items, allItems, answers);
    setProtoTypeOnFormItems(items);

    const scrollBox = useRef<HTMLDivElement>(null);

    // Scroll to top whenever we move subsection
    useEffect(() => {
        scrollBox.current?.scrollTo(0, 0);
    }, [subsection]);

    const handleSave = (link?: string) => {
        //Records should always be checked because their children need to be checked
        const invalids = items.filter(e => (e.required || e.type === "records") && e.numberOfQuestions(answers[e.id], answers) !== e.numberAnswered(answers[e.id], answers));
        if (invalids.length === 0) {
            onSave();
            if (link == null) {
                onBack();
            } else {
                history(link);
            }
        } else {
            setValidate(true);
        }
    };

    const handleLink = e => {
        //special link for declaration
        if (e.target.href.includes("open_declaration")) {
            setViewingDec(true);
            e.preventDefault();
        }
        e.stopPropagation();
    };

    return (
        <>
            <ApplicationDeclarationPopup open={viewingDec} onClose={() => setViewingDec(false)}/>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start"}} className="noSelect">
                <div style={{flex: 1, textAlign: "right"}}>
                    <BackButton link={backLink} style={{margin: Dimens.DoubleMargin}} title={Str.save_and_exit()}/>
                </div>
                <Paper style={{padding: 0, margin: Dimens.DoubleMargin, height: `calc(100% - ${Dimens.DoubleMargin * 2}px)`, position: "relative", width: "70vw"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <SavingIndicator style={{position: "absolute", right: Dimens.DoubleMargin, top: 0}} state={savingState}/>
                    <div style={{overflowY: "auto", height: "100%"}} ref={scrollBox}>
                        <div style={{padding: Dimens.DoubleMargin}}>
                            <Heading>{subsection.name}</Heading>
                            {items.map(item => <FormItemView formName={subsection.name} handleLink={handleLink} validate={validate} item={item} answer={answers[item.id]} allAnswers={answers} allItems={allItems}
                                                             key={item.id} setAnswer={a => onSetAnswer(item.id, a)} onDeleteFile={(...args) => onDeleteFile(item.id, ...args)}
                                                             onViewFile={onViewFile} onUploadFile={(file, name, ...args) => onUploadFile(item.id, file, name, ...args)}/>)}
                            <FormNavigationBar navigationOptions={navigationOptions} handleSave={handleSave}/>
                        </div>
                    </div>
                </Paper>
                <div style={{flex: 1}}/>
            </div>
        </>
    );
};

interface ContainerProps extends PassthroughProps {
    subsection: ApiResult<FormSubsection | undefined>;
    answers: ApiResult<StringDictionary>;
    navigationOptions: ApiResult<FormNavigationOptions>;
}

const Container = ({subsection, answers, navigationOptions, ...props}: ContainerProps) => {
    return (
        <DataDependant data={subsection}>
            {subsection?.payload != null && navigationOptions?.payload != null && <ApplicationFormScreen subsection={subsection.payload} answers={answers?.payload ?? {}} navigationOptions={navigationOptions.payload} {...props}/>}
        </DataDependant>
    );
};

export default Container;