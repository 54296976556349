import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiCallState, apiCallStateUtil} from "grantfairy-web-common";
import {EnglishLanguageConfig} from "../../model/EnglishLanguageConfig";

const english = createSlice({
    name: "englishLanguageConfig",
    initialState: apiCallStateUtil.makeLoading<EnglishLanguageConfig>(),
    reducers: {
        request(state) {

        },
        got(state, action: PayloadAction<ApiCallState<EnglishLanguageConfig>>) {
            return action.payload;
        }
    }
});

export const reducer = english.reducer;
export const actions = english.actions;
