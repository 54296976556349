import {CSSProperties} from "react";

export enum FormFieldWidth {
    Small = "small",
    Medium = "medium",
    Large = "large",
    Full = "full",
    Natural = "natural"
}

export interface CalculatedFormFieldWidth {
    getCSS(font: string, padding: number): CSSProperties;
}

export class CalculatedFormFieldWidthFraction implements CalculatedFormFieldWidth {
    readonly fraction: number;

    constructor(fraction: number) {
        this.fraction = fraction;
    }

    getCSS(font: string, padding: number): CSSProperties {
        return {width: (this.fraction * 100) + "%"};
    }
}

export class CalculatedFormFieldWidthToFitTexts implements CalculatedFormFieldWidth {
    readonly texts: string[];

    constructor(texts: string[]) {
        this.texts = texts;
    }

    displayTextWidth(text: string, font: string): number {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d")!;
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    getCSS(font: string, padding: number): CSSProperties {
        const lengths = this.texts.map(t => this.displayTextWidth(t, font));
        const longestLength = Math.max(...lengths);
        return {width: (longestLength + padding) + "px", maxWidth: "100%"};
    }
}

export const calculateFieldWidth = (fieldWidth: FormFieldWidth, texts: string[] = []): CalculatedFormFieldWidth => {
    switch (fieldWidth) {
        case FormFieldWidth.Small:
            return new CalculatedFormFieldWidthFraction(0.2);
        case FormFieldWidth.Medium:
            return new CalculatedFormFieldWidthFraction(0.4);
        case FormFieldWidth.Large:
            return new CalculatedFormFieldWidthFraction(0.6);
        case FormFieldWidth.Full:
            return new CalculatedFormFieldWidthFraction(1);
        case FormFieldWidth.Natural:
            return new CalculatedFormFieldWidthToFitTexts(texts);
        default:
            return new CalculatedFormFieldWidthFraction(1);
    }
};
