import * as Property from "./Property";

it("merges facility lists", () => {
    const a = [
        {id: "1", name: "a"},
        {id: "2", name: "b"},
        {id: "3", name: "b"}
    ];
    const b = [
        {id: "4", name: "a"},
        {id: "2", name: "a"},
        {id: "3", name: "b"},
        {id: "6", name: "c"}
    ];
    const expected = [
        {id: "1", name: "a"},
        {id: "2", name: "b"},
        {id: "3", name: "b"},
        {id: "4", name: "a"},
        {id: "6", name: "c"}
    ];
    expect(Property.joinFacilities(a, b)).toEqual(expected);
});