import {ReactElement, useRef} from "react";
import {Transition} from "react-transition-group";

const TabContainer = props => {
    const transitionStyles = {
        entering: {opacity: 0.5},
        entered: {opacity: 1},
        exiting: {opacity: 0.5},
        exited: {opacity: 0}
    };

    const nodeRef = useRef(null);
    const height = props.fixedHeight ? "calc(100% - 64px)" : "";

    return (
        <Transition nodeRef={nodeRef} in={true} appear={true} timeout={300}>
            {state =>
                (<div ref={nodeRef} style={{height: height, display: "flex", padding: 32, justifyContent: "center", transition: "opacity 300ms ease-in-out", opacity: 0, ...transitionStyles[state]}}>{props.children}</div>)
            }
        </Transition>
    );
};

interface MasterDetailsViewProps {
    listView: ReactElement | ReactElement[] | string;
    detailView: ReactElement | ReactElement[] | string;
    showDetailView: boolean;
    fixedHeight?: boolean;
}

export const MasterDetailView = ({detailView, listView, showDetailView, fixedHeight = true}: MasterDetailsViewProps) => {
    return (
        <TabContainer fixedHeight={fixedHeight}>
            <div style={{width: showDetailView ? 500 : 1200, flex: "none", maxWidth: "80vw", display: "flex", flexDirection: "column", height: "100%"}}>
                {listView}
            </div>
            {showDetailView && (
                <div style={{display: "flex", flex: 1}}>
                    <div style={{width: 32}}/>
                    <div style={{flex: 1}}>
                        {detailView}
                    </div>
                </div>
            )}
        </TabContainer>
    );
};