import {useEffect, useState} from "react";
import * as api from "../../util/api";
import {ApiResultState} from "../../util/ApiResult";
import PaymentScreen from "../../views/PaymentScreen";

const Container = ({planID, onPaid}) => {

    const [result, setResult] = useState(null);

    useEffect(() => {
        api.POST("Purchase/getToken", {}).then(result => {
            if (result.success) {
                setResult({state: ApiResultState.Success, payload: result.result.token});
            } else {
                setResult({state: ApiResultState.Error});
            }
        });
        return undefined;
    }, []);

    const gotNonce = nonce => {
        api.POST("Purchase/verifyPay", {plan_id: planID, nonce}).then(result => {
            console.log(result);
            if (result.success && result.result.success) {
                onPaid();
            } else {
                alert(result.error + result.result.error);
            }
        });
    };

    if (result && result.state === ApiResultState.Success) {
        return <PaymentScreen braintreeToken={result.payload} gotNonce={gotNonce}/>;
    }
    return null;
};

export default Container;