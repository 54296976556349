import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import {AspectFitImage, Paper} from "grantfairy-web-common";
import moment from "moment";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useAlert} from "../../Alerts";
import * as Assets from "../../Assets";
// @ts-ignore
import licenseTextPath from "../../assets/licenses.txt";
import {PrimaryButton} from "../../components/Buttons";
import ColouredLink from "../../components/ColouredLink";
import {MasterDetailView} from "../../components/TabContainer";
import * as Constants from "../../Constants";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import Colours from "../../theme/Colours";
import Dimens from "../../theme/Dimens";
import {useSecondaryColour} from "../../theme/Theme";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import BackButton from "../../views/BackButton";
import DataDependant from "../../views/DataDependant";
import {Heading, Subtitle, Title} from "../../views/Text";
import ChangeEmailDialog from "./ChangeEmailDialog";
import NotificationSettingsScreen from "./NotificationSettingsScreen";

const ListItem = ({item, isSelected, onSelect}) => {
    const style = {padding: 16, borderBottom: "1px solid #CCC", display: "flex", alignItems: "center", background: isSelected ? Colours.RowHighlight : ""};
    return (
        <div style={style} className="noSelect" onClick={onSelect}>
            <Icon fontSize="large" color={"secondary"}>{item.icon}</Icon>
            <div style={{flex: 1, marginLeft: 16}}>
                <Subtitle style={{margin: 0}}>{item.name}</Subtitle>
                <p style={{color: "#888", margin: "8px 0 0 0"}}>{item.description}</p>
            </div>
        </div>
    );
};

const List = ({items, selectedItem, setSelectedItem}) => {
    return items.map((e, index) => <ListItem key={index} item={e} isSelected={index === selectedItem} onSelect={() => setSelectedItem(index)}/>);
};

const AccountSettings = ({userName, email, currentSchoolName, onLogout, loginProvider, onChangePassword, onChangeEmail}) => {

    const [alert, showAlert] = useAlert();
    const [isChangingEmail, setIsChangingEmail] = useState(false);

    const handleLogout = () => {
        showAlert(Str.logout(), Str.logout_confirm(), Str.logout(), Str.cancel(), onLogout, null);
    };

    const handleChangePass = () => {
        if (loginProvider == null) {
            onChangePassword();
            showAlert(Str.change_password(), Str.password_reset_info(email), Str.okay(), "");
        } else {
            showAlert(Str.change_password(), Str.signedInWithProviderNoPassword(loginProvider), Str.okay(), "");
        }
    };

    const handleChangeEmail = () => {
        if (loginProvider == null) {
            setIsChangingEmail(true);
        } else {
            showAlert(Str.change_email(), Str.signedInWithProviderNoEmailChange(loginProvider), Str.okay(), "");
        }
    };

    return (
        <div style={{width: "calc(100% - 64px)", padding: 32}}>
            {alert}
            <ChangeEmailDialog open={isChangingEmail} onClose={() => setIsChangingEmail(false)} onChangeEmail={onChangeEmail} originalEmail={email}/>
            <AspectFitImage backgroundSize="contain" src={require("../../" + Assets.UCAS_LOGO_BRANDED)} style={{width: "100%", height: 200}}/>
            <div style={{alignItems: "center", display: "flex", flexDirection: "column"}}>
                <Title>{userName}</Title>
                <Subtitle>{email}</Subtitle>
                {currentSchoolName && <Subtitle style={{margin: 4}}>{currentSchoolName}</Subtitle>}
                <div style={{marginTop: 64, display: "flex", flexDirection: "column", width: "50%"}}>
                    <PrimaryButton onClick={handleChangeEmail}>{Str.change_email()}</PrimaryButton>
                    <div style={{height: 16}}/>
                    <PrimaryButton onClick={handleChangePass}>{Str.change_password()}</PrimaryButton>
                    <div style={{height: 16}}/>
                    <PrimaryButton onClick={handleLogout}>{Str.sign_out()}</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

const Contact = ({sendMessage}) => {
    const [message, setMessage] = useState("");
    const [sent, setSent] = useState(false);
    const [alert, showAlert] = useAlert();

    const handleSend = () => {
        const toSend = message.trim();
        if (toSend.length > 0) {
            setSent(true);
            sendMessage(message);
        } else {
            showAlert(Str.error(), Str.empty_form_error(), Str.okay(), "");
        }
    };

    if (sent) {
        return (
            <div style={{padding: 16}}>
                <Title>{Str.contact_support()}</Title>
                <p>{Str.your_message_sent()}</p>
            </div>
        );
    }

    return (
        <div style={{padding: Dimens.DoubleMargin}}>
            {alert}
            <Heading style={{margin: 0}}>{Str.contact_support()}</Heading>
            <div style={{padding: 0}}>
                <p>{Str.contact_support_part_1()}</p>
                <p><b>{Str.contact_support_part_2_title()}</b><br/>{Str.contact_support_part_2()}</p>
                <p><b>{Str.contact_support_part_3_title()}</b><br/>{Str.contact_support_part_3()}</p>
                <p>{Str.contact_support_signoff()}</p>
                <TextField style={{width: "100%"}} multiline rows={10} inputProps={{maxLength: 1000}} variant="outlined"
                           placeholder={Str.type_a_message()} value={message} onChange={e => setMessage(e.target.value)}/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <p style={{color: "#888"}}>{message.trim().length > 0 ? (message.trim().length + "/1000") : ""}</p>
                    <PrimaryButton onClick={handleSend} style={{paddingLeft: 64, paddingRight: 64, marginTop: 16}}>{Str.send()}</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

const LicensesPopup = ({open, onClose}) => {

    const [text, setText] = useState("Loading...");

    useEffect(() => {
        const rawFile = new XMLHttpRequest();
        rawFile.open("GET", licenseTextPath);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                setText(rawFile.responseText);
            }
        };
        rawFile.send();
    }, []);

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onBackdropClick={onClose}>
            <Title style={{margin: Dimens.StandardMargin}}>{Str.open_source_licenses()}</Title>
            <DialogContent>
                <div style={{whiteSpace: "pre-wrap"}}>{text}</div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>{Str.close()}</Button>
            </DialogActions>
        </Dialog>
    );
};

const About = () => {

    const [showingLicenses, setShowingLicenses] = useState(false);
    const secondary = useSecondaryColour();

    return (
        <div style={{width: `calc(100% - ${Dimens.DoubleMargin * 2}px)`, padding: Dimens.DoubleMargin}}>
            <LicensesPopup open={showingLicenses} onClose={() => setShowingLicenses(false)}/>
            <AspectFitImage backgroundSize="contain" src={require("../../" + Assets.UCAS_LOGO_BRANDED)} style={{width: "100%", height: 200}}/>
            <div style={{alignItems: "center", display: "flex", flexDirection: "column", marginTop: Dimens.DoubleMargin}}>

                <ColouredLink colour={secondary} href={Constants.TERMS_LINK}>{Str.terms()}</ColouredLink>
                <ColouredLink colour={secondary} href={Constants.PRIVACY_LINK} style={{marginTop: Dimens.StandardMargin}}>{Str.privacy()}</ColouredLink>
                <ColouredLink colour={secondary} href="/cookies.html" style={{marginTop: Dimens.StandardMargin}}>Cookie Policy</ColouredLink>

                <Subtitle>Version {Constants.VERSION_HUMAN_READABLE}</Subtitle>
                <Subtitle>Copyright © {moment().format("yyyy")}</Subtitle>

                <div style={{marginTop: 64, display: "flex", flexDirection: "column", width: "50%"}}>
                    <PrimaryButton onClick={() => setShowingLicenses(true)}>{Str.open_source_licenses()}</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

const SettingsTab = (props) => {

    const items = [
        {name: Str.accountSettings(), description: Str.account_settings_exp(), icon: "account_circle", link: "account"},
        {name: Str.notificationSettings(), description: Str.notif_settings_exp(), icon: "notifications_active", link: "notification"},
        {name: Str.contact_grantfairy(), description: Str.contact_support(), icon: "email", link: "contact"},
        {name: Str.about(), description: Str.about_exp(), icon: "info", link: "about"}
    ];

    const nav = useNavigate();

    const setTab = item => {
        nav("/more/settings/" + items[item].link);
    };

    const selectedItem = props.tab;

    return (
        <MasterDetailView listView={(
            <Paper style={{flex: 1, overflowY: "auto", width: "100%"}}>
                <BackButton link="/more" style={{margin: Dimens.StandardMargin}}/>
                <List items={items} selectedItem={props.tab} setSelectedItem={setTab}/>
            </Paper>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "auto"}}>
                {selectedItem === 0 && <AccountSettings {...props}/>}
                {selectedItem === 1 && <NotificationSettingsScreen/>}
                {selectedItem === 2 && <Contact {...props}/>}
                {selectedItem === 3 && <About {...props}/>}
            </Paper>
        )} showDetailView={true}/>
    );
};

const convertTabNameToId = (name) => {
    const tabNameIds = {
        account: 0,
        notification: 1,
        contact: 2,
        about: 3
    };
    return tabNameIds[name.toLowerCase()] ?? 0;
};

const Container = (props) => (
    <DataDependant data={props.settings}>
        <SettingsTab {...props} settings={props.settings?.payload}/>
    </DataDependant>
);

export const mapStateToProps = (state, {tab}) => ({
    userName: selectors.userName(state),
    email: FirebaseUtil.currentUser().email,
    settings: selectors.settings(state),
    currentSchoolName: selectors.currentSchoolName(state),
    loginProvider: FirebaseUtil.getLoginProvider(),
    tab: tab == null ? 0 : convertTabNameToId(tab)
});

const mapDispatchToProps = dispatch => ({
    onSettingsFieldChanged: (field, newValue) => dispatch(actions.setSettingsField(field, newValue)),
    sendMessage: message => dispatch(actions.sendFeedback(message)),
    onLogout: () => dispatch(actions.logout()),
    onChangePassword: () => dispatch(actions.changePassword()),
    onChangeEmail: newEmail => dispatch(actions.changeEmail(newEmail))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {tab} = useParams();
    return (<Connected tab={tab} />);
};

export default WrappedAndConnected;