import {MIAN} from "../model/MIAN";
import {ReactNode} from "react";

export const MIAN_FREQUENCY = 6;

export const zipWithMians = <T>(items: T[], mians: MIAN[], itemMapper: (item: T) => ReactNode, mianMapper: (mian: MIAN) => ReactNode) => {
    const result: ReactNode[] = [];

    let itemsSinceMian = 0;
    let mianIndex = 0;

    for (let i = 0; i < items.length; i++) {
        if (itemsSinceMian === MIAN_FREQUENCY && mians[mianIndex]) {
            result.push(mianMapper(mians[mianIndex]));
            mianIndex++;
            itemsSinceMian = 0;
            i--;
        } else {
            result.push(itemMapper(items[i]));
            itemsSinceMian++;
        }
    }

    return result;
};