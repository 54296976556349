import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import * as selectors from "../selectors";

it("Selects scholarship details", () => {
    const uid = "test_id";
    const version = "3";
    const state = {
        scholarshipDetails: {
            [uid + version]: {state: ApiResultState.Loading}
        }
    };
    expect(selectors.scholarshipDetails(state, uid, version)).toEqual({state: ApiResultState.Loading});
});

it("Selects scholarship details success", () => {
    const uid = "test_id";
    const version = "3";
    const state = {
        scholarshipDetails: {
            [uid + version]: {state: ApiResultState.Success, payload: {name: "im a scholarship"}}
        }
    };
    expect(selectors.scholarshipDetails(state, uid, version)).toEqual({state: ApiResultState.Success, payload: {name: "im a scholarship"}});
});

it("Selects campus", () => {
    const accommodationCampuses = makeSuccessResult([
        {id: "1", name: "im 1"},
        {id: "2", name: "im 2"},
        {id: "3", name: "im 3"}
    ]);
    const accommodationSelectedCampusId = makeSuccessResult("2");
    const expected = {id: "2", name: "im 2"};
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.accommodationSelectedCampus(state)).toEqual(expected);
});

/** */
it("Selects null when no campus", () => {
    const accommodationCampuses = {
        state: ApiResultState.Error
    };
    const accommodationSelectedCampusId = makeSuccessResult("2");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.accommodationSelectedCampus(state)).toEqual(null);
});

it("Selects null when invalid campus", () => {
    const accommodationCampuses = {
        state: ApiResultState.Success,
        payload: [
            {id: "1", name: "im 1"},
            {id: "2", name: "im 2"},
            {id: "3", name: "im 3"}
        ]
    };
    const accommodationSelectedCampusId = makeSuccessResult("40");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.accommodationSelectedCampus(state)).toEqual(null);
});

it("Selects null when invalid campus list", () => {
    const accommodationCampuses = {
        state: ApiResultState.Success
    };
    const accommodationSelectedCampusId = makeSuccessResult("40");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.accommodationSelectedCampus(state)).toEqual(null);
});

it("Selects true when invalid campus", () => {
    const accommodationCampuses = makeSuccessResult([{id: "1", name: "one"}, {id: "2", name: "two"}, {id: "3", name: "three"}]);
    const accommodationSelectedCampusId = makeSuccessResult("-1");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.hasNoCampus(state)).toEqual(true);
});

it("Selects true when no campus", () => {
    const accommodationCampuses = makeSuccessResult([{id: "1", name: "one"}, {id: "2", name: "two"}, {id: "3", name: "three"}]);
    const accommodationSelectedCampusId = makeSuccessResult(null);
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.hasNoCampus(state)).toEqual(true);
});

it("Selects false when have campus", () => {
    const accommodationCampuses = makeSuccessResult([{id: "1", name: "one"}, {id: "2", name: "two"}, {id: "3", name: "three"}]);
    const accommodationSelectedCampusId = makeSuccessResult("2");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.hasNoCampus(state)).toEqual(false);
});

it("Selects false when no campus but loading", () => {
    const accommodationCampuses = {state: ApiResultState.Loading};
    const accommodationSelectedCampusId = makeSuccessResult("-1");
    const state = {accommodationSelectedCampusId, accommodationCampuses};
    expect(selectors.hasNoCampus(state)).toEqual(false);
});

it("Selects application status", () => {
    const state = {
        universityApplications: makeSuccessResult([
            {
                option: {
                    id: "test1"
                },
                status: 2
            },
            {
                option: {
                    id: "test2"
                },
                status: 4
            }
        ])
    };
    const status = selectors.applicationStatus(state, "test1");
    expect(status).toEqual(2);
});

it("Calculates document completion percent", () => {
    const docTypes = [
        {id: "typea", required: true},
        {id: "typeb", required: false},
        {id: "typec", required: true},
        {id: "typed", required: true}
    ];
    const docs = [
        {documentType: "typea", name: "test"},
        {documentType: "typea", name: "test"},
        {documentType: "typea", name: "test"},
        {documentType: "typeb", name: "test"},
        {documentType: "typec", name: "test"}
    ];
    const agreements = [
        {id: "a", type: "tickbox", required: true},
        {id: "b", type: "tickbox", required: true},
        {id: "c", type: "tickbox", required: true}
    ];
    const answers = {a: "true", b: "true", c: "true"};

    const state = {
        applicationFormDocumentAgreements: makeSuccessResult(agreements),
        applicationFormDocumentTypes: makeSuccessResult(docTypes),
        applicationFormDocuments: makeSuccessResult(docs),
        applicationFormAnswers: makeSuccessResult(answers)
    };

    expect(selectors.documentAgreementsComplete(state)).toEqual(true);

    expect(selectors.documentCompletionPercent(state)).toEqual(75);
});

it("checks doc is added to application", () => {
    const application = {documents: ["typea/test1.jpg", "typea/test2.jpg", "typea/test3.jpg", "typeb/test4.png", "typec/test5.jpg"]};
    const state = {
        universityApplicationsByID: {
            1: makeSuccessResult(application)
        }
    };

    const doTest = (documentType, name, fileType, expected) => {
        expect(selectors.isDocumentSelectedForApplication(state, 1, {documentType, name, fileType})).toEqual(expected);
    };

    doTest("typea", "test1", "jpg", true);
    doTest("typea", "test2", "jpg", true);
    doTest("typea", "test3", "png", false);
    doTest("typeb", "test3", "jpg", false);
    doTest("typeb", "test4", "png", true);
});

it("gets funding mians for wishlist when not enough", () => {
    const wishlist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const mians = ["a", "b"];
    const state = {
        fundingWishlist: makeSuccessResult(wishlist),
        fundingAppliedList: makeSuccessResult([]),
        fundingSuccessList: makeSuccessResult([]),
        fundingDeletedList: makeSuccessResult([]),
        fundingMians: makeSuccessResult(mians)
    };
    const result = selectors.fundingMians(state, 0);
    expect(result).toEqual(mians);
});

it("gets funding mians for wishlist when too many", () => {
    const wishlist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const mians = ["a", "b", "c", "d", "e", "f"];
    const state = {
        fundingWishlist: makeSuccessResult(wishlist),
        fundingAppliedList: makeSuccessResult([]),
        fundingSuccessList: makeSuccessResult([]),
        fundingDeletedList: makeSuccessResult([]),
        fundingMians: makeSuccessResult(mians)
    };
    const result = selectors.fundingMians(state, 0);
    expect(result).toEqual(["a", "b", "c"]);
});

it("gets funding mians for applied when not enough", () => {
    const wishlist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const applied = [1, 2, 3, 4, 5, 6, 7];
    const mians = ["a", "b"];
    const state = {
        fundingWishlist: makeSuccessResult(wishlist),
        fundingAppliedList: makeSuccessResult(applied),
        fundingSuccessList: makeSuccessResult([]),
        fundingDeletedList: makeSuccessResult([]),
        fundingMians: makeSuccessResult(mians)
    };
    const result = selectors.fundingMians(state, 1);
    expect(result).toEqual([]);
});

it("gets funding mians for applied when too many", () => {
    const wishlist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const applied = [1, 2, 3, 4, 5, 6, 7];
    const mians = ["a", "b", "c", "d", "e", "f"];
    const state = {
        fundingWishlist: makeSuccessResult(wishlist),
        fundingAppliedList: makeSuccessResult(applied),
        fundingSuccessList: makeSuccessResult([]),
        fundingDeletedList: makeSuccessResult([]),
        fundingMians: makeSuccessResult(mians)
    };
    const result = selectors.fundingMians(state, 1);
    expect(result).toEqual(["c"]);
});

it("gets fundingWishlistAmount", () => {
    const amounts = ["£10", "£20", "30", "£40,000", "£50 100", "£6 000", "7,000,000", "£1 2 3"];
    const expectTotal = 10 + 20 + 30 + 40000 + 50100 + 6000 + 7000000 + 123;
    const scholarships = amounts.map((amount, index) => {
        return ({amount, uid: index});
    });
    const state = {fundingWishlist: makeSuccessResult(scholarships)};

    expect(selectors.fundingWishlistAmount(state)).toEqual(makeSuccessResult(expectTotal));
});

describe("showApplicationFormErrors", () => {
    it("should returns the correct value from the state object", () => {
        expect(selectors.showApplicationFormErrors({showApplicationFormErrors: {"12345": true}}, 12345)).toBe(true);
        expect(selectors.showApplicationFormErrors({showApplicationFormErrors: {"95484": false}}, 95484)).toBe(false);
    });

    it("should return false if application id not present in state", () => {
        expect(selectors.showApplicationFormErrors(null, 54864)).toBe(false);
        expect(selectors.showApplicationFormErrors({}, 68746351)).toBe(false);
        expect(selectors.showApplicationFormErrors({showApplicationFormErrors: null}, 383934957)).toBe(false);
        expect(selectors.showApplicationFormErrors({showApplicationFormErrors: {}}, 0)).toBe(false);
    });
});

describe("showAgentsStates", () => {
    it("hasAgent should return false if when returns empty array of agents from API", () => {
        const currentAgents = makeSuccessResult([]);
        const state = {currentAgents};
        expect(selectors.hasAgent(state)).toBe(false);
    }); 

    it("hasAgent should return true if when returns array of agents from API", () => {
        const AgentList = [1, 2];
        const currentAgents = makeSuccessResult(AgentList);
        const state = {currentAgents};
        expect(selectors.hasAgent(state)).toBe(true);
    }); 
});
