
// DO NOT MODIFY THIS FILE
// THIS FILE IS AUTOGENERATED BASED ON THE ASSETS IN THE ASSETS DIRECTORY
// ANY CHANGES HERE WILL BE OVERWRITTEN ON NEXT CI/CD BUILD
export const ICON_ACC = "assets/icon_acc.png";
export const BLURS_1 = "assets/blurs/1.png";
export const BLURS_0 = "assets/blurs/0.png";
export const BLURS_2 = "assets/blurs/2.png";
export const ICON_MYRIAD_ENGLISH = "assets/icon_myriad_english.png";
export const IC_HEART_PINK = "assets/ic_heart_pink.png";
export const LUGGAGE_TAG = "assets/luggage_tag.png";
export const IC_HEART_BLUE = "assets/ic_heart_blue.png";
export const PERCENTICONS_10 = "assets/percentIcons/10.png";
export const PERCENTICONS_0 = "assets/percentIcons/0.png";
export const PERCENTICONS_60 = "assets/percentIcons/60.png";
export const PERCENTICONS_100 = "assets/percentIcons/100.png";
export const PERCENTICONS_90 = "assets/percentIcons/90.png";
export const PERCENTICONS_20 = "assets/percentIcons/20.png";
export const PERCENTICONS_70 = "assets/percentIcons/70.png";
export const PERCENTICONS_50 = "assets/percentIcons/50.png";
export const PERCENTICONS_40 = "assets/percentIcons/40.png";
export const PERCENTICONS_30 = "assets/percentIcons/30.png";
export const PERCENTICONS_80 = "assets/percentIcons/80.png";
export const UCAS_LOGIN_BACKGROUND = "assets/ucas_login_background.png";
export const ICON_GF = "assets/icon_gf.png";
export const TICK_GREY = "assets/tick_grey.png";
export const ARROW_RIGHT = "assets/arrow_right.png";
export const ENGLISH_TEST_BOOKING_LOGO = "assets/english_test_booking_logo.png";
export const LOGO = "assets/logo.png";
export const LOGIN_GOOGLE = "assets/login/google.png";
export const LOGIN_EMAIL = "assets/login/email.png";
export const LOGIN_FACEBOOK = "assets/login/facebook.png";
export const LOGIN_APPLE = "assets/login/apple.png";
export const LOGIN_TWITTER = "assets/login/twitter.png";
export const ICON_FOREGROUND_LIGHT = "assets/icon_foreground_light.png";
export const HOUSE_FAVE = "assets/house_fave.png";
export const PROFILE_MOBILE_IMAGE = "assets/profile_mobile_image.png";
export const IC_HEART_OUTLINE_BLUE = "assets/ic_heart_outline_blue.png";
export const UCAS_LOGO = "assets/ucas_logo.png";
export const ICON_UNI = "assets/icon_uni.png";
export const CHECK_ON = "assets/check_on.png";
export const LOCK = "assets/lock.png";
export const HOUSE_GREY = "assets/house_grey.png";
export const HOUSE_HIGHLIGHTED = "assets/house_highlighted.png";
export const HOUSE_GREY_FAVE = "assets/house_grey_fave.png";
export const IC_HEART_OUTLINE_PINK = "assets/ic_heart_outline_pink.png";
export const SOLD_OUT = "assets/sold_out.png";
export const UCAS_LOGO_BRANDED = "assets/ucas_logo_branded.svg";
export const UCAS_LOGO_WHITE_SVG = "assets/ucas_logo_white_svg.svg";
export const RINGTONE = "assets/ringtone.mp3";
export const HOUSE_FAVE_HIGHLIGHTED = "assets/house_fave_highlighted.png";
export const UCAS_LOGO_BLACK = "assets/UCAS_Logo_BLACK.svg";
export const ISOMETRIC_PERSON_WITH_SEARCH = "assets/isometric_person_with_search.png";
export const ARROW_LEFT = "assets/arrow_left.png";
export const POPULARSUBJECTS_ARCHITECTURE_BUILDING_AND_PLANNING = "assets/popularSubjects/architecture_building_and_planning.jpeg";
export const POPULARSUBJECTS_COMPUTER_SCIENCE = "assets/popularSubjects/computer_science.jpeg";
export const POPULARSUBJECTS_DENTISTRY = "assets/popularSubjects/dentistry.jpeg";
export const POPULARSUBJECTS_MEDICINE_AND_ALLIED_SUBJECTS = "assets/popularSubjects/medicine_and_allied_subjects.jpeg";
export const POPULARSUBJECTS_SOCIAL_STUDIES = "assets/popularSubjects/social_studies.jpeg";
export const POPULARSUBJECTS_BUSINESS_AND_MANAGEMENT = "assets/popularSubjects/business_and_management.jpeg";
export const POPULARSUBJECTS_PSYCHOLOGY = "assets/popularSubjects/psychology.jpeg";
export const POPULARSUBJECTS_ART_AND_DESIGN = "assets/popularSubjects/art_and_design.jpeg";
export const POPULARSUBJECTS_LAW = "assets/popularSubjects/law.jpeg";
export const ICON_JOBS = "assets/icon_jobs.png";
export const UCAS_ROUND = "assets/UCAS_round.png";
export const DOWNLOAD_QR = "assets/download_qr.png";
export const ICON_FOREGROUND_DARK = "assets/icon_foreground_dark.png";
export const HOUSE = "assets/house.png";
export const CHECK_OFF = "assets/check_off.png";
export const TICK_GREEN = "assets/tick_green.png";
export const UNI_MAP_MARKER = "assets/uni_map_marker.png";
