import {BasicCourseInfo} from "./Course";
import {BasicScholarshipInfo} from "./Scholarship";

export interface HomeScreenItem {
    columnSpan: number;
    rowSpan: number;
    content: HomeScreenItemContent;
}

export type HomeScreenItemContent = HomeScreenLinkCard | HomeScreenBanner | HomeScreenCourseFavouriteList | HomeScreenProgressIndicator | HomeScreenScholarshipsList;

export interface HomeScreenLinkCard {
    type: "LinkCard";
    backgroundColor: string;
    title: string;
    titleColor: string;
    buttonText: string;
    buttonColor: string;
    link: string;
    orientation: "Vertical" | "Horizontal";
}

export interface HomeScreenBanner {
    type: "Banner";
    image: HomeScreenImage;
    title: string;
    buttonText: string;
    link: string;
    imageAspectRatio: number;
}

export interface HomeScreenCourseFavouriteList {
    type: "CourseFavouriteList";
    numberOfCourses: number;
    courses: BasicCourseInfo[];
}

export interface HomeScreenScholarshipsList {
    type: "ScholarshipsList";
    numberOfScholarships: number;
    scholarships: BasicScholarshipInfo[];
}

export interface HomeScreenProgressIndicator {
    type: "ProgressIndicator";
    backgroundColor: string;
    textColor: string;
    title: string;
    subtitle: string;
    completionFraction: number;
    progressText: string;
    progressSubText: string;
    buttonText: string;
    link: string;
}

export enum HomeScreenImage {
    IsometricPersonWithSearch = "IsometricPersonWithSearch"
}
