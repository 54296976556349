import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useEffect, useRef, useState} from "react";
import {useAlert} from "../../Alerts";
import {PrimaryButton, SecondaryButton} from "../../components/Buttons";
import {OutlinedButton} from "grantfairy-web-common";
import {ShowPasswordInputAdornment} from "../../components/ToggleButtons";
import * as selectors from "../../redux/selectors";
import * as StorageKeys from "../../StorageKeys";
import * as Str from "../../strings/Str";
import {POSTWithoutToken} from "../../util/api";
import {Title} from "../../views/Text";
import { connect } from "react-redux";

const EmailAddressState = Object.freeze({
    Exists: "Exists",
    DoesNotExist: "DoesNotExist",
    Invalid: "Invalid",
    SignedInWithDiffProvider: "SignedInWithDiffProvider",
    Loading: "Loading",
    Error: "Error"
});

const PasswordState = Object.freeze({
    AwaitingInput: "AwaitingInput",
    Loading: "Loading",
    Error: "Error"
});

const enterListener = callback => e => {
    if (e.key === "Enter") callback();
};

const FieldHeading = ({children, style}) => <p style={{fontSize: 18, color: "#444", fontWeight: 500, ...style}}>{children}</p>;

const InfoText = ({children, style}) => <p style={{maxWidth: 300, fontSize: 12, color: "#444", fontWeight: 300, whiteSpace: "pre-wrap", ...style}}>{children}</p>;
const EmailLogin = ({checkEmailAddress, signIn, signUp, forgotPassword, onClose, showSocialLogin, userCanSignUp}) => {

    const [alert, showAlert] = useAlert();

    const passwordField = useRef();

    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    

    //The actual email currently being used to sign in, set when the user hits 'next'
    const [emailToProcess, setEmailToProcess] = useState("");

    const [emailAddressStates, setEmailAddressStates] = useState({});

    const [passwordState, setPasswordState] = useState({state: PasswordState.AwaitingInput});

    const currentState = emailAddressStates[emailToProcess];
    const currentStateType = currentState?.state;

    const emailError = (() => {
        if (currentStateType === EmailAddressState.Invalid) return Str.invalid_email();
        if (currentStateType === EmailAddressState.SignedInWithDiffProvider) return Str.cant_sign_in_used_provider(currentState.provider);
        if (currentStateType === EmailAddressState.Error) return currentState.error;
        return null;
    })();

    const passwordVisible = currentStateType === EmailAddressState.DoesNotExist || currentStateType === EmailAddressState.Exists;
    const emailNextVisible = !passwordVisible;

    const isExistingAccount = currentStateType === EmailAddressState.Exists;
    const passwordAutofillHint = isExistingAccount ? "current-password" : "new-password";

    useEffect(() => {
        setEmailToProcess(null);
    }, [emailInput]);

    /** Code With Local validation */
    const [loginScreen, setLoginScreen] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const passwordInfo = loginScreen ? Str.welcome_existing_account() : Str.welcome_no_account();
    const passwordPlaceholder = loginScreen ? Str.your_password() : Str.new_password();
    const passwordError = passwordState?.error;

    useEffect(() => {
        if (!loginScreen && !userCanSignUp) {
            showSocialLogin(false);
        } else {
            showSocialLogin(true);
        }
    }, [loginScreen, userCanSignUp, showSocialLogin]);

    const onSignIn = () => {
        const email = emailInput;
        const password = passwordInput;
        checkEmail();
        checkPassword();
        if(emailValid.length > 0) return;
        if(passwordValid.length > 0) return;
        signIn(email, password, newState => {
            setPasswordState(newState);
        });
    };

    const onSignUp = () => {
        const email = emailInput;
        const password = passwordInput;
        checkEmail();
        checkPassword();
        if(emailValid.length > 0) return;
        if(passwordValid.length > 0) return;
        signUp(email, password, newState => {
            setPasswordState(newState);
        });
    };

    /** Local Validation */
    const [emailValid, setEmailValid] = useState("");
    const [emailValidated, setEmailValidated] = useState(false);
    const checkEmail = () => {
        const email = emailInput;
        if(email.length === 0) {
            setEmailValid(Str.enter_email());
            return;
        }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailValid(Str.invalid_email());
            return;
        }
        setEmailValid("");
        setEmailValidated(true);        
    };
    const onEmailNext = () => {
        checkEmail();
        if(emailValidated) {
            // @ts-ignore
            passwordField.current.focus();
        }
    };

    /** Act on enter_key */
    const [passwordValid, setPasswordValid] = useState("");
    const checkPassword = () => {
        const password = passwordInput;
        setPasswordValid("");
        if(password.length === 0) {
            setPasswordValid("Please enter your password");
            return;
        }
        if(validatePassword(password)) {
            setPasswordValid("");
        } else {
            setPasswordValid(Str.password_weak());
            return;
        }
        checkEmail();
    };

    const onPasswordNext = () => {
        checkEmail();
        checkPassword();
        if(loginScreen) {
            onSignIn();
        } else {
            onSignUp();
        }
    };

    const onForgotPassword = () => {
        showAlert(Str.loading());
        const email = emailInput;
        if (emailInput.length > 0) {
            forgotPassword(email, () => {
                showAlert(Str.password_reset(), Str.password_reset_info(email), Str.okay());
            });
        } else {
            showAlert("Please enter your email address first");
        }

    };

    const emailFocus = () => {
        setEmailValid("");
    };

    const passwordFocus = () => {
        setPasswordValid("");
    };

    if (!loginScreen && !userCanSignUp) {
        return (
            <div style={{width: 400, height: "100%", padding: "15px 15px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <p style={{ fontSize: 36, fontWeight: 600, marginTop: 0, lineHeight: 1, fontFamily: "Oswald" }}>We are retiring this app soon and Signup is now closed</p>
                <p style={{ fontSize: 18, paddingRight: "60px" }}>Our international content can be found with the hub at UCAS.com</p>
                <p style={{ fontSize: 18 }}>Continue the journey through the UCAS hub; the best resource for access to information regarding higher education opportunities in the UK</p>
                <SecondaryButton onClick={() => window.open("https://www.ucas.com/what-are-my-options/create-your-ucas-hub-today", "_self")} style={{marginTop: 20, borderRadius: 24}}>Get started with the UCAS Hub</SecondaryButton>
                <div style={{ fontSize: 18, marginTop: "120px" }}>
                    <p>Or if you have an existing international app login</p>
                    <SecondaryButton onClick={() => setLoginScreen(true)} style={{width: "100%"}}>Login</SecondaryButton>
                </div>
            </div>
        );
    }

    return (
        <div style={{width: "100%", minHeight: 330}}>
            {alert}
            <div style={{paddingTop: 60, textAlign: "center"}}>
            {loginScreen && <Title>{Str.sign_in_to_continue()}</Title>}
                {!loginScreen && <Title>{Str.sign_up_to_continue()}</Title>}
            </div>
            <div style={{padding: "-1 40px"}}>
                <FieldHeading style={{margin: 0, margiTop: 8}}>{Str.email()} *</FieldHeading>
                <TextField variant='outlined' style={{width: "100%", marginTop: 16, borderColor: "orange" }} 
                        type="email" placeholder={Str.your_email_address()} size="small"
                        id={"login-email-input"}
                        inputProps={{autoFocus: true}}
                        value={emailInput}
                        onChange={e => setEmailInput(e.target.value)}
                        onBlur={checkEmail}
                        autoComplete="username"
                        onKeyDown={enterListener(onEmailNext)}
                        onFocus={emailFocus}
                        />
                {emailValid && <p style={{color: "red", maxWidth:400}}>{emailValid}</p>}
                {emailError && <p style={{color: "red", maxWidth:400}}>{emailError}</p>}
                <p />
                <FieldHeading style={{margin: 0, margiTop: 8}}>
                    {loginScreen && Str.enter_password()}
                    {!loginScreen && Str.create_password()}&nbsp;
                *</FieldHeading>
                {!loginScreen && <InfoText>{passwordInfo}</InfoText> }
                <TextField variant="outlined" style={{width: "100%", marginTop: 16}}  placeholder={passwordPlaceholder} size="small" inputRef={passwordField}
                        autoComplete={passwordAutofillHint} value={passwordInput} type={showPassword ? "text" : "password"}
                        onChange={e => setPasswordInput(e.target.value)} 
                        onKeyDown={enterListener(onPasswordNext)}
                        onBlur={checkPassword}
                        onFocus={passwordFocus}
                        InputProps={{endAdornment: <ShowPasswordInputAdornment showPassword={showPassword} setShowPassword={setShowPassword}/>}}
                        id={"login-pwd-input"}
                        />
                {passwordValid && <p style={{color: "red", maxWidth:400}}>{passwordValid}</p>}
                {passwordError && <p style={{color: "red", maxWidth:400}}>{passwordError}</p>}
                <div style={{justifyContent: "space-between", marginTop: 16, textAlign: "center", display: emailNextVisible ? "flex" : "none"}}>
                    {loginScreen && <Button color="secondary" onClick={onForgotPassword} id={"forgot-pwd-btn"}>{Str.forgot_password()}</Button>}
                    {loginScreen && <PrimaryButton onClick={onSignIn} id={"login-btn"}>{Str.login()}</PrimaryButton>}
                    {!loginScreen && <PrimaryButton onClick={onSignUp} id={"signin-btn"}>{Str.create_account()}</PrimaryButton>}
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: 40}}>
                    {loginScreen && <p style={{margin: 8, textAlign: "center"}}>Not a member?</p>}
                    {!loginScreen && <p style={{margin: 8, textAlign: "center"}}>Already a member?</p>}
                    {loginScreen && <OutlinedButton id={"signin-btn"} buttonColor="primary" style={{flex: 1}} onClick={() => {
                        setLoginScreen(!loginScreen);
                        setEmailValid("");
                        // @ts-ignore
                        setPasswordState("");
                        // @ts-ignore
                        setEmailAddressStates("");
                    }} >{Str.create_account()}</OutlinedButton>}
                    {!loginScreen && <OutlinedButton id={"login-btn"}  buttonColor="primary" style={{flex: 1}} onClick={() => {
                        setLoginScreen(!loginScreen);
                        setPasswordValid("");
                        //  @ts-ignore
                        setPasswordState("");
                        // @ts-ignore
                        setEmailAddressStates("");
                    }} >{Str.login()}</OutlinedButton>}
                </div>
            </div>
        </div>
    );   
};
export const validatePassword = (password) => {
    const totalLength = password.length;
    const letterLength = password.replace(/[^A-Z]/gi, "").length;
    const numberLength = totalLength - letterLength;
    return totalLength >= 8 && letterLength > 0 && numberLength > 0;
};

const Container = ({onClose, redirectUrl, showSocialLogin, userCanSignUp}) => {

    const checkEmailAddress = (email, callback) => {
        POSTWithoutToken("Login/checkEmail", {email}).then(result => {
            if (!result.success) {
                callback({state: EmailAddressState.Error, error: result.error});
                return;
            }
            const {valid, exists, existingProvider} = result.result;
            if (!valid) {
                callback({state: EmailAddressState.Invalid});
            } else if (existingProvider != null) {
                callback({state: EmailAddressState.SignedInWithDiffProvider, provider: existingProvider});
            } else if (exists) {
                callback({state: EmailAddressState.Exists});
            } else {
                callback({state: EmailAddressState.DoesNotExist});
            }
        });
    };

    const signIn = (email, password, callback) => {
        if (password.trim().length === 0) {
            return;
        }
        POSTWithoutToken("Login/signIn", {email, password}).then(result => {
            if (result.success) {
                localStorage.setItem(StorageKeys.CURRENT_USER, JSON.stringify(result.result));
                window.location.replace(redirectUrl ?? "/");
            } else {
                callback({state: PasswordState.Error, error: result.error});
            }
        });
    };

    const signUp = (email, password, callback) => {
        if (!validatePassword(password)) {
            callback({state: PasswordState.Error, error: Str.password_weak()});
            return;
        }
        POSTWithoutToken("Login/signUp", {email, password}).then(result => {
            if (result.success) {
                localStorage.setItem(StorageKeys.CURRENT_USER, JSON.stringify(result.result));
                window.location.replace(redirectUrl ?? "/");
            } else {
                callback({state: PasswordState.Error, error: result.error});
            }
        });
    };

    const forgotPassword = (email, callback) => {
        POSTWithoutToken("Login/forgotPassword", {email}).then(() => {
            callback();
        });
    };

    return (
        <div style={{width: "100%"}}>
            <EmailLogin checkEmailAddress={checkEmailAddress} signIn={signIn} signUp={signUp} forgotPassword={forgotPassword} onClose={onClose} showSocialLogin={showSocialLogin} userCanSignUp={userCanSignUp}/>
        </div>
    );
};

const mapStateToProps = state => ({
    userCanSignUp: selectors.userCanSignUp(state)
});
const Connected = connect(mapStateToProps)(Container);

export default Connected;