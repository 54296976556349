import {Button} from "@mui/material";
import Colours from "../theme/Colours";
import {useGfTheme} from "../theme/GFThemeProvider";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const primaryStyles = (props) =>{
    return {
        "&:hover": {
            background: props.enabled ? props.background : Colours.DisabledButtonBackground,
            filter: props.enabled ? "brightness(90%)" : ""
        },
        color: props.foreground,
        background: props.background
    };
};

export const PrimaryButton = (props) => {
    const background = useGfTheme().primaryButtonColor;
    const foreground = useGfTheme().primaryButtonForeground;
    const style = primaryStyles({enabled: props.enabled ?? true, background, foreground});
    return <Button variant="contained" sx={style} {...props}/>;
};

export const SecondaryFilledButton = (props) => {
    const background = useGfTheme().secondaryButtonColor;
    const foreground = useGfTheme().secondaryButtonForeground;
    const style = primaryStyles({enabled: props.enabled ?? true, background, foreground});
    return <Button variant="contained" sx={style} {...props}/>;
};

const secondaryStyles = (props) => {
    return {
        "&:hover": {
            background: props.color + "20",
            border: `1px solid ${props.color}`
        },
        background: "white",
        color: props.color,
        border: `1px solid ${props.color}`
    };
};

export const SecondaryButton = (props) => {
    const color = useGfTheme().secondaryButtonColor;
    const style = secondaryStyles({enabled: !props.disabled, color});
    return <Button variant="outlined" sx={style} {...props}/>;
};

export const HSpacer = ({space}) => <div style={{width: space}}/>;

export const BackButton = ({title}) => {
    return (
        <span 
            style={{color: Colours.selectedTabColor, textDecoration: "underline", cursor: "pointer"}}>
            <div style={{display: "inline-flex", alignItems: "center", justifyContent: "flex-start"}}>
                <ArrowBack style={{fontSize: 32}}/>
                <HSpacer space={8}/>
                <span>{title.toUpperCase()}</span>
            </div>
        </span>
    );
};
