import {FormAnswerType, FormItem, RecordEntry} from "../../util/form/FormItem";
import {StringDictionary} from "../../types/StringDictionary";
import {MouseEventHandler, useMemo} from "react";
import {useSecondaryColour} from "../../theme/Theme";
import {useAlert} from "../../Alerts";
import * as FormUtil from "../../util/form/FormUtil";
import * as Str from "../../strings/Str";
import {Title} from "../Text";
import {Button, Icon} from "@mui/material";
import {PrimaryButton} from "../../components/Buttons";
import {FormAnswerSetter, FormItemView} from "./FormItemView";
import * as _ from "lodash";

interface RecordAnswerProps {
    item: RecordEntry;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    answer: FormAnswerType;
    setAnswer: FormAnswerSetter;
    validate: boolean;
    onViewFile: (originalName: string, fileName: string) => void;
    onDeleteFile: (fileName: string, isRecord: boolean, index: number, innerQuestionID: string) => void;
    onUploadFile: (file: string, originalName: string, isRecord: boolean, index: number, innerQuestionID: string) => void;
    allItems: FormItem[];
    allAnswers: StringDictionary;
}

const FormRecordView = ({item, handleLink, answer, setAnswer, validate, onViewFile, onDeleteFile, onUploadFile, allItems, allAnswers}: RecordAnswerProps) => {
    const records = useMemo(() => {
        if (answer == null) return [];
        try {
            return JSON.parse(answer);
        } catch (e) {
            return [];
        }
    }, [answer]);

    const color = useSecondaryColour();

    const [alert, showAlert] = useAlert();

    const updateAnswer = (index, questionID, answer) => {
        const newRecords = FormUtil.modifyRecordEntry(records, index, questionID, answer);
        setAnswer(JSON.stringify(newRecords));
    };

    const onAdd = () => {
        const newRecords = [...records, []];
        setAnswer(JSON.stringify(newRecords));
    };

    const onDelete = index => {
        showAlert(Str.delete_record(item.question), item.deleteMessage ?? Str.sure_delete_record(item.question), Str.delet(), Str.cancel, () => {
            const newRecords = [...records];
            newRecords.splice(index, 1);
            setAnswer(JSON.stringify(newRecords));
        });
    };

    return (
        <div id={item.id}>
            {alert}
            {records.map((record, index) => {
                const answersDic = _.cloneDeep(allAnswers);
                record.forEach(({question, answer}) => answersDic[question] = answer);
                const allItemsIncludingThis = [...allItems, ...item.items];
                const showingItems = FormUtil.filterShowingItems(item.items, allItemsIncludingThis, answersDic);
                return <div style={{border: "1px solid " + color, borderRadius: 16, padding: 16, marginBottom: 8, marginTop: 8}}>
                    <div style={{display: "flex"}}>
                        <Title style={{color: color, flex: 1}}>{item.question} {index + 1}</Title>
                        <Button onClick={() => onDelete(index)}><Icon>clear</Icon></Button>
                    </div>
                    <div>
                        {showingItems.map(showingItem => <FormItemView handleLink={handleLink} item={showingItem} formName={item.question} answer={answersDic[showingItem.id]} validate={validate}
                                                                setAnswer={answer => updateAnswer(index, showingItem.id, answer)} allItems={allItemsIncludingThis} allAnswers={answersDic}
                                                                onViewFile={onViewFile} onDeleteFile={file => onDeleteFile(file, true, index, showingItem.id)}
                                                                onUploadFile={(file, name) => onUploadFile(file, name, true, index, showingItem.id)}/>)}
                    </div>
                </div>;
            })}
            <div style={{display: "flex", justifyContent: "center", padding: 16}}>
                {/* TODO hardcoded string */}
                <PrimaryButton onClick={onAdd}>Add {item.question}</PrimaryButton>
            </div>
        </div>
    );

};

export default FormRecordView;
