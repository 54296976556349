import Icon from "@mui/material/Icon";
import {Paper} from "grantfairy-web-common";
import {connect} from "react-redux";
import {useAlert} from "../../Alerts";
import ColouredLink from "../../components/ColouredLink";
import * as FeatureFlags from "../../FeatureFlags";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {useTertiaryColour} from "../../theme/GFThemeProvider";
import {useIsLightTheme, usePrimaryColour, useSecondaryColour} from "../../theme/Theme";
import * as FirebaseUtil from "../../util/FirebaseUtil";
import ApplyButton from "../../views/ApplyButton";
import DataDependant from "../../views/DataDependant";
import {Tabbar} from "../../views/Tabbar";
import {Subtitle, Title} from "../../views/Text";
import {EnglishLanguageTestRequirement, FullCourseInfo} from "../../model/Course";
import {CSSProperties, ReactElement, useState} from "react";
import {ButtonBase} from "@mui/material";
import Styles from "../../theme/Styles";
import Colours from "../../theme/Colours";
import {VSpacer} from "../../components/Spacers";
import EnglishLanguageRequirementsTable from "../../views/EnglishLanguageRequirementsTable";
import {ProviderWebsiteButton, ProviderWebsiteButtonType} from "../../views/ProviderWebsiteButton";
import {SanitizedHtml} from "../../components/SanitizedHtml";
import {PrimaryButton, SecondaryButton} from "../../components/Buttons";
import TuitionFeeLocaleSetView from "../../views/TuitionFeeLocaleSetView";
import {useNavigate} from "react-router-dom";
import {handleProviderAnalytics} from "../universities/HandleProviderAnalytics";

const CourseDescription = ({course}: { course: FullCourseInfo }) => {
    const {summary, summaryTranslation} = course;
    const summaryStyle: CSSProperties = {whiteSpace: "pre-wrap"};
    const summaryComponent = <p style={summaryStyle}>{summary}</p>;
    const summaryTranslationComponent = <p style={summaryStyle}>{summaryTranslation}</p>;
    if (summaryTranslation == null || summaryTranslation === "") return summaryComponent;
    return <>{[summaryTranslationComponent, <hr/>, summaryComponent]}</>;
};

const AtasFlag = ({style = {}}) => (
    <div style={{display: "flex", ...style}}>
        <div style={{border: "2px solid " + useSecondaryColour(), padding: 12, borderRadius: 8, maxWidth: 400}}>
            <b>{Str.course_may_require_atas()}</b>
            <br/><br/>
            {Str.atas_details()}
            <br/><br/>
            <ColouredLink href="https://www.gov.uk/guidance/academic-technology-approval-scheme">{Str.learn_more_atas()}</ColouredLink>
        </div>
    </div>
);

const AccordionedView = ({children, title, style = {}}: { children: ReactElement, title: string, style?: CSSProperties }) => {
    const [isOpen, setOpen] = useState(false);
    const icon = isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down";
    return (
        <div style={{borderTop: Styles.standardBorder, borderBottom: Styles.standardBorder, width: "100%", ...style}}>
            <ButtonBase onClick={() => setOpen(o => !o)} style={{width: "100%"}}>
                <div style={{display: "flex", padding: "16px 0", width: "100%", textAlign: "left", alignItems: "center"}}>
                    <Subtitle style={{margin: 0, flex: 1, fontWeight: 600}}>{title}</Subtitle>
                    <Icon style={{color: useSecondaryColour()}}>{icon}</Icon>
                </div>
            </ButtonBase>
            {isOpen && children}
        </div>
    );
};

const MissingDetailsView = ({course, info}: { course: FullCourseInfo, info: string }) => {
    const handleAnalytics = () => handleProviderAnalytics("", course.providerID, "", course.providerCourseUrl);
    return (
        <div>
            {info}
            <VSpacer space={16}/>
            {course.providerCourseUrl != null && course.providerCourseUrl !== "" && (
                <div style={{textAlign: "center"}}>
                    <ProviderWebsiteButton
                        link={course.providerCourseUrl}
                        courseID={course.id}
                        providerID={course.providerID}
                        buttonType={ProviderWebsiteButtonType.CourseDetailsMissingRequirements}
                        analyticsHandler={handleAnalytics}
                    />
                    <VSpacer space={16}/>
                </div>
            )}
        </div>
    );
};

const CourseEntryRequirement = ({text, hasMultiple}: { text: string, hasMultiple: boolean }) => (
    <div>
        {hasMultiple && <Subtitle style={{color: Colours.secondaryText, margin: 0, flex: 1, fontWeight: 600}}>{Str.some_course_options_require()}</Subtitle>}
        <p style={{whiteSpace: "pre-wrap"}}>{text}</p>
    </div>
);

const EntryRequirements = ({course}: { course: FullCourseInfo }) => {
    if (course.entryRequirements.length === 0) {
        return <MissingDetailsView course={course} info={Str.no_entry_reqs_available()}/>;
    } else {
        const hasMultiple = course.entryRequirements.length > 1;
        return <>{course.entryRequirements.map((er, index) => <CourseEntryRequirement  key={index} text={er.text} hasMultiple={hasMultiple}/>)}</>;
    }
};

const CourseEnglishLanguageRequirement = ({tests, hasMultiple}: { tests: EnglishLanguageTestRequirement[], hasMultiple: boolean }) => (
    <div>
        {hasMultiple && <Subtitle style={{color: Colours.secondaryText, margin: 0, flex: 1, fontWeight: 600, marginBottom: 8}}>{Str.some_course_options_require()}</Subtitle>}
        <EnglishLanguageRequirementsTable tests={tests}/>
        <p>{Str.need_one_entry_requirement()}</p>
    </div>
);

const EnglishLanguageRequirements = ({course}: { course: FullCourseInfo }) => {
    if (course.englishLanguageRequirements.length === 0) {
        return <MissingDetailsView course={course} info={Str.no_entry_reqs_available()}/>;
    } else {
        const hasMultiple = course.englishLanguageRequirements.length > 1;
        return <>{course.englishLanguageRequirements.map((er, index) => <CourseEnglishLanguageRequirement key={index} tests={er.tests} hasMultiple={hasMultiple}/>)}</>;
    }
};

const TuitionFeeInfo = ({course}: { course: FullCourseInfo }) => {
    if (course.tuitionFees.length === 0) {
        return <MissingDetailsView course={course} info={Str.no_tuition_fee_data_available()}/>;
    } else {
        return <TuitionFeeLocaleSetView sets={course.tuitionFees}/>;
    }
};

const CourseInfoTab = ({course}: { course: FullCourseInfo }) => (
    <>
        <CourseDescription course={course}/>
        {course.isAtas && <AtasFlag style={{marginTop: 32}}/>}
        <AccordionedView title={Str.entry_requirements()} style={{marginTop: 32}}>
            <EntryRequirements course={course}/>
        </AccordionedView>
        <AccordionedView title={Str.english_language_requirements()}>
            <EnglishLanguageRequirements course={course}/>
        </AccordionedView>
        <AccordionedView title={Str.tuition_fees()}>
            <TuitionFeeInfo course={course}/>
        </AccordionedView>
    </>
);

const UniversityDetailsTab = ({university, course}) => {
    const addressLines = [university.address1, university.address2, university.address3, university.address4].filter(e => e != null && e !== "");
    const hasTranslation = university.descriptionTranslation !== "" && university.descriptionTranslation != null;
    const handleAnalytics = () => handleProviderAnalytics(university.name, university.id, university.address4, university.website);
    return (
        <div style={{padding: "0 16px"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <Title style={{margin: 0}}>{university.name}</Title>
                    {addressLines.map((e, index) => <Subtitle key={index} style={{margin: "8px 0"}}>{e}</Subtitle>)}
                </div>
                <div>
                    <img alt="logo" src={university.logo} style={{width: 72, height: 72}}/>
                </div>
            </div>
            <hr style={{margin: "16px 0", border: "1px solid #CCC"}}/>
            {hasTranslation && <SanitizedHtml html={university.descriptionTranslation}/>}
            {hasTranslation && <hr/>}
            <SanitizedHtml html={university.description}/>
            <div style={{padding: 16, display: "flex", justifyContent: "center"}}>
                <ProviderWebsiteButton
                    link={university.website}
                    courseID={course.id}
                    providerID={university.id}
                    buttonType={ProviderWebsiteButtonType.CourseDetailsProvider}
                    analyticsHandler={handleAnalytics}
                />
            </div>
        </div>
    );
};

interface FundingInfoTabProps {
    info: any;
    course: FullCourseInfo;
    onChoose: () => void;
}

const FundingInfoTab = ({info, course, onChoose}: FundingInfoTabProps) => (
    <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{textAlign: "center", padding: "32px 128px", margin: "auto", width: "50%", maxWidth: "20cm"}}>
            <p style={{fontSize: 50, marginTop: 8, marginBottom: 32}}>{Str.num_scholarships(info.count)}</p>
            <p style={{fontSize: 30, marginBottom: 32}}>{Str.scholarships_specific_to_course(course.title)}</p>
            <p style={{fontSize: 30, marginBottom: 32}}><b>{Str.add_course_to_profile()}</b></p>
            <PrimaryButton onClick={onChoose}>{Str.add_to_profile_and_view_wishlist()}</PrimaryButton>
        </div>
    </div>
);

const CourseApplyStateDisabledReasons = Object.freeze({
    provider_not_accepting: {reason: Str.cant_apply_this_course(), websiteButtonText: Str.website()},
    no_options_available: {reason: Str.cant_apply_this_course(), websiteButtonText: Str.website()},
    apply_via_ucas: {reason: Str.must_appply_ucas(), websiteButtonText: Str.go_to_ucas()},
    unknown: {reason: Str.generic_cant_apply(), websiteButtonText: null}
});


const ApplyTab = ({course}: { course: FullCourseInfo }) => {

    const secondary = useSecondaryColour();
    const applicationsEnabled = course.isAcceptingApplications;
    const notAcceptingApplicationsReasonId = course.notAcceptingApplicationsReason;
    const notAcceptingApplicationsReason = CourseApplyStateDisabledReasons[notAcceptingApplicationsReasonId] ?? CourseApplyStateDisabledReasons.unknown;
    const notAcceptingApplicationsAltUrl = course.notAcceptingApplicationsAltUrl;
    const hasAltUrl = notAcceptingApplicationsAltUrl != null && notAcceptingApplicationsReason.websiteButtonText != null;
    const handleAnalytics = () => handleProviderAnalytics("", course.providerID, "", course.providerCourseUrl);

    return (
        <div style={{width: "100%"}}>
            {!applicationsEnabled && (
                <div style={{display: "flex", padding: 64, alignItems: "center", justifyContent: "center"}}>
                    <Paper style={{overflowY: "auto", padding: 48, textAlign: "center"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        <Subtitle>{notAcceptingApplicationsReason.reason}</Subtitle>
                        {hasAltUrl && <ProviderWebsiteButton
                            title={notAcceptingApplicationsReason.websiteButtonText}
                            link={notAcceptingApplicationsAltUrl ?? ""}
                            buttonType={ProviderWebsiteButtonType.CourseDetailsApply}
                            courseID={course.id}
                            providerID={course.providerID}
                            style={{marginTop: 16}}
                            analyticsHandler={handleAnalytics}
                        />}
                    </Paper>
                </div>
            )}
            {applicationsEnabled && (
                <>
                    <Title style={{color: secondary, paddingLeft: 10}}>{Str.x_options_available(course.options.length)}</Title>
                    {course.options.map((option, index) => (
                        <div key={index} style={{borderBottom: "1px solid lightgrey", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10}}>
                            <p style={{whiteSpace: "pre-wrap", fontWeight: "bold", maxWidth: 400, marginRight: 16}}>{Str.option_x(index + 1)}:</p>
                            <p style={{whiteSpace: "pre-wrap", flex: 1}}>{option.info}</p>
                            <ApplyButton option={option}/>
                        </div>
                    ))}
                    {course.isAtas && <AtasFlag style={{marginTop: 32}}/>}
                </>
            )}
        </div>
    );
};

const courseDetailsTabs = {
    info: {path: "", name: Str.course(), enabled: true},
    apply: {path: "apply", name: Str.apply(), enabled: FeatureFlags.APPLICATIONS_ENABLED},
    funding: {path: "funding", name: Str.funding(), enabled: true},
    university: {path: "university", name: Str.university_lower(), enabled: true}
};

const visibleTabs = Object.values(courseDetailsTabs).filter(e => e.enabled);

interface CourseDetailsScreenProps {
    course: FullCourseInfo;
    provider: any;
    fundingInfo: any;
    onChoose: ((FullCourseInfo) => void);
    onSendToAgent: ((course: FullCourseInfo, provider: any) => void);
    isAgent: boolean;
    tabPath: string;
    onChangeTab: ((index: number, history: (url) => void) => void);
    hasAgent: boolean;
}

const CourseDetailsScreen = ({course, provider, fundingInfo, onChoose, onSendToAgent, isAgent, tabPath, onChangeTab, hasAgent}: CourseDetailsScreenProps) => {
    const [alert, showAlert] = useAlert();
    const history = useNavigate();

    const handleSendAgent = () => {
        // TODO hardcoded string
        showAlert(isAgent ? Str.sendToStudent() : Str.sendToAgent(), isAgent ? "Send this course to this student?" : Str.sendToAgentConfirm(), Str.send(), Str.cancel(), () => {
            onSendToAgent(course, provider);
        });
    };

    const isLightTheme = useIsLightTheme();
    const primary = usePrimaryColour();
    const tertiary = useTertiaryColour();

    const colour = isLightTheme ? tertiary : primary;

    const tabIndex = visibleTabs.map(e => e.path).indexOf(tabPath);
    if (tabIndex === -1) return null;

    const handleChangeTab = (index: number) => {
        onChangeTab(index, history);
    };

    return (
        <div style={{padding: 16}} className="noSelect">
            {alert}
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{width: 300}}/>
                <div style={{flex: 1, textAlign: "center", color: colour}}>
                    <Title style={{padding: 0, margin: 16}}>{course.title}</Title>
                    <Subtitle style={{padding: 0, margin: 0}}>{provider.name}</Subtitle>
                </div>
                <div style={{width: 300, textAlign: "right"}}>
                    {FeatureFlags.AGENT_ENABLED && (hasAgent || isAgent) && (
                        <SecondaryButton onClick={handleSendAgent}><Icon>share</Icon>&nbsp;&nbsp;{isAgent ? Str.sendToStudent() : Str.sendToAgent()}</SecondaryButton>
                    )}
                </div>
            </div>
            <Tabbar tabs={visibleTabs.map(e => e.name)} selected={tabIndex} setSelected={handleChangeTab} style={{marginTop: 16}}/>
            <div style={{marginTop: 16}}>
                {tabPath === courseDetailsTabs.info.path && <CourseInfoTab course={course}/>}
                {tabPath === courseDetailsTabs.apply.path && <ApplyTab course={course}/>}
                {tabPath === courseDetailsTabs.funding.path && <FundingInfoTab info={fundingInfo} course={course} onChoose={() => onChoose(course)}/>}
                {tabPath === courseDetailsTabs.university.path && <UniversityDetailsTab university={provider} course={course}/>}
            </div>
        </div>
    );
};

interface ContainerProps {
    courseID: string;
    course: any;
    provider: any;
    fundingInfo: any;
    getCourseInformation: ((courseId: string) => void);
    onChoose: ((FullCourseInfo) => void);
    onSendToAgent: ((course: FullCourseInfo, university: any) => void);
    isAgent: boolean;
    tabPath: string;
    onChangeTab: ((index: number, history: (url) => void) => void);
    hasAgent: boolean;
}

const Container = ({courseID, course, provider, fundingInfo, getCourseInformation, ...props}: ContainerProps) => {
    if (course == null) {
        getCourseInformation(courseID);
    }
    return <DataDependant data={course}>
        <CourseDetailsScreen course={course?.payload} provider={provider?.payload ?? {}} fundingInfo={fundingInfo?.payload ?? {}} {...props}/>
    </DataDependant>;
};

export const mapStateToProps = (state, {courseID, tab, makeUrlForCourse}) => {
    const course = selectors.courseDetailsForCourse(state, courseID);
    const provider = (course == null || course.payload == null) ? null : selectors.courseProviderDetails(state)[course.payload.providerID];
    return {
        courseID,
        course,
        provider,
        fundingInfo: selectors.courseFundingDetails(state, courseID),
        isAgent: FirebaseUtil.isAgent(),
        hasAgent: selectors.hasAgent(state),
        tabPath: tab ?? "",
        makeUrlForCourse
    };
};

const mapDispatchToProps = (dispatch, {courseID, makeUrlForCourse}) => {
    return {
        onChangeTab: (tabIndex: number, history: (url) => void) => history(`${makeUrlForCourse(courseID)}/${visibleTabs[tabIndex].path}`),
        onChoose: (course: FullCourseInfo) => dispatch(actions.setSelectedCourse(course)),
        onSendToAgent: (course: FullCourseInfo, provider: any) => {
            dispatch(actions.sendAgentCourse(course.id, course.title, provider.name));
            dispatch(actions.setAgentChatOpen(true));
        },
        getCourseInformation: courseId => {
            dispatch(actions.requestCourseDetails(courseId));
            dispatch(actions.requestCourseFundingDetails(courseId));
        }
    };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;
