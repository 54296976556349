import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Icon from "@mui/material/Icon";
import useEventListener from "@use-it/event-listener";
import {AspectFitImage} from "grantfairy-web-common";
import {useState} from "react";
import {usePrimaryColour} from "../theme/Theme";

const Arrow = ({onClick, visibility, icon, color}) => (
    <i onClick={onClick} className="material-icons hoverable" style={{fontSize: 64, color: color, visibility}}>{icon}</i>
);

const NavButtons = ({onNext, onPrev, prevVisibility, nextVisibility, color = "white"}) => (
    <div style={{position: "absolute", width: "calc(100% - 32px)", height: 200, padding: 16, top: "calc(50% - 100px)", display: "flex", justifyContent: "space-between"}}>
        <Arrow onClick={onPrev} visibility={prevVisibility} color={color} icon="arrow_back_ios"/>
        <Arrow onClick={onNext} visibility={nextVisibility} color={color} icon="arrow_forward_ios"/>
    </div>
);

const ThumbNail = ({url, isCurrent, thumbWidth, onSelect}) => {
    const primary = usePrimaryColour();
    const borderWidth = 4;
    const border = isCurrent ? `${borderWidth}px solid ${primary}` : "";
    const margin = isCurrent ? (4 - borderWidth) : 4;
    const style = {width: `calc(${thumbWidth}% - 8px)`, height: 70, margin, borderRadius: 8, border};
    return <AspectFitImage className="hoverable" style={style} src={url} onClick={onSelect}/>;
};

const ImageCarouselAlert = ({open, urls, selected, setSelected, onClose}) => {
    const thumbNailPerRow = urls.length < 10 ? urls.length : Math.min((urls.length + 1) / 2, 15);
    const thumbWidth = 100 / thumbNailPerRow;

    const onPrev = () => {
        if (selected > 0) setSelected(selected - 1);
    };
    const onNext = () => {
        if (selected < (urls.length - 1)) setSelected(selected + 1);
    };

    const prevVisibility = selected > 0 ? "" : "hidden";
    const nextVisibility = (selected < (urls.length - 1)) ? "" : "hidden";

    useEventListener("keydown", ({key}) => {
        if (key === "ArrowLeft" || key === "ArrowUp") onPrev();
        if (key === "ArrowRight" || key === "ArrowDown") onNext();
        if (key === "Escape") onClose();
    });

    return (
        <Dialog open={open} fullWidth={true} maxWidth="xl" onBackdropClick={onClose}>
            <div style={{background: "#222"}} className="noSelect">
                <NavButtons onNext={onNext} onPrev={onPrev} nextVisibility={nextVisibility} prevVisibility={prevVisibility}/>
                <div style={{position: "absolute", width: "100%", textAlign: "right", color: "white"}}>
                    <Button onClick={onClose}><Icon style={{color: "white"}} fontSize="large">clear</Icon></Button>
                </div>
                <div style={{textAlign: "center"}}>
                    <AspectFitImage backgroundSize="contain" src={urls[selected]} style={{height: "calc(100vh - 240px)", width: "100%"}}/>
                </div>
                <div style={{flex: 1, display: "flex", flexWrap: "wrap", alignContent: "flex-start", justifyContent: "center"}}>
                    {urls.map((url, index) => <ThumbNail key={index} onSelect={() => setSelected(index)} isCurrent={index === selected} url={url} thumbWidth={thumbWidth}/>)}
                </div>
            </div>
        </Dialog>
    );
};

const ImageCarousel = ({urls, primary}) => {
    const [selected, setSelected] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);

    const thumbNailPerRow = urls.length < 10 ? urls.length : Math.min((urls.length + 1) / 2, 15);
    const thumbWidth = 100 / thumbNailPerRow;

    const onPrev = () => {
        if (selected > 0) setSelected(selected - 1);
    };
    const onNext = () => {
        if (selected < (urls.length - 1)) setSelected(selected + 1);
    };

    const prevVisibility = selected > 0 ? "" : "hidden";
    const nextVisibility = (selected < (urls.length - 1)) ? "" : "hidden";
    return <>
        <ImageCarouselAlert urls={urls} selected={selected} setSelected={setSelected} open={alertOpen} onClose={() => setAlertOpen(false)}/>
        <div style={{height: 500, position: "relative", display: "flex", flexDirection: "column"}}>
            <NavButtons color={primary} onNext={onNext} onPrev={onPrev} nextVisibility={nextVisibility} prevVisibility={prevVisibility}/>
            <div style={{textAlign: "center", flex: 1}}>
                <AspectFitImage backgroundSize={"contain"} src={urls[selected]} style={{height: "100%", width: "100%"}} onClick={() => setAlertOpen(true)}/>
            </div>
            {urls.length > 1 && (
                <div style={{display: "flex", flexWrap: "wrap", alignContent: "flex-start", justifyContent: "center"}}>
                    {urls.map((url, index) => <ThumbNail key={index} onSelect={() => setSelected(index)} isCurrent={index === selected} url={url} thumbWidth={thumbWidth}/>)}
                </div>
            )}
        </div>
    </>;
};

export default ImageCarousel;