import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {useState} from "react";
import SegmentedControl from "../components/SegmentedControl";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {mainSectionTheme} from "../theme/Theme";
import Dimens from "../theme/Dimens";

const SegmentedControlTestPage = () => {
    const [selected, setSelected] = useState(2);

    const options = ["one", "two", "three", "four", "five"];
    const doNothing = () => {};

    return (
        <div style={{margin: Dimens.DoubleMargin}}>
            <MuiThemeProvider theme={mainSectionTheme}>
                <GFThemeProvider value={sections.Main}>
                    <SegmentedControl options={options} startAnswer={doNothing} selectedIndex={selected} setSelectedIndex={setSelected}/>
                </GFThemeProvider>
            </MuiThemeProvider>
        </div>
    );
};

export default SegmentedControlTestPage;
