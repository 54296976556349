import {apiCallStateUtil, Util} from "grantfairy-web-common";
import {all, call, put, select, take, takeEvery, takeLatest} from "redux-saga/effects";
import * as Applications from "../Applications";
import {ACCOMMODATION_APPLICATION_STATUS} from "../Applications";
import * as Constants from "../Constants";
import {
    ChatOpenEvent,
    ChatSendMessageEvent,
    CourseApplicationCreatedEvent,
    LoginEvent,
    LearnerViewsSearchResultEvent,
    LearnerFavouritesSearchResultEvent,
    LearnerProgressesApplicationThroughCartEvent,
    LearnerBeginsAccommodationApplicationEvent,
    LearnerSwitchesLanguagesEvent, LearnerSubmitsAccommodationApplicationEvent
} from "../model/AnalyticsEvents";
import * as Property from "../Property";
import {GoogleAnalyticsService} from "../services/analytics/GoogleAnalyticsService";
import * as Str from "../strings/Str";
import * as api from "../util/api";
import * as api2 from "../util/api2";
import {ApiResultState} from "../util/ApiResult";
import * as CVLibraryApi from "../util/CVLibraryApi";
import {CourseDeepLink, DeepLinkUtil} from "../util/DeepLink";
import {currentUser} from "../util/FirebaseUtil";
import * as FirebaseUtil from "../util/FirebaseUtil";
import * as FormUtil from "../util/form/FormUtil";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as englishLanguageArticlesSlice from "./slices/englishLanguageArticles";
import * as englishLanguageConfigSlice from "./slices/englishLanguageConfig";
import * as homeScreenItemsSlice from "./slices/homeScreenItems";
import * as notificationSettingsAnswersSlice from "./slices/settings/notificationSettingsAnswers";
import * as notificationSettingsFormItemsSlice from "./slices/settings/notificationSettingsFormItems";
import {history} from "./store";

export function* requestAppInit() {
    const result = yield call(api.POST, "Dashboard/appOpened", {});
    yield put(actions.gotDashboard(result));
    if (result.result.needsSignIn) {
        yield put(actions.requestLogin(false));
    }
    if (result.result.onboardedState !== "email_verification_needed")
    {
        yield put(actions.doneAppInit());
    }
}

export function *watchRequestAppInit() {
    yield takeEvery(actions.REQUEST_APP_INIT, requestAppInit);
}

export function* doneAppInit() {
    yield put(homeScreenItemsSlice.actions.request());

    yield put(actions.requestLogin(false));
    yield put(actions.requestPopularSubjects());
    yield put(actions.requestCourseSearchOptions());
    yield put(actions.requestApplicationInfo());
    yield put(actions.requestUniApplicationList());
    yield put(actions.requestCourseFavouritesList());
    yield put(actions.requestSelectedCourse());
    yield put(actions.requestFundingList());

    yield put(actions.requestAccommodationList());
    yield put(actions.requestAccommodationFavourites());
    yield put(actions.requestAccApplicationInfo());
    yield put(actions.requestAccommodationApplicationList());

    yield put(actions.requestJobsConfig());
    yield put(actions.requestJobsFavourites());

    yield put(actions.requestUniversityList());
    yield put(actions.requestUniversityFavourites());

    yield put(englishLanguageArticlesSlice.actions.request());
    yield put(englishLanguageConfigSlice.actions.request());

    yield put(actions.requestSettings());
    yield put(notificationSettingsFormItemsSlice.actions.request());

    yield put(actions.requestAgentInfo());

    yield put(actions.appFocused(false));

}

export function* watchDoneAppInit() {
    yield takeEvery(actions.DONE_APP_INIT, doneAppInit);
}

export function* requestAppFocused({tryPush}) {
    let token = null;
    if (tryPush) {
        token = yield FirebaseUtil.getFcmToken();
    }
    const result = yield call(api.POST, "Dashboard/main", {FCM: token, version: Constants.VERSION, new_open: 1});
    yield put(actions.gotAppFocused(result));
}

export function* watchAppFocused() {
    yield takeEvery(actions.APP_FOCUSED, requestAppFocused);
}

export function* requestDashboard() {
    const result = yield call(api.POST, "Dashboard/appOpened", {});
    yield put(actions.gotDashboard(result));
}

export function* watchRequestDashboard() {
    yield takeEvery(actions.REQUEST_DASHBOARD, requestDashboard);
}

export function* requestProfile() {
    yield put(actions.requestedProfile());
    const result = yield call(api2.getRequest, "profile/info");
    yield put(actions.gotProfile(result));
}

export function* watchRequestProfile() {
    yield takeEvery(actions.REQUEST_PROFILE, requestProfile);
}

export function* requestOnboarding() {
    yield put(actions.requestedOnboarding());
    const result = yield call(api2.getRequest, "profile/onboardingInfo");
    yield put(actions.gotOnboarding(result));
}
export function* watchRequestOnboarding() {
    yield takeEvery(actions.REQUEST_ONBOARDING, requestOnboarding);
}

export function* requestLogin(action) {
    const {forceVerify} = action;
    const hasSentVerificationEmailResult = yield select(selectors.hasSentVerificationEmail);
    const needsToVerify = forceVerify || !hasSentVerificationEmailResult?.payload;
    yield put(actions.requestedLogin());
    const result = yield call(api.POST, "Users/login", {doVerify: needsToVerify ? 1 : 0});
    yield put(actions.gotLogin(result));
}
export function* watchRequestLogin() {
    yield takeEvery(actions.REQUEST_LOGIN, requestLogin);
}

export function* requestCourseList() {
    const filters = yield select(selectors.courseSearchFilters);
    if (filters == null || Object.keys(filters).length === 1) {
        return;
    }
    const args = {levelOfStudy: filters.levelOfStudy};
    if (filters.searchQuery) {
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerViewsSearchResultEvent(user?.uid ?? "", "courses", filters.searchQuery));
        args.search = filters.searchQuery;
    }
    if (filters.providerID) {
        args.providerID = filters.providerID;
    }
    if (filters.studyMode) {
        args.studyMode = filters.studyMode;
    }

    args.filterByEnglishLanguageRequirements = filters.filterByEnglishLanguageRequirements ? "1" : "0";
    // We don't support grouping by provider anymore
    args.groupByProvider = "0";

    yield put(actions.requestedCourseList());
    const result = yield call(api2.getRequest, "courses", args);
    yield put(actions.gotCourseList(result));
}

export function* watchRequestCourseList() {
    yield takeEvery(actions.REQUEST_COURSE_LIST, requestCourseList);
}

export function* requestCourseSearchOptions(action) {
    const {courseListMustReload} = action;
    const courseSearchOptionsAlreadyInPlace = yield select(selectors.courseSearchOptions);
    const existingSearchDone = yield select(selectors.courseSearchResult)?.payload !== null;
    const alreadySetEnglishQualifications = courseSearchOptionsAlreadyInPlace?.englishQualificationsPresent;

    yield put(actions.requestedCourseSearchOptions());
    const result = yield call(api2.getRequest, "courses/searchOptions");
    yield put(actions.gotCourseSearchOptions(result));

    if (existingSearchDone && (courseListMustReload || result.result.englishQualificationsPresent !== alreadySetEnglishQualifications)) {
        yield put(actions.requestCourseList());
    }
}

export function* watchRequestCourseSearchOptions() {
    yield takeEvery(actions.REQUEST_COURSE_SEARCH_OPTIONS, requestCourseSearchOptions);
}

export function* requestPopularSubjects() {
    const popularSubjectsResult = yield call(api2.getRequest, "popularSubjects");
    yield put(actions.gotPopularSubjects(popularSubjectsResult));
}

export function* watchRequestPopularSubjects() {
    yield takeEvery(actions.REQUEST_POPULAR_SUBJECTS, requestPopularSubjects);
}

export function* watchCourseSearchFilters() {
    yield takeLatest([actions.SET_COURSE_SEARCH_QUERY, actions.SET_COURSE_SEARCH_PROVIDER_ID, actions.SET_COURSE_SEARCH_LOS, actions.SET_COURSE_SEARCH_STUDY_MODE, actions.SET_COURSE_SEARCH_ENGLISH_LANGUAGE_REQUIREMENTS], requestCourseList);
}

export function* setCourseSearchLos(action) {
    const {los} = action;
    yield call(api2.postRequest, "courses/updateLos", {levelOfStudy: los});
}
export function* watchSetCourseSearchLos() {
    yield takeEvery(actions.SET_COURSE_SEARCH_LOS, setCourseSearchLos);
}

export function* requestCourseDetailsIfNeeded(action) {
    const {id} = action;
    const currentResult = yield select(selectors.courseDetailsForCourse, id);
    if (resultIsOk(currentResult)) {
        return;
    }
    yield put(actions.requestedCourseDetails(id));
    const result = yield call(api2.getRequest, `courses/${id}`);
    const providerID = result?.result?.course?.providerID;
    if (providerID != null) {
        yield put(actions.requestCourseProviderDetails(providerID));
    }
    yield put(actions.gotCourseDetails(id, result));
}

export function* watchRequestCourseDetails() {
    yield takeEvery(actions.REQUEST_COURSE_DETAILS, requestCourseDetailsIfNeeded);
}

export function* requestCourseFundingDetailsIfNeeded(action) {
    const {courseID} = action;
    const existingDetails = yield select(selectors.courseFundingDetails, courseID);
    if (resultIsOk(existingDetails)) return;
    yield put(actions.requestedCourseFundingDetails(courseID));
    const result = yield call(api2.getRequest, `courses/${courseID}/fundingInfo`);
    yield put(actions.gotCourseFundingDetails(courseID, result));
}

export function* watchRequestCourseFundingDetails() {
    yield takeEvery(actions.REQUEST_COURSE_FUNDING_DETAILS, requestCourseFundingDetailsIfNeeded);
}

export function* requestCourseProviderDetailsIfNeeded(action) {
    const {id} = action;
    const existingDetails = yield select(selectors.courseProviderDetails);
    if (resultIsOk(existingDetails[id])) return;
    yield put(actions.requestedCourseProviderDetails(id));
    const result = yield call(api2.getRequest, `providers/${id}`);
    yield put(actions.gotCourseProviderDetails(id, result));
}

export function* watchRequestCourseProviderDetails() {
    yield takeEvery(actions.REQUEST_COURSE_PROVIDER_DETAILS, requestCourseProviderDetailsIfNeeded);
}

export function* setCourseFave(action) {
    const {course, fave} = action;
    const {id} = course;
    if (fave) {
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerFavouritesSearchResultEvent(user.uid ?? "", course.id, course.providerID, "courses"));
        yield call(api2.postRequest, "courses/favourites", {courseID: id});
    } else {
        yield call(api2.deleteRequest, "courses/favourites", {courseID: id});
    }
}

export function* watchCourseFave() {
    yield takeEvery(actions.SET_COURSE_FAVE, setCourseFave);
}

export function* requestJobsConfig() {
    const result = yield call(api.POST, "Jobs/info", {});
    yield put(actions.gotJobsConfig(result));
}

export function* watchRequestJobsConfig() {
    yield takeEvery(actions.REQUEST_JOBS_CONFIG, requestJobsConfig);
}

export function* waitForJobConfig() {
    yield call(waitForState, state => selectors.jobsConfig(state)?.state === ApiResultState.Success);
}

export function* requestJobList() {
    yield call(waitForJobConfig);
    const configResult = yield select(selectors.jobsConfig);
    const filters = yield select(selectors.jobSearchFilters);
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new LearnerViewsSearchResultEvent(user?.uid ?? "", "jobs", filters.searchQuery ?? ""));
    const existingResult = yield select(selectors.jobSearchResultForFilters, filters);
    if (resultIsOk(existingResult)) {
        return;
    }
    const config = configResult.payload;
    yield put(actions.requestedJobList(filters));
    const result = yield call(CVLibraryApi.searchJobs, config, filters);
    yield put(actions.gotJobList(filters, result));
}

export function* watchRequestJobList() {
    yield takeEvery(actions.REQUEST_JOB_LIST, requestJobList);
}

export function* watchSetJobSearchFilters() {
    yield takeEvery(actions.SET_JOB_SEARCH_FILTERS, function* () {
        yield put(actions.requestJobList());
    });
}

export function* requestJobDetailsIfNeeded(action) {
    const {id} = action;
    const existingDetails = yield select(selectors.jobDetails, id);
    if (resultIsOk(existingDetails)) return;
    yield call(waitForJobConfig);
    const configResult = yield select(selectors.jobsConfig);
    const config = configResult.payload;
    yield put(actions.requestedJobDetails(id));
    const result = yield call(CVLibraryApi.getJob, config, id);
    yield put(actions.gotJobDetails(id, result));
}

export function* watchRequestJobDetails() {
    yield takeEvery(actions.REQUEST_JOB_DETAILS, requestJobDetailsIfNeeded);
}

export function* requestJobsFavourites() {
    const result = yield call(api.POST, "Jobs/getFavourites", {});
    yield put(actions.gotJobsFavourites(result));
}

export function* watchRequestJobsFavourites() {
    yield takeEvery(actions.REQUEST_JOBS_FAVOURITES, requestJobsFavourites);
}

export function* setJobFave(action) {
    const fave = action.fave;
    /** @type {import("../model/Job").Job} */
    const job = action.job;
    if (fave) {
        const postedUnix = job.posted == null ? null : (job.posted.getTime() / 1000);
        const params = {
            jobID: job.id,
            title: job.title,
            logo: job.logo,
            salary: job.salary ?? "",
            description: job.description,
            posted: postedUnix,
            valid: job.valid ? 1 : 0
        };
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerFavouritesSearchResultEvent(user.uid ?? "", job.id.toString(), "", "jobs"));
        yield call(api.POST, "Jobs/addFavourite", params);
    } else {
        yield call(api.POST, "Jobs/removeFavourite", {jobID: job.id});
    }
}

export function* watchJobFave() {
    yield takeEvery(actions.SET_JOB_FAVE, setJobFave);
}

export function* requestUniversityList() {
    const result = yield call(api2.getRequest, "providers");
    yield put(actions.gotUniversityList(result));
}

export function* watchRequestUniversityList() {
    yield takeEvery(actions.REQUEST_UNIVERSITY_LIST, requestUniversityList);
}

export function* requestUniversityFavourites() {
    const result = yield call(api2.getRequest, "providers/favourites");
    yield put(actions.gotUniversityFavourites(result));
}

export function* watchRequestUniversityFavourites() {
    yield takeEvery(actions.REQUEST_UNIVERSITY_FAVOURITES, requestUniversityFavourites);
}

export function* setUniversityFave(action) {
    const {university, fave} = action;
    const id = university.id;
    if (fave) {
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerFavouritesSearchResultEvent(user.uid ?? "", university.id, "", "universities"));
        yield call(api2.postRequest, "providers/favourites", {id});
    } else {
        yield call(api2.deleteRequest, "providers/favourites", {id});
    }
}

export function* watchUniversityFave() {
    yield takeEvery(actions.SET_UNIVERSITY_FAVE, setUniversityFave);
}

export function* requestCourseFavouritesList() {
    const result = yield call(api2.getRequest, "courses/favourites");
    yield put(actions.gotCourseFavouritesList(result));
}

export function* watchRequestCourseFavouritesList() {
    yield takeEvery(actions.REQUEST_COURSE_FAVOURITES_LIST, requestCourseFavouritesList);
}

export function* requestSelectedCourse() {
    const result = yield call(api2.getRequest, "courses/selected");
    yield put(actions.gotSelectedCourse(result));
}

export function* watchRequestSelectedCourse() {
    yield takeEvery(actions.REQUEST_SELECTED_COURSE, requestSelectedCourse);
}

export function* requestFundingList() {
    yield put(actions.requestedFundingList());
    const result = yield call(api.POST, "Funding/list", {});
    yield put(actions.gotFundingList(result));
}

export function* watchRequestFundingList() {
    yield takeEvery(actions.REQUEST_FUNDING_LIST, requestFundingList);
}

export function* requestScholarshipDetailsIfNeeded(action) {
    const {uid, version} = action;
    const existingDetails = yield select(selectors.scholarshipDetails, uid, version);
    if (resultIsOk(existingDetails)) return;
    yield put(actions.requestedScholarshipDetails(uid, version));
    const result = yield call(api.POST, "Funding/getScholarship", {scholarshipID: uid, version});
    yield put(actions.gotScholarshipDetails(uid, version, result));
}

export function* watchRequestScholarshipDetails() {
    yield takeEvery(actions.REQUEST_SCHOLARSHIP_DETAILS, requestScholarshipDetailsIfNeeded);
}

export function* moveScholarship(action) {
    const {scholarshipID, version, tab} = action;
    yield put(actions.requestedFundingList());
    yield call(api.POST, "Funding/moveScholarship", {scholarshipID, version, tab});
    yield requestFundingList();
}

export function* watchMoveScholarship() {
    yield takeEvery(actions.MOVE_SCHOLARSHIP, moveScholarship);
}

export function* reportScholarship(action) {
    const {scholarshipID, version, text} = action;
    yield call(api.POST, "Funding/reportScholarship", {scholarshipID, version, description: text});
}

export function* watchReportScholarship() {
    yield takeEvery(actions.REPORT_SCHOLARSHIP, reportScholarship);
}

export function* requestAccommodationList() {
    yield put(actions.requestedAccommodationList());
    const filters = yield select(selectors.accommodationFilters);
    const params = Property.filtersToApiParams(filters);
    const result = yield call(api.POST, "Accommodation/Properties/list", params);
    yield put(actions.gotAccommodationList(result));
}

export function* watchRequestAccommodationList() {
    yield takeEvery(actions.REQUEST_ACCOMMODATION_LIST, requestAccommodationList);
}

export function* watchAccommodationFilters() {
    yield takeEvery(actions.COMMIT_ACCOMMODATION_FILTERS, requestAccommodationList);
}

export function* requestAccommodationCount() {
    const filters = yield select(selectors.accommodationFiltersDraft);
    const params = Property.filtersToApiParams(filters);
    const result = yield call(api.POST, "Accommodation/Properties/resultCount", params);
    yield put(actions.gotAccommodationCount(result));
}

export function* watchAccommodationFiltersDraft() {
    yield takeEvery(actions.SET_ACCOMMODATION_FILTERS, requestAccommodationCount);
}

export function* requestAccommodationFavourites() {
    const result = yield call(api.POST, "Accommodation/Properties/favourites", {});
    yield put(actions.gotAccommodationFavourites(result));
}

export function* watchRequestAccommodationFavourites() {
    yield takeEvery(actions.REQUEST_ACCOMMODATION_FAVOURITES, requestAccommodationFavourites);
}

export function* setAccommodationFave(action) {
    const {property, fave} = action;
    if (fave) {
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerFavouritesSearchResultEvent(user.uid ?? "", property.uid, "", "rooms"));
    }
    yield call(api.POST, "Accommodation/Properties/toggleFavourite", {propertyID: property.uid, favourite: fave ? 1 : 0});
}

export function* watchAccommodationFave() {
    yield takeEvery(actions.SET_ACCOMMODATION_FAVE, setAccommodationFave);
}

export function* requestAccommodationPropertyDetailsIfNeeded(action) {
    const {uid} = action;
    const accommodationDetails = yield select(selectors.accommodationPropertyDetails);
    if (resultIsOk(accommodationDetails[uid])) return;
    yield put(actions.requestedPropertyDetails(uid));
    const result = yield call(api.POST, "Accommodation/Properties/get", {propertyID: uid});
    yield put(actions.gotPropertyDetails(uid, result));
}

export function* watchRequestAccommodationPropertyDetails() {
    yield takeEvery(actions.REQUEST_PROPERTY_DETAILS, requestAccommodationPropertyDetailsIfNeeded);
}

export function* requestApplicationInfo() {
    const result = yield call(api2.getRequest, "applications/info");
    yield put(actions.gotApplicationInfo(result));
}

export function* watchRequestApplicationInfo() {
    yield takeEvery(actions.REQUEST_APPLICATION_INFO, requestApplicationInfo);
}

export function* requestAccommodationApplicationInfo() {
    const result = yield call(api.POST, "Applications/getInfo", {applicationForm: 2});
    yield put(actions.gotAccApplicationInfo(result));
}

export function* watchRequestAccommodationApplicationInfo() {
    yield takeEvery(actions.REQUEST_ACC_APPLICATION_INFO, requestAccommodationApplicationInfo);
}

export function* setApplicationFormAnswer(action) {
    const {questionID, answer} = action;
    const result = yield call(api.POST, "Applications/save", {question: questionID, answer});
    if (result.success) {
        yield put(actions.didSetApplicationFormAnswer(questionID, answer));
    }
}

export function* watchSetApplicationFormAnswer() {
    yield takeEvery(actions.SET_APPLICATION_FORM_ANSWER, setApplicationFormAnswer);
}

export function* setApplicationFormChangedCourseAndProfileLoad() {
    yield put(actions.setApplicationFormChangedSinceCourseLoad(true));
    yield put(actions.setApplicationFormChangedSinceProfileLoad(true));
}

export function* watchSaveApplicationForm() {
    yield takeEvery(actions.DID_SET_APPLICATION_FORM_ANSWER, setApplicationFormChangedCourseAndProfileLoad);
}

export function* refreshProfileIfApplicationFormChanged(action) {
    if (action.path.startsWith("/profile")) {
        const recentChangesPresent = yield select(selectors.applicationFormChangedSinceProfileLoad);
        if (recentChangesPresent) {
            yield put(actions.setApplicationFormChangedSinceProfileLoad(false));
            yield put(actions.requestProfile());
        }
    }
}

export function* watchRefreshProfileIfApplicationFormChanged() {
    yield takeEvery(actions.PATH_CHANGED, refreshProfileIfApplicationFormChanged);
}

export function* refreshCourseListIfApplicationFormChanged(action) {
    if (action.path.startsWith("/courses")) {
        const recentChangesPresent = yield select(selectors.applicationFormChangedSinceCourseLoad);
        if (recentChangesPresent) {
            yield put(actions.setApplicationFormChangedSinceCourseLoad(false));
            yield put(actions.requestCourseSearchOptions(false));
        }
    }
}

export function* watchRefreshCourseListIfApplicationFormChanged() {
    yield takeEvery(actions.PATH_CHANGED, refreshCourseListIfApplicationFormChanged);
}

export function* setProfileFieldChangedSinceApplicationLoad() {
    yield put(actions.setProfileDetailsChangedSinceApplicationFormLoad(true));
}

export function* watchSaveProfileField() {
    yield takeEvery(actions.DID_SET_PROFILE_FIELD, setProfileFieldChangedSinceApplicationLoad);
}

export function* refreshApplicationIfProfileDetailsChanged(action) {
    if (action.path.startsWith("/applications")) {
        const recentChangesPresent = yield select(selectors.profileChangedSinceApplicationLoad);
        if (recentChangesPresent) {
            yield put(actions.setProfileDetailsChangedSinceApplicationFormLoad(false));
            yield put(actions.requestApplicationInfo());
        }
    }
}

export function* watchRefreshApplicationIfProfileDetailsChanged() {
    yield takeEvery(actions.PATH_CHANGED, refreshApplicationIfProfileDetailsChanged);
}

export function* requestUniApplicationList() {
    const result = yield call(api2.getRequest, "applications");
    yield put(actions.gotUniApplicationList(result));
}

export function* watchRequestUniApplicationList() {
    yield takeEvery(actions.REQUEST_UNI_APPLICATION_LIST, requestUniApplicationList);
}

export function* requestUniApplication(action) {
    const {uid} = action;
    yield put(actions.requestedUniApplication(uid));
    const result = yield call(api2.getRequest, `applications/${uid}`);
    yield put(actions.gotUniApplication(uid, result));
    yield put(actions.autoSelectApplicationDocuments(uid));
}

export function* watchRequestUniApplication() {
    yield takeEvery(actions.REQUEST_UNI_APPLICATION, requestUniApplication);
}

export function* requestAccommodationApplicationList() {
    const result = yield call(api.POST, "Applications/getApplications", {applicationForm: 2});
    yield put(actions.gotAccommodationApplicationList(result));
}

export function* watchRequestAccommodationApplicationList() {
    yield takeEvery(actions.REQUEST_ACCOMMODATION_APPLICATION_LIST, requestAccommodationApplicationList);
}

export function* applyForCourse(action) {
    const {optionID} = action;
    const result = yield call(api2.postRequest, "applications", {optionID});
    yield put(actions.requestUniApplicationList());
    const newID = result.result.applicationID;
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new CourseApplicationCreatedEvent(user.uid, newID));
    yield call(history.push, "/applications/apply/" + newID);
}

export function* watchApplyForCourse() {
    yield takeEvery(actions.APPLY_FOR_COURSE, applyForCourse);
}

export function* applyForTenancy(action) {
    const {tenancyID} = action;
    const result = yield call(api.POST, "Applications/sendAccApplication", {tenancyID, status: ACCOMMODATION_APPLICATION_STATUS.draft, uid: -1});
    yield put(actions.requestAccommodationApplicationList());
    const newID = result.result.application_id;
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new LearnerBeginsAccommodationApplicationEvent(user?.uid ?? "", newID, tenancyID));
    yield call(history.push, "/accommodation/applications/apply/" + newID);
}

export function* watchApplyForTenancy() {
    yield takeEvery(actions.APPLY_FOR_TENANCY, applyForTenancy);
}

export function* addToCart(action) {
    const {applicationID} = action;
    const applicationResult = yield select(selectors.universityApplicationByID, applicationID);
    const allDocuments = yield select(selectors.applicationFormDocumentsArray);
    const application = applicationResult?.payload;
    if (application == null) return;
    const documents = JSON.stringify(Applications.filterNonExistentDocuments(application.documents, allDocuments));
    const result = yield call(api2.postRequest, `applications/${applicationID}/addToCart`, {documents});
    yield alertIfError(result);
    yield put(actions.requestUniApplicationList());
    yield call(history.push, "/applications/3");
}

export function* watchAddToCart() {
    yield takeEvery(actions.ADD_APPLICATION_TO_CART, addToCart);
}

export function* saveApplicationDocuments(action) {
    const {applicationID} = action;
    const applicationResult = yield select(selectors.universityApplicationByID, applicationID);
    const allDocuments = yield select(selectors.applicationFormDocumentsArray);
    const application = applicationResult?.payload;
    if (application == null) return;
    const docs = application.documents ?? [];
    const documents = JSON.stringify(Applications.filterNonExistentDocuments(docs, allDocuments));
    yield call(api2.postRequest, `applications/${applicationID}/documents`, {documents});
}

export function* watchSaveApplicationDocuments() {
    yield takeEvery(actions.SELECT_DOCUMENT_FOR_APPLICATION, saveApplicationDocuments);
}

export function* autoSelectApplicationDocuments(action) {
    const {applicationID} = action;
    const applicationResult = yield select(selectors.universityApplicationByID, applicationID);
    const application = applicationResult?.payload;
    if (application == null) {
        return;
    }
    const existingDocuments = application.documents ?? [];
    if (existingDocuments.length === 0) {
        const allDocuments = yield select(selectors.applicationFormDocumentsArray);
        const documentsToTick = allDocuments.filter(currentDocument => allDocuments.filter(document => currentDocument.documentType === document.documentType).length === 1);
        const actionsToPut = documentsToTick.map(document => put(actions.selectDocumentForApplication(applicationID, document, true)));
        yield all(actionsToPut);
    }
}

export function* watchAutoSelectApplicationDocuments() {
    yield takeEvery(actions.AUTO_SELECT_APPLICATION_DOCUMENTS, autoSelectApplicationDocuments);
}

export function* sendAccommodationApplication(action) {
    const {tenancyID, applicationID} = action;
    yield call(api.POST, "Applications/sendAccApplication", {status: ACCOMMODATION_APPLICATION_STATUS.sent, uid: applicationID});
    const userId = currentUser()?.uid ?? "";
    new GoogleAnalyticsService().logEvent(new LearnerSubmitsAccommodationApplicationEvent(userId, applicationID, tenancyID));
    yield put(actions.requestAccommodationApplicationList());
    yield call(history.push, "/accommodation/applications/sent");
}

export function* watchSendAccommodationApplication() {
    yield takeEvery(actions.SEND_ACCOMMODATION_APPLICATION, sendAccommodationApplication);
}

export function* cancelAccommodationApplicationRedirect(action) {
    const {accApplicationID} = action;
    yield call(api2.postRequest, "applications/cancelRedirect", {url: accApplicationID});
}

export function* watchCancelAccommodationApplicationRedirect() {
    yield takeEvery(actions.CANCEL_ACCOMMODATION_APPLICATION_REDIRECT, cancelAccommodationApplicationRedirect);
}

export function* sendUniApplications(action) {
    const {ids} = action;
    const result = yield call(api2.postRequest, "applications/sendApplications", {applicationIDs: ids.join(",")});
    if (result.success) {
        yield put(actions.requestUniApplicationList());
        yield call(history.push, "/applications/4");
        const user = currentUser();
        ids.forEach(id => {
            new GoogleAnalyticsService().logEvent(new LearnerProgressesApplicationThroughCartEvent(user?.uid ?? "", id));
        });
        yield put(actions.showAlert(Str.platform_payment_received(), Str.platform_payment_received_info(), Str.okay(), ""));
    } else {
        yield alertIfError(result);
    }
}

export function* watchSendUniApplications() {
    yield takeEvery(actions.SEND_UNI_APPLICATIONS, sendUniApplications);
}

export function* requestApplicationPaymentInfo(action) {
    const {numberApplications} = action;
    yield put(actions.requestedApplicationPaymentRequiredInfo());
    const result = yield call(api2.getRequest, "applicationTokens/paymentRequired", {numberApplications});
    yield put(actions.gotApplicationPaymentRequiredInfo(result));
}

export function* submitFormFillerCredentials(action) {
    const {applicationID, username, password} = action;
    yield call(api2.postRequest, `applications/${applicationID}/formFillerAccount`, {username, password});
    yield put(actions.requestUniApplicationList());
}

export function* watchSubmitFormFillerCredentials() {
    yield takeEvery(actions.SUBMIT_FORMFILLER_ACCOUNT_CREDENTIALS, submitFormFillerCredentials);
}

export function* completeApplicationSubmission(action) {
    const {applicationID} = action;
    yield call(api2.postRequest, `applications/${applicationID}/markAsCompleted`);
    yield put(actions.requestUniApplicationList());
}

export function* watchCompleteApplicationSubmission() {
    yield takeEvery(actions.COMPLETE_APPLICATION_SUBMISSION, completeApplicationSubmission);
}

export function* watchRequestApplicationPaymentInfo() {
    yield takeEvery(actions.REQUEST_APPLICATION_PAYMENT_REQUIRED_INFO, requestApplicationPaymentInfo);
}

export function* viewDocument(action) {
    const {document} = action;
    const {name, fileType, documentType} = document;
    const fileName = name + "." + fileType;
    yield call(api.downloadFile, "Documents/download", {documentType, fileName}, fileName);
}

export function* watchViewDocument() {
    yield takeEvery(actions.VIEW_DOCUMENT, viewDocument);
}

export function* deleteDocument(action) {
    const {document} = action;
    const {name, fileType, documentType} = document;
    const fileName = name + "." + fileType;
    yield call(api.POST, "Documents/delete", {documentType, fileName});
}

export function* watchDeleteDocument() {
    yield takeEvery(actions.DELETE_DOCUMENT, deleteDocument);
}

export function* uploadDocument(action) {
    const {file, fullFileName, documentTypeId} = action;
    const result = yield call(api.POSTWithFile, "Documents/upload", {documentType: documentTypeId, fileName: fullFileName}, "file", file);
    yield alertIfError(result);
    yield put(actions.requestApplicationInfo());
    yield put(actions.uploadDocumentComplete());
}

export function* watchUploadDocument() {
    yield takeEvery(actions.UPLOAD_DOCUMENT, uploadDocument);
}

export function* pasteDocument(action) {
    const {text, fullFileName, documentTypeId} = action;
    const result = yield call(api.POST, "Documents/upload", {documentType: documentTypeId, fileName: fullFileName, pasted: text});
    yield alertIfError(result);
    yield put(actions.requestApplicationInfo());
}

export function* watchPasteDocument() {
    yield takeEvery(actions.PASTE_DOCUMENT, pasteDocument);
}

export function* viewFormDocument(action) {
    const {originalName, fileName} = action;
    yield call(api.downloadFile, "Applications/downloadFile", {fileName}, originalName);
}

export function* watchViewFormDocument() {
    yield takeEvery(actions.VIEW_FORM_DOCUMENT, viewFormDocument);
}

export function* deleteFormDocument(action) {
    const {questionID, fileName, isRecord, index, innerQuestionID} = action;
    const result = yield call(api.POST, "Applications/deleteFile", {fileName});
    if (result.success) {
        const newAnswer = "";
        if (isRecord) {
            const oldAnswerJ = yield select(selectors.applicationFormAnswers);
            const oldAnswer = oldAnswerJ.payload[questionID];
            const records = JSON.parse(oldAnswer);
            const newRecords = FormUtil.modifyRecordEntry(records, index, innerQuestionID, newAnswer);
            yield put(actions.setApplicationFormAnswer(questionID, JSON.stringify(newRecords)));
        } else {
            yield put(actions.setApplicationFormAnswer(questionID, newAnswer));
        }
    }
}

export function* watchDeleteFormDocument() {
    yield takeEvery(actions.DELETE_FORM_DOCUMENT, deleteFormDocument);
}

export function* uploadFormDocument(action) {
    const {questionID, file, fileName, originalName, isRecord, index, innerQuestionID} = action;
    const result = yield call(api.POSTWithFile, "Applications/uploadFile", {fileName}, "file", file);
    if (result.success) {
        const answer = {uploaded: Util.rn(), fileName, originalName};
        const newAnswer = JSON.stringify(answer);
        if (isRecord) {
            const oldAnswerJ = yield select(selectors.applicationFormAnswers);
            const oldAnswer = oldAnswerJ.payload[questionID];
            const records = JSON.parse(oldAnswer);
            const newRecords = FormUtil.modifyRecordEntry(records, index, innerQuestionID, newAnswer);
            yield put(actions.setApplicationFormAnswer(questionID, JSON.stringify(newRecords)));
        } else {
            yield put(actions.setApplicationFormAnswer(questionID, newAnswer));
        }
        yield put(actions.uploadDocumentComplete());
    } else {
        yield put(actions.uploadDocumentComplete());
    }
}

export function* watchUploadFormDocument() {
    yield takeEvery(actions.UPLOAD_FORM_DOCUMENT, uploadFormDocument);
}

export function* deleteApplication(action) {
    const {applicationID} = action;
    yield call(api.POST, "Applications/deleteApplication", {uid: applicationID});
    yield put(actions.requestUniApplicationList());
    yield put(actions.requestAccommodationApplicationList());
}

export function* watchDeleteApplication() {
    yield takeEvery(actions.DELETE_APPLICATION, deleteApplication);
}

export function* moveApplicationToDraft(action) {
    const {applicationID} = action;
    yield call(api2.postRequest, "applications/" + applicationID + "/moveToDrafts", {uid: applicationID});
    yield put(actions.requestUniApplicationList());
}

export function* watchMoveApplicationToDraft() {
    yield takeEvery(actions.MOVE_APPLICATION_TO_DRAFT, moveApplicationToDraft);
}

export function* setProfileField(action) {
    const {questionID, answer} = action;
    const newProfile = {[questionID]: answer};
    const oldProfileResult = yield select(selectors.userProfile);
    const oldProfile = Applications.parseAnswers(Object.values(oldProfileResult?.payload ?? {}));
    if (oldProfile == null) return;

    const apiParams = {...oldProfile, ...newProfile};
    const result = yield call(api2.postRequest, "profile/update", {...apiParams, updateOptions: 1});
    if (questionID.includes("study_level")) {
        yield put(actions.requestCourseSearchOptions(true));
    }
    yield put(actions.requestFundingList());
    yield put(actions.didSetProfileField());
    yield put(actions.gotProfile(result));
}

export function* watchSetProfileField() {
    yield takeEvery(actions.SET_PROFILE_FIELD, setProfileField);
}

export function* setOnboardingField(action) {
    const {questionID, answer} = action;
    const newOnboarding = {[questionID]: answer};
    const oldOnboardingResult = yield select(selectors.userOnboarding);
    const oldOnboarding = oldOnboardingResult?.payload;
    if (oldOnboarding == null) return;

    const apiParams = {...oldOnboarding, ...newOnboarding};
    const result = yield call(api2.postRequest, "profile/onboardingUpdate", {...apiParams, updateOptions: 1});
    yield put(actions.didSetOnboardingField());
    yield put(actions.gotOnboarding(result));
}
export function* watchSetOnboardingField() {
    yield takeEvery(actions.SET_ONBOARDING_FIELD, setOnboardingField);
}

export function* requestSettings() {
    const result = yield call(api.POST, "Settings/list", {supportedLanguages: JSON.stringify(Constants.LANGUAGES)});
    yield put(actions.gotSettings(result));
}

export function* watchRequestSettings() {
    yield takeEvery(actions.REQUEST_SETTINGS, requestSettings);
}

export function* requestNotificationSettingsFormItems() {
    const fullResult = yield call(api2.getAndParse, "settings/notificationSettingsForm", {}, (json) => json);
    const formItemsResult = apiCallStateUtil.mapResult(fullResult, a => a.formItems);
    const answersResult = apiCallStateUtil.mapResult(fullResult, a => a.answers);
    yield put(notificationSettingsFormItemsSlice.actions.got(formItemsResult));
    yield put(notificationSettingsAnswersSlice.actions.gotInitialAnswersFromServer(answersResult));
}

export function* watchRequestNotificationSettingsFormItems() {
    yield takeEvery(notificationSettingsFormItemsSlice.actions.request.type, requestNotificationSettingsFormItems);
}

export function* watchSetNotificationSettingsAnswer() {
    yield takeEvery(notificationSettingsAnswersSlice.actions.setAnswer, function* (action) {
        const {question, answer} = action.payload;
        const answers = JSON.stringify([{question, answer}]);
        yield call(api2.postRequest, "settings/notificationSettings", {answers});
    });
}

export function* setSettingsField(action) {
    const {field, value} = action;
    const oldSettingsResult = yield select(selectors.settings);
    const oldSettings = oldSettingsResult?.payload;
    if (oldSettings == null) return;
    const newSettings = {...oldSettings, [field]: value};
    Object.keys(newSettings).forEach(key => newSettings[key] = newSettings[key] ? 1 : 0);
    yield call(api.POST, "Settings/update", {...newSettings});
}

export function* watchSetSettingsField() {
    yield takeEvery(actions.SET_SETTINGS_FIELD, setSettingsField);
}

export function* sendFeedback(action) {
    const {text} = action;
    const OS = Constants.OS;
    const version = Constants.VERSION;
    yield call(api.POST, "Settings/feedback", {text, OS, version});
}

export function* watchSendFeedback() {
    yield takeEvery(actions.SEND_FEEDBACK, sendFeedback);
}

export function* watchSetLanguage() {
    yield takeEvery(actions.SET_LANGUAGE, function* (action) {
        // @ts-ignore
        const {languageID} = action;
        const user = currentUser();
        new GoogleAnalyticsService().logEvent(new LearnerSwitchesLanguagesEvent(user?.uid ?? "", languageID));
        yield call(api.POST, "Settings/setLanguage", {lang: languageID});
        yield requestSettings();
    });
}

export function* setSelectedCourse(action) {
    const {id} = action.course;
    yield call(history.push, "/funding");
    yield call(api2.postRequest, "courses/selected", {courseID: id});
    yield put(actions.requestFundingList());
    yield put(actions.requestProfile());
    yield put(actions.requestCourseList());
}

export function* watchSelectCourse() {
    yield takeEvery(actions.SET_SELECTED_COURSE, setSelectedCourse);
}

export function* deselectCourse() {
    yield call(api2.deleteRequest, "courses/selected");
    yield put(actions.requestFundingList());
}

export function* watchDeselectCourse() {
    yield takeEvery(actions.DESELECT_COURSE, deselectCourse);
}

export function* setAccommodationCampus(action) {
    const {campusID} = action;
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new LearnerViewsSearchResultEvent(user?.uid ?? "", "rooms", campusID));
    yield put(actions.requestedAccommodationList());
    yield call(api.POST, "Users/setCampus", {campusID});
    yield put(actions.requestAccommodationList());
    yield put(actions.requestAccommodationFavourites());
}

export function* watchSetCampus() {
    yield takeEvery(actions.SET_ACCOMMODATION_CAMPUS, setAccommodationCampus);
}

export function* requestAccommodationDirections(action) {
    const {fromLat, fromLng, toLat, toLng} = action;

    const existing = yield select(selectors.accommodationDirectionInfo, fromLat, fromLng, toLat, toLng);
    if (resultIsOk(existing)) return;

    yield put(actions.requestedAccommodationDirections(fromLat, fromLng, toLat, toLng));

    const response = yield call(api2.getRequest, "accommodation/directions", {fromLat, fromLng, toLat, toLng});

    if (response.success) {
        const cycling = response.result.cycling.routes[0];
        const driving = response.result.driving.routes[0];
        const walking = response.result.walking.routes[0];
        const finalResult = {walking, driving, cycling};

        yield put(actions.gotAccommodationDirections(fromLat, fromLng, toLat, toLng, {success: true, result: finalResult}));
    }
}

export function* watchRequestDirections() {
    yield takeEvery(actions.REQUEST_ACCOMMODATION_DIRECTIONS, requestAccommodationDirections);
}

function* watchLogout() {
    yield takeEvery(actions.LOGOUT, function* () {
        yield FirebaseUtil.logout();
        yield call(history.push, "/login");
    });
}

function* watchChangePassword() {
    yield takeEvery(actions.CHANGE_PASSWORD, function* () {
        yield FirebaseUtil.sendPasswordResetEmail();
    });
}

export function* handleChangeEmail(action) {
    const {newEmail} = action;
    yield call(api.POST, "Users/changeEmail", {newEmail: newEmail, doVerify: 1});
    //  TODO: Might need some specific text here
    yield put(actions.showAlert(Str.verifyEmailChange(), Str.email_not_verified(newEmail), Str.okay(), ""));

}

function* watchChangeEmail() {
    yield takeEvery(actions.CHANGE_EMAIL, handleChangeEmail);
}

export function* handleGoBack(action) {
    const desiredPath = action.desiredPath;
    const routeHistory = yield select(selectors.routeHistory);
    if (routeHistory.length < 2) {
        // We don't know the history or there is none, so navigate to the desired previous page
        yield call(history.push, desiredPath);
    } else {
        const previousPage = routeHistory[routeHistory.length - 2];
        if (previousPage === desiredPath) {
            // Since the last page is also the page we want to go to, instead of pushing it on the stack, we can pop. This makes the browser buttons make more sense
            yield call(history.back);
        } else {
            // Resort to pushing, the user has taken some weird route through the app
            yield call(history.push, desiredPath);
        }
    }
}

function* watchGoBack() {
    yield takeEvery(actions.GO_BACK, handleGoBack);
}

export function* requestAgentInfo(action) {
    const {avoidReload} = action;
    if (!avoidReload) {
        yield put(actions.requestedAgentInfo());
    }
    const result = yield call(api.POST, "Agent/info", {});
    yield put(actions.gotAgentInfo(result));
}

function* watchRequestAgentInfo() {
    yield takeEvery(actions.REQUEST_AGENT_INFO, requestAgentInfo);
}

export function* changeAgent(action) {
    const agentID = action.agentID ?? -1;
    yield put(actions.requestedAgentInfo());
    yield call(api.POST, "Agent/changeAgent", {agentID});
    yield put(actions.requestAgentInfo());
    yield put(actions.requestUniApplicationList());
}

function* watchChangeAgent() {
    yield takeEvery(actions.CHANGE_AGENT, changeAgent);
}

function* reportAgent({report}) {
    yield call(api.POST, "Agent/reportAgent", {message: report});
}

function* watchReportAgent() {
    yield takeEvery(actions.REPORT_AGENT, reportAgent);
}

export function* sendAgentMessage(action) {
    const {message} = action;
    yield call(api.POST, "Agent/sendMessage", {message});
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new ChatSendMessageEvent(user.uid));
    yield put(actions.requestAgentInfo(true));
}

function* watchSendAgentMessage() {
    yield takeEvery(actions.SEND_AGENT_MESSAGE, sendAgentMessage);
}

export function* sendAgentFile(action) {
    const {file} = action;
    yield call(api.POSTWithFile, "Agent/sendFile", {}, "file", file);
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new ChatSendMessageEvent(user.uid));
    yield put(actions.requestAgentInfo(true));
}

function* watchSendAgentFile() {
    yield takeEvery(actions.SEND_AGENT_FILE, sendAgentFile);
}

export function* sendAgentCourse(action) {
    const {courseName, providerName, courseID} = action;
    // TODO hardcoded string
    const meta = courseName + " at " + providerName;
    const deepLink = new CourseDeepLink(courseID);
    const link = DeepLinkUtil.deepLinkToUrl(deepLink);
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new ChatSendMessageEvent(user.uid));
    if (FirebaseUtil.isAgent()) {
        //The current webapp user is actually the agent using it within the iframe in the agent portal. Send a message up to the agent portal to share this course
        const target = window.parent;
        if (target) {
            target.postMessage({
                meta,
                link,
                // @ts-ignore
                studentID: window.agentSignedInAs,
                type: "sendCourseLink"
            }, process.env.REACT_APP_AGENT_PORTAL_URL ?? "", []);
        }
        return;
    }
    yield call(api.POST, "Agent/sendLink", {meta, link});
    yield put(actions.requestAgentInfo(true));
}

function* watchSendAgentCourse() {
    yield takeEvery(actions.SEND_AGENT_COURSE, sendAgentCourse);
}

function* didSubscribeFunding() {
    yield put(actions.requestedFundingList());
    yield put(actions.requestFundingList());
    yield put(actions.showAlert(Str.thank_you(), Str.thank_subscribing(), Str.next(), ""));
}

function* watchDidSubscribeFunding() {
    yield takeEvery(actions.DID_SUBSCRIBE_FUNDING, didSubscribeFunding);
}

function* didOkServerSidePopup({id}) {
    yield call(api.POST, "Dashboard/clickedPopup", {popupID: id});
}

function* watchDidOkServerSidePopup() {
    yield takeEvery(actions.OK_SERVER_SIDE_POPUP, didOkServerSidePopup);
}

function* openDeepLink(action) {
    /** @type import("../util/DeepLink").DeepLink*/
    const link = action.link;
    yield call(history.push, link.toWebappUrl());
}

function* watchOpenDeepLink() {
    yield takeEvery(actions.OPEN_DEEP_LINK, openDeepLink);
}

function* requestEnglishLanguageArticles() {
    // @ts-ignore
    const result = yield call(api2.getAndParse, "englishLanguageTests/articles", {}, (json) => json.articles);
    yield put(englishLanguageArticlesSlice.actions.got(result));
}

function* watchRequestEnglishLanguageArticles() {
    yield takeEvery(englishLanguageArticlesSlice.actions.request.type, requestEnglishLanguageArticles);
}

function* requestEnglishLanguageConfig() {
    const result = yield call(api2.getAndParse, "englishLanguageTests/config", {}, json => json);
    yield put(englishLanguageConfigSlice.actions.got(result));
}

function* watchRequestEnglishLanguageConfig() {
    yield takeEvery(englishLanguageConfigSlice.actions.request.type, requestEnglishLanguageConfig);
}

function* requestHomeScreenItems() {
    // @ts-ignore
    const result = yield call(api2.getAndParse, "homeScreen/content", {}, (json) => json.homeScreenItems);
    yield put(homeScreenItemsSlice.actions.got(result));
}

function* watchRequestHomeScreenItems() {
    yield takeEvery(homeScreenItemsSlice.actions.request.type, requestHomeScreenItems);
}

function* requestViewedMIAN({mian}) {
    yield call(api.POST, "Dashboard/viewedMIAN", {mian_id: mian.uid});
}

function * watchRequestViewedMIAN() {
    yield takeEvery(actions.VIEWED_MIAN, requestViewedMIAN);
}

function* requestClickedMIAN({mian}) {
    yield call(api.POST, "Dashboard/clickedMIAN", {mian_id: mian.uid});
}

function* watchRequestClickedMIAN() {
    yield takeEvery(actions.CLICKED_MIAN, requestClickedMIAN);
}

function* requestSubjectSearchOptions({searchSeed}) {
    const result = yield call (api2.postRequest, "popularSubjects/search", {searchSeed: searchSeed});
    yield put(actions.gotSubjectSearchOptions(result));
}

function* watchRequestSubjectSearchoptions() {
    yield takeEvery(actions.REQUEST_SUBJECT_SEARCH_OPTIONS, requestSubjectSearchOptions);
}

function gotDashboard() {
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new LoginEvent(user.uid));
}

function* watchGotDashboard() {
    yield takeEvery(actions.GOT_DASHBOARD, gotDashboard);
}

function* openAgentChat() {
    const user = currentUser();
    new GoogleAnalyticsService().logEvent(new ChatOpenEvent(user.uid));
    yield call(api.POST, "Agent/markAgentMessagesAsRead", {});
    yield put(actions.requestUnreadMessagesCount());
}

function* watchOpenAgentChat() {
    yield takeEvery(actions.SET_AGENT_CHAT_OPEN, openAgentChat);
}

function* requestUnreadAgentMessagesCount() {
    yield put(actions.requestedUnreadMessagesCount());
    const result = yield call(api.POST, "Agent/getUnreadAgentMessagesCount", {});
    yield put(actions.gotUnreadMessagesCount(result));
}
function* watchRequestUnreadAgentMessagesCount() {
    yield takeEvery(actions.REQUEST_UNREAD_AGENT_MESSAGES_COUNT, requestUnreadAgentMessagesCount);
}

function* requestRemoteConfig() {
    const showRemoteConfig = yield FirebaseUtil.activateRemoteConfig();
    yield put(actions.gotRemoteConfig(showRemoteConfig));
}

function* watchRequestRemoteConfig() {
    yield takeEvery(actions.REQUEST_REMOTE_CONFIG, requestRemoteConfig);
}

export default function* rootSaga() {
    yield all([
        watchRequestAppInit(),
        watchDoneAppInit(),
        watchAppFocused(),
        watchRequestDashboard(),
        watchRequestProfile(),
        watchRequestOnboarding(),
        watchRequestLogin(),
        watchRequestCourseSearchOptions(),
        watchRefreshCourseListIfApplicationFormChanged(),
        watchCourseSearchFilters(),
        watchSetCourseSearchLos(),
        watchRequestCourseList(),
        watchRequestCourseDetails(),
        watchRequestCourseFundingDetails(),
        watchRequestCourseProviderDetails(),
        watchCourseFave(),
        watchRequestJobsConfig(),
        watchRequestJobList(),
        watchSetJobSearchFilters(),
        watchRequestJobDetails(),
        watchRequestJobsFavourites(),
        watchJobFave(),
        watchAutoSelectApplicationDocuments(),
        watchRequestUniversityList(),
        watchRequestUniversityFavourites(),
        watchUniversityFave(),
        watchRequestCourseFavouritesList(),
        watchRequestSelectedCourse(),
        watchRequestFundingList(),
        watchRequestScholarshipDetails(),
        watchMoveScholarship(),
        watchReportScholarship(),
        watchRequestAccommodationList(),
        watchAccommodationFilters(),
        watchAccommodationFiltersDraft(),
        watchRequestAccommodationPropertyDetails(),
        watchRequestAccommodationFavourites(),
        watchAccommodationFave(),
        watchSetCampus(),
        watchRequestDirections(),
        watchRequestApplicationInfo(),
        watchRequestAccommodationApplicationInfo(),
        watchSetApplicationFormAnswer(),
        watchSaveApplicationForm(),
        watchRequestUniApplicationList(),
        watchRequestUniApplication(),
        watchRequestAccommodationApplicationList(),
        watchApplyForCourse(),
        watchApplyForTenancy(),
        watchAddToCart(),
        watchSendAccommodationApplication(),
        watchCancelAccommodationApplicationRedirect(),
        watchSendUniApplications(),
        watchRequestApplicationPaymentInfo(),
        watchSubmitFormFillerCredentials(),
        watchCompleteApplicationSubmission(),
        watchDeleteDocument(),
        watchViewDocument(),
        watchUploadDocument(),
        watchPasteDocument(),
        watchViewFormDocument(),
        watchDeleteFormDocument(),
        watchUploadFormDocument(),
        watchSaveApplicationDocuments(),
        watchMoveApplicationToDraft(),
        watchDeleteApplication(),
        watchSetProfileField(),
        watchSetOnboardingField(),
        watchRequestSettings(),
        watchRequestNotificationSettingsFormItems(),
        watchSetNotificationSettingsAnswer(),
        watchSetSettingsField(),
        watchSendFeedback(),
        watchSetLanguage(),
        watchSelectCourse(),
        watchDeselectCourse(),
        watchLogout(),
        watchChangePassword(),
        watchChangeEmail(),
        watchGoBack(),
        watchRequestAgentInfo(),
        watchChangeAgent(),
        watchReportAgent(),
        watchSendAgentMessage(),
        watchSendAgentFile(),
        watchSendAgentCourse(),
        watchDidSubscribeFunding(),
        watchDidOkServerSidePopup(),
        watchOpenDeepLink(),
        watchRequestEnglishLanguageArticles(),
        watchRequestEnglishLanguageConfig(),
        watchRequestHomeScreenItems(),
        watchRefreshProfileIfApplicationFormChanged(),
        watchRefreshApplicationIfProfileDetailsChanged(),
        watchSaveProfileField(),
        watchRequestViewedMIAN(),
        watchRequestClickedMIAN(),
        watchRequestPopularSubjects(),
        watchRequestSubjectSearchoptions(),
        watchGotDashboard(),
        watchOpenAgentChat(),
        watchRequestUnreadAgentMessagesCount(),
        watchRequestRemoteConfig()
    ]);
}

function resultIsOk(currentResult) {
    return (currentResult?.state === ApiResultState.Loading || currentResult?.state === ApiResultState.Success);
}

function* alertIfError(result) {
    if (!result.success) {
        yield put(actions.showAlert(result.errorTitle ?? Str.error(), result.error, Str.okay(), ""));
    }
}

export function* waitForState(selector) {
    if (yield select(selector)) return;

    while (true) {
        yield take("*");
        if (yield select(selector)) return;
    }
}