import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FormQuestionAnswerDictionary, FormQuestionAnswerPair, formQuestionAnswerPairsToDictionary} from "../../../util/form/FormUtil";
import {FormAnswerType} from "../../../util/form/FormItem";
import {ApiCallState} from "grantfairy-web-common";

const initialState: FormQuestionAnswerDictionary = {};

const slice = createSlice({
    name: "notificationSettingsAnswers",
    initialState,
    reducers: {
        gotInitialAnswersFromServer(state, action: PayloadAction<ApiCallState<FormQuestionAnswerPair[]>>) {
            const result: FormQuestionAnswerPair[] = action.payload.result?.payload ?? [];
            return formQuestionAnswerPairsToDictionary(result);
        },
        setAnswer(state, action: PayloadAction<{ question: string, answer: FormAnswerType }>) {
            state[action.payload.question] = action.payload.answer;
        }
    }
});

export const reducer = slice.reducer;
export const actions = slice.actions;
