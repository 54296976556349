import {Paper} from "grantfairy-web-common";
import {useMemo} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import {MasterDetailView} from "../../components/TabContainer";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import JobDetailsView from "./JobDetailsView";
import {JobList} from "./JobListView";

const JobsListScreen = ({jobs, selectedJobId, favouriteIDs, makeUrlForJob, onFave}) => {
    return (
        <MasterDetailView listView={(
            <div style={{flex: 1, overflowY: "auto"}}>
                <JobList onFave={onFave} favouriteIDs={favouriteIDs} jobs={jobs} selectedJobId={selectedJobId} makeUrlForJob={makeUrlForJob} searchCategory="jobs_favourites"/>
            </div>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "auto"}}>
                <JobDetailsView jobID={selectedJobId}/>
            </Paper>
        )} showDetailView={selectedJobId != null}/>
    );
};

const JobsFavouritesTab = ({favourites, selectedJobId, browseURL, makeUrlForJob, onFave}) => {

    //cache the faves as they are. We never stop showing a fave even if it becomes unfaved, we still show it
    const cachedFaves = useMemo(() => favourites, []);

    if (cachedFaves.length === 0) {
        return (
            <div style={{display: "flex", padding: 64, alignItems: "center", justifyContent: "center"}}>
                <Paper style={{overflowY: "auto", padding: 48, textAlign: "center"}}>
                    <Subtitle id={"jobs-favourite-tab-no-favourite-jobs-text"}>{Str.no_favourite_jobs()}</Subtitle>
                    <PrimaryButton id={"jobs-favourite-tab-browse-button"} component={Link} to={browseURL} style={{marginTop: 16}}>{Str.browse()}</PrimaryButton>
                </Paper>
            </div>
        );
    }

    return <JobsListScreen jobs={cachedFaves} favouriteIDs={favourites.map(e => e.id)} makeUrlForJob={makeUrlForJob} selectedJobId={selectedJobId} onFave={onFave}/>;
};

const Container = (props) => {
    const {favourites} = props;
    return (
        <DataDependant data={favourites}>
            <JobsFavouritesTab  {...props} favourites={favourites.payload} id={"jobs-favourite-tab"}/>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {jobID}) => ({
    favourites: selectors.favouritedJobsSortedByValidity(state),
    browseURL: "/jobs/search",
    selectedJobId: jobID == null ? null : parseInt(jobID)
});

const mapDispatchToProps = (dispatch) => ({
    makeUrlForJob: job => "/jobs/favourites/" + job.id,
    onFave: (job, isFavourited) => dispatch(actions.setJobFave(job, isFavourited))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {jobID} = useParams();
    return (<Connected jobID={jobID} />);
};

export default WrappedAndConnected;