import {Check} from "@mui/icons-material";
import {HTMLAttributes, ReactElement} from "react";
import {usePrimaryColour} from "../theme/Theme";
import Dimens from "../theme/Dimens";

interface DropdownWithCheckProps {
    props: HTMLAttributes<HTMLLIElement>;
    selected: boolean;
    label: ReactElement | string;
}

const DropDownOptionWithCheck = ({props, selected, label}: DropdownWithCheckProps) => {
    const background = usePrimaryColour();
    const additionalStyles = selected ? {background} : {};
    return (
        <li {...props} style={{...props.style, ...additionalStyles}}>
            <Check style={{display: selected ? "inline" : "none", marginRight: Dimens.HalfMargin}}/> {label}
        </li>
    );
};

export default DropDownOptionWithCheck;
