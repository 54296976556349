import {HomeScreenBanner, HomeScreenImage} from "../../model/HomeScreenItem";
import * as Assets from "../../Assets";
import Dimens from "../../theme/Dimens";
import {VSpacer} from "../../components/Spacers";
import {PrimaryButton} from "../../components/Buttons";
import {Link} from "react-router-dom";
import {DeepLinkUtil} from "../../util/DeepLink";

const BannerView = ({banner}: { banner: HomeScreenBanner }) => {
    function srcForImage(image: HomeScreenImage) {
        switch (image) {
            case HomeScreenImage.IsometricPersonWithSearch:
                return require(Assets.ISOMETRIC_PERSON_WITH_SEARCH);
        }
        // @ts-ignore
        return null;
    }

    return (
        <div style={{display: "flex", gap: Dimens.DoubleMargin, padding: Dimens.StandardMargin, boxSizing: "border-box", height: "100%", alignItems: "center"}}>
            <img alt="" style={{height: "100%", aspectRatio: `${banner.imageAspectRatio}/1`}} src={srcForImage(banner.image)}/>
            <div style={{flex: 1, textAlign: "right"}}>
                <p style={{margin: 0}}>{banner.title}</p>
                <VSpacer space={Dimens.StandardMargin}/>
                <PrimaryButton component={Link} to={DeepLinkUtil.deepLinkFromUrl(banner.link)?.toWebappUrl()}>{banner.buttonText}</PrimaryButton>
            </div>
        </div>
    );
};

export default BannerView;
