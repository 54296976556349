import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: { [key: string]: boolean } = {};

const formSectionExpandedState = createSlice({
    name: "formSectionExpandedState",
    initialState: initialState,
    reducers: {
        // Action payload is the sectionID
        toggleSectionExpanded(state, action: PayloadAction<string>) {
            const sectionID = action.payload;
            const isCurrentlyExpanded = state[sectionID] ?? false;
            return {...state, [sectionID]: !isCurrentlyExpanded};
        }
    }
});

export const reducer = formSectionExpandedState.reducer;
export const actions = formSectionExpandedState.actions;
