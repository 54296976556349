import * as Applications from "../../Applications";
import * as FeatureFlags from "../../FeatureFlags";
import {makeSuccessResult, mapResult} from "../../util/ApiResult";
import {FormItem} from "../../util/form/FormItem";
import {fetchAndCacheReducer, fetchReducer, fetchReducerWithLoading, fetchUnwrappedReducer} from "../../util/reducerUtil";
import {SavingState} from "../../views/SavingIndicator";
import * as actions from "../actions";

export const userName = (state = "", action) => {
    if (action.type === actions.GOT_DASHBOARD) {
        return action.result.result.name ?? "";
    }
    return state;
};

export const needsSignIn = (state = {}, action) => fetchReducer(state, action, actions.GOT_DASHBOARD, e => e.needsSignIn);

export const hasSentVerificationEmail = (state = {}, action) => {
    const oldValue = state?.payload;
    //This can't ever be changed from true once it has been true because once it's sent it's sent.
    if (oldValue === true) return state;
    return fetchReducer(state, action, actions.GOT_LOGIN, e => e.sentVerificationEmail);
};

export const needsEmailVerification = (state = {}, action) => fetchReducer(state, action, actions.GOT_LOGIN, e => !e.verified);

export const courseSearchOptions = (state = {}, action) => fetchUnwrappedReducer(state, action, actions.GOT_COURSE_SEARCH_OPTIONS, e => e);

export const applicationFormChangedSinceCourseLoad = (state = false, action) => {
    if (action.type === actions.SET_APPLICATION_FORM_CHANGED_SINCE_COURSE_LOAD) {
        return action.recentChangesPresent;
    }
    return state;
};

export const applicationFormChangedSinceProfileLoad = (state = false, action) => {
    if (action.type === actions.SET_APPLICATION_FORM_CHANGED_SINCE_PROFILE_LOAD) {
        return action.recentChangesPresent;
    }
    return state;
};

export const popularSubjects = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUEST_POPULAR_SUBJECTS, actions.GOT_POPULAR_SUBJECTS, e => e.list);

export const profileChangedSinceApplicationLoad = (state = false, action) => {
    if (action.type === actions.SET_PROFILE_DETAILS_CHANGED_SINCE_APPLICATION_FORM_LOAD) {
        return action.recentChangesPresent;
    }
    return state;
};

export const courseSearchFilters = (state = null, action) => {
    if (action.type === actions.GOT_COURSE_SEARCH_OPTIONS) {
        const options = action.result.result;
        return {levelOfStudy: options.levelOfStudy ?? options.defaultLevelOfStudy};
    } else if (action.type === actions.SET_COURSE_SEARCH_QUERY) {
        if (state == null) return null;
        // @ts-ignore
        return {...state, searchQuery: action.query};
    } else if (action.type === actions.SET_COURSE_SEARCH_PROVIDER_ID) {
        if (state == null) return null;
        // @ts-ignore
        return {...state, providerID: action.providerID};
    } else if (action.type === actions.SET_COURSE_SEARCH_STUDY_MODE) {
        if (state == null) return null;
        // @ts-ignore
        return {...state, studyMode: action.studyMode};
    } else if (action.type === actions.SET_COURSE_SEARCH_LOS) {
        if (state == null) return null;
        // @ts-ignore
        return {...state, levelOfStudy: action.los};
    } else if (action.type === actions.SET_COURSE_SEARCH_ENGLISH_LANGUAGE_REQUIREMENTS) {
        if (state == null) return null;
        // @ts-ignore
        return {...state, filterByEnglishLanguageRequirements: action.filterByEnglishLanguageRequirements};
    }
    return state;
};

export const courseSearchResult = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_COURSE_LIST, actions.GOT_COURSE_LIST, e => e);

export const courseMians = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_COURSE_LIST, actions.GOT_COURSE_LIST, e => e.mians);

export const courseFavourites = (state = {}, action) => {
    if (action.type === actions.SET_COURSE_FAVE) {
        return mapResult(state, old => {
            const {course, fave} = action;
            if (fave) {
                return [...old, course];
            } else {
                return old.filter(e => e.id !== course.id);
            }
        });
    }
    return fetchReducer(state, action, actions.GOT_COURSE_FAVOURITES_LIST, e => e.courses);
};

export const courseDetails = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_COURSE_DETAILS, actions.REQUESTED_COURSE_DETAILS, r => r.course, a => a.id);
};

export const courseFundingDetails = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_COURSE_FUNDING_DETAILS, actions.REQUESTED_COURSE_FUNDING_DETAILS, r => r, a => a.courseID);
};

export const courseProviderDetails = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_COURSE_PROVIDER_DETAILS, actions.REQUESTED_COURSE_PROVIDER_DETAILS, r => r.provider, a => a.id);
};

export const selectedCourse = (state = {}, action) => {
    if (action.type === actions.SET_SELECTED_COURSE) {
        const {course} = action;
        return makeSuccessResult(course);
    }
    if (action.type === actions.DESELECT_COURSE) {
        return makeSuccessResult(null);
    }
    return fetchReducer(state, action, actions.GOT_SELECTED_COURSE, e => e.course);
};

const scholarshipSorted = scholarships => {
    if (scholarships == null) return null;
    const sorted = [...scholarships];
    sorted.sort((a, b) => {
        const deadlineA = parseInt(a.deadline_end);
        const deadlineB = parseInt(b.deadline_end);
        if (isNaN(deadlineA)) return 1;
        if (isNaN(deadlineB)) return -1;
        return deadlineA - deadlineB;
    });
    return sorted;
};

export const fundingWishlist = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_FUNDING_LIST, actions.GOT_FUNDING_LIST, a => scholarshipSorted(a.scholarships));
export const fundingAppliedList = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_FUNDING_LIST, actions.GOT_FUNDING_LIST, a => scholarshipSorted(a.appliedList));
export const fundingSuccessList = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_FUNDING_LIST, actions.GOT_FUNDING_LIST, a => scholarshipSorted(a.successList));
export const fundingDeletedList = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_FUNDING_LIST, actions.GOT_FUNDING_LIST, a => scholarshipSorted(a.deletedList));

export const fundingMians = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_FUNDING_LIST, actions.GOT_FUNDING_LIST, e => e.mians);

export const scholarshipDetails = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_SCHOLARSHIP_DETAILS, actions.REQUESTED_SCHOLARSHIP_DETAILS, r => r.scholarship, a => a.uid + a.version);
};

export const applicationFormSections = (state = {}, action) => {
    return fetchReducer(state, action, actions.GOT_APPLICATION_INFO, res => {
        if (res == null || res.form == null) return null;
        return Applications.getSectionsFromForm(res.form);
    });
};

export const applicationFormAnswers = (state = {}, action) => {
    if (action.type === actions.SET_APPLICATION_FORM_ANSWER) {
        const {questionID, answer} = action;
        return mapResult(state, state => ({...state, [questionID]: answer}));
    }
    /*    if (action.type === actions.DELETE_FORM_DOCUMENT) {
            const {questionID} = action;
            return mapResult(state, state => ({...state, [questionID]: undefined}));
        }
        if (action.type === actions.UPLOAD_FORM_DOCUMENT) {
            const {questionID, fileName, originalName, uploaded} = action;
            const data = {fileName, originalName, uploaded};
            const newAnswer = JSON.stringify(data);
            return mapResult(state, state => ({...state, [questionID]: newAnswer}));
        }*/
    return fetchReducer(state, action, actions.GOT_APPLICATION_INFO, res => {
        if (res == null || res.answers == null) return null;
        return Applications.parseAnswers(res.answers);
    });
};

export const applicationFormSavingState = (state = null, action) => {
    if (action.type === actions.SET_APPLICATION_FORM_ANSWER) return SavingState.saving;
    if (action.type === actions.UPLOAD_FORM_DOCUMENT) return SavingState.saving;
    if (action.type === actions.DELETE_FORM_DOCUMENT) return SavingState.saving;
    if (action.type === actions.DID_SET_APPLICATION_FORM_ANSWER) return SavingState.saved;
    return state;
};

export const applicationFormDocumentTypes = (state = {}, action) => fetchReducer(state, action, actions.GOT_APPLICATION_INFO, res => {
    if (res == null || res.form == null) return null;
    const rawForm = JSON.parse(res.form);
    return rawForm.documentTypes;
});

export const applicationFormDocuments = (state = {}, action) => {
    if (action.type === actions.DELETE_DOCUMENT) {
        const {document} = action;
        return mapResult(state, r => {
            return r.filter(d => d.documentType !== document.documentType || d.name !== document.name);
        });
    }
    return fetchReducer(state, action, actions.GOT_APPLICATION_INFO, res => res.documents);
};

export const applicationFormDocumentAgreements = (state = {}, action) => fetchReducer(state, action, actions.GOT_APPLICATION_INFO, res => {
    if (res == null || res.form == null) return null;
    const rawForm = JSON.parse(res.form);
    const {documentAgreements} = rawForm;
    documentAgreements.forEach(item => {
        Object.setPrototypeOf(item, FormItem.getPrototype(item.type));
    });
    return documentAgreements;
});

export const showApplicationFormErrors = (state = {}, action) => {
    if (action.type === actions.SET_SHOW_APPLICATION_FORM_ERRORS) {
        return {...state, [action.applicationID]: action.showErrors};
    }

    return state;
};

export const universityApplications = (state = {}, action) => fetchReducer(state, action, actions.GOT_UNI_APPLICATION_LIST, r => {
    if (r == null || r.applications == null) return null;
    return r.applications.map(a => ({...a, status: parseInt(a.status)}));
});

export const applicationPromotionInfo = (state = {}, action) => fetchReducer(state, action, actions.GOT_UNI_APPLICATION_LIST, r => {
    if (r == null || r.promotion == null) return null;
    return r.promotion;
});

export const shouldApplicationTabShowWelcomePopup = (state = {}, action) => fetchReducer(state, action, actions.GOT_APPLICATION_INFO, r => {
    return r.shouldShowWelcomePopup;
});

export const applicationWelcomePopupNotAlreadyDisplayed = (state = true, action) => {
    if (action.type === actions.TURN_OFF_WELCOME_POPUP) {
        return false;
    }
    return state;
};

export const universityApplicationsByID = (state = {}, action) => {
    if (action.type === actions.SELECT_DOCUMENT_FOR_APPLICATION) {
        const {applicationID, document, isSelected} = action;
        const newDoc = document.documentType + "/" + document.name + "." + document.fileType;
        const newApplication = mapResult(state[applicationID], oldApplication => {
            if (isSelected) {
                const oldDocs = oldApplication.documents ?? [];
                const newDocs = [...oldDocs, newDoc];
                return {...oldApplication, documents: newDocs};
            } else {
                const newDocs = oldApplication.documents.filter(e => e !== newDoc);
                return {...oldApplication, documents: newDocs};
            }
        });
        return {...state, [applicationID]: newApplication};
    }
    return fetchAndCacheReducer(state, action, actions.GOT_UNI_APPLICATION, actions.REQUESTED_UNI_APPLICATION, r => r.application);
};

export const universityApplicationSpecificForm = (state = {}, action) => {
    return fetchAndCacheReducer(state, action, actions.GOT_UNI_APPLICATION, actions.REQUESTED_UNI_APPLICATION, res => {
        if (res == null) return null;
        const formToUse = FeatureFlags.APPLICATION_SPECIFIC_FORM_ENABLED ? res.applicationSpecificForm : res.form;
        if (formToUse == null) return null;
        return Applications.getSectionsFromForm(formToUse);
    });
};

export const applicationPaymentRequiredInfo = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_APPLICATION_PAYMENT_REQUIRED_INFO, actions.GOT_APPLICATION_PAYMENT_REQUIRED_INFO, r => r);

export const userProfile = (state = {}, action) => {
    if (action.type === actions.SET_PROFILE_FIELD) {
        const {questionID, answer} = action;
        return mapResult(state, state => ({...state, [questionID]: answer}));
    }
    return fetchReducerWithLoading(state, action, actions.REQUESTED_PROFILE, actions.GOT_PROFILE, r => r.answers);
};

export const userOnboarding = (state = {}, action) => {
    if (action.type === actions.SET_ONBOARDING_FIELD) {
        const {questionID, answer} = action;
        return mapResult(state, state => {
            return {...state, [questionID]: answer};
        });
    }
    return fetchReducerWithLoading(state, action, actions.REQUESTED_ONBOARDING, actions.GOT_ONBOARDING, r => r.answers);
};

export const userLogin = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_LOGIN, actions.GOT_LOGIN, r => r);

export const profileOptions = (state = [], action) => fetchUnwrappedReducer(state, action, actions.GOT_LOGIN, r => {
    return r.optionTags.map(({name, tag, specialCategory}) => ({name, tag, specialCategory}));
});

export const profileForm = (state = {}, action)=> fetchReducerWithLoading(state, action, actions.REQUESTED_PROFILE, actions.GOT_PROFILE, res =>  {
    if (res == null || res.form == null) {
        return null;
    }
    const parsedForm = Applications.getSectionsFromParsedForm(res.form);
    return parsedForm;
});

export const onboardingForm = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_ONBOARDING, actions.GOT_ONBOARDING, res =>  {
    if (res == null || res.form == null) {
        return null;
    }
    const parsedForm = Applications.getSectionsFromParsedForm(res.form);
    return parsedForm;
});

export const languageOptions = (state = [], action) => fetchUnwrappedReducer(state, action, actions.GOT_SETTINGS, r => r.languages);

export const settings = (state = {}, action) => {
    if (action.type === actions.SET_SETTINGS_FIELD) {
        const {field, value} = action;
        return mapResult(state, old => {
            return {...old, [field]: value};
        });
    }
    return fetchReducer(state, action, actions.GOT_SETTINGS, r => {
        if (r == null || r.pref == null) return null;
        const {pref_contact_emails, pref_contact_deadline, pref_contact_match, pref_contact_update} = r.pref;
        return {
            pref_contact_emails: parseInt(pref_contact_emails) === 1,
            pref_contact_deadline: parseInt(pref_contact_deadline) === 1,
            pref_contact_match: parseInt(pref_contact_match) === 1,
            pref_contact_update: parseInt(pref_contact_update) === 1
        };
    });
};

export const currentSchoolName = (state = "", action) => fetchUnwrappedReducer(state, action, actions.GOT_SETTINGS, r => r.school ?? "");

export const currentLanguageID = (state = null, action) => fetchUnwrappedReducer(state, action, actions.GOT_SETTINGS, r => r.currentLanguage);

export const latestToast = (state = {}, action) => {
    if (action.type === actions.SHOW_TOAST) {
        const previousID = state.id ?? 0;
        return {text: action.text, id: previousID + 1};
    }
    return state;
};

export const latestAlert = (state = null, action) => {
    const {type, ...alert} = action;
    if (type === actions.SHOW_ALERT) {
        return alert;
    }
    if (action.type === actions.DISMISS_ALERT) {
        return null;
    }
    return state;
};

export const currentAgents = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_AGENT_INFO, actions.GOT_AGENT_INFO, r => r.agents, true);

export const agentInvites = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_AGENT_INFO, actions.GOT_AGENT_INFO, r => r.invites);

export const agentMessages = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_AGENT_INFO, actions.GOT_AGENT_INFO, r => r.messages);

export const agentsHistory = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_AGENT_INFO, actions.GOT_AGENT_INFO, r => r.agentsHistory);

export const viewingIan = (state = null, action) => {
    if (action.type === actions.CLOSE_IAN) {
        return null;
    }
    return fetchUnwrappedReducer(state, action, actions.GOT_APP_FOCUSED, r => r.ian ?? null);
};

export const serverSidePopup = (state = null, action) => {
    if (action.type === actions.OK_SERVER_SIDE_POPUP || action.type === actions.CANCEL_SERVER_SIDE_POPUP) {
        return null;
    }
    return fetchUnwrappedReducer(state, action, actions.GOT_APP_FOCUSED, r => r.popup ?? null);
};

export const routeHistory = (state = [], action) => {
    if (action.type === actions.PATH_CHANGED) {
        return [...state, action.path];
    }
    return state;
};

export const isAgentChatOpen = (state = false, action) => {
    if (action.type === actions.SET_AGENT_CHAT_OPEN) {
        return action.isOpen;
    }
    return state;
};

export const uploadingDocument = (state = false, action) => {
    if (action.type === actions.UPLOAD_DOCUMENT || action.type === actions.UPLOAD_FORM_DOCUMENT) {
        return true;
    } else if (action.type === actions.UPLOAD_DOCUMENT_COMPLETE) {
        return false;
    }
    return state;
};

export const subjectSearchOptions = (state = [], action) => fetchUnwrappedReducer(state, action, actions.GOT_SUBJECT_SEARCH_OPTIONS, e => e);

export const unreadAgentMessagesCount = (state = {}, action) => fetchReducerWithLoading(state, action, actions.REQUESTED_UNREAD_AGENT_MESSAGES_COUNT, actions.GOT_UNREAD_AGENT_MESSAGES_COUNT, e => e);

export const showRemoteConfig = (state = {}, action) => {
    if (action.type === actions.GOT_REMOTE_CONFIG) {
        return action.result;
    }
    return state;
};

export const reducers = {
    userName,
    needsSignIn,
    hasSentVerificationEmail,
    needsEmailVerification,
    courseSearchOptions,
    applicationFormChangedSinceCourseLoad,
    applicationFormChangedSinceProfileLoad,
    profileChangedSinceApplicationLoad,
    courseSearchFilters,
    courseSearchResult,
    courseMians,
    courseFavourites,
    courseDetails,
    courseFundingDetails,
    courseProviderDetails,
    selectedCourse,
    fundingWishlist,
    fundingAppliedList,
    fundingSuccessList,
    fundingDeletedList,
    fundingMians,
    applicationFormSections,
    applicationFormAnswers,
    applicationFormSavingState,
    applicationFormDocumentTypes,
    applicationFormDocuments,
    applicationFormDocumentAgreements,
    showApplicationFormErrors,
    universityApplications,
    universityApplicationsByID,
    universityApplicationSpecificForm,
    applicationPaymentRequiredInfo,
    userProfile,
    userOnboarding,
    userLogin,
    profileForm,
    onboardingForm,
    scholarshipDetails,
    languageOptions,
    currentLanguageID,
    settings,
    currentSchoolName,
    latestToast,
    latestAlert,
    currentAgents,
    agentInvites,
    agentMessages,
    agentsHistory,
    viewingIan,
    serverSidePopup,
    routeHistory,
    applicationPromotionInfo,
    shouldApplicationTabShowWelcomePopup,
    applicationWelcomePopupNotAlreadyDisplayed,
    isAgentChatOpen,
    uploadingDocument,
    popularSubjects,
    subjectSearchOptions,
    unreadAgentMessagesCount,
    showRemoteConfig
};