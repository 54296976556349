import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Route, Routes} from "react-router-dom";
import Alerts from "../Alerts";
import ArticlesTab from "../screens/englishLanguageTests/ArticlesTab";
import MoreTab from "../screens/main/MoreTab";
import {sections} from "../Tab";
import {GFThemeProvider} from "../theme/GFThemeProvider";
import {englishLanguageTestsSectionTheme} from "../theme/Theme";
import {EnglishLanguageTestsSectionActionBar} from "../views/ActionBar";
import UploadingDocumentPopup from "../components/UploadingDocumentPopup";

const Router = () => {
    return (
        <MuiThemeProvider theme={englishLanguageTestsSectionTheme}>
            <GFThemeProvider value={sections.EnglishLanguageTests}>
                <Alerts/>
                <UploadingDocumentPopup/>
                <div className="englishLanguageTestsSection" style={{display: "flex", flexDirection: "column", height: "100vh"}}>
                    <EnglishLanguageTestsSectionActionBar/>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Routes>
                            <Route key="information" path="/" element={<ArticlesTab />}/>
                            <Route key="information" path="/information/" element={<ArticlesTab />}/>
                            <Route key="information" path="/information/:selectedArticleID" element={<ArticlesTab />}/>
                            <Route key="more" path="/more" element={<MoreTab/>}/>
                        </Routes>
                    </div>
                </div>
            </GFThemeProvider>
        </MuiThemeProvider>
    );
};

export default Router;
