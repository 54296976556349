import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import * as Applications from "../../../Applications";
import {APPLICATION_FORMS} from "../../../Applications";
import * as Str from "../../../strings/Str";
import {useControlColourName} from "../../../theme/Theme";
import * as api from "../../../util/api";
import * as api2 from "../../../util/api2";
import {ApiResultState} from "../../../util/ApiResult";
import DataDependant from "../../../views/DataDependant";
import ViewFormDetails from "./ViewFormDetails";

const Screen = ({application}) => {

    const sections = Applications.getSectionsFromForm(application.form);
    const documentTypes = JSON.parse(application.form)?.documentTypes ?? [];
    const documents = application.documents ?? [];
    const answers = Applications.parseAnswers(application.answers);

    return (
        <ViewFormDetails formSections={sections} answers={answers} documents={documents} documentTypes={documentTypes}/>
    );
};

const Container = ({application, applicationForm}) => {
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (applicationForm === APPLICATION_FORMS.university) {
            api2.getRequest("applications/" + application.id).then(result => {
                if (result.success) {
                    setResult({state: ApiResultState.Success, payload: result.result.application});
                } else {
                    setResult({state: ApiResultState.Error});
                }
            });
        } else {
            api.POST("Applications/getApplication", {uid: application.id, applicationForm}).then(result => {
                if (result.success) {
                    setResult({state: ApiResultState.Success, payload: result.result.application});
                } else {
                    setResult({state: ApiResultState.Error});
                }
            });
        }
    }, []);

    return <DataDependant data={result}>
        <Screen application={result?.payload}/>
    </DataDependant>;
};

const Popup = ({onClose, ...props}) => {
    const buttonColor = useControlColourName();
    return (
        <Dialog open={true} maxWidth="md" fullWidth onBackdropClick={onClose}>
            <DialogContent style={{padding: 0}}>
                <Container {...props}/>
            </DialogContent>
            <DialogActions>
                <Button color={buttonColor} onClick={onClose}>{Str.close()}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Popup;