import {testSaga} from "redux-saga-test-plan";
import {put} from "redux-saga/effects";
import {JobLevel, JobSort} from "../../model/JobSearchFilters";
import * as Property from "../../Property";
import * as api from "../../util/api";
import * as api2 from "../../util/api2";
import {ApiResultState, makeSuccessResult} from "../../util/ApiResult";
import * as CVLibraryApi from "../../util/CVLibraryApi";
import * as actions from "../actions";
import {SELECT_DOCUMENT_FOR_APPLICATION} from "../actions";
import * as sagas from "../sagas";
import * as selectors from "../selectors";
import {history} from "../store";

it("Requests Dashboard", () => {
    const saga = testSaga(sagas.requestDashboard);
    const result = "mock_result";
    saga.next()
        .call(api.POST, "Dashboard/appOpened", {})
        .next(result)
        .put(actions.gotDashboard(result))
        .next()
        .isDone();
});

it("Requests Course Favourites", () => {
    const saga = testSaga(sagas.requestCourseFavouritesList);
    const result = "mock_result";
    saga.next()
        .call(api2.getRequest, "courses/favourites").next(result)
        .put(actions.gotCourseFavouritesList(result)).next()
        .isDone();
});

it("Requests Funding", () => {
    const saga = testSaga(sagas.requestFundingList);
    const result = "mock_result";
    saga.next()
        .put(actions.requestedFundingList()).next()
        .call(api.POST, "Funding/list", {}).next(result)
        .put(actions.gotFundingList(result)).next()
        .isDone();
});

it("Requests Accommodation", () => {
    const saga = testSaga(sagas.requestAccommodationList);
    const result = "mock_result";
    const filters = Property.INITIAL_FILTERS;
    const params = Property.filtersToApiParams(filters);
    saga.next()
        .put(actions.requestedAccommodationList()).next()
        .select(selectors.accommodationFilters).next(filters)
        .call(api.POST, "Accommodation/Properties/list", params).next(result)
        .put(actions.gotAccommodationList(result)).next()
        .isDone();
});

it("Requests accommodation details if and only if needed", () => {
    const propertyID = 403;
    testFetchAndCacheSaga(propertyID, sagas.requestAccommodationPropertyDetailsIfNeeded, actions.requestedPropertyDetails(propertyID), selectors.accommodationPropertyDetails, actions.requestedPropertyDetails(propertyID), "Accommodation/Properties/get", {propertyID}, result => actions.gotPropertyDetails(propertyID, result));
});

it("Requests Scholarships details if and only if needed", () => {
    const scholarshipID = "test_id";
    const version = "2";

    const statesCausingFetch = [
        {state: ApiResultState.Error},
        {}
    ];

    statesCausingFetch.forEach(testState => {
        const saga = testSaga(sagas.requestScholarshipDetailsIfNeeded, actions.requestScholarshipDetails(scholarshipID, version));
        const result = "mock_result";
        saga.next()
            .select(selectors.scholarshipDetails, scholarshipID, version).next(testState)
            .put(actions.requestedScholarshipDetails(scholarshipID, version)).next()
            .call(api.POST, "Funding/getScholarship", {scholarshipID: scholarshipID, version}).next(result)
            .put(actions.gotScholarshipDetails(scholarshipID, version, result)).next()
            .isDone();
    });

    //A loading state or success state should not trigger a new fetch
    const statesCausingNoFetch = [
        {state: ApiResultState.Success},
        {state: ApiResultState.Loading}
    ];

    statesCausingNoFetch.forEach(testState => {
        const saga = testSaga(sagas.requestScholarshipDetailsIfNeeded, actions.requestScholarshipDetails(scholarshipID, version));
        saga.next()
            .select(selectors.scholarshipDetails, scholarshipID, version).next(testState)
            .next(testState)
            .isDone();
    });
});

it("Sets accommodation campus and reloads", () => {
    const action = actions.setAccommodationCampus(45);
    const saga = testSaga(sagas.setAccommodationCampus, action);
    saga.next()
        .put(actions.requestedAccommodationList()).next()
        .call(api.POST, "Users/setCampus", {campusID: 45}).next()
        .put(actions.requestAccommodationList()).next()
        .put(actions.requestAccommodationFavourites()).next()
        .isDone();
});

it("Sets application form answer", () => {
    const action = actions.setApplicationFormAnswer("qid", "ans");
    const saga = testSaga(sagas.setApplicationFormAnswer, action);
    const result = {success: true};
    saga.next()
        .call(api.POST, "Applications/save", {question: "qid", answer: "ans"}).next(result)
        .put(actions.didSetApplicationFormAnswer("qid", "ans")).next()
        .isDone();
});

it("Sends verification email when not already sent", () => {
    verificationTest(false, makeSuccessResult(false), 1);
});

it("Sends verification email when not already sent", () => {
    verificationTest(false, {}, 1);
});

it("Doesn't send verification email when already sent", () => {
    verificationTest(false, makeSuccessResult(true), 0);
});

it("Send verification email when already sent but forced", () => {
    verificationTest(true, makeSuccessResult(true), 1);
});

it("Deletes documents", () => {
    const action = actions.deleteDocument(
        {
            "documentType": "other",
            "name": "hello_world",
            "fileType": "jpg"
        }
    );
    const saga = testSaga(sagas.deleteDocument, action);
    saga.next()
        .call(api.POST, "Documents/delete", {documentType: "other", fileName: "hello_world.jpg"}).next()
        .isDone();
});

function verificationTest(forceVerify, hasSentResult, doVerify) {
    const action = actions.requestLogin(forceVerify);
    const saga = testSaga(sagas.requestLogin, action);
    const result = "mock_result";
    saga.next()
        .select(selectors.hasSentVerificationEmail).next(hasSentResult)
        .put(actions.requestedLogin()).next()
        .call(api.POST, "Users/login", {doVerify}).next(result)
        .put(actions.gotLogin(result)).next()
        .isDone();
}

it("Goes back when no history", () => {
    const saga = testSaga(sagas.handleGoBack, actions.goBack("/desired"));

    saga.next()
        .select(selectors.routeHistory).next([])
        .call(history.push, "/desired").next()
        .isDone();
});

it("Goes back when wrong history", () => {
    const saga = testSaga(sagas.handleGoBack, actions.goBack("/desired"));

    saga.next()
        .select(selectors.routeHistory).next(["/wrong", "/stillWrong", "/soWrong", "/notEvenClose"])
        .call(history.push, "/desired").next()
        .isDone();
});

it("Goes back when right history", () => {
    const saga = testSaga(sagas.handleGoBack, actions.goBack("/desired"));

    saga.next()
        .select(selectors.routeHistory).next(["/wrong", "/stillWrong", "/desired", "/current"])
        .call(history.back).next()
        .isDone();
});

it("should select single documents", () => {
    const documents = [
        {
            documentType: "other",
            name: "dummy",
            uploaded: 1553900731,
            fileType: "pdf"
        },
        {
            documentType: "transcripts",
            name: "a_text",
            uploaded: 1556312510,
            fileType: "txt"
        },
        {
            documentType: "personal_statement",
            name: "another_test",
            uploaded: 1556538003,
            fileType: "txt"
        }
    ];

    const emptyResult = {
        payload: {
            documents: []
        }
    };
    const saga = testSaga(sagas.autoSelectApplicationDocuments, actions.autoSelectApplicationDocuments(5));
    saga.next().select(selectors.universityApplicationByID, 5)
        .next(emptyResult).select(selectors.applicationFormDocumentsArray).next(documents)
        .all([
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[0],
                isSelected: true
            }),
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[1],
                isSelected: true
            }),
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[2],
                isSelected: true
            })
        ]).next().isDone();
});

it("should select single documents even when documents property is null", () => {
    const documents = [
        {
            documentType: "other",
            name: "dummy",
            uploaded: 1553900731,
            fileType: "pdf"
        },
        {
            documentType: "transcripts",
            name: "a_text",
            uploaded: 1556312510,
            fileType: "txt"
        },
        {
            documentType: "personal_statement",
            name: "another_test",
            uploaded: 1556538003,
            fileType: "txt"
        }
    ];

    const emptyResult = {
        payload: {
            documents: null
        }
    };
    const saga = testSaga(sagas.autoSelectApplicationDocuments, actions.autoSelectApplicationDocuments(5));
    saga.next().select(selectors.universityApplicationByID, 5)
        .next(emptyResult).select(selectors.applicationFormDocumentsArray).next(documents)
        .all([
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[0],
                isSelected: true
            }),
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[1],
                isSelected: true
            }),
            put({
                type: SELECT_DOCUMENT_FOR_APPLICATION,
                applicationID: 5,
                document: documents[2],
                isSelected: true
            })
        ]).next().isDone();
});

describe("Job list fetch", () => {
    /** @type {import("../../model/JobSearchFilters").JobSearchFilters} **/
    const jobSearchFilters = {searchQuery: "search", sort: JobSort.Relevance, jobLevel: JobLevel.PartTime, distance: 10, location: "London"};

    /** @type {import("../../model/JobsApiConfig").JobsConfig} */
    const configPayload = {
        api_key: "",
        cities: [],
        search_string_grad_blank: "",
        search_string_grad: "",
        search_string_part_time_blank: "",
        search_string_part_time: "",
        search_string_full_time_blank: "",
        search_string_full_time: ""
    };
    const jobsConfig = {state: ApiResultState.Success, payload: configPayload};

    const mockResult = {state: ApiResultState.Success, payload: []};

    it("requests job list with right filters when no cache", () => {
        testSaga(sagas.requestJobList).next()
            .call(sagas.waitForJobConfig).next()
            .select(selectors.jobsConfig).next(jobsConfig)
            .select(selectors.jobSearchFilters).next(jobSearchFilters)
            .select(selectors.jobSearchResultForFilters, jobSearchFilters).next(null)
            .put(actions.requestedJobList(jobSearchFilters)).next()
            .call(CVLibraryApi.searchJobs, configPayload, jobSearchFilters).next(mockResult)
            .put(actions.gotJobList(jobSearchFilters, mockResult)).next()
            .isDone();
    });

    it("Returns existing result when there is cache", () => {
        testSaga(sagas.requestJobList).next()
            .call(sagas.waitForJobConfig).next()
            .select(selectors.jobsConfig).next(jobsConfig)
            .select(selectors.jobSearchFilters).next(jobSearchFilters)
            .select(selectors.jobSearchResultForFilters, jobSearchFilters).next(mockResult)
            .isDone();
    });
});

describe("Job details fetch", () => {
    const id = "job_id";
    /** @type {import("../../model/JobsApiConfig").JobsConfig} */
    const configPayload = {
        api_key: "",
        cities: [],
        search_string_grad_blank: "",
        search_string_grad: "",
        search_string_part_time_blank: "",
        search_string_part_time: "",
        search_string_full_time_blank: "",
        search_string_full_time: ""
    };
    const jobsConfig = {state: ApiResultState.Success, payload: configPayload};

    const mockResult = {state: ApiResultState.Success, payload: {}};

    it("requests job details when no cache", () => {
        testSaga(sagas.requestJobDetailsIfNeeded, actions.requestJobDetails(id)).next()
            .select(selectors.jobDetails, id).next(null)
            .call(sagas.waitForJobConfig).next()
            .select(selectors.jobsConfig).next(jobsConfig)
            .put(actions.requestedJobDetails(id)).next()
            .call(CVLibraryApi.getJob, configPayload, id).next(mockResult)
            .put(actions.gotJobDetails(id, mockResult)).next()
            .isDone();
    });

    it("Returns existing result when there is cache", () => {
        testSaga(sagas.requestJobDetailsIfNeeded, actions.requestJobDetails(id)).next()
            .select(selectors.jobDetails, id).next(mockResult)
            .isDone();
    });
});

function testFetchAndCacheSaga(key, sagaToTest, requestAction, cacheSelector, requestedAction, apiEndpoint, apiParameters, gotActionCreator, selectorArgs = []) {
    //An error state or no cache at all should cause a new fetch
    const statesCausingFetch = [
        {[key]: {state: ApiResultState.Error}},
        {}
    ];

    statesCausingFetch.forEach(testState => {
        const saga = testSaga(sagaToTest, requestAction);
        const result = "mock_result";
        saga.next()
            .select(cacheSelector, ...selectorArgs).next(testState)
            .put(requestedAction).next()
            .call(api.POST, apiEndpoint, apiParameters).next(result)
            .put(gotActionCreator(result)).next()
            .isDone();
    });

    //A loading state or success state should not trigger a new fetch
    const statesCausingNoFetch = [
        {[key]: {state: ApiResultState.Success}},
        {[key]: {state: ApiResultState.Loading}}
    ];

    statesCausingNoFetch.forEach(testState => {
        const saga = testSaga(sagaToTest, requestAction);
        saga.next()
            .select(cacheSelector, ...selectorArgs)
            .next(testState)
            .isDone();
    });
}