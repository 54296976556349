import {AppBar, Button, useMediaQuery} from "@mui/material";
import Icon from "@mui/material/Icon";
import "flag-icon-css/css/flag-icon.css";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import * as Str from "../strings/Str";
import LanguagePicker from "./LanguagePicker";
import {SectionTabDestination, SectionTabs} from "./SectionTabs";
import {gfWindow} from "../model/GFWindow";
import {Language} from "../model/Language";
import {useColourOnPrimary} from "../theme/GFThemeProvider";
import {Dispatch} from "redux";
import {useNavigate} from "react-router-dom";
import {useIsLightTheme} from "../theme/Theme";
import * as Assets from "../Assets";
import PlainLink from "../components/PlainLink";
import {GoogleAnalyticsService} from "../services/analytics/GoogleAnalyticsService";
import {currentUser} from "../util/FirebaseUtil";
import {LearnerSelectsDashboardItemEvent} from "../model/AnalyticsEvents";

interface TitleBarProps {
    userName?: string;
    languages: Language [];
    currentLanguage?: string;
    onSetLanguage: (languageId: string) => void;
    currentSection: SectionTabDestination;
    onSignOut: () => void;
}

const TitleBar = ({userName, languages, currentLanguage, onSetLanguage, currentSection, onSignOut}: TitleBarProps) => {
    const textColour = useColourOnPrimary();
    const history = useNavigate();
    const onSetSection = section => {
        const userId = currentUser().uid ?? "";
        new GoogleAnalyticsService().logEvent(new LearnerSelectsDashboardItemEvent(userId, section.name, section.name));
        return history(section.root + section.default);
    };
    const onHomeSelected = () => history("/");
    const showUserName = useMediaQuery("(min-width: 1440px)");
    const appIcon = useIsLightTheme() ? Assets.UCAS_LOGO : Assets.UCAS_LOGO;

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 0px 0px 20px"}}>
                <div>
                {/* TO-DO Need underline at original deisgn */}
                {/* <div style={{borderBottom: "solid 6px #706CB2", width: "136px"}}> */}
                    <PlainLink to="/">
                        <img alt="" src={require("../" + appIcon)} style={{width: "auto", height: "32px"}}/>
                    </PlainLink>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <LanguagePicker languages={languages} currentLanguage={currentLanguage} onSetLanguage={onSetLanguage}/>
                    {showUserName && <p style={{margin: "8px 0px", flex: "1 1 auto", fontSize: 14}}>{userName}</p>}
                    {!gfWindow.isAgent && (
                        <Button style={{color: textColour, marginLeft: 16, font: "inherit", textTransform: "none"}} startIcon={<Icon style={{width: 20, height: 20}}>logout</Icon>} size="small" onClick={onSignOut}>
                            {Str.logout()}
                        </Button>
                    )}
                </div>
            </div>
            <h1 style={{padding: "0px 20px", fontFamily: "font-family: Roboto, sans-serif"}}>
                {Str.web_strapline()}
            </h1>
            <AppBar position="static" color="primary" style={{transition: "background-color 2s"}}>
                <div style={{display: "flex", alignItems: "stretch", color: textColour}}>
                    <SectionTabs currentSection={currentSection} onSectionSelected={onSetSection} onHomeSelected={onHomeSelected}/>
                </div>
            </AppBar>
        </div>
    );
};

const mapStateToProps = (state, {currentSection}: { currentSection: SectionTabDestination }) => ({
    userName: selectors.userName(state),
    languages: selectors.languageOptions(state),
    currentLanguage: selectors.currentLanguageID(state) ?? undefined,
    currentSection
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSetLanguage: languageID => dispatch(actions.setLanguage(languageID)),
    onSignOut: () => dispatch(actions.logout())
});

const Container = connect(mapStateToProps, mapDispatchToProps)(TitleBar);

export default Container;