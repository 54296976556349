import QueryString from "query-string";
import {Provider} from "react-redux";
import * as Constants from "./Constants";
import * as actions from "./redux/actions";
import {init as initStore, store} from "./redux/store";
import Root from "./Root";
import {StandardAnalyticsProvider} from "./services/analytics/AnalyticsProvider";
import {initialiseGoogleAnalytics} from "./services/analytics/GoogleAnalyticsService";
import HistoryService from "./services/HistoryService";
import LanguageService from "./services/LanguageService";
import ToastService from "./services/ToastService";
import "./theme/App.css";
import "./theme/index.css";
import * as FirebaseUtil from "./util/FirebaseUtil";
import {gfWindow} from "./model/GFWindow";
import {createRoot} from "react-dom/client";
import {createBrowserHistory} from "history";

const queryParams = QueryString.parse(window.location.search);

const isAgent = queryParams.isAgent === "1";
if (isAgent) {
    gfWindow.agentToken = queryParams.agentToken as string;
    gfWindow.agentSignedInAs = queryParams.agentSignedInAs as string;
    gfWindow.isAgent = true;
}

initStore();

// This should not work this way by pre-loading everything we can think of at the start!
const onLoggedIn = () => {
    const history = createBrowserHistory();
    store.dispatch(actions.pathChanged(history.location.pathname));

    store.dispatch(actions.requestAppInit());

    window.onfocus = () => {
        store.dispatch(actions.appFocused(true));
    };
};

if (isAgent) {
    onLoggedIn();
} else if (FirebaseUtil.isSignedIn()) {
    onLoggedIn();
} else if (window.location.href.indexOf("/login") === -1 && window.location.href.indexOf("/mobile") === -1 && window.location.href.indexOf("/identity") === -1) {
    window.location.replace("/login?redirectUrl=" + encodeURIComponent(window.location.href));
}

const rootView = (
    <Provider store={store}>
        <StandardAnalyticsProvider>
            <div className="ucasTheme">
                <Root/>
                <LanguageService/>
                <HistoryService/>
                <ToastService/>
            </div>
        </StandardAnalyticsProvider>
    </Provider>
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(rootView);

setupSignInWithApple();
initialiseGoogleAnalytics();

function setupSignInWithApple() {
    //The redirect url for apple has to match the current domain
    //We host the webapp at various domains so need to allow all of them
    //So we need to detect which one we're on currently
    const host = Constants.BASE_URLS.filter(e => e === window.location.host)[0];
    if (host == null) {
        console.error("Unknown host - sign in with Apple disabled");
        return;
    }

    const hostWithProtocol = window.location.protocol + "//" + host;

    gfWindow.AppleID.auth.init({
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: "email",
        redirectURI: hostWithProtocol,
        usePopup: true
    });
}
