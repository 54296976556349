import {AspectFitImage} from "grantfairy-web-common";
import moment from "moment";
import {connect} from "react-redux";
import sanitizeHtml from "sanitize-html";
import {LearnerClicksReferralButtonEvent} from "../../model/AnalyticsEvents";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import * as Str from "../../strings/Str";
import {currentUser} from "../../util/FirebaseUtil";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import WebButton from "../../views/WebButton";

/**
 *
 * @param job {import("../../model/Job").Job}
 * @return {JSX.Element}
 * @constructor
 */
const JobDetailsView = ({job}) => {
    const datePosted = job.posted == null ? "" : moment(job.posted).format("DD/MM/yyyy");
    const analyticsHandler = () => {
        const userId = currentUser()?.uid ?? "";
        new GoogleAnalyticsService().logEvent(new LearnerClicksReferralButtonEvent("apply_career", userId, job.title, "", job.id.toString(), Str.website(), job.url));
    };

    return (
        <div style={{padding: 32}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <AspectFitImage backgroundSize="contain" style={{width: 200, height: 120}} src={job.logo}/>
                <div style={{flex: 1, marginLeft: 16}}>
                    <Subtitle style={{margin: 0}}>{job.title}</Subtitle>
                    {job.salary != null && <p style={{color: "#555", margin: "8px 0 0 0"}}>{job.salary}</p>}
                    <p style={{color: "#555", margin: "8px 0 0 0"}}>{Str.date_posted(datePosted)}</p>
                </div>

                <WebButton title={Str.website()} link={job.url} onClick={analyticsHandler}/>
            </div>
            <hr/>
            <p dangerouslySetInnerHTML={{__html: sanitizeHtml(job.descriptionFormatted)}}/>
        </div>
    );
};

const Container = ({jobID, job, getJobDetails}) => {
    if (job == null) {
        getJobDetails(jobID);
    }
    return (
        <DataDependant data={job}>
            <JobDetailsView job={job?.payload}/>
        </DataDependant>
    );
};

const mapStateToProps = (state, {jobID}) => {
    return {
        jobID,
        job: selectors.jobDetails(state, jobID)
    };
};

const mapDispatchToProps = dispatch => ({
    getJobDetails: jobID => {
        dispatch(actions.requestJobDetails(jobID));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;