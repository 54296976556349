import "../theme/Card.css";
import PlainLink from "../components/PlainLink";
import * as Constants from "../../src/Constants";
import FavouriteButton from "./FavouriteButton";
import LazyLoad from "react-lazyload";


const ProviderCard = ({university, isSelected, isFave, onFave, makeUrlForUniversity, universityIdSelector, logClickQuery}) => {
    const imagePath = Constants.REACT_APP_ASSETS_PATH + "providers/marketing_images/" + university.id + Constants.ASSETS_IMG_EXT;
    const logoPath = Constants.REACT_APP_ASSETS_PATH + "providers/" + university.id + Constants.ASSETS_LOGO_EXT;
    const logClickQueryHandler = () => {
        logClickQuery(university);
    };

    return (
        <PlainLink to={makeUrlForUniversity(university)} id={universityIdSelector(university)} onClick={logClickQueryHandler}>
            <div className="card uni-card noSelect">
                <div className="card-header-image lazyload-wrapper">
                    <LazyLoad overflow>
                        <img src={imagePath} alt=""/>
                        <img className="logo-image" src={logoPath} alt=""/>
                    </LazyLoad>
                    <div className="favourite-item">
                        <FavouriteButton favourited={isFave} onFave={onFave} itemID={university.id}/>
                    </div>
                </div>
                <div className="card-contents">
                    <div className="card-body">
                        <div className="card-body-item">
                            <h1>{university.name}</h1>
                            <p>{university.address}</p>
                        </div>
                    </div>
                    {/*         Leave these lines for future  */}
                    {/*<div className="card-footer">*/}
                    {/*    <div className="card-infos">*/}
                    {/*        {university.count && <p className="icon-box"><span className="material-icons">school</span>{university.count}</p>}*/}
                    {/*         {course.outcomes && <p className="icon-box"><span className="material-icons">schedule</span> 1 year</p>} */}
                    {/*         {course.outcomes && <p className="icon-box"><span className="material-icons">schedule</span> 6.0 PTE</p>} */}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </PlainLink>
    );
};

export default ProviderCard;