import {AnalyticsEvent, ProviderUrlSelected} from "../../model/AnalyticsEvents";
import {AnalyticsService, StandardAnalyticsService} from "./AnalyticsService";


describe("StandardAnalyticsService", () => {
    it("should log events to all services", () => {
        let mEvent1: AnalyticsEvent<any> | null = null;
        let mEvent2: AnalyticsEvent<any> | null = null;

        const testEvent: ProviderUrlSelected = {category: "providers", eventName: "provider_url_selected", params: {courseID: "12345", providerURL: "www.somewhere.co.uk", providerID: "a", buttonType: "b"}, eventAction: "clicked"};

        const mockService1: AnalyticsService = {
            logEvent: (event) => mEvent1 = event,
            logPageView: (page) => {
            }
        };

        const mockService2: AnalyticsService = {
            logEvent: (event) => mEvent2 = event,
            logPageView: (page) => {
            }
        };

        const service = new StandardAnalyticsService([mockService1, mockService2]);

        service.logEvent(testEvent);
        expect(mEvent1).toBe(testEvent);
        expect(mEvent2).toBe(testEvent);

    });

    it("should log pages to all services", () => {
        let mPage1: string | null = null;
        let mPage2: string | null = null;

        const testPage = "test/page";

        const mockService1: AnalyticsService = {
            logEvent: (event) => {
            },
            logPageView: (page) => mPage1 = page
        };

        const mockService2: AnalyticsService = {
            logEvent: (event) => {
            },
            logPageView: (page) => mPage2 = page
        };

        const service = new StandardAnalyticsService([mockService1, mockService2]);

        service.logPageView(testPage);
        expect(mPage1).toBe(testPage);
        expect(mPage2).toBe(testPage);
    });
});