import {Paper} from "grantfairy-web-common";
import {useMemo} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import UniversityListScreen from "./UniversityListScreen";

const UniversityFavouritesTab = ({universities, selectedUniversity, browseURL, ...props}) => {

    //cache the faves as they are. We never stop showing a fave even if it becomes unfaved, we still show it
    const cachedFaves = useMemo(() => universities, []);

    if (universities.length === 0) {
        return (
            <div style={{display: "flex", padding: 64, alignItems: "center", justifyContent: "center"}}>
                <Paper style={{overflowY: "auto", padding: 48, textAlign: "center"}}>
                    <Subtitle id="uni-empty-favourites-str">{Str.no_favourite_unis()}</Subtitle>
                    <PrimaryButton id="uni-empty-favourites-browse-btn" component={Link} to={browseURL} style={{marginTop: 16}}>{Str.browse()}</PrimaryButton>
                </Paper>
            </div>
        );
    }

    return <UniversityListScreen  {...props} universities={cachedFaves} selectedUniversity={selectedUniversity} favouriteIDs={universities.map(e => e.id)} searchCategory="universities_favourites"/>;
};

const Container = (props) => {
    const {universities, selectedUniversity} = props;
    return (
        <DataDependant data={universities}>
            <UniversityFavouritesTab  {...props} universities={universities.payload} selectedUniversity={selectedUniversity}/>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {universityID}) => ({
    universities: selectors.favouritedUniversitiesSortedByName(state),
    favouriteIDs: selectors.favouritedUniversityIDs(state),
    selectedUniversityID: universityID,
    selectedUniversity: selectors.courseProviderDetails(state)[universityID],
    makeUrlForUniversity: (uni) => `/universities/favourites/${uni.id}`,
    browseURL: "/universities/search"
});

const mapDispatchToProps = (dispatch, {universityID}) => {
    return {
        onFave: (university, fave) => dispatch(actions.setUniversityFave(university, fave)),
        onCoursesView: (history) => {
            dispatch(actions.setCourseSearchProviderId(universityID));
            history("/courses");
        }
    };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {universityID} = useParams();
    return (<Connected universityID={universityID} />);
};

export default WrappedAndConnected;