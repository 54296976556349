import * as Str from "../strings/Str";

/**
 * @param property object with keys price_unit and price and currency
 * @param from true if this is a minimum price, ie "from £x". False is this is an absolute price ie "£x"
 */
const PropertyPrice = ({property, from = true}: { property: { price_unit: string, price: string, currency: string }, from?: boolean }) => {
    if (from) {
        const template = Str.price_from("placeholder", property.price_unit);
        const [before, after] = template.split("placeholder");
        return <>{before}<span style={{color: "#e77f3a", fontWeight: 500}}>{property.currency}{property.price}</span>{after}</>;
    } else {
        const template = Str.price("placeholder", property.price_unit);
        const [before, after] = template.split("placeholder");
        return <>{before}<span style={{color: "#e77f3a", fontWeight: 500}}>{property.currency}{property.price}</span>{after}</>;
    }
};

export default PropertyPrice;