import {CVLibraryJob, Job, parseCvLibraryDate, parseCvLibraryJob} from "./Job";

const jobDateParseTests = [
    {text: "2021-05-20T07:02:14Z", unix: 1621494134},
    {text: "2021-05-17T13:07:05Z", unix: 1621256825},
    {text: "2021-05-20T07:02:14", unix: 1621494134},
    {text: "2021-05-17T13:07:05", unix: 1621256825}
];

test.each(jobDateParseTests)("Test date parsing for jobs %o", ({text, unix}) => {
    const parsed = parseCvLibraryDate(text);
    const actualUnix = (parsed?.getTime() ?? 0) / 1000;
    expect(actualUnix).toBe(unix);
});

const jobParseTests: { unparsed: CVLibraryJob; parsed: Job }[] = [
    {
        unparsed: {
            valid: true,
            salary: undefined,
            id: 1,
            description: "I am some text with some <em>random</em> em in it",
            logo: "logo.png",
            description_formatted: "Hello world",
            url: "jobs.com",
            posted: undefined,
            title: "job"
        }, parsed: {
            valid: true,
            salary: undefined,
            id: 1,
            description: "I am some text with some random em in it",
            logo: "logo.png",
            descriptionFormatted: "Hello world",
            url: "jobs.com",
            posted: undefined,
            title: "job"
        }
    },
    {
        unparsed: {
            valid: true,
            salary: "£1,000,000,000",
            id: 2,
            description: "Some text",
            logo: "logo.png",
            description_formatted: "Hello world",
            url: "jobs.com",
            posted: "2021-05-17T13:07:05",
            title: "job"
        }, parsed: {
            valid: true,
            salary: "£1,000,000,000",
            id: 2,
            description: "Some text",
            logo: "logo.png",
            descriptionFormatted: "Hello world",
            url: "jobs.com",
            posted: parseCvLibraryDate("2021-05-17T13:07:05") as Date,
            title: "job"
        }
    }
];

test.each(jobParseTests)("Test parsing for job %o", ({unparsed, parsed}) => {
    const actual = parseCvLibraryJob(unparsed);
    expect(actual).toEqual(parsed);
});