import * as Constants from "./Constants";
import * as Str from "./strings/Str";
import {FormItem} from "./util/form/FormItem";
import {FormSection, FormSubsection} from "./util/form/FormSection";
import {FormNavigationOption, FormNavigationOptions} from "./screens/main/applications/BaseApplicationFormScreen";

export const COURSE_APPLICATION_STATUS = Object.freeze({
    draft: 1,
    cart: 2,
    processing: 3,
    sent: 4,
    rejected: 5
});

export const ACCOMMODATION_APPLICATION_STATUS = Object.freeze({
    draft: 1,
    sent: 3
});

export const APPLICATION_POST_SENT_STATUS = Object.freeze({
    manualRegistrationRequired: 10,
    partialManualRegistrationRequiringUsername: 14,
    partialManualRegistrationRequiringPassword: 15,
    awaitingFormFilling: 20,
    awaitingFinalCheck: 30,
    complete: 40,
    awaitingAgentSubmission: 50
});

export const APPLICATION_PROGRESS = Object.freeze({
    sent: 1,
    inReview: 2,
    awaitingInfo: 3,
    offerMade: 4,
    uniRejected: 5,
    userRejected: 6,
    acceptedFirm: 7,
    acceptedInsurance: 8
});

export const APPLICATION_FORMS = Object.freeze({
    university: 1,
    accommodation: 2
});

export const APPLICATION_PROGRESS_NAMES = [
    Str.application_sent(), Str.in_review(), Str.awaiting_info(), Str.offer_made(), Str.uni_rejected(), Str.offer_rejected(), Str.accepted_firm(), Str.accepted_ins()
];

export const getSectionsFromForm = rawFormJson => {
    const rawForm = JSON.parse(rawFormJson);
    return getSectionsFromParsedForm(rawForm);
};

export const getSectionsFromParsedForm = parsedForm => {
    if (parsedForm == null) return [];
    const variablesRaw = parsedForm.variables;
    const variables = {};
    variablesRaw.forEach(a => {
        variables[a.name] = a.items;
    });

    parsedForm.sections.forEach(section => {
        Object.setPrototypeOf(section, FormSection.prototype);
        section.subsections.forEach(subsection => {
            subsection.items = subsection.items.map(item => {
                if ((item.type === "radio" || item.type === "telephone" || item.type === "multi_select" )  && item.options[0] === "$") {
                    item.options = variables[item.options];
                }
                if (item.type === "records") {
                    item.items = item.items.map(item2 => {
                        if (item2.type === "radio" && item2.options[0] === "$") {
                            item2.options = variables[item2.options];
                        }
                        return item2;
                    });
                }
                return item;
            }).filter(e => {
                try {
                    return FormItem.getPrototype(e.type) != null;
                } catch (e) {
                    return false;
                }
            });
            Object.setPrototypeOf(subsection, FormSubsection.prototype);
        });
    });
    return parsedForm.sections.filter(e => e.name != null);
};

export const parseAnswers = raw => {
    const dic = {};
    raw?.forEach(({question_id, answer}) => {
        dic[question_id] = answer;
    });
    return dic;
};

export const parseDocumentFromString = document => {
    const [type, name] = document.split("/");
    return {type, name};
};

/**
 *
 * @param documents {string[]} The documents to check eg ["passport/a.jpg"]
 * @param allDocuments {[{documentType: string, name: string, fileType: string}]} every document in existence
 * @return {string[]} The same array but filtered to only include documents that actually exist in allDocuments
 */
export const filterNonExistentDocuments = (documents, allDocuments) => {
    const allDocsAsStrings = allDocuments.map(e => e.documentType + "/" + e.name + "." + e.fileType);
    return documents.filter(e => allDocsAsStrings.includes(e));
};

export const areApplicationDocumentsReady = (application, documentTypes, allDocuments) => {
    if (application == null || documentTypes == null || !Array.isArray(documentTypes) || documentTypes.length === 0) return false;
    const documents = filterNonExistentDocuments(application.documents ?? [], allDocuments);
    const typesOfExistingDocuments = documents.map(e => parseDocumentFromString(e).type);
    const requiredTypes = documentTypes.filter(e => e.required).map(e => e.id);
    const completedDocumentsOutOfRequired = requiredTypes.filter(e => typesOfExistingDocuments.includes(e)).length;
    return completedDocumentsOutOfRequired === requiredTypes.length;
};

export const validateFileForDocument = (documentType, file, onResult) => {
    if (file.size > Constants.MAX_DOCUMENT_SIZE_BYTES) {
        onResult({errorTitle: Str.file_too_large(), error: Str.file_limit_5mb()});
        return;
    }
    if (documentType.characterLimit) {
        if (file.type === "text/plain") {
            const maxLength = parseInt(documentType.characterLimit);

            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (evt) => {
                const text = (evt.target!.result as string).replace(/\n/g, "").replace(/\r/g, "");
                if (text.length > maxLength) {
                    onResult({errorTitle: Str.invalid_file(), error: Str.too_many_char(maxLength, text.length)});
                } else {
                    onResult({valid: true});
                }
            };
            reader.onerror = () => {
                onResult({error: "Unknown error"});
            };
        } else {
            onResult({errorTitle: Str.invalid_file(), error: Str.invalid_file()});
        }
    } else {
        onResult({valid: true});
    }
};

export const allSubsectionsFromSections = (sections: FormSection[]): FormSubsection[] => {
    return sections.flatMap(s => s.subsections);
};

export const getNavigationOptionsForSubsection = (currentSubsectionName: String, subsections: FormSubsection[], baseUrl: string): FormNavigationOptions => {
    const subsectionsWithIndex = subsections.map((subsection, index) => ({subsection, index}));
    const currentSubsectionIndex: number = subsectionsWithIndex.find(s => s.subsection.name === currentSubsectionName)!.index;

    const canGoBack = currentSubsectionIndex > 0;
    const canGoForward = currentSubsectionIndex < subsections.length - 1;

    const previousSection: FormSubsection | undefined = canGoBack ? subsections[currentSubsectionIndex - 1] : undefined;
    const nextSection: FormSubsection | undefined = canGoForward ? subsections[currentSubsectionIndex + 1] : undefined;

    const navigationOptionForSection = (subsection?: FormSubsection): FormNavigationOption | undefined => {
        if (subsection === undefined) return undefined;
        return {link: linkForSubsection(subsection, baseUrl), name: subsection.name};
    };

    return {
        previousSection: navigationOptionForSection(previousSection),
        nextSection: navigationOptionForSection(nextSection)
    };
};

export const linkForSubsection = (subsection: FormSubsection, baseUrl: string): string => {
    const baseUrlWithSlash = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;
    return baseUrlWithSlash + encodeURIComponent(subsection.name);
};
