import {Icon} from "@mui/material";
import * as Str from "../strings/Str";
import {PrimaryButton} from "./Buttons";

const ScrollToTopButton = ({onClick, id, buttonText}: {onClick: ()=>void, id: string, buttonText: string}) => {
    return <PrimaryButton id={"jobs-search-view-back-to-search-button"} style={{position: "absolute", bottom: 16, right: 16, zIndex: 999}} onClick={onClick}>
        <Icon>arrow_upward</Icon>
        <span style={{marginLeft: 12}}>{Str.back_to_search()}</span>
    </PrimaryButton>;
};

export default ScrollToTopButton;