import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {AspectFitImage, useInterval} from "grantfairy-web-common";
import {useEffect, useRef, useState} from "react";
import * as navigationUtil from "../util/navigationUtil";
import {MIAN, MIANImage, MIANLinkType, MIANSlide} from "../model/MIAN";
import {useNavigate} from "react-router-dom";
import * as actions from "../redux/actions";
import {connect} from "react-redux";
import {InView} from "react-intersection-observer";

type MIANImageWithRatio = MIANImage & { ratio: number }

const getBestImage = (images: MIANImage[], height: number, width: number): MIANImageWithRatio | null => {
    if (!images || images.length == 0) return null;
    const targetRatio = height / width;
    const imagesWithRatio: MIANImageWithRatio[] = images.map(i => ({...i, ratio: i.height / i.width}));
    const validImages = imagesWithRatio.filter(i => i.ratio < targetRatio);
    //If none valid then they're all too high, so take the lowest since that's least bad
    if (validImages.length === 0) return imagesWithRatio.reduce((a, b) => a.ratio < b.ratio ? a : b);
    //If we have valid ones then what we have is all the ones below the target ratio, so takes the highest
    const imageWithHighestRatio = validImages.reduce((a, b) => a.ratio > b.ratio ? a : b);
    return imageWithHighestRatio;
};

const getImages = (slide: MIANSlide, expanded: boolean): MIANImage[] => {
    if (slide == null || slide.expanded_images == null) return [];
    return slide.expanded_images.length === 0 ? slide.images : expanded ? slide.expanded_images : slide.images;
};

const Arrow = ({icon, left = 0, right = 0, color, onClick}: { icon: string, left?: number, right?: number, color: string, onClick: () => void }) => (
    <div style={{height: "100%", position: "absolute", left, right, display: "flex", alignItems: "center"}}>
        <Icon style={{color, fontSize: 80}} onClick={onClick}>{icon}</Icon>
    </div>
);

const Slides = ({slides, expanded, arrowColor, onClick}: { slides: MIANSlide[], expanded: boolean, arrowColor: string, onClick: () => void }) => {

    const time = useInterval(500);

    const [imageWidth, setImageWidth] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);

    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (imageRef.current) {
            setImageWidth(imageRef.current.offsetWidth);
        }
    }, [imageRef, time]);

    const targetHeight = expanded ? 200 : 100;
    const maxHeight = targetHeight * 1.5;

    const bestImage = getBestImage(getImages(slides[0], expanded), targetHeight, imageWidth);
    if (bestImage == null) return (<></>);

    const actualRatio = bestImage.ratio;
    const actualHeight = actualRatio * imageWidth;
    const finalHeight = Math.min(maxHeight, actualHeight);

    const slide = slides[currentSlide];
    const image = getBestImage(getImages(slide, expanded), targetHeight, imageWidth);
    if (image == null) return (<></>);

    const showPrev = currentSlide > 0;
    const showNext = currentSlide < slides.length - 1;

    const onPrev = () => setCurrentSlide(e => e - 1);
    const onNext = () => setCurrentSlide(e => e + 1);

    return (
        <div style={{position: "relative", height: finalHeight, width: "100%"}} ref={imageRef}>
            <div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}} onClick={onClick}>
                <AspectFitImage style={{height: "100%", width: "100%"}} src={image.url} backgroundSize="contain"/>
            </div>
            {showPrev && <Arrow icon="keyboard_arrow_left" onClick={onPrev} color={arrowColor} left={0}/>}
            {showNext && <Arrow icon="keyboard_arrow_right" onClick={onNext} color={arrowColor} right={0}/>}
        </div>
    );
};

const MIANView = ({mian, viewedMIAN, clickedMIAN}: { mian: MIAN, viewedMIAN: (mian: MIAN) => void, clickedMIAN: (mian: MIAN) => void }) => {
    const history = useNavigate();

    const [expanded, setExpanded] = useState(false);
    const [hasBeenSeen, setHasBeenSeen] = useState(false);

    const expandable = mian.slides.filter(e => e.expanded_images.length > 0).length > 0;

    const onView = (visible: Boolean) => {
        if(hasBeenSeen || !visible) return;

        setHasBeenSeen(true);
        console.log("Requesting viewed mian");
        viewedMIAN(mian);
    };

    const onClick = () => {
        if (expandable) {
            setExpanded(e => !e);
        } else {
            viewedMIAN(mian);
            clickedMIAN(mian);
            openLink();
        }
    };

    const openLink = () => {
        switch (mian.link_type) {
            case MIANLinkType.link:
                window.open(mian.link_data);
                break;
            case MIANLinkType.scholarship:
                history("/funding/" + mian.link_data + "/-1");
                break;
            case MIANLinkType.tab:
                const url = navigationUtil.tabNumberToUrl(mian.link_data);
                if (url != null) {
                    history(url);
                }
                break;
            case MIANLinkType.course:
                const course = JSON.parse(mian.link_data);
                history("/courses/" + course.universityID + "/" + course.uid);
                break;
            case MIANLinkType.university:
                history("/universities/search/" + mian.link_data);
                break;
            case MIANLinkType.options:
                history("/profile/options");
                break;
        }
    };

    return (
        <InView as="div" style={{background: mian.bleedColor, width: "100%"}} onChange={(inView) => onView(inView)}>
            <Slides slides={mian.slides} arrowColor={mian.arrowColor} expanded={expanded} onClick={onClick}/>
            {expandable && <Button onClick={openLink} style={{width: "100%", background: mian.buttonColor, color: mian.linkColor}}>{mian.linkText}</Button>}
        </InView>
    );
};

const mapStateToProps = ({mian}) => {
    return {mian: mian};
};

const mapDispatchToProps = (dispatch, {mian}) => ({
    viewedMIAN: (mian: MIAN) => {
        dispatch(actions.viewedMIAN(mian));
    },
    clickedMIAN: (mian: MIAN) => {
        dispatch(actions.clickedMIAN(mian));
    },
    mian: mian
});

export default connect(mapStateToProps, mapDispatchToProps)(MIANView);
