import {DialogContent, TextField} from "@mui/material";
import ColouredLink from "../../../components/ColouredLink";
import {ShowPasswordInputAdornment} from "../../../components/ToggleButtons";
import * as Str from "../../../strings/Str";
import {useSecondaryColour} from "../../../theme/Theme";
import {Dispatch, SetStateAction} from "react";

type ManualRegistrationContentProps = {
    application: any,
    registrationHelpText: string
    validate: boolean
    username: string,
    setUsername: (value: string) => void
    usernameValid: boolean
    password: string,
    setPassword: (value: string) => void
    passwordValid: boolean
    showPassword: boolean
    setShowPassword: Dispatch<SetStateAction<boolean>>
    hasError: boolean
}

export const ManualRegistrationContent = ({
                                              application,
                                              registrationHelpText,
                                              validate,
                                              username,
                                              setUsername,
                                              usernameValid,
                                              password,
                                              setPassword,
                                              passwordValid,
                                              showPassword,
                                              setShowPassword,
                                              hasError
                                          }: ManualRegistrationContentProps) => (
    <DialogContent style={{padding: "0 24px"}}>
        <p style={{marginTop: 0, whiteSpace: "pre-wrap"}}>{Str.myriad_requires_manual_registration(application.course.providerName)}</p>
        <p><b>{Str.link_to_register("")}</b><ColouredLink colour={useSecondaryColour()} href={application.applyUrl}>{Str.click_here()}</ColouredLink></p>
        {registrationHelpText.length !== 0 && <p>{registrationHelpText}</p>}
        <TextField color="primary" style={{width: "100%"}} label={Str.your_email_or_username()} onChange={e => setUsername(e.target.value)} variant="outlined" size="small"
                   value={username} error={validate && !usernameValid}/>
        <TextField color="primary" style={{width: "100%", marginTop: 16}} label={Str.your_password()} onChange={e => setPassword(e.target.value)}
                   type={showPassword ? "text" : "password"} variant="outlined" size="small" value={password} error={validate && !passwordValid}
                   InputProps={{endAdornment: <ShowPasswordInputAdornment showPassword={showPassword} setShowPassword={setShowPassword}/>}}/>
        {validate && hasError && <p style={{color: "red"}}>{Str.please_fill_field()}</p>}
    </DialogContent>);