import {Dialog, DialogContent} from "@mui/material";
import {OutlinedButton} from "grantfairy-web-common";
import {useState} from "react";
import {connect} from "react-redux";
import * as Assets from "../../Assets";
import {PrimaryButton} from "../../components/Buttons";
import {PAYMENT_OPTIONS} from "../../model/PaymentOptions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {usePrimaryColour} from "../../theme/Theme";
import * as Util from "../../util/StringUtil";

const InfoContent = ({wishlistCount, wishlistAmount, onSignup, onCancel}) => (
    <div style={{padding: 32, textAlign: "center"}}>
        <img style={{width: 100, height: 100}} src={require("../../" + Assets.LOGO)} alt=""/>

        <p style={{fontSize: 25, fontWeight: 600, margin: 8, marginTop: 32}}>{Str.grantfairy_has_identified()}</p>
        <p style={{fontSize: 30, fontWeight: 600, margin: 8}}>{Str.num_scholarships(wishlistCount)}</p>
        <p style={{fontSize: 25, fontWeight: 600, margin: 8}}>{Str.worth()}</p>
        <p style={{fontSize: 35, fontWeight: 700, margin: 8}}>{Util.moneyfy(wishlistAmount)}</p>
        <p style={{marginTop: 32}}>{Str.click_to_update_your_profile()}</p>

        <PrimaryButton style={{marginTop: 16, paddingLeft: 64, paddingRight: 64, width: "100%"}} onClick={onSignup}>{Str.sign_up()}</PrimaryButton>
        <br/>
        <OutlinedButton style={{marginTop: 16, width: "100%"}} onClick={onCancel}>{Str.cancel()}</OutlinedButton>
    </div>
);

const PaymentOption = ({option, isSelected, onSelect}) => {
    const primary = usePrimaryColour();
    const style = {
        border: "2px solid " + (isSelected ? primary : "#888"),
        overflow: "hidden",
        borderRadius: 16,
        margin: 16,
        flex: 1,
        color: isSelected ? primary : "#666",
        width: "calc(100% - 32px)"
    };

    return (
        <div style={{flex: 1}}>
            {!isSelected && option.header && <p style={{padding: 8, margin: 0, visibility: "hidden"}}>a</p>}
            <div style={style} onClick={onSelect} className="hoverable">
                {isSelected && option.header && <p style={{padding: 8, margin: 0, background: primary, color: "white"}}>{option.header}</p>}

                <p style={{margin: 0, marginTop: 32, fontSize: 60, fontWeight: 600}}>{option.length}</p>
                <p style={{margin: 0, marginTop: 8, fontSize: 20, fontWeight: 600}}>{option.period}</p>
                <p style={{margin: 0, marginTop: 8, fontSize: 20, fontWeight: 600}}>£{option.totalPrice}</p>

                <div style={{background: isSelected ? primary : "", marginTop: 16, padding: 8}}>
                    {/* TODO hardcoded strings and currency */}
                    <p style={{fontSize: 16, fontWeight: 500, margin: 0, color: isSelected ? "white" : ""}}>(£{option.price} / mo)</p>
                </div>
            </div>
        </div>
    );
};

const PaymentOptionPicker = ({option, setOption}) => {
    return (
        <div style={{display: "flex", justifyContent: "space-around", alignItems: "flex-end"}}>
            {PAYMENT_OPTIONS.map((op, index) => <PaymentOption key={index} option={op} isSelected={option === op} onSelect={() => setOption(op)}/>)}
        </div>
    );
};

const PaymentChoice = ({onPay, onCancel}) => {

    const [option, setOption] = useState(PAYMENT_OPTIONS[1]);

    const handlePay = () => onPay(option);

    return (
        <div style={{padding: 32, textAlign: "center"}}>
            <img style={{width: 100, height: 100}} src={require("../../" + Assets.LOGO)} alt=""/>

            <div style={{marginTop: 32}}>
                <PaymentOptionPicker option={option} setOption={setOption}/>
            </div>

            <p style={{color: "#888", margin: 0, marginTop: 16}}>{option.terms}</p>

            <PrimaryButton style={{marginTop: 16, paddingLeft: 64, paddingRight: 64, width: "100%"}} onClick={handlePay}>{Str.sign_up()}</PrimaryButton>
            <br/>
            <OutlinedButton style={{marginTop: 16, width: "100%"}} onClick={onCancel}>{Str.cancel()}</OutlinedButton>
        </div>
    );
};

const Popup = ({onClose, onPay, ...props}) => {
    const [page, setPage] = useState(0);
    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <DialogContent style={{padding: 0}}>
                <div style={{minHeight: 200}}>
                    {page === 0 && <InfoContent {...props} onCancel={onClose} onSignup={() => setPage(1)}/>}
                    {page === 1 && <PaymentChoice {...props} onCancel={() => setPage(0)} onPay={onPay}/>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state, props) => ({
    wishlistCount: selectors.fundingWishlistCount(state)?.payload ?? 0,
    wishlistAmount: selectors.fundingWishlistAmount(state)?.payload ?? 0,
    ...props
});

const Connected = connect(mapStateToProps)(Popup);

export default Connected;
