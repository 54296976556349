import {Info} from "@mui/icons-material";
import {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useAlert} from "../../Alerts";
import {ACCOMMODATION_APPLICATION_STATUS, APPLICATION_FORMS} from "../../Applications";
import {PrimaryButton, SecondaryButton} from "../../components/Buttons";
import HTMLString from "../../components/HTMLString";
import * as Property from "../../Property";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import Dimens from "../../theme/Dimens";
import Styles from "../../theme/Styles";
import DataDependant from "../../views/DataDependant";
import PropertyPrice from "../../views/PropertyPrice";
import {Subtitle} from "../../views/Text";
import ApplicationInfoPopup from "../main/applications/ApplicationInfoPopup";

const ApplicationListItemButtons = props => {
    const {application} = props;

    const style = {margin: 8, height: 36};

    const edit = <PrimaryButton style={style} component={Link} to={props.editLink}>{Str.cont()}</PrimaryButton>;
    const view = <SecondaryButton style={style} onClick={() => props.onView(application)}>{Str.preview()}</SecondaryButton>;
    const del = <SecondaryButton style={style} onClick={() => props.onDelete(application)}>{Str.delet()}</SecondaryButton>;

    if (application.status === ACCOMMODATION_APPLICATION_STATUS.draft) return [edit, del];
    if (application.status === ACCOMMODATION_APPLICATION_STATUS.sent) return [view];
    return null;
};

const ApplicationListItem = ({application, editLink, ...props}) => (
    <div style={{padding: "4px 16px", borderBottom: Styles.standardBorder, display: "flex", alignItems: "center"}} className="noSelect">
        <img alt="" style={{width: 64, height: 64}} src={application.property.image_url}/>
        <div style={{flex: 1, margin: 16}}>
            <p style={{margin: 4, fontWeight: 700}}>{application.property.name}</p>
            <Subtitle style={{margin: 4}}><PropertyPrice from={false} property={application.tenancy}/></Subtitle>
            <Subtitle style={{margin: 4}}>{Property.tenancyDateRange(application.tenancy)}</Subtitle>
        </div>
        <div style={{display: "flex", flexDirection: "column", width: 200}}>
            <ApplicationListItemButtons application={application} editLink={editLink} {...props}/>
        </div>
    </div>
);

const ApplicationList = ({applications, makeEditLink, ...props}) => {
    return applications.filter(e => e.property).map(a => <ApplicationListItem application={a} editLink={makeEditLink(a)} {...props}/>);
};

const Container = ({applications, onDeleteApplication, makeEditLink, status}) => {

    const applicationList = applications?.payload ?? [];

    const [alert, showAlert] = useAlert();

    const [viewingApplicationID, setViewingApplicationID] = useState(null);
    const viewingApplication = applicationList.filter(e => e.id === viewingApplicationID)[0];

    const onDelete = application => {
        showAlert(Str.delete_application(), Str.confirm_delete_application(), Str.delet(), Str.cancel(), () => onDeleteApplication(application));
    };

    return (
        <DataDependant data={applications}>
            {alert}
            {viewingApplication && (
                <ApplicationInfoPopup application={viewingApplication} onClose={() => setViewingApplicationID(null)} applicationForm={APPLICATION_FORMS.accommodation}/>
            )}
            <div>
                {status === ACCOMMODATION_APPLICATION_STATUS.sent && applicationList.length > 0 && (
                    <div style={{textAlign: "center", padding: Dimens.DoubleMargin, borderBottom: Styles.standardBorder}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Info/>
                            <p style={{margin: 0, marginLeft: Dimens.StandardMargin}}>
                                <HTMLString string={Str.acc_sent_explanatory_text()}/>
                            </p>
                        </div>
                    </div>
                )}
                <ApplicationList applications={applicationList} onDelete={onDelete} onView={app => setViewingApplicationID(app.id)} makeEditLink={makeEditLink}/>
            </div>
        </DataDependant>
    );
};

export const mapStateToProps = (state, {status}) => ({
    applications: selectors.accommodationApplicationsWithStatus(state, status),
    makeEditLink: app => "/accommodation/applications/apply/" + app.id,
    status
});

export const mapDispatchToProps = dispatch => ({
    onDeleteApplication: application => dispatch(actions.deleteApplication(application.id))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Connected;