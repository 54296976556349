import {BasicCourseInfo} from "../model/Course";
import PlainLink from "../components/PlainLink";
import FavouriteButton from "./FavouriteButton";
import * as FeatureFlags from "../FeatureFlags";
import LinkToApplyPage from "./LinkToApplyPage";
import "../theme/Card.css";
import * as Constants from "../Constants";
import LazyLoad from "react-lazyload";

export interface CourseListItemProps {
    course: BasicCourseInfo;
    isSelected: boolean;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (isFave: boolean) => void;
    isFave: boolean;
    makeApplyUrlForCourse: (courseId: string) => string;
    showProviderInfo: boolean;
    onClick: (courseId: string) => void;
}

const  CourseCard = ({course,  makeUrlForCourse, isFave, onFave, makeApplyUrlForCourse, onClick}: CourseListItemProps) => {
    const imagePath = Constants.REACT_APP_ASSETS_PATH + "providers/marketing_images/" + course.providerID + Constants.ASSETS_IMG_EXT;
    const logoPath = Constants.REACT_APP_ASSETS_PATH + "providers/" + course.providerID + Constants.ASSETS_LOGO_EXT;
    const onClickHandler = () => {
        onClick(course.id);
    };
    /*         Leave this line for future  */
    /* const outcomes = course.outcomes ? course.outcomes.slice(0,52) + (course.outcomes.length > 52 ? "..." : "") : ""; */

    return ( 
        <PlainLink to={makeUrlForCourse(course.id)} id={"course-list-course-" + course.id} onClick={onClickHandler}>
            <div className="card uni-card noSelect">
                <div className="card-header-image lazyload-wrapper">
                    <LazyLoad overflow>
                        <img src={imagePath}  alt="" />
                        <img className="logo-image" src={logoPath} alt="" />
                    </LazyLoad>
                    <div className="favourite-item">
                        <FavouriteButton onFave={onFave} favourited={isFave} itemID={course.id}/><br/>
                            {FeatureFlags.COURSE_LIST_APPLY_BUTTONS_ENABLED && <LinkToApplyPage link={makeApplyUrlForCourse(course.id)} canApply={course.canApply}/>}                            
                    </div>
                </div>
                <div className="card-contents">
                    <div className="card-body">
                        <div className="card-body-item">
                            <h1>{course.title}</h1>
                            <p>{course.providerName}</p>
                        </div>
                    </div>
                    {/*         Leave these lines for future  */}
                    {/*<div className="card-footer">*/}
                    {/*    <div className="card-infos">*/}
                    {/*        {course.outcomes && <p className="icon-box"><span className="material-icons">school</span>{outcomes}</p>}*/}
                    {/*        {!course.outcomes && <p className="icon-box"><span className="material-icons">school</span>{Str.unspecified()}</p>}*/}
                    {/*         {course.outcomes && <p className="icon-box"><span className="material-icons">schedule</span> 1 year</p>} */}
                    {/*         {course.outcomes && <p className="icon-box"><span className="material-icons">schedule</span> 6.0 PTE</p>} */}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

        </PlainLink>
    );
};

export default CourseCard;