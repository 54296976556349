import {Paper} from "grantfairy-web-common";
import {ReactNode, useMemo} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import {MasterDetailView} from "../../components/TabContainer";
import * as actions from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import DataDependant from "../../views/DataDependant";
import {Subtitle} from "../../views/Text";
import CourseDetails from "./CourseDetails";
import {BasicCourseInfo, SelectedCourse} from "../../model/Course";
import {ApiResult} from "../../util/ApiResult";
import CourseCardItem from "../../views/CourseCard";
import {currentUser} from "../../util/FirebaseUtil";
import {GoogleAnalyticsService} from "../../services/analytics/GoogleAnalyticsService";
import {LearnerClicksSearchResultEvent} from "../../model/AnalyticsEvents";

interface CourseListProps {
    courses: BasicCourseInfo[];
    selectedCourse: SelectedCourse;
    onViewMore?: () => void;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    checkFave: (course: BasicCourseInfo) => boolean;
    makeApplyUrlForCourse: (courseId: string) => string;
}

const CourseList = ({courses, selectedCourse, makeUrlForCourse, onFave, checkFave, makeApplyUrlForCourse}: CourseListProps) => {
    const listItems = courses.map(e => {
        const isSelected = e.id === selectedCourse.id;
        const clickLogHandler = (courseId) => {
            const user = currentUser();
            const courseRanking = courses.findIndex(course => course.id === courseId) + 1 ?? 0;
            const courseUrl = makeUrlForCourse(courseId ?? "");
            new GoogleAnalyticsService().logEvent(new LearnerClicksSearchResultEvent(user?.uid ?? "", "courses favourites", "", courseRanking.toString(), courseId, courseUrl));
        };
        return <CourseCardItem key={e.id} course={e} isSelected={isSelected} makeUrlForCourse={makeUrlForCourse} onFave={fave => onFave(e, fave)}
                               isFave={checkFave(e)} makeApplyUrlForCourse={makeApplyUrlForCourse} showProviderInfo={true} onClick={clickLogHandler}/>;
    });
    return <>{listItems}</>;
};

interface CourseFavouritesTabProps {
    favourites: BasicCourseInfo[];
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    makeApplyUrlForCourse: (courseId: string) => string;
    findCoursesUrl: string;
    viewingCourseId: string;
    courseDetailsComponent: ReactNode;
}

const CourseFavouritesTab = ({
                                 favourites,
                                 viewingCourseId,
                                 courseDetailsComponent,
                                 makeUrlForCourse,
                                 findCoursesUrl,
                                 onFave,
                                 makeApplyUrlForCourse
                             }: CourseFavouritesTabProps) => {

    const selectedCourse = {id: viewingCourseId};

    //cache the faves as they are. We never stop showing a fave even if it becomes unfaved, we still show it
    const cachedFaves = useMemo(() => favourites, []);

    const checkFave = course => {
        return favourites.includes(course);
    };

    if (cachedFaves.length === 0) {
        return (
            <div style={{display: "flex", padding: 64, alignItems: "center", justifyContent: "center"}}>
                <Paper style={{overflowY: "auto", padding: 48, textAlign: "center"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <Subtitle id={"home-empty-favourites-str"}>{Str.emptyFavourites()}</Subtitle>
                    <PrimaryButton id={"search-courses-empty-favourites-btn"} component={Link} to={findCoursesUrl} style={{marginTop: 16}}>{Str.browse_courses2()}</PrimaryButton>
                </Paper>
            </div>
        );
    }

    return (
        <MasterDetailView showDetailView={viewingCourseId != null} listView={(
            <div style={{flex: 1, overflowY: "auto", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                <CourseList courses={cachedFaves} selectedCourse={selectedCourse} makeUrlForCourse={makeUrlForCourse} onFave={onFave}
                            checkFave={checkFave} makeApplyUrlForCourse={makeApplyUrlForCourse}/>
            </div>
        )} detailView={(
            <Paper style={{height: "100%", overflowY: "scroll"}} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {courseDetailsComponent}
            </Paper>
        )}/>
    );
};

interface ContainerProps {
    favourites: ApiResult<BasicCourseInfo[]>;
    makeUrlForCourse: (courseId: string) => string;
    onFave: (course: BasicCourseInfo, isFave: boolean) => void;
    makeApplyUrlForCourse: (courseId: string) => string;
    findCoursesUrl: string;
    viewingCourseId: string;
    tab: string;
}

const Container = (props: ContainerProps) => {
    const {favourites, viewingCourseId, makeUrlForCourse, tab} = props;
    const details = <CourseDetails courseID={viewingCourseId} makeUrlForCourse={makeUrlForCourse} tab={tab}/>;
    return <DataDependant data={favourites}><CourseFavouritesTab courseDetailsComponent={details} {...props} favourites={favourites.payload ?? []}/></DataDependant>;
};

export const mapStateToProps = (state, {viewingCourseId, tab}) => ({
    favourites: selectors.courseFavouritesSortedByName(state),
    viewingCourseId,
    makeUrlForCourse: (id) => `/favourites/${id}`,
    findCoursesUrl: "/courses",
    tab,
    makeApplyUrlForCourse: (id) => `/favourites/${id}/apply`
});

const mapDispatchToProps = dispatch => ({
    onFave: (course, fave) => dispatch(actions.setCourseFave(course, fave))
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {viewingCourseId, tab} = useParams();
    return (<Connected viewingCourseId={viewingCourseId} tab={tab} />);
};

export default WrappedAndConnected;
