import {FormItemQuestion} from "../../util/form/FormItem";
import {HTMLProps, MouseEventHandler, ReactNode} from "react";
import {useTextColour} from "../../theme/Theme";
import ColouredLink from "../../components/ColouredLink";
import {OptionalityIndicator} from "../../components/OptionalityIndicator";

interface QuestionProps {
    item: FormItemQuestion;
    handleLink: MouseEventHandler<HTMLAnchorElement>;
    showOptionalLabel?: boolean;
}

const FormQuestionText = ({item, handleLink, style = {}, showOptionalLabel = true}: QuestionProps & HTMLProps<HTMLParagraphElement>) => {
    const tintColour = useTextColour();

    const text = item.question;
    let textToRender: ReactNode = text;
    if (item.action) {
        const {on, link} = item.action;
        if (text.includes(on)) {
            const [before, after] = text.split(on);
            const linkAnchor = <ColouredLink className="external-hoverable" colour={tintColour} rel="noopener noreferrer" onClick={handleLink} href={link}>{on}</ColouredLink>;
            textToRender = [before, linkAnchor, after];
        }
    }

    return (
        <p style={{marginBottom: 0, ...style}}>{textToRender}<OptionalityIndicator markWithAsterisk={item.required} showOptionalLabel={showOptionalLabel}/></p>
    );
};

export default FormQuestionText;
