import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Icon from "@mui/material/Icon";
import Slider from "@mui/material/Slider";
import {OutlinedButton} from "grantfairy-web-common";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {PrimaryButton} from "../../components/Buttons";
import {ROOM_TYPES} from "../../Property";
import * as actions from "../../redux/actions";
import * as  selectors from "../../redux/selectors";
import * as Str from "../../strings/Str";
import {useSecondaryColour} from "../../theme/Theme";
import DataDependant from "../../views/DataDependant";
import {SmallTitle, Title} from "../../views/Text";
import PropertiesList from "./AccommodationPropertyList";
import Map from "./AccommodationPropertyMap";
import {CampusSelector} from "./NoCampusView";
import Colours from "../../theme/Colours";

const FiltersPopup = ({filters, setFilters, open, onClose, onGo, resultCount}) => {

    const [localFilters, setLocalFilters] = useState(filters);

    const setLocalField = (field, value) => {
        setLocalFilters(f => ({...f, [field]: value}));
    };

    const minPrice = localFilters.minPrice;
    const maxPrice = localFilters.maxPrice ?? 400;//slider goes £0 - £399 then switches to unlimited. We use null to represent unlimited but in the UI its 400

    const minTenancy = localFilters.minTenancyDays === 1 ? 0 : (localFilters.minTenancyDays / 7);//its stored in days but UI is in weeks except for the option of '1 day'
    const maxTenancy = localFilters.maxTenancyDays === null ? 52 : (localFilters.maxTenancyDays / 7);//null means unlimited, otherwise UI is in weeks.

    const [localPriceState, setLocalPriceState] = useState([minPrice, maxPrice]);
    const [localTenancyState, setLocalTenancyState] = useState([minTenancy, maxTenancy]);

    const minPriceHuman = "£" + localPriceState[0];
    const maxPriceHuman = localPriceState[1] === 400 ? Str.no_limit() : `£${localPriceState[1]}`;

    const minTenancyHuman = localTenancyState[0] === 0 ? Str.one_day() : localTenancyState[0] === 1 ? Str.one_week() : Str.x_weeks(localTenancyState[0]);
    const maxTenancyHuman = localTenancyState[1] === 52 ? Str.no_limit() : localTenancyState[1] === 1 ? Str.one_week() : Str.x_weeks(localTenancyState[1]);

    const controlColourName = "secondary";

    useEffect(() => {
        const handler = setTimeout(() => {
            setFilters(localFilters);
        }, 500);

        return () => clearTimeout(handler);
    }, [localFilters]);

    const cleanAndUpdateSlider = updater => (e, value) => {
        if (value[0] !== value[1]) {
            updater(value);
        }
    };

    const priceChanged = () => {
        const [min, max] = localPriceState;
        const maxMapped = max === 400 ? null : max;
        setLocalField("minPrice", min);
        setLocalField("maxPrice", maxMapped);
    };

    const tenancyChanged = () => {
        const [min, max] = localTenancyState;
        const minMapped = min === 0 ? 1 : min * 7;
        const maxMapped = max === 52 ? null : max * 7;
        setLocalField("minTenancyDays", minMapped);
        setLocalField("maxTenancyDays", maxMapped);
    };

    const roomCheckHandler = roomType => e => {
        const add = e.target.checked;
        if (add) {
            setLocalField("roomTypes", [...localFilters.roomTypes, roomType]);
        } else {
            setLocalField("roomTypes", localFilters.roomTypes.filter(e => e !== roomType));
        }
    };

    const isRoomIncluded = type => localFilters.roomTypes.includes(type);

    const handleGo = () => {
        onClose();
        onGo(localFilters);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onBackdropClick={onClose}>
            <div style={{margin: 30, marginTop: 0, marginBottom: 0}}>
                <Title>{Str.filters()}</Title>
                <div style={{display: "flex"}}>
                    <div style={{flex: 1}}>
                        <SmallTitle>{Str.room_types()}</SmallTitle>
                        <FormControlLabel control={<Checkbox checked={isRoomIncluded(ROOM_TYPES.shared)} color={controlColourName} onChange={roomCheckHandler(ROOM_TYPES.shared)}/>}
                                          label={Str.shared_room()}/><br/>
                        <FormControlLabel control={<Checkbox checked={isRoomIncluded(ROOM_TYPES.private)} color={controlColourName} onChange={roomCheckHandler(ROOM_TYPES.private)}/>}
                                          label={Str.private_room()}/><br/>
                        <FormControlLabel control={<Checkbox checked={isRoomIncluded(ROOM_TYPES.studio)} color={controlColourName} onChange={roomCheckHandler(ROOM_TYPES.studio)}/>}
                                          label={Str.studio_room()}/><br/>
                        <FormControlLabel control={<Checkbox checked={isRoomIncluded(ROOM_TYPES.entirePlace)} color={controlColourName} onChange={roomCheckHandler(ROOM_TYPES.entirePlace)}/>}
                                          label={Str.entire_place()}/><br/>
                    </div>
                    <div style={{flex: 1}}>
                        <SmallTitle>{Str.availability()}</SmallTitle>
                        <FormControlLabel control={<Checkbox checked={!localFilters.hideSold} color={controlColourName} onChange={e => setLocalField("hideSold", !e.target.checked)}/>}
                                          label={Str.show_all()}/><br/>
                        <FormControlLabel control={<Checkbox checked={localFilters.hideSold} color={controlColourName} onChange={e => setLocalField("hideSold", e.target.checked)}/>}
                                          label={Str.hide_sold()}/><br/>
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <SmallTitle style={{flex: 1}}>{Str.price_per_week()}</SmallTitle>
                    <SmallTitle style={{color: useSecondaryColour()}}>{Str.range(minPriceHuman, maxPriceHuman)}</SmallTitle>
                </div>
                <div style={{width: "calc(100% - 8px)"}}>
                    <Slider value={localPriceState} color="secondary" max={400} min={0} onChange={cleanAndUpdateSlider(setLocalPriceState)} onChangeCommitted={priceChanged}/>
                </div>
                <div style={{display: "flex"}}>
                    <SmallTitle style={{flex: 1}}>{Str.tenancy_duration()}</SmallTitle>
                    <SmallTitle style={{color: useSecondaryColour()}}>{Str.range(minTenancyHuman, maxTenancyHuman)}</SmallTitle>
                </div>
                <div style={{width: "calc(100% - 8px)"}}>
                    <Slider value={localTenancyState} color="secondary" max={52} min={0} onChange={cleanAndUpdateSlider(setLocalTenancyState)} onChangeCommitted={tenancyChanged}/>
                </div>
                <div style={{margin: "16px", textAlign: "center"}}>
                    <PrimaryButton onClick={handleGo}>{Str.show_x_properties(resultCount)}</PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToFilterProps = (state, ownProps) => ({
    filters: selectors.accommodationFiltersDraft(state),
    resultCount: selectors.accommodationFilterResultCount(state)?.payload ?? 0,
    ...ownProps
});

const mapDispatchToFilterProps = dispatch => ({
    setFilters: (filters) => dispatch(actions.setAccommodationFilters(filters)),
    onGo: filters => dispatch(actions.commitAccommodationFilters(filters))
});

const ConnectedFiltersPopup = connect(mapStateToFilterProps, mapDispatchToFilterProps)(FiltersPopup);

const AccommodationSearchTab = ({properties, mians, campuses, selectedCampus = null, onCampusChange = null, selectedPropertyId = null, makeUrlForProperty, makeUrlForPropertyDetails = null, checkFave = null, onFave = null, directionInfo = null}) => {

    const [filtersOpen, setFiltersOpen] = useState(false);

    return (
        <>
            <ConnectedFiltersPopup open={filtersOpen} onClose={() => setFiltersOpen(false)}/>
            <div style={{height: "calc(100%)", display: "flex", position: "relative"}} className="noSelect">
                <Map properties={properties} campus={selectedCampus} makeUrlForProperty={makeUrlForProperty} selectedPropertyId={selectedPropertyId} checkFave={checkFave}
                     directionInfo={directionInfo} showDirectionInfo/>
                <div style={{position: "absolute", right: 16, top: 16, bottom: 16, width: 500, overflowY: "auto"}}>
                    <PropertiesList properties={properties} mians={mians} selectedPropertyId={selectedPropertyId} makeUrlForProperty={makeUrlForProperty}
                        // @ts-ignore
                                    makeUrlForPropertyDetails={makeUrlForPropertyDetails} checkFave={checkFave} onFave={onFave} searchCategory="rooms" searchTerm={selectedCampus?.id ?? ""} directionInfo={directionInfo} showDirectionInfo/>
                </div>
                <div style={{position: "absolute", top: 16, left: 16}}>
                    <div style={{display: "flex"}}>
                        <CampusSelector campuses={campuses} selectedCampus={selectedCampus} onCampusChange={onCampusChange}/>
                        <div style={{width: 16}}/>
                        <OutlinedButton id="myriad-rooms-search-filters" style={{ background: "white", color: Colours.StandardText }} onClick={() => setFiltersOpen(true)}><Icon>filter_list</Icon>&nbsp;{Str.filters()}</OutlinedButton>
                    </div>
                </div>
            </div>
        </>
    );
};

const Container = ({properties, mians, campuses, requestDirections, ...props}) => {

    useEffect(() => {
        if (props.selectedProperty && props.selectedCampus) {
            requestDirections(props.selectedProperty, props.selectedCampus);
        }
    }, [props.selectedProperty, props.selectedCampus]);

    return (
        <DataDependant data={properties}>
            {props.selectedCampus != null && <AccommodationSearchTab makeUrlForProperty={undefined} properties={properties?.payload} mians={mians.payload ?? []} campuses={campuses?.payload} {...props}/>}
        </DataDependant>
    );
};

export const mapStateToProps = (state, {propertyID}) => {
    const properties = selectors.accommodationProperties(state);
    const selectedProperty = (properties?.payload ?? []).filter(e => e.uid === propertyID)[0];
    const selectedCampus = selectors.accommodationSelectedCampus(state);
    let directionInfo = null;
    if (selectedProperty && selectedCampus) {
        directionInfo = selectors.accommodationDirectionInfo(state, selectedProperty.lat, selectedProperty.lng, selectedCampus.lat, selectedCampus.lng)?.payload;
    }
    return ({
        properties,
        mians: selectors.accommodationMians(state),
        selectedProperty,
        campuses: selectors.accommodationCampuses(state),
        selectedCampus,
        makeUrlForProperty: property => "/accommodation/search/" + property.uid,
        makeUrlForPropertyDetails: property => "/accommodation/search/" + property.uid + "/details",
        selectedPropertyId: propertyID,
        checkFave: property => (selectors.accommodationFavourites(state)?.payload ?? []).map(e => e.uid).includes(property.uid),
        directionInfo
    });
};

export const mapDispatchToProps = dispatch => ({
    onCampusChange: campus => {
        if (campus && campus.id) {
            dispatch(actions.setAccommodationCampus(campus.id));
        }
    },
    onFave: (property, fave) => dispatch(actions.setAccommodationFave(property, fave)),
    requestDirections: (property, campus) => dispatch(actions.requestAccommodationDirections(property.lat, property.lng, campus.lat, campus.lng))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const WrappedAndConnected = () => {
    const {propertyID} = useParams();
    return (<Connected propertyID={propertyID} />);
};

export default WrappedAndConnected;