import {connect} from "react-redux";
import * as actions from "../../../redux/actions";
import * as selectors from "../../../redux/selectors";
import * as Str from "../../../strings/Str";
import BaseApplicationFormScreen from "./BaseApplicationFormScreen";
import {useParams} from "react-router-dom";

const baseUrl = "/applications/apply/";

export const mapStateToProps = (state, {subsection, applicationID}) => ({
    subsection: selectors.applicationSpecificFormSubsection(state, decodeURIComponent(subsection), applicationID),
    navigationOptions: selectors.universitySpecificFormNavigationOptions(state, decodeURIComponent(subsection), baseUrl + applicationID + "/form", applicationID),
    allItems: selectors.applicationSpecificFormAllItems(state, applicationID),
    answers: selectors.applicationFormAnswers(state),
    savingState: selectors.applicationFormSavingState(state),
    backLink: baseUrl + applicationID
});

export const mapDispatchToProps = (dispatch, {applicationID}) => ({
    onSetAnswer: (questionID, answer) => dispatch(actions.setApplicationFormAnswer(questionID, answer)),
    onBack: () => dispatch(actions.goBack(baseUrl + applicationID)),
    onSave: () => dispatch(actions.showToast(Str.saved())),
    onViewFile: (originalName: string, fileName: string) => dispatch(actions.viewFormDocument(fileName, originalName)),
    onDeleteFile: (questionID: string, fileName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => dispatch(actions.deleteFormDocument(questionID, fileName, isRecord, index, innerQuestionID)),
    onUploadFile: (questionID: string, file: string, originalName: string, isRecord?: boolean, index?: number, innerQuestionID?: string) => dispatch(actions.uploadFormDocument(questionID, file, originalName, isRecord, index, innerQuestionID))
});

export const Connected = connect(mapStateToProps, mapDispatchToProps)(BaseApplicationFormScreen);

const WrappedAndConnected = () => {
    const {applicationID, subsection} = useParams();
    return (<Connected applicationID={applicationID} subsection={subsection}/>);
};

export default WrappedAndConnected;